import { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import TONFilter from '#TONClient/TONFilter';
import { getMasterSeqNoRangeFromNewApi } from '#TONClient/helpers';
import { Maybe } from 'graphql/jsutils/Maybe';
import { additionalFields, binaryFields, shortFields } from '#TONClient/EVERTransaction/fields';

export const result = (args: Maybe<GetTransactionsArgs>): string =>
    `
        ${shortFields}
        ${args?.needAdditionalFields ? additionalFields : ''}
        ${args?.needBinaryFields ? binaryFields : ''}
    `;

export const newFilter = async (args: GetTransactionsArgs): Promise<string> => {
    try {
        if (!args) {
            return '';
        }

        const { filterValues, direction, limit, lastItem } = args;
        const minBalanceDelta = filterValues?.minBalanceDelta?.value;
        const maxBalanceDelta = filterValues?.maxBalanceDelta?.value;
        const accountId = filterValues?.accountId || filterValues?.accountId?.value;
        const isAborted = filterValues?.aborted?.value;
        const workchainValue = filterValues?.workchain_id?.value;

        const workchain =
            workchainValue || workchainValue === 0 ? `workchain: ${filterValues?.workchain_id?.value},` : '';
        const balance = `
                ${
                    minBalanceDelta || minBalanceDelta === 0
                        ? `min_balance_delta: "${TONFilter.convertTonsToHexNano(minBalanceDelta)}",`
                        : ''
                }
                    ${
                        maxBalanceDelta || maxBalanceDelta === 0
                            ? `max_balance_delta: "${TONFilter.convertTonsToHexNano(maxBalanceDelta)}",`
                            : ''
                    }
            `;
        const aborted = `${isAborted !== null && isAborted !== undefined ? `aborted: ${isAborted},` : ''}`;
        const masterSeqNoRange = await getMasterSeqNoRangeFromNewApi(
            filterValues?.minTime?.value,
            filterValues?.maxTime?.value
        );
        const cursorPosition = lastItem?.endCursor
            ? `${direction === 'DESC' ? 'before' : 'after'}: "${lastItem.endCursor}",`
            : '';
        const itemsRange = `${direction === 'DESC' ? 'last' : 'first'}: ${limit},`;
        const commonFilters = `${masterSeqNoRange}${balance}${itemsRange}${cursorPosition}`;

        if (accountId) return `${aborted}${commonFilters}`;
        else return `${workchain}${commonFilters}`;
    } catch (e) {
        return '';
    }
};

export const getAccountTransactionsQuery = async (args: GetTransactionsArgs): Promise<string> => {
    const { filterValues } = args;
    const accountId = filterValues?.accountId?.value || filterValues?.accountId;
    const filter = await newFilter(args);
    const fields = result(args);

    return `
        query {
            blockchain{
                account(address:"${accountId}"){
                    transactions(${filter}){
                        edges{
                            node{
                                ${fields}
                            }
                            cursor
                        }
                        pageInfo{
                            endCursor
                            hasNextPage
                        }
                    }
                }
            }
        }
    `;
};

export const getTransactionsWithPaginationQuery = async (args: GetTransactionsArgs): Promise<string> => {
    const filter = await newFilter(args);
    const fields = result(args);

    return `
        query{
            blockchain{
                transactions(${filter}){
                    edges{
                        node{
                            ${fields}
                        }
                        cursor
                    }
                    pageInfo{
                        endCursor
                    }
                }
            }
        }
    `;
};
