import React, { useState } from 'react';
import { OverridableComponent } from '@mui/types';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material/SvgIcon';
import classnames from 'classnames';

import { common } from '#uikit/designCore/styles';

import './ActionImage.scss';

const defaultFill = '#fff';

type Props = {
    Img: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
    fill?: string;
    fillOnHover?: string;
    onClick?: () => void;
} & Partial<SvgIconProps>;

const ActionImage: React.FC<Props> = ({ Img, fill, fillOnHover, onClick = () => {}, ...props }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={classnames(common.cursorPointer, 'ActionImage')}
            style={{
                fill: isHover ? fillOnHover || fill || defaultFill : fill || defaultFill,
            }}
            onClick={() => onClick()}
            onPointerOver={() => setIsHover(true)}
            onPointerOut={() => setIsHover(false)}
        >
            <Img {...props} className={classnames(props.className, 'MUI', 'Img')} />
        </div>
    );
};

export { ActionImage };
