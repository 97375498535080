import React, { ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import { TONClient } from '#TONClient';
import { LinkWithChild, UILabel, UITextButton } from '#components';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin, common } from '#uikit/designCore/styles';

export type AccentDetails = {
    title?: string | number;
    titleRole?: TypographyVariants;
    titleComponent?: ReactElement;
    details: string;
    detailsLinkPath?: string;
    isBalance?: boolean;
    annotation?: string;
    onPress?: () => void;
    containerClassNames?: string;
    detailsLinkRole?: string;
    isHiddenOnAsc?: boolean;
    isAverageBlockTime?: boolean;
    isItemsPerSecond?: boolean;
};

export const accentDetailsViewTestIDs = {
    networkValueFor: (details: string) => `networkValueFor_${details}`,
    networkValueSubtitleFor: (details: string) => `networkValueSubtitleFor_${details}`,
};

const AccentDetailsView = ({
    title = '',
    titleRole = TypographyVariants.LightHuge,
    titleComponent,
    details = '',
    detailsLinkPath,
    onPress = undefined,
    containerClassNames,
    detailsLinkRole,
    isBalance = false,
    annotation = '',
}: AccentDetails) => {
    const [convertedBalance, setConvertedBalance] = useState<number>(0);

    useEffect(() => {
        if (!isBalance) return;

        if (!title) {
            setConvertedBalance(0);
        } else {
            setConvertedBalance(Math.floor(TONClient.nanoToTons(title)));
        }
    }, [title, isBalance]);

    const renderedTitle = (() => {
        const testIDProp = { testID: accentDetailsViewTestIDs.networkValueFor(details) };

        if (titleComponent) return titleComponent;

        const titleParam = isBalance ? convertedBalance : title;

        const formattedTitle = !titleParam
            ? '-'
            : typeof titleParam === 'number'
            ? liveLocalized.amountToLocale(titleParam)
            : titleParam;

        if (onPress) {
            return (
                <UITextButton
                    title={formattedTitle}
                    titleRole={titleRole}
                    classNames={margin.topMedium}
                    onPress={onPress}
                    {...testIDProp}
                />
            );
        }

        return (
            <UILabel role={titleRole} classNames={margin.topMedium} {...testIDProp}>
                {formattedTitle}
            </UILabel>
        );
    })();

    const detailsComponent = (
        <UILabel
            testID={accentDetailsViewTestIDs.networkValueSubtitleFor(details)}
            color={ColorVariants.TextSecondary}
            role={TypographyVariants.ParagraphFootnote}
            classNames={classnames(margin.topSmall, margin.bottomDefault)}
        >
            {detailsLinkPath ? (
                <LinkWithChild navigationPath={detailsLinkPath}>
                    <UITextButton title={details} titleRole={detailsLinkRole || TypographyVariants.ParagraphFootnote} />
                </LinkWithChild>
            ) : (
                details
            )}
        </UILabel>
    );

    return (
        <div className={classnames(common.backgroundTransparent, containerClassNames)}>
            {renderedTitle}
            {detailsComponent}
            <UILabel
                role={TypographyVariants.ParagraphFootnote}
                color={ColorVariants.TextSecondary}
                classNames={margin.bottomDefault}
            >
                {annotation}
            </UILabel>
        </div>
    );
};

export default AccentDetailsView;
