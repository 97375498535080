// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONContract, TONFilterTags } from '#TONClient';
import type { GetContractsArgs, TONContractT } from '#TONClient/TONContract/types';
import type { FilterValues } from '#TONClient/TONFilter';
import { ContractView } from '#components';
import animationData from '#assets/animations/data1.json';

import ListScreen from '../ListScreen';

const ContractsComponent = () => {
    const ref = useRef(null);

    const [totalCount, setTotalCount] = useState<number>(null);

    // Details
    const details = [
        {
            title: totalCount,
            details: liveLocalized.UniqueContracts,
        },
    ];

    // Actions
    const itemsLoader = useCallback(
        async (args: GetContractsArgs, filterValues: FilterValues): Promise<TONContractT[]> =>
            TONContract.getContracts({
                ...args,
                filterValues,
            }),
        []
    );

    const onChangeItems = (newItems: TONContractT[]) => setTotalCount(newItems.length);

    useEffect(() => {
        if (totalCount === null) controllerService.showSpinnerOverlay();
        else controllerService.hideSpinnerOverlay();
    }, [totalCount]);

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.SmartContracts}
            listTitle={liveLocalized.TopUsed}
            shouldHideListTitleOnASC
            details={details}
            itemsLoader={itemsLoader}
            onChangeItems={onChangeItems}
            animationData={animationData}
            filterProps={TONFilterTags.getContractProps()}
            hasDefaultFilterValuesDefined
            collectionConfigs={ContractView.getCollectionConfigs()}
            realtimeUpdated={false}
        />
    );
};

const Contracts = observer(ContractsComponent);

export { Contracts };
