import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import type { AccentDetails } from '#components/AccentDetailsTable/AccentDetailsView';
import { AccentDetailsTable, TagFilter, EVERList, UILabel } from '#components';
import type { GetTONItemsArgs, TONCollectionConfigs, TONExternalConfigs, EVERItem } from '#components/EVERList';
import { EVERListRef } from '#components/EVERList/types';
import MomentHelper from '#helpers/MomentHelper';
import { defaultCollectionConfigs } from '#controllers/Lists/helpers';
import {
    getAverageBlockTimeStr,
    getDefaultFilterValue,
    getDefaultFilterValues,
    getFilterValuesFromURLParams,
} from '#controllers/helpers';
import { aggregationItemsTimeRange } from '#controllers/constants';
import Screen from '#controllers/Screen';
import { ScreenRefType } from '#controllers/Screen/types';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import type { FilterPropObj } from '#TONClient/TONFilterTags';
import { TONClient, TONFilter } from '#TONClient';
import { margin } from '#uikit/designCore/styles/margin';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import Utils from '#helpers/Utils';
import { common, container, flex } from '#uikit/designCore/styles';

import { ListScreenRefType } from './types';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import { defaultItemsLoader } from '#components/EVERList';

export const smallAccentTitleRoleObj = { titleRole: TypographyVariants.PromoMedium };

type Props = {
    title?: string;
    listTitle?: string;
    shouldHideListTitleOnASC?: boolean;
    titleRole?: TypographyVariants;
    subtitleRole?: TypographyVariants;
    details?: AccentDetails[];
    // Need fix Flow Errors with it
    itemsLoader?: (args: GetTONItemsArgs, filterValues?: FilterValues) => any;
    itemsAggregator?: (filterValues: FilterValues) => Promise<number | null>;
    animationData?: any;
    filterProps?: FilterPropObj;
    needToCallOnChangeFilterCustomOnFocus?: boolean;
    collectionConfigs?: TONCollectionConfigs;
    externalControlConfigs?: TONExternalConfigs;
    allowFilterSubscription?: boolean;
    realtimeUpdated?: boolean;
    needTagFilterContainerAboveTitle?: boolean;
    hideTetris?: boolean;
    needList?: boolean;
    queryGetter?: (args: GetTONItemsArgs) => Promise<string>;
    onSubscribeForUpdate?: (filterValues?: FilterValues) => void;
    onUnsubscribeForUpdate?: () => void;
    onChangeFilterCustom?: (filterValues: FilterValues) => void;
    onStartReload?: (filterValues: FilterValues) => void;
    onChangeItems?: (items: EVERItem[]) => void;
    customListComponent?: ReactNode;
    descriptionComponent?: ReactNode;
    rightTitleComponent?: ReactNode;
    leftTitleComponent?: ReactNode;
    customContentComponent?: ReactNode;
    belowTitleComponent?: ReactNode;
    beforeTitleComponent?: ReactNode;
    avatarComponent?: ReactNode;
};

const ListScreen = forwardRef<ListScreenRefType, Props>(
    (
        {
            title = '',
            listTitle = '',
            shouldHideListTitleOnASC = false,
            titleRole = TypographyVariants.TitleHuge,
            subtitleRole,
            details = [],
            itemsLoader = defaultItemsLoader,
            itemsAggregator = async () => 0,
            animationData = null,
            filterProps = {},
            needToCallOnChangeFilterCustomOnFocus,
            collectionConfigs = defaultCollectionConfigs,
            externalControlConfigs = { sortingKey: '' },
            allowFilterSubscription = false,
            realtimeUpdated = true,
            needTagFilterContainerAboveTitle = true,
            needList = true,
            hideTetris = false,
            queryGetter = async () => '',
            onSubscribeForUpdate = () => {},
            onUnsubscribeForUpdate = () => {},
            onStartReload = () => {},
            onChangeItems = () => {},
            onChangeFilterCustom = () => {},
            customListComponent = null,
            descriptionComponent = null,
            leftTitleComponent = null,
            rightTitleComponent = null,
            customContentComponent = null,
            belowTitleComponent = null,
            beforeTitleComponent = null,
            avatarComponent = null,
        },
        Ref
    ) => {
        const ref = useRef<ScreenRefType>(null);
        const listRef = useRef<EVERListRef>(null);
        const customQueryListRef = useRef<EVERListRef>(null);
        const defaultFilterValues = getDefaultFilterValues(filterProps);
        const [params, setParams] = useParams();

        const sortDirectionByDefault =
            params?.sortDirection || externalControlConfigs?.sortDirectionByDefault || 'DESC';

        const [items, setItems] = useState<EVERItem[]>([]);
        const [sortDirection, setSortDirection] = useState<SortDirection>(sortDirectionByDefault);
        const [lastItemsCount, setLastItemsCount] = useState<number>(0);
        const [lastItemsMinTime, setLastItemsMinTime] = useState<number>(0);
        const [lastItemsMaxTime, setLastItemsMaxTime] = useState<number>(0);
        const [filterValues, setFilterValues] = useState<FilterValues>(defaultFilterValues);
        const [playgroundArgs, setPlaygroundArgs] = useState<GetTONItemsArgs>({});
        const [query, setQuery] = useState<string>('');

        const externalControlConfigsResult = externalControlConfigs.items?.length
            ? {
                  items,
                  ...externalControlConfigs,
                  sortDirectionByDefault: sortDirection,
              }
            : {
                  items: null,
                  sortDirectionByDefault: sortDirection,
                  isLoading: externalControlConfigs?.isLoading,
                  sortingKey: '',
              };

        const isAsc = sortDirection === 'ASC';
        const itemsPerSecond =
            lastItemsCount && lastItemsMinTime && lastItemsMaxTime
                ? Utils.roundToMeaningDigit(lastItemsCount / (lastItemsMaxTime - lastItemsMinTime))
                : 0;

        const detailsList = details.map((item) => {
            if (item.isHiddenOnAsc && isAsc) return { ...item, title: '-' };
            if (item.isItemsPerSecond) return { ...item, title: itemsPerSecond };
            if (item.isAverageBlockTime)
                return { ...item, title: getAverageBlockTimeStr(itemsPerSecond ? 1 / itemsPerSecond : 0) };
            return item;
        });

        // Actions
        const unshiftItem = (item?: EVERItem, sortingKeyParam?: string) => {
            listRef?.current && listRef.current?.unshiftItem(item, sortingKeyParam);
        };

        const unshiftNewItem = (newLastItemsMaxTime?: number) => {
            setLastItemsMaxTime((prev) => {
                return !!newLastItemsMaxTime && newLastItemsMaxTime > prev ? newLastItemsMaxTime : lastItemsMaxTime;
            });
            setLastItemsCount((prev) => prev + 1);
        };

        const loadLastItemsCount = async (filterValuesParam?: FilterValues) => {
            const { value: newMaxTimeValue } = filterValuesParam?.maxTime || {};
            const now = MomentHelper.now();
            const maxTime = newMaxTimeValue ? Math.min(newMaxTimeValue, now) : now;
            const minTime = maxTime - aggregationItemsTimeRange;
            const newLastItemsCount = await itemsAggregator({
                ...filterValuesParam,
                maxTime: { value: maxTime, list: [] },
                minTime: { value: minTime, list: [] },
            });
            setLastItemsMaxTime(maxTime);
            setLastItemsMinTime(minTime);
            setLastItemsCount(newLastItemsCount ? TONClient.number(newLastItemsCount) : 0);
        };

        // Events
        const onErrorOccurs = () => {
            controllerService.showServiceUnavailable();
        };

        const onChangeFilter = (key: string, value: any): void => {
            setFilterValues((prevFilterValues: FilterValues) => {
                const newFilterValues = { ...defaultFilterValues, ...prevFilterValues };
                if (key) {
                    newFilterValues[key] = {
                        ...(newFilterValues[key] || prevFilterValues[key] || getDefaultFilterValue(filterProps, key)),
                        value,
                    };
                }

                onChangeFilterCustom(newFilterValues);

                listRef.current?.reload(newFilterValues);

                setParams(TONFilter.getValuesFromFilterValues(newFilterValues, true));
                return newFilterValues;
            });
        };

        const resetAllFilers = () => {
            setFilterValues({});
            setParams({}, false);
            listRef.current?.reload({});
        };

        useImperativeHandle(Ref, () => ({
            unshiftItem,
            unshiftNewItem,
            setItems: (newItems: EVERItem[]) => setItems(newItems),
            componentWillFocus: () => {
                ref?.current && ref.current.componentWillFocus();
            },
            componentWillBlur: () => {
                if (listRef?.current) listRef.current.finishRealtimeUpdate();
                ref?.current && ref.current.componentWillBlur();
            },
            updateFilterValues: (newFilterValues: FilterValues) => {
                setFilterValues((prevFilterValues: any) => {
                    const result = { ...prevFilterValues };
                    Object.keys(newFilterValues).forEach((key) => {
                        const prevFilterValue = prevFilterValues[key];
                        const newFilterValue = newFilterValues[key];
                        const defaultFilterValue = getDefaultFilterValue(filterProps, key);
                        result[key] = {
                            value:
                                prevFilterValue?.value !== null
                                    ? prevFilterValue?.value
                                    : newFilterValue?.value !== null
                                    ? newFilterValue?.value
                                    : defaultFilterValue?.value,
                            list:
                                prevFilterValue?.list !== null && prevFilterValue?.list?.length
                                    ? prevFilterValue?.list
                                    : newFilterValue?.list !== null && newFilterValue?.list?.length
                                    ? newFilterValue?.list
                                    : defaultFilterValue?.list,
                            strict:
                                prevFilterValue?.strict !== null
                                    ? prevFilterValue?.strict
                                    : newFilterValue?.strict !== null
                                    ? newFilterValue?.strict
                                    : defaultFilterValue?.strict,
                        };
                    });

                    return result;
                });
            },
        }));

        const onFocus = (filterValuesParam: { [key: string]: string }) => {
            if (filterValuesParam?.customQuery) {
                customQueryListRef?.current && customQueryListRef?.current?.reload({});
            } else {
                const filterValuesFromURLParams = getFilterValuesFromURLParams(filterValuesParam, filterValues);
                const newFilterValues = { ...filterValues, ...filterValuesFromURLParams };
                setFilterValues(newFilterValues);
                setTimeout(() => {
                    listRef?.current && listRef?.current?.reload(newFilterValues);
                }, 0);
            }
        };

        useEffect(() => {
            if (needToCallOnChangeFilterCustomOnFocus) {
                onChangeFilterCustom(filterValues); // this is needed for ContractDetailsScreen only
            }

            return onWillFocus(params, ref, onFocus);
        }, []);

        // Components
        const tagFilterComponent = (
            <div className={classnames(controllerService.reactContentClassNames, margin.topDefault)}>
                <TagFilter
                    //@ts-ignore doesn`t see partial type
                    playgroundArgs={{
                        ...playgroundArgs,
                        collection: collectionConfigs.collectionKey,
                        direction: sortDirection,
                        queryParams: params,
                        query,
                    }}
                    filterProps={filterProps}
                    filterValues={filterValues}
                    onChange={onChangeFilter}
                    resetAllFilers={resetAllFilers}
                />
            </div>
        );

        const titleComponent = (
            <div className={classnames(controllerService.reactContentClassNames, margin.topSpacious)}>
                <div className={classnames(common.displayFlex, margin.bottomMedium, container.centerLeft)}>
                    <div className={classnames(container.centerLeft, common.displayFlex, flex.flex1)}>
                        {avatarComponent}
                        {leftTitleComponent}
                        <UILabel role={titleRole}>{title}</UILabel>
                    </div>
                    {rightTitleComponent}
                </div>
            </div>
        );

        const accentDetailsTableComponent = (
            <AccentDetailsTable
                containerClassNames={classnames(controllerService.reactContentClassNames, margin.topDefault)}
                detailsList={detailsList}
            />
        );

        const getListComponent = (
            itemsLoaderParam: (args: GetTONItemsArgs, filterValues?: FilterValues) => Promise<EVERItem[]>,
            isCustomQuery: boolean = false
        ) => (
            <EVERList
                // TODO need to remove this scratch
                ref={isCustomQuery ? customQueryListRef : listRef}
                title={shouldHideListTitleOnASC && isAsc ? '' : listTitle}
                titleRole={subtitleRole}
                classNames={margin.topHuge}
                contentClassNames={controllerService.reactContentClassNames}
                realtimeUpdated={realtimeUpdated}
                nothingWasFoundMessage={liveLocalized.NothingWasFound}
                collectionConfigs={collectionConfigs}
                externalControlConfigs={externalControlConfigsResult}
                onChangeSortDirection={(newSortDirection) => {
                    setSortDirection(newSortDirection);
                    if (!isCustomQuery) {
                        setParams({ sortDirection: newSortDirection });
                    }
                }}
                onErrorOccurs={onErrorOccurs}
                itemsLoader={itemsLoaderParam}
                filterValues={filterValues}
                onChangeItems={(newListItems: EVERItem[]) => {
                    setItems(newListItems);
                    onChangeItems(newListItems);
                }}
                onChangeNewItems={(newListItems: EVERItem[]) => {
                    setItems(newListItems);
                    onChangeItems(newListItems);
                }}
                onSubscribeForUpdate={onSubscribeForUpdate}
                onUnsubscribeForUpdate={onUnsubscribeForUpdate}
                onStartReload={(filterValuesParam, args) => {
                    onStartReload(filterValuesParam || {});
                    loadLastItemsCount(filterValuesParam);
                    setPlaygroundArgs(args || {});
                    queryGetter({ ...args, filterValues: filterValuesParam }).then(setQuery);
                }}
            />
        );

        const listComponent = customListComponent || getListComponent(itemsLoader);

        const customContent = (
            <>
                {needTagFilterContainerAboveTitle && tagFilterComponent}
                {beforeTitleComponent}
                {titleComponent}
                {belowTitleComponent}
                {descriptionComponent}
                {accentDetailsTableComponent}
                {customContentComponent}
                {needList && listComponent}
            </>
        );

        return <Screen customContentComponent={customContent} />;
    }
);

export default observer(ListScreen);
