class TONEnvironment {
    static getEnv(value?: string): string {
        return `${value || ''}`.toLowerCase();
    }

    static isDevelopment(): boolean {
        return this.getEnv(process.env.NODE_ENV) === 'development';
    }

    static isProduction(): boolean {
        return this.getEnv(process.env.NODE_ENV) === 'production';
    }
}

export { TONEnvironment };
