import { TONAccountT } from '#TONClient/TONAccount/types';
import { TONClient } from '#TONClient';

import RootKWTTokenContractAbi from './abi/RootTokenContractKWT.abi.json';
import KWTTokenWalletAbi from './abi/TONKWTTokenWallet.abi.json';

import {
    AccountFlexClientTokensQueryType,
    HoldersQueryType,
    StatisticOfTokenQueryType,
    TokenDetailsQueryType,
    TokenQueryType,
    TokenDetailsWalletQueryType,
    TokenStatisticsQueryType,
    WalletAccountTokenQueryType,
    WalletsForAccountQueryType,
} from './types';
import { tokenStatisticsQueryString } from './constants';

export const queryKWTDetails = async (account: TONAccountT) =>
    TONClient.runGetMethods({
        account,
        abi: RootKWTTokenContractAbi,
        methods: ['getName', 'getSymbol', 'getDecimals', 'getTotalSupply'],
    });

export const queryKWTWalletDetails = async (account: TONAccountT) =>
    TONClient.runGetMethods({
        account,
        abi: KWTTokenWalletAbi,
        methods: ['getDetails'],
    });

export const queryTokens = async <T>(filters: string, fields: string): Promise<T | null> => {
    try {
        return TONClient.JSFetchQuery(`
            query {
                ft {
                    tokens(${filters}) {${fields}}
                }
            }
    `);
    } catch (e) {
        return null;
    }
};

export const queryWalletsForAccount = async (
    accountId: string,
    walletFilters: string
): Promise<WalletsForAccountQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
                query {
                    blockchain {
                        account (address: "${accountId}") {
                            info {
                                tokenHolder {
                                    wallets(${walletFilters}) {
                                        pageInfo {
                                            hasNextPage
                                            endCursor
                                        }
                                        nodes {
                                            address
                                            balance
                                            token {
                                                address
                                                decimals
                                                symbol
                                            } 
                                        } 
                                    }
                                }
                            }
                        }
                    } 
                }
    `);
    } catch (e) {
        return null;
    }
};

export const queryWalletAccountToken = async (accountId: string): Promise<WalletAccountTokenQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
                query {
                    ft {
                        wallet (address: "${accountId}") {
                            balance
                            token {
                                address
                                decimals
                                symbol
                            }
                        }
                    }
                }
    `);
    } catch (e) {
        return null;
    }
};

export const queryWallets = async (
    tokenId: string,
    walletFilters: string
): Promise<TokenDetailsWalletQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
            query { 
                ft { 
                    token(address:"${tokenId}") { 
                        decimals
                        wallets(${walletFilters}) { 
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                            edges { 
                                cursor 
                                node { 
                                    address 
                                    balance 
                                    percentage  
                                } 
                            } 
                        } 
                    } 
                } 
            }
    `);
    } catch (e) {
        return null;
    }
};

export const queryAccountFlexClientTokens = async (
    accountId: string
): Promise<AccountFlexClientTokensQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
                query {
                    flex {
                        wallets (clientAddress:"${accountId}") {
                            address
                            userId
                            totalBalance
                            token {
                                decimals
                                ticker
                            }
                        }
                    }
                }
    `);
    } catch (e) {
        return null;
    }
};

export const queryHolders = async (tokenAddress: string, walletFilters: string): Promise<HoldersQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
            query {
                ft{
                    token(address: "${tokenAddress}") {
                        address
                        decimals
                        name
                        symbol
                        wallets(${walletFilters}) {
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                            edges {
                                cursor
                                node {
                                    address
                                    balance
                                    holder {
                                        address
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);
    } catch (e) {
        return null;
    }
};

export const queryTokenDetails = async (tokenAddress: string): Promise<TokenQueryType | null> => {
    try {
        const response = await TONClient.JSFetchQuery<TokenDetailsQueryType>(`
            query {
                ft{
                    token(address: "${tokenAddress}") {
                        address
                        decimals
                        name
                        symbol
                        rootOwner
                        standard
                        totalSupply
                    }
                }
            }
    `);

        return response?.data.ft.token || null;
    } catch (e) {
        return null;
    }
};

export const queryTokenStatistics = async (): Promise<TokenStatisticsQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(tokenStatisticsQueryString);
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const queryStatisticOfToken = async (tokenId: number): Promise<StatisticOfTokenQueryType | null> => {
    return TONClient.JSFetchQuery(`
        query {
            ft {
                token (address: "${tokenId}") {
                    statistics {
                        totalWalletCount
                    }
                }
            }
        }
    `);
};
