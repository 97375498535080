export const color = {
    LightTheme: 'LightTheme',
    DarkTheme: 'DarkTheme',
    TextPrimary: 'TextPrimary',
    TextSecondary: 'TextSecondary',
    TextTertiary: 'TextTertiary',
    TextPrimaryInverted: 'TextPrimaryInverted',
    TextNeutral: 'TextNeutral',
    TextAccent: 'TextAccent',
    TextNegative: 'TextNegative',
    TextPositive: 'TextPositive',
    TextWarning: 'TextWarning',
    TextOverlay: 'TextOverlay',
    TextOverlayInverted: 'TextOverlayInverted',

    BackgroundPrimary: 'BackgroundPrimary',
    BackgroundSecondary: 'BackgroundSecondary',
    BackgroundTertiary: 'BackgroundTertiary',
    BackgroundNeutral: 'BackgroundNeutral',
    BackgroundAccent: 'BackgroundAccent',
    BackgroundNegative: 'BackgroundNegative',
    BackgroundPositive: 'BackgroundPositive',
    BackgroundWarning: 'BackgroundWarning',
    BackgroundPrimaryInverted: 'BackgroundPrimaryInverted',
    BackgroundTertiaryInverted: 'BackgroundTertiaryInverted',
    BackgroundOverlay: 'BackgroundOverlay',
    BackgroundOverlayInverted: 'BackgroundOverlayInverted',

    LinePrimary: 'LinePrimary',
    LineSecondary: 'LineSecondary',
    LineTertiary: 'LineTertiary',
    LineNeutral: 'LineNeutral',
    LineAccent: 'LineAccent',
    LineNegative: 'LineNegative',
    LinePositive: 'LinePositive',

    IconAccent: 'IconAccent',
    IconSecondary: 'IconSecondary',
    IconNeutral: 'IconNeutral',

    Transparent: 'Transparent',
    KeyboardStyle: 'KeyboardStyle',

    Shadow: 'Shadow',
};
