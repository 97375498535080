import React from 'react';
import { BalanceView } from '#components';
import type { Tons, TonsNumber } from '#TONClient/TONClient';

type Props = {
    balance?: TonsNumber | Tons;
    hex?: boolean;
    notConvert?: boolean;
};

const LongBalanceView: React.FC<Props> = ({ balance = 0, hex = false, notConvert = false }) => (
    <BalanceView hex={hex} balance={balance} notConvert={notConvert} fixFractional fractionalDigits={9} />
);

export default LongBalanceView;
