import React, { useEffect, useRef, useState } from 'react';

const useTap = (initRef?: React.RefObject<HTMLElement>): [React.RefObject<HTMLElement>, boolean] => {
    const ref = useRef<HTMLElement>(null);

    const [isTap, setIsTap] = useState(false);

    useEffect(() => {
        const targetRef = initRef || ref;

        if (targetRef.current) {
            const onMouseDownHandler = () => {
                setIsTap(true);

                const onMouseUpHandler = () => {
                    setIsTap(false);
                    document.removeEventListener('mouseup', onMouseUpHandler);
                };
                document.addEventListener('mouseup', onMouseUpHandler);
            };

            targetRef.current?.addEventListener('mousedown', onMouseDownHandler);

            return () => targetRef.current?.removeEventListener('mousedown', onMouseDownHandler);
        }
    }, [ref.current, initRef?.current]);

    return [ref, isTap];
};

export { useTap };
