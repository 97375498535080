import { TONAccount, TONClient } from '#TONClient';
import TONFilter, { FilterValues } from '#TONClient/TONFilter';
import { TONAccountT } from '#TONClient/TONAccount/types';
import { getEndCursor, getOtherFilters, getPaginationFiltersString, loadDetailsBySteps } from '#TONClient/helpers';
import { NOT_FOUND } from '#TONClient/statuses';
import { TONLog, TONString } from '#TONUtility';
import Utils from '#helpers/Utils';

import {
    queryAccountFlexClientTokens,
    queryHolders,
    queryKWTDetails,
    queryKWTWalletDetails,
    queryStatisticOfToken,
    queryTokenDetails,
    queryTokens,
    queryTokenStatistics,
    queryWalletAccountToken,
    queryWalletsForAccount,
    queryWallets,
} from './queries';
import { KWTRootAddress, KWTWalletsCodeHash, searchTokensField, TOKENS_ORDER_BY, TokenStandards } from './constants';
import {
    AccountWallet,
    AccountWalletVariants,
    GetHolderArgs,
    GetTokenArgs,
    KWTWalletDetailsFromRunGetMethods,
    SearchTokenResult,
    SearchTokensType,
    StatisticOfTokenType,
    Tip3Token,
    TokenHolder,
    TokenQueryType,
    TokenStatisticsType,
    TokenDetailsWallet,
} from './types';

const getTokenFilters = (args: GetTokenArgs, filterValues?: FilterValues) => {
    const { direction, limit, lastItem } = args;
    const paginationFilters = getPaginationFiltersString(
        direction || 'DESC',
        limit || TONClient.itemsLoadingMax,
        lastItem?.endCursor
    );
    const otherFilters = getOtherFilters([
        { value: filterValues?.symbol?.value, label: 'symbolSubstring' },
        { value: filterValues?.name?.value, label: 'namePrefix' },
        { value: TOKENS_ORDER_BY.lastTransferFirst, label: 'orderBy', wrapInBraces: false },
    ]);

    return `${paginationFilters}${otherFilters}`;
};

const getPreparedToken = (token: TokenQueryType): Tip3Token => ({
    name: token.name,
    symbol: token.symbol,
    rootAddress: token.address,
    rootOwnerAddress: token.rootOwner,
    totalSupply: TONString.getFormattedBalanceByDecimals(token.totalSupply, token.decimals),
    decimals: token.decimals,
    standard: token.standard === 'TIP_32' ? 'TIP-3.1' : token.standard,
});

const getToken = async (tokenAddress: string): Promise<Tip3Token | null> => {
    const tokenResult = await queryTokenDetails(tokenAddress);

    if (tokenResult) return getPreparedToken(tokenResult);
    else return null;
};

const getKWTData = async (log: TONLog): Promise<Tip3Token | null> => {
    try {
        //@ts-ignore doesn`t see method
        const account: TONAccountT = await TONAccount.getAccountsForRunTVM([KWTRootAddress]).then((res) => res[0]);
        if (!account) {
            log.debug(NOT_FOUND);
            return null;
        }

        const response = await queryKWTDetails(account);

        if (response) {
            const decimals = TONClient.number(response[2]?.value0);

            return {
                rootAddress: account.id,
                name: response[0]?.value0,
                symbol: response[1]?.value0,
                rootOwnerAddress: '',
                decimals,
                standard: TokenStandards.KWT,
                totalSupply: TONString.getFormattedBalanceByDecimals(response[3]?.value0 || 0, decimals),
            };
        } else return null;
    } catch (e) {
        return null;
    }
};

const getKWTWalletDetails = async (account: TONAccountT): Promise<KWTWalletDetailsFromRunGetMethods | null> => {
    try {
        const response = await queryKWTWalletDetails(account);
        const item = response?.[0];

        if (item) {
            return {
                walletAddress: account.id,
                rootAddress: item.root_address,
                balance: TONClient.number(item.balance),
            };
        } else return null;
    } catch (e) {
        return null;
    }
};

const getKWTWallets = async (token: Tip3Token, log: TONLog): Promise<TokenHolder[] | null> => {
    try {
        const walletDocs = await TONClient.getAcDocsByCodeHashes([KWTWalletsCodeHash]);

        if (!token || !walletDocs?.length) {
            log.debug(NOT_FOUND);
            return null;
        }
        log.debug('KWT wallet docs', walletDocs);

        const subLists = Utils.divideBySubLists(walletDocs, 1000);

        const walletsFromRunGetMethods = await loadDetailsBySteps<KWTWalletDetailsFromRunGetMethods | null>(
            getKWTWalletDetails,
            subLists,
            log
        );

        //@ts-ignore doesn`t see not nullable filtration
        return walletsFromRunGetMethods
            .filter((wallet) => wallet && wallet.balance)
            .map((wallet) => ({
                ...wallet,
                name: token.name,
                symbol: token.symbol,
                decimals: token.decimals,
                //@ts-ignore doesn`t see not nullable filtration
                balance: TONString.getFormattedBalanceByDecimals(wallet.balance, token.decimals),
            }));
    } catch (e) {
        log.error(e);
        return null;
    }
};

const getWalletsForAccount = async (accountId: string): Promise<AccountWallet[] | null> => {
    try {
        let wallets: AccountWallet[] = [];

        const setWallets = async (lastWalletCursor?: string) => {
            const walletFilters = getPaginationFiltersString('DESC', 50, lastWalletCursor);
            const response = await queryWalletsForAccount(accountId, walletFilters);
            const walletsResult = response?.data?.blockchain.account.info.tokenHolder.wallets;

            if (walletsResult) {
                const {
                    nodes,
                    pageInfo: { hasNextPage, endCursor },
                } = walletsResult;

                const newWallets = nodes.map(({ balance, token: { address: rootAddress, decimals, symbol } }) => ({
                    symbol,
                    balance: TONString.getFormattedBalanceByDecimals(balance, decimals),
                    rootAddress,
                    decimals,
                }));

                wallets = [...wallets, ...newWallets];

                if (hasNextPage) await setWallets(endCursor);
            } else return;
        };

        await setWallets();

        return wallets;
    } catch (e) {
        return null;
    }
};

const getWalletAccountToken = async (accountID: string): Promise<AccountWallet | null> => {
    try {
        const response = await queryWalletAccountToken(accountID);
        const wallet = response?.data?.ft.wallet;

        if (wallet) {
            const { symbol, decimals, address: rootAddress } = wallet.token;

            return {
                symbol,
                balance: TONString.getFormattedBalanceByDecimals(wallet.balance, decimals),
                decimals,
                rootAddress,
            };
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
};

const getWallets = async (args: GetHolderArgs, tokenAddress: string): Promise<TokenDetailsWallet[] | null> => {
    if (!tokenAddress) return null;

    try {
        const { lastItem, direction, limit } = args;
        const walletFilters = getPaginationFiltersString(
            direction || 'DESC',
            limit || TONClient.itemsLoadingMax,
            lastItem?.endCursor
        );

        const response = await queryWallets(tokenAddress, walletFilters);
        const token = response?.data?.ft.token;

        if (token) {
            const { decimals, wallets } = token;
            const { endCursor: pageEndCursor } = wallets.pageInfo;

            const tokenWallets = wallets.edges.map(({ node: { address: walletAddress, balance, percentage } }) => ({
                walletAddress,
                balance: TONString.getFormattedBalanceByDecimals(balance, decimals),
                percentage,
                decimals,
            }));

            return tokenWallets.map((wallet, index) => ({
                ...wallet,
                ...(index === tokenWallets.length - 1
                    ? { endCursor: getEndCursor(direction || 'DESC', token.wallets.edges[0].cursor, pageEndCursor) }
                    : {}),
            }));
        } else return null;
    } catch (e) {
        return null;
    }
};

const getAccountFlexClientTokens = async (accountID: string): Promise<AccountWallet[] | null> => {
    try {
        const response = await queryAccountFlexClientTokens(accountID);
        const wallets = response?.data?.flex.wallets;

        if (wallets) {
            return wallets
                .sort((wallet1, wallet2) => (wallet1.userId > wallet2.userId ? 1 : -1))
                .map(({ address, totalBalance, token: { decimals, ticker } }) => ({
                    symbol: ticker,
                    balance: TONClient.number(totalBalance),
                    decimals,
                    rootAddress: address,
                    variant: AccountWalletVariants.FlexClient,
                }));
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }
};

const getHolders = async (args: GetHolderArgs, tokenAddress: string): Promise<TokenHolder[] | null> => {
    if (!tokenAddress) return null;

    try {
        const { lastItem, direction, limit } = args;
        const walletFilters = getPaginationFiltersString(
            direction || 'DESC',
            limit || TONClient.itemsLoadingMax,
            lastItem?.endCursor
        );
        const response = await queryHolders(tokenAddress, walletFilters);
        const token = response?.data?.ft.token;

        if (token) {
            const { endCursor: pageEndCursor } = token.wallets.pageInfo;
            const { address: rootAddress, decimals, name, symbol } = token;

            const wallets = token.wallets.edges.map(
                ({
                    node: {
                        address: walletAddress,
                        balance,
                        holder: { address: ownerAddress },
                    },
                }) => ({
                    rootAddress,
                    ownerAddress,
                    balance: TONString.getFormattedBalanceByDecimals(balance, decimals),
                    decimals,
                    name,
                    symbol,
                    walletAddress,
                })
            );
            TONFilter.sortBy(wallets, args, 'balance');

            return wallets.map((wallet, index) => ({
                ...wallet,
                ...(index === wallets.length - 1
                    ? { endCursor: getEndCursor(direction || 'DESC', token.wallets.edges[0].cursor, pageEndCursor) }
                    : {}),
            }));
        } else return null;
    } catch (e) {
        return null;
    }
};

const findTokens = async (searchExpression: string): Promise<SearchTokenResult[] | null> => {
    const namePrefixFilter = `namePrefix: "${searchExpression}",`;

    try {
        let tokens: SearchTokenResult[] = [];

        const setTokens = async (lastTokenCursor?: string) => {
            const paginationFilters = getPaginationFiltersString('DESC', 50, lastTokenCursor);
            const filters = `${namePrefixFilter}${paginationFilters}`;

            const response = await queryTokens<SearchTokensType>(filters, searchTokensField);
            const tokensResult = response?.data?.ft.tokens;

            if (tokensResult) {
                const {
                    nodes,
                    pageInfo: { hasNextPage, endCursor },
                } = tokensResult;

                const newTokens = nodes.map(({ address, symbol }) => ({ rootAddress: address, symbol }));

                tokens = [...tokens, ...newTokens];

                if (hasNextPage) await setTokens(endCursor);
            } else return;
        };

        await setTokens();

        return tokens;
    } catch (e) {
        return null;
    }
};

const getTokenStatistics = async (): Promise<TokenStatisticsType | null> => {
    try {
        const response = await queryTokenStatistics();
        const statistics = response?.data?.ft.statistics[0];

        if (statistics) {
            return {
                numberOfTokens: statistics.totalTokenCount,
                numberOfWallets: statistics.totalWalletCount,
            };
        } else return null;
    } catch (e) {
        return null;
    }
};

const getStatisticOfToken = async (tokenId: number): Promise<StatisticOfTokenType | null> => {
    try {
        const response = await queryStatisticOfToken(tokenId);
        const statistics = response?.data?.ft.token.statistics;

        if (statistics) {
            return { numberOfHolders: statistics.totalWalletCount };
        } else return null;
    } catch (e) {
        return null;
    }
};

export {
    getPreparedToken,
    getToken,
    getKWTData,
    getKWTWalletDetails,
    getKWTWallets,
    getWalletsForAccount,
    getWalletAccountToken,
    getAccountFlexClientTokens,
    getHolders,
    findTokens,
    getTokenFilters,
    getTokenStatistics,
    getStatisticOfToken,
    getWallets,
};
