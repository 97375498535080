// @flow
import React from 'react';
import { BalanceView } from '#components';

type Props = {};

const GasView = (props: Props) => {
    return <BalanceView {...props} hideIcon hex notConvert fractionalDigits={0} />;
};

export default GasView;
