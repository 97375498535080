import React from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONClient } from '#TONClient';
import type { TONStakeT } from '#TONClient/TONMessage/types';
import MomentHelper from '#helpers/MomentHelper';
import { BalanceView, LinkWithChild, UIDetailsButton } from '#components';
import { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import { paths } from '#navigation/paths';

type Props = {
    item: Maybe<TONStakeT>;
};

export const stakeViewTestIDs = {
    stakeView: 'stakeView',
};

const StakeView = ({ item }: Props) => {
    if (!item) return null;

    const { id, stakeAt, amount, type, created_at } = item;
    const timeDate = MomentHelper.getTimeDate(created_at);

    const narrow = controllerService.isNarrow;
    const secondDetails = stakeAt
        ? narrow
            ? type
            : `${liveLocalized.ElectionsID}: ${stakeAt} | ${liveLocalized.Type}: ${type}`
        : narrow
        ? type
        : `${liveLocalized.Type}: ${type}`;

    return (
        <LinkWithChild navigationPath={paths.transactions.details} params={{ id: item.id }}>
            <UIDetailsButton
                testID={stakeViewTestIDs.stakeView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                truncTitle
                title={id}
                captionComponent={<BalanceView balance={amount || '0'} fixFractional />}
                details={timeDate}
                secondDetails={secondDetails}
            />
        </LinkWithChild>
    );
};

StakeView.externalConfigs = {
    items: null,
    sortingKey: 'created_at',
};

StakeView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: { id: string }): string => {
            return `stake-view-${id}`;
        },
        idKey: 'id',
        newItemName: 'NewStake',
        collectionName: liveLocalized.Stake,
        //@ts-ignore doesn`t calculate right type
        collectionKey: TONClient.collections.transactions,
        renderItem: ({ item }: { item: any }) => <StakeView item={item} />,
    };
};

export default observer(StakeView);
