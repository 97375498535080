import { action, makeAutoObservable, observable } from 'mobx';

import type { RustCupParticipant, TONDePoolT } from '#TONClient/TONDePool/types';
import type { TONMasterConfig } from '#TONClient/EVERBlock/types';
import type { NFTsCollection, NFTsCollectionRTW } from '#TONClient/EVERNft/types';

class DataService {
    @observable masterConfigWithValidators: TONMasterConfig | null = null;
    @observable cleanMasterConfigWithValidators: TONMasterConfig | null = null;
    @observable dePools: TONDePoolT[] | null = null;
    @observable rustCupParticipants: RustCupParticipant[] = [];
    @observable rustCupData: { contestStart: number; contestEnd: number; contestPeriod: number } = {
        contestStart: 0,
        contestEnd: 0,
        contestPeriod: 0,
    };
    @observable rustCupTotalPointsParticipants: any[] = [];

    @observable NFTCollections: NFTsCollectionRTW[] | null = null;
    @observable NFTCollectionsByIdWithTokens: { [id: string]: NFTsCollection } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setMasterConfigWithValidators = (param: TONMasterConfig) => {
        this.masterConfigWithValidators = param;
    };

    @action
    setCleanMasterConfigWithValidators = (param: TONMasterConfig) => {
        this.cleanMasterConfigWithValidators = param;
    };

    @action
    setDePools = (dePoolsParam: TONDePoolT[]) => {
        this.dePools = dePoolsParam;
    };

    @action
    setRustCupParticipants = (rustCupParticipants: RustCupParticipant[]) => {
        this.rustCupParticipants = rustCupParticipants;
    };

    @action
    setRustCupData = (rustCupData: { contestStart: number; contestEnd: number; contestPeriod: number }) => {
        this.rustCupData = rustCupData;
    };

    @action
    setRustCupTotalPointsParticipants = (participants: any[]) => {
        this.rustCupTotalPointsParticipants = participants;
    };

    @action
    setNftCollectionByIdWithTokens = (nftCollection: NFTsCollection, id: string) => {
        this.NFTCollectionsByIdWithTokens[id] = nftCollection;
    };

    @action
    setNFTCollections = (collections: NFTsCollectionRTW[]) => {
        this.NFTCollections = collections;
    };
}

const dataService = new DataService();

export default dataService;
