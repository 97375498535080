import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { themesService } from '@services/ThemesService';
import { ActionImage, Spinner, UILabel, UIShareManager, UITextButton } from '#components';
import { blockingLinkElementID } from '#components/Link/LinkWithChild/LinkWithChild';
import Utils from '#helpers/Utils';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex } from '#uikit/designCore/styles/flex';
import { height } from '#uikit/designCore/styles/height';
import { margin } from '#uikit/designCore/styles/margin';
import UIColorPalette from '#uikit/designCore/color/pallete';

import type { ActionProps } from '../UIActionComponent/ReactActionComponent';
import UIActionComponent from '../UIActionComponent/ReactActionComponent';

import './UIDetailsButton.scss';

type Props = ActionProps & {
    progress: boolean;
    transparent: boolean;
    index: number | null;
    image?: string;
    title: string;
    truncTitle: boolean;
    caption: string;
    truncCaption: boolean;
    details: string;
    detailsPrefix: string;
    secondDetails?: string;
    truncDetails?: boolean;
    titleComponent?: JSX.Element;
    captionComponent?: JSX.Element;
    customComponent?: JSX.Element;
    titleIsText?: boolean;
    copyTarget: string | null;
    copyValue: string | null;
    isTapped: boolean;
    isHover: boolean;
    containerClassNames: string;
};

export const UIDetailsButtonTestIds = {
    title: 'title',
    details: 'details',
    secondDetails: 'secondDetails',
    caption: 'caption',
};

export const UIDetailsButtonCopyTargets = {
    title: 'title',
    details: 'details',
};

const uiDetailsButtonTestIDs = {
    copyButton: 'copyButton',
};

const UIDetailsButton: React.FC<Props> = ({
    disabled = false,
    titleIsText,
    titleComponent,
    captionComponent,
    secondDetails,
    customComponent,
    progress = false,
    index = null,
    image = null,
    title = '',
    truncTitle = false,
    caption = '',
    truncCaption = false,
    truncDetails = false,
    details = '',
    detailsPrefix = '',
    copyTarget = null,
    copyValue = null,
    isTapped,
    isHover,
    onPress,
    containerClassNames,
}) => {
    const narrow = controllerService.isNarrow;

    // Getters
    const titleColor =
        disabled || titleIsText
            ? ColorVariants.TextSecondary
            : isHover || isTapped
            ? ColorVariants.TextAccent
            : ColorVariants.TextPrimary;

    // Events
    const onPressCopy = useCallback(() => {
        const str = copyTarget === UIDetailsButtonCopyTargets.title ? title : details || '';
        UIShareManager.copyToClipboard(copyValue || str, liveLocalized.CopiedToClipboard);
    }, [copyTarget, title, details, copyValue]);

    const progressCardComponent = <Spinner classNames="progress-spinner" />;

    const copyButton = narrow ? null : (
        <div
            className={classNames('copy-button', {
                visible: isHover,
            })}
            id={blockingLinkElementID}
            data-nativeid={blockingLinkElementID}
            data-testid={uiDetailsButtonTestIDs.copyButton}
        >
            <ActionImage
                Img={ContentCopyIcon}
                fill={themesService.isDarkTheme ? UIColorPalette.text.darkPrimary : UIColorPalette.text.lightPrimary}
                fillOnHover={
                    themesService.isDarkTheme ? UIColorPalette.text.darkAccent : UIColorPalette.text.lightAccent
                }
                onClick={onPressCopy}
                fontSize="small"
            />
        </div>
    );

    const getFormattedText = (str: string) => Utils.truncTextToNarrow(str);
    const formattedCaption = truncCaption ? getFormattedText(caption) : caption;
    const captionText = !caption ? null : (
        <UILabel
            testID={UIDetailsButtonTestIds.caption}
            role={TypographyVariants.ParagraphNote}
            color={ColorVariants.TextPrimary}
        >
            {formattedCaption}
        </UILabel>
    );
    const formattedTitle = truncTitle ? getFormattedText(title) : title;

    const titleCaptionComponent = (
        <div className="row-container">
            {!!formattedTitle &&
                (narrow ? (
                    //@ts-ignore
                    <UITextButton
                        testID={UIDetailsButtonTestIds.title}
                        buttonHeightClassName={height.smallCell}
                        titleColor={titleColor}
                        title={formattedTitle}
                        onPress={onPress}
                    />
                ) : (
                    <div className="label-container">
                        <UILabel
                            testID={UIDetailsButtonTestIds.title}
                            role={TypographyVariants.Action}
                            color={titleColor}
                        >
                            {formattedTitle}
                        </UILabel>
                        {copyTarget === UIDetailsButtonCopyTargets.title ? copyButton : null}
                    </div>
                ))}
            {titleComponent}
            {!formattedTitle && !titleComponent && <div className="title-replacer" />}
            {captionText}
            {captionComponent}
        </div>
    );

    const detailsComponent = !!(details || secondDetails) && (
        <div className="details-component row-container">
            <div className="details-component__first-item" data-testid={UIDetailsButtonTestIds.details}>
                <UILabel
                    classNames={flex.flex1}
                    color={ColorVariants.TextSecondary}
                    role={TypographyVariants.ParagraphFootnote}
                >
                    {detailsPrefix}
                    {truncDetails ? getFormattedText(details) : details}
                </UILabel>
                {copyTarget === UIDetailsButtonCopyTargets.details ? copyButton : null}
            </div>
            <UILabel
                testID={UIDetailsButtonTestIds.secondDetails}
                color={ColorVariants.TextSecondary}
                role={TypographyVariants.ParagraphFootnote}
            >
                {secondDetails}
            </UILabel>
        </div>
    );

    const contentCardComponent = (
        <div className="content-card-component">
            {customComponent}
            {titleCaptionComponent}
            {detailsComponent}
        </div>
    );

    const renderCard = progress ? (
        progressCardComponent
    ) : (
        <div className="card-container">
            {index !== null && (
                <UILabel
                    classNames={classNames(narrow ? margin.rightSmall : margin.rightDefault)}
                    color={ColorVariants.TextPrimary}
                    role={TypographyVariants.Action}
                >
                    {index}.
                </UILabel>
            )}
            {!!image && <img alt="img" src={image} className={`card-img ${narrow ? '' : 'large-margin'}`} />}
            {contentCardComponent}
        </div>
    );

    return (
        <div className={classNames('UIDetailsButton', containerClassNames)}>
            <div
                className="background"
                style={{ backgroundColor: themesService.isDarkTheme ? 'rgba(41,47,50, 0.6)' : '#F4F4F5' }}
            />
            {renderCard}
        </div>
    );
};

const UIComponentDetailsButton = (props: any) => {
    const narrow = controllerService.isNarrow;
    const actionProps = {
        ...props,
        onPress: narrow ? () => {} : props.onPress,
    };

    return (
        <UIActionComponent {...actionProps}>
            <UIDetailsButton {...props} />
        </UIActionComponent>
    );
};

export default observer(UIComponentDetailsButton);
