import React from 'react';
import classnames from 'classnames';

import { border } from '#uikit/designCore/styles';

type Props = {
    focused: boolean;
    className: string;
    hover: boolean;
};

export const TextViewWrapper: React.FC<Props> = ({ focused, hover, children, className }) => {
    const borderBottomColor = React.useMemo((): string => {
        return (focused && border.colorAccent) || (hover && border.colorPrimaryInverted) || border.colorNeutral;
    }, [focused, hover]);

    return <div className={classnames(className, border.bottom, border.solid, borderBottomColor)}>{children}</div>;
};
