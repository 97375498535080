import { getPaginationFiltersString } from '#TONClient/helpers';
import TONClient from '#TONClient/TONClient';
import { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import type { FilterValues } from '#TONClient/TONFilter';

const getTransferDetailsQueryFilters = (messageId: string) => `messageIds: ["${messageId}"]`;

const getFiltersForAccountTransfers = (args: GetTransactionsArgs, filterValues: FilterValues): string => {
    const { direction, limit, lastItem } = args;
    const paginationFilters = getPaginationFiltersString(
        direction || 'DESC',
        limit || TONClient.itemsLoadingMax,
        lastItem?.endCursor
    );

    const type = filterValues.transferType.value;
    const typeFilter = type ? `transferTypes: ${type},` : '';

    return `${paginationFilters}${typeFilter}`;
};

export { getFiltersForAccountTransfers, getTransferDetailsQueryFilters };
