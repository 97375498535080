import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TIP3TokenService } from '@services/DataServices/TIP3Tokens';
import { EVERTip3, TONFilterTags } from '#TONClient';
import type { Tip3Token } from '#TONClient/EVERTip3/types';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import { getDetails } from '#controllers/Details/Tip3Token/helpers';
import TokenHoldersView from '#components/EVERList/ListViews/Tokens/TokenHoldersView';

const KWTComponent: React.FC = () => {
    const ref = useRef(null);

    const [params] = useParams();

    const [token, setToken] = useState<Tip3Token | null>(null);

    const holders = TIP3TokenService.KWTWallets
        ? TIP3TokenService.KWTWallets.map((holder, index) => ({ ...holder, index }))
        : null;

    const onLoadToken = async () => {
        controllerService.showSpinnerOverlay();

        const tokenResult = await EVERTip3.getKWT();
        setToken(tokenResult);

        EVERTip3.fetchKWTWallets();

        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadToken);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            title="KWT"
            copyableViewData={{ value: '' }}
            listTitle={`${liveLocalized.KWTWallets}`}
            details={getDetails(token)}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            needListInDetails
            //@ts-ignore
            filterProps={TONFilterTags.getTokenDetailsProps()}
            collectionConfigs={TokenHoldersView.getCollectionConfigs(token?.totalSupply)}
            externalControlConfigs={{
                ...TokenHoldersView.KWTExternalConfigs,
                items: holders || [],
                load25more: true,
                isLoading: !holders,
            }}
        />
    );
};

const KWT = observer(KWTComponent);

export { KWT };
