import React from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import './MobilePopover.scss';

type Props = {
    open: boolean;
    onClose: () => void;
};

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement<any, any> }>(
    (props, ref) => <Slide direction="up" ref={ref} {...props} />
);

const MobilePopover: React.FC<Props> = ({ open, onClose, children }) => (
    <Dialog
        open={open}
        onClose={onClose}
        keepMounted
        TransitionProps={{ timeout: 500, easing: 'cubic-bezier(.44,1.24,.77,1.02)' }}
        TransitionComponent={Transition}
        children={children}
        classes={{ root: 'MobilePopover' }}
    />
);

export default MobilePopover;
