const formatDECField = '(format: DEC)';

const transactionShortFields = `
    id
    now
    tr_type
    tr_type_name
    account_addr
    balance_delta${formatDECField}
`;

export { formatDECField, transactionShortFields };
