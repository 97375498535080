import React, { ReactNode } from 'react';

import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { UILabel } from '#components';

type Props = {
    children: ReactNode;
};

const Caption = (props: Props) => (
    <UILabel role={TypographyVariants.ParagraphNote} color={ColorVariants.TextSecondary}>
        {props.children}
    </UILabel>
);

export default Caption;
