import React from 'react';
import classnames from 'classnames';

import type { SortDirection } from '#TONClient/TONFilter';
import { container } from '#uikit/designCore/styles/container';
import { common } from '#uikit/designCore/styles/comon';

import './SortSwitcher.scss';

type Props = {
    sortDirection: SortDirection;
    disabled?: boolean;
    unActive?: boolean;
    onPress: () => void;
    classNames?: string;
};

const sortSwitcherTestIDs = {
    sortingButton: 'sortingButton',
};

const SortSwitcher = ({ sortDirection, disabled = false, unActive = false, onPress, classNames }: Props) => (
    <div
        className={classnames('SortSwitcher', classNames, container.center, common.icon)}
        data-testid={sortSwitcherTestIDs.sortingButton}
        onClick={onPress}
    >
        <img
            src={require('assets/ico-blue-arrow/arrow-down.png').default}
            alt="sort"
            className={classnames(common.icon, common.cursorPointer, {
                rotate: sortDirection === 'DESC',
                disabled: disabled || unActive,
            })}
        />
    </div>
);

export default SortSwitcher;
