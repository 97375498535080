import { TONClient } from '#TONClient';
import { OnErrorCallbackType } from '#TONClient/types';

import { TransfersByMessageIDResponseType, AccountTransferQueryType } from './types';

const queryTransferDetailsByMessageID = async (
    filters: string,
    fields: string,
    onError: OnErrorCallbackType = () => {}
): Promise<TransfersByMessageIDResponseType | null> => {
    try {
        return TONClient.JSFetchQuery<TransfersByMessageIDResponseType>(`
            query {
                ft {
                    transfersByMessageIds(${filters}) {${fields}}
                }
            }
        `);
    } catch (e) {
        onError(e);
        return null;
    }
};

const queryTransfersForAccount = async (
    accountId: string,
    filters: string,
    onError: OnErrorCallbackType = () => {}
): Promise<AccountTransferQueryType | null> => {
    try {
        return TONClient.JSFetchQuery(`
                query {
                    blockchain {
                        account(address: "${accountId}") {
                            info {
                                tokenHolder {
                                    transfers(${filters}) {
                                        pageInfo {
                                            endCursor
                                        }
                                        edges {
                                            cursor
                                            node {
                                                transferType
                                                messageId
                                                token {
                                                    symbol
                                                    decimals
                                                }
                                                fromHolder {
                                                    address
                                                }
                                                toHolder {
                                                    address
                                                }
                                                timestamp
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        `);
    } catch (e) {
        onError(e);
        return null;
    }
};

export { queryTransferDetailsByMessageID, queryTransfersForAccount };
