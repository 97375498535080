export const common = {
    backgroundTransparent: 'backgroundTransparent',
    noOpacity: 'noOpacity',
    opacity30: 'opacity30',
    opacity70: 'opacity70',
    fullOpacity: 'fullOpacity',
    displayNone: 'displayNone',
    displayFlex: 'displayFlex',
    overflowHidden: 'overflowHidden',
    overflowVisible: 'overflowVisible',
    flexWrap: 'flexWrap',
    dismissStripe: 'dismissStripe',
    icon: 'icon',
    positionRelative: 'positionRelative',
    positionAbsolute: 'positionAbsolute',
    profilePhoto: 'profilePhoto',
    'zIndex-1': 'zIndex-1',
    cursorPointer: 'cursorPointer',
    zIndex1: 'zIndex1',
    positionAbsoluteCenter: 'positionAbsoluteCenter',
    wordBreakALl: 'wordBreakALl',
    displayInlineBlock: 'displayInlineBlock',
    defaultContentOffsetRight: 'defaultContentOffsetRight',
    defaultContentOffsetLeft: 'defaultContentOffsetLeft',
    textTransformCapitalize: 'textTransformCapitalize',
    UIBackgroundView: 'UIBackgroundView',
};
