import React from 'react';

import { common } from '#uikit/designCore/styles/comon';
//@ts-ignore doesn`t see import
import toggleOn from '#assets/ico-toggle-on/ico-toggle-on.png';
//@ts-ignore doesn`t see import
import toggleOff from '#assets/ico-toggle-off/ico-toggle-off.png';

type Props = {
    active: boolean;
    onClick: (turnOn?: boolean) => void;
    testID?: string;
};

const UIToggle: React.FC<Props> = ({ active = false, onClick = () => {}, testID }) => (
    <div className={common.cursorPointer} data-testid={testID} onClick={() => onClick(!active)}>
        <img src={active ? toggleOn : toggleOff} alt="toggle" />
    </div>
);

export default UIToggle;
