import { liveLocalized } from '@services/LocalizationService';

export const ErrorScreenTestID = 'ErrorScreen';

export enum errorCodes {
    pageNotfound = '404',
    serviceUnavailable = '000',
}

export const errors = {
    [errorCodes.pageNotfound]: {
        title: '404',
        caption: liveLocalized.WeCanTFindThePageYouReLookingFor,
    },
    [errorCodes.serviceUnavailable]: {
        title: liveLocalized.WelcomeTo000,
        caption: liveLocalized.TheRequestedServiceIsDownToGetUpAsapTryAgainLater,
    },
};

export const testIDs = {
    BACK_TO_HOME_BUTTON: `${ErrorScreenTestID}-back-to-home-button`,
};
