import { DocumentSnapshot, QuerySnapshot } from '@firebase/firestore-types';
import { NFTItem, NFTsCollection, NFTsCollectionRTW } from '#TONClient/EVERNft/types';
import EnvManager from '#helpers/EnvManager';
import { TONLog } from '#TONUtility';

import FBTemplate from '../FBTemplate';

const customLog = new TONLog('FB_NFT');

export default class FB_NFT extends FBTemplate {
    static collectionName = 'nft';

    private static network = EnvManager.getNetwork();

    private static collectionsListCollectionName = 'collections';
    private static tokensListCollectionName = 'tokens';
    private static maxTimeWithoutUpdateCollectionsList = 9_000_00;
    private static maxTimeWithoutUpdateCollection = 9_000_00;

    public static async checkNeedUpdateCollectionsListCache(): Promise<boolean | null> {
        try {
            const lastUpdateTime = await FB_NFT.getCollection()
                .doc(FB_NFT.network)
                .get()
                .then((doc: DocumentSnapshot<{ updated: number }>) => doc.data()?.updated);

            customLog.debug(`Loaded collections lastUpdateTime ----->`, lastUpdateTime);

            return !lastUpdateTime || Date.now() - lastUpdateTime > FB_NFT.maxTimeWithoutUpdateCollectionsList;
        } catch (e) {
            return null;
        }
    }

    public static async checkNeedUpdateListCache(collectionId: string): Promise<boolean | null> {
        try {
            const collectionRef = FB_NFT.getCollection();
            const collectionData = await collectionRef
                .doc(FB_NFT.network)
                .collection(FB_NFT.collectionsListCollectionName)
                .doc(collectionId)
                .get()
                .then((doc: DocumentSnapshot<{ [field: string]: string }>) => doc.data());
            const lastUpdateTime = collectionData?.updated;

            customLog.debug(`Loaded collection: ${collectionId} lastUpdateTime ----->`, lastUpdateTime);

            return !lastUpdateTime || Date.now() - lastUpdateTime > FB_NFT.maxTimeWithoutUpdateCollection;
        } catch (e) {
            return null;
        }
    }

    public static async getCollectionsList(): Promise<NFTsCollectionRTW[] | null> {
        try {
            const collections: NFTsCollectionRTW[] = await FB_NFT.getCollection()
                .doc(FB_NFT.network)
                .collection(FB_NFT.collectionsListCollectionName)
                .get()

                .then((docs: QuerySnapshot<NFTsCollectionRTW>) => docs.docs.map((doc) => doc.data()));

            customLog.debug(`Loaded collections ----->`, collections);

            return collections;
        } catch (e) {
            return null;
        }
    }

    public static async getCollectionData(collectionId: string): Promise<NFTsCollection | null> {
        try {
            const collectionRef = FB_NFT.getCollection()
                .doc(FB_NFT.network)
                .collection(FB_NFT.collectionsListCollectionName)
                .doc(collectionId);

            const collectionData = await collectionRef.get().then((doc: DocumentSnapshot<NFTItem>) => doc.data());
            const tokens = await collectionRef
                .collection(FB_NFT.tokensListCollectionName)
                .get()
                .then((docs: QuerySnapshot<NFTItem>) => docs.docs.map((doc) => doc.data()));
            const preparedCollectionData = { ...collectionData, tokens };

            customLog.debug(`Loaded collection: ${collectionId} ----->`, preparedCollectionData);
            return preparedCollectionData;
        } catch (e) {
            return null;
        }
    }

    public static updateCollectionsListCache = async (collections: NFTsCollectionRTW[]): Promise<void> => {
        try {
            Promise.all(
                collections.map((collection) => {
                    const collectionDoc = FB_NFT.getCollection()
                        .doc(FB_NFT.network)
                        .collection(FB_NFT.collectionsListCollectionName)
                        .doc(collection.id);

                    return collectionDoc.update(collection).catch(() => collectionDoc.set(collection));
                })
            )
                .then(() => FB_NFT.getCollection().doc(FB_NFT.network).update({ updated: Date.now() }))
                .then(() => customLog.debug('Collections list updated'));
        } catch (e) {
            return;
        }
    };

    public static async updateCollectionCache({
        id: collectionId,
        name,
        shortName,
        description,
        rootKey,
        codeHash,
        tokensCount,
        image,
        tokens,
    }: NFTsCollection): Promise<void> {
        try {
            const collectionRef = FB_NFT.getCollection()
                .doc(FB_NFT.network)
                .collection(FB_NFT.collectionsListCollectionName)
                .doc(collectionId);
            const tokensCollectionRef = collectionRef.collection(FB_NFT.tokensListCollectionName);

            const preparedCollectionData = {
                id: collectionId,
                name,
                shortName,
                description,
                rootKey,
                codeHash,
                tokensCount,
                image,
                updated: Date.now(),
            };

            await Promise.all(tokens.map((token) => tokensCollectionRef.doc(token.id).set(token))).then(() =>
                customLog.debug(`Tokens uploaded to collection: ${collectionId}`)
            );

            await collectionRef
                .update(preparedCollectionData)
                .then(() =>
                    customLog.debug(`Collection: ${collectionId} data uploaded ----->`, preparedCollectionData)
                );
        } catch (e) {
            return;
        }
    }
}
