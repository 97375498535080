// @flow
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { reaction } from 'mobx';
import type { IReactionDisposer } from 'mobx';
import classNames from 'classnames';

import controllerService from '@services/ControllerService';
import { localizationService } from '@services/LocalizationService';
import { themesService } from '@services/ThemesService';
import { Alert, TopBar } from '#components';
import MenuScreen from '#controllers/Dialogs/MenuScreen';
import { navigationMenuItems } from '#navigation/navigationMenuItems';
import { Routing } from '#navigation/Routes';
import { useWebFonts } from '#helpers/hooks/useWebFonts';
import { ThemesNames } from '#uikit/themes';

import '#uikit/designCore/styles/index.scss';
import {
    ThemeContext as ReactThemeContext,
    LightTheme as ReactLightTheme,
    DarkTheme as ReactDarkTheme,
} from '#uikit/themes/colors';

import Configs from './configs';

import './styles.scss';

useWebFonts();

let langListenerDisposer: IReactionDisposer;
let themeListenerDisposer: IReactionDisposer;

const App = () => (
    <div className={classNames('App', themesService.isDarkTheme ? ThemesNames.DarkTheme : ThemesNames.LightTheme)}>
        <TopBar />
        <MenuScreen menuItems={navigationMenuItems} />
        <Routing />
        <Alert />
    </div>
);

const AppWrapper = () => {
    const [isHidden, setIsHidden] = React.useState(true);

    const removePreSpinner = () => {
        const spinnerDiv = document.getElementById('spinner-container');
        if (spinnerDiv) {
            spinnerDiv.remove();
        }
    };

    const refresh = () => {
        setIsHidden(true);
        setImmediate(() => setIsHidden(false));
    };

    useEffect(() => {
        const resizeListener = () => {
            controllerService.setScreenWidth(window.innerWidth);
            controllerService.setScreenHeight(window.innerHeight);
        };

        window.addEventListener('resize', resizeListener);

        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    useEffect(() => {
        langListenerDisposer = reaction(() => localizationService.language, refresh, {
            name: 'Language switch reaction',
        });

        themeListenerDisposer = reaction(
            () => themesService.isDarkTheme,
            () => {
                refresh();
            },
            { name: 'Theme toggle reaction' }
        );

        (async () => {
            await Configs.setup();
            await themesService.load();

            setIsHidden(false);
            setTimeout(() => {
                removePreSpinner();
            }, 0);
        })();

        return () => {
            if (langListenerDisposer) langListenerDisposer();
            if (themeListenerDisposer) themeListenerDisposer();
        };
    }, []);

    if (isHidden) {
        return null;
    }

    return (
        <BrowserRouter>
            <ReactThemeContext.Provider value={themesService.isDarkTheme ? ReactDarkTheme : ReactLightTheme}>
                <App />
            </ReactThemeContext.Provider>
        </BrowserRouter>
    );
};

export default AppWrapper;
