// @flow
import FBProfiles from '@Firebase/FBProfiles';
import type { Profile } from '@Firebase/FBProfiles';
import FBContracts from '@Firebase/FBContracts';
import type { ContractsByCodeHash } from '@Firebase/FBContracts';
import FBDePools from '@Firebase/FBDePools';
import EnvManager from '#helpers/EnvManager';
import Utils from '#helpers/Utils';
import { TONAccount, TONClient, TONMessage, TONValidator } from '#TONClient';
import TONFilter from '#TONClient/TONFilter';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import { addProfileAndValidatorId } from '#TONClient/TONValidator/helpers';

import { ERROR, SUCCESS } from '../statuses';
import type { AccountCodeHash, TONAccountT } from '../TONAccount/types';
import type {
    DePoolInfoExternal,
    TONDePoolT,
    DePoolRoundsExternal,
    DePoolDetailsT,
    DePoolRoundObjExternal,
    DePoolParticipant,
    DepoolPerformance,
} from './types';
import { FBQueryWrapper } from '../helpers';

export default class TONDePool {
    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('TONDePools', str, params);
    }

    static getDePoolCodeHashFilterForContracts(
        dePoolContractsByCodeHash: ContractsByCodeHash
    ): {|
        code_hash: { in: string[] },
    |} {
        const docs: string[] = Object.keys(dePoolContractsByCodeHash).map((codeHash) => `"${codeHash}"`);

        return {
            code_hash: {
                in: docs,
            },
        };
    }

    static async getDePoolCodeHashFilter() {
        const dePoolContractsByCodeHash =
            (await FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)()) || {};
        const docs: string[] = Object.keys(dePoolContractsByCodeHash);

        return {
            code_hash: {
                in: docs,
            },
        };
    }

    static async loadDePools(): Promise<TONDePoolT[]> {
        const log = this.initLog('list');
        try {
            const [codeHashFilter, profiles, dePoolContractsByCodeHash]: [
                any,
                { [string]: Profile },
                ContractsByCodeHash
            ] = await Promise.all([
                this.getDePoolCodeHashFilter(),
                FBQueryWrapper(FBProfiles.getProfiles, null)(),
                FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)(),
            ]);

            const itemsLoader = (lastItem) =>
                TONClient.queryCollectionAccounts(
                    { ...(!!lastItem && { id: { lt: lastItem.id } }), ...codeHashFilter },
                    TONClient.runTVMFields,
                    [{ path: 'id', direction: 'DESC' }]
                );
            const acDocs = await TONClient.loadAllInRange({
                log: this.initLog('ids'),
                itemsLoader,
            });

            const detailedAcDocs = await Promise.all(
                acDocs.map((account) =>
                    this.getDePoolDetails(account, dePoolContractsByCodeHash || {}).then((details) => ({
                        ...(account: any),
                        ...(details || {}),
                    }))
                )
            );

            const formattedDocs = detailedAcDocs
                // .filter(({ stakes }) => !!stakes) for future
                .map((doc) => addProfileAndValidatorId<TONDePoolT>(doc, profiles || {}));

            log.debug(SUCCESS, formattedDocs);
            return formattedDocs;
        } catch (err) {
            log.error(ERROR, err);
            return [];
        }
    }

    static async getDePool(dePoolAddress: string): Promise<DePoolDetailsT | null> {
        const dePoolContractsByCodeHash = await FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)();
        const codeHashFilter = this.getDePoolCodeHashFilterForContracts(dePoolContractsByCodeHash || {});
        const filter = { id: { eq: `"${dePoolAddress}"` }, ...codeHashFilter };

        const acDocs = await TONClient.queryAccounts(
            Utils.JSONStringifyWithoutParanthesesAroundKeys(filter),
            TONClient.runTVMFields
        );

        if (!acDocs) return null;

        const account = acDocs[0];
        const log = this.initLog('details', { account });

        try {
            const [details, profiles] = await Promise.all([
                this.getDePoolDetails(account, dePoolContractsByCodeHash || {}, true),
                FBQueryWrapper(FBProfiles.getProfiles, null)(),
            ]);

            const result = addProfileAndValidatorId<DePoolDetailsT>(
                {
                    ...account,
                    ...(details || {}),
                },
                profiles || {}
            );

            log.debug(SUCCESS, result);
            return result;
        } catch (e) {
            log.error(ERROR, e);

            return null;
        }
    }

    static async getDePoolDetails(
        account: TONAccountT,
        dePoolContractsByCodeHash: ContractsByCodeHash,
        needBalance: boolean = false
    ): Promise<DePoolDetailsT | null> {
        const abi = dePoolContractsByCodeHash[account?.code_hash]?.abi;
        if (!abi) return null;

        const log = this.initLog('getDePoolInfo / getParticipants / getRounds', { account, abi });
        try {
            const [dePoolInfo, participants, rounds, balance]: [
                DePoolInfoExternal | null,
                string[],
                DePoolRoundsExternal,
                string
            ] = await Promise.all([
                this.getDePoolInfo(account, dePoolContractsByCodeHash),
                TONClient.runTVMWrapped({ account, abi, functionName: 'getParticipants' }).then(
                    (response) => response?.participants
                ),
                TONClient.runTVMWrapped({ account, abi, functionName: 'getRounds' }).then(
                    (response) => response?.rounds
                ),
                needBalance ? this.getDePoolBalance(account, dePoolContractsByCodeHash) : '',
            ]);

            const participantsInfo = await Promise.all(
                participants.map((participant) =>
                    TONClient.runTVMWrapped({
                        account,
                        abi,
                        input: { addr: participant },
                        functionName: 'getParticipantInfo',
                    })
                )
            );

            const formattedRounds = (rounds ? Utils.objectValues(rounds) : []).map((round: DePoolRoundObjExternal) =>
                TONDePool.roundFormatter(round)
            );

            const formattedParticipants = participantsInfo.map((participant, index) => ({
                address: participants[index],
                ...TONDePool.participantFormatter(participant),
            }));

            // $FlowExpectedError
            return {
                ...dePoolInfo,
                balance: balance || '',
                dePoolAddress: dePoolInfo?.id,
                rounds: formattedRounds,
                participants: formattedParticipants,
                participantsCount: formattedParticipants.length,
                stakes: formattedRounds.reduce((prev, curr) => prev + (curr.step === 9 ? 0 : curr.stake), 0),
                code_hash: account.code_hash,
            };
        } catch (err) {
            log.error(ERROR, err);
            return null;
        }
    }

    static async getDePoolInfo(
        account: TONAccountT,
        dePoolContractsByCodeHash: ContractsByCodeHash
    ): Promise<DePoolInfoExternal | null> {
        try {
            const abi = dePoolContractsByCodeHash[account.code_hash]?.abi;
            const dePoolInfo = await TONClient.runTVMWrapped({ account, abi, functionName: 'getDePoolInfo' });

            return {
                ...account,
                ...dePoolInfo,
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    static async aggregateDePools() {
        const codeHashFilter = await this.getDePoolCodeHashFilter();
        const log = this.initLog('aggregate accounts count', { filter: codeHashFilter });

        return TONClient.aggregateItems({ filter: codeHashFilter, collection: 'accounts', log });
    }

    static roundFormatter(round: DePoolRoundObjExternal, hex: boolean = false) {
        const converter = TONClient.optionalHex0xToDecConverter(hex);

        return {
            ...round,
            id: converter(round.id),
            completionReason: [
                'RoundNotCompletedYet',
                'DePoolClosedByOwner',
                'RoundOneOfTheFirstTwoRounds',
                'ValidatorStakeLessThanValidatorAssurance',
                'StakeRejectedByElector',
                'RoundCompletedSuccessfully',
                'DePoolLostElections',
                'ValidatorBlamed',
                'ValidatorSentNoRequest',
            ][converter(round.completionReason)],
            completionReasonNum: converter(round.completionReason),
            handledStakesAndRewards: converter(round.handledStakesAndRewards),
            participantQty: converter(round.participantQty),
            recoveredStake: converter(round.recoveredStake),
            rewards: converter(round.rewards),
            stake: converter(round.stake),
            stakeHeldFor: converter(round.stakeHeldFor),
            step: converter(round.step),
            stepName: [
                'PrePooling',
                'Pooling',
                'WaitingValidatorRequest',
                'WaitingIfStakeAccepted',
                'WaitingValidationStart',
                'WaitingIfValidatorEinElections',
                'WaitingUnfreeze',
                'WaitingReward',
                'Completing',
                'Completed',
            ][converter(round.step)],
            supposedElectedAt: converter(round.supposedElectedAt),
            unfreeze: converter(round.unfreeze),
            unused: converter(round.unused),
            validatorRemainingStake: converter(round.validatorRemainingStake),
            validatorStake: converter(round.validatorStake),
            vsetHashInElectionPhase: converter(round.vsetHashInElectionPhase),
        };
    }

    static participantFormatter(participant: DePoolParticipant, hex: boolean = false) {
        const converter = TONClient.optionalHex0xToDecConverter(hex);

        const summarizeStakes = (stakesObj: any) => {
            return Utils.objectValues(stakesObj).reduce((prev, curr) => {
                return prev + converter(typeof curr === 'string' ? curr : curr?.amount || curr?.remainingAmount || '');
            }, 0);
            // Object.keys(stakesObj).reduce((prev, curr) => ({
            //     ...prev,
            //     [TONClient.hex0xToDec(curr)]: TONClient.hex0xToDec(stakesObj[curr]),
            // }), {});
        };

        const getData = (obj) => {
            return Utils.objectValues(obj).reduce(
                (prev, curr) => ({
                    ...prev,
                    lastWithdrawalTime: TONClient.number(prev.lastWithdrawalTime || curr.lastWithdrawalTime),
                    remainingAmount: TONClient.number(prev.remainingAmount || curr.remainingAmount),
                    withdrawalPeriod: TONClient.number(prev.withdrawalPeriod || curr.withdrawalPeriod),
                    withdrawalValue: TONClient.number(prev.withdrawalValue || curr.withdrawalValue),
                }),
                {}
            );
        };

        const vestings: any = participant.vestings || {};
        const locks: any = participant.locks || {};

        const vestingsData = getData(vestings);
        const locksData = getData(locks);

        return {
            ...participant,
            total: converter(participant.total),
            reward: converter(participant.reward),
            withdrawValue: converter(participant.withdrawValue),
            stakes: summarizeStakes(participant.stakes),
            vestings: summarizeStakes(vestings),
            locks: summarizeStakes(participant.locks),
            vestingsData,
            locksData,
        };
    }

    static async getDePools(): Promise<TONDePoolT[]> {
        if (!EnvManager.isMainnet()) {
            // && !EnvManager.isRustnet()
            return this.loadDePools();
        }

        let dePools: TONDePool[] | null;

        const [profiles, dePoolsFromFB]: [{ [string]: Profile }, any[]] = await Promise.all([
            FBQueryWrapper(FBProfiles.getProfiles, null)(),
            FBQueryWrapper(FBDePools.getDePools, null)(),
        ]);

        if (dePoolsFromFB) {
            dePools = dePoolsFromFB;
        } else {
            dePools = await this.loadDePools();
        }

        return (dePools || []).map((item) => addProfileAndValidatorId<TONDePoolT>(item, profiles || {}));
    }

    static async getDePoolBalance(
        account: TONAccountT,
        dePoolContractsByCodeHash: ContractsByCodeHash
    ): Promise<string> {
        const contract = dePoolContractsByCodeHash[account.code_hash];
        if (
            ![
                FBContracts.contracts.dePoolProxyV1,
                FBContracts.contracts.dePoolV2Test,
                FBContracts.contracts.dePoolProxyV2,
            ].includes(contract?.name)
        ) {
            const result = await TONClient.runTVMWrapped({
                account,
                abi: contract?.abi,
                functionName: 'getDePoolBalance',
            });
            return result.value0 || '';
        }

        return '';
    }

    static async getPerformance(id: string): Promise<DepoolPerformance> {
        const [extMessages, codeHashes, contractsByCodeHash, config] = await Promise.all([
            TONClient.loadAllInRange({
                order: [{ path: 'created_at', direction: 'DESC' }],
                result: 'id body created_at',
                collection: TONClient.collections.messages,
                getFilter: (lastItem: TONMessageT) => ({
                    ...TONFilter.timePaginationFilter({
                        args: { lastItem, direction: 'DESC' },
                        key: 'created_at',
                    }),
                    msg_type: { eq: TONMessage.types.extOut },
                    src: { eq: id },
                }),
                multiThread: true,
                log: this.initLog('External messages for DePool'),
            }),
            TONAccount.getCodeHashes([id]),
            FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)(),
            TONValidator.getMasterConfig(),
        ]);
        const { abi } = contractsByCodeHash[codeHashes[0].code_hash];
        const decodedMsgs = await Promise.all(
            extMessages.map((msg) =>
                TONClient.decodeMessageBody({
                    abi,
                    body: msg.body,
                    internal: false,
                })
            )
        );

        const completedRounds = decodedMsgs
            .filter((msg) => msg.name === 'RoundCompleted')
            .map((msg) => msg?.value?.round)
            .filter((round) => !['1', '2', '3', '4'].includes(round.id) && Number(round.supposedElectedAt) !== 0)
            .sort((roundA, roundB) => {
                return roundA?.supposedElectedAt - roundB?.supposedElectedAt;
            });
        // const roundsWithElectedAt = completedRounds
        //     .filter((round) => TONClient.number(round?.supposedElectedAt) > 0);
        const totalDuration =
            TONClient.number(completedRounds[completedRounds.length - 1]?.supposedElectedAt) -
            TONClient.number(completedRounds[0]?.supposedElectedAt);
        const roundsCount = Math.floor(totalDuration / config.p15.validators_elected_for) + 1;

        const successfulRounds = completedRounds.reduce((prev, curr) => {
            return prev + (['5', '1'].includes(curr?.completionReason) ? 1 : 0);
        }, 0);

        const result = {
            completedRounds,
            calculatedRounds: roundsCount,
            successfulRounds,
        };
        const log = this.initLog(`get performance for ${id}`);
        log.debug(SUCCESS, result);

        return result;
    }

    static async getDePoolDetailsWithProxyId(acDocs: AccountCodeHash[]): Promise<DePoolDetailsT[]> {
        const log = this.initLog('DePools details for accounts');

        // filtering DePools from accounts
        const [dePoolProxyContractsByCodeHash, dePoolContractsByCodeHash] = await Promise.all([
            FBQueryWrapper(FBContracts.getDePoolProxyContractsByCodeHash, null)(),
            FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)(),
        ]);
        const proxies = acDocs.filter(
            (doc) =>
                TONValidator.getValidatorType(doc.code_hash, dePoolProxyContractsByCodeHash) ===
                TONValidator.types.dePool
        );
        log.debug('Proxies accounts', proxies);

        // get DePool account ids (Creators for proxies)
        const proxyCreatorIds = await TONAccount.getCreatorIdsForAccounts(proxies.map((doc) => doc.id));
        log.debug('DePool account ids (Creators for proxies)', proxyCreatorIds);

        let result;

        // get DePool Details
        if (EnvManager.isMainnet()) {
            // || EnvManager.isRustnet()
            const dePools = await this.getDePools();
            const dePoolsById = Utils.groupById(dePools, 'id');

            // matching DePools with accounts
            result = proxyCreatorIds.map((id, index) => {
                return {
                    ...dePoolsById[id],
                    proxyId: proxies[index].id,
                };
            });
        } else {
            // get account fields for DePools
            const dePoolAcDocs = await TONAccount.getAccountsForRunTVM(proxyCreatorIds);
            log.debug('DePool accounts for runTVM', dePoolAcDocs);

            // get DePoolInfo for each account
            const dePoolDetailsDocs = await Promise.all(
                dePoolAcDocs.map((doc) => this.getDePoolInfo(doc, dePoolContractsByCodeHash))
            );
            result = dePoolDetailsDocs.map((details, index) => ({
                ...details,
                proxyId: proxies[index].id,
            }));
        }

        // only active depools are in FB collection,
        // so if no data there, then depool is terminated and should be removed
        const filteredResult = result.filter(({ validatorWallet }) => !!validatorWallet);
        log.debug(acDocs, SUCCESS, filteredResult);
        return filteredResult;
    }
}
