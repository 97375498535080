// @flow
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import dataService from '@services/DataServices';

import { TONFilterTags, EVERNft } from '#TONClient';
import type { NFTItem } from '#TONClient/EVERNft/types';
import NFTView from '#components/EVERList/ListViews/Tokens/NFTView';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import DetailsScreen from '#controllers/Details/DetailsScreen';

const NFTCollectionComponent = () => {
    const ref = useRef(null);

    const [params] = useParams();
    const { id } = params;

    const collection = dataService.NFTCollectionsByIdWithTokens[id];
    const [tokens, setTokens] = useState<NFTItem>([]);

    const title = `${collection?.name || liveLocalized.NFTCollectionName}`;

    const onLoadCollection = async ({ id: idParam }) => {
        controllerService.showSpinnerOverlay();
        EVERNft.fetchNFTCollection(idParam).then(controllerService.hideSpinnerOverlay);
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadCollection);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            title={collection ? title : liveLocalized.Collection}
            listTitle={`${liveLocalized.Tokens} ${tokens?.length || ''}`}
            profile={{ avatar: collection?.image }}
            copyableViewData={null}
            details={[
                {
                    caption: liveLocalized.Description,
                    value: collection?.description,
                },
                DetailsRow.copyableRow(liveLocalized.RootAddress, collection?.rootKey),
                DetailsRow.copyableRow(liveLocalized.IndexBaseAddress, collection?.id),
                DetailsRow.copyableRow(liveLocalized.TONAccount.code_hash, collection?.codeHash),
            ]}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            needListInDetails
            filterProps={TONFilterTags.getNFTProps()}
            collectionConfigs={NFTView.getCollectionConfigs()}
            externalControlConfigs={{
                ...NFTView.externalConfigs,
                onChangeFilter: (items) => setTokens(items),
                items: collection?.tokens,
                load25more: true,
                isLoading: !dataService.NFTCollectionsByIdWithTokens[id],
            }}
        />
    );
};

const NFTCollection = observer(NFTCollectionComponent);

export { NFTCollection };
