// @flow
import EnvManager from '#helpers/EnvManager';
import { getNetworkCluster } from '#helpers/index';
import { getUrlParamsForPlayground } from '#controllers/helpers';
import type { PlaygroundArgs } from '#components/TagFilter';
import { netKeys } from 'constants/constants';
import { NetKey } from 'types/types';

const MAINNET_ENDPOINT = 'mainnet.evercloud.dev/480fe4ee5f3e45ac85e6aa70505dc8dc';
const DEVNET_ENDPOINT = 'devnet.evercloud.dev/480fe4ee5f3e45ac85e6aa70505dc8dc';

export default class LinkManager {
    static https = 'https://';

    static urls = {
        testTonOrg: 'https://test.ton.org',
        everSurf: 'https://ton.surf',
        everOSDev: 'https://docs.everos.dev/ever-platform/products/evercloud/get-started',
        supportMail: 'support@tonlabs.io',
        dePool: 'https://depool.tonlabs.io',
        apiDocs: 'https://docs.evercloud.dev/reference/graphql-api',
    };

    static openURL = (url: string, blank: boolean = true) => window.open(url, blank ? '_blank' : '_self');

    static async getPlayground(args: PlaygroundArgs) {
        const netKey = EnvManager.getNetwork();
        const { mainnet, net, rustnet } = netKeys;
        let cluster;
        if (netKey === mainnet) cluster = MAINNET_ENDPOINT;
        else if (netKey === net) cluster = DEVNET_ENDPOINT;
        else if (netKey === rustnet) cluster = 'net.ton.dev';
        else cluster = getNetworkCluster();

        if (Array.isArray(cluster)) cluster = cluster[0];

        const prefix = process.env.TEST_HOST ? '-test' : '';
        const host =
            process.env.NODE_ENV === 'production'
                ? `https://network.gosh.sh/graphql`
                : 'http://0.0.0.0:4001';

        const params: any = await getUrlParamsForPlayground(args);
        const { collection, filter, orderBy, limit, result, network } = params;

        let link = `${host}?cluster=${cluster}&network=${network}`;
        if (args.query) {
            link += `&isBlockChainApiQuery=true&query=${args.query.replace(/\n/g, '').replace(/ +/g, ' ').trim()}`;
        } else {
            link +=
                `&collection=${collection}&filter=${filter}` +
                `&orderBy=${orderBy}&limit=${limit}&result=${result
                    .replace('hash', 'id')
                    .replace(/\\s+/g, ' ')
                    .trim()}`;
        }
        console.log(link);

        return link;
    }

    static getMainUrl(netKey: NetKey) {
        const everLive = 'ever.live';

        if (netKey === netKeys.mainnet) {
            return EnvManager.isTestHost() ? `${this.https}main-test.${everLive}` : `${this.https}${everLive}`;
        }

        const postfix = EnvManager.isTestHost() ? '-test' : '';
        return `${this.https}${netKey}${postfix}.${everLive}`;
    }

    static getAvatar(hash: string, includePrefix: boolean = true) {
        const seedStr = includePrefix ? hash.split(':')[1] || hash : hash;
        return `https://avatars.dicebear.com/api/bottts/${seedStr}.svg`;
    }

    static openEverLive = (netKey: NetKey, blank?: boolean) => {
        this.openURL(this.getMainUrl(netKey), blank);
    };

    static openSurf = () => {
        this.openURL(this.urls.everSurf);
    };

    static openEverOSDev = () => {
        this.openURL(this.urls.everOSDev);
    };

    static openSupportMail = () => {
        this.openURL(`mailto:${this.urls.supportMail}`);
    };

    static openTestTonOrg = () => {
        this.openURL(this.urls.testTonOrg);
    };

    static openDePool = () => {
        this.openURL(this.urls.dePool);
    };

    static openApiDocs = () => {
        this.openURL(this.urls.apiDocs);
    };
}
