// @flow
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';

import type { GetMessagesArgs, TONMessageT } from '#TONClient/TONMessage/types';
import { liveLocalized } from '@services/LocalizationService';
import { TONFilterTags, TONValidator } from '#TONClient';
import { TONLog } from '#TONUtility';
import { StakeView } from '#components';
import type { FilterValues } from '#TONClient/TONFilter';

import TabViewList, { getImperativeHandleParamsForTabViewList } from './TabViewList';

export type StakesListProps = {
    proxies: string[],
};

const log = new TONLog('TransactionsList');

const StakesList = forwardRef<StakesListProps, any>(({ proxies = [] }: StakesListProps, Ref) => {
    const ref = useRef(null);

    useImperativeHandle(Ref, () => getImperativeHandleParamsForTabViewList(ref));

    const itemsLoader = useCallback(
        (args: GetMessagesArgs, filterValues: FilterValues): Promise<TONMessageT[]> => {
            if (!proxies) {
                log.debug('No proxies passed');
                return Promise.resolve([]);
            }

            return TONValidator.getStakes({
                ...args,
                filterValues,
                proxies,
            });
        },
        [proxies]
    );

    if (!proxies.length) return null;

    return (
        <TabViewList
            ref={ref}
            nothingWasFoundMessage={liveLocalized.NoMessagesFound}
            allowFilterSubscription
            subscriptionAlwaysEnabled
            realtimeUpdated={false}
            filterProps={TONFilterTags.getStakesProps()}
            collectionConfigs={StakeView.getCollectionConfigs()}
            itemsLoader={itemsLoader}
        />
    );
});

export default observer(StakesList);
