// @flow
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONAccount, TONMessage, EVERTransaction } from '#TONClient';
import type { EVERTransactionT } from '#TONClient/EVERTransaction/types';
import { CopyableView, LinkWithCopy, UIDetailsTable } from '#components';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import DetailsRowHelper from '#controllers/Details/DetailsRowHelper';
import { getTableRowTextButtonProps } from '#controllers/Details/helpers';
import { TONLog } from '#TONUtility';
import { margin } from '#uikit/designCore/styles';

import Details from './Details';
import DetailsScreen from '../DetailsScreen';
import { paths } from '#navigation/paths';

const log = new TONLog('TransactionDetailsScreen');

const TransactionComponent = () => {
    const ref = useRef(null);
    const [tr, setTr] = useState<?EVERTransactionT>(null);

    const [params] = useParams();

    // Getters
    const {
        now,
        in_msg,
        in_message,
        out_msgs,
        out_messages = [],
        tr_type,
        account_addr,
        value_received,
        value_sent,
        fees,
        balance_delta,
    } = tr || {};

    // details
    const mainDetails = [
        {
            caption: liveLocalized.Type,
            value: liveLocalized.TONTransaction.types[Details.trTypes[tr_type]],
        },
        ...[now ? DetailsRowHelper.getTimeAndDate(now) : {}],
        DetailsRowHelper.accountLinkWithCopy(liveLocalized.Account, account_addr),
    ];

    const commentary = tr?.end_status === TONAccount.states.unInit ? ' (waiting for contract deployment)' : '';

    const optionalDetails = [
        DetailsRowHelper.crystals(liveLocalized.BalanceChange, balance_delta),
        value_received && DetailsRowHelper.crystals(liveLocalized.ValueReceived, value_received),
        value_received &&
            in_message &&
            in_message?.msg_type_name !== TONMessage.types.extIn &&
            DetailsRowHelper.accountLinkWithCopy(liveLocalized.From, in_message?.src),
        value_sent && DetailsRowHelper.crystals(liveLocalized.ValueSent, value_sent),
        out_messages?.length && {
            caption: liveLocalized.To,
            component: out_messages.map<React$Node>(({ dst, id }, index, array) =>
                dst ? (
                    <LinkWithCopy
                        key={`out-message-dst-address-${id || ''}`}
                        {...getTableRowTextButtonProps(index)}
                        title={dst}
                        navigationPath={paths.accounts.details}
                        params={{ id: dst }}
                        classNames={index < array.length - 1 ? margin.bottomDefault : ''}
                    />
                ) : null
            ),
        },
        DetailsRowHelper.crystals(liveLocalized.Fees, fees),
        {
            caption: liveLocalized.Statuses.Aborted,
            value: tr?.aborted ?? commentary,
            type: UIDetailsTable.cellType.bool,
        },
        in_message?.id
            ? DetailsRowHelper.messageLinkWithCopy(liveLocalized.InMessageId, in_message?.id)
            : in_msg && DetailsRowHelper.copyableRow(liveLocalized.InMessageId, in_msg),
        out_messages?.length
            ? {
                  caption: liveLocalized.OutMessageIds,
                  component: out_messages.map<React$Node>(({ id }, index, array) => (
                      <LinkWithCopy
                          key={`out-message-link-${id}`}
                          {...getTableRowTextButtonProps(index)}
                          title={id}
                          navigationPath={paths.messages.details}
                          params={{ id }}
                          style={index < array.length - 1 ? margin.bottomDefault : ''}
                      />
                  )),
              }
            : out_msgs && {
                  caption: liveLocalized.OutMessageIds,
                  component: out_msgs.map<React$Node>((id, index, array) => (
                      <CopyableView
                          key={`out-message-link-${id}`}
                          value={id}
                          displayCaption={false}
                          classNames={index < array.length - 1 ? margin.bottomDefault : ''}
                      />
                  )),
              },
    ];
    const details = [...mainDetails, ...(tr_type === EVERTransaction.types.ordinary ? optionalDetails : [])];

    // more details
    const moreDetails = !tr
        ? []
        : [
              ...(tr.tr_type === EVERTransaction.types.ordinary ? Details.getFees(tr) : []),
              ...Details.getFields(tr),
              ...DetailsRowHelper.proofBocRows(tr?.proof, tr?.boc, 'transaction', tr?.id),
              ...Details.getStorage(tr),
              ...Details.getCredit(tr),
              ...Details.getCompute(tr),
              ...Details.getAction(tr),
              ...Details.getBounce(tr),
              ...Details.getSplitInfo(tr),
              ...Details.getEndingFields(tr),
          ];

    // Actions
    const onLoadTransaction = async ({ id: idParam }) => {
        if (!idParam) {
            log.debug('No transaction id passed');
            return;
        }

        controllerService.showSpinnerOverlay();
        try {
            const newTr = await EVERTransaction.getTransaction(idParam, {
                needAdditionalFields: true,
                needBinaryFields: true,
            });
            if (!newTr) {
                controllerService.showPageNotFound(liveLocalized.TransactionWithThisIdWasntFound);
                return;
            }
            setTr(newTr);
        } catch (e) {
            controllerService.showServiceUnavailable();
        }
        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadTransaction);
    }, [params]);

    // Render
    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.TransactionDetails}
            details={details}
            needMoreDetailsButton
            moreDetails={moreDetails}
        />
    );
};

const Transaction = observer(TransactionComponent);

export { Transaction };
