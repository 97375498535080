import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import EnvManager from '#helpers/EnvManager';
import LinkManager from '#helpers/LinkManager';
import Utils from '#helpers/Utils';
//@ts-ignore doesn`t see import
import rubyIcon from '#assets/ruby&gem/ruby-ccolored.png';
import { Accordion, AccentDetailsTable, ExternalLink, UILabel } from '#components';
import { TONClient, TONMessage, EVERTransaction, EVERBlock, TONAccount, TONValidator, TONDePool } from '#TONClient';
import type { FilterValues } from '#TONClient/TONFilter';
import { BlocksStatistic } from '#TONClient/EVERBlock/types';
import MomentHelper from '#helpers/MomentHelper';
import useAccountsStatistic from '#helpers/hooks/useAccountsStatistic';
import { loadTotalBalances, loadTotalBlocks } from '#controllers/Lists/helpers';
import { aggregationItemsTimeRange } from '#controllers/constants';
import { getAverageBlockTimeStr } from '#controllers/helpers';
import { mainPageStatisticQuery } from '#TONClient/TONFields';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { height, margin } from '#uikit/designCore/styles';

import Screen from '../Screen';

import './styles.scss';
import { paths } from '#navigation/paths';

const mainScreenTestIDs = {
    titleLink: (title: string) => `titleLink_${title}`,
    mainScreenTitle: 'mainScreenTitle',
};

const Description = ({ children }: any) => (
    <UILabel classNames="description" role={TypographyVariants.ParagraphNote} color={ColorVariants.TextSecondary}>
        {children}
    </UILabel>
);

const SmallLink = (props: any) => (
    <ExternalLink {...props} buttonHeightClassName={height.littleCell} titleRole={TypographyVariants.ParagraphNote} />
);

const MainScreenComponent = () => {
    const ref = useRef(null);

    const [accountsStatistic, setAccountsStatistic] = useAccountsStatistic();
    const [blocksStatistic, setBlocksStatistic] = useState<BlocksStatistic>({
        totalCount: 0,
        ratePerSecond: 0,
        countByCurrentValidators: 0,
    });
    const [accounts24Count, setAccounts24Count] = useState<number>(0);
    const [operationsCount, setOperationsCount] = useState<number>(0);
    const [operations24Count, setOperations24Count] = useState<number>(0);
    const [dePoolsCount, setDePoolsCount] = useState<number>(0);
    const [dePoolsStakes, setDePoolsStakes] = useState<number>(0);
    const [validatorsCount, setValidatorsCount] = useState<number>(0);
    const [rewardForYear, setRewardForYear] = useState<number>(0);
    const [totalRewardsForTheMonth, setTotalRewardsForTheMonth] = useState<number>(0);
    const [electorBalance, setElectorBalance] = useState<number>(0);

    const locale = liveLocalized.networks[EnvManager.getNetwork()] || {};

    // Details
    const rewardForYearStr = rewardForYear ? `${liveLocalized.amountToLocale(rewardForYear.toFixed(1))}%` : '-';
    const details = [
        {
            title: blocksStatistic.totalCount,
            details: liveLocalized.TotalBlocks,
            detailsLinkPath: paths.blocks.main,
            annotation: liveLocalized.TotalBlocksAnnotation,
        },
        {
            title: getAverageBlockTimeStr(blocksStatistic.ratePerSecond ? 1 / blocksStatistic.ratePerSecond : 0),
            details: liveLocalized.AverageBlockTime,
            detailsLinkPath: paths.blocks.main,
            annotation: liveLocalized.AverageBlockTimeAnnotation,
        },
        {
            isBalance: true,
            title: accountsStatistic.totalSupply,
            details: liveLocalized.TotalSupply,
            detailsLinkPath: paths.accounts.main,
            annotation: liveLocalized.TotalSupplyAnnotation,
        },
        {
            isBalance: true,
            title: accountsStatistic.circulatingSupply,
            details: liveLocalized.CirculatingSupply,
            detailsLinkPath: paths.accounts.main,
            annotation: liveLocalized.CirculatingSupplyAnnotation,
        },
        {
            title: accountsStatistic.accountsCount,
            details: liveLocalized.AccountsTotal,
            detailsLinkPath: paths.accounts.main,
            annotation: liveLocalized.AccountsTotalAnnotation,
        },
        {
            title: accounts24Count,
            details: liveLocalized.Accounts24h,
            detailsLinkPath: paths.accounts.main,
            annotation: liveLocalized.NewAccounts24hAnnotation,
        },
        {
            title: dePoolsCount,
            details: liveLocalized.ActiveDePools,
            detailsLinkPath: paths.dePools.main,
            annotation: liveLocalized.ActiveDePoolsAnnotation,
        },
        {
            isBalance: true,
            title: dePoolsStakes,
            details: liveLocalized.DePoolsStakes,
            detailsLinkPath: paths.dePools.main,
            annotation: liveLocalized.DePoolsStakesAnnotation,
        },
        {
            title: validatorsCount,
            details: liveLocalized.Validators,
            detailsLinkPath: paths.validators.main,
            annotation: liveLocalized.ValidatorsAnnotation,
        },
        {
            isBalance: true,
            title: electorBalance,
            details: liveLocalized.TotalStaked,
            detailsLinkPath: paths.validators.main,
            annotation: liveLocalized.TotalStakedAnnotation,
        },
        {
            title: rewardForYearStr,
            details: liveLocalized.RewardsYield,
            detailsLinkPath: paths.validators.main,
            annotation: liveLocalized.RewardsYieldAnnotation,
        },
        {
            isBalance: true,
            title: totalRewardsForTheMonth,
            details: liveLocalized.TotalRewardsForTheMonth,
            detailsLinkPath: paths.validators.main,
            annotation: liveLocalized.TotalRewards30DaysAnnotation,
        },
        {
            title: operationsCount,
            details: liveLocalized.OrdinaryTransactionsTotal,
            detailsLinkPath: paths.transactions.main,
            annotation: liveLocalized.OrdinaryTransactionsTotalAnnotation,
        },
        {
            title: operations24Count,
            details: liveLocalized.OrdinaryTransactions24h,
            detailsLinkPath: paths.transactions.main,
            annotation: liveLocalized.OrdinaryTransactions24hAnnotation,
        },
    ];

    // Actions
    const loadStatistics = async (filterValuesParam?: FilterValues) => {
        if (!EnvManager.isNetworkOnDappServer) {
            const { data: { statistics = {} } = {} } = await TONClient.JSFetchQuery(mainPageStatisticQuery);
            const { accounts, transactions, depools, validators, blocks } = statistics;

            setAccountsStatistic(false, {
                accountsCount: accounts.totalCount,
                totalSupply: accounts.totalSupply,
                circulatingSupply: accounts.circulatingSupply,
                giversBalance: accounts.amountOnGivers,
            });

            if (statistics) setBlocksStatistic(blocks);

            setAccounts24Count(accounts.lastDayCount);

            setOperationsCount(transactions.totalOrdinaryCount);
            setOperations24Count(transactions.lastDayOrdinaryCount);

            setDePoolsCount(depools.activeDepoolCount);
            setDePoolsStakes(depools.totalStaked);

            setValidatorsCount(validators.totalCount);
            setElectorBalance(validators.totalStaked);
            setRewardForYear(validators.apr * 100);
            setTotalRewardsForTheMonth(validators.rewardsPer30Days);
        } else {
            loadTotalBlocks(filterValuesParam || {}).then((count) => {
                setBlocksStatistic((prev) => ({ ...prev, totalCount: count }));
            });

            EVERBlock.aggregateBlocks({
                maxTime: { value: MomentHelper.now(), list: [] },
                minTime: { value: MomentHelper.now() - aggregationItemsTimeRange, list: [] },
                workchain_id: { value: 0, list: [] },
            }).then((value) => {
                if (value) {
                    setBlocksStatistic((prev) => ({
                        ...prev,
                        ratePerSecond: Utils.roundToMeaningDigit(value / aggregationItemsTimeRange),
                    }));
                }
            });

            loadTotalBalances(filterValuesParam || {}).then((result) => {
                const totalSupply = result?.aggregatedBalance || 0;
                const giversBalance = result?.aggregatedGiversBalance || 0;

                setAccountsStatistic(true, {
                    totalSupply,
                    circulatingSupply: Math.max(totalSupply - giversBalance, 0),
                    giversBalance,
                });
            });

            TONAccount.aggregateAccounts().then((count) => {
                setAccountsStatistic(true, { accountsCount: count });
            });

            TONAccount.aggregateAccountsForLast24h().then((count) => {
                setAccounts24Count(count);
            });

            EVERTransaction.aggregateOrdinaryTransactions().then((count) => {
                setOperationsCount(count);
            });

            EVERTransaction.aggregateOrdinaryTransactions(true).then((count) => {
                setOperations24Count(count);
            });

            TONDePool.getDePools().then((dePools) => {
                setDePoolsCount((dePools || []).filter(({ stakes }) => stakes > 1).length);
                setDePoolsStakes(Utils.summarizeByKey(dePools || [], 'stakes'));
            });

            TONAccount.getAccountBalance(TONClient.addresses.elector).then((balance) => {
                setElectorBalance(balance);
            });

            TONValidator.getMasterConfig(true).then((config) => {
                const rewards =
                    (config.p34.bonuses / config.p34.total_stake / (MomentHelper.now() - config.p34.utime_since) / 2) *
                    MomentHelper.secondsInYear *
                    100;

                setValidatorsCount(config.p34.list.length);
                setRewardForYear(rewards);
            });

            TONMessage.aggregateRewardsForTheMonth().then((totalRewards) => setTotalRewardsForTheMonth(totalRewards));
        }
    };

    // Components
    const titleComponent = (
        <div className={classNames(margin.topSpacious, margin.bottomDefault)}>
            <UILabel testID={mainScreenTestIDs.mainScreenTitle} role={TypographyVariants.TitleHuge}>
                {locale.title}
            </UILabel>
        </div>
    );

    // DescriptionComponent
    let descriptionComponent = <Description>{locale?.description || ''}</Description>;

    if (EnvManager.isMainnet()) {
        descriptionComponent = (
            <Description>
                {liveLocalized.formatString(
                    locale.description,
                    <SmallLink href={LinkManager.urls.everOSDev} title="EVER OS" />,
                    <SmallLink href={LinkManager.urls.apiDocs} title={locale.here} />
                )}
                {'.'}
            </Description>
        );
    }

    if (EnvManager.isTestnet()) {
        descriptionComponent = (
            <Description>
                {locale?.description || ''} <SmallLink href={LinkManager.urls.testTonOrg} />
            </Description>
        );
    }

    if (EnvManager.isGamenet()) {
        const url = 'https://forum.freeton.org/t/validator-contest-proposal-the-crystal-game-magister-ludi/1218';
        descriptionComponent = <SmallLink href={url} />;
    }

    if (EnvManager.isDevnet()) {
        const smallLink = <SmallLink href={LinkManager.urls.everOSDev} />;
        const description2 = (
            <>
                {locale.description2} <img src={rubyIcon} alt="icon" className="icon" />
            </>
        );
        const description3 = (
            <>
                {locale.description3} <SmallLink href={LinkManager.urls.supportMail} />
            </>
        );

        descriptionComponent = (
            <Description>
                {liveLocalized.formatString(locale?.description || '', smallLink, description2, description3)}
                {'.'}
            </Description>
        );
    }

    const contentComponent = (
        <div className={classNames('MainScreenContent', controllerService.reactContentClassNames)}>
            {titleComponent}
            {EnvManager.isMainnet() || EnvManager.isDevnet2()
                ? descriptionComponent
                : locale.description && <Accordion>{descriptionComponent}</Accordion>}
            <AccentDetailsTable
                detailsList={details}
                detailsLinkRole={TypographyVariants.MonoText}
                cellClassNames="statusCell"
            />
        </div>
    );

    useEffect(() => {
        loadStatistics();
    }, []);

    return <Screen ref={ref} contentComponent={contentComponent} />;
};

const MainScreen = observer(MainScreenComponent);

export { MainScreen };
