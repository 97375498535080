import classNames from 'classnames';
import React, { ReactElement } from 'react';

import { CurrencyPositionType, CurrencySymbolSizeType } from './types';
import { CurrencyPosition, CurrencySymbolSize } from './constants';

export const quantityAndCurrency = (
    quantity: string,
    currency: ReactElement,
    options: {
        position?: CurrencyPositionType;
        separator?: string;
        currencySymbolSize?: CurrencySymbolSizeType;
        key?: string;
        integerClassNames?: string;
        fractionalClassNames?: string;
    } = { position: CurrencyPosition.After }
): ReactElement => {
    const { position, separator, currencySymbolSize, key, integerClassNames, fractionalClassNames } = options;

    let quantityElement;

    if (separator) {
        const [integer, fractional] = quantity.split(separator);
        if (integer && fractional) {
            quantityElement = (
                <div key={`${key || ''}:amount:integer`} className={integerClassNames}>
                    {integer}
                    <span className={fractionalClassNames}>{`${separator}${fractional}`}</span>
                </div>
            );
        }
    }

    if (!quantityElement) {
        quantityElement = (
            <div key={`${key || ''}:amount`} className={integerClassNames}>
                {quantity}
            </div>
        );
    }

    let separatorClassName;
    if (!currencySymbolSize) {
        // Default
        separatorClassName = 'tinyWidth';
    } else if (currencySymbolSize === CurrencySymbolSize.S) {
        separatorClassName = 'tinyWidth';
    } else if (currencySymbolSize === CurrencySymbolSize.M) {
        separatorClassName = 'smallWidth';
    } else if (currencySymbolSize === CurrencySymbolSize.L) {
        separatorClassName = 'defaultWidth';
    }

    return (
        <div className={classNames('displayFlex', 'centerLeftContainer')}>
            {position === CurrencyPosition.Before ? currency : quantityElement}
            {<div className={separatorClassName} />}
            {position === CurrencyPosition.Before ? quantityElement : currency}
        </div>
    );
};
