import React from 'react';
import classNames from 'classnames';

import { AlignItems } from '#uikit/designCore/styles/flex/types';
import { common } from '#uikit/designCore/styles/comon';

type FlexContainerProps = {
    alignItems?: AlignItems;
};

const FlexContainer: React.FC<FlexContainerProps> = ({ alignItems = 'alignCenter', children }) => (
    <div className={classNames(common.displayFlex, alignItems)}>{children}</div>
);

export default FlexContainer;
