import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import searchService from '@services/SearchService';
import { SearchField, BackgroundView } from '#components';
import { SearchDialog } from '#components/search/SearchDialog';
import { border, common, container } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';

import SiteMenu from './SiteMenu';
import LeftMenu from './LeftMenu';

import './TopBar.scss';

const TopBar = () => {
    const topBarRef = useRef<HTMLDivElement>(null);

    const { isNarrow } = controllerService;

    const leftPart = useMemo(
        () => (
            <div className={classnames('TopBar__leftPart', container.centerLeft)}>
                <LeftMenu />
            </div>
        ),
        []
    );

    const rightPart = useMemo(
        () => (
            <div className={classnames('TopBar__rightPart', common.displayFlex, container.centerRight)}>
                <SiteMenu />
            </div>
        ),
        []
    );

    return (
        <BackgroundView
            ref={topBarRef}
            color={ColorVariants.BackgroundPrimary}
            classNames={classnames('TopBar', border.bottomColorTertiary, border.solid, { narrow: isNarrow })}
            data-testid="TopBar"
        >
            {leftPart}
            <SearchField />
            {rightPart}
            <SearchDialog isOpen={Boolean(searchService.searchExpression)} anchorEl={topBarRef.current} />
        </BackgroundView>
    );
};

export default observer(TopBar);
