export const width = {
    full: 'fullWidth',
    threeQuarters: 'threeQuartersWidth',
    twoThirds: 'twoThirdsWidth',
    half: 'halfWidth',
    third: 'thirdWidth',
    quarter: 'quarterWidth',
    tiny: 'tinyWidth',
    small: 'smallWidth',
    default: 'defaultWidth',
    fullPaddingContainer: 'fullPaddingContainer',
    great: 'greatWidth',
    huge: 'hugeWidth',
    major: 'majorWidth',
};
