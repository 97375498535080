const UIColorPalette = {
    primaryMinus: '#47A9DA',
    primary: '#0088CC',
    primaryPlus: '#007AB8',
    primary1: '#C5E4F3',
    primary2: '#A2D4EC',
    primary3: '#7AC1E4',
    primary4: '#006BA1',
    primary5: '#005885',
    primary6: '#003F5E',
    primaryAlpha20: 'rgba(0, 136, 204, 0.2)',
    secondary: '#FFFFFF',
    tertiary: '#FFFFFF',
    black: '#000000',
    blackLight: '#2B3338',
    green: '#00AC47',
    yellow: '#E6AC00',
    white: '#FFFFFF',
    fa: '#FAFAFA',
    dark: '#102027',
    grey: '#727C81',
    grey1: '#EBEDEE',
    grey2: '#DDE1E2',
    grey3: '#CED3D6',
    light: '#CFD8DC',
    whiteLight: '#F5F5F5',
    notWhite: '#F8F9F9',
    black80: 'rgba(0,0,0,0.8)',
    white20: 'rgba(255,255,255,0.2)',
    white40: 'rgba(255,255,255,0.4)',
    white80: 'rgba(255,255,255,0.8)',
    hue005D8C: '#005D8C',
    hue00334C: '#00334C',
    hue0090D9: '#0090D9',
    hue0077B2: '#0077B2',
    overlay60: 'rgba(16, 32, 39, 0.6)',
    overlay40: 'rgba(16, 32, 39, 0.4)',
    overlay20: 'rgba(16, 32, 39, 0.2)',
    overlay0: 'rgba(16, 32, 39, 0)',

    separatorChat: '#9FA6A9',

    success: '#27AE60',
    warning: '#F2C94C',
    error: '#CC0022',
    caution: '#CC4400',
    text: {
        lightPrimary: '#20262A',
        darkPrimary: '#FFFFFF',
        darkPrimaryInverted: '#EBEDEE',
        lightParagraph: '#000000',
        darkParagraph: '#FFFFFF',
        lightSecondary: '#364046',
        darkSecondary: '#EBEDEE',
        lightTertiary: '#7D8589',
        darkTertiary: '#BEC4C8',
        lightQuarternary: '#BEC4C8',
        darkQuarternary: '#96A1A7',
        lightCaution: '#FF9800',
        darkCaution: '#FF9800',
        lightAccent: '#007AB8',
        darkAccent: '#007AB8',
        lightNeutral: '#ABB4B8',
        darkNeutral: '#ABB4B8', // TODO: ask Eugene about the real value
        lightPositive: '#228007',
        darkPositive: '#228007', // TODO: ask Eugene about the real value
        lightNegative: '#CE0014',
        darkNegative: '#CE0014', // TODO: ask Eugene about the real value
    },
    background: {
        lightPrimary: '#FDFDFD',
        lightPrimaryInverted: '#15191C',
        darkPrimary: '#263238',
        darkPrimaryInverted: '#EBEDEE',
        lightSecondary: '#F7F8F8',
        darkSecondary: '#232E33',
        lightTertiary: '#F1F2F3',
        darkTertiary: '#2F3D45',
        lightQuarter: '#E7EBED',
        darkQuarter: '#5A7684',
        lightQuinary: '#F3F5F6',
        darkQuinary: '#85AFC4',
        lightWhiteLight: '#F5F5F5',
        darkWhiteLight: '#F5F5F5',
        lightPositive: '#2EAC09',
        darkPositive: '#2EAC09', // TODO: ask Eugene about the real value
        lightNegative: '#CE0014',
        darkNegative: '#CE0014', // TODO: ask Eugene about the real value
        lightBrake: '#D6DADC',
        darkBrake: '#D6DADC', // TODO: ask Eugene about the real value
    },
    avatar: [
        '#EF5350',
        '#EC407A',
        '#AB47BC',
        '#7E57C2',
        '#5C6BC0',
        '#1E88E5',
        '#0288D1',
        '#0097A7',
        '#009688',
        '#43A047',
        '#558B2F',
        '#F4511E',
        '#8D6E63',
        '#78909C',
    ],
    tag: {
        black: '#20262A',
        green: '#228007',
        red: '#CE0014',
        blue: '#0088CC',
        default: '#F5F5F5',
    },
};
export default UIColorPalette;
