export const font = {
    bold: 'bold',
    medium: 'medium',
    keyBold: 'keyBold',
    keyRegular: 'keyRegular',
    keyLight: 'keyLight',
    headlineBold: 'headlineBold',
    headlineLight: 'headlineLight',
    subheadBold: 'subheadBold',
    subheadLight: 'subheadLight',
    titleBold: 'titleBold',
    titleRegular: 'titleRegular',
    titleLight: 'titleLight',
    subtitleBold: 'subtitleBold',
    subtitleRegular: 'subtitleRegular',
    subtitleLight: 'subtitleLight',
    accentBold: 'accentBold',
    accentRegular: 'accentRegular',
    accentMedium: 'accentMedium',
    promoMedium: 'promoMedium',
    bodyBold: 'bodyBold',
    bodyRegular: 'bodyRegular',
    bodyMedium: 'bodyMedium',
    smallBold: 'smallBold',
    smallRegular: 'smallRegular',
    smallRegularHigh: 'smallRegularHigh',
    smallMedium: 'smallMedium',
    captionBold: 'captionBold',
    captionRegular: 'captionRegular',
    captionMedium: 'captionMedium',
    tinyBold: 'tinyBold',
    tinyRegular: 'tinyRegular',
    tinyMedium: 'tinyMedium',
    microRegular: 'microRegular',
    menuBold: 'menuBold',
    menuRegular: 'menuRegular',
    iconBold: 'iconBold',
    iconRegular: 'iconRegular',
};
