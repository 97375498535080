import DataService from '@services/DataServices';
import { TONClient } from '#TONClient';
import { SUCCESS } from '#TONClient/statuses';
import type { TONAccountT } from '#TONClient/TONAccount/types';
import { FBQueryWrapper } from '#TONClient/helpers';
import { convertByteToString } from '#helpers/convert';
import Utils from '#helpers/Utils';

import { NFTRootContracts, NftRootInfo } from './constants';
import CheckContractSupportTIP6 from './abi/TIP4/CheckContractSupportTIP6.abi.json';
import CollectionData from './abi/TIP4/CollectionData.abi.json';
import CollectionDetailsTip4 from './abi/TIP4/CollectionDetails.abi.json';
import NFTDetails from './abi/TIP4/NFTDetails.abi.json';
import IndexBasis from './abi/IndexBasis.abi.json';
import IndexBasisTIP4 from './abi/TIP4/IndexBasis.abi.json';
import DataAbi from './abi/Data.abi.json';
import FB_NFT from '../../Firebase/FB_NFT';
import type { NFTItem, NFTsCollection, NFTsCollectionRTW, TIP4CollectionFromDB } from './types';

export default class EVERNft {
    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('NFTToken', str, params);
    }

    static testCollectionsIds = ['0:4db068640191790a67f2511a5e5bdee8be1ead4787a020f0125cc8c8f87e2161'];

    static collectionsIndexBasis = {
        Standard1: '38f6d9b0e990df92b6275a2b06c0bb78a851c63f7fdf8753fd1292a6375de3a3',
        Standard2: '502af55b57c71d9e2baffcb4673a3fabe50eab6201e885c7ee64b7d865010a93',
        TIP4: '51d2e5e5718525436e8179cd5450038c2f1dcf6e4fd8a2c1526d114aad0e0f84',
    };

    static collectionParams = {
        [EVERNft.collectionsIndexBasis.Standard1]: {
            getCollections: EVERNft.getCommonCollections,
        },
        [EVERNft.collectionsIndexBasis.Standard2]: {
            getCollections: EVERNft.getCommonCollections,
        },
        [EVERNft.collectionsIndexBasis.TIP4]: {
            getCollections: EVERNft.getTIP4Collections,
        },
    };

    static async getCommonNFTDetails(account: TONAccountT) {
        const result = await TONClient.runGetMethods({
            account,
            abi: DataAbi,
            methods: ['getInfo'],
        }).then((data) => {
            const item = data[0] || {};

            return {
                ...item,
                url: convertByteToString(item.url),
                name: convertByteToString(item.name),
            };
        });

        return {
            ...result,
            last_paid: account.last_paid,
            accountId: account.id,
        };
    }

    static async getCommonCollectionDetails(
        account: TONAccountT,
        aggregateTokens = true
    ): Promise<NFTsCollectionRTW | null> {
        try {
            const log = EVERNft.initLog('NFTCollectionDetails');
            const result = (
                await TONClient.runGetMethods({
                    account,
                    abi: IndexBasis,
                    methods: ['getInfo'],
                    input: { answerId: 0 },
                })
            )[0];

            let tokensCount = 0;
            if (aggregateTokens) {
                tokensCount = await TONClient.aggregateItems({
                    filter: {
                        code_hash: {
                            eq: Utils.remove0x(result.codeHashData),
                        },
                    },
                    log,
                    collection: 'accounts',
                });
            }

            const grandBazarItem = NFTRootContracts.find((item: NftRootInfo) => item.addr === result.addrRoot);

            return {
                id: account.id,
                name: grandBazarItem?.name,
                shortName: grandBazarItem?.short,
                image: grandBazarItem?.iconBase64Data ? `data:image/png;base64,${grandBazarItem?.iconBase64Data}` : '',
                rootKey: result.addrRoot,
                codeHash: result.codeHashData,
                tokensCount,
                description: grandBazarItem?.description,
            };
        } catch (e) {
            return null;
        }
    }

    static async getCommonCollections(docs: TONAccountT[]): Promise<NFTsCollectionRTW[]> {
        const collectionsWithDetails = await Promise.all(
            docs.map((doc) => EVERNft.getCommonCollectionDetails(doc, false))
        ).then((collections) => collections.filter((collection) => !!collection));

        const aggregationResult = await TONClient.batchAggregateAccounts(
            collectionsWithDetails.map((item) => {
                return {
                    filter: {
                        code_hash: {
                            //@ts-ignore doesn`t see "!!collection" filtration above
                            eq: Utils.remove0x(item.codeHash),
                        },
                    },
                };
            })
        );

        //@ts-ignore doesn`t see filtration
        return (collectionsWithDetails || [])
            .map((item, index) => ({
                ...item,
                tokensCount: aggregationResult[index],
            }))
            .filter((item) => item.tokensCount > 0)
            .filter((item) =>
                [
                    // dev
                    '0:744e340c8d7653cd79d47bf53f613834153ccf02ed03b56eb8f802b34e79fd43', // Grandbazar
                    '0:08f2db8e9f1da37998e4668e23073c62bca375bb19093a7f14b200904b8b90a5', // Funny monkey
                    // main
                    '0:9416aa7661522d82d6b1c9f3c3984d624ebe9f309c471e1835460559c95394b4', // Grandbazar
                    '0:658ac333d054fdf48336aa90dc60225e8f89ddc25558383791d9d22658db05c2', // Third Place
                    '0:f3f9a600004a673c9644a117cff606232042c04f6dddbaae2cde95e891404884', // Chess NFT
                    '0:b04b2c78c3867807875c07c681d11b8d1d773bf614e7643b31871b4b725d568c', // FREELAND Passport
                    '0:b934bc974414304c1bf142acad4cf07ff96d454df59b4c5b079387f8d863b1dd', // Freeland passport portraits
                    '0:b926587cea460eeafd4cabf46a18726bd0d193cfde02eae93c81d01b653e4ac4', // Rust Cup Game Cars Collection
                    '0:891c7d1c7d0fe1d3fc2ae982b3699451a7b0dee5fceddd2eb8cae4248e2b6189', // Free TON ESPORTS NFT
                    '0:08220cadbafc69a1ad100593bf79031f00e03c89eeec2594a4eae783662a239e', // MaxArt Foundation
                    '0:48d132bb00afef4fa5829e7b23fbeffc2ac2a4db7c9871c4d7e7f6e7b38e1ede', // Universal humans
                    '0:50ff65aef6e566ff943f5f1dbd220d0e5b3ff344c22c091a549e370daaf2a7ec', // MYTH Gallery
                ].includes(item.id)
            );
    }

    static async getTIP4NFTDetails(account: TONAccountT, needExtraData: boolean = false, rootKey?: string) {
        const result = await TONClient.runGetMethods<{ json: string }>({
            account,
            abi: CollectionDetailsTip4,
            methods: ['getJson'],
            input: { answerId: 0 },
        }).then((result) => JSON.parse(result[0].json));

        const { owner, id } = await TONClient.runGetMethods({
            account,
            abi: NFTDetails,
            methods: ['getInfo'],
            input: { answerId: 0 },
        }).then((result) => result[0]);

        let extraData;
        if (needExtraData) {
            extraData = {
                description: result?.description,
                image: result?.files[0]?.source,
            };
        }

        return {
            id,
            accountId: account.id,
            last_paid: account.last_paid,
            name: result?.name,
            url: result?.external_url,
            addrRoot: rootKey,
            addrOwner: owner,
            addrData: account.id,
            ...(extraData ? { extraData } : {}),
        };
    }

    static async getTip4CollectionDetails(
        doc: TONAccountT,
        aggregateTokens = true,
        shouldCheckContract = false
    ): Promise<NFTsCollectionRTW | null> {
        try {
            const { collection: collectionIndexBasic } = await TONClient.runGetMethods({
                account: doc,
                abi: IndexBasisTIP4,
                methods: ['getInfo'],
                input: { answerId: 0 },
            }).then((result) => result[0]);
            if (!collectionIndexBasic) return null;

            const account = await TONClient.queryCollectionAccounts(
                { id: { eq: collectionIndexBasic } },
                'id boc'
            ).then((result) => result[0]);
            if (!account.id) return null;

            if (shouldCheckContract) {
                const isContractSupportTip6 = await TONClient.runGetMethods({
                    account: account,
                    abi: CheckContractSupportTIP6,
                    methods: ['supportsInterface'],
                    input: { answerId: 0, interfaceID: '0x1217AAAB' },
                }).then((result) => result[0]?.value0);
                if (!isContractSupportTip6) return null;

                const hasCollectionDescription = await TONClient.runGetMethods({
                    account: account,
                    abi: CheckContractSupportTIP6,
                    methods: ['supportsInterface'],
                    input: { answerId: 0, interfaceID: '0x24D7D5F5' },
                }).then((result) => result[0]?.value0);
                if (!hasCollectionDescription) return null;
            }

            const collectionData = await TONClient.runGetMethods<{ json: string }>({
                account: account,
                abi: CollectionDetailsTip4,
                methods: ['getJson'],
                input: { answerId: 0 },
            }).then((result) => JSON.parse(result[0].json || '{}') as TIP4CollectionFromDB);

            const extraCollectionData = (await TONClient.runGetMethods({
                account: account,
                abi: CollectionData,
                methods: ['nftCodeHash', 'totalSupply'],
                input: { answerId: 0, id: 0 },
            })) as [{ codeHash: string }, { count: string }, { nft: string }];

            let tokensCount;
            if (aggregateTokens) {
                tokensCount = ((await TONClient.runGetMethods({
                    account: account,
                    abi: CollectionData,
                    methods: ['totalSupply'],
                    input: { answerId: 0 },
                })) as [{ count: string }])[0].count;
            }

            return {
                id: doc.id,
                name: collectionData.name,
                shortName: collectionData.name,
                description: collectionData.description,
                rootKey: account.id,
                codeHash: extraCollectionData[0].codeHash,
                tokensCount: TONClient.number(tokensCount),
                image: collectionData.files[0]?.source,
            };
        } catch (e) {
            return null;
        }
    }

    static async getTIP4Collections(docs: TONAccountT[]): Promise<NFTsCollectionRTW[]> {
        //@ts-ignore doesn`t see filtration
        return Promise.all(docs.map((doc) => EVERNft.getTip4CollectionDetails(doc, true, true))).then((collections) =>
            collections.filter(
                (collection) => collection?.name && !EVERNft.testCollectionsIds.includes(collection?.rootKey || '')
            )
        );
    }

    static async getNFTCollections(): Promise<NFTsCollectionRTW[]> {
        const log = EVERNft.initLog('NFTCollections');

        const acDocs: TONAccountT[] = await TONClient.getAcDocsByCodeHashes(Object.values(this.collectionsIndexBasis));
        const collectionsWithDetails = await Promise.all(
            Object.values(this.collectionsIndexBasis).map((indexBasis) =>
                this.collectionParams[indexBasis].getCollections(acDocs.filter((doc) => doc.code_hash === indexBasis))
            )
        ).then((result) => result.reduce((acc, currentCollections) => [...acc, ...currentCollections], []));

        log.debug(SUCCESS, collectionsWithDetails);

        return collectionsWithDetails;
    }

    static async getNFTCollection(id: string): Promise<NFTsCollection | null> {
        const log = EVERNft.initLog('NFTCollectionDetails', { id });
        const account = await TONClient.queryCollectionAccounts({ id: { eq: id } }, TONClient.runTVMFields, [
            { path: 'balance', direction: 'ASC' },
        ]).then((result) => result[0]);

        let collectionDetails = await this.getCommonCollectionDetails(account);
        let isTip4Collection = false;
        if (!collectionDetails) {
            const tip4CollectionDetails = await this.getTip4CollectionDetails(account);
            if (!tip4CollectionDetails) return null;

            collectionDetails = tip4CollectionDetails;
            isTip4Collection = true;
        }

        const tokenDocs: TONAccountT[] = await TONClient.getAcDocsByCodeHashes(
            [Utils.remove0x(collectionDetails?.codeHash)],
            'last_paid'
        );
        log.debug('tokenDocs', tokenDocs);

        const tokens = await Promise.all(
            tokenDocs.map((doc) => {
                if (isTip4Collection) return this.getTIP4NFTDetails(doc, false, collectionDetails?.rootKey);
                else return this.getCommonNFTDetails(doc);
            })
        );

        log.debug('tokens', tokens);

        const result = {
            ...collectionDetails,
            tokens,
        };

        log.debug(SUCCESS, result);

        //@ts-ignore doesn`t see contract check
        return result;
    }

    static async getNFT(id: string): Promise<NFTItem> {
        const log = EVERNft.initLog('NFTDetails', { id });

        const account = await TONClient.queryAccounts(
            Utils.JSONStringifyWithoutParanthesesAroundKeys({ id: { eq: `"${id}"` } }),
            TONClient.runTVMFields,
            Utils.JSONStringifyWithoutParanthesesAroundKeys([
                { path: '"balance"', direction: 'ASC' },
            ]),
        ).then((result: TONAccountT[]) => result[0]);

        const commonResult = await this.getCommonNFTDetails(account);
        const isCommonToken = commonResult.addrOwner;
        let tip4Result;
        if (!isCommonToken) {
            tip4Result = await this.getTIP4NFTDetails(
                account,
                true,
                '0:0b1d2bd70953ee82c8889bee60d0696c3e61fbda6469d9b7a6d0832413ba18cf'
            );
        }
        const result = isCommonToken ? commonResult : tip4Result;

        log.debug(SUCCESS, result);

        return result;
    }

    static async fetchNFTCollections(): Promise<void> {
        const collectionsCache = await FBQueryWrapper(FB_NFT.getCollectionsList, null)();
        if (collectionsCache) {
            DataService.setNFTCollections(collectionsCache);
        } else {
            await this.setUpdatedCollections();
            return;
        }

        const needUpdateCollectionsListCache = await FB_NFT.checkNeedUpdateCollectionsListCache();
        if (needUpdateCollectionsListCache || needUpdateCollectionsListCache === null) {
            this.setUpdatedCollections();
        }
    }

    static async fetchNFTCollection(collectionId: string): Promise<void> {
        if (DataService.NFTCollectionsByIdWithTokens[collectionId]) return;

        const collectionCache = await FBQueryWrapper(FB_NFT.getCollectionData, null)(collectionId);
        if (collectionCache) {
            DataService.setNftCollectionByIdWithTokens(collectionCache, collectionId);
        } else {
            await this.setUpdatedCollection(collectionId);
            return;
        }

        const needUpdateCollectionCache = await FB_NFT.checkNeedUpdateListCache(collectionId);
        if (needUpdateCollectionCache || needUpdateCollectionCache === null) {
            this.setUpdatedCollection(collectionId);
        }
    }

    static async setUpdatedCollections(): Promise<void> {
        const newCollections = await this.getNFTCollections();

        //@ts-ignore
        await FBQueryWrapper(
            FB_NFT.updateCollectionsListCache,
            null
        )(newCollections).then(() => DataService.setNFTCollections(newCollections));
    }

    static async setUpdatedCollection(collectionId: string): Promise<void> {
        const newCollectionData = await this.getNFTCollection(collectionId);
        if (!newCollectionData) return;

        //@ts-ignore
        await FBQueryWrapper(
            FB_NFT.updateCollectionCache,
            null
        )(newCollectionData).then(() => DataService.setNftCollectionByIdWithTokens(newCollectionData, collectionId));
    }
}
