// @flow
import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { TONFilterTags, EVERBlock, TONClient } from '#TONClient';
import type { GetBlocksArgs, TONBlockT } from '#TONClient/EVERBlock/types';
import { getBlocksQuery } from '#TONClient/EVERBlock/helpers';
import type { FilterValues } from '#TONClient/TONFilter';
import animationData from '#assets/animations/data4.json';
import { BlockView } from '#components';
import { loadShards, loadSignedBlocks, loadTotalBlocks } from '#controllers/Lists/helpers';
import { getAverageBlockTimeStr } from '#controllers/helpers';
import EnvManager from '#helpers/EnvManager';

import ListScreen from '../ListScreen';

const BlocksScreenComponent = () => {
    const ref = useRef(null);

    const [blocksStatistic, setBlocksStatistic] = useState({
        totalCount: 0,
        ratePerSecond: 0,
        countByCurrentValidators: 0,
    });
    const [shardsCount, setShardsCount] = useState<number>(0);

    // Details
    const details = [
        {
            title: blocksStatistic.totalCount,
            details: liveLocalized.TotalBlocks,
        },
        {
            title: getAverageBlockTimeStr(blocksStatistic.ratePerSecond ? 1 / blocksStatistic.ratePerSecond : 0),
            details: liveLocalized.AverageBlockTime,
            isHiddenOnAsc: true,
            isAverageBlockTime: EnvManager.isNetworkOnDappServer,
        },
        {
            title: blocksStatistic.countByCurrentValidators,
            details: liveLocalized.BlocksByCurrentValidators,
            isHiddenOnAsc: true,
        },
        {
            title: shardsCount,
            details: liveLocalized.WorkchainShards,
        },
    ];

    // Actions
    const itemsLoader = useCallback((args: GetBlocksArgs, filterValues: FilterValues): Promise<TONBlockT[]> => {
        return EVERBlock.getBlocks({
            ...args,
            filterValues,
        });
    }, []);

    const itemsAggregator = (filterValues: FilterValues) =>
        EVERBlock.aggregateBlocks({
            maxTime: filterValues.maxTime || {},
            minTime: filterValues.minTime || {},
            workchain_id: { value: 0, list: [] },
        });

    // Events
    const onLoadingNew = (block: ?EVERBlock): void => {
        if (block && ref.current) {
            ref.current.unshiftItem(block, 'gen_utime');
            setBlocksStatistic((prev) => ({ ...prev, countByCurrentValidators: prev.countByCurrentValidators + 1 }));
        }
    };

    const onSubscribeForUpdate = (filterValues: FilterValues): void => {
        EVERBlock.subscribeForUpdate(onLoadingNew, { filterValues });
    };

    const onUnsubscribeForUpdate = (): void => {
        EVERBlock.unsubscribe();
    };

    const onStartReload = (filterValues?: FilterValues) => {
        if (!EnvManager.isNetworkOnDappServer) {
            TONClient.queryBlocksStatistic().then((statistics) => {
                if (statistics) setBlocksStatistic(statistics);
            });
        } else {
            loadTotalBlocks(filterValues || {}).then((count) => {
                setBlocksStatistic((prev) => ({ ...prev, totalCount: count }));
            });

            loadSignedBlocks(filterValues || {}).then((count) => {
                setBlocksStatistic((prev) => ({ ...prev, countByCurrentValidators: count }));
            });
        }

        loadShards(filterValues || {}).then((result) => {
            setShardsCount(result.shardsCount);
            ref?.current && ref.current.updateFilterValues(result.filterValues);
        });
    };

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Blocks}
            listTitle={liveLocalized.Recent}
            shouldHideListTitleOnASC
            details={details}
            itemsLoader={itemsLoader}
            itemsAggregator={itemsAggregator}
            animationData={animationData}
            filterProps={TONFilterTags.getBlockProps()}
            collectionConfigs={BlockView.getCollectionConfigs()}
            realtimeUpdated
            onSubscribeForUpdate={onSubscribeForUpdate}
            onUnsubscribeForUpdate={onUnsubscribeForUpdate}
            onStartReload={onStartReload}
            queryGetter={getBlocksQuery}
        />
    );
};

const Blocks = observer(BlocksScreenComponent);

export { Blocks };
