import React from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { LinkWithChild, UIDetailsButton } from '#components';
import { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import { TONClient } from '#TONClient';
import type { NFTItem } from '#TONClient/EVERNft/types';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { paths } from '#navigation/paths';

type Props = {
    item: NFTItem;
    index?: number;
};

const NFTViewTestIDs = {
    NFTViewTestIDs: 'NFTViewTestIDs',
    NFTCollectionElView: 'NFTCollectionElView',
};

const NFTView = ({ item, index }: Props) => {
    if (!item) return null;

    const { addrOwner, name, last_paid } = item;
    const { isNarrow } = controllerService;

    const lastTxPrefix = isNarrow ? '' : `${liveLocalized.LastTx}: `;
    const lastPaidStr = last_paid ? `${lastTxPrefix}${MomentHelper.getTimeDate(TONClient.number(last_paid))}` : '';

    return (
        <LinkWithChild navigationPath={paths.NFT.token} params={{ id: item.accountId }}>
            <UIDetailsButton
                testID={NFTViewTestIDs.NFTCollectionElView}
                index={index || index === 0 ? index + 1 : null}
                copyTarget={UIDetailsButtonCopyTargets.details}
                title={isNarrow ? Utils.truncTextToNarrow(name) : name}
                truncDetails
                caption={Utils.truncTextToNarrow(item.addrData, isNarrow)}
                detailsPrefix={isNarrow ? '' : `${liveLocalized.Owner}: `}
                details={addrOwner}
                secondDetails={lastPaidStr}
            />
        </LinkWithChild>
    );
};

NFTView.externalConfigs = {
    sortingKey: 'last_paid',
    filterParams: {
        expression: {
            name: 'name',
            addrOwner: 'addrOwner',
            addrData: 'addrData',
        },
    },
};

NFTView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: NFTItem): string => {
            return `NFT-view-${id}`;
        },
        idKey: 'accountId',
        collectionName: liveLocalized.NFTCollection,
        renderItem: ({ item }: { item: any }, index?: number) => <NFTView index={index} item={item} />,
    };
};

export default observer(NFTView);
