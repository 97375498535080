import {
    testnet,
    net,
    net2,
    common1net,
    common2net,
    privatenet,
    mainnet,
    cinet,
    osnet,
    rustnet,
    game0net,
    customnet,
    fldnet,
    SEnet,
    vps21net,
    vps23net,
    vps25net,
    bsnnet,
    bsn2net,
    toldnet,
    rfldnet,
    goshnet,
    gychainnet,
    smftnet,
    tonnet,
} from '../../webpack/webconfigs';

const netKeys = {
    testnet: testnet.key,
    net: net.key,
    net2: net2.key,
    common1net: common1net.key,
    common2net: common2net.key,
    privatenet: privatenet.key,
    mainnet: mainnet.key,
    cinet: cinet.key,
    osnet: osnet.key,
    rustnet: rustnet.key,
    game0net: game0net.key,
    customnet: customnet.key,
    fldnet: fldnet.key,
    SEnet: SEnet.key,
    vps21net: vps21net.key,
    vps23net: vps23net.key,
    vps25net: vps25net.key,
    bsnnet: bsnnet.key,
    bsn2net: bsn2net.key,
    toldnet: toldnet.key,
    rfldnet: rfldnet.key,
    goshnet: goshnet.key,
    gychainnet: gychainnet.key,
    smftnet: smftnet.key,
    tonnet: tonnet.key,
};

const MAINNET_ENDPOINT = 'mainnet.evercloud.dev/480fe4ee5f3e45ac85e6aa70505dc8dc';
const DEVNET_ENDPOINT = 'devnet.evercloud.dev/480fe4ee5f3e45ac85e6aa70505dc8dc';

export { netKeys, MAINNET_ENDPOINT, DEVNET_ENDPOINT };
