import { Maybe } from 'graphql/jsutils/Maybe';

import { UIColorThemeName } from '../color/types';
import UIColorThemeAction from '../color/themeAction';
import UIColorThemeDark from '../color/themeDark';
import UIColorThemeLight from '../color/themeLight';
import type { UIColorData, UIColorThemeData, UIColorThemeNameType } from '../color/types';

const themes: { [key in UIColorThemeNameType]: UIColorThemeData } = {
    light: UIColorThemeLight,
    dark: UIColorThemeDark,
    action: UIColorThemeAction,
};

let current = UIColorThemeName.light;

export class UITheme {
    static current: UIColorThemeData = themes.light;

    static switchCurrentTheme(themeName: UIColorThemeNameType) {
        current = themeName;
        this.current = themes[themeName];
    }

    // Text colors
    static textPrimary(theme?: Maybe<UIColorThemeNameType>): string {
        return themes[theme || current].text.primary.normal;
    }

    static stateTextPrimary(
        theme: Maybe<UIColorThemeNameType>,
        disabled: boolean,
        tapped: boolean,
        hover: boolean
    ): UIColorData {
        const primary = UITheme.textPrimary(theme);
        if (disabled) {
            return primary.disabled;
        }
        if (tapped) {
            return primary.tapped;
        }
        if (hover) {
            return primary.hover;
        }
        return primary.normal;
    }

    static actionTextPrimary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.action;
    }

    static textParagraph(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.paragraph;
    }

    static textSecondary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.secondary;
    }

    static textTertiary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.tertiary;
    }

    static textQuaternary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.quaternary;
    }

    static textCaution(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.caution;
    }

    static textPlaceholder(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.placeholder;
    }

    static textAccent(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.accent;
    }

    static textDisabled(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].detailsInput.amount.placeholder;
    }

    static textPositive(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.positive;
    }

    static textNegative(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].text.negative;
    }

    // Background colors
    static backgroundPrimary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.primary;
    }

    static backgroundPrimaryInverted(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.primaryInverted;
    }

    static backgroundSecondary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.secondary;
    }

    static backgroundTertiary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.tertiary;
    }

    static backgroundQuarter(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.quarter;
    }

    static backgroundQuinary(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.quinary;
    }

    static backgroundWhiteLight(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.whiteLight;
    }

    static backgroundPositive(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.positive;
    }

    static backgroundNegative(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.negative;
    }

    static backgroundBrake(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].background.brake;
    }

    static borderBottom(theme?: Maybe<UIColorThemeNameType>): { [key: string]: string } {
        return themes[theme || current].borderBottom;
    }

    static borderBottomColor(theme: Maybe<UIColorThemeNameType>, focused: boolean, hover: boolean): UIColorData {
        const borderBottom = UITheme.borderBottom(theme);
        if (focused) {
            return borderBottom.focused;
        }
        if (hover) {
            return borderBottom.hover;
        }
        return borderBottom.normal;
    }

    static borderBottomLightColor(theme: Maybe<UIColorThemeNameType>) {
        const borderBottom = UITheme.borderBottom(theme);
        return borderBottom.light;
    }

    static button(theme?: Maybe<UIColorThemeNameType>): { [key: string]: string } {
        return themes[theme || current].button;
    }

    static detailsInputComment(theme?: Maybe<UIColorThemeNameType>): UIColorData {
        return themes[theme || current].detailsInput.comment;
    }
}
