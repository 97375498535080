import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { UILabel } from '#components';
import type { DetailsRow } from '#controllers/Details/DetailsRowHelper/types';
import type { SortDirection } from '#TONClient/TONFilter';
import { TONFilter } from '#TONClient';
import UIDetailsTable from '#components/UIDetailsTable/index';
import SortSwitcher from '#components/EVERList/SortSwitcher';
import { common } from '#uikit/designCore/styles/comon';
import { padding } from '#uikit/designCore/styles/padding';
import { flex } from '#uikit/designCore/styles/flex';
import { margin } from '#uikit/designCore/styles/margin';
import { text } from '#uikit/designCore/styles/text';

type Props = {
    items: any[];
    sortingKey: string;
    additionalSortingKey?: string;
    sortDirectionByDefault?: SortDirection;
    getDetailsRow: (item: any) => DetailsRow;
    narrow?: boolean;
    classNames?: string;
    onPress?: (details: DetailsRow) => void;
    leftCellClassNames?: string;
    rightCellClassNames?: string;
    rowContainerClassNames?: string;
    rowSeparator?: boolean;
};

const UIDetailsSortingTable = ({
    items,
    sortingKey,
    additionalSortingKey,
    sortDirectionByDefault,
    getDetailsRow,
    ...props
}: Props) => {
    const [sortedItems, setSortedItems] = useState<any[]>([]);
    const [sortDir, setSortDir] = useState<SortDirection>(sortDirectionByDefault || 'DESC');
    const [additionalSortDir, setAdditionalSortDir] = useState<SortDirection>(sortDirectionByDefault || 'DESC');
    const [disableMainSwitcher, setDisableMainSwitcher] = useState<boolean>(false);

    const sortingItems = (dir: SortDirection, sortKey, additionalSortKey) => {
        const innerItems = [...items];
        TONFilter.sortBy(innerItems, { direction: dir }, sortKey, additionalSortKey);

        setSortedItems(innerItems);
    };

    const onChangeAdditionalSortDirection = () => {
        const newDirection = additionalSortDir === 'DESC' ? 'ASC' : 'DESC';
        setAdditionalSortDir(newDirection);
        setDisableMainSwitcher(true);
    };

    const onChangeSortDirection = () => {
        const newDirection = sortDir === 'DESC' ? 'ASC' : 'DESC';
        setSortDir(newDirection);
        setDisableMainSwitcher(false);
    };

    useEffect(() => {
        sortingItems(additionalSortDir, additionalSortingKey, sortingKey);
    }, [additionalSortDir]);

    useEffect(() => {
        sortingItems(sortDir, sortingKey, additionalSortingKey);
    }, [sortDir]);

    return (
        <div>
            <div className={classNames(common.displayFlex, padding.topDefault)}>
                <UILabel classNames={classNames(flex.flex1, margin.rightDefault, text.alignRight)}>
                    <SortSwitcher
                        onPress={onChangeSortDirection}
                        sortDirection={sortDir}
                        disabled={!items.length}
                        unActive={disableMainSwitcher}
                        classNames={margin.leftNone}
                    />
                </UILabel>
                <UILabel classNames={classNames(flex.flex3, text.alignCenter)}>
                    {additionalSortingKey && (
                        <SortSwitcher
                            onPress={onChangeAdditionalSortDirection}
                            sortDirection={additionalSortDir}
                            disabled={!items.length}
                            unActive={!disableMainSwitcher}
                            classNames={margin.leftNone}
                        />
                    )}
                </UILabel>
            </div>
            <UIDetailsTable
                {...props}
                //@ts-ignore
                detailsList={sortedItems.map(getDetailsRow)}
            />
        </div>
    );
};

export default UIDetailsSortingTable;
