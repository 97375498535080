// @flow
import { liveLocalized } from '@services/LocalizationService';
import Utils from '#helpers/Utils';
import UITextStyle from '#uikit/designCore/style/text';

export const toFixedOrEmpty = (arg: ?number): string => {
    return Utils.toFixedOrEmpty(arg, 9);
};

export const getTableRowTextButtonProps = (index: number) => {
    return {
        multiLine: true,
        classNames: index > 0 && 'marginTopDefault',
        textStyle: UITextStyle.actionSmallMedium,
    };
};

export const getComputeExitCodeDescription = (code: number) => {
    return (
        {
            [0]: liveLocalized.TvmTerminatedSuccessfully,
            [1]: liveLocalized.TvmTerminatedSuccessfullyAlternative,
            [2]: liveLocalized.StackUnderflow,
            [3]: liveLocalized.StackOverflow,
            [4]: liveLocalized.IntegerOverflow,
            [5]: liveLocalized.RangeCheckError,
            [6]: liveLocalized.InvalidOpcode,
            [7]: liveLocalized.TypeCheckError,
            [8]: liveLocalized.CellOverflow,
            [9]: liveLocalized.CellUnderflow,
            [10]: liveLocalized.DictionaryError,
            [11]: liveLocalized.UnknownError,
            [12]: liveLocalized.FatalError,
            [13]: liveLocalized.OutOfGasTheContractIsEitherLowOnGasOrItsLimitIsExceeded,
        }[code] || liveLocalized.UnknownCode
    );
};
