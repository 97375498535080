import React from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import { LinkWithChild, UILabel } from '#components';
import { paths } from '#navigation/paths';
import type { TONCollectionConfigs } from '#components/EVERList';
import type { NFTsCollectionRTW } from '#TONClient/EVERNft/types';

type Props = {
    item: NFTsCollectionRTW;
    index?: number;
};

const NFTCollectionViewTestIDs = {
    NFTCollectionView: 'NFTCollection-view',
};

const NFTCollectionView = ({ item }: Props) => {
    if (!item) return null;
    const narrow = controllerService.isNarrow;

    const { rootKey, shortName, tokensCount, image, id } = item;

    const detailsStr = (caption: string, value?: number) => {
        const secondCaption = narrow ? '' : `${caption}: `;
        return (
            <UILabel>
                {secondCaption} {value}
            </UILabel>
        );
    };

    const totalGrantedStr = detailsStr(liveLocalized.NumberTokens, tokensCount);

    return (
        <LinkWithChild navigationPath={paths.NFT.collection} params={{ id }}>
            <UIDetailsButton
                testID={NFTCollectionViewTestIDs.NFTCollectionView}
                copyTarget={UIDetailsButtonCopyTargets.details}
                title={shortName || liveLocalized.NFTCollectionName}
                image={image}
                caption={totalGrantedStr}
                truncDetails
                details={rootKey}
            />
        </LinkWithChild>
    );
};

NFTCollectionView.externalConfigs = {
    sortingKey: 'tokensCount',
    // filterParams: {
    //     minMax: TONFilterTags.getDePoolFilterMinMaxParams(true),
    // },
};

NFTCollectionView.getCollectionConfigsNFT = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: NFTsCollectionRTW): string => {
            return `Token-view-${id}`;
        },
        idKey: 'address',
        collectionName: liveLocalized.NFTCollection,
        renderItem: ({ item }: { item: any }, index?: number) => <NFTCollectionView index={index} item={item} />,
    };
};

export default observer(NFTCollectionView);
