import React, { ReactNode, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Slide } from '@mui/material';
import classnames from 'classnames';

import { BackgroundView, UITextButton } from '#components';
import PopoverMenu from '#components/Popover/PopoverMenu/PopoverMenu';
import { flex, padding } from '#uikit/designCore/styles';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { ColorVariants } from '#uikit/designCore/constants/color';

import type { MenuItemProps } from '../types';

import './Switcher.scss';

type Props = {
    menuItems: MenuItemProps[];
    renderTrigger: (onPress?: () => void) => ReactNode;
};

const Transition = React.forwardRef<unknown, TransitionProps & { children: React.ReactElement<any, any> }>(
    (props, ref) => <Slide direction="up" ref={ref} {...props} />
);

const Switcher: React.FC<Props> = ({ menuItems, renderTrigger }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return menuItems.length <= 10 ? (
        <PopoverMenu
            menuItems={menuItems}
            componentContainerStyles={{ style: { transform: 'translateY(10px)' } }}
            componentClassNames={padding.horizontal}
        >
            {renderTrigger()}
        </PopoverMenu>
    ) : (
        <>
            {renderTrigger(() => setIsDialogOpen((prev) => !prev))}
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                keepMounted
                TransitionProps={{ timeout: 500, easing: 'cubic-bezier(.44,1.24,.77,1.02)' }}
                TransitionComponent={Transition}
                classes={{ paper: 'MUI Switcher' }}
            >
                <BackgroundView classNames="Dialog">
                    {menuItems.map(
                        ({
                            title,
                            titleColor,
                            titleStyle,
                            titleTypography = TypographyVariants.ActionCallout,
                            onPress,
                            chosen,
                            classNames,
                        }) => (
                            <UITextButton
                                key={title}
                                title={title}
                                titleColor={
                                    titleStyle ||
                                    titleColor ||
                                    (chosen ? ColorVariants.TextAccent : ColorVariants.TextPrimary)
                                }
                                titleRole={titleTypography}
                                onPress={() => {
                                    if (onPress) onPress();
                                    setIsDialogOpen(false);
                                }}
                                classNames={classnames(classNames, flex.justifyContentCenter)}
                            />
                        )
                    )}
                </BackgroundView>
            </Dialog>
        </>
    );
};

export { Switcher };
