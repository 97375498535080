import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { LinkWithCopy, ParticipantView, RoundView, EVERList, QRCode } from '#components';
import type { TONExternalConfigs } from '#components/EVERList';
import { TONDePool } from '#TONClient';
import type { DePoolParticipant, DepoolPerformance, DePoolRound, TONDePoolT } from '#TONClient/TONDePool/types';
import { getParticipantTableRows, getRoundTableRows } from '#controllers/Details/DePool/expansionDetails';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import DetailsTabView from '#controllers/Details/components/DetailsTabView';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import { paths } from '#navigation/paths';
import { validatorDetailsGetParams } from '#navigation/helpers';
import { container } from '#uikit/designCore/styles/container';
import { margin } from '#uikit/designCore/styles/margin';
import { common } from '#uikit/designCore/styles';

export const getDePoolTabProps = (isRoundsList: boolean, externalControlConfigs: TONExternalConfigs) => ({
    classNames: margin.topDefault,
    contentClassNames: container.fullWidthPadding,
    realtimeUpdated: false,
    collectionConfigs: isRoundsList
        ? {
              ...RoundView.configs,
              renderItem: ({ item }: { item: DePoolRound }) => (
                  <RoundView
                      item={item}
                      detailsListGetter={(round: DePoolRound) =>
                          getRoundTableRows({
                              key: '',
                              round,
                          })
                      }
                  />
              ),
          }
        : {
              ...ParticipantView.configs,
              renderItem: ({ item }: { item: DePoolParticipant }) => (
                  <ParticipantView
                      item={item}
                      detailsListGetter={(participant) =>
                          getParticipantTableRows({
                              key: '',
                              participant,
                          })
                      }
                  />
              ),
          },
    externalControlConfigs,
});

const DePoolComponent = () => {
    const ref = useRef(null);
    const [depool, setDepool] = useState<Maybe<TONDePoolT>>(null);
    const [performance, setPerformance] = useState<Maybe<DepoolPerformance>>(null);

    const [params] = useParams();
    const { id } = params;

    // details
    const { calculatedRounds, successfulRounds } = performance || {};
    const {
        validatorWallet,
        proxies,
        stakes,
        minStake,
        validatorAssurance,
        validatorRewardFraction,
        poolClosed,
        code_hash,
        validatorId,
        balance,
    } = depool || {};

    const successfulRoundsPercent = Math.floor(((successfulRounds || 0) / (calculatedRounds || 0)) * 100);

    const details = [
        {
            caption: liveLocalized.SuccessfulRounds,
            value: poolClosed
                ? liveLocalized.Closed
                : calculatedRounds && successfulRounds
                ? `${successfulRoundsPercent}% (${successfulRounds} / ${calculatedRounds} ${liveLocalized.Rounds})`
                : '-',
        },
        DetailsRow.accountLinkWithCopy(liveLocalized.DePoolAddress, id),
        {
            caption: liveLocalized.ValidatorDetails,
            component: (
                <LinkWithCopy
                    title={validatorWallet}
                    navigationPath={paths.validators.details}
                    params={validatorDetailsGetParams({ validatorId })}
                />
            ),
        },
        DetailsRow.accountLinkWithCopy(
            liveLocalized.formatString(liveLocalized.ProxyAddress, 1),
            proxies && proxies[0]
        ),
        DetailsRow.accountLinkWithCopy(
            liveLocalized.formatString(liveLocalized.ProxyAddress, 2),
            proxies && proxies[1]
        ),
        DetailsRow.crystals(liveLocalized.Balance, balance),
        DetailsRow.crystals(liveLocalized.Stakes, stakes),
        DetailsRow.crystals(liveLocalized.MinStake, minStake),
        DetailsRow.crystals(liveLocalized.ValidatorAssurance, validatorAssurance),
        {
            caption: liveLocalized.ValidatorRewardFraction,
            value: validatorRewardFraction ? `${validatorRewardFraction} %` : '',
        },
        {
            caption: liveLocalized.DePoolClosed,
            value: poolClosed,
        },
        DetailsRow.copyableRow(liveLocalized.DePoolCodeHash, code_hash),
    ];

    // pages
    const { participants, rounds } = depool || {};

    const pages = useMemo(
        () => [
            {
                title: `${liveLocalized.ParticipantQty} ${participants?.length || ''}`,
                component: (
                    <EVERList
                        {...getDePoolTabProps(false, {
                            ...ParticipantView.externalConfigs,
                            items: participants,
                        })}
                    />
                ),
            },
            {
                title: liveLocalized.Rounds,
                component: (
                    <EVERList
                        {...getDePoolTabProps(true, {
                            ...ParticipantView.externalConfigs,
                            items: rounds,
                        })}
                    />
                ),
            },
        ],
        [depool]
    );

    const onLoadDepoolDetails = async ({ id: idParam }) => {
        TONDePool.getPerformance(idParam).then((newPerformance) => {
            setPerformance(newPerformance);
        });
        controllerService.showSpinnerOverlay();
        const newDepool = await TONDePool.getDePool(idParam);
        setDepool(newDepool);
        controllerService.hideSpinnerOverlay();
    };

    // return this.state.dePool ? `ton://${this.getId()}` : '';
    // TODO: support the link in the following format once it's supported
    // `ton://debot/${debotAddress}?depool=${this.getId()}`
    // For now let's use a regular depool address & render it in the QR code
    const depoolLink = depool ? id : '';

    const rightTitleComponent =
        controllerService.isNarrow || !depool ? null : (
            <div
                className={classnames(
                    controllerService.reactContentClassNames,
                    container.centerRight,
                    common.displayFlex
                )}
            >
                <QRCode link={depoolLink} />
            </div>
        );

    useEffect(() => {
        return onWillFocus(params, ref, onLoadDepoolDetails);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            needMoreDetailsButton={false}
            profile={depool}
            title={liveLocalized.formatString(liveLocalized.DePool, depool?.name || '')}
            rightTitleComponent={rightTitleComponent}
            copyableViewData={null}
            details={details}
            detailsComponent={<DetailsTabView pages={pages} />}
        />
    );
};

const DePool = observer(DePoolComponent);

export { DePool };
