// @flow
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { liveLocalized } from '@services/LocalizationService';
import { TONCache, TONCommonStore } from '#TONUtility';
import type { TONAsyncStorage } from '#TONUtility';
import ExtraConfig from '#configs/ExtraConfig';
import { uiLocalized } from '#uikit/@tonlabs/localization';

import { Language } from '../constants';

const LocalizationStoreKeys = Object.freeze({
    LanguageKey: 'tonLiveLanguage',
});

export default class LocalizationStore extends TONCommonStore {
    @observable availableLanguages: Language[] = ExtraConfig.availableLanguages;
    @observable language: Language = ExtraConfig.mainLanguage;

    constructor() {
        super();
        makeObservable(this);
    }

    // eslint-disable-next-line class-methods-use-this
    get storage(): ?TONAsyncStorage {
        return TONCache.getAsyncStorage();
    }

    onLoad = async () => {
        liveLocalized.setLanguages(this.availableLanguages);
        uiLocalized.setLanguages(this.availableLanguages);

        await this.loadStoreValues();

        liveLocalized.setLanguage(this.language);
        uiLocalized.setLanguage(this.language);
        moment.locale(this.language);
    };

    // @overridden
    onUnload = async () => {
        await this.clearStoreValues();
    };

    @action
    loadStoreValues = async () => {
        await Promise.all(
            Object.keys(LocalizationStoreKeys).map(async (storeKey) => {
                const key = LocalizationStoreKeys[storeKey];
                if (this.storage) {
                    const storeValue: Language = await this.storage.getItem(key);
                    if (key === LocalizationStoreKeys.LanguageKey) {
                        const localLanguage = liveLocalized.getInterfaceLanguage();
                        this.language = storeValue || this.getSupportedLanguage(localLanguage);
                    } else {
                        // not supported yet
                    }
                } else {
                    throw new Error(`No storage or key present for ${key}`);
                }
            })
        );
    };

    getSupportedLanguage = (language: string): Language => {
        const supportedLanguages = ExtraConfig.enabledLanguages;

        // For two part languages (ex. pt_BR)
        if (supportedLanguages.includes(language)) {
            return language;
        }

        // Split language and region (ex. 'en-RU') and get only language
        const [shortLanguage] = language.split('-');
        if (supportedLanguages.includes(shortLanguage)) {
            return shortLanguage;
        }

        return ExtraConfig.mainLanguage;
    };

    @action
    clearStoreValues = async () => {
        await Promise.all(
            Object.keys(LocalizationStoreKeys).map(async (storeKey) => {
                const key = LocalizationStoreKeys[storeKey];
                if (this.storage) {
                    this.storage.removeItem(key);
                } else {
                    throw new Error(`No storage or key present for ${key}`);
                }
            })
        );
    };

    @action
    saveLanguageToStorage = async (language: Language) => {
        if (this.storage) {
            await this.storage.setItem(LocalizationStoreKeys.LanguageKey, language);
        } else {
            throw new Error('No storage or key is initialized');
        }
    };

    @action
    setAvailableLanguages = (availableLanguages: Language[]) => {
        this.availableLanguages = availableLanguages;
    };

    @action
    changeLanguage = async (language: Language) => {
        liveLocalized.setLanguage(language);
        uiLocalized.setLanguage(language);
        moment.locale(language);
        this.language = language;
        await this.saveLanguageToStorage(language);
    };
}
