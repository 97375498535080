import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import UITextButton from '#components/UITextButton';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { flex } from '#uikit/designCore/styles/flex';
import { common } from '#uikit/designCore/styles/comon';
import controllerService from '@services/ControllerService';

import './UITabView.scss';

export type TabViewPage = {
    title: string;
    component: ReactNode;
};

export type TabViewProps = {
    pages: TabViewPage[];
    width: number;
    indicatorWidth?: number;
    containerClassNames?: string;
    pageClassNames?: string;
    titleClassNames?: string;
    initialIndex?: number;
    onChangeTab?: (index: number) => void;
};

export const tabViewTestIDs = {
    tabTitle: (title: string) => `tabTitle_${title}`,
};

const UITabView = ({
    pages = [],
    width,
    indicatorWidth,
    containerClassNames,
    pageClassNames,
    initialIndex = 0,
    onChangeTab = () => {},
}: TabViewProps) => {
    const [currTabIndex, setCurrTabIndex] = useState<number>(initialIndex);

    const indicatorLineWidth = (indicatorWidth || width) / pages.length;

    const onPressTab = (index: number) => {
        onChangeTab(index);
        setCurrTabIndex(index);
    };

    const tapBar = (
        <div className={common.displayFlex}>
            {pages.map(({ title }: TabViewPage, index: number) => {
                return (
                    <div
                        key={`tab-view-label-${title}`}
                        onClick={() => onPressTab(index)}
                        className={classNames(common.displayFlex, flex.column, flex.flex1, flex.alignCenter)}
                    >
                        <UITextButton
                            title={title}
                            titleColor={index === currTabIndex ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
                            onPress={() => onPressTab(index)}
                            testID={tabViewTestIDs.tabTitle(title)}
                        />
                    </div>
                );
            })}
        </div>
    );

    const normalizedWidth = controllerService.isNarrow ? width - 32 : width;
    const indicatorLine = pages.length && width && (
        <div className={classNames(common.overflowHidden)}>
            <div
                className="UITabView__bottomLine"
                style={{
                    width: `${indicatorLineWidth}px`,
                    transform: `translateX(${(normalizedWidth / pages.length) * currTabIndex}px)`,
                }}
            />
        </div>
    );

    const pagesComponent = (
        <div className={classNames(common.overflowHidden, pageClassNames)}>
            <div
                className={classNames('UITabView__pages', common.displayFlex)}
                style={{ transform: `translateX(${-width * currTabIndex}px)` }}
            >
                {pages.map(({ title, component }: TabViewPage, index) => (
                    <div
                        className={flex.flex1}
                        style={{ width: `${width}px`, minWidth: `${width}px` }}
                        key={`tab-view-page-${title}`}
                    >
                        <div>{component}</div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className={classNames(common.displayFlex, flex.column, flex.alignSelfCenter, containerClassNames)}>
            {tapBar}
            {indicatorLine}
            {pagesComponent}
        </div>
    );
};

export default UITabView;
