import React, { useState } from 'react';
import { observer } from 'mobx-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';

import { languagesInfo, Language } from '@services/LocalizationService/constants';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { localizationService } from '@services/LocalizationService';
import { UIButton } from '#components';
import PopoverMenu from '#components/Popover/PopoverMenu';
import { padding } from '#uikit/designCore/styles';

const getMenuItems = (
    onPress: (language: Language) => void
): { title: string; onPress: (language: Language) => void; chosen: boolean }[] => {
    return localizationService.availableLanguages.map((language) => ({
        title: languagesInfo[language].name,
        onPress: () => onPress(language),
        chosen: language === localizationService.language,
    }));
};

const onPress = (language: Language) => {
    if (language !== localizationService.language) {
        localizationService.changeLanguage(language);
    }
};

const LangSwitcher: React.FC = () => {
    const [isShow, setIsShow] = useState(false);
    return (
        <PopoverMenu
            menuItems={getMenuItems(onPress)}
            onShow={() => setIsShow(true)}
            onHide={() => setIsShow(false)}
            componentClassNames={padding.horizontal}
        >
            <UIButton
                classNames={padding.horizontalTiny}
                title={languagesInfo[localizationService.language].name}
                iconR={KeyboardArrowDownIcon}
                iconRClassNames={classnames('arrowDrop', { show: isShow })}
                buttonColor={isShow ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
                buttonSize={UIButton.buttonSize.large}
            />
        </PopoverMenu>
    );
};

export default observer(LangSwitcher);
