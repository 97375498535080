import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { BackgroundView, UILabel } from '#components';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { border, padding } from '#uikit/designCore/styles';

import './Alert.scss';

let closeTimeout: NodeJS.Timeout = setTimeout(() => {});

const Alert: React.FC = () => {
    const isOpen = controllerService.isAlertOpen;

    useEffect(() => {
        if (isOpen) {
            closeTimeout = setTimeout(() => controllerService.setIsAlertOpen(false), 3000);
        } else {
            clearTimeout(closeTimeout);
        }
    }, [isOpen]);

    return (
        <BackgroundView
            color={ColorVariants.StaticBackgroundBlack}
            classNames={classnames('Alert', padding.default, border.radiusMedium, {
                open: isOpen,
            })}
        >
            <UILabel color={ColorVariants.StaticTextPrimaryLight} role={TypographyVariants.ParagraphFootnote}>
                {controllerService.alertText}
            </UILabel>
        </BackgroundView>
    );
};

export default observer(Alert);
