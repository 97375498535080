import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONFilterTags } from '#TONClient';
import type { TONDePoolT } from '#TONClient/TONDePool/types';
import { LinkWithChild, ShortBalanceView, UILabel, FlexContainer, UIDetailsButton } from '#components';
import { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import { paths } from '#navigation/paths';
import { common } from '#uikit/designCore/styles/comon';
import { container } from '#uikit/designCore/styles/container';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { ColorVariants } from '#uikit/designCore/constants/color';

type Props = {
    item: TONDePoolT;
    index: number;
};

const dePoolViewTestIDs = {
    dePoolView: 'de-pool-view',
};

const DePoolView = ({ item, index }: Props) => {
    if (!item) return null;
    const narrow = controllerService.isNarrow;

    const { id, name, avatar, validatorAssurance, validatorRewardFraction, participantsCount, stakes } = item;

    const detailsStr = (caption: string, value: number) => {
        const secondCaption = narrow ? '' : `${caption}: `;
        return `${secondCaption}${value}`;
    };

    const participantsStr = detailsStr(liveLocalized.ParticipantQty, participantsCount);
    const captionComponent = (
        <UILabel
            color={ColorVariants.TextSecondary}
            role={TypographyVariants.ParagraphFootnote}
            classNames={classNames(common.displayFlex, container.centerLeft)}
        >
            <div>{participantsStr}</div>
            &nbsp;|&nbsp;
            <div>{!narrow && `${liveLocalized.Stakes}:\u00A0`}</div>
            <ShortBalanceView balance={stakes} />
        </UILabel>
    );

    const rewardStr = detailsStr(liveLocalized.ValidatorRewardFraction, validatorRewardFraction);
    const secondDetails = (
        <FlexContainer alignItems="alignBaseline">
            {rewardStr}
            {validatorRewardFraction ? '%' : ''}
            {' | \u00A0'}
            <div>{!narrow && `${liveLocalized.ValidatorAssuranceShort}:`}&nbsp;</div>
            <ShortBalanceView balance={validatorAssurance} />
        </FlexContainer>
    );

    return (
        <LinkWithChild navigationPath={paths.dePools.details} params={{ id }}>
            <UIDetailsButton
                testID={dePoolViewTestIDs.dePoolView}
                index={index}
                copyTarget={UIDetailsButtonCopyTargets.details}
                image={avatar}
                title={name}
                truncTitle={narrow}
                captionComponent={captionComponent}
                truncDetails
                details={id}
                secondDetails={secondDetails}
            />
        </LinkWithChild>
    );
};

DePoolView.externalConfigs = {
    sortingKey: 'stakes',
    filterParams: {
        //@ts-ignore
        minMax: TONFilterTags.getDePoolFilterMinMaxParams(true),
        expression: {
            name: 'name',
        },
    },
};

const ObservingDePoolView = observer(DePoolView);
DePoolView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: TONDePoolT): string => {
            return `de-pool-view-${id}`;
        },
        idKey: 'id',
        collectionName: liveLocalized.DePools,
        renderItem: ({ item }: { item: any }) => <ObservingDePoolView index={item?.place} item={item} />,
    };
};

export default DePoolView;
