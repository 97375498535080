// @flow
import { TONAccount, EVERBlock, TONFilter } from '#TONClient';
import type { BlockFilterValues, TONMasterConfig } from '#TONClient/EVERBlock/types';
import type { FilterValues } from '#TONClient/TONFilter';
import TONValidator from '#TONClient/TONValidator';
import Utils from '#helpers/Utils';
import { ValidatorsElectionTime } from '#TONClient/TONValidator/types';
import MomentHelper from '#helpers/MomentHelper';
import { liveLocalized } from '@services/LocalizationService';

export const loadShards = async (
    filterValues: FilterValues
): Promise<{ filterValues: BlockFilterValues, shardsCount: number }> => {
    const masterBlock = await EVERBlock.getMasterblockShards();
    const workchain_ids = {
        '-1': true,
    };

    (masterBlock?.master?.shard_hashes || []).forEach((item) => {
        if (!workchain_ids[item.workchain_id]) {
            workchain_ids[item.workchain_id] = true;
        }
    });
    const newFilterValues: BlockFilterValues = {
        ...(filterValues || {}),
        shard: {
            value: filterValues?.shard?.value || null,
            list: masterBlock?.master?.shard_hashes || [],
        },
        workchain_id: {
            // $FlowExpectedError ( property workchain_id is missing in object type )
            value: filterValues?.workchain_id?.value,
            list: Object.keys(workchain_ids).map((item) => Number(item)),
        },
    };

    return {
        filterValues: newFilterValues,
        shardsCount: masterBlock?.master?.shard_hashes?.length,
    };
};

export const loadSignedBlocks = async (filterValues: FilterValues): Promise<number> => {
    const config: ?TONMasterConfig = await TONValidator.getMasterConfig();
    if (!config) return 0;

    return EVERBlock.aggregateBlocks({
        ...(filterValues || {}),
        minTime: TONFilter.prepareValue(config?.p34?.utime_since),
    });
};

export const loadTotalBlocks = async (filterValues: FilterValues): Promise<number> => {
    // this custom query is needed for fast loading for workchain_id == 0
    let blCount;
    const mappedFV = TONFilter.getValuesFromFilterValues(filterValues);
    if (
        Utils.objectValues(mappedFV).reduce((prev, curr) => prev + (curr != null ? 1 : 0), 0) === 1 &&
        mappedFV.workchain_id === 0
    ) {
        const [masterBlCount, allBlCount] = await Promise.all([
            EVERBlock.aggregateMasterchainBlocks(),
            EVERBlock.aggregateBlocks(),
        ]);
        blCount = allBlCount - masterBlCount;
    } else {
        blCount = await EVERBlock.aggregateBlocks(filterValues);
    }

    return blCount;
};

export const loadTotalBalances = async (
    filterValues: FilterValues
): Promise<{ aggregatedBalance: number, aggregatedGiversBalance: number }> => {
    const [aggregatedBalance, aggregatedGiversBalance] = await Promise.all([
        TONAccount.aggregateAccountsBalance(filterValues),
        TONAccount.aggregateAccountsBalanceInGivers(filterValues),
    ]);

    return { aggregatedBalance, aggregatedGiversBalance };
};

export const getValidatorsElectionsTime = (masterConfig: TONMasterConfig | {}): ValidatorsElectionTime => {
    let electionsStart = 0;
    let electionsEnd = 0;
    let nextElectionsStart = 0;
    let nextElectionsEnd = 0;
    let electionsTitle = '';

    const { p15, p34, p36 } = masterConfig;
    const { elections_start_before, elections_end_before, validators_elected_for } = p15 || {};
    const now = MomentHelper.now();

    if (p36) {
        const { utime_until } = p36 || {};

        electionsStart = utime_until - elections_start_before;
        electionsEnd = utime_until - elections_end_before;
    } else {
        const { utime_until } = p34 || {};

        electionsStart = utime_until - elections_start_before;
        electionsEnd = utime_until - elections_end_before;
    }

    nextElectionsStart = electionsStart + validators_elected_for;
    nextElectionsEnd = electionsEnd + validators_elected_for;
    electionsTitle =
        !!electionsStart && !!electionsEnd
            ? electionsStart < now && now < electionsEnd
                ? liveLocalized.Open
                : liveLocalized.Closed
            : '';

    return { electionsStart, electionsEnd, nextElectionsStart, nextElectionsEnd, electionsTitle };
};

export const defaultCollectionConfigs = {
    idKey: '',
    renderItem: () => null,
    keyExtractor: () => '',
    idName: '',
    newItemName: '',
    collectionName: '',
};
