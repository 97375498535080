import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useParams = (): [
    { [key: string]: string },
    (newParams: { [p: string]: string | number | boolean }, savePrevParams?: boolean) => void
] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const preparedParams = useMemo(() => {
        const newCurrentParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => (newCurrentParams[key] = value));

        return newCurrentParams;
    }, [searchParams]);

    const setParams = (newParams: { [key: string]: string | number | boolean }, savePrevParams: boolean = true) => {
        const resultParams = Object.fromEntries<string>(
            Object.entries({ ...(savePrevParams ? preparedParams : {}), ...newParams })
                .filter(([_, value]) => value || value === 0 || value === false)
                .map(([key, value]) => [key, String(value)])
        );

        setSearchParams(resultParams);
    };

    return [preparedParams, setParams];
};

export { useParams };
