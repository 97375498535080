import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONFilterTags, EVERTip3 } from '#TONClient';
import type { GetTokenArgs, TokenStatisticsType } from '#TONClient/EVERTip3/types';
import { FilterValues } from '#TONClient/TONFilter';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import ListScreen from '#controllers/Lists/ListScreen';
import Tip3TokenView from '#components/EVERList/ListViews/Tokens/Tip3TokenView';

const Tip3TokensComponent: React.FC = () => {
    const ref = useRef(null);

    const [params] = useParams();

    const [{ numberOfTokens, numberOfWallets }, setTokenStatistics] = useState<Partial<TokenStatisticsType>>({});

    const onFocus = async () => {
        controllerService.showSpinnerOverlay();
        EVERTip3.getTokenStatistics().then((statistics) => {
            if (statistics) setTokenStatistics(statistics);
            controllerService.hideSpinnerOverlay();
        });
    };

    const itemsLoader = useCallback((args: GetTokenArgs, filterValues?: FilterValues) => {
        return EVERTip3.getTIP32Tokens(args, filterValues);
    }, []);

    const details = [
        {
            title: numberOfTokens || '',
            details: liveLocalized.NumberTip3Tokens,
        },
        {
            title: numberOfWallets || '',
            details: liveLocalized.NumberTip3Wallets,
        },
    ];

    useEffect(() => {
        return onWillFocus(params, ref, onFocus);
    }, [params]);

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Tip3Tokens}
            details={details}
            realtimeUpdated={false}
            collectionConfigs={Tip3TokenView.getCollectionConfigs()}
            //@ts-ignore doesn`t see method
            filterProps={TONFilterTags.getTIP3TokensProps()}
            externalControlConfigs={{
                ...Tip3TokenView.externalConfigs,
            }}
            itemsLoader={itemsLoader}
        />
    );
};

const Tip3Tokens = observer(Tip3TokensComponent);

export { Tip3Tokens };
