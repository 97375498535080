import React from 'react';
import classnames from 'classnames';

//@ts-ignore doesn`t see import
import ProgressIcon from '#assets/progress.png';

import './Spinner.scss';

type Props = {
    classNames?: string;
};
const Spinner: React.FC<Props> = ({ classNames }) => (
    <img className={classnames('Spinner', classNames)} src={ProgressIcon} alt="progress" />
);

export default Spinner;
