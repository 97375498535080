// @flow
import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import { TONFilterTags, TONMessage } from '#TONClient';
import type { GetMessagesArgs, TONMessageT } from '#TONClient/TONMessage/types';
import type { FilterValues } from '#TONClient/TONFilter';
import animationData from '#assets/animations/data3.json';
import { MessageView } from '#components';
import { loadShards } from '#controllers/Lists/helpers';

import ListScreen from '../ListScreen';

const MessagesComponent = () => {
    const ref = useRef(null);

    const [messagesCount, setMessagesCount] = useState<number>(0);

    // Details
    const details = [
        {
            details: `${liveLocalized.Current} ${liveLocalized.MPS}`,
            isHiddenOnAsc: true,
            isItemsPerSecond: true,
        },
        {
            title: liveLocalized.amountToLocale(messagesCount),
            details: liveLocalized.MessagesCount,
        },
    ];

    // Actions
    const itemsLoader = useCallback((args: GetMessagesArgs, filterValues: FilterValues): Promise<TONMessageT[]> => {
        return TONMessage.getMessages({
            ...args,
            filterValues,
        });
    }, []);

    const itemsAggregator = (filterValuesWithTime: FilterValues) => TONMessage.aggregateMessages(filterValuesWithTime);

    const aggregateMessages = async (filterValues?: FilterValues) => {
        try {
            TONMessage.aggregateMessages(filterValues || {}).then((count) => {
                setMessagesCount(count);
            });

            loadShards(filterValues || {}).then((result) => {
                ref?.current && ref.current.updateFilterValues(result.filterValues || {});
            });
        } catch (e) {
            controllerService.showServiceUnavailable();
        }
    };

    const unshiftNewMessage = (msg: ?TONMessageT) => {
        ref?.current && msg && ref.current.unshiftItem(msg, 'created_at');
        ref?.current && ref.current.unshiftNewItem(msg?.created_at);
    };

    // Events
    const onLoadingNew = (msg: ?TONMessageT): void => {
        unshiftNewMessage(msg);
    };

    const onSubscribeForUpdate = (filterValues: FilterValues): void => {
        TONMessage.subscribeForUpdate(onLoadingNew, { filterValues });
    };

    const onUnsubscribeForUpdate = (): void => {
        TONMessage.unsubscribe();
    };

    const onStartReload = (filterValues: FilterValues): void => {
        aggregateMessages(filterValues);
    };

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Messages}
            listTitle={liveLocalized.Latest}
            shouldHideListTitleOnASC
            details={details}
            itemsLoader={itemsLoader}
            itemsAggregator={itemsAggregator}
            animationData={animationData}
            filterProps={TONFilterTags.getMessageProps()}
            collectionConfigs={MessageView.getCollectionConfigs(null)}
            allowFilterSubscription
            realtimeUpdated
            onSubscribeForUpdate={onSubscribeForUpdate}
            onUnsubscribeForUpdate={onUnsubscribeForUpdate}
            onStartReload={onStartReload}
        />
    );
};

const Messages = observer(MessagesComponent);

export { Messages };
