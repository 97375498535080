import { Maybe } from 'graphql/jsutils/Maybe';

import { TONLog } from '#TONUtility';

import TONClient from '../TONClient';
import TONFilter from '../TONFilter';
import TONValidator from '../TONValidator';
import { NOT_FOUND, SUCCESS } from '../statuses';
import { getBlocksQuery, newResult, orderByTimeAndNumber } from './helpers';
import type { AggregateBlockSignatures, BlockFilterValues, GetBlocksArgs, TONBlockT } from './types';
import { additionalFields, masterBlockShardsFields, shortFields, binaryFields, hashField, listFields } from './fields';
import Utils from '#helpers/Utils';

let newBlocksById = {};
let subscription;

const customLog = new TONLog('EVERBlock');

export default class EVERBlock {
    static statuses = {
        unknown: 0,
        proposed: 1,
        finalized: 2,
        refused: 3,
    };

    static shortFields = shortFields;

    static oldResult(args?: Maybe<GetBlocksArgs>, needP40: boolean = false) {
        return `
            ${listFields}
            ${args?.needAdditionalFields ? additionalFields(needP40) : ''}
            ${args?.needBinaryFields ? binaryFields : ''}
        `;
    }

    static oldFormatter = (doc: TONBlockT): TONBlockT => ({
        ...doc,
        parent_id: doc?.prev_ref?.root_hash,
    });

    static newFormatter = (doc: TONBlockT): TONBlockT => ({
        ...doc,
        id: doc?.hash || '',
        parent_id: doc?.prev_ref?.root_hash,
    });

    static filter(args: Maybe<GetBlocksArgs>, isJSFetchQuery: boolean = false) {
        if (!args) {
            return {};
        }

        const { filterValues } = args;
        return {
            // ...(lastItem ? { seq_no: { le: lastItem?.seq_no } } : {}),
            ...TONFilter.timePaginationFilter({
                args,
                key: 'gen_utime',
            }),
            ...TONFilter.workchainKeyBlockFilter(filterValues),
            ...TONFilter.workchainShardFilter(filterValues, isJSFetchQuery),
            ...TONFilter.minMaxNumberFilter({
                key: 'tr_count',
                minParam: filterValues?.minTransactions ?? null,
                maxParam: filterValues?.maxTransactions ?? null,
                isNumber: true,
            }),
        };
    }

    static orderBy(args: Maybe<GetBlocksArgs>) {
        if (!args) {
            return [];
        }

        const { filterValues } = args;
        const path = filterValues?.key_block?.value ? 'seq_no' : 'gen_utime';

        return TONFilter.orderBy(args, path);
    }

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('EVERBlock', str, params);
    }

    static async getBlocks(args: GetBlocksArgs, ids?: string[]) {
        const filter = this.filter(args);
        const log = this.initLog('list', { args, filter, ids: ids?.length });

        try {
            const query = await getBlocksQuery(args);
            if (!query) return [];

            const { blocks, endCursor } = await TONClient.queryBlocksFromBlockchainApi(
                query,
                args.filterValues?.key_block?.value || false
            );
            TONFilter.sortBy(blocks, args, 'gen_utime');

            const formattedBlocks = blocks.map((block, index) => {
                return {
                    ...EVERBlock.newFormatter(block),
                    ...(index === blocks.length - 1 ? { endCursor } : {}),
                };
            });

            log.debug(SUCCESS, formattedBlocks);
            return formattedBlocks;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getMasterblockShards(): Promise<TONBlockT> {
        const log = this.initLog('shards');
        try {
            const shards = await TONClient.queryBlockShards(masterBlockShardsFields, 1);
            log.debug(SUCCESS, shards);
            return shards;
        } catch (e) {
            log.error(e);
            throw e;
        }
    }

    static async getBlock(bId: string, args?: GetBlocksArgs, logInfo: string = '') {
        const log = this.initLog(bId, logInfo);

        try {
            let docs = await TONClient.queryBlock(bId, newResult(args));

            if (docs?.hash) {
                const formattedBlock = this.newFormatter(docs);
                log.debug(SUCCESS, formattedBlock);

                return formattedBlock;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            if (err.message.includes('code: 400')) {
                log.debug(NOT_FOUND);
                return null;
            }

            log.error(err);
            throw err;
        }
    }

    static async getChildForBlock(block: TONBlockT) {
        const log = this.initLog('child', block);

        try {
            const docs = await TONClient.queryBlockBySeqNo(
                block.workchain_id,
                block.seq_no + 1,
                block.shard,
                hashField
            );

            if (docs?.hash) {
                const formattedBlock = this.newFormatter(docs);
                log.debug(SUCCESS, formattedBlock);
                return formattedBlock;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getKeyBlockForBlock(block: TONBlockT) {
        const log = this.initLog('key block', block);

        try {
            if (!block.prev_key_block_seqno) {
                log.debug('prev_key_block_seqno was not passed');
                return null;
            }

            const docs = await TONClient.queryBlockBySeqNo(
                -1,
                block.prev_key_block_seqno,
                '8000000000000000',
                hashField
            );

            if (docs?.hash) {
                const formattedBlock = this.newFormatter(docs);
                log.debug(SUCCESS, formattedBlock);
                return formattedBlock;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getBlockIdsByNumberFromCollection(seq_no: number) {
        const log = this.initLog(seq_no);

        try {
            const docs = await TONClient.queryCollectionBlocks(
                {
                    seq_no: { eq: seq_no },
                },
                listFields,
                orderByTimeAndNumber
            );

            if (docs?.length) {
                const formattedBlocks: TONBlockT[] = docs.map(this.oldFormatter);
                log.debug(SUCCESS, formattedBlocks);
                return formattedBlocks;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getSignedBlocks(args: GetBlocksArgs, public_key: string) {
        if (!public_key) {
            customLog.debug(NOT_FOUND, 'no public_key for validators');
            return [];
        }

        const nodeId = TONValidator.getNodeId(public_key);
        const filter = {
            signatures: {
                any: {
                    node_id: { eq: `"${nodeId}"` },
                },
            },
            ...TONFilter.timePaginationFilter({ args, key: 'gen_utime' }),
        };

        const log = this.initLog('list of block-signatures for validator', {
            public_key,
            filter,
        });
        const blockSignDocs = await TONClient.queryBlocksSignatures(
            Utils.JSONStringifyWithoutParanthesesAroundKeys(filter),
            'id gen_utime'
        );

        if (blockSignDocs.length) {
            log.debug(SUCCESS, nodeId, blockSignDocs);
            const blockIds = blockSignDocs.map((item) => item.id);
            return this.getBlocks(args, blockIds);
        }

        log.debug(NOT_FOUND);
        return [];
    }

    static async aggregateBlockSignatures(
        nodeIds: string[] | string = [],
        startTime?: number,
        endTime?: number
    ): Promise<AggregateBlockSignatures> {
        const log = this.initLog('aggregate of block-signatures for nodeIds', {
            nodeIds,
            startTime,
            endTime,
        });

        if (!nodeIds) return { signaturesByNodeIds: {}, total: 0 };

        const nodeIdsArr = typeof nodeIds === 'string' ? [nodeIds] : nodeIds;

        try {
            const query = (nodeId) =>
                TONClient.queryAggregateBlockSignatures(
                    Utils.JSONStringifyWithoutParanthesesAroundKeys({
                        ...(startTime || endTime
                            ? {
                                  gen_utime: {
                                      ...(startTime && { gt: startTime }),
                                      ...(endTime && { le: endTime }),
                                  },
                              }
                            : {}),
                        signatures: {
                            any: {
                                node_id: { eq: `"${nodeId}"` },
                            },
                        },
                    })
                );

            // TODO replace on batch in future
            const signsForNodeIds = await Promise.all(nodeIdsArr.map((nodeId) => query(nodeId)));

            const signaturesByNodeIds = {};
            nodeIdsArr.forEach((nodeId, index) => {
                signaturesByNodeIds[nodeId] = Number(signsForNodeIds[index][0]);
            });

            const total = signsForNodeIds.reduce((prev, curr) => prev + Number(curr[0]), 0);

            log.debug(SUCCESS, { signaturesByNodeIds, total });

            return { signaturesByNodeIds, total };
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async aggregateMasterchainBlocks(filterValues: BlockFilterValues = {}) {
        return this.aggregateBlocks({ ...filterValues, workchain_id: { value: -1, list: [] } });
    }

    static async aggregateBlocks(filterValues: BlockFilterValues = {}) {
        const filter = this.filter({ filterValues }, true);
        const log = this.initLog('blocks count', { filterValues, filter });

        try {
            const blocksCount = await TONClient.aggregateBlocks(
                Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : ''
            );

            log.debug(SUCCESS, blocksCount);
            return blocksCount;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static unsubscribe() {
        if (subscription) {
            customLog.debug('Stop listen blocks update...');
            TONClient.unsubscribe(subscription);
        }
    }

    static async subscribeForUpdate(callback: (block: Maybe<TONBlockT>) => void, args: GetBlocksArgs) {
        this.unsubscribe();
        const filter = this.filter(args);
        customLog.debug('Start listening for blocks update...', args, filter);
        newBlocksById = {};

        subscription = await TONClient.subscribeCollectionBlocks(filter, this.oldResult(), (block) => {
            customLog.debug('Block was updated', block);

            if (!block || newBlocksById[block.id]) {
                return;
            }

            newBlocksById[block.id] = 1;
            callback(this.oldFormatter(block));
        });
    }
}
