import React from 'react';

import UILabel from '#components/UILabel';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin } from '#uikit/designCore/styles';

const Subtitle: React.FC = ({ children }) => (
    <UILabel
        classNames={margin.topMedium}
        role={TypographyVariants.HeadlineSubhead}
        color={ColorVariants.TextSecondary}
    >
        {children}
    </UILabel>
);

export { Subtitle };
