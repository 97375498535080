import { border } from './border';
import { color } from './color';
import { common } from './comon';
import { container } from './container';
import { flex } from './flex';
import { font } from './font';
import { height } from './height';
import { margin } from './margin';
import { padding } from './padding';
import { text } from './text';
import { typography } from './typography';
import { width } from './width';

export { border, color, common, container, flex, font, height, margin, padding, text, typography, width };
