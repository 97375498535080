// @flow
import type { GetTONItemsArgs } from '#components/EVERList';
import type { DePoolFilterValues } from '#TONClient/TONDePool/types';
import { TIP32FilterValues } from '#TONClient/EVERTip3/types';
import { TONString } from '#TONUtility';

import TONClient from './TONClient';
import type { AccountFilterValues } from './TONAccount/types';
import type { BlockFilterValues } from './EVERBlock/types';
import type { MessageFilterValues } from './TONMessage/types';
import type { TransactionFilterValues } from './EVERTransaction/types';
import { AccountTransferFilterValues } from './EVERTransfer/types';

export type TONTimeKey = 'created_at' | 'now' | '"now"' | 'gen_utime' | 'last_paid';

export type FilterValue<T> = { value: T, list: (string | number)[], strict?: boolean };

export type SortDirection = 'ASC' | 'DESC';

export type DefaultFilterValues = {
    workchain_id?: FilterValue<number>,
    minTime?: FilterValue<number>,
    maxTime?: FilterValue<number>,
    [string]: FilterValue<any>,
};

export type FilterValues =
    | AccountFilterValues
    | BlockFilterValues
    | MessageFilterValues
    | TransactionFilterValues
    | DePoolFilterValues
    | TIP32FilterValues
    | AccountTransferFilterValues;

export type FilterParams = {
    minMax: {
        [string]: {
            min: string,
            max: string,
            normalize?: boolean,
        },
    },
    expression?: {},
};

export type FilterByMinMaxParams = {
    value: number,
    min: ?number,
    max: ?number,
    normalize?: boolean,
};

export type TONDiapasonKeyNames = 'tr_count' | 'value' | 'balance_delta';

export type MinMaxNumberFilterArgs = {
    key: TONDiapasonKeyNames, // name of field for filter
    minParam: ?FilterValue<number>, // min value
    maxParam: ?FilterValue<number>, // max value
    denormalize?: boolean, // need convert nanograms to grams
    integer?: boolean,
    isNumber?: boolean,
};

export type TimePaginationFilterArgs = {
    args: GetTONItemsArgs | any,
    key: TONTimeKey,
    isNumber?: boolean,
};

export default class TONFilter {
    static masterchainFilter = {
        workchain_id: { eq: -1 },
    };

    static prepareValue(value: any): FilterValue<any> {
        return { value, list: [] };
    }

    static convertTonsToHexNano(value: number): string {
        const result = `0x${(value * 10 ** 9).toString(16)}`;
        return value < 0 ? `-${result.replace('-', '')}` : result;
    }

    static getValuesFromFilterValues(filterValues: FilterValues = {}, forURL: boolean = false): any {
        return Object.keys(filterValues).reduce((prev, curr: string) => {
            const filterValue = filterValues[curr];
            const mappedValue = filterValue?.value || filterValue?.list ? filterValue?.value : filterValue;
            let resultValue = forURL && !mappedValue && mappedValue !== 0 ? '' : mappedValue;
            if (!!resultValue && resultValue.shard && forURL) {
                resultValue = `${resultValue.workchain_id}:${resultValue.shard}`;
            }

            return {
                ...prev,
                [curr]: resultValue,
            };
        }, {});
    }

    static async accountWorkchainFilter(
        filterValues: MessageFilterValues,
        key?: string,
        isJSFetchQuery: boolean = false
    ) {
        if (!filterValues) return {};

        const { accountId, workchain_id } = this.getValuesFromFilterValues(filterValues);

        if (accountId && accountId.split) {
            const accountWorkchain = accountId.split(':')[0];
            if (workchain_id == null || Number(accountWorkchain) === workchain_id) {
                return TONFilter.accountFilter(filterValues, key);
            }
            return { id: { eq: null } };
        }
        return TONFilter.workchainStringFilter(filterValues, key, isJSFetchQuery);
    }

    static async accountFilter(filterValues?: FilterValues, key?: string) {
        if (!key) return {};

        const { accountId } = this.getValuesFromFilterValues(filterValues);
        const hexAccountId = await TONClient.convertAddressToHex(accountId);
        return hexAccountId ? { [key]: { eq: hexAccountId } } : {};
    }

    static workchainStringFilter(filterValues: FilterValues, key?: string, isJSFetchQuery: boolean = false) {
        const { workchain_id } = this.getValuesFromFilterValues(filterValues);
        return workchain_id == null || !key
            ? {}
            : {
                  [key]: {
                      ge: isJSFetchQuery ? `"${workchain_id}:"` : `${workchain_id}:`,
                      lt: isJSFetchQuery ? `"${workchain_id}:z"` : `${workchain_id}:z`,
                  },
              };
    }

    static workchainShardFilter(filterValues?: FilterValues, isJSFetchQuery: boolean = false) {
        const { workchain_id, shard } = this.getValuesFromFilterValues(filterValues);

        if (shard?.shard != null) {
            // only workchain 0 shards are available in filters
            if (workchain_id != null && workchain_id !== shard?.workchain_id) {
                return {
                    workchain_id: { eq: -2 }, // hack to show nothing in result
                };
            }

            return {
                shard: { eq: isJSFetchQuery ? `"${shard.shard}"` : shard.shard },
                workchain_id: { eq: shard?.workchain_id },
            };
        }

        if (workchain_id != null) {
            return {
                workchain_id: { eq: TONClient.number(workchain_id) },
            };
        }

        return {};
    }

    static workchainKeyBlockFilter(filterValues?: FilterValues): any {
        const { workchain_id, key_block } = this.getValuesFromFilterValues(filterValues);

        if (key_block === true) {
            return {
                workchain_id: { eq: -1 },
                key_block: { eq: key_block },
            };
        }

        if (workchain_id != null) {
            return {
                workchain_id: { eq: TONClient.number(workchain_id) },
            };
        }

        return {};
    }

    static timeFilter(key: string, filterValues: FilterValues) {
        const { maxTime, minTime } = this.getValuesFromFilterValues(filterValues);

        return (
            !!(minTime || maxTime) && {
                [(key: string)]: {
                    ...(maxTime && { le: maxTime }),
                    ...(minTime && { ge: minTime }),
                },
            }
        );
    }

    static timePaginationFilter({ args, key, isNumber = true }: TimePaginationFilterArgs) {
        const { lastItem, filterValues, direction = 'DESC' } = args;
        const { minTime, maxTime } = this.getValuesFromFilterValues(filterValues);
        const lastValue: number = (lastItem || {})[(key: string)];

        const totalMax =
            direction === 'DESC'
                ? lastValue && maxTime
                    ? Math.min(lastValue, maxTime)
                    : lastValue || maxTime
                : maxTime;

        const totalMin =
            direction === 'DESC' ? minTime : lastValue && minTime ? Math.max(lastValue, minTime) : lastValue || minTime;

        return (
            !!(totalMax || totalMin) && {
                [(key: string)]: {
                    ...(totalMax && { le: isNumber ? totalMax : `${totalMax}` }: any),
                    ...(totalMin && { ge: isNumber ? totalMin : `${totalMin}` }),
                },
            }
        );
    }

    static minMaxNumberFilter(args: MinMaxNumberFilterArgs, isJSFetchQuery: boolean = false) {
        const { key, denormalize = false, isNumber = false } = args;
        const filterValues = {};
        if (args.minParam) filterValues.minParam = args.minParam;
        if (args.maxParam) filterValues.maxParam = args.maxParam;

        // $FlowExpectedError
        const { minParam, maxParam } = this.getValuesFromFilterValues(filterValues);
        if (minParam == null && maxParam == null) {
            return {};
        }

        const processIfNeeded = (num) => {
            const denormalized = denormalize ? this.convertTonsToHexNano(num) : num;

            if (isNumber) return denormalized;
            else return isJSFetchQuery ? `"${denormalized}"` : `${denormalized}`;
        };

        const result = {};
        if (minParam != null) result.ge = processIfNeeded(minParam);
        if (maxParam != null) result.le = processIfNeeded(maxParam);

        return {
            [(key: string)]: result,
        };
    }

    static orderBy(
        args: GetTONItemsArgs | any,
        path: string | string[],
        isJSFetchQuery: boolean = false
    ): { path: string, direction: SortDirection }[] {
        const { direction } = args;
        const order: SortDirection = direction || 'DESC';

        if (Array.isArray(path)) {
            return path.reduce(
                (ar, item) => [
                    ...ar,
                    {
                        path: isJSFetchQuery ? `"${item}"` : item,
                        direction: order,
                    },
                ],
                []
            );
        }

        return [{ path: isJSFetchQuery ? `"${path}"` : path, direction: order }];
    }

    static sortBy(list: any[], args: GetTONItemsArgs | any, mainKey: string, spareKey?: string) {
        if (!list?.length) return;

        const key = list[0][mainKey] !== undefined ? mainKey : spareKey;
        const isString = typeof list[0][key] === 'string' && !TONString.parseAmount(list[0][key]);

        const sorter =
            args.direction === 'DESC'
                ? (a, b) => {
                      const aValue = isString ? a[key].toLowerCase() : TONString.parseAmount(a[key]) || 0;
                      const bValue = isString ? b[key].toLowerCase() : TONString.parseAmount(b[key]) || 0;
                      return bValue > aValue ? 1 : bValue < aValue ? -1 : 0;
                  }
                : (a, b) => {
                      const aValue = isString ? a[key].toLowerCase() : TONString.parseAmount(a[key]) || 0;
                      const bValue = isString ? b[key].toLowerCase() : TONString.parseAmount(b[key]) || 0;
                      return bValue > aValue ? -1 : bValue < aValue ? 1 : 0;
                  };
        list.sort(sorter);
    }

    static minMaxNumberFilterExternal({ value, min = null, max = null, normalize = false }: FilterByMinMaxParams) {
        const number = normalize ? TONClient.nanoToTons(value) : value;

        return (min === null || number >= min) && (max === null || number <= max);
    }

    static filterBy(list: { [string]: any }[], filterValues: FilterValues, filterParams: FilterParams) {
        const filterValuesMapped = this.getValuesFromFilterValues(filterValues);
        const filterer = (item: any): boolean => {
            const result = Object.keys(filterParams.minMax || {}).map((path) =>
                this.minMaxNumberFilterExternal({
                    value: item[path],
                    min: filterValuesMapped[filterParams.minMax[path].min],
                    max: filterValuesMapped[filterParams.minMax[path].max],
                    normalize: filterParams.minMax[path].normalize,
                })
            );

            const expressionResult = Object.keys(filterParams.expression || {}).map((path) => {
                if (filterValuesMapped[path]) {
                    return filterValues[path]?.strict
                        ? (item[path] || '').toLowerCase() === filterValuesMapped[path].toLowerCase()
                        : (item[path] || '').toLowerCase().includes(filterValuesMapped[path].toLowerCase());
                }

                return true;
            });

            return (
                result.reduce((prev, curr) => prev && curr, true) &&
                expressionResult.reduce((prev, curr) => prev && curr, true)
            );
        };

        // $FlowExpectedError
        return list.filter(filterer);
    }
}
