import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { paths } from '#navigation/paths';
import { MainScreen } from '#controllers/MainScreen';
import { Blocks } from '#controllers/Lists/Blocks';
import { Block } from '#controllers/Details/Block';
import { Messages } from '#controllers/Lists/Messages';
import { Message } from '#controllers/Details/Message';
import { Transactions } from '#controllers/Lists/Transactions';
import { Transaction } from '#controllers/Details/Transaction';
import { ZeroState } from '#controllers/Details/ZeroState';
import { Accounts } from '#controllers/Lists/Accounts';
import { Account } from '#controllers/Details/Account';
import { Contracts } from '#controllers/Lists/Contracts';
import { Contract } from '#controllers/Details/Contract';
import { Tip3Tokens } from '#controllers/Lists/Tip3Tokens';
import { KWT, Tip3Token } from '#controllers/Details/Tip3Token';
import { NFTCollections } from '#controllers/Lists/NFT';
import { NFT, NFTCollection } from '#controllers/Details/NFT';
import { Validators } from '#controllers/Lists/Validators';
import { Validator } from '#controllers/Details/Validator';
import { DePools } from '#controllers/Lists/DePools';
import { DePool } from '#controllers/Details/DePool';
import { Transfer } from '#controllers/Details/Transfer';
import { LegalNotes } from '#controllers/LegalNotes';

const Routing = () => (
    <Routes>
        <Route path="/" element={<Navigate to={paths.main} />} />
        <Route path="/*" element={<Navigate to={paths.main} />} />
        <Route path={paths.main} element={<MainScreen />} />
        <Route path={paths.blocks.main} element={<Blocks />} />
        <Route path={paths.blocks.details} element={<Block />} />
        <Route path={paths.messages.main} element={<Messages />} />
        <Route path={paths.messages.details} element={<Message />} />
        <Route path={paths.transactions.main} element={<Transactions />} />
        <Route path={paths.transactions.details} element={<Transaction />} />
        <Route path={paths.zeroState} element={<ZeroState />} />
        <Route path={paths.accounts.main} element={<Accounts />} />
        <Route path={paths.accounts.details} element={<Account />} />
        <Route path={paths.contracts.main} element={<Contracts />} />
        <Route path={paths.contracts.details} element={<Contract />} />
        <Route path={paths.TIP32.main} element={<Tip3Tokens />} />
        <Route path={paths.TIP32.details} element={<Tip3Token />} />
        <Route path={paths.KWT} element={<KWT />} />
        <Route path={paths.NFT.main} element={<NFTCollections />} />
        <Route path={paths.NFT.collection} element={<NFTCollection />} />
        <Route path={paths.NFT.token} element={<NFT />} />
        <Route path={paths.validators.main} element={<Validators />} />
        <Route path={paths.validators.details} element={<Validator />} />
        <Route path={paths.dePools.main} element={<DePools />} />
        <Route path={paths.dePools.details} element={<DePool />} />
        <Route path={paths.transfers.details} element={<Transfer />} />
        <Route path={paths.legalNotes} element={<LegalNotes />} />
    </Routes>
);

export { Routing };
