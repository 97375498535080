import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';

import { LinkWithChild, ShortBalanceView } from '#components';
import type { TONCollectionConfigs } from '#components/EVERList';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import { TONClient } from '#TONClient';
import type { TONAccountT } from '#TONClient/TONAccount/types';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { paths } from '#navigation/paths';

type Props = {
    item: Maybe<TONAccountT>;
    totalBalance: number;
};

export const accountViewTestIDs = {
    accountView: 'account-view',
};

const AccountView = ({ item = null, totalBalance = 0 }: Props) => {
    if (!item) return null;

    const { id, balance, name, last_paid } = item;
    const percentNumber = totalBalance && balance ? (TONClient.number(balance) / totalBalance) * 100 : 0;
    const fraction = Utils.roundToMeaningDigit(percentNumber);
    const percent = fraction ? (fraction >= 100 ? '-' : `${liveLocalized.amountToLocale(fraction)} %`) : '';
    const lastPaidStr = last_paid ? `${liveLocalized.LastTx}: ${MomentHelper.getTimeDate(last_paid)}` : '';

    return (
        <LinkWithChild navigationPath={paths.accounts.details} params={{ id: item.id }}>
            <UIDetailsButton
                testID={accountViewTestIDs.accountView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                copyValue={id}
                {...(name
                    ? {
                          title: name,
                      }
                    : {
                          truncTitle: true,
                          title: id,
                      })}
                captionComponent={<ShortBalanceView balance={balance} />}
                details={lastPaidStr}
                secondDetails={percent}
            />
        </LinkWithChild>
    );
};

AccountView.externalConfigs = {
    items: null,
    sortingKey: 'balance',
};

AccountView.getCollectionConfigs = (totalBalance: number): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: { id: string }): string => {
            return `account-view-${id}`;
        },
        idKey: 'id',
        collectionName: liveLocalized.Accounts,
        //@ts-ignore doesn`t calculate right type
        collectionKey: TONClient.collections.accounts,
        renderItem: ({ item }: any) => (
            <AccountView
                item={item}
                totalBalance={totalBalance} // taken from accounts screen
            />
        ),
    };
};

export default AccountView;
