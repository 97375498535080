export enum ColorVariants {
    TextPrimary = 'TextPrimary',
    TextSecondary = 'TextSecondary',
    TextTertiary = 'TextTertiary',
    TextPrimaryInverted = 'TextPrimaryInverted',
    TextNeutral = 'TextNeutral',
    TextAccent = 'TextAccent',
    TextNegative = 'TextNegative',
    TextPositive = 'TextPositive',
    TextWarning = 'TextWarning',
    TextOverlay = 'TextOverlay',
    TextOverlayInverted = 'TextOverlayInverted',
    // TextNulled = 'TextNulled',
    BackgroundPrimary = 'BackgroundPrimary',
    BackgroundSecondary = 'BackgroundSecondary',
    BackgroundTertiary = 'BackgroundTertiary',
    BackgroundNeutral = 'BackgroundNeutral',
    BackgroundAccent = 'BackgroundAccent',
    BackgroundNegative = 'BackgroundNegative',
    BackgroundPositive = 'BackgroundPositive',
    BackgroundWarning = 'BackgroundWarning',
    BackgroundPrimaryInverted = 'BackgroundPrimaryInverted',
    // BackgroundSecondaryInverted = 'BackgroundSecondaryInverted',
    BackgroundTertiaryInverted = 'BackgroundTertiaryInverted',
    BackgroundOverlay = 'BackgroundOverlay',
    BackgroundOverlayInverted = 'BackgroundOverlayInverted',
    // BackgroundNulled = 'BackgroundNulled',
    // BackgroundBAndW = 'BackgroundBAndW',
    LinePrimary = 'LinePrimary',
    LineSecondary = 'LineSecondary',
    LineTertiary = 'LineTertiary',
    LineNeutral = 'LineNeutral',
    LineAccent = 'LineAccent',
    LineNegative = 'LineNegative',
    LinePositive = 'LinePositive',
    IconAccent = 'IconAccent',
    // IconPrimary = 'IconPrimary',
    IconSecondary = 'IconSecondary',
    // IconTertiary = 'IconTertiary',
    // IconPrimaryInverted = 'IconPrimaryInverted',
    IconNeutral = 'IconNeutral',
    // IconNegative = 'IconNegative',
    // That ones are for technical reasons
    Transparent = 'Transparent',
    KeyboardStyle = 'KeyboardStyle',
    StaticTextPrimaryDark = 'StaticTextPrimaryDark',
    StaticTextPrimaryLight = 'StaticTextPrimaryLight',
    StaticTextOverlayDark = 'StaticTextOverlayDark',
    StaticTextOverlayLight = 'StaticTextOverlayLight',
    StaticBackgroundBlack = 'StaticBackgroundBlack',
    StaticBackgroundWhite = 'StaticBackgroundWhite',
    StaticIconPrimaryDark = 'StaticIconPrimaryDark',
    StaticIconPrimaryLight = 'StaticIconPrimaryLight',
    StaticHoverOverlay = 'StaticHoverOverlay',
    StaticPressOverlay = 'StaticPressOverlay',
    StaticBlack = 'StaticBlack',
    StaticBackgroundAccent = 'StaticBackgroundAccent',
    StaticBackgroundNegative = 'StaticBackgroundNegative',
    StaticBackgroundPositive = 'StaticBackgroundPositive',
    Shadow = 'Shadow',
}
