import UIConstant from '../constants';

export const heightStyles = {
    none: {
        height: null,
    },
    tinyCell: {
        // 16
        height: UIConstant.tinyCellHeight(),
    },
    littleCell: {
        // 20
        height: UIConstant.tinyButtonHeight(),
    },
    smallCell: {
        // 24
        height: UIConstant.smallCellHeight(),
    },
    mediumCell: {
        // 40
        height: UIConstant.mediumCellHeight(),
    },
    defaultCell: {
        // 48
        height: UIConstant.defaultCellHeight(),
    },
    bigCell: {
        // 56
        height: UIConstant.bigCellHeight(),
    },
    greatCell: {
        // 72
        height: UIConstant.greatCellHeight(),
    },
    majorCell: {
        // 80
        height: UIConstant.majorCellHeight(),
    },

    minHeightGreatCell: {
        // 72
        minHeight: UIConstant.greatCellHeight(),
    },

    buttonHeight: {
        // 48
        height: UIConstant.buttonHeight(),
    },
    fullHeight: {
        height: '100%',
    },
};

export default class UIStyleHeight {
    static none() {
        // null
        return heightStyles.none;
    }

    static tinyCell() {
        // 16
        return heightStyles.tinyCell;
    }

    static littleCell() {
        // 20
        return heightStyles.littleCell;
    }

    static smallCell() {
        // 24
        return heightStyles.smallCell;
    }

    static mediumCell() {
        // 40
        return heightStyles.mediumCell;
    }

    static defaultCell() {
        // 48
        return heightStyles.defaultCell;
    }

    static bigCell() {
        // 56
        return heightStyles.bigCell;
    }

    static greatCell() {
        // 72
        return heightStyles.greatCell;
    }

    static majorCell() {
        // 80
        return heightStyles.majorCell;
    }

    static minHeightGreatCell() {
        // 72
        return heightStyles.minHeightGreatCell;
    }

    static buttonHeight() {
        // 48
        return heightStyles.buttonHeight;
    }

    static full() {
        return heightStyles.fullHeight;
    }
}
