// @flow
export const otherCurrencyQuery = `{
    value
    currency
}`;

export const mainPageStatisticQuery = `query {
  statistics {
    blocks {
      countByVersion(period: DAY)
      totalCount
      countByCurrentValidators
      ratePerSecond
    }
    messages {
      totalCount
      ratePerSecond
    }
    transactions {
      totalOrdinaryCount
      lastDayOrdinaryCount
      ratePerSecond
    }
    accounts {
      totalCount
      totalSupply
      amountOnGivers
      circulatingSupply
      lastDayCount
      accountTypesCount
    }
    depools {
      activeDepoolCount
      activeParticipantsCount
      totalRewards
      totalStaked
    }
    validators {
      totalCount
      lastCycleCountDelta
      totalStaked
      rewardsPer30Days
      apr
    }
  }
}
`;

export const blocksStatisticQuery = `query {
  statistics {
    version
    blocks {
      totalCount
      countByCurrentValidators
      ratePerSecond
    }
  }
}
`;
