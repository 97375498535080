import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { EVERTip3 } from '#TONClient';
import { GetHolderArgs } from '#TONClient/EVERTip3/types';
import { getHolders, getWallets } from '#TONClient/EVERTip3/helpers';
import type { Tip3Token as Tip3TokenType } from '#TONClient/EVERTip3/types';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import { getDetails } from '#controllers/Details/Tip3Token/helpers';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import TabViewList from '#controllers/TabViewLists/TabViewList';
import DetailsTabView from '#controllers/Details/components/DetailsTabView';
import TokenHoldersView from '#components/EVERList/ListViews/Tokens/TokenHoldersView';
import {
    walletsExternalConfigs,
    getWalletsCollectionConfigs,
} from '#components/EVERList/ListViews/Tokens/TokenWalletsView';

const Tip3TokenComponent: React.FC = () => {
    const ref = useRef(null);

    const [params] = useParams();
    const { id } = params;

    const [token, setToken] = useState<Tip3TokenType | null>(null);
    const [numberOfHolders, setNumberOfHolders] = useState<number | null>(null);

    const title = `${token?.name || liveLocalized.Tip3TokenDetails}`;

    const walletsLoader = useCallback((args: GetHolderArgs) => getWallets(args, id) || [], [id]);
    const holdersLoader = useCallback((args: GetHolderArgs) => getHolders(args, id) || [], [id]);

    const pages = [
        {
            title: `${liveLocalized.Holders} ${numberOfHolders || numberOfHolders === 0 ? numberOfHolders : ''}`,
            component: (
                <TabViewList
                    collectionConfigs={TokenHoldersView.getCollectionConfigs(token?.totalSupply)}
                    externalControlConfigs={TokenHoldersView.externalConfigs}
                    realtimeUpdated={false}
                    itemsLoader={holdersLoader}
                />
            ),
        },
        {
            title: `${liveLocalized.Wallets}`,
            component: (
                <TabViewList
                    collectionConfigs={getWalletsCollectionConfigs()}
                    externalControlConfigs={walletsExternalConfigs}
                    realtimeUpdated={false}
                    itemsLoader={walletsLoader}
                />
            ),
        },
    ];

    const onLoadToken = async ({ id: idParam }) => {
        controllerService.showSpinnerOverlay();

        const tokenResult = await EVERTip3.getTIP3Token(idParam);
        setToken(tokenResult);

        EVERTip3.getStatisticOfToken(idParam).then((statistic) => {
            if (statistic) setNumberOfHolders(statistic.numberOfHolders);
        });

        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadToken);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            title={title}
            details={getDetails(token)}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            detailsComponent={<DetailsTabView pages={pages} />}
        />
    );
};

const Tip3Token = observer(Tip3TokenComponent);

export { Tip3Token };
