import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import LinkManager from '#helpers/LinkManager';
import { useTap } from '#helpers/hooks/useTap';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import type { FilterPropObj } from '#TONClient/TONFilterTags';
import { UIButton, ExternalLink } from '#components';
import type { GetTONItemsArgs } from '#components/EVERList';
import { ExternalLinkTypes } from '#components/Link/ExternalLink/contants';
import { border, margin, padding, color, flex, common, height } from '#uikit/designCore/styles';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { ColorVariants } from '#uikit/designCore/constants/color';

import Tag from './Tag/Tag';

import './TagFilter.scss';

export type PlaygroundArgs = GetTONItemsArgs & {
    collection?: string;
    direction?: SortDirection;
    accountId?: string;
    blockId?: string;
    disable?: boolean;
    queryParams?: { [key: string]: any };
    query?: string;
};

type Props = {
    className?: string;
    showDefaultTags?: boolean;
    filterValues?: FilterValues;
    editable?: boolean;
    filterProps: FilterPropObj;
    playgroundArgs?: PlaygroundArgs;
    onChange?: (key: string, value: any) => void;
    resetAllFilers?: () => void;
};

const tagFilterTestIDs = {
    playgroundButton: 'playgroundButton',
    filtersBlock: 'filtersBlock',
};

const TagFilter: React.FC<Props> = ({
    filterProps,
    className = '',
    showDefaultTags = true,
    editable = true,
    filterValues = {},
    onChange = () => {},
    resetAllFilers = () => {},
    playgroundArgs = {},
}) => {
    const [playgroundLink, setPlaygroundLink] = useState<string>('');

    const anchorRef = useRef<HTMLDivElement>(null);

    const [_, isTap] = useTap(anchorRef);

    const reactKey = (propKey: string) => `filter-tag-${propKey}`;

    const onChangeValue = (key: string) => (value: string) => {
        onChange(key, value);
    };

    useEffect(() => {
        if (!playgroundArgs?.collection) return;

        const { accountId, blockId } = playgroundArgs;
        LinkManager.getPlayground({
            ...playgroundArgs,
            filterValues: { ...filterValues, accountId, blockId },
        }).then((link) => {
            setPlaygroundLink(link);
        });
    }, [playgroundArgs, filterValues]);

    const renderLinkToPlayground = editable && playgroundArgs?.collection && !playgroundArgs?.disable && (
        <ExternalLink
            testID={tagFilterTestIDs.playgroundButton}
            type={ExternalLinkTypes.button}
            href={playgroundLink}
            title={liveLocalized.Playground}
            classNames={classnames(padding.horizontalNormal, border.common, margin.bottomSmall)}
            titleColor={color.TextAccent}
            buttonColor={ColorVariants.BackgroundPrimary}
            buttonSize={UIButton.buttonSize.small}
            buttonShape={UIButton.buttonShape.radius}
            onPress={() => {}}
        />
    );

    if (!filterProps) return null;

    return (
        <div
            className={classnames(flex.rowWrap, common.displayFlex, className)}
            data-testid={tagFilterTestIDs.filtersBlock}
        >
            {Object.keys(filterProps).map((propKey) => (
                <Tag
                    key={reactKey(propKey)}
                    propKey={propKey}
                    showDefault={showDefaultTags}
                    editable={editable}
                    filterPropItem={filterProps[propKey]}
                    filterValue={filterValues[propKey]}
                    onChangeValue={onChangeValue(propKey)}
                />
            ))}
            {Boolean(Object.keys(filterProps).length) && (
                <div
                    ref={anchorRef}
                    className={classnames(
                        'ResetAllButton',
                        border.common,
                        padding.horizontalNormal,
                        margin.bottomSmall,
                        margin.rightNormal,
                        common.displayFlex,
                        flex.alignItemsCenter,
                        TypographyVariants.ActionFootnote,
                        { pressed: isTap }
                    )}
                    onClick={resetAllFilers}
                >
                    <div className={classnames(color.TextAccent)}>{liveLocalized.ResetAll}</div>
                </div>
            )}

            {renderLinkToPlayground}
        </div>
    );
};

export { TagFilter };
