import { getEndCursor } from '#TONClient/helpers';
import { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import { FilterValues } from '#TONClient/TONFilter';
import { OnErrorCallbackType } from '#TONClient/types';
import { TONString } from '#TONUtility';

import { getTransferDetailsQueryFilters, getFiltersForAccountTransfers } from './helpers';
import { AccountTransfer, TransferDetailsType } from './types';
import { queryTransferDetailsByMessageID, queryTransfersForAccount } from './queries';
import { transferDetailsFields } from './constants';

const loadTransferByMessageID = async (
    messageID: string,
    onError: OnErrorCallbackType = () => {}
): Promise<TransferDetailsType | null> => {
    try {
        const filters = getTransferDetailsQueryFilters(messageID);

        const response = await queryTransferDetailsByMessageID(filters, transferDetailsFields, onError);
        const transfer = response?.data?.ft.transfersByMessageIds[0];

        if (transfer) {
            const { token, transferType, fromHolder, toHolder, value, timestamp } = transfer;

            return {
                tokenSymbol: token.symbol,
                tokenDecimals: token.decimals,
                transferType,
                fromHolderAddress: fromHolder.address,
                toHolderAddress: toHolder.address,
                value: TONString.getFormattedBalanceByDecimals(value, token.decimals),
                timestamp,
            };
        } else {
            return null;
        }
    } catch (e) {
        onError(e);
        return null;
    }
};

const loadAccountTransfers = async (
    accountID: string,
    args: GetTransactionsArgs,
    filterValues: FilterValues,
    onError: OnErrorCallbackType
): Promise<AccountTransfer[] | null> => {
    try {
        const filters = getFiltersForAccountTransfers(args, filterValues);

        const response = await queryTransfersForAccount(accountID, filters);
        const transfers = response?.data?.blockchain.account.info.tokenHolder.transfers;

        if (transfers) {
            const {
                edges,
                pageInfo: { endCursor: pageEndCursor },
            } = transfers;

            // @ts-ignore
            return edges.map(
                ({ node: { transferType, token, toHolder, fromHolder, messageId, timestamp, value } }, index) => ({
                    type: TONString.getStringWithOnlyFirstCapitalizedLetter(transferType),
                    tokenSymbol: token.symbol,
                    to: toHolder?.address,
                    from: fromHolder?.address,
                    messageId,
                    timestamp,
                    value: TONString.getFormattedBalanceByDecimals(value, token.decimals),
                    decimals: token.decimals,
                    ...(index === edges.length - 1
                        ? { endCursor: getEndCursor(args.direction || 'DESC', edges[0].cursor, pageEndCursor) }
                        : {}),
                })
            );
        } else {
            return null;
        }
    } catch (e) {
        onError(e);
        return null;
    }
};

export { loadTransferByMessageID, loadAccountTransfers };
