// @flow
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import TONFilterTags from '#TONClient/TONFilterTags';
import { EVERTransaction } from '#TONClient';
import { getAccountTransactionsQuery } from '#TONClient/EVERTransaction/helpers';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import type { GetTransactionsArgs, EVERTransactionT } from '#TONClient/EVERTransaction/types';
import { TONLog } from '#TONUtility';
import { TransactionView } from '#components';

import TabViewList, { getImperativeHandleParamsForTabViewList } from './TabViewList';
import type { CommonTabViewListProps } from './TabViewList';

type Props = CommonTabViewListProps & {
    blockId?: string,
    onChangeItemsList?: () => any[],
    onChangeFilter: () => any,
    sortDirectionByDefault?: SortDirection,
    onChangeSortDirection?: (direction: SortDirection) => void,
};

const log = new TONLog('TransactionsList');

const TransactionsList = forwardRef<Props, any>(
    (
        {
            accountId = '',
            blockId = '',
            realtimeUpdated = false,
            onChangeItemsList = () => [],
            onUpdateItems = () => {},
            onChangeFilter = () => {},
            onChangeSortDirection = () => {},
            sortDirectionByDefault,
        }: Props,
        Ref
    ) => {
        const ref = useRef(null);

        // Events
        const handleLoadTransaction = (tr: ?EVERTransactionT) => {
            if (!tr) {
                return;
            }

            ref?.current && ref.current.unshiftItem(tr);
            onUpdateItems();
        };

        const itemsLoader = useCallback(
            (args: GetTransactionsArgs, filterValues: FilterValues): Promise<EVERTransactionT[]> => {
                if (blockId) {
                    return EVERTransaction.getTransactions({
                        ...args,
                        filterValues,
                        blockId,
                    });
                }

                if (!accountId) {
                    log.debug('No accountId passed');
                    // $FlowExpectedError
                    return [];
                }

                return EVERTransaction.getTransactionsWithPagination({
                    ...args,
                    filterValues: {
                        ...filterValues,
                        accountId,
                    },
                });
            },
            [blockId, accountId]
        );

        useImperativeHandle(Ref, () => getImperativeHandleParamsForTabViewList(ref));

        // Render
        return (
            <TabViewList
                ref={ref}
                nothingWasFoundMessage={liveLocalized.NoTransactionsFound}
                realtimeUpdated={realtimeUpdated}
                filterProps={TONFilterTags.getAccountTransactionProps(false, !blockId)}
                collectionConfigs={TransactionView.getCollectionConfigs(accountId)}
                itemsLoader={itemsLoader}
                onChangeSortDirection={onChangeSortDirection}
                onSubscribeForUpdate={(filterValues: FilterValues): void => {
                    EVERTransaction.subscribeForUpdate(handleLoadTransaction, {
                        filterValues: {
                            ...filterValues,
                            accountId,
                        },
                    });
                }}
                onUnsubscribeForUpdate={(): void => {
                    EVERTransaction.unsubscribe();
                }}
                onChangeFilterCustom={onChangeFilter}
                onChangeItemsList={onChangeItemsList}
                externalControlConfigs={{ sortDirectionByDefault }}
                accountId={accountId}
                blockId={blockId}
                queryGetter={
                    accountId
                        ? (args: GetTransactionsArgs) =>
                            getAccountTransactionsQuery({
                                ...args,
                                filterValues: {
                                    ...args.filterValues,
                                    accountId,
                                },
                            })
                        : async () => {}
                }
            />
        );
    }
);

export default observer(TransactionsList);
