import FBTemplate from './FBTemplate';
import { CollectionReference } from '@Firebase/firestore-types';

export type Profile = {
    name: string;
    avatar: string;
};

const AVATARS_DIR = 'https://firebasestorage.googleapis.com/v0/b/gram-scan.appspot.com/o/profile-avatars%2F';

export default class FBProfiles extends FBTemplate {
    static collectionName = 'profiles';

    static profiles: { [key: string]: Profile } | null = null;

    static async getProfiles(): Promise<{ [key: string]: Profile } | null> {
        if (FBProfiles.profiles === null) {
            FBProfiles.profiles = await FBProfiles.loadProfiles();
        }

        return FBProfiles.profiles;
    }

    static async loadProfiles(): Promise<{ [key: string]: Profile } | null> {
        try {
            const collectionRef: CollectionReference<Profile> = FBProfiles.getCollection();
            const collSnapshot = await collectionRef.get();

            const profiles = await Promise.all(
                collSnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const avatarStr = data?.avatar || '';
                    return {
                        name: data?.name,
                        avatar: avatarStr.startsWith('http') ? avatarStr : `${AVATARS_DIR}${avatarStr}`,
                        key: doc.id,
                    };
                })
            );

            const profilesByKey: { [key: string]: Profile } = profiles.reduce(
                (prev, { name, avatar, key }) => ({
                    ...prev,
                    [key]: { name, avatar },
                }),
                {}
            );

            console.log('FBProfiles', profilesByKey);
            return profilesByKey;
        } catch (e) {
            return null;
        }
    }
}
