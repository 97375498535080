// @flow
import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import animationData from '#assets/animations/data5.json';
import { TONFilterTags, EVERTransaction, TONClient } from '#TONClient';
import { getTransactionsWithPaginationQuery } from '#TONClient/EVERTransaction/helpers';
import type { GetTransactionsArgs, EVERTransactionT } from '#TONClient/EVERTransaction/types';
import type { FilterValues } from '#TONClient/TONFilter';
import { TransactionView } from '#components';
import { loadShards } from '#controllers/Lists/helpers';

import ListScreen from '../ListScreen';

const TransactionsComponent = () => {
    const ref = useRef(null);

    const [transactionsCount, setTransactionsCount] = useState<number>(0);

    // Details
    const details = [
        {
            details: `${liveLocalized.Current} ${liveLocalized.TPS}`,
            isHiddenOnAsc: true,
            isItemsPerSecond: true,
        },
        {
            title: liveLocalized.amountToLocale(transactionsCount),
            details: liveLocalized.TransactionsCount,
        },
    ];

    // Actions
    const itemsLoader = useCallback((args: GetTransactionsArgs, filterValues: FilterValues): Promise<
        EVERTransactionT[]
    > => {
        return EVERTransaction.getTransactionsWithPagination({
            ...args,
            filterValues,
        });
    }, []);

    const itemsAggregator = (filterValuesWithTime: FilterValues) =>
        EVERTransaction.aggregateTransactions(filterValuesWithTime);

    const loadTotalTransactions = async (filterValues?: FilterValues) => {
        try {
            EVERTransaction.aggregateTransactions(filterValues || {}).then((count) => {
                setTransactionsCount(TONClient.number(count));
            });

            loadShards(filterValues || {}).then((result) => {
                ref?.current && ref.current.updateFilterValues(result.filterValues);
            });
        } catch (e) {
            controllerService.showServiceUnavailable();
        }
    };

    const unshiftNewTransaction = (tr: ?EVERTransactionT) => {
        // increment transactions count
        setTransactionsCount((prevCount) => prevCount + 1);

        ref?.current && tr && ref.current.unshiftItem(tr, 'now');
        ref?.current && ref.current.unshiftNewItem(tr?.now);
    };

    // Events
    const onLoadingNew = (tr: ?EVERTransactionT): void => {
        unshiftNewTransaction(tr);
    };

    const onSubscribeForUpdate = (filterValues: FilterValues): void => {
        EVERTransaction.subscribeForUpdate(onLoadingNew, { filterValues });
    };

    const onUnsubscribeForUpdate = (): void => {
        EVERTransaction.unsubscribe();
    };

    const onStartReload = (filterValues: FilterValues): void => {
        loadTotalTransactions(filterValues);
    };

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Transactions}
            listTitle={liveLocalized.Latest}
            shouldHideListTitleOnASC
            details={details}
            itemsLoader={itemsLoader}
            itemsAggregator={itemsAggregator}
            animationData={animationData}
            filterProps={TONFilterTags.getTransactionProps()}
            collectionConfigs={TransactionView.getCollectionConfigs(null)}
            realtimeUpdated
            onSubscribeForUpdate={onSubscribeForUpdate}
            onUnsubscribeForUpdate={onUnsubscribeForUpdate}
            onStartReload={onStartReload}
            queryGetter={getTransactionsWithPaginationQuery}
        />
    );
};

const Transactions = observer(TransactionsComponent);

export { Transactions };
