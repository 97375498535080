// @flow
import React from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import FBContracts from '@Firebase/FBContracts';
import type { Contract } from '@Firebase/FBContracts';
import {
    CopyableView,
    LinkWithChild,
    LinkWithCopy,
    LongBalanceView,
    Spinner,
    BalanceView,
    UITextButton,
    UILabel,
    Accordion,
} from '#components';
import UIDetailsTable, { DetailsList, DetailsRow } from '#components/UIDetailsTable';
import { TONClient } from '#TONClient';
import type { Tons, TonsNumber } from '#TONClient/TONClient';
import type { TONOtherCurrency } from '#TONClient/TONTypes';
import { AccountWallet, AccountWalletVariants } from '#TONClient/EVERTip3/types';
import { TokenStandards } from '#TONClient/EVERTip3/constants';
import Utils from '#helpers/Utils';
import MomentHelper from '#helpers/MomentHelper';
import { paths } from '#navigation/paths';
import { URLSearchParamsType } from '#navigation/types';
import { UIStyle, UITextStyle } from '#uikit/designCore';
import { margin, height, common, flex } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import './DetailsRowHelper.scss';

export default class DetailsRowHelper {
    static testIDs: any = {
        fileSize: (kilobytes: string) => `fileSize_${kilobytes}`,
        downloadFileButton: 'downloadFileButton',
    };

    static getValueDependingOnFetchingStatus = (
        isDataLoadingIndicationValue: boolean,
        hasDataIndicationValue: boolean,
        values: {
            valueForInProgressState: any,
            valueForSuccessState: any,
            valueForFailedState: any,
        }
    ) => {
        if (isDataLoadingIndicationValue) {
            return values.valueForInProgressState;
        }
        return hasDataIndicationValue ? values.valueForSuccessState : values.valueForFailedState;
    };

    static crystals(caption: string, balance?: Tons | TonsNumber, hex: boolean = false): DetailsRow {
        return {
            caption,
            ...(balance === '' ? { value: '-' } : { component: <LongBalanceView hex={hex} balance={balance} /> }),
        };
    }

    static hexToDec(caption: string, num?: string): DetailsRow {
        return {
            caption,
            value: TONClient.hexToDec(num),
        };
    }

    static otherCurrency(otherCurrencies?: TONOtherCurrency[], title: string, key?: string): DetailsList {
        if (!otherCurrencies) return [];

        const otherCurrenciesRows = otherCurrencies.map<DetailsRow>((item) => {
            return {
                caption: `${liveLocalized.TONOtherCurrency.value} / ${liveLocalized.TONOtherCurrency.currency}`,
                value: `${liveLocalized.amountToLocale(TONClient.hexToDec(item.value))} / ${item.currency}`,
            };
        });

        return UIDetailsTable.formatNestedList([{ caption: title }, ...otherCurrenciesRows], key);
    }

    static copyableRow(caption: string, value: string, truncated: boolean = true) {
        return value
            ? {
                  caption,
                  component: (
                      <CopyableView
                          displayCaption={false}
                          caption={caption}
                          value={truncated ? Utils.truncText(value, controllerService.isNarrow) : value}
                          copyValue={value}
                          textStyle={UITextStyle.secondarySmallMedium}
                      />
                  ),
              }
            : null;
    }

    static messageLinkWithCopy(caption: string, value: string) {
        return this.linkWithCopy(caption, value, paths.messages.details, { id: value });
    }

    static transactionLinkWithCopy(caption: string, value?: string) {
        return this.linkWithCopy(caption, value, paths.transactions.details, { id: value });
    }

    static blockLinkWithCopy(caption: string, value: string) {
        return this.linkWithCopy(caption, value, paths.blocks.details, { id: value });
    }

    static accountLinkWithCopy(caption: string, value?: string) {
        return this.linkWithCopy(caption, value, paths.accounts.details, { id: value });
    }

    static dePoolLinkWithCopy(caption: string, value?: string) {
        return this.linkWithCopy(caption, value, paths.dePools.details, { id: value });
    }

    static linkWithCopy(
        caption: string,
        value?: string,
        navigationPath: ?string,
        params: URLSearchParamsType,
        truncTitle?: boolean
    ) {
        if (!navigationPath) return { caption, value };

        return {
            caption,
            component: (
                <LinkWithCopy
                    truncTitle={truncTitle}
                    title={value || ''}
                    navigationPath={navigationPath}
                    params={params}
                />
            ),
        };
    }

    static copyableRows(caption: string, values?: string[]) {
        if (!values?.length) return [];

        return values.map<?DetailsRow>((value: string, index) =>
            this.copyableRow(liveLocalized.formatString(caption, index + 1), value)
        );
    }

    static binaryRow(caption: string, base64: ?string, fileName: string): ?DetailsRow {
        if (!base64) {
            return null;
        }

        const narrow = controllerService.isNarrow;

        const value = Utils.truncText(base64, narrow);
        const kilobytes = Utils.roundToMeaningDigit(Utils.getKBSize(base64));
        return {
            caption,
            component: (
                <div className={classnames({ [common.displayFlex]: !narrow, [flex.row]: !narrow })}>
                    <CopyableView
                        displayCaption={false}
                        caption={caption}
                        value={value}
                        copyValue={base64}
                        style={!narrow && UIStyle.margin.rightDefault()}
                        titleRole={TypographyVariants.Action}
                    />
                    <UILabel
                        style={narrow ? UIStyle.margin.topSmall() : UIStyle.margin.rightDefault()}
                        role={TypographyVariants.ParagraphText}
                        testID={this.testIDs.fileSize(kilobytes)}
                    >
                        {' '}
                        {kilobytes} KB
                    </UILabel>
                    <UITextButton
                        buttonHeightClassName={height.littleCell}
                        title={liveLocalized.Download}
                        onPress={() => Utils.saveBinary(base64, fileName)}
                        testID={this.testIDs.downloadFileButton}
                    />
                </div>
            ),
            key: value,
        };
    }

    static proofBocRows(proof: string, boc?: string, entityName?: string, id?: string) {
        return [
            this.binaryRow(liveLocalized.Proof, proof, `${entityName || ''}_proof_${id || ''}`),
            this.binaryRow(liveLocalized.Boc, boc, `${entityName || ''}_boc_${id || ''}`),
        ];
    }

    static codeDataLibraryRows(
        code?: string,
        data?: string,
        library?: string,
        code_hash: string,
        data_hash: string,
        library_hash: string,
        entityName?: string,
        id?: string
    ) {
        return [
            this.binaryRow(liveLocalized.Code, code, `${entityName || ''}_code_${id || ''}`),
            this.copyableRow(liveLocalized.TONAccount.code_hash, code_hash),
            this.binaryRow(liveLocalized.Data, data, `${entityName || ''}_data_${id || ''}`),
            this.copyableRow(liveLocalized.TONAccount.data_hash, data_hash),
            this.binaryRow(liveLocalized.Library, library, `${entityName || ''}_library_${id || ''}`),
            this.copyableRow(liveLocalized.TONAccount.library_hash, library_hash),
        ];
    }

    static tokenBalanceRows(caption: string, tokenBalances: AccountWallet[] | null) {
        const getRows = (tokenBalances: AccountWallet[]) =>
            tokenBalances
                .sort((tokenBalance1, tokenBalance2) => (tokenBalance2.symbol > tokenBalance1.symbol ? -1 : 1))
                .map(({ symbol, balance, rootAddress, decimals, walletAddress, variant }) => (
                    <div key={walletAddress} className="TokenBalanceRow">
                        <LinkWithChild
                            classNames="link"
                            {...((symbol === TokenStandards.KWT && {
                                navigationPath: paths.KWT,
                            }) ||
                                (variant === AccountWalletVariants.FlexClient && {
                                    navigationPath: paths.accounts.details,
                                    params: { id: rootAddress },
                                }) || {
                                    navigationPath: paths.TIP32.details,
                                    params: { id: rootAddress },
                                })}
                        >
                            <p className="TokenBalanceRow__link">{Utils.truncText(symbol, false, 15)}</p>
                        </LinkWithChild>
                        <BalanceView hideIcon balance={balance} notConvert fixFractional fractionalDigits={decimals} />
                    </div>
                ));

        const hiddenBalances = (tokenBalances || []).slice(10);

        const tokenBalancesTable = (
            <div>
                {getRows((tokenBalances || []).slice(0, 10))}
                {Boolean(hiddenBalances.length) && (
                    <Accordion title={liveLocalized.ShowAll} titleHide={liveLocalized.Hide}>
                        {getRows(hiddenBalances)}
                    </Accordion>
                )}
            </div>
        );

        return {
            caption,
            component: this.getValueDependingOnFetchingStatus(!tokenBalances, tokenBalances?.length, {
                valueForInProgressState: (
                    <div className="SpinnerWrapper">
                        <Spinner />
                    </div>
                ),
                valueForSuccessState: tokenBalancesTable,
                valueForFailedState: <UILabel role="ParagraphText">-</UILabel>,
            }),
        };
    }

    static tokenSymbol(caption: string, value: string) {
        return {
            caption,
            component: <UILabel role="ParagraphText">{value}</UILabel>,
        };
    }

    static contractType(contract: ?Contract, navigationId: string) {
        const navigationPath =
            contract && FBContracts.isDePoolContract(contract)
                ? paths.dePools.details
                : contract && FBContracts.isTIP32Contract(contract)
                ? paths.TIP32.details
                : null;

        return {
            caption: liveLocalized.Type,
            component: this.getValueDependingOnFetchingStatus(!contract?.name && !!contract, !!contract?.name, {
                valueForInProgressState: (
                    <div className="SpinnerWrapper">
                        <Spinner />
                    </div>
                ),
                valueForSuccessState: navigationPath ? (
                    <LinkWithChild navigationPath={navigationPath} params={{ id: navigationId }}>
                        <UITextButton title={contract?.name} buttonHeightClassName={height.littleCell} />
                    </LinkWithChild>
                ) : (
                    <UITextButton title={contract?.name} buttonHeightClassName={height.littleCell} />
                ),
                valueForFailedState: <UILabel role="ParagraphText">-</UILabel>,
            }),
        };
    }

    static getTimeAndDate = (timestamp?: number) => ({
        caption: `${liveLocalized.Time} & ${liveLocalized.Date}`,
        component:
            timestamp === undefined ? (
                <UILabel
                    color={ColorVariants.TextSecondary}
                    role={TypographyVariants.ParagraphText}
                    classNames={margin.bottomTiny}
                >
                    -
                </UILabel>
            ) : (
                <>
                    <UILabel
                        color={ColorVariants.TextSecondary}
                        role={TypographyVariants.ParagraphText}
                        classNames={margin.bottomTiny}
                    >
                        {MomentHelper.getFullTimeDate(timestamp)}
                    </UILabel>
                    <UILabel color={ColorVariants.TextTertiary} role={TypographyVariants.ParagraphNote}>
                        {liveLocalized.UnixTime}: {timestamp}
                    </UILabel>
                </>
            ),
    });
}
