import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { UIDetailsInput } from '#components/UIDetailsInput';

type Props = {
    integer: boolean;
    isString: boolean;
    value: string;
    placeholder: string;
    comment: string;
    onSubmitEditing: (value: any) => void;
    onPressComment: () => void;
    onChangeValue: (value: string | number) => void;
};

const maxInt = 2147483647;

export const detailsInputTestIDs = {
    amountInput: (placeholder: string) => `detailsInput_${placeholder}`,
};

// $FlowExpectedError
const DetailsInput: React.FC<Props> = forwardRef(
    (
        {
            placeholder,
            comment,
            onPressComment,
            value,
            integer = false,
            isString = false,
            onChangeValue = () => {},
            onSubmitEditing = () => {},
        },
        ref
    ) => {
        const [innerValue, setInnerValue] = useState('');
        const input = useRef(null);

        const onChangeText = (newValue: string) => {
            if (integer) {
                if (/^\d*$/.test(newValue)) {
                    const maxValue = Math.min(Number(newValue), maxInt);
                    setInnerValue(maxValue.toString());
                    onChangeValue(maxValue);
                }
            } else {
                setInnerValue(newValue);
                onChangeValue(newValue);
            }
        };

        const handleSubmitEditing = () => {
            const val = isString ? innerValue : Number(innerValue);
            onSubmitEditing(val);
        };

        useImperativeHandle(
            ref,
            () => ({
                focus: () => {
                    input && input.current && input.current.focus();
                },
            }),
            []
        );

        useEffect(() => {
            setInnerValue(value || '');
        }, [value]);

        return (
            <UIDetailsInput
                ref={input}
                testID={detailsInputTestIDs.amountInput(placeholder)}
                {...{
                    integer,
                    placeholder,
                    comment,
                    onPressComment,
                    onChangeValue,
                }}
                onSubmitEditing={handleSubmitEditing}
                value={innerValue}
                onChangeText={onChangeText}
            />
        );
    }
);

export default DetailsInput;
