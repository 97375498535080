import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import FBCredentials from './FBCredentials';

require('firebase/firestore');

let currentApp = null;

export default class Firebase {
    static db = null;

    static init() {
        try {
            const credentials = FBCredentials.prod;
            currentApp = firebase.initializeApp(credentials);
            Firebase.db = firebase.firestore();
        } catch (e) {
            console.error(e);
        }
    }
}
