import { Maybe } from 'graphql/jsutils/Maybe';

import { getMasterSeqNoRangeFromNewApi } from '#TONClient/helpers';
import TONFilter from '#TONClient/TONFilter';
import { additionalFields, binaryFields, messageTransactionsField, shortFields } from '#TONClient/TONMessage/fields';

import { messageTypeByFilters } from './constants';
import type { GetMessagesArgs } from './types';

export const result = (args: Maybe<GetMessagesArgs>): string =>
    `
        ${shortFields}
        ${args?.needAdditionalFields ? additionalFields : ''}
        ${args?.needBinaryFields ? binaryFields : ''}
        ${args?.needTransactionFields ? messageTransactionsField : ''}
    `;

const getMessageTypes = (extInt: Maybe<string>, srcDst: Maybe<string>): string => {
    let types: string[] = [];

    if (extInt && srcDst) {
        types = messageTypeByFilters[extInt].filter((type) => messageTypeByFilters[srcDst].includes(type));
    } else if (extInt) {
        types = messageTypeByFilters[extInt];
    } else if (srcDst) {
        types = messageTypeByFilters[srcDst];
    }

    return types.join(',');
};

const newFilter = async (args: GetMessagesArgs): Promise<string> => {
    const { filterValues, direction, limit, lastItem } = args;
    const minValue = filterValues?.minValue?.value;

    const types = getMessageTypes(filterValues?.extInt?.value, filterValues?.srcDst?.value);
    const type = types ? `msg_type:[${types}],` : '';

    const masterSeqNoRange = await getMasterSeqNoRangeFromNewApi(
        filterValues?.minTime?.value,
        filterValues?.maxTime?.value
    );

    const min = minValue ? `min_value:"${TONFilter.convertTonsToHexNano(minValue)}",` : '';

    const cursorPosition = lastItem?.endCursor
        ? `${direction === 'DESC' ? 'before' : 'after'}: "${lastItem.endCursor}",`
        : '';

    const itemsRange = limit ? `${direction === 'DESC' ? 'last' : 'first'}: ${limit},` : '';

    return `${type}${masterSeqNoRange}${min}${cursorPosition}${itemsRange}`;
};

export const getAccountMessagesQuery = async (args: GetMessagesArgs) => {
    const { filterValues } = args;
    const accountId = filterValues?.accountId?.value || filterValues?.accountId;
    const filter = await newFilter(args);
    const fields = result(args);

    return ` 
        query{
            blockchain{
                account(address:"${accountId}"){
                    messages(${filter}){
                        edges{
                            node{
                                ${fields}
                            }
                            cursor
                            }
                            pageInfo{
                                endCursor
                                hasNextPage
                        }
                    }
                }
            }
        }
    `;
};
