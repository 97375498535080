import { netKeys } from 'constants/constants';

const {
    mainnet,
    testnet,
    net,
    net2,
    privatenet,
    cinet,
    osnet,
    rustnet,
    game0net,
    SEnet,
    fldnet,
    rfldnet,
    bsnnet,
    bsn2net,
    toldnet,
    goshnet,
    gychainnet,
    smftnet,
    tonnet,
} = netKeys;

export default class EnvManager {
    static isNetworkOnDappServer = [fldnet, rfldnet, bsnnet, bsn2net, gychainnet, smftnet].includes(this.getNetwork());
    static isNetworkWithoutAccessToFB = [gychainnet].includes(this.getNetwork());

    static getNetwork() {
        if (this.isDevelopment()) {
            return process.env.NETWORK;
        }

        if (window.location.hostname.includes('gychain')) {
            return gychainnet;
        }

        const getPrefix = (separator) => window.location.hostname.split(separator)[0];
        if (this.isTestHost()) {
            if (window.location.hostname.includes('gosh')) {
                return goshnet;
            }

            return getPrefix('-');
        }

        if (window.location.hostname.includes('gosh.sh')) {
            return goshnet;
        }

        const prefix = getPrefix('.');
        return ['ever'].includes(prefix)
            ? mainnet
            : prefix === 'gosh'
            ? goshnet
            : Object.values(netKeys).includes(prefix)
            ? prefix // Network name should be well known,
            : SEnet; // otherwise it is SEnet
    }

    static isMainnet() {
        return this.getNetwork() === mainnet;
    }

    static isTestnet() {
        return this.getNetwork() === testnet;
    }

    static isDevnet() {
        return this.getNetwork() === net;
    }

    static isDevnet2() {
        return this.getNetwork() === net2;
    }

    static isPrivatenet() {
        return this.getNetwork() === privatenet;
    }

    static isCinet() {
        return this.getNetwork() === cinet;
    }

    static isOsnet() {
        return this.getNetwork() === osnet;
    }

    static isRustnet() {
        return this.getNetwork() === rustnet;
    }

    static isGamenet() {
        return [game0net].includes(this.getNetwork());
    }

    static isSEnet() {
        return this.getNetwork() === SEnet;
    }

    static isFldnet() {
        return this.getNetwork() === fldnet;
    }

    static isBsnnet() {
        return this.getNetwork() === bsnnet;
    }

    static isBsn2net() {
        return this.getNetwork() === bsn2net;
    }

    static isToldnet() {
        return this.getNetwork() === toldnet;
    }

    static isTestHost() {
        return process.env.TEST_HOST;
    }

    static isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }

    static isDevelopmentTesthost() {
        return this.isDevelopment() || this.isTestHost();
    }

    static isGoshnet() {
        return this.getNetwork() === goshnet;
    }

    static isGychainnet() {
        return this.getNetwork() === gychainnet;
    }

    static isTonnet() {
        return this.getNetwork() === tonnet;
    }
}
