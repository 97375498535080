export const container = {
    center: 'centerContainer',
    centerLeft: 'centerLeftContainer',
    centerRight: 'centerRightContainer',
    rowSpace: 'rowSpaceContainer',
    rowCenterSpace: 'rowCenterSpaceContainer',
    absoluteFill: 'absoluteFillContainer',
    absoluteFillObject: 'absoluteFillObjectContainer',
    screen: 'screenContainer',
    page: 'pageContainer',
    pageNormal: 'pageNormalContainer',
    pageSlim: 'pageSlimContainer',
    fullScreenController: 'fullScreenControllerContainer',
    bottomOffsetScreen: 'bottomOffsetScreenContainer',
    bottomOffsetItem: 'bottomOffsetItemContainer',
    absoluteFillWidth: 'absoluteFillWidthContainer',
    bottomScreen: 'bottomScreenContainer',
    topScreen: 'topScreenContainer',
    leftScreen: 'leftScreenContainer',
    rightScreen: 'rightScreenContainer',
    centerOnScreen: 'centerOnScreenContainer',
    backgroundImage: 'backgroundImageContainer',
    fullWidthCenter: 'fullWidthCenterContainer',
    halfWidth: 'halfWidthContainer',
    thirdWidth: 'thirdWidthContainer',
    twoThirdsWidth: 'twoThirdsWidthContainer',
    fullWidthPadding: 'fullWidthPaddingContainer',
    fullWidthPaddingCenter: 'fullWidthPaddingCenterContainer',
};
