import React, { useState } from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import UITextButton from '#components/UITextButton';

import './Accordion.scss';

type Props = {
    title?: string;
    titleHide?: string;
    expanded?: boolean;
    classNames?: string;
    hideButtonAfterOpen?: boolean;
};

export const accordionTestIDs = {
    hideButton: 'hideButton',
    showButton: 'showButton',
};

const Accordion: React.FC<Props> = ({
    title = liveLocalized.MoreDetails,
    titleHide = liveLocalized.LessDetails,
    expanded = false,
    classNames,
    children,
    hideButtonAfterOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(expanded);
    const [isVisible, setIsVisible] = useState(expanded);

    const onOpen = () => {
        setIsOpen(true);
        setIsVisible(true);
    };

    const onClose = () => {
        setIsVisible(false);
    };

    const onTransitionEnd = () => {
        if (!isVisible) setIsOpen(false);
    };

    return (
        <div className={classnames('Accordion', classNames)}>
            {isOpen && hideButtonAfterOpen ? null : (
                <UITextButton
                    title={isOpen ? titleHide : title}
                    onPress={isOpen ? onClose : onOpen}
                    testID={accordionTestIDs.hideButton}
                />
            )}
            <div className={classnames('Accordion__content', { visible: isVisible })} onTransitionEnd={onTransitionEnd}>
                {isOpen && children}
            </div>
        </div>
    );
};

export default Accordion;
