import React, { ReactElement, useCallback } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCustomNavigate } from '#navigation/hooks/useCustomNavigate';

type Props = {
    children: ReactElement;
    href?: string;
    navigationPath: string;
    params?: { [key: string]: string } | { [key: number]: string };
    classNames?: string;
    onFollow?: () => void;
    preventNavigation?: boolean;
};

import './LinkWithChild.scss';

export const blockingLinkElementID = 'blocking-link-element';

const checkTargetForBlockingElement = (element: Maybe<Element>) => {
    if (element?.id && element?.id === blockingLinkElementID) {
        return true;
    }

    if (element?.parentNode) {
        return checkTargetForBlockingElement(element?.parentElement);
    }

    return false;
};

const LinkWithChild: React.FC<Props> = ({
    children,
    href,
    navigationPath,
    params,
    classNames,
    onFollow = () => {},
    preventNavigation = false,
}) => {
    const navigate = useCustomNavigate();

    const paramsKeyValuePare = Object.entries(params || {});
    const navigationPathWithQueryParams =
        params && paramsKeyValuePare.length
            ? `${navigationPath}?${paramsKeyValuePare.map(([key, value]) => `${key}=${value}`).join('&')}`
            : navigationPath;

    const onFollowLink = useCallback(() => {
        onFollow();

        if (preventNavigation) return;
        navigate(navigationPath, params);
    }, [preventNavigation, navigationPath, params]);

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();

            if (checkTargetForBlockingElement(e.target as Element)) {
                return;
            }

            if (href || e.ctrlKey) {
                window.open(href || navigationPathWithQueryParams);
                return;
            }

            onFollowLink();
        },
        [href, params, onFollowLink]
    );

    const link = (
        <a className="LinkWithChild" href={href || navigationPathWithQueryParams} onClick={onClick}>
            {children}
        </a>
    );

    return classNames ? <div className={classNames}>{link}</div> : link;
};

export { LinkWithChild };
