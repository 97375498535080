import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import type { GetTONItemsArgs, TONCollectionConfigs, EVERItem } from '#components/EVERList/index';
import ExportCSVButton from '#components/EVERList/ExportCSVButton';
import Toggle from '#components/EVERList/Toggle';
import SortSwitcher from '#components/EVERList/SortSwitcher';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import { UILabel } from '#components';
import { height } from '#uikit/designCore/styles/height';
import { common } from '#uikit/designCore/styles/comon';
import { flex } from '#uikit/designCore/styles/flex';
import { TypographyVariants } from '#uikit/themes/typography';

import UpdateView from './ListViews/UpdateView';

type Props = {
    headerTestID: string;
    exportCSVTestID: string;
    collectionConfigs: TONCollectionConfigs;
    updateViewDetails: Maybe<string>;
    titleRole: TypographyVariants;
    title: string;
    newItemsCount: number;
    realtimeUpdated: boolean;
    isRealtimeUpdatedNow: boolean;
    onToggle: () => void;
    onPressUpdate: () => void;
    stateItems: EVERItem[];
    items: Maybe<EVERItem[]>;
    customItemsForExport: Maybe<EVERItem[]>;
    sortDirection: SortDirection;
    onChangeSortDirection: () => void;
    itemsLoader: (args: GetTONItemsArgs, filterValues?: FilterValues) => Promise<EVERItem[]>;
    filterValues: FilterValues;
};

const Header: React.FC<Props> = ({
    collectionConfigs,
    updateViewDetails,
    headerTestID,
    exportCSVTestID,
    titleRole,
    title,
    newItemsCount,
    realtimeUpdated,
    isRealtimeUpdatedNow,
    stateItems,
    onToggle,
    onPressUpdate,
    onChangeSortDirection,
    sortDirection,
    items,
    customItemsForExport,
    itemsLoader,
    filterValues,
}) => {
    const narrow = controllerService.isNarrow;

    const exportToCSVButton = !!stateItems.length && (
        <ExportCSVButton
            collectionConfigs={collectionConfigs}
            items={customItemsForExport || items || null}
            testID={exportCSVTestID}
            itemsLoader={itemsLoader}
            sortDirection={sortDirection}
            filterValues={filterValues}
        />
    );

    const toggle = (
        <div className={height.defaultCell}>
            {realtimeUpdated && (
                <Toggle title={liveLocalized.RealtimeUpdate} active={isRealtimeUpdatedNow} onClick={onToggle} />
            )}
        </div>
    );

    const sortDirectionButton = (
        <div className={classNames(height.defaultCell, common.displayFlex, flex.alignItemsCenter)}>
            <SortSwitcher onPress={onChangeSortDirection} sortDirection={sortDirection} disabled={!stateItems.length} />
        </div>
    );

    const titleComponent = <UILabel role={titleRole}>{title}</UILabel>;
    const titleContainer = (
        <div className={classNames(common.displayFlex)}>
            <div className={classNames(common.displayFlex, flex.flex1, flex.alignItemsCenter)}>
                {!!title && titleComponent}
            </div>
            {!narrow && exportToCSVButton}
            {toggle}
            {sortDirectionButton}
        </div>
    );

    return (
        <div data-testid={headerTestID}>
            {titleContainer}
            {narrow && exportToCSVButton}
            {!!newItemsCount && (
                <UpdateView
                    title={liveLocalized.localizedStringTemplateForValue(
                        newItemsCount,
                        collectionConfigs.newItemName || ''
                    )}
                    details={updateViewDetails}
                    onPress={onPressUpdate}
                />
            )}
        </div>
    );
};

export default observer(Header);
