import { AbiContract } from '@eversdk/core/dist/modules';
import { DocumentSnapshot } from '@Firebase/firestore-types';

import { TONLog } from '#TONUtility';
import { TIP32ContractName } from '#TONClient/EVERTip3/constants';
import Utils from '#helpers/Utils';
import EnvManager from '#helpers/EnvManager';

import FBTemplate from './FBTemplate';

export type Contract = {
    abi?: AbiContract;
    name: string;
    code_hash: string;
    showCustodianPublicKeys?: boolean;
    type: string;
    network?: { [key: string]: boolean };
    avatar?: string;
};

type FBContract = {
    abi?: string; // if Proxy then no abi
    name: string;
    code_hash: string;
    showCustodianPublicKeys?: boolean;
    type: string;
    avatar?: string;
};

export type ContractsByCodeHash = { [key: string]: Contract };

const log = new TONLog('FBContracts');

export default class FBContracts extends FBTemplate {
    static contracts = {
        setCodeMultisig: 'SetcodeMultisig',
        safeMultisig: 'SafeMultisig',
        safeMultisig24: 'SafeMultisig 24h',
        simpleWallet: 'SimpleWallet',
        chessLevel2: 'Chess level #2',
        dePoolProxyV1: 'DePool Proxy v1',
        dePoolProxyV2: 'DePool Proxy v2',
        dePoolProxyV3: 'DePool Proxy v3',
        dePoolProxyV4: 'DePool Proxy v4',
        dePoolV1: 'DePool v1',
        dePoolV2: 'DePool v2',
        dePoolV2Test: 'DePool v2 Test',
        dePoolV3Final: 'DePool v3 Final',
        dePoolV4: 'DePool RustCup v4',
        dePoolV5: 'DePool RustCup v5',
        surf: 'Surf',
        elector: 'Elector',
        electorFunC: 'ElectorFunC',
        electorSol2: 'ElectorSol2',
        electorSol6: 'ElectorSol6',
        grandbazar: 'Grandbazar NFT Collection',
        fide: 'FIDE 2021 NFT Collection',
        thirdPlace: 'Third Place NFT Collection',
        rustCup: 'Rust Cup NFT Collection',
        maxArt: 'MaxArt NFT Collection',
        universalHumans: 'Universal Humans NFT Collection',
        myth: 'MYTH Gallery NFT Collection',
    };

    static types = {
        depool: 'depool',
        proxy: 'proxy',
        debots: 'debots',
        wallets: 'wallets',
        others: 'others',
        elector: 'elector',
        contests: 'contests',
        tokens: 'tokens',
    };

    static collectionName = 'contracts';

    static defaultNetForElector = 'default';

    static contractsByCodeHash: ContractsByCodeHash | null = null;
    static contractsByTypeAndCodeHash: { [key: string]: ContractsByCodeHash | null } = {
        [FBContracts.types.wallets]: null,
        [FBContracts.types.depool]: null,
        [FBContracts.types.proxy]: null,
    };
    static electorContract: Contract | null = null;
    static electorFuncName = 'ElectorFunc';
    static abiAndAvatarLinkPrefix = 'https://firebasestorage.googleapis.com/v0/b/gram-scan.appspot.com/o/';

    static getParsedContracts = (docs: DocumentSnapshot<FBContract>[]) =>
        Utils.parseArray(
            docs.map((item) => Utils.objectValues(item.data() || {}).map((data) => ({ ...data, type: item.id })))
        );

    static getLinkWithAbiAndAvatarLinkPrefix(link: string): string {
        return link.includes(FBContracts.abiAndAvatarLinkPrefix.substr(0, 5))
            ? link
            : FBContracts.abiAndAvatarLinkPrefix + link;
    }

    static getPreparedContract(contract: FBContract): FBContract {
        const newDataForContract: { abi: string; avatar: string } = {
            abi: contract.abi ? FBContracts.getLinkWithAbiAndAvatarLinkPrefix(contract.abi) : '',
            avatar: contract.avatar ? FBContracts.getLinkWithAbiAndAvatarLinkPrefix(contract.avatar) : '',
        };

        return {
            ...contract,
            ...newDataForContract,
        };
    }

    static isFuncElector(contract: Contract) {
        return contract.name === FBContracts.electorFuncName;
    }

    static async getContractsByCodeHash(codeHash?: string): Promise<ContractsByCodeHash | null> {
        if (!FBContracts.contractsByCodeHash || (codeHash && !FBContracts.contractsByCodeHash[codeHash])) {
            FBContracts.contractsByCodeHash = (await FBContracts.loadAllContractsByCodeHash(codeHash)) || {};
        }

        return FBContracts.contractsByCodeHash;
    }

    static async getContractsOfTypeByCodeHash(contractType: string): Promise<ContractsByCodeHash | null> {
        if (FBContracts.contractsByCodeHash) {
            Utils.objectValues(FBContracts.contractsByCodeHash || {}).forEach((contract: Contract) => {
                if (contract.type === contractType && FBContracts.contractsByTypeAndCodeHash[contractType]) {
                    //@ts-ignore doesn`t see check above
                    FBContracts.contractsByTypeAndCodeHash[contractType][contract.code_hash] = contract;
                }
            });
        }

        if (!FBContracts.contractsByTypeAndCodeHash[contractType]) {
            FBContracts.contractsByTypeAndCodeHash[contractType] = await FBContracts.loadContractsOfTypeByCodeHash(
                contractType
            );
        }

        return FBContracts.contractsByTypeAndCodeHash[contractType];
    }

    static async getDePoolContractsByCodeHash(): Promise<ContractsByCodeHash | null> {
        return FBContracts.getContractsOfTypeByCodeHash(FBContracts.types.depool);
    }

    static async getDePoolProxyContractsByCodeHash(): Promise<ContractsByCodeHash | null> {
        return FBContracts.getContractsOfTypeByCodeHash(FBContracts.types.proxy);
    }

    static async getElectorContractForCurrentNetwork(): Promise<Contract | null> {
        if (FBContracts.electorContract === null) {
            let defaultContract;
            const result = await FBContracts.loadContractsOfTypeByCodeHash(FBContracts.types.elector);
            Utils.objectValues(result || {}).forEach((contract) => {
                if (contract.network) {
                    if (contract.network[FBContracts.defaultNetForElector]) {
                        defaultContract = contract;
                    }

                    if (contract.network[EnvManager.getNetwork()]) {
                        FBContracts.electorContract = contract;
                    }
                }
            });

            if (!FBContracts.electorContract && defaultContract) {
                FBContracts.electorContract = defaultContract;
            }
        }

        return FBContracts.electorContract;
    }

    static loadContractAbi(contract: FBContract): Promise<FBContract | null> {
        try {
            if (contract.abi) {
                return fetch(contract.abi).then(async (response) => {
                    if (response.ok) {
                        const json = await response.json();
                        return {
                            ...contract,
                            abi: json,
                        };
                    }
                    return contract;
                });
            }

            return Promise.resolve(contract);
        } catch (e) {
            return new Promise((resolve) => resolve(null));
        }
    }

    static async loadContractsOfTypeByCodeHash(contractType: string): Promise<ContractsByCodeHash | null> {
        try {
            const doc = await FBContracts.getCollection().doc(contractType).get();
            const contractsData = Utils.objectValues(doc.data());

            const result = await Promise.all(
                contractsData.map((contract: FBContract) => {
                    return FBContracts.loadContractAbi(FBContracts.getPreparedContract(contract));
                })
            );
            const resultFormatted = Utils.groupById(result, 'code_hash');

            log.debug('Contracts were loaded from FB', contractType, resultFormatted);
            return resultFormatted;
        } catch (e) {
            return null;
        }
    }

    static async loadAllContractsByCodeHash(codeHash?: string): Promise<ContractsByCodeHash | null> {
        try {
            const snapShot = await FBContracts.getCollection().get();

            const docs: DocumentSnapshot<FBContract>[] = await Promise.all(
                snapShot.docs.map((doc) => FBContracts.getCollection().doc(doc.id).get())
            );

            const contracts: FBContract[] = codeHash
                ? FBContracts.getParsedContracts(docs).filter((contract) => contract.code_hash === codeHash)
                : FBContracts.getParsedContracts(docs);

            const formattedContracts = await Promise.all(
                contracts.map((contract: FBContract) => {
                    return FBContracts.loadContractAbi(FBContracts.getPreparedContract(contract));
                })
            );
            const contractsByCodeHash: ContractsByCodeHash = Utils.groupById(formattedContracts, 'code_hash');

            log.debug('ContractsByCodeHash were loaded from FB', contractsByCodeHash);

            return contractsByCodeHash;
        } catch (e) {
            return null;
        }
    }

    static getSurfHashCodeFromContracts(contractsByCodeHash: ContractsByCodeHash): string {
        return (
            Utils.objectValues(contractsByCodeHash).find((contract) => contract.name === FBContracts.contracts.surf)
                ?.code_hash || ''
        );
    }

    static isDePoolContract(contract?: Contract): boolean {
        if (!contract) {
            return false;
        }

        return [FBContracts.types.proxy, FBContracts.types.depool].includes(contract.type);
    }

    static isNFTCollectionContract(contract?: Contract): boolean {
        if (!contract) {
            return false;
        }

        return [
            FBContracts.contracts.grandbazar,
            FBContracts.contracts.fide,
            FBContracts.contracts.thirdPlace,
            FBContracts.contracts.rustCup,
            FBContracts.contracts.maxArt,
            FBContracts.contracts.universalHumans,
            FBContracts.contracts.myth,
        ].includes(contract.name);
    }

    static isTIP32Contract = (contract?: Contract): boolean => contract?.name === TIP32ContractName;

    static isWalletContract(contract?: Contract): boolean {
        if (!contract) {
            return false;
        }

        return [FBContracts.types.wallets].includes(contract.type);
    }

    static isRootTokenContract(contract?: Contract): boolean {
        if (!contract) {
            return false;
        }

        return [FBContracts.types.tokens].includes(contract.type);
    }
}
