import React, { useEffect } from 'react';

const useOnClickOutside = (
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
    permittedElementSelectors?: string[]
) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            if (permittedElementSelectors) {
                const isTargetElementPermitted = permittedElementSelectors.find((selector) =>
                    (event.target as HTMLElement).closest(selector)
                );
                if (isTargetElementPermitted) return;
            }

            handler(event);
        };
        document.addEventListener('click', listener);

        return () => document.removeEventListener('click', listener);
    }, [ref, handler]);
};

export { useOnClickOutside };
