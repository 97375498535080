import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import UIDetailsTable, { DetailsList } from '#components/UIDetailsTable';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import Caption from '#components/EVERList/ListViews/Caption';
import ShortBalanceView from '#components/BalanceView/ShortBalanceView';
import FlexContainer from '#components/common/FlexContainer';
import { TONClient } from '#TONClient';
import type { DePoolRound } from '#TONClient/TONDePool/types';
import MomentHelper from '#helpers/MomentHelper';

type Props = {
    item: DePoolRound;
    detailsListGetter: (any) => DetailsList;
};

export const roundViewTestIDs = {
    roundView: 'roundView',
};

const RoundView = ({ item, detailsListGetter }: Props) => {
    const [visibleDetails, setVisibleDetails] = useState(false);

    if (!item) return null;
    const narrow = controllerService.isNarrow;

    const { id, step, stepName, stake, unused, rewards, supposedElectedAt } = item;

    const caption = (
        <Caption>
            <FlexContainer alignItems="alignBaseline">
                {liveLocalized.Stake}:&nbsp;
                <ShortBalanceView balance={stake} />
            </FlexContainer>
        </Caption>
    );
    const secondDetails = (
        <Caption>
            <FlexContainer alignItems="alignBaseline">
                {liveLocalized.Unused}:&nbsp;
                <ShortBalanceView balance={unused} />
            </FlexContainer>
        </Caption>
    );

    const detailsStr = narrow
        ? MomentHelper.getTimeDate(supposedElectedAt) || '-'
        : `${liveLocalized.ElectionsID}: ${MomentHelper.getFullTimeDate(supposedElectedAt) || '-'}`;

    return (
        <>
            <UIDetailsButton
                testID={roundViewTestIDs.roundView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                title={`${id} | ${liveLocalized.Step}: ${narrow ? step : liveLocalized.dePoolRoundSteps[stepName]}`}
                caption={caption}
                details={detailsStr}
                secondDetails={secondDetails}
                onPress={() => setVisibleDetails(!visibleDetails)}
            />
            {visibleDetails && <UIDetailsTable detailsList={detailsListGetter(item)} />}
        </>
    );
};

RoundView.configs = {
    keyExtractor: ({ id }: DePoolRound): string => {
        return `rounds-view-${id}`;
    },
    idKey: 'id',
    collectionName: liveLocalized.Rounds,
    renderItem: () => null,
};

RoundView.externalConfigs = {
    sortingKey: 'id',
    sortDirectionByDefault: TONClient.sortDirection.ASC,
};

export default observer(RoundView);
