import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { TONFilterTags, EVERTransfer } from '#TONClient';
import TONFilter from '#TONClient/TONFilter';
import type { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import { AccountTransfer } from '#TONClient/EVERTransfer/types';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import { TONLog } from '#TONUtility';
import TabViewList, { getImperativeHandleParamsForTabViewList } from '#controllers/TabViewLists/TabViewList';
import type { CommonTabViewListProps } from '#controllers/TabViewLists/TabViewList';
import { AccountTransferViewGetCollectionConfigs } from '#components/EVERList/ListViews/Transfers/AccountTransferView';

type Props = CommonTabViewListProps & {
    onChangeItemsList?: () => any[];
    onChangeFilter: (filterValues: FilterValues) => any;
    sortDirectionByDefault?: SortDirection;
    onChangeSortDirection?: (direction: SortDirection) => void;
};

const log = new TONLog('TransactionsList');

const TransfersListComponent = forwardRef<ReturnType<typeof getImperativeHandleParamsForTabViewList>, Props>(
    (
        {
            accountId = '',
            onChangeItemsList = () => [],
            onUpdateItems = () => {},
            onChangeFilter = () => {},
            onChangeSortDirection = () => {},
            sortDirectionByDefault,
        },
        Ref
    ) => {
        const ref = useRef(null);

        const itemsLoader = useCallback(
            async (args: GetTransactionsArgs, filterValues: FilterValues): Promise<AccountTransfer[]> => {
                if (!accountId) {
                    log.debug('No accountId passed');
                    return [];
                }

                const transfers = await EVERTransfer.getAccountTransfers(accountId, args, filterValues);
                if (transfers) {
                    TONFilter.sortBy(transfers || [], args, 'timestamp');
                    return transfers;
                } else return [];
            },
            [accountId]
        );

        useImperativeHandle(Ref, () => getImperativeHandleParamsForTabViewList(ref));

        // Render
        return (
            <TabViewList
                ref={ref}
                realtimeUpdated={false}
                nothingWasFoundMessage={liveLocalized.NoTransfersFound}
                filterProps={TONFilterTags.getAccountTransferProps()}
                collectionConfigs={AccountTransferViewGetCollectionConfigs(accountId)}
                itemsLoader={itemsLoader}
                onChangeSortDirection={onChangeSortDirection}
                onChangeFilterCustom={onChangeFilter}
                onChangeItemsList={onChangeItemsList}
                externalControlConfigs={{ sortDirectionByDefault, sortingKey: 'timestamp' }}
                accountId={accountId}
            />
        );
    }
);

const TransfersList = observer(TransfersListComponent);

export { TransfersList };
