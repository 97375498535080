import React, { ReactElement, useRef, useState } from 'react';

export type ActionProps = {
    testID?: string;
    disabled?: boolean;
    showIndicator?: boolean;
    onPress?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    children?: ReactElement;
};

type Props = any & ActionProps;

const UIActionComponent = ({
    testID = '',
    disabled = false,
    showIndicator = false,
    onPress = null,
    children,
    onMouseEnter,
    onMouseLeave,
}: Props) => {
    const [isTapped, setTapped] = useState<boolean>(false);
    const [isHover, setHover] = useState<boolean>(false);

    // Events
    const onMouseDownHandler = () => {
        setTapped(true);
    };

    const onMouseUpHandler = () => {
        setTapped(false);
    };

    const onClickHandler = () => {
        if (onPress) {
            onPress();
        }
    };

    const onMouseEnterHandler = () => {
        setHover(true);
        if (onMouseEnter) {
            onMouseEnter();
        }
    };

    const onMouseLeaveHandler = () => {
        setHover(false);
        if (onMouseLeave) {
            onMouseLeave();
        }
    };

    const isDisabled = disabled || showIndicator || !onPress;

    return (
        <div
            data-testid={testID}
            onClick={isDisabled ? undefined : onClickHandler}
            onMouseDown={isDisabled ? undefined : onMouseDownHandler}
            onMouseUp={isDisabled ? undefined : onMouseUpHandler}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            {React.cloneElement(children, {
                isTapped,
                isHover,
                style: {
                    cursor: 'pointer',
                    ...children.props.style,
                },
            })}
        </div>
    );
};

export default UIActionComponent;
