export const maxFractionalDigits = 18;

export enum CurrencyPosition {
    Before = 'before',
    After = 'after',
}

export enum CurrencySymbolSize {
    S = 'small',
    M = 'medium',
    L = 'large',
}
