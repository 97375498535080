export const margin = {
    topNone: 'marginTopNone',
    topTiny: 'marginTopTiny',
    topSmall: 'marginTopSmall',
    topNormal: 'marginTopNormal',
    topDefault: 'marginTopDefault',
    topMedium: 'marginTopMedium',
    topHuge: 'marginTopHuge',
    topSpacious: 'marginTopSpacious',
    topGreat: 'marginTopGreat',
    topBig: 'marginTopBig',
    topMajor: 'marginTopMajor',
    topMassive: 'marginTopMassive',
    topVast: 'marginTopVast',
    topTremendous: 'marginTopTremendous',
    topEnormous: 'marginTopEnormous',
    topGiant: 'marginTopGiant',

    leftNone: 'marginLeftNone',
    leftGreat: 'marginLeftGreat',
    leftMajor: 'marginLeftMajor',
    leftTiny: 'marginLeftTiny',
    leftSmall: 'marginLeftSmall',
    leftNormal: 'marginLeftNormal',
    leftDefault: 'marginLeftDefault',
    leftMedium: 'marginLeftMedium',
    leftHuge: 'marginLeftHuge',

    rightNone: 'marginRightNone',
    rightGreat: 'marginRightGreat',
    rightMajor: 'marginRightMajor',
    rightTiny: 'marginRightTiny',
    rightSmall: 'marginRightSmall',
    rightNormal: 'marginRightNormal',
    rightDefault: 'marginRightDefault',
    rightMedium: 'marginRightMedium',
    rightHuge: 'marginRightHuge',

    bottomNone: 'marginBottomNone',
    bottomTiny: 'marginBottomTiny',
    bottomSmall: 'marginBottomSmall',
    bottomNormal: 'marginBottomNormal',
    bottomDefault: 'marginBottomDefault',
    bottomMedium: 'marginBottomMedium',
    bottomHuge: 'marginBottomHuge',
    bottomSpacious: 'marginBottomSpacious',
    bottomGreat: 'marginBottomGreat',
    bottomMajor: 'marginBottomMajor',
    bottomMassive: 'marginBottomMassive',
    bottomVast: 'marginBottomVast',
    bottomGiant: 'marginBottomGiant',

    default: 'marginDefault',
    none: 'marginNone',

    horizontalOffset: 'marginHorizontalOffset',
    horizontalNegativeOffset: 'marginHorizontalNegativeOffset',
    horizontalMediumOffset: 'marginHorizontalMediumOffset',
    horizontalSmallOffset: 'marginHorizontalSmallOffset',
    horizontalTinyOffset: 'marginHorizontalTinyOffset',
    leftRight: 'marginLeftRight',
};
