// @flow
import { liveLocalized } from '@services/LocalizationService';
import UIDetailsTable from '#components/UIDetailsTable';
import MomentHelper from '#helpers/MomentHelper';

import type {
    P12,
    P14,
    P15,
    P16,
    P17,
    P18,
    P6,
    P8,
    TONGasLimitPrices,
    TONMaster,
    TONMasterConfig,
    TONVotingParams,
    TONBlockLim,
    TONBlockLimits,
    TONMsgForwardPrices,
    P28,
    P29,
    TONZeroStateMaster,
    P40,
} from '#TONClient/EVERBlock/types';
import DetailsRow from '#controllers/Details/DetailsRowHelper';

const { bool } = UIDetailsTable.cellType;
const { bold, boldTopOffset } = UIDetailsTable.captionType;
const { crystals, hexToDec, otherCurrency } = DetailsRow;

export default class MasterDetails {
    static p6(p6: ?P6) {
        if (!p6) return [];

        const { p6: p6Locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: p6Locale.title },
            { caption: p6Locale.mint_new_price, value: p6.mint_new_price },
            { caption: p6Locale.mint_add_price, value: p6.mint_add_price },
        ]);
    }

    static p8(p8: ?P8) {
        if (!p8) return [];

        const { p8: p8Locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: p8Locale.title },
            { caption: p8Locale.version, value: p8.version },
            { caption: p8Locale.capabilities, value: p8.capabilities },
        ]);
    }

    static votingParams(params: ?TONVotingParams, title: string) {
        if (!params) return [];

        const { TONVotingParams: locale } = liveLocalized.TONBlock.master;

        return UIDetailsTable.formatNestedList({
            list: [
                { caption: title },
                { caption: locale.min_tot_rounds, value: params.min_tot_rounds },
                { caption: locale.max_tot_rounds, value: params.max_tot_rounds },
                { caption: locale.min_wins, value: params.min_wins },
                { caption: locale.max_losses, value: params.max_losses },
                { caption: locale.min_store_sec, value: params.min_store_sec },
                { caption: locale.max_store_sec, value: params.max_store_sec },
                { caption: locale.bit_price, value: params.bit_price },
                { caption: locale.cell_price, value: params.cell_price },
            ],
            key: `voting-params-${title}`,
        });
    }

    static p12(p12: ?P12) {
        if (!p12) return [];

        const { p12: p12Locale } = liveLocalized.TONBlock.master.config;
        let result = [];

        p12.forEach((item, index) => {
            const rows = UIDetailsTable.formatNestedList(
                [
                    { caption: `p12[${index}]` },
                    { caption: p12Locale.workchain_id, value: item.workchain_id },
                    { caption: p12Locale.enabled_since, value: MomentHelper.getTimeDate(item.enabled_since) },
                    { caption: p12Locale.actual_min_split, value: item.actual_min_split },
                    { caption: p12Locale.min_split, value: item.min_split },
                    { caption: p12Locale.max_split, value: item.max_split },
                    { caption: p12Locale.active, value: item.active, type: bool },
                    { caption: p12Locale.accept_msgs, value: item.accept_msgs, type: bool },
                    { caption: p12Locale.flags, value: item.flags },
                    { caption: p12Locale.zerostate_root_hash, value: item.zerostate_root_hash },
                    { caption: p12Locale.zerostate_file_hash, value: item.zerostate_file_hash },
                    { caption: p12Locale.version, value: item.version },
                    { caption: p12Locale.basic, value: item.basic, type: bool },
                    { caption: p12Locale.vm_version, value: item.vm_version },
                    { caption: p12Locale.vm_mode, value: item.vm_mode },
                    { caption: p12Locale.min_addr_len, value: item.min_addr_len },
                    { caption: p12Locale.max_addr_len, value: item.max_addr_len },
                    { caption: p12Locale.addr_len_step, value: item.addr_len_step },
                    { caption: p12Locale.workchain_type_id, value: item.workchain_type_id },
                ],
                `p12[${index}]`
            );
            result = [...result, ...rows];
        });

        return result;
    }

    static p14(p14: ?P14) {
        if (!p14) return [];

        const { p14: p14Locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: p14Locale.title },
            crystals(p14Locale.basechain_block_fee, p14.basechain_block_fee, true),
            crystals(p14Locale.masterchain_block_fee, p14.masterchain_block_fee, true),
        ]);
    }

    static p15(p15: ?P15) {
        if (!p15) return [];

        const { p15: p15Locale } = liveLocalized.TONBlock.master.config;
        const { title } = p15Locale;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            { caption: p15Locale.validators_elected_for, value: p15.validators_elected_for },
            { caption: p15Locale.elections_start_before, value: p15.elections_start_before },
            { caption: p15Locale.elections_end_before, value: p15.elections_end_before },
            { caption: p15Locale.stake_held_for, value: p15.stake_held_for },
        ]);
    }

    static p16(p16: ?P16) {
        if (!p16) return [];

        const { p16: p16Locale } = liveLocalized.TONBlock.master.config;
        const { title } = p16Locale;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            { caption: p16Locale.max_main_validators, value: p16.max_main_validators },
            { caption: p16Locale.max_validators, value: p16.max_validators },
            { caption: p16Locale.min_validators, value: p16.min_validators },
        ]);
    }

    static p17(p17: ?P17) {
        if (!p17) return [];

        const { p17: p17Locale } = liveLocalized.TONBlock.master.config;
        const { title } = p17Locale;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            crystals(p17Locale.max_stake, p17.max_stake, true),
            { caption: p17Locale.max_stake_factor, value: p17.max_stake_factor / 2 ** 16 },
            crystals(p17Locale.min_stake, p17.min_stake, true),
            crystals(p17Locale.min_total_stake, p17.min_total_stake, true),
        ]);
    }

    static validatorConfigs(config: ?TONMasterConfig) {
        if (!config) return [];

        return [...this.p15(config.p15), ...this.p16(config.p16), ...this.p17(config.p17)];
    }

    static p18(p18: ?P18) {
        if (!p18) return [];

        const { p18: p18Locale } = liveLocalized.TONBlock.master.config;
        const { title } = p18Locale;

        let result = [];

        p18.forEach((item, index) => {
            const rows = UIDetailsTable.formatNestedList([
                { caption: `${title} [${index}]` },
                hexToDec(p18Locale.utime_since, item.utime_since),
                hexToDec(p18Locale.bit_price_ps, item.bit_price_ps),
                hexToDec(p18Locale.cell_price_ps, item.cell_price_ps),
                hexToDec(p18Locale.mc_bit_price_ps, item.mc_bit_price_ps),
                hexToDec(p18Locale.mc_cell_price_ps, item.mc_cell_price_ps),
            ]);
            result = [...result, ...rows];
        });

        return result;
    }

    static gasLimitPrices(param: ?TONGasLimitPrices, title: string) {
        if (!param) return [];

        const { TONGasLimitPrice: locale } = liveLocalized.TONBlock.master;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            hexToDec(locale.gas_price, param.gas_price),
            hexToDec(locale.gas_limit, param.gas_limit),
            hexToDec(locale.special_gas_limit, param.special_gas_limit),
            hexToDec(locale.gas_credit, param.gas_credit),
            hexToDec(locale.block_gas_limit, param.block_gas_limit),
            hexToDec(locale.freeze_due_limit, param.freeze_due_limit),
            hexToDec(locale.delete_due_limit, param.delete_due_limit),
            hexToDec(locale.flat_gas_limit, param.flat_gas_limit),
            hexToDec(locale.flat_gas_price, param.flat_gas_price),
        ]);
    }

    static blockLim(param: TONBlockLim, title: string, key: string) {
        if (!param) return [];

        const { TONBlockLim: locale } = liveLocalized.TONBlock.master;

        return UIDetailsTable.formatNestedList({
            list: [
                { caption: title },
                { caption: locale.underload, value: param.underload },
                { caption: locale.soft_limit, value: param.soft_limit },
                { caption: locale.hard_limit, value: param.hard_limit },
            ],
            key,
        });
    }

    static blockLimits(param: ?TONBlockLimits, title: string) {
        if (!param) return [];

        const { TONBlockLimits: locale } = liveLocalized.TONBlock.master;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            ...this.blockLim(param.bytes, locale.bytes, `${title}-${locale.bytes}`),
            ...this.blockLim(param.gas, locale.gas, `${title}-${locale.gas}`),
            ...this.blockLim(param.lt_delta, locale.lt_delta, `${title}-${locale.lt_delta}`),
        ]);
    }

    static msgForwardPrices(param: ?TONMsgForwardPrices, title: string) {
        if (!param) return [];

        const { TONMsgForwardPrices: locale } = liveLocalized.TONBlock.master;

        return UIDetailsTable.formatNestedList([
            { caption: title },
            hexToDec(locale.lump_price, param.lump_price),
            hexToDec(locale.bit_price, param.bit_price),
            hexToDec(locale.cell_price, param.cell_price),
            { caption: locale.ihr_price_factor, value: param.ihr_price_factor },
            { caption: locale.first_frac, value: param.first_frac },
            { caption: locale.next_frac, value: param.next_frac },
        ]);
    }

    static p28(param: ?P28) {
        if (!param) return [];

        const { p28: locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: locale.title },
            { caption: locale.mc_catchain_lifetime, value: param.mc_catchain_lifetime },
            { caption: locale.shard_catchain_lifetime, value: param.shard_catchain_lifetime },
            { caption: locale.shard_validators_lifetime, value: param.shard_validators_lifetime },
            { caption: locale.shard_validators_num, value: param.shard_validators_num },
        ]);
    }

    static p29(param: ?P29) {
        if (!param) return [];

        const { p29: locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: locale.title },
            { caption: locale.round_candidates, value: param.round_candidates },
            { caption: locale.next_candidate_delay_ms, value: param.next_candidate_delay_ms },
            { caption: locale.consensus_timeout_ms, value: param.consensus_timeout_ms },
            { caption: locale.fast_attempts, value: param.fast_attempts },
            { caption: locale.attempt_duration, value: param.attempt_duration },
            { caption: locale.catchain_max_deps, value: param.catchain_max_deps },
            { caption: locale.max_block_bytes, value: param.max_block_bytes },
            { caption: locale.max_collated_bytes, value: param.max_collated_bytes },
        ]);
    }

    static p40(param: ?P40) {
        if (!param) return [];

        const { p40: locale } = liveLocalized.TONBlock.master.config;

        return UIDetailsTable.formatNestedList([
            { caption: locale.title },
            { caption: locale.slashing_period_mc_blocks_count, value: param.slashing_period_mc_blocks_count },
            { caption: locale.resend_mc_blocks_count, value: param.resend_mc_blocks_count },
            { caption: locale.min_samples_count, value: param.min_samples_count },
            { caption: locale.collations_score_weight, value: param.collations_score_weight },
            { caption: locale.signing_score_weight, value: param.signing_score_weight },
            { caption: locale.min_slashing_protection_score, value: param.min_slashing_protection_score },
            { caption: locale.z_param_numerator, value: param.z_param_numerator },
            { caption: locale.z_param_denominator, value: param.z_param_denominator },
        ]);
    }

    static boldRow(caption: string, value: ?string) {
        return { caption, value, captionType: bold };
    }

    static config(master: ?(TONMaster | TONZeroStateMaster)) {
        if (!master || !master.config) return [];

        const {
            p0,
            p1,
            p2,
            p3,
            p4,
            p6,
            p7,
            p8,
            p9 = [],
            p10 = [],
            p11,
            p12,
            p14,
            p18,
            p20,
            p21,
            p22,
            p23,
            p24,
            p25,
            p28,
            p29,
            p31 = [],
            p40,
        } = master.config;

        const { config: configLocale } = liveLocalized.TONBlock.master;

        return [
            this.boldRow(configLocale.p0, p0),
            this.boldRow(configLocale.p1, p1),
            this.boldRow(configLocale.p2, p2),
            this.boldRow(configLocale.p3, p3),
            this.boldRow(configLocale.p4, p4),
            ...this.p6(p6),
            ...otherCurrency(p7, `p7 (${liveLocalized.TONOtherCurrency.title})`, 'p7'),
            ...this.p8(p8),
            { caption: 'p9', value: p9.join(', '), captionType: boldTopOffset },
            this.boldRow('p10', p10.join(', ')),
            ...UIDetailsTable.formatNestedList([
                { caption: configLocale.p11.title },
                ...this.votingParams(p11?.normal_params, configLocale.p11.normal_params),
                ...this.votingParams(p11?.critical_params, configLocale.p11.critical_params),
            ]),
            ...this.p12(p12),
            ...this.p14(p14),
            ...this.validatorConfigs(master.config),
            ...this.p18(p18),
            ...this.gasLimitPrices(p20, configLocale.p20),
            ...this.gasLimitPrices(p21, configLocale.p21),
            ...this.blockLimits(p22, configLocale.p22),
            ...this.blockLimits(p23, configLocale.p23),
            ...this.msgForwardPrices(p24, configLocale.p24),
            ...this.msgForwardPrices(p25, configLocale.p25),
            ...this.p28(p28),
            ...this.p29(p29),
            { caption: configLocale.p31, value: p31.join('\n\n '), captionType: boldTopOffset },
            ...this.p40(p40),
        ];
    }
}
