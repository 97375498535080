import React, { useState } from 'react';

import { liveLocalized } from '@services/LocalizationService';
import { DetailsList } from '#components/UIDetailsTable';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { DePoolParticipant } from '#TONClient/TONDePool/types';
import { ShortBalanceView, UIDetailsTable } from '#components';
import FlexContainer from '#components/common/FlexContainer';

import Caption from '../Caption';

type Props = {
    item: DePoolParticipant;
    detailsListGetter: (any) => DetailsList;
};

export const participantViewTestIDs = {
    participantView: 'participantView',
};

const ParticipantView = ({ item, detailsListGetter }: Props) => {
    const [visibleDetails, setVisibleDetails] = useState(false);

    if (!item) return null;

    const { address, total, reward, reinvest } = item;

    const captionComponent = (
        <Caption>
            <FlexContainer alignItems="alignBaseline">
                {liveLocalized.TotalStake}:&nbsp;
                <ShortBalanceView balance={total} />
            </FlexContainer>
        </Caption>
    );

    const secondDetailsComponent = (
        <FlexContainer alignItems="alignBaseline">
            {liveLocalized.Reward}:&nbsp;
            <ShortBalanceView balance={reward} />
        </FlexContainer>
    );

    return (
        <>
            <UIDetailsButton
                testID={participantViewTestIDs.participantView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                truncTitle
                title={address || '-'}
                captionComponent={captionComponent}
                details={`${liveLocalized.Reinvest}: ${reinvest.toString()}`}
                secondDetails={secondDetailsComponent}
                onPress={() => setVisibleDetails(!visibleDetails)}
            />
            {visibleDetails && <UIDetailsTable detailsList={detailsListGetter(item)} />}
        </>
    );
};

ParticipantView.configs = {
    keyExtractor: ({ address }: DePoolParticipant): string => {
        return `participant-view-${address}`;
    },
    idKey: 'address',
    collectionName: liveLocalized.ParticipantQty,
    renderItem: () => null,
};

ParticipantView.externalConfigs = {
    sortingKey: 'total',
};

export default ParticipantView;
