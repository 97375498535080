// @flow
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import DataService from '@services/DataServices';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import ListScreen from '#controllers/Lists/ListScreen';
import NFTCollectionView from '#components/EVERList/ListViews/Tokens/NFTCollectionView';
import EVERNft from '#TONClient/EVERNft';
import Utils from '#helpers/Utils';

const NFTCollectionsComponent = () => {
    const ref = useRef(null);

    const [params] = useParams();

    const details = [
        {
            title: (DataService.NFTCollections || []).length,
            details: liveLocalized.NumberCollections,
        },
        {
            title: Utils.summarizeByKey(DataService.NFTCollections || [], 'tokensCount'),
            details: liveLocalized.NumberNFTs,
        },
    ];

    const onLoadCollections = async () => {
        if (DataService.NFTCollections?.length) return;

        controllerService.showSpinnerOverlay();
        EVERNft.fetchNFTCollections().then(controllerService.hideSpinnerOverlay);
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadCollections);
    }, [params]);

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.CatalogNFTTokens}
            details={details}
            collectionConfigs={NFTCollectionView.getCollectionConfigsNFT()}
            externalControlConfigs={{
                ...NFTCollectionView.externalConfigs,
                items: DataService.NFTCollections,
                isLoading: !DataService.NFTCollections,
            }}
            realtimeUpdated={false}
        />
    );
};

const NFTCollections = observer(NFTCollectionsComponent);

export { NFTCollections };
