// @flow
import FBAccounts from '@Firebase/FBAccounts';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';

import TONClient from '../TONClient';
import EVERTransaction from '../EVERTransaction';
import { CONVERT, ERROR, NOT_FOUND, SUCCESS } from '../statuses';
import TONFilter from '../TONFilter';
import type {
    AccountCodeHash,
    AccountCustodian,
    AccountFilterValues,
    GetAccountsArgs,
    GetCustodiansArgs,
    TONAccountT,
    UnconfirmedTransactionForAccount,
} from './types';
import { additionalFields, shortFields, binaryFields } from './fields';
import { FBQueryWrapper } from '../helpers';

export default class TONAccount {
    static statuses = {
        unInit: 0,
        active: 1,
        frozen: 2,
    };

    static states = {
        unInit: 0,
        active: 1,
        frozen: 2,
        nonExist: 3,
    };

    static statusChanges = {
        unchanged: 0,
        frozen: 1,
        deleted: 2,
    };

    static skipReasons = {
        noState: 0,
        badState: 1,
        noGas: 2,
    };

    static formatter = (acDoc: TONAccountT, namesById: { [key: string]: string }): TONAccountT => ({
        ...acDoc,
        // joined fields
        name: namesById[acDoc?.id],
    });

    static newFormatter = (account: TONAccountT, namesById: { [key: string]: string }): TONAccountT => {
        const id = account.id?.replace('account/', '') || '';

        return {
            ...account,
            id,
            name: namesById[id],
        };
    };

    static accountsForRunTVMbyId = {};

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('TONAccount', str, params);
    }

    static result(args: ?GetAccountsArgs) {
        return `
            ${shortFields}
            ${args?.needAdditionalFields ? additionalFields : ''}
            ${args?.needBinaryFields ? binaryFields : ''}
        `;
    }

    static balanceFilter(args: GetAccountsArgs, isJSFetchQuery: boolean = false) {
        const { lastItem, filterValues, direction } = args;
        const { minBalance, maxBalance } = TONFilter.getValuesFromFilterValues(filterValues);
        if (!minBalance && !maxBalance && !lastItem) {
            return {};
        }

        const nanoMaxBalance = TONClient.tonsToNano(maxBalance || 0);
        const totalMax =
            direction === 'DESC'
                ? lastItem?.balance && nanoMaxBalance
                    ? Math.min(nanoMaxBalance, TONClient.number(lastItem?.balance))
                    : nanoMaxBalance || lastItem?.balance || 0
                : nanoMaxBalance;

        const nanoMinBalance = TONClient.tonsToNano(minBalance || 0);
        const totalMin =
            direction === 'DESC'
                ? nanoMinBalance
                : lastItem?.balance && nanoMinBalance
                ? Math.max(nanoMinBalance, TONClient.number(lastItem?.balance))
                : nanoMinBalance || lastItem?.balance || 0;

        return {
            balance: {
                ...(totalMin && { ge: isJSFetchQuery ? `"${totalMin}"` : `${totalMin}` }: any),
                ...(totalMax && { le: isJSFetchQuery ? `"${totalMax}"` : `${totalMax}` }),
            },
        };
    }

    static codeHashFilter(filterValues: AccountFilterValues) {
        const { code_hash } = TONFilter.getValuesFromFilterValues(filterValues);
        return code_hash ? { code_hash: { eq: code_hash } } : {};
    }

    static filter(args: GetAccountsArgs, isJSFetchQuery: boolean = false) {
        const { filterValues } = args;
        return {
            ...(this.codeHashFilter(filterValues): { code_hash?: { eq: string } }),
            ...(this.balanceFilter(args, isJSFetchQuery): any), // {| balance?: { ge?: string, le?: string } |}
            ...TONFilter.timeFilter('last_paid', filterValues),
            // ...(lastItem ? { last_paid: { lt: lastItem?.last_paid } } : {}), // second pagination
            ...TONFilter.workchainShardFilter(filterValues),
        };
    }

    static async getAccounts(args: GetAccountsArgs) {
        const filter = this.filter(args, true);
        const orderBy = TONFilter.orderBy(args, 'balance', true);

        const log = this.initLog('list', {
            args,
            filter,
        });

        try {
            const [docs, namesById] = await Promise.all([
                TONClient.queryAccounts(
                    Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : '',
                    this.result(args),
                    Utils.JSONStringifyWithoutParanthesesAroundKeys(orderBy[0]),
                    args?.limit
                ),
                FBQueryWrapper(FBAccounts.getAccountNamesById, {})(),
            ]);

            if (!docs?.length) {
                log.debug(NOT_FOUND);
                return [];
            }

            const formattedDocs = docs.map((doc) => this.formatter(doc, namesById));
            log.debug(SUCCESS, formattedDocs);
            return formattedDocs;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async getCreatorIdForAccount(aId: string): Promise<?string> {
        const txDocs = await TONClient.queryTransactions(
            Utils.JSONStringifyWithoutParanthesesAroundKeys({
                account_addr: { eq: `"${aId}"` },
                orig_status: { eq: TONAccount.states.nonExist },
                end_status: { ne: TONAccount.states.nonExist },
            }),
            EVERTransaction.accountCreatorFields
        );

        if (!txDocs?.length) return null;

        return txDocs[0]?.in_message?.src;
    }

    static async getCreatorIdsForAccounts(aIds: string[]): Promise<(?string)[]> {
        const txDocs = await TONClient.loadAllInList(aIds, {
            result: EVERTransaction.accountCreatorFields,
            collection: TONClient.collections.transactions,
            getFilter: (subList) => ({
                account_addr: { in: subList },
                orig_status: { eq: TONAccount.states.nonExist },
                end_status: { ne: TONAccount.states.nonExist },
            }),
        });
        const txDocsByAccountAddr = Utils.groupById(txDocs, 'account_addr');

        return aIds.map((id) => txDocsByAccountAddr[id]?.in_message?.src);
    }

    static async getAccountForRunTVM(aId: string) {
        if (this.accountsForRunTVMbyId[aId]) {
            return this.accountsForRunTVMbyId[aId];
        }

        const acDocs = await TONClient.queryAccounts(
            Utils.JSONStringifyWithoutParanthesesAroundKeys({ id: { eq: `"${aId}"` } }),
            TONClient.runTVMFields
        );

        if (!acDocs.length) return null;

        this.accountsForRunTVMbyId[aId] = acDocs[0];
        return acDocs[0];
    }

    static async getAccountsForRunTVM(aIds: string[]) {
        const acDocs = await TONClient.loadAllInList(aIds, {
            result: TONClient.runTVMFields,
            collection: TONClient.collections.accounts,
            getFilter: (subList) => ({ id: { in: subList } }),
        });
        const acDocsById = Utils.groupById(acDocs, 'id');

        return aIds.map<string>((id) => acDocsById[id]);
    }

    static async getAccountBalance(aId: string): Promise<?number> {
        const log = this.initLog('balance', aId);

        try {
            const acDocs = await TONClient.queryCollectionAccounts(
                { id: { eq: aId } },
                `id balance${TONClient.formatDEC}`
            );

            log.debug(SUCCESS, acDocs);

            return acDocs?.length && acDocs[0].balance;
        } catch (e) {
            log.error(e);
            return null;
        }
    }

    static async getCodeHashesById(ids: string[], needLog: boolean = false): Promise<{ [string]: AccountCodeHash }> {
        const log = this.initLog('Get code_hash for ids', ids.length);

        try {
            const acDocs = await TONClient.loadAllInList(ids, {
                result: 'id code_hash',
                collection: TONClient.collections.accounts,
                getFilter: (subList) => ({ id: { in: subList } }),
            });
            const acDocsById = Utils.groupById(acDocs, 'id');

            needLog && log.debug(SUCCESS, acDocsById);
            return acDocsById;
        } catch (e) {
            log.error(e);
            return {};
        }
    }

    static async getCodeHashes(ids: string[]): Promise<AccountCodeHash[]> {
        const codeHashesById = await this.getCodeHashesById(ids);
        return Object.keys(codeHashesById).map((id) => codeHashesById[id]);
    }

    static async getAccountIdsByExpression(
        aId: ?string,
        args?: GetAccountsArgs
        // isFullId: boolean = true,
    ): Promise<*> {
        if (!aId) {
            return null;
        }

        const log = this.initLog(aId);
        const isPartiallyId = aId.length < 45;

        try {
            const hexAId = isPartiallyId ? aId : (await TONClient.convertAddressToHex(aId)) || '';
            if (!hexAId) {
                log.debug(NOT_FOUND);
                return null;
            }

            if (hexAId !== aId) {
                console.log(CONVERT, hexAId);
            }

            const isChainIncluded = hexAId.includes(':');
            let acDocs;
            if (isChainIncluded) {
                acDocs = await TONClient.queryCollectionAccounts(
                    { id: { ge: hexAId, le: `${hexAId}z` } },
                    this.result(args)
                );
            } else {
                const acDocsArrays: TONAccountT[][] = await Promise.all(
                    ['2', '1', '0', '-1'].map((prefix) => {
                        return TONClient.queryCollectionAccounts(
                            { id: { ge: `${prefix}:${hexAId}`, le: `${prefix}:${hexAId}z` } },
                            this.result(args)
                        );
                    })
                );

                acDocs = acDocsArrays.reduce((prev, curr) => [...prev, ...curr], []);
            }

            if (acDocs[0]?.id) {
                const namesById = await FBQueryWrapper(FBAccounts.getAccountNamesById, {})();
                const resultFormatted = acDocs.map((doc) => this.formatter(doc, namesById));

                log.debug(SUCCESS, resultFormatted);
                return resultFormatted;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getAccountWithCreator(aId: ?string, args?: GetAccountsArgs): Promise<TONAccountT | null> {
        if (!aId) {
            return null;
        }

        const log = this.initLog(aId);

        try {
            const hexAId = (await TONClient.convertAddressToHex(aId)) || '';
            if (!hexAId) {
                log.debug(NOT_FOUND);
                return null;
            }

            if (hexAId !== aId) console.log(CONVERT, hexAId);

            const [account, namesById] = await Promise.all([
                TONClient.queryAccount(hexAId, this.result(args)),
                FBQueryWrapper(FBAccounts.getAccountNamesById, {})(),
            ]);

            if (account?.id) {
                const formattedAccount = this.newFormatter(account, namesById);

                const creatorId = args?.needAdditionalFields
                    ? await this.getCreatorIdForAccount(formattedAccount.id)
                    : null;

                const result = {
                    ...formattedAccount,
                    creator: creatorId || '',
                };

                log.debug(SUCCESS, result);
                return result;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async getCustodiansForAccount({
        id,
        account,
        contractsByCodeHash,
    }: GetCustodiansArgs): Promise<AccountCustodian[]> {
        const log = this.initLog('Custodians for account', { id, account });

        const accountWithBocAndCodeHash = id && !account ? await this.getAccountForRunTVM(id) : account;

        if (!accountWithBocAndCodeHash) {
            log.debug("Can't find account", accountWithBocAndCodeHash);
            return [];
        }

        const contract = contractsByCodeHash[accountWithBocAndCodeHash?.code_hash] || {};

        if (contract?.showCustodianPublicKeys) {
            const result: { custodians: any[] } = await TONClient.runTVMWrapped({
                account: accountWithBocAndCodeHash,
                abi: contract?.abi,
                functionName: 'getCustodians',
            });
            const custodians = result?.custodians.map((custodian) => ({
                ...custodian,
                pubkey: custodian.pubkey.padStart(64, '0'),
            }));
            log.debug(SUCCESS, custodians);

            return custodians;
        }

        return [];
    }

    static async getUnconfirmedTransactionsForAccount({
        id,
        account,
        contractsByCodeHash,
    }: GetCustodiansArgs): Promise<UnconfirmedTransactionForAccount[]> {
        const log = this.initLog('Transactions for account', { id, account });

        const accountWithBocAndCodeHash = id && !account ? await this.getAccountForRunTVM(id) : account;

        if (!accountWithBocAndCodeHash) {
            log.debug("Can't find account", accountWithBocAndCodeHash);
            return [];
        }

        const contract = contractsByCodeHash[accountWithBocAndCodeHash?.code_hash] || {};

        const result: { transactions: UnconfirmedTransactionForAccount[] } = await TONClient.runTVMWrapped({
            account: accountWithBocAndCodeHash,
            abi: contract?.abi,
            functionName: 'getTransactions',
        });

        const transactions = result?.transactions || [];

        log.debug(SUCCESS, transactions);

        return transactions;
    }

    static async aggregateAccounts(filterValues: AccountFilterValues) {
        const filter = await this.filter({ filterValues });
        const log = this.initLog('aggregate accounts count', { filterValues, filter });

        return TONClient.aggregateItems({ filter, collection: 'accounts', log });
    }

    static async aggregateAccountsBalance(filterValues: AccountFilterValues) {
        try {
            const filter = await this.filter({ filterValues });
            const log = this.initLog('aggregate accounts balance', { filterValues, filter });
            const accountIdsByType = await FBQueryWrapper(FBAccounts.getAccountIdsByType, null)();

            return TONClient.aggregateItems({
                collection: 'accounts',
                filter: {
                    ...filter,
                    id: {
                        notIn: accountIdsByType?.[FBAccounts.typeNames.burner] || [], // burned tokens
                    },
                },
                fields: [{ field: 'balance', fn: 'SUM' }],
                log,
            });
        } catch (e) {
            return null;
        }
    }

    static async aggregateAccountsBalanceInGivers(filterValues: AccountFilterValues) {
        const filter = await this.filter({ filterValues });

        return this.aggregateAccountsBalanceInGiversForFilter(filter);
    }

    static async aggregateAccountsBalanceInGiversForFilter(filter: any) {
        try {
            const accountIdsByType = await FBQueryWrapper(FBAccounts.getAccountIdsByType, null)();
            if (!accountIdsByType) return null;

            const idFilter = {
                id: {
                    in: accountIdsByType[FBAccounts.typeNames.giver] || [],
                },
            };

            const filterWithId = {
                ...filter,
                ...idFilter,
            };

            const log = this.initLog('aggregate balance in givers', { filter: filterWithId });

            return TONClient.aggregateItems({
                collection: 'accounts',
                filter: filterWithId,
                fields: [{ field: 'balance', fn: 'SUM' }],
                log,
            });
        } catch (e) {
            return null;
        }
    }

    static async aggregateAccountsForLast24h() {
        const filter = {
            now: { gt: MomentHelper.dayBefore() },
            orig_status: { eq: TONAccount.states.nonExist },
            end_status: { ne: TONAccount.states.nonExist },
        };
        const log = this.initLog('accounts for last 24h', { filter });

        return TONClient.aggregateItems({ filter, collection: 'transactions', log });
    }

    static async aggregateNewAccountsMonthly() {
        const log = this.initLog('Last15months');

        try {
            const monthsCount = 15;
            const months: number[] = Array(monthsCount)
                .fill()
                .map((e, i) => monthsCount - i);
            const operations = months.map((month) => ({
                filter: {
                    created_at: {
                        ge: MomentHelper.monthBefore(month, false),
                        lt: MomentHelper.monthBefore(month - 1, false),
                    },
                    code_hash: { gt: null },
                },
                collection: TONClient.collections.messages,
            }));

            const accountsCount = await TONClient.batchQuery(operations, TONClient.operationTypes.aggregateCollection);

            const result = accountsCount.map((count, index) => ({
                timeStamp: MomentHelper.monthBefore(monthsCount - index - 1, false),
                value: count,
            }));

            log.debug(SUCCESS, result);
            return result;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async aggregateCurrentCirculatingSupply() {
        const emptyFilterValues: any = {};
        const [totalBalances, giversBalances] = await Promise.all([
            this.aggregateAccountsBalance(emptyFilterValues),
            this.aggregateAccountsBalanceInGiversForFilter(),
        ]);

        return TONClient.number(totalBalances) - TONClient.number(giversBalances);
    }

    static async aggregateCirculatingSupplyMonthly() {
        const log = this.initLog('Circulating supply last 15 months');
        const accountIdsByType = await FBQueryWrapper(FBAccounts.getAccountIdsByType, {})();

        try {
            const monthsCount = 15;
            const months: number[] = Array(monthsCount)
                .fill()
                .map((e, i) => i);

            const giverTxsFilters = months.map((month) => ({
                filter: {
                    account_addr: {
                        in: accountIdsByType[FBAccounts.typeNames.giver] || [],
                    },
                    now: {
                        ge: MomentHelper.monthBefore(month, false),
                        lt: MomentHelper.monthBefore(month - 1, false),
                    },
                },
                fields: [{ field: 'balance_delta', fn: 'SUM' }],
            }));

            const burnerTxsFilters = months.map((month) => ({
                filter: {
                    account_addr: {
                        in: accountIdsByType[FBAccounts.typeNames.burner] || [],
                    },
                    now: {
                        ge: MomentHelper.monthBefore(month, false),
                        lt: MomentHelper.monthBefore(month - 1, false),
                    },
                },
                fields: [{ field: 'balance_delta', fn: 'SUM' }],
            }));

            const emissionMessagesFilters = months.map((month) => ({
                filter: {
                    src: { eq: '-1:0000000000000000000000000000000000000000000000000000000000000000' },
                    created_at: {
                        ge: MomentHelper.monthBefore(month, false),
                        lt: MomentHelper.monthBefore(month - 1, false),
                    },
                },
                fields: [{ field: 'value', fn: 'SUM' }],
            }));

            const [
                currentCirculatingSupply,
                giverTxsBalanceDeltas,
                burnerTxsBalanceDeltas,
                emissionMessageValues,
            ] = await Promise.all([
                this.aggregateCurrentCirculatingSupply(),
                TONClient.batchAggregateTransactions(giverTxsFilters),
                TONClient.batchAggregateTransactions(burnerTxsFilters),
                TONClient.batchAggregateMessages(emissionMessagesFilters),
            ]);

            let circulatingSupply = currentCirculatingSupply;
            const result = giverTxsBalanceDeltas
                .map((monthBalanceDelta, index) => {
                    circulatingSupply =
                        circulatingSupply +
                        monthBalanceDelta -
                        emissionMessageValues[index] +
                        burnerTxsBalanceDeltas[index];
                    return {
                        timeStamp: MomentHelper.monthBefore(index, false),
                        value: Math.floor(TONClient.nanoToTons(circulatingSupply)),
                    };
                })
                .sort((a, b) => a.timeStamp - b.timeStamp);

            log.debug(SUCCESS, result);
            return result;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }
}
