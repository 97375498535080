import * as React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { themesService } from '@services/ThemesService';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { ThemesNames } from '#uikit/themes';

import { UILabelProps } from './types';
import './UILabel.scss';

const UILabel: React.FC<UILabelProps> = ({
    testID,
    role = TypographyVariants.ParagraphText,
    color: colorProp = ColorVariants.TextPrimary,
    style,
    classNames,
    ...rest
}) => {
    const preparedClassNames = classnames('defaultTextComponentContainer', classNames, {
        [role]: role,
        [(themesService.isDarkTheme ? ThemesNames.DarkTheme : ThemesNames.LightTheme) + ' ' + colorProp]: colorProp,
    });

    return <div className={preparedClassNames} {...rest} data-testid={testID} style={style} />;
};

export default observer(UILabel);
