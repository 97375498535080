import React from 'react';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import classnames from 'classnames';

import { UIButton, UITextButton } from '#components';
import type { UIActionComponentProps } from '#components/UIActionComponent/ReactActionClassComponent';
import { common } from '#uikit/designCore/styles';

import { ExternalLinkTypes } from './contants';

import './ExternalLink.scss';

type Props = UIActionComponentProps & {
    type?: string;
    href: string;
    title?: string | JSX.Element;
    classNames?: string;
    titleColor?: string;
    buttonColor?: string;
    buttonSize?: string;
    buttonShape?: string;
    openInNewTab?: boolean;
    needArrowIcon?: boolean;
};

const ExternalLink: React.FC<Props> = ({
    type = ExternalLinkTypes.text,
    title = '',
    href,
    openInNewTab = false,
    needArrowIcon = false,
    onPress = () => {},
    ...props
}) => {
    const Component = type === ExternalLinkTypes.text ? UITextButton : UIButton;

    return (
        <a
            className={classnames('ExternalLink', needArrowIcon && common.displayFlex)}
            href={href}
            target={openInNewTab ? '_blank' : '_self'}
            rel="noopener noreferrer"
        >
            <Component {...props} onPress={onPress} title={title || href} />
            {needArrowIcon && (
                <div className="ExternalLink__img-wrapper">
                    <NorthEastIcon className={classnames('MUI', 'ExternalLink__img')} />
                </div>
            )}
        </a>
    );
};

export { ExternalLink };
