import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { ColorVariants } from '#uikit/designCore/constants/color';
import { border, margin, common, padding } from '#uikit/designCore/styles';
import { UIButton } from '#components';

import './SignButton.scss';

type Props = {
    active: boolean;
    onSwitch: (negative: boolean) => void;
};

const SignButton: React.FC<Props> = ({ active, onSwitch }) => {
    const [negative, setNegative] = useState(false);

    useEffect(() => {
        setNegative(active);
    }, [active]);

    const onPress = useCallback(() => {
        setNegative(!negative);
        onSwitch(!negative);
    }, [negative]);

    return (
        <UIButton
            classNames={classnames(
                'SignButton',
                padding.horizontalNone,
                margin.rightSmall,
                common.icon,
                !negative && border.common
            )}
            titleColor={!negative && ColorVariants.TextSecondary}
            buttonColor={!negative ? ColorVariants.BackgroundPrimary : ColorVariants.BackgroundAccent}
            title="-"
            onPress={onPress}
        />
    );
};

export default SignButton;
