import React, { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
//@ts-ignore doesn`t see import
import type { Profile } from '@Firebase/FBProfiles';
import { useParams } from '#navigation/hooks/useParams';
import type { DetailsList } from '#components/UIDetailsTable';
import { Accordion, CopyableView, UIDetailsTable } from '#components';
import type { CopyableViewProps } from '#components/CopyableView';
import type { TONCollectionConfigs, TONExternalConfigs } from '#components/EVERList';
import Avatar from '#controllers/Details/components/Avatar';
import { ListScreenRefType } from '#controllers/Lists/ListScreen/types';
import type { FilterValues } from '#TONClient/TONFilter';
import type { FilterPropObj } from '#TONClient/TONFilterTags';
import { margin } from '#uikit/designCore/styles/margin';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import ListScreen from '../Lists/ListScreen/ListScreen';

type DetailsScreenRefType = Pick<ListScreenRefType, 'componentWillFocus' | 'componentWillBlur' | 'updateFilterValues'>;

type FuncProps = {
    title: string;
    titleRole?: TypographyVariants;
    profile?: Profile;
    belowTitleComponent?: ReactNode;
    rightTitleComponent?: ReactNode;
    needTagFilterContainerAboveTitle?: boolean;
    needListInDetails?: boolean;
    details?: DetailsList;
    moreDetails?: DetailsList;
    detailsComponent?: ReactNode;
    realtimeUpdated?: boolean;
    needMoreDetailsButton?: boolean;
    listTitle?: string;
    subtitleRole?: TypographyVariants;
    filterProps?: FilterPropObj;
    collectionConfigs?: TONCollectionConfigs;
    externalControlConfigs?: TONExternalConfigs;
    // Need fix Flow Errors with it
    itemsLoader?: (args: any, filterValues?: FilterValues) => any;
    copyableViewData?: CopyableViewProps;
    onChangeFilterCustom?: (filterValues: FilterValues) => void;
    isPrivate?: boolean;
    onUnlocked?: (unlocked: boolean, walletAddress?: string) => void;
};

export const detailsScreenTestIDs = {
    fileSize: (kilobytes: string) => `fileSize_${kilobytes}`,
    downloadFileButton: 'downloadFileButton',
    addressBlock: 'addressBlock',
    prevBlockButton: 'prevBlockButton',
    nextBlockButton: 'nextBlockButton',
};

const DetailsScreen = forwardRef<DetailsScreenRefType, FuncProps>(
    (
        {
            titleRole,
            subtitleRole,
            copyableViewData,
            needMoreDetailsButton = true,
            needListInDetails = false,
            detailsComponent,
            moreDetails = [],
            details = [],
            profile,
            ...props
        },
        Ref
    ) => {
        const ref = useRef<ListScreenRefType>(null);

        const [params] = useParams();
        const { id } = params;

        // Components
        const copyableViewComponent = (
            <CopyableView
                {...(copyableViewData === undefined
                    ? {
                          caption: liveLocalized.ID,
                          value: id,
                      }
                    : copyableViewData)}
                classNames={controllerService.reactContentClassNames}
            />
        );

        const optionalContentComponent = (
            <>
                {/* details */}
                <UIDetailsTable
                    narrow={controllerService.isNarrow}
                    containerClassNames={controllerService.reactContentClassNames}
                    detailsList={details}
                />

                {/* moreDetails */}
                {needMoreDetailsButton && (
                    <Accordion classNames={classnames(controllerService.reactContentClassNames, margin.topDefault)}>
                        <UIDetailsTable
                            narrow={controllerService.isNarrow}
                            containerClassNames={margin.topDefault}
                            detailsList={moreDetails}
                        />
                    </Accordion>
                )}

                {detailsComponent}
            </>
        );

        const customContentComponent = (
            <>
                {copyableViewComponent}
                {optionalContentComponent}
            </>
        );

        useImperativeHandle(Ref, () => ({
            componentWillFocus: () => ref?.current && ref.current.componentWillFocus(),
            componentWillBlur: () => ref?.current && ref.current.componentWillBlur(),
            updateFilterValues: (newFilterValues: FilterValues) =>
                ref?.current && ref.current.updateFilterValues(newFilterValues),
        }));

        return (
            <ListScreen
                {...props}
                ref={ref}
                needList={needListInDetails}
                titleRole={titleRole || TypographyVariants.TitleMedium}
                subtitleRole={subtitleRole || TypographyVariants.PromoMedium}
                customContentComponent={customContentComponent}
                avatarComponent={<Avatar profile={profile} />}
                hideTetris
            />
        );
    }
);

export default observer(DetailsScreen);
