import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import UISeparator from '#components/UISeparator';
import { width, common, flex, color } from '#uikit/designCore/styles';

import AccentDetailsView from './AccentDetailsView';
import type { AccentDetails } from './AccentDetailsView';

const Separator = () => <UISeparator color={color.BackgroundSecondary} />;

type Props = {
    detailsList: AccentDetails[];
    containerClassNames?: string;
    detailsLinkRole?: string;
    cellClassNames?: string;
};

export const accentDetailsTableTestIDs = {
    detailsAccentTable: 'detailsAccentTable',
};

const AccentDetailsTable: React.FC<Props> = ({
    detailsList = [],
    containerClassNames,
    detailsLinkRole,
    cellClassNames,
}) => {
    const narrow = controllerService.isNarrow;

    const renderDetailsView = (item: AccentDetails, needBorderBottom: boolean = false) => {
        if (!item) return null;

        return (
            <>
                <AccentDetailsView {...item} containerClassNames={flex.flex1} detailsLinkRole={detailsLinkRole} />
                {needBorderBottom && <Separator />}
            </>
        );
    };

    const narrowView = (() => {
        const { length } = detailsList;
        return (
            <div className={containerClassNames} data-testid={accentDetailsTableTestIDs.detailsAccentTable}>
                {detailsList.map((item, index) => (
                    <React.Fragment key={`details-view-${item.title || ''}-${index}`}>
                        {renderDetailsView(item, index < length - 1)}
                    </React.Fragment>
                ))}
            </div>
        );
    })();

    const wideView = (() => {
        const { length } = detailsList;

        const result: JSX.Element[] = [];
        for (let i = 0; i < length; i += 2) {
            const row = (
                <div key={`details-view-${detailsList[i].title || ''}-${i}`} className={containerClassNames}>
                    <div
                        className={classnames(common.displayFlex, flex.row, width.full, cellClassNames)}
                        data-testid={accentDetailsTableTestIDs.detailsAccentTable}
                    >
                        {renderDetailsView(detailsList[i])}
                        {renderDetailsView(detailsList[i + 1])}
                    </div>
                    {i < length - 2 ? <Separator /> : null}
                </div>
            );
            result.push(row);
        }
        return <>{result}</>;
    })();

    return narrow ? narrowView : wideView;
};

export default observer(AccentDetailsTable);
