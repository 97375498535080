import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import { TIP3TokenService } from '@services/DataServices/TIP3Tokens';
import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import FBContracts from '@Firebase/FBContracts';
import type { Contract, ContractsByCodeHash } from '@Firebase/FBContracts';
import MomentHelper from '#helpers/MomentHelper';
import { TONClient, TONAccount, TONFilter, EVERTip3 } from '#TONClient';
import { FBQueryWrapper } from '#TONClient/helpers';
import type { AccountCustodian, TONAccountT, UnconfirmedTransactionForAccount } from '#TONClient/TONAccount/types';
import type { TransactionFilterValues } from '#TONClient/EVERTransaction/types';
import type { MessageFilterValues } from '#TONClient/TONMessage/types';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import DetailsTabView from '#controllers/Details/components/DetailsTabView';
import { SurfLink } from '#controllers/Details/components/SurfLink';
import AddressSection from '#controllers/Details/components/AddressSection';
import { TransfersList } from '#controllers/Details/Account/TabViewLists/Transfers';
import { getFilterValuesFromURLParams } from '#controllers/helpers';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import { TONLog } from '#TONUtility';
import { Accordion, CopyableView, QRCode, UIDetailsSortingTable, UIDetailsTable } from '#components';
import Utils from '#helpers/Utils';
import { common, container, flex, font, margin } from '#uikit/designCore/styles';

import MessagesList from '../../TabViewLists/MessagesList';
import TransactionsList from '../../TabViewLists/TransactionsList';
import DetailsScreen from '../DetailsScreen';

type Addresses = {
    address: string;
    urlAddress: string;
};

type Base64Addresses = {
    bounce: Addresses;
    nonBounce: Addresses;
};

const log = new TONLog('AccountDetailsScreen');

const getTypeValueForAccountStatus = (status?: number) => {
    if (!status && status != 0) return {};

    const statuses = {
        [TONAccount.statuses.active]: {
            value: liveLocalized.Statuses.Active,
            type: UIDetailsTable.cellType.success,
        },
        [TONAccount.statuses.unInit]: {
            value: liveLocalized.Statuses.UnInit,
            type: UIDetailsTable.cellType.disabled,
        },
        [TONAccount.statuses.frozen]: {
            value: liveLocalized.Statuses.Frozen,
            type: UIDetailsTable.cellType.default,
        },
        default: {
            value: '',
            type: UIDetailsTable.cellType.default,
        },
    };

    return statuses[status] || statuses.default;
};

const AccountComponent = forwardRef((props, Ref) => {
    const ref = useRef(null);
    const messagesList = useRef(null);
    const transactionsList = useRef(null);
    const transfersList = useRef(null);

    const [account, setAccount] = useState<Maybe<TONAccountT>>(null);
    const [surfHash, setSurfHash] = useState<string>('');
    const [contract, setContract] = useState<Maybe<Contract>>({
        name: '',
        code_hash: '',
        type: '',
    });
    const [custodians, setCustodians] = useState<AccountCustodian[]>([]);
    const [unconfirmedTsx, setUnconfirmedTsx] = useState<UnconfirmedTransactionForAccount[]>([]);
    const [base64, setBase64] = useState<Base64Addresses>({
        bounce: {
            address: '',
            urlAddress: '',
        },
        nonBounce: {
            address: '',
            urlAddress: '',
        },
    });

    const [params, setParams] = useParams();

    const { id: idFromUrlParams, tabIndex: tabIndexParam, sortDirection: sortDirectionByDefault } = params;
    const id = idFromUrlParams || account?.id;

    // Details
    const {
        acc_type,
        creator,
        balance,
        name,
        last_paid,
        due_payment,
        last_trans_lt,
        balance_other,
        code,
        data,
        library,
        proof = '',
        boc,
        code_hash = '',
        data_hash = '',
        library_hash = '',
    } = account || {};

    const KWTWallet = TIP3TokenService.KWTWalletsByAddress[account?.id || ''];
    const details = [
        name ? { caption: liveLocalized.Name, value: name } : null,
        {
            caption: liveLocalized.Status,
            ...getTypeValueForAccountStatus(acc_type),
        },
        DetailsRow.accountLinkWithCopy(liveLocalized.Creator, creator || ''),
        DetailsRow.crystals(liveLocalized.Balance, balance),

        DetailsRow.contractType(contract, id || ''),
        DetailsRow.tokenBalanceRows(
            liveLocalized.TokenBalances,
            TIP3TokenService.walletsByOwnerAddress[account?.id || ''] ||
                TIP3TokenService.KWTWalletsByAddress[account?.id || '']
                ? [
                      ...(TIP3TokenService.walletsByOwnerAddress[account?.id || ''] || []),
                      ...(KWTWallet ? [KWTWallet] : []),
                  ]
                : null
        ),
    ];

    const balanceOtherRows = DetailsRow.otherCurrency(
        balance_other,
        liveLocalized.TONAccount.balance_other,
        'balance_other'
    );

    const moreDetails = [
        ...balanceOtherRows,
        {
            caption: liveLocalized.TONAccount.last_paid,
            value: MomentHelper.getTimeDate(last_paid || null),
        },
        DetailsRow.crystals(liveLocalized.TONAccount.due_payment, due_payment),
        {
            caption: liveLocalized.TONAccount.last_trans_lt,
            value: last_trans_lt,
        },
        ...DetailsRow.codeDataLibraryRows(code, data, library, code_hash, data_hash, library_hash, 'account', id),
        ...DetailsRow.proofBocRows(proof, boc, 'account', id),
    ];

    // Events
    const onChangeMessagesFilter = (filterValues: MessageFilterValues) => {
        setParams({
            ...TONFilter.getValuesFromFilterValues(filterValues, true),
            tabIndex: 0,
            id,
        });
    };

    const onChangeTransactionsFilter = (filterValues: TransactionFilterValues) => {
        setParams({
            ...TONFilter.getValuesFromFilterValues(filterValues, true),
            tabIndex: 1,
            id,
        });
    };

    const onChangeTransfersFilter = (filterValues: {}) => {
        setParams({
            ...TONFilter.getValuesFromFilterValues(filterValues, true),
            tabIndex: 2,
            id,
        });
    };

    // Actions
    const loadAccount = async (aId): Promise<Maybe<TONAccountT>> => {
        const newAccount = await TONAccount.getAccountWithCreator(aId, {
            needAdditionalFields: true,
            needBinaryFields: true,
        });

        if (!newAccount && !account) {
            controllerService.showPageNotFound(liveLocalized.AccountWithThisAddressWasntFound);
            return null;
        }

        if (newAccount) {
            setAccount(newAccount);
            return newAccount;
        }

        return account;
    };

    const convertAddress = async (aId) => {
        const conversionRequest = (params: any) => {
            return TONClient.convertAddressToBase64({ address: aId, ...params });
        };
        const [bounceBase64, bounceBase64Url, nonBounceBase64, nonBounceBase64Url] = await Promise.all([
            conversionRequest({ url: false, bounce: true }),
            conversionRequest({ url: true, bounce: true }),
            conversionRequest({ url: false, bounce: false }),
            conversionRequest({ url: true, bounce: false }),
        ]);
        setBase64({
            bounce: {
                address: bounceBase64,
                urlAddress: bounceBase64Url,
            },
            nonBounce: {
                address: nonBounceBase64,
                urlAddress: nonBounceBase64Url,
            },
        });
    };

    const onLoadAccountData = async (params) => {
        const { id: idParam } = params;

        if (!idParam) {
            log.debug('No account Id passed');
            return;
        }

        controllerService.showSpinnerOverlay();
        const newAccount = await loadAccount(idParam);
        controllerService.hideSpinnerOverlay();

        convertAddress(idParam);

        const newFilterValues = getFilterValuesFromURLParams(params);
        messagesList.current && messagesList.current.updateFilterValues(newFilterValues);
        transactionsList.current && transactionsList.current.updateFilterValues(newFilterValues);
        transfersList.current && transfersList.current.updateFilterValues(newFilterValues);

        const contractsByCodeHash = await FBQueryWrapper<
            (codeHash?: string) => Promise<ContractsByCodeHash | null>,
            {}
        >(
            FBContracts.getContractsByCodeHash,
            {}
        )(newAccount?.code_hash);
        const newSurfHash = FBContracts.getSurfHashCodeFromContracts(contractsByCodeHash || {});
        const newContract = contractsByCodeHash?.[newAccount?.code_hash || ''];

        setSurfHash(newSurfHash);
        setContract(newContract);

        if (newAccount) EVERTip3.fetchAccountWallets(newAccount.id, newAccount.code_hash);

        if (newAccount) {
            TONAccount.getCustodiansForAccount({ account: newAccount, contractsByCodeHash }).then((newCustodians) => {
                setCustodians(newCustodians);
            });
            TONAccount.getUnconfirmedTransactionsForAccount({ account: newAccount, contractsByCodeHash }).then(
                (unconfirmedTransactions) => {
                    setUnconfirmedTsx(unconfirmedTransactions);
                }
            );
        }
    };

    const renderUnconfirmedTransactionSection = (tr: UnconfirmedTransactionForAccount) => {
        const detailsList = [
            DetailsRow.copyableRow(liveLocalized.ID, tr.id),
            DetailsRow.crystals(liveLocalized.Value, tr.value),
            DetailsRow.copyableRow(liveLocalized.Dst, tr.dest),
        ];
        return (
            <div key={tr.id} className={margin.bottomMedium}>
                <UIDetailsTable narrow={controllerService.isNarrow} detailsList={detailsList} />
                <Accordion title={liveLocalized.Signatures} titleHide={liveLocalized.Hide}>
                    <AddressSection
                        copyableViews={custodians.map((custodian) => ({
                            caption: TONClient.isTxSignedByNthCustodian(
                                tr.confirmationsMask,
                                TONClient.number(custodian.index)
                            )
                                ? 'yes'
                                : 'no',
                            value: Utils.remove0x(custodian.pubkey),
                        }))}
                        label={`${tr.signsReceived}/${tr.signsRequired}`}
                    />
                </Accordion>
            </div>
        );
    };

    const renderAccountAddressSection = (addresses: Maybe<Addresses>, label: string) => {
        if (!addresses) return null;

        return (
            <AddressSection
                copyableViews={[
                    {
                        caption: liveLocalized.base64,
                        value: addresses.address,
                    },
                    {
                        caption: liveLocalized.base64Url,
                        value: addresses.urlAddress,
                    },
                ]}
                label={label}
            />
        );
    };

    // Pages
    const subProps = {
        accountId: id,
        onUpdateItems: () => loadAccount(idFromUrlParams),
        realtimeUpdated: true,
    };

    const pages = [
        {
            title: liveLocalized.Messages,
            component: (
                <MessagesList
                    ref={messagesList}
                    onChangeFilter={onChangeMessagesFilter}
                    onChangeSortDirection={(direction) => setParams({ sortDirection: direction })}
                    sortDirectionByDefault={sortDirectionByDefault}
                    {...subProps}
                />
            ),
        },
        {
            title: liveLocalized.Transactions,
            component: (
                <TransactionsList
                    ref={transactionsList}
                    onChangeFilter={onChangeTransactionsFilter}
                    onChangeSortDirection={(direction) => setParams({ sortDirection: direction })}
                    sortDirectionByDefault={sortDirectionByDefault}
                    {...subProps}
                />
            ),
        },
        {
            title: liveLocalized.Transfers,
            component: (
                <TransfersList
                    ref={transfersList}
                    onChangeFilter={onChangeTransfersFilter}
                    onChangeSortDirection={(direction) => setParams({ sortDirection: direction })}
                    sortDirectionByDefault={sortDirectionByDefault}
                    {...subProps}
                />
            ),
        },
    ];

    useEffect(() => {
        return onWillFocus(params, ref, onLoadAccountData);
    }, [params]);

    useImperativeHandle(Ref, () => ({
        loadAccountData: onLoadAccountData,
        transactionsList: transactionsList.current,
        messagesList: messagesList.current,
    }));

    // Render
    const depoolLink = id || '';
    const surfLink = account ? `https://uri.ton.surf/chat/${id}` : '';

    const copyableViewData = {
        value: id,
        caption: liveLocalized.addressHex,
    };

    const { bounce, nonBounce } = base64 || {};
    const isSurfAccount = account?.code_hash === surfHash;

    const optionalDataCaptionComponent = (
        <div className={classnames(margin.bottomMedium, controllerService.reactContentClassNames)}>
            <Accordion
                title={`${liveLocalized.Addresses} ${liveLocalized.base64} / ${liveLocalized.base64Url}`}
                titleHide={liveLocalized.Hide}
            >
                {renderAccountAddressSection(bounce, liveLocalized.BounceableAddress)}
                {renderAccountAddressSection(nonBounce, liveLocalized.NonBounceableAddress)}
            </Accordion>
            {controllerService.isNarrow &&
                account &&
                isSurfAccount /* || FBContracts.isDePoolContract(this.state.contract) */ && (
                    // TODO: uncomment the code above once the proper URI scheme is supported
                    <SurfLink
                        text={isSurfAccount ? liveLocalized.ContactIn : liveLocalized.StakeIn}
                        href={isSurfAccount ? surfLink : depoolLink}
                    />
                )}
        </div>
    );

    const QRCodeComponent =
        controllerService.isNarrow || !account ? null : (
            <div
                className={classnames(
                    controllerService.reactContentClassNames,
                    container.centerLeft,
                    common.displayFlex
                )}
            >
                <CopyableView
                    {...copyableViewData}
                    classNames={classnames(margin.rightDefault, flex.flex1, common.wordBreakALl, font.smallRegular)}
                />
                <QRCode link={account?.code_hash === surfHash ? surfLink : depoolLink} />
            </div>
        );

    const belowTitleComponent = (
        <>
            {QRCodeComponent}
            {optionalDataCaptionComponent}
        </>
    );

    const custodiansListComponent =
        !account || !custodians.length ? null : (
            <div className={controllerService.reactContentClassNames}>
                <Accordion title={liveLocalized.Custodians} titleHide={liveLocalized.Hide}>
                    <UIDetailsSortingTable
                        items={custodians}
                        sortingKey="index"
                        additionalSortingKey="pubkey"
                        getDetailsRow={(custodian: AccountCustodian) =>
                            DetailsRow.copyableRow(
                                liveLocalized.formatString(liveLocalized.PublicKey, custodian.index),
                                Utils.remove0x(custodian.pubkey),
                                controllerService.isNarrow
                            )
                        }
                        narrow={controllerService.isNarrow}
                    />
                </Accordion>
            </div>
        );

    const unconfirmedTsxComponent =
        !account ||
        !contract ||
        !FBContracts.isWalletContract(contract) ||
        !unconfirmedTsx?.length ||
        !custodians?.length ? null : (
            <div className={controllerService.reactContentClassNames}>
                <Accordion title={liveLocalized.UnconfirmedTxs} titleHide={liveLocalized.Hide}>
                    {unconfirmedTsx.map((tr) => renderUnconfirmedTransactionSection(tr))}
                </Accordion>
            </div>
        );

    const detailsComponent = (
        <>
            {custodiansListComponent}
            {unconfirmedTsxComponent}
            <DetailsTabView pages={pages} />
        </>
    );

    return (
        <DetailsScreen
            ref={ref}
            title={account?.name || liveLocalized.AccountDetails}
            details={details}
            moreDetails={moreDetails}
            detailsComponent={detailsComponent}
            belowTitleComponent={belowTitleComponent}
            copyableViewData={controllerService.isNarrow ? copyableViewData : {}}
            needMoreDetailsButton
            allowFilterSubscription
        />
    );
});

const Account = observer(AccountComponent);

export { Account };
