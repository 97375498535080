import Firebase from './index';

export default class FBTemplate {
    static collectionName = '';

    static getCollection() {
        try {
            const { db } = Firebase;
            return db?.collection(this.collectionName);
        } catch (e) {
            return null;
        }
    }
}
