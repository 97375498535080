import React from 'react';

import { liveLocalized } from '@services/LocalizationService';
import { Tip3Token } from '#TONClient/EVERTip3/types';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import { BalanceView } from '#components';

export const getDetails = (token: Tip3Token | null) => [
    DetailsRow.tokenSymbol(liveLocalized.Symbol, token?.symbol || '-'),
    DetailsRow.accountLinkWithCopy(liveLocalized.RootAddress, token?.rootAddress || '-'),
    {
        caption: liveLocalized.TotalSupply,
        component: (
            <BalanceView
                hideIcon
                hex={false}
                balance={token?.totalSupply}
                notConvert
                fixFractional
                fractionalDigits={token?.decimals || 6}
            />
        ),
    },
    {
        caption: liveLocalized.Decimals,
        value: token?.decimals,
    },
    DetailsRow.accountLinkWithCopy(liveLocalized.RootOwner, token?.rootOwnerAddress || '-'),
];
