import React, { useState, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import {
    BackgroundView,
    LinkWithChild,
    UIBottomBar,
    UIButton,
    UILabel,
    UISeparator,
    UITextButton,
    Bullet,
} from '#components';
import { paths } from '#navigation/paths';
import Config from '#configs/index';
import UIScreen from '#controllers/Screen/UIScreen/UIScreen';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { common, flex, height, margin, width } from '#uikit/designCore/styles';

import { Descr, SiteLink, Subtitle } from './components';

import './LegalNotes.scss';

const { siteURL } = Config.getLegalNotesConfig();

enum tabNames {
    privacyPolicy = 'privacyPolicy',
    termOfUse = 'termOfUse',
}

type ContentType = {
    [key in tabNames]: {
        title: string;
        term?: string;
        dataLastReceived: string;
        textComponents: ReactNode;
    };
};

const contentData: ContentType = {
    privacyPolicy: {
        title: 'PrivacyPolicy',
        term: 'Policy',
        dataLastReceived: 'September 07, 2022',
        textComponents: (
            <>
                <Descr>
                    <SiteLink>Ever.live</SiteLink> as a Everscale-based blockchain explorer does not require to collect
                    your personal data (e.g., your name, ID or other identifiers that can be directly linked to you).
                    However, anonymous blockchain wallet addresses, transaction hashes, token names, NFTs, and other
                    public blockchain identifiers and information are collected and processed for the purpose of
                    providing users services and public information from the blockchain.
                </Descr>
                <Descr>
                    In order to improve services <SiteLink /> can also collect information about a user's devices,
                    including the hardware model, operating system and version, unique device identifiers, type of
                    browser and version, last access time, the Internet Protocol (IP) address, etc.
                </Descr>
                <Descr>
                    For the purpose to communicate with you regarding your inquiries, questions or support tickets,{' '}
                    <SiteLink /> support team may ask you to provide some data as wallet address, transaction ID, email,
                    your messenger ID, subject of inquiry and its content, attachments and any other information you
                    voluntarily provide to us. DO NOT SHARE WITH ANYONE PRIVATE KEY OR SEED PHRASE. FLEX NEVER ASK IT.
                </Descr>
                <Descr>
                    <SiteLink>Ever.live</SiteLink> can also use third-party service providers, like Google Analytics,
                    which may independently obtain and process your personal information. <SiteLink>Ever.live</SiteLink>{' '}
                    does not control how these third parties handle your data. Please review their privacy policies to
                    understand how they process and share your personal data.
                </Descr>
                <Descr>
                    Nevertheless, <SiteLink /> never collects and processes special categories of personal data about
                    users unless the user voluntarily provides such data.
                </Descr>
                <Descr>
                    As you navigate through and interact with <SiteLink /> website, we may use cookies, which are small
                    files placed on the hard drive of your computer or mobile device, and web beacons, which are small
                    electronic files located on pages of the website, to collect certain information about your
                    equipment, browsing actions, and patterns.
                </Descr>
                <Descr>
                    The data automatically collected from cookies may include information from your web browser and
                    details of your visits <SiteLink />, including traffic data, location data and logs, page views,
                    length of visit and website navigation paths as well as information about your device and internet
                    connection, including your IP address, etc. Ever.live collects this data in order to help improve{' '}
                    <SiteLink /> website and user experience.
                </Descr>
                <Descr>
                    You have the right to choose whether or not to accept cookies. However, please note that if you
                    choose to refuse cookies you may not be able to use the full functionality of the <SiteLink />. Most
                    browsers allow you to change your cookie settings. Please use "Help" page in your browser for more
                    details.
                </Descr>
                <Descr>
                    The information <SiteLink /> does collect automatically may also include statistical and performance
                    information arising from the user's website activity. This type of data will only be used in an
                    aggregated and anonymized manner.
                </Descr>
                <Descr>
                    BY ACCESSING AND USING <SiteLink>EVER.LIVE</SiteLink>, USER UNDERSTANDS AND CONSENTS TO THE DATA
                    PRACTICES AND SERVICE PROVIDERS' TREATMENT OF THE USER’S INFORMATION.
                </Descr>
            </>
        ),
    },
    termOfUse: {
        title: 'TermsOfUse',
        dataLastReceived: 'September 07, 2022',
        textComponents: (
            <>
                <Descr>
                    These Terms of Use set forth the terms and conditions under which you are allowed to access and to
                    use all of the website services at <SiteLink /> (“Website”).
                </Descr>
                <Descr>
                    SHOULD YOU ELECT TO USE THE WEBSITE, THE TERMS, TOGETHER WITH THE PRIVACY POLICY, FORM A LEGAL
                    CONTRACT BETWEEN YOU AND EVER.LIVE (THE "AGREEMENT"). BY USING THE WEBSITE, YOU IRREVOCABLY AGREE TO
                    ALL OF THE TERMS AND CONDITIONS OF THE AGREEMENT.
                </Descr>
                <Descr>
                    IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS IN THE AGREEMENT, YOU MAY NOT USE THE
                    WEBSITE.
                </Descr>
                <Descr>
                    <SiteLink>Ever.live</SiteLink> reserves the right to change, update, add or remove provisions of
                    these Terms, at any time by posting the updated Terms on the Website, along with a notice of the
                    effective date of the updated Terms. By using the Website after the Terms were updated, you are
                    agreeing to all of the updated Terms. If you do not agree with any of the updated Terms, your only
                    recourse will be to stop using the Website.
                </Descr>

                <Subtitle>Eligibility</Subtitle>
                <Descr>
                    To be eligible to use any of the services and products on the Website, you must be at least 18 years
                    old, have the capacity to enter into a legally binding agreement and reside in a country in which
                    the relevant services and products are available or you must be at least 13 years old and you
                    perform the access by means granted by your legal representative for use on the Website.
                </Descr>

                <Subtitle>Content</Subtitle>
                <Descr>
                    Except as may be otherwise noted, ever.live content is the copyrighted work of EverX Labs Ltd
                    (“EverX”), and EverX expressly retain all right, title and interest in and to the content, including
                    and without limitation, all intellectual property rights therein and thereto. Except as expressly
                    permitted in these Terms, any other use of the content may violate copyright and/or other applicable
                    laws.
                </Descr>
                <Descr>
                    In addition to the EverX content, the Website may contain information and materials provided by
                    third parties or links to third party sites. Third party content is the copyrighted work of its
                    owner, which expressly retains all right title and interest in and to this content, including,
                    without limitation, all intellectual property rights therein and thereto. In addition to being
                    subject to these Terms, the third party content may also be subject to different and/or additional
                    terms of use and/or privacy policies of such third parties. Please contact the appropriate third
                    party for further information regarding any such different and/or additional terms of use applicable
                    to the third party content.
                </Descr>
                <Descr>
                    The Website provides links to the third party sites to you as a convenience, and does not verify,
                    make any representations or take responsibility for such third party sites, including, without
                    limitation, the truthfulness, accuracy, quality or completeness of the content, services, links
                    displayed and/or any other activities conducted on or through such third party sites.
                </Descr>

                <Subtitle>Limited content license </Subtitle>
                <Descr>
                    EverX grants you the limited, revocable, non-transferable, non-exclusive right to use the Website
                    and associated content and you to display on your computer, download blockchain data and print pages
                    from this Website provided: (a) the copyright notice appears on all such printouts, (b) the
                    information will not be altered, (c) the content is only used for personal, educational and
                    non-commercial use, and (d) you do not redistribute or copy the information to any other media.
                </Descr>
                <Descr>
                    All rights not expressly granted herein are reserved. Except as otherwise required or limited by
                    applicable law, any reproduction, distribution, modification, retransmission, or publication of any
                    copyrighted material is strictly prohibited without the express, written, consent of the copyright
                    owner or an applicable license.
                </Descr>

                <Subtitle>Intellectual property rights</Subtitle>
                <Descr>
                    EverX, ever.live, Everscale and other trademarks are the property of EverX or third parties, and you
                    may not use such intellectual rights without the express, prior written consent of EverX or the
                    applicable third party.
                </Descr>
                <Descr>
                    EverX respects the intellectual property rights of others, and we ask you to do the same. If you
                    believe that your work has been copied in a way that constitutes copyright infringement, please
                    contact us provided the following information:
                </Descr>
                <Bullet bullet="•">
                    A physical or electronic signature of a person authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed.
                </Bullet>
                <Bullet bullet="•">
                    Identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if
                    multiple works are covered by a single notification, a representative list of such works.
                </Bullet>
                <Bullet bullet="•">
                    Identification of the material that is claimed to be infringing or to be the subject of infringing
                    activity and that is to be removed or access to which is to be disabled at the Website, and
                    information reasonably sufficient to permit EverX to locate the material.
                </Bullet>
                <Bullet bullet="•">
                    Information reasonably sufficient to permit EverX to contact you as the complaining party, such as
                    an address, telephone number, and, if available, an electronic mail address at which you may be
                    contacted.
                </Bullet>
                <Bullet bullet="•">
                    A statement that you have a good faith belief that the use of the material in the manner complained
                    of is not authorized by the copyright and/or trademark owner, its agent, or the law.
                </Bullet>
                <Bullet bullet="•">
                    A statement that the information in the notification is accurate, and under penalty of perjury, that
                    you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                </Bullet>

                <Subtitle>General Use of the Website</Subtitle>
                <Descr>You may not use the Website to post, transmit or link to:</Descr>
                <Bullet bullet="i">
                    software or programs which contain any harmful code, including, but not limited to, viruses, worms
                    or logic bombs;
                </Bullet>
                <Bullet bullet="ii">
                    content that defames, abuses, harasses, stalks, threatens, or otherwise violates the legal rights
                    (such as rights of privacy and publicity) of others;
                </Bullet>
                <Bullet bullet="iii">
                    content that includes racially, ethnically, or otherwise offensive language; (v) content that
                    discusses or incites illegal activity; or
                </Bullet>
                <Bullet bullet="iv">
                    content that includes explicit/obscene language or solicits/post sexually explicit images (actual or
                    simulated).
                </Bullet>
                <Descr>In addition, you may not:</Descr>
                <Bullet bullet="a">
                    deploy to the Website any robot, spider, scraper, website search/retrieval application, or other
                    application designed to retrieve, index, "data mine," information from the Website;
                </Bullet>
                <Bullet bullet="b">
                    use the Website to disseminate any unsolicited or unauthorized advertising, promotional materials,
                    'junk mail', 'spam', 'chain letters', 'pyramid schemes', or any other form of such solicitation;
                </Bullet>
                <Bullet bullet="c">
                    develop any applications that interact with the Website without EverX prior written consent.
                </Bullet>
                <Descr>This list of prohibitions provides examples and is not complete or exclusive.</Descr>

                <Subtitle>Warranty Disclaimer</Subtitle>
                <Descr>
                    EVER.LIVE AND ALL CONTENT, MATERIALS, INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ARE PROVIDED ON
                    AN "AS IS" AND "AS AVAILABLE" BASIS. YOUR USE OF THE WEBSITE IS AT YOUR OWN RISK.
                </Descr>
                <Descr>
                    THERE ARE NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, AS TO THE FUNCTIONALITY OR
                    USEFULNESS OF THIS WEBSITE OR ANY CONTENT. EVERX DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED OR
                    STATUTORY, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                    PURPOSE. EVERX MAKES NO WARRANTY THAT, (I) THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                    ERROR-FREE, (II) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE WILL BE ACCURATE OR
                    RELIABLE, (III) THE QUALITY OF ANY PRODUCTS, SERVICES, CONTENT, INFORMATION, OR OTHER MATERIALS
                    PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE WILL MEET YOUR EXPECTATIONS, (IV) ANY ERRORS IN THE
                    WEBSITE AND DATA WILL BE CORRECTED, OR THAT THIS WEBSITE, ITS CONTENT ARE FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS. ANY MATERIAL (INCLUDING BLOCKCHAIN DATA OR CONTENT) DOWNLOADED OR OBTAINED
                    THROUGH THE USE OF THE WEBSITE IS DONE AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                    DAMAGE TO YOU, YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY MATERIAL.
                    INFORMATION CREATED BY THIRD PARTIES THAT YOU MAY ACCESS ON THIS WEBSITE OR THROUGH LINKS IS NOT
                    ADOPTED OR ENDORSED BY EVERX AND REMAINS THE RESPONSIBILITY OF THE THIRD PARTY.
                </Descr>
                <Descr>
                    EVERX MAKES NO REPRESENTATION OR WARRANTY THAT ALL OF THE FEATURES OF THIS WEBSITE WILL BE AVAILABLE
                    TO YOU FROM SPECIFIC COUNTRIES, OR THAT THE WEBSITE CONTENT IS PERMITTED TO BE ACCESSED FROM ANY
                    COUNTRY. YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DECISION BY YOU TO USE EVER.LIVE
                    FROM YOUR LOCATION, AND THAT SUCH USE MAY BE SUBJECT TO, AND THAT YOU ARE RESPONSIBLE FOR,
                    COMPLIANCE WITH APPLICABLE LOCAL LAWS.
                </Descr>

                <Subtitle>Limitation of Liability</Subtitle>
                <Descr>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT EVERX AND ITS SUBSIDIARIES,
                    AGENTS, LICENSORS, MANAGERS, AFFILIATED COMPANIES, EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS AND
                    DIRECTORS SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES ARISING OUT OF OR CONNECTED TO YOUR USE OF, OR
                    INABILITY TO USE, THE WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY AND ALL DIRECT, INDIRECT,
                    INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR STATUTORY DAMAGES, INCLUDING ANY LOSS OF
                    BUSINESS, LOSS OR PROFITS, LOSS OF REVENUE, LOSS OF DATA, LOSS OF GOODWILL, OR ANY COST OF COVER OR
                    COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, EVEN IF EVER.LIVE OR EVERX HAS BEEN ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGES, REGARDLESS OF THE LEGAL THEORY UNDER WHICH DAMAGES ARE SOUGHT,
                    WHETHER IN BREACH OF CONTRACT OR IN TORT, INCLUDING NEGLIGENCE.
                </Descr>

                <Subtitle>Feedback</Subtitle>
                <Descr>
                    If you send or transmit any Feedback, suggesting or recommending changes to the ever.live, Website
                    content or any services offered through the Website, including, without limitation, new features or
                    functionality relating thereto, all such feedback is, and will be treated as, non-confidential and
                    non-proprietary. You hereby grant to EverX a royalty-free, worldwide, transferable, irrevocable,
                    perpetual license, including the right to grant and authorize sublicenses, to use or incorporate
                    into the Website and/or any products or services any and all feedback.
                </Descr>
                <Descr>
                    For the avoidance of doubt, EverX shall be free to use, without any attribution or compensation to
                    you, any ideas, know-how, concepts, techniques, or other intellectual property and proprietary
                    rights contained in the feedback, whether or not patentable, for any purpose whatsoever, including
                    but not limited to, developing, manufacturing, having manufactured, importing, having imported,
                    licensing, marketing, distributing, reproducing, and selling, directly or indirectly, products and
                    services using such feedback, and otherwise exploiting in any manner such feedback. You understand
                    and agree that EverX is not obligated to use, display, reproduce, or distribute any such ideas,
                    know-how, concepts, or techniques contained in the feedback, and you have no right to compel such
                    use, display, reproduction, or distribution.
                </Descr>

                <Subtitle>Governing Law and Legal dispute</Subtitle>
                <Descr>
                    This Agreement will be governed by the laws of England and Wales, without regard to its conflict of
                    laws principles. All suits hereunder will be brought solely in the London Court of International
                    Arbitration (LCIA). The parties hereby irrevocably waive any and all claims and defenses either
                    might otherwise have in any such action or proceeding in any of such courts based upon any alleged
                    lack of personal jurisdiction, improper venue, forum nonconvenience or any similar claim or defense.
                </Descr>
            </>
        ),
    },
};

const LegalNotesComponent = () => {
    const [activeTabName, setActiveTabName] = useState<tabNames>(tabNames.privacyPolicy);

    const dateLastRevised = 'September 07, 2022';

    const content = (
        <div className={classnames(width.full, common.displayFlex, flex.column, 'LegalNotes')}>
            <UILabel
                color={ColorVariants.TextPrimary}
                role={TypographyVariants.TitleHuge}
                classNames={classnames(
                    controllerService.reactContentClassNames,
                    margin.topMassive,
                    margin.bottomDefault
                )}
            >
                {liveLocalized.LegalNotes}
            </UILabel>
            <BackgroundView classNames={classnames(common.displayFlex, flex.column, 'LegalNotes__navbar')}>
                <nav className={classnames(controllerService.reactContentClassNames, common.displayFlex)}>
                    <UIButton
                        classNames={classnames(margin.rightMedium, height.button)}
                        buttonColor={ColorVariants.BackgroundPrimary}
                        title={liveLocalized.PrivacyPolicy}
                        titleColor={
                            activeTabName === tabNames.privacyPolicy
                                ? ColorVariants.TextAccent
                                : ColorVariants.TextPrimary
                        }
                        onPress={() => {
                            if (activeTabName !== tabNames.privacyPolicy) setActiveTabName(tabNames.privacyPolicy);
                        }}
                    />
                    <UIButton
                        classNames={height.button}
                        buttonColor={ColorVariants.BackgroundPrimary}
                        title={liveLocalized.TermsOfUse}
                        titleColor={
                            activeTabName === tabNames.termOfUse ? ColorVariants.TextAccent : ColorVariants.TextPrimary
                        }
                        onPress={() => {
                            if (activeTabName !== tabNames.termOfUse) setActiveTabName(tabNames.termOfUse);
                        }}
                    />
                </nav>
                <UISeparator color={ColorVariants.BackgroundTertiary} />
            </BackgroundView>
            <div className={controllerService.reactContentClassNames}>
                <UILabel
                    classNames={margin.topSmall}
                    color={ColorVariants.TextPrimary}
                    role={TypographyVariants.TitleMedium}
                >
                    {liveLocalized[contentData[activeTabName].title]}
                </UILabel>
                {contentData[activeTabName].term && (
                    <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.ParagraphLabel}>
                        {/*@ts-ignore doesn`t see check above*/}"{liveLocalized[contentData[activeTabName].term]}"
                    </UILabel>
                )}
                <UILabel
                    color={ColorVariants.TextPrimary}
                    role={TypographyVariants.ParagraphNote}
                    classNames={margin.topSmall}
                >
                    {liveLocalized.LastRevised}: {contentData[activeTabName].dataLastReceived}
                </UILabel>
                <section className={classnames(common.displayFlex, flex.column)}>
                    {contentData[activeTabName].textComponents}
                </section>
                <LinkWithChild navigationPath={paths.main}>
                    <UITextButton
                        classNames={margin.topBig}
                        align={UITextButton.alignClassNames.center}
                        title={`${liveLocalized.BackTo} ${siteURL}`}
                    />
                </LinkWithChild>
            </div>
            <UIBottomBar isNarrow={controllerService.isNarrow} copyRight={liveLocalized.CopyRight} />
        </div>
    );

    return <UIScreen contentComponent={content} />;
};

const LegalNotes = observer(LegalNotesComponent);

export { LegalNotes };
