import React from 'react';
import classnames from 'classnames';

import type { CopyableViewProps } from '#components/CopyableView';
import { CopyableView, UILabel } from '#components';
import { detailsScreenTestIDs } from '#controllers/Details/DetailsScreen';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin } from '#uikit/designCore/styles';

type Props = {
    copyableViews: CopyableViewProps[];
    label: string;
};

const AddressSection: React.FC<Props> = ({ copyableViews, label }) => (
    <>
        <UILabel classNames={classnames(margin.topDefault, margin.bottomSmall)} role={TypographyVariants.MonoNote}>
            {label}
        </UILabel>
        <div data-testid={detailsScreenTestIDs.addressBlock}>
            {copyableViews.map((item) => (
                <CopyableView key={`${item.value}-${item.caption || ''}`} {...item} />
            ))}
        </div>
    </>
);

export default AddressSection;
