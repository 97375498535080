import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import MomentHelper from '#helpers/MomentHelper';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import Utils from '#helpers/Utils';
import type { TONBlockT } from '#TONClient/EVERBlock/types';
import type { TONCollectionConfigs } from '#components/EVERList';
import { LinkWithChild } from '#components';
import { TONClient } from '#TONClient';
import { paths } from '#navigation/paths';

type Props = {
    item: Maybe<TONBlockT>;
};

export const blockViewTestIDs = {
    blockView: 'blockView',
};

const BlockView = ({ item = null }: Props) => {
    if (!item) return null;

    const { seq_no, gen_utime, tr_count, gen_software_version } = item;
    const timeStr = `${MomentHelper.getTimeDate(gen_utime)}`;
    const trCountStr = tr_count ? liveLocalized.localizedStringForValue(tr_count, 'Transaction') : '';

    return (
        <LinkWithChild navigationPath={paths.blocks.details} params={{ id: item.id }}>
            <UIDetailsButton
                testID={blockViewTestIDs.blockView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                title={`${seq_no}`}
                caption={timeStr}
                details={`${Utils.getWorkchainShard(item)}${gen_software_version ? ` | v${gen_software_version}` : ''}`}
                secondDetails={trCountStr}
            />
        </LinkWithChild>
    );
};

BlockView.externalConfigs = {
    items: null,
    sortingKey: 'gen_utime',
};

BlockView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ id }: { id: string }): string => {
            return `block-view-${id}`;
        },
        idKey: 'id',
        newItemName: 'NewBlock',
        collectionName: liveLocalized.Blocks,
        //@ts-ignore doesn`t calculate right type
        collectionKey: TONClient.collections.blocks,
        renderItem: ({ item }: { item: any }) => <BlockView item={item} />,
    };
};

export default BlockView;
