export enum padding {
    none = 'paddingNone',
    default = 'paddingDefault',
    tiny = 'paddingTiny',
    small = 'paddingSmall',

    topDefault = 'paddingTopDefault',
    topTiny = 'paddingTopTiny',
    topSmall = 'paddingTopSmall',
    topMedium = 'paddingTopMedium',
    topHuge = 'paddingTopHuge',

    rightSmall = 'paddingRightSmall',
    rightDefault = 'paddingRightDefault',
    rightHuge = 'paddingRightHuge',
    rightGreat = 'paddingRightGreat',

    bottomTiny = 'paddingBottomTiny',
    bottomSmall = 'paddingBottomSmall',
    bottomMedium = 'paddingBottomMedium',
    bottomSpacious = 'paddingBottomSpacious',
    bottomMassive = 'paddingBottomMassive',
    bottomMajor = 'paddingBottomMajor',
    bottomVast = 'paddingBottomVast',
    bottomEnormous = 'paddingBottomEnormous',
    bottomGiant = 'paddingBottomGiant',
    bottomNormal = 'paddingBottomNormal',
    bottomDefault = 'paddingBottomDefault',
    bottomHuge = 'paddingBottomHuge',

    leftSmall = 'paddingLeftSmall',
    leftDefault = 'paddingLeftDefault',

    horizontalNone = 'paddingHorizontalNone',
    horizontalTiny = 'paddingHorizontalTiny',
    horizontalSmall = 'paddingHorizontalSmall',
    horizontal = 'paddingHorizontal',
    horizontalNormal = 'paddingHorizontalNormal',

    verticalNone = 'paddingVerticalNone',
    verticalTiny = 'paddingVerticalTiny',
    vertical = 'paddingVertical',
    verticalNormal = 'paddingVerticalNormal',
    verticalSmall = 'paddingVerticalSmall',
}
