import React from 'react';
import { observer } from 'mobx-react';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import classnames from 'classnames';

import { themesService } from '@services/ThemesService';
import controllerService from '@services/ControllerService';
import { ActionImage } from '#components';
import { margin } from '#uikit/designCore/styles';
import { UIColorPalette } from '#uikit/designCore';

const ThemeToggleComponent: React.FC = () => (
    <ActionImage
        Img={themesService.isDarkTheme ? DarkModeOutlinedIcon : LightModeOutlinedIcon}
        fill={themesService.isDarkTheme ? UIColorPalette.text.darkPrimary : UIColorPalette.text.lightPrimary}
        fillOnHover={themesService.isDarkTheme ? UIColorPalette.text.darkPrimary : UIColorPalette.text.lightTertiary}
        onClick={() => themesService.toggleTheme()}
        className={classnames({ [margin.leftSmall]: !controllerService.isNarrow })}
        data-testId="themeSwitcher"
    />
);

const ThemeToggle = observer(ThemeToggleComponent);

export { ThemeToggle };
