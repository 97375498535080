import React from 'react';

export enum TypographyVariants {
    TitleHuge = 'TitleHuge',
    TitleLarge = 'TitleLarge',
    TitleMedium = 'TitleMedium',
    TitleSmall = 'TitleSmall',
    PromoHuge = 'PromoHuge',
    PromoLarge = 'PromoLarge',
    PromoMedium = 'PromoMedium',
    PromoSmall = 'PromoSmall',
    HeadlineHead = 'HeadlineHead',
    HeadlineSubhead = 'HeadlineSubhead',
    HeadlineFootnote = 'HeadlineFootnote',
    HeadlineLabel = 'HeadlineLabel',
    Action = 'Action',
    ActionCallout = 'ActionCallout',
    ActionFootnote = 'ActionFootnote',
    ActionLabel = 'ActionLabel',
    ParagraphText = 'ParagraphText',
    ParagraphNote = 'ParagraphNote',
    ParagraphFootnote = 'ParagraphFootnote',
    ParagraphLabel = 'ParagraphLabel',
    MonoText = 'MonoText',
    MonoNote = 'MonoNote',
    LightHuge = 'LightHuge',
    LightLarge = 'LightLarge',
    LightMedium = 'LightMedium',
    LightSmall = 'LightSmall',
}

export type TypographyT = {
    [variant in TypographyVariants]: { [key: string]: string | number | undefined };
};

export type FontVariant = {
    fontFamily: string;
};

export type Font = {
    regular: FontVariant;
    medium: FontVariant;
    semiBold: FontVariant;
    light: FontVariant;
};

export const InterFont: Font = {
    semiBold: {
        fontFamily: 'Inter-SemiBold',
    },
    light: {
        fontFamily: 'Inter-Light',
    },
    medium: {
        fontFamily: 'Inter-Medium',
    },
    regular: {
        fontFamily: 'Inter-Regular',
    },
};

const InterFontNormalSemiBold: React.CSSProperties = {
    fontFamily: InterFont.semiBold.fontFamily,
    fontWeight: '600',
    // TODO: think if fontStyle is proper place to put it here
    // coz I could easily imagine some label with italic fontStyle
    // Ask Eugene if it appliable to typography
    fontStyle: 'normal',
};

const InterFontNormalMedium: React.CSSProperties = {
    fontFamily: InterFont.medium.fontFamily,
    fontWeight: '500',
    fontStyle: 'normal',
};

const InterFontNormalRegular: React.CSSProperties = {
    fontFamily: InterFont.regular.fontFamily,
    fontWeight: '400',
    fontStyle: 'normal',
};

const InterFontNormalLight: React.CSSProperties = {
    fontFamily: InterFont.light.fontFamily,
    fontWeight: '300',
    fontStyle: 'normal',
};

export const Typography: TypographyT = {
    [TypographyVariants.TitleHuge]: {
        ...InterFontNormalSemiBold,
        fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: -0.79,
    },
    [TypographyVariants.TitleLarge]: {
        ...InterFontNormalSemiBold,
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: -0.64,
    },
    [TypographyVariants.TitleMedium]: {
        ...InterFontNormalSemiBold,
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: -0.4,
    },
    [TypographyVariants.TitleSmall]: {
        ...InterFontNormalSemiBold,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: -0.26,
    },
    [TypographyVariants.PromoHuge]: {
        ...InterFontNormalRegular,
        fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: -0.79,
    },
    [TypographyVariants.PromoLarge]: {
        ...InterFontNormalRegular,
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: -0.64,
    },
    [TypographyVariants.PromoMedium]: {
        ...InterFontNormalRegular,
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: -0.4,
    },
    [TypographyVariants.PromoSmall]: {
        ...InterFontNormalRegular,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: -0.26,
    },
    [TypographyVariants.HeadlineHead]: {
        ...InterFontNormalSemiBold,
        fontSize: '17px',
        lineHeight: '24px',
        letterSpacing: -0.22,
    },
    [TypographyVariants.HeadlineSubhead]: {
        ...InterFontNormalSemiBold,
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: -0.13,
    },
    [TypographyVariants.HeadlineFootnote]: {
        ...InterFontNormalSemiBold,
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: -0.04,
    },
    [TypographyVariants.HeadlineLabel]: {
        ...InterFontNormalSemiBold,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: 0.05,
    },
    [TypographyVariants.Action]: {
        ...InterFontNormalMedium,
        fontSize: '17px',
        lineHeight: '24px',
        letterSpacing: -0.22,
    },
    [TypographyVariants.ActionCallout]: {
        ...InterFontNormalMedium,
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: -0.13,
    },
    [TypographyVariants.ActionFootnote]: {
        ...InterFontNormalMedium,
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: -0.04,
    },
    [TypographyVariants.ActionLabel]: {
        ...InterFontNormalMedium,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: 0.05,
    },

    [TypographyVariants.ParagraphText]: {
        ...InterFontNormalRegular,
        fontSize: '17px',
        lineHeight: '24px',
        letterSpacing: -0.22,
    },
    [TypographyVariants.ParagraphNote]: {
        ...InterFontNormalRegular,
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: -0.13,
    },
    [TypographyVariants.ParagraphFootnote]: {
        ...InterFontNormalRegular,
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: -0.04,
    },
    [TypographyVariants.ParagraphLabel]: {
        ...InterFontNormalRegular,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: 0.05,
    },

    [TypographyVariants.MonoText]: {
        ...InterFontNormalRegular,
        fontSize: '17px',
        lineHeight: '24px',
        letterSpacing: -0.22,
    },
    [TypographyVariants.MonoNote]: {
        ...InterFontNormalRegular,
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: -0.13,
    },

    [TypographyVariants.LightHuge]: {
        ...InterFontNormalLight,
        fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: -0.79,
    },
    [TypographyVariants.LightLarge]: {
        ...InterFontNormalLight,
        fontSize: '30px',
        lineHeight: '40px',
        letterSpacing: -0.64,
    },
    [TypographyVariants.LightMedium]: {
        ...InterFontNormalLight,
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: -0.4,
    },
    [TypographyVariants.LightSmall]: {
        ...InterFontNormalLight,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: -0.26,
    },
};
