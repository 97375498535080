import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import UITextButton from '#components/UITextButton';
import UIDetailsButton from '#components/UIDetailsButton';
import { TONClient } from '#TONClient';
import { margin } from '#uikit/designCore/styles/margin';
import { flex } from '#uikit/designCore/styles/flex';

type Props = {
    loadingMoreItems: boolean;
    showAll: boolean;
    hideButton: boolean;
    footerComponent?: ReactElement;
    testID: string;
    onPress: () => any;
};

const Footer: React.FC<Props> = ({ showAll, footerComponent, testID, onPress, loadingMoreItems, hideButton }) => {
    if (loadingMoreItems) {
        return <UIDetailsButton containerClassNames={margin.topDefault} transparent progress />;
    }

    if (hideButton) return null;

    const title = showAll
        ? liveLocalized.LoadAll
        : liveLocalized.formatString(liveLocalized.LoadMore, TONClient.itemsLoadingLimit);

    return (
        footerComponent || (
            <UITextButton
                classNames={classNames(margin.topDefault, flex.alignSelfCenter)}
                title={title}
                align={UITextButton.alignClassNames.center}
                onPress={onPress}
                testID={testID}
            />
        )
    );
};

export default Footer;
