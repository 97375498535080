// @flow
import { paths } from '#navigation/paths';

export type NavigationMenuItem = {
    title: string,
    screenName: string,
    navigationPath: string,
};

export type NavigationMenuItemsGroup = {
    title: string,
    items: NavigationMenuItem[],
};

export type NavigationDeepMenuList = NavigationMenuItemsGroup[];

const showZeroState = true; // !EnvManager.isMainnet();

const navigationMenuItems: NavigationDeepMenuList = [
    {
        title: 'Blockchain',
        items: [
            {
                title: 'Blocks',
                navigationPath: paths.blocks.main,
            },
            {
                title: 'Messages',
                navigationPath: paths.messages.main,
            },
            {
                title: 'Transactions',
                navigationPath: paths.transactions.main,
            },
        ],
    },
    {
        title: 'Contracts',
        items: [
            {
                title: 'Accounts',
                navigationPath: paths.accounts.main,
            },
            {
                title: 'SmartContracts',
                navigationPath: paths.contracts.main,
            },
            {
                title: 'Tip3Tokens',
                navigationPath: paths.TIP32.main,
            },
            {
                title: 'KWT',
                navigationPath: paths.KWT,
            },
            {
                title: 'NFT',
                navigationPath: paths.NFT.main,
            },
        ],
    },
    {
        title: 'Staking',
        items: [
            {
                title: 'Validators',
                navigationPath: paths.validators.main,
            },
            {
                title: 'DePools',
                navigationPath: paths.dePools.main,
            },
        ],
    },
];

if (showZeroState) {
    navigationMenuItems[0].items.push({
        title: 'ZeroState',
        navigationPath: paths.zeroState,
    });
}

export { navigationMenuItems };
