// @flow
import { saveAs } from 'file-saver';
import { TONString } from '#TONUtility';

import { liveLocalized } from '@services/LocalizationService';

export default class Utils {
    static getPercentString(part = 0, whole) {
        if (!whole || !Number(whole)) {
            return '';
        }

        const percent = Math.floor((part / whole) * 10000) / 100;
        const formattedPercent = TONString.getNumberString(percent);

        return `(${formattedPercent}%) `;
    }

    static truncAddress(address: string) {
        const parts = address.split(':');
        return `${parts[0]}:${parts[1].substr(0, 4)}`;
    }

    static base64ToBytesArray(base64: string) {
        return Buffer.from(base64, 'base64');
    }

    static getKBSize(base64: string) {
        return this.base64ToBytesArray(base64).length / 1024;
    }

    static saveBinary(base64: ?string, name: string) {
        if (!base64) return;

        saveAs(new Blob([this.base64ToBytesArray(base64)]), `${name}.boc`);
    }

    static getWorkchainShard(block) {
        const { shard, workchain_id } = block;
        if (Utils.isNil(shard) || Utils.isNil(workchain_id)) {
            return '';
        }
        const formattedShard = shard.replace(/00/g, '');
        return `${workchain_id}:${formattedShard}...`;
    }

    static getShard(block) {
        if (!block?.shard) return '';
        return `${(block?.shard || '').replace(/00/g, '')}...`;
    }

    static divideBySubLists(list: any[], size: number = 50): any[][] {
        const result = [];
        for (let index = 0; index < list.length; index += size) {
            result.push(list.slice(index, index + size));
        }
        return result;
    }

    static amountToLocOrEmpty(num: number) {
        return num ? liveLocalized.amountToLocale(num) : '-';
    }

    static objectValues(object: { [string]: any }): any[] {
        return Object.keys(object || {}).map((field) => object[field]);
    }

    static parseArray<T>(array: T[][] | { [string]: T }[]): T[] {
        return array.reduce(
            (prev, curr) => [...prev, ...(Array.isArray(curr) ? curr : this.objectValues(curr) || [])],
            []
        );
    }

    static groupById(list: any[], field: string) {
        return list.reduce(
            (prev, curr) => ({
                ...prev,
                [curr[field]]: curr,
            }),
            {}
        );
    }

    static callIfNotDuplicate(
        id: string,
        itemsById: { [string]: boolean },
        callback: (newItemsById: { [string]: boolean }) => void = () => {}
    ) {
        if (id == null) {
            console.warn('callIfNotDuplicate go undefined id, all elements without id be ignored');
        }
        if (!itemsById[id]) {
            itemsById[id] = true;
            callback(itemsById);
        }
    }

    static removeDuplicates(list: (any | string)[], path?: string): (any | string)[] {
        const itemsById = {};
        list.forEach((item: any) => {
            const id = path ? item[path] : item;
            if (!itemsById[id]) {
                itemsById[id] = item;
            }
        });

        return this.objectValues(itemsById);
    }

    static flatify(obj: any) {
        if (!obj) return {};

        const result = {};
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
                const flattifyResult = this.flatify(obj[key]);
                Object.keys(flattifyResult).forEach((innerKey) => {
                    result[`${key} / ${innerKey}`] = flattifyResult[innerKey];
                });
            } else {
                result[key] = obj[key];
            }
        });

        return result;
    }

    static roundToMeaningDigit(num: number): number {
        const fraction = num - Math.trunc(num);
        for (let i = 0; i < 10; i += 1) {
            if (fraction * 10 ** i >= 1) {
                return Math.trunc(num) + Number(fraction.toFixed(i));
            }
        }
        return num;
    }

    static truncText(str: string, narrow: boolean, signsCount?: number) {
        if (!str) {
            return '';
        }

        const signs = signsCount || (narrow ? 5 : 9);
        if (str.length <= signs * 2) {
            return str;
        }
        return `${str.substr(0, signs)} ... ${str.substr(str.length - signs)}`;
    }

    static truncTextToNarrow(str: string, narrow: boolean, signsCount?: number) {
        return this.truncText(str, true, signsCount);
    }

    static isNil(arg: any): boolean {
        return arg == null;
    }

    static toFixedOrEmpty(arg: ?number, fractionDigits: number): string {
        return arg == null ? '' : arg === 0 ? '0' : arg.toFixed(fractionDigits);
    }

    static remove0x(str: string = ''): string {
        return str.slice(0, 2) === '0x' ? str.slice(2) : str;
    }

    static summarizeByKey(arr: any[], key: string): number {
        return arr.reduce((prev, curr) => prev + curr[key], 0);
    }

    static formatHex(str: string = ''): string {
        return this.remove0x(str).padStart(64, '0');
    }

    static JSONStringifyWithoutParanthesesAroundKeys(obj: Object): string {
        const stringifyValue = (value: any) =>
            typeof value === 'object' ? this.JSONStringifyWithoutParanthesesAroundKeys(value) : value;

        if (Array.isArray(obj)) {
            return `[${obj.reduce((acc, current) => `${acc}${stringifyValue(current)},`, '')}]`;
        }

        return `{${Object.entries(obj).reduce((acc, [key, value]) => `${acc}${key}: ${stringifyValue(value)},`, '')}}`;
    }
}
