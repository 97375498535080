const blocksMainPath = '/blocks';
const messagesMainPath = '/messages';
const transactionsMainPath = '/transactions';
const accountsMainPath = '/accounts';
const contractsMainPath = '/contracts';
const TIP32MainPath = '/tip3';
const NFTMainPath = '/nft';
const validatorsMainPath = '/validators';
const dePoolsMainPath = '/depools';
const transfersMainPath = '/transfers';

const paths = {
    main: '/landing',
    blocks: {
        main: blocksMainPath,
        details: `${blocksMainPath}/blockDetails`,
    },
    messages: {
        main: messagesMainPath,
        details: `${messagesMainPath}/messageDetails`,
    },
    transactions: {
        main: transactionsMainPath,
        details: `${transactionsMainPath}/transactionDetails`,
    },
    zeroState: '/zeroState',
    accounts: {
        main: accountsMainPath,
        details: `${accountsMainPath}/accountDetails`,
    },
    contracts: {
        main: contractsMainPath,
        details: `${contractsMainPath}/contractDetails`,
    },
    TIP32: {
        main: TIP32MainPath,
        details: `${TIP32MainPath}/tip3Details`,
    },
    KWT: '/KWT',
    NFT: {
        main: NFTMainPath,
        collection: `${NFTMainPath}/nftCollectionDetails`,
        token: `${NFTMainPath}/tokenDetails`,
    },
    validators: {
        main: validatorsMainPath,
        details: `${validatorsMainPath}/validatorDetails`,
    },
    dePools: {
        main: dePoolsMainPath,
        details: `${dePoolsMainPath}/depoolDetails`,
    },
    transfers: {
        main: transfersMainPath,
        details: `${transfersMainPath}/transferDetails`,
    },
    legalNotes: '/legalNotes',
};

export { paths };
