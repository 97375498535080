import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import UIDetailsTable from '#components/UIDetailsTable';
import UITextButton from '#components/UITextButton';
import { Accordion, EVERList, MessageDescrView, TabView, LinkWithChild, LinkWithCopy } from '#components';
import { TONLog } from '#TONUtility';
import DetailsRowHelper from '#controllers/Details/DetailsRowHelper';
import { EVERBlock } from '#TONClient';
import type { TONBlockT, TONValidatorSet } from '#TONClient/EVERBlock/types';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import { onWillFocus } from '#navigation/helpers';
import { paths } from '#navigation/paths';
import { useParams } from '#navigation/hooks/useParams';
import TransactionsList from '#controllers/TabViewLists/TransactionsList';
import ValidatorsList from '#controllers/TabViewLists/ValidatorsList';
import DetailsTabView from '#controllers/Details/components/DetailsTabView';
//@ts-ignore doesn`t see import
import IconArrowRight from '#assets/ico-arrow-right.png';
//@ts-ignore doesn`t see import
import IconArrowLeft from '#assets/ico-arrow-left.png';
import { margin } from '#uikit/designCore/styles/margin';
import { width } from '#uikit/designCore/styles/width';
import { container } from '#uikit/designCore/styles/container';
import { common, flex } from '#uikit/designCore/styles';

import Details from './helpers/Details';
import MasterDetails from './helpers/MasterDetails';
import ShardDetails from './helpers/ShardDetails';
import DetailsScreen, { detailsScreenTestIDs } from '../DetailsScreen';

const log = new TONLog('BlockDetailsScreen');

const BlockComponent = () => {
    const ref = useRef(null);
    const txsListRef = useRef(null);

    const [block, setBlock] = useState<Maybe<TONBlockT>>(null);
    const [parentBlock, setParentBlock] = useState<Maybe<TONBlockT>>(null);
    const [childBlock, setChildBlock] = useState<Maybe<TONBlockT>>(null);
    const [prevKeyBlock, setPrevKeyBlock] = useState<Maybe<TONBlockT>>(null);

    const [params] = useParams();
    const { id } = params;

    const narrow = controllerService.isNarrow;

    // details
    const { gen_utime, seq_no, workchain_id, shard, prev_key_block_seqno, gen_software_version } = block || {};

    const details = [
        ...[gen_utime ? DetailsRowHelper.getTimeAndDate(gen_utime) : {}],
        {
            caption: liveLocalized.Number,
            value: seq_no,
        },
        {
            caption: liveLocalized.Workchain,
            value: workchain_id,
        },
        {
            caption: liveLocalized.Shard,
            value: shard,
        },
        {
            caption: liveLocalized.TONBlock.gen_software_version,
            value: gen_software_version,
        },
        {
            caption: liveLocalized.TONBlock.prev_key_block_seqno,
            component: (
                <LinkWithCopy
                    title={prev_key_block_seqno}
                    navigationPath={paths.blocks.details}
                    params={{ id: prevKeyBlock?.id || '' }}
                />
            ),
        },
    ];

    // more details
    const { start_lt, end_lt, value_flow, global_id, gen_catchain_seqno, flags, signatures, boc, master } = block || {};

    const moreDetails = [
        {
            caption: liveLocalized.LogicalTime,
            value: `${start_lt || ''} - ${end_lt || ''}`,
        },
        DetailsRowHelper.crystals(liveLocalized.FeesCollected, value_flow?.fees_collected),
        {
            caption: liveLocalized.TONBlock.global_id,
            value: global_id,
        },
        {
            caption: liveLocalized.TONBlock.gen_catchain_seqno,
            value: gen_catchain_seqno,
        },
        {
            caption: liveLocalized.TONBlock.flags,
            value: flags,
        },
        ...Details.refs(block || null),
        ...Details.someFields(block || null),
        ...DetailsRowHelper.proofBocRows(signatures?.proof || '', boc, 'block', block?.id),
        ...Details.inMsgDescr({
            inMsgDescr: master?.recover_create_msg || null,
            title: liveLocalized.RecoverCreateMsg,
            key: '',
            needOffset: true,
        }),
    ];

    // validator pages
    const { p32, p34, p36 } = block?.master?.config || {};

    const getValidatorPage = (title: string, param?: TONValidatorSet) => ({
        title: `${title} ${param?.list?.length ? ` ${param?.list?.length}` : ''}`,
        component: <ValidatorsList validatorSet={param} keyBlockNum={block?.seq_no} convertWeight />,
    });

    const validatorPages = [
        getValidatorPage(liveLocalized.Prev, p32),
        getValidatorPage(liveLocalized.Current, p34),
        getValidatorPage(liveLocalized.NextShort, p36),
    ];

    // pages
    const detailsListGetterParams = {
        title: liveLocalized.MessageDetails,
        key: '',
        listMode: true,
    };

    const { in_msg_descr, out_msg_descr } = block || {};

    const getProps = useCallback(
        (isInMsg: boolean) => ({
            classNames: margin.topDefault,
            contentClassNames: container.fullWidthPadding,
            realtimeUpdated: false,
            collectionConfigs: {
                ...MessageDescrView.configs,
                renderItem: ({ item }: { item: TONMessageT }) => (
                    <MessageDescrView
                        item={item}
                        detailsListGetter={
                            isInMsg
                                ? (inMsgDescr) =>
                                      Details.inMsgDescr({
                                          ...detailsListGetterParams,
                                          inMsgDescr,
                                      })
                                : (outMsgDescr) =>
                                      Details.outMsgDescr({
                                          ...detailsListGetterParams,
                                          outMsgDescr,
                                      })
                        }
                    />
                ),
            },
            externalControlConfigs: {
                items: isInMsg ? in_msg_descr : out_msg_descr,
            },
        }),
        [block]
    );

    const subProps = {
        containerClassNames: classnames(margin.topDefault, width.fullPaddingContainer),
    };

    const inMsgCount = in_msg_descr?.length || 0;
    const outMsgCount = out_msg_descr?.length || 0;
    const totalMessageCount = inMsgCount + outMsgCount;

    const [msgListPropsIn, msgListPropsOut] = useMemo(() => [getProps(true), getProps(false)], [getProps]);

    const pages = [
        {
            title: `${narrow ? liveLocalized.Txs : liveLocalized.Transactions} ${block?.tr_count || ''}`,
            component: <TransactionsList ref={txsListRef} blockId={id} />,
        },
        {
            title: `${narrow ? liveLocalized.Msgs : liveLocalized.MsgDescrs} ${totalMessageCount || ''}`,
            component: (
                <TabView
                    {...subProps}
                    pages={[
                        {
                            title: `${narrow ? liveLocalized.InMsgs : liveLocalized.InMsgDescrs} ${inMsgCount || ''}`,
                            component: <EVERList {...msgListPropsIn} />,
                        },
                        {
                            title: `${narrow ? liveLocalized.OutMsgs : liveLocalized.OutMsgDescrs} ${
                                outMsgCount || ''
                            }`,
                            component: <EVERList {...msgListPropsOut} />,
                        },
                    ]}
                />
            ),
        },
    ];

    if (master?.config) {
        pages.push({
            title: `${narrow ? liveLocalized.Vals : liveLocalized.Validators} ${
                master?.config?.p34?.list?.length || ''
            }`,
            component: <TabView {...subProps} pages={validatorPages} initialIndex={1} />,
        });
    }

    // Actions
    const onLoadBlock = async ({ id: idParam }) => {
        if (!idParam) {
            log.debug('No block id passed');
            return;
        }

        try {
            const newBlock = await EVERBlock.getBlock(idParam, { needAdditionalFields: true, needBinaryFields: true });
            if (!newBlock) {
                controllerService.showPageNotFound(liveLocalized.BlockWithThisIdWasntFound);
                return;
            }
            setBlock(newBlock);

            const [newParentBlock, newChildBlock, newPrevKeyBlock] = await Promise.all([
                EVERBlock.getBlock(newBlock.parent_id || '', undefined, 'parent'),
                EVERBlock.getChildForBlock(newBlock),
                EVERBlock.getKeyBlockForBlock(newBlock),
            ]);
            setParentBlock(newParentBlock);
            setChildBlock(newChildBlock);
            setPrevKeyBlock(newPrevKeyBlock);

            if (txsListRef.current) {
                txsListRef.current.loadItems();
            }
        } catch (e) {
            log.error('Error while loading block', e);
            controllerService.showServiceUnavailable();
        }
    };

    // next link
    const button = (
        <UITextButton
            disabled={!childBlock?.id}
            title={liveLocalized.Next}
            backIconPath={IconArrowRight}
            testID={detailsScreenTestIDs.nextBlockButton}
        />
    );

    const nextLink = childBlock?.id ? (
        <LinkWithChild navigationPath={paths.blocks.details} params={{ id: childBlock?.id }}>
            {button}
        </LinkWithChild>
    ) : (
        button
    );

    // prev next nav bar
    const prevNextNavBar = (
        <div
            className={classnames(
                common.displayFlex,
                flex.justifySpaceBetween,
                controllerService.reactContentClassNames
            )}
        >
            <LinkWithChild navigationPath={paths.blocks.details} params={{ id: parentBlock?.id }}>
                <UITextButton
                    disabled={!parentBlock?.id}
                    title={liveLocalized.Previous}
                    icon={IconArrowLeft}
                    testID={detailsScreenTestIDs.prevBlockButton}
                />
            </LinkWithChild>
            {nextLink}
        </div>
    );

    // accordions
    const { account_blocks } = block || {};
    const { TONBlock: blockLocale } = liveLocalized;

    const sections = [
        {
            title: blockLocale.value_flow.title,
            rows: Details.valueFlow(value_flow || null),
        },
        {
            title: blockLocale.account_blocks.title,
            rows: Details.accountBlocksList(account_blocks || null),
        },
        {
            title: liveLocalized.Shards,
            rows: ShardDetails.shards(master || null),
        },
        {
            title: blockLocale.master.config.title,
            rows: MasterDetails.config(master || null),
        },
    ];

    const accordions = sections.map(({ title, rows }, index) => {
        if (!rows.length) return null;

        return (
            <Accordion
                key={`details-accordion-section-${title}`}
                classNames={classnames(controllerService.reactContentClassNames, margin.topDefault)}
                title={title}
            >
                <UIDetailsTable
                    narrow={controllerService.isNarrow}
                    containerClassNames={index === 4 ? margin.topDefault : ''}
                    detailsList={rows}
                />
            </Accordion>
        );
    });

    useEffect(() => {
        return onWillFocus(params, ref, onLoadBlock);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.BlockDetails}
            belowTitleComponent={prevNextNavBar}
            details={details}
            needMoreDetailsButton
            moreDetails={moreDetails}
            detailsComponent={
                <>
                    {accordions}
                    <DetailsTabView pages={pages} />
                </>
            }
        />
    );
};

const Block = observer(BlockComponent);

export { Block };
