// @flow
import type {
    ElectionParticipantsForFuncElector,
    ElectorAccountContract,
    SolidityElectorOutput,
    StakeAndBonuses,
} from '#TONClient/TONValidator/types';
import { TONClient, TONValidator } from '#TONClient';
import { NOT_FOUND, SUCCESS } from '#TONClient/statuses';
import Utils from '#helpers/Utils';
import type { Tons } from '#TONClient/TONClient';
import FBContracts from '@Firebase/FBContracts';

// eslint-disable-next-line max-len
export const getFuncElectorElectionParticipants = async (
    electorAccountContract: ElectorAccountContract
): Promise<ElectionParticipantsForFuncElector> => {
    const log = TONValidator.initLog('election participants', electorAccountContract);

    try {
        const r = await TONClient.runGet({
            account: electorAccountContract?.account?.boc,
            function_name: 'participant_list_extended',
        });

        if (!r) {
            log.debug(NOT_FOUND);
            return {};
        }

        const participants = TONClient.arrayFromCONS(r.output[4])
            .map((arr) => {
                const subarr = arr[1] || [];
                return {
                    public_key: Utils.formatHex(arr[0]),
                    stake: TONClient.nanoToTons(Utils.remove0x(subarr[0])),
                    // max_factor - 1, addr - 2,
                    adnl_addr: Utils.formatHex(subarr[3]),
                };
            })
            .sort((a, b) => b.stake - a.stake);
        const utime_since = r.output[0];

        const result = {
            utime_since,
            participants,
        };
        log.debug(SUCCESS, result);
        return result;
    } catch (e) {
        log.error(e);
        return {};
    }
};

// eslint-disable-next-line max-len
export const getFuncElectorTotalStakesAndBonuses = async (
    electorAccountContract: ElectorAccountContract
): Promise<{ [number]: StakeAndBonuses }> => {
    const log = TONValidator.initLog('total stakes');

    try {
        const r = await TONClient.runGet({
            account: electorAccountContract?.account?.boc,
            function_name: 'past_elections',
        });

        if (!r) {
            log.debug(NOT_FOUND);
            return {};
        }

        const arrays = TONClient.arrayFromCONS(r.output[0]);
        const result = {};
        arrays.forEach((arr: Tons[]) => {
            result[arr[0]] = {
                stake: TONClient.nanoToTons(arr[5]),
                bonuses: TONClient.nanoToTons(arr[6]),
            };
        });

        log.debug(SUCCESS, result, r);
        return result;
    } catch (e) {
        log.error(e);
        return {};
    }
};

// eslint-disable-next-line max-len
export const getSolidityElectorOutput = async (
    electorAccountContract: ElectorAccountContract
): Promise<SolidityElectorOutput> => {
    const log = TONValidator.initLog('get output of get method for solidity elector', electorAccountContract);

    if (FBContracts.isFuncElector(electorAccountContract)) return {};

    const result = await TONClient.runTVMWrapped({
        ...electorAccountContract,
        functionName: 'get',
    });

    if (!result) {
        log.debug(NOT_FOUND);
        return {};
    }

    const pastElections = {};
    Object.keys(result.past_elections).forEach((key) => {
        const currentPastElections = result.past_elections[key];

        pastElections[key] = {
            stake: TONClient.nanoToTons(currentPastElections.total_stake),
            bonuses: TONClient.nanoToTons(currentPastElections.bonuses),
        };
    });
    result.past_elections = pastElections;

    // const members = Utils.objectValues(output.cur_elect.members);
    result.cur_elect.members = (Object.keys(result.cur_elect.members || {}) || []).map((public_key) => {
        const member = result.cur_elect.members[public_key];
        return {
            ...member,
            public_key: Utils.formatHex(public_key),
            stake: TONClient.nanoToTons(member.stake),
            adnl_addr: Utils.formatHex(member.adnl_addr),
        };
    });

    log.debug(SUCCESS, result);
    return result;
};
