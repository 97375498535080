import React from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import type { Tip3Token } from '#TONClient/EVERTip3/types';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import { BalanceView, LinkWithChild, UILabel } from '#components';
import type { TONCollectionConfigs } from '#components/EVERList';
import { paths } from '#navigation/paths';
import Utils from '#helpers/Utils';

type Props = {
    item: Tip3Token;
    index?: number;
};

const Tip3TokenViewTestIDs = {
    Tip3TokenView: 'Tip3Token-view',
};

const Tip3TokenView = ({ item }: Props) => {
    if (!item) return null;
    const narrow = controllerService.isNarrow;

    const { name, rootAddress, decimals, totalSupply } = item;

    const captionComponent = (
        <>
            {!narrow && <UILabel>{liveLocalized.TotalSupply}:&nbsp;</UILabel>}
            <BalanceView
                hideIcon
                hex={false}
                balance={totalSupply}
                notConvert
                fixFractional
                fractionalDigits={totalSupply < 1 && item ? 3 : 0}
            />
        </>
    );

    const rootAddressStr = `${liveLocalized.RootAddress}: ${Utils.truncText(rootAddress)}`;
    const secondDetailsStr = `${!narrow ? liveLocalized.Decimals + ': ' : ''}${decimals}`;

    return (
        <LinkWithChild navigationPath={paths.TIP32.details} params={{ id: rootAddress }}>
            <UIDetailsButton
                testID={Tip3TokenViewTestIDs.Tip3TokenView}
                copyTarget={UIDetailsButtonCopyTargets.details}
                copyValue={rootAddress}
                title={Utils.truncTextToNarrow(name)}
                captionComponent={captionComponent}
                details={rootAddressStr}
                secondDetails={secondDetailsStr}
            />
        </LinkWithChild>
    );
};

Tip3TokenView.externalConfigs = {
    sortingKey: 'last_paid',
    filterParams: {
        expression: {
            name: 'name',
            rootAddress: 'rootAddress',
        },
    },
};

Tip3TokenView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ rootAddress, name, symbol }: Tip3Token): string => {
            return `Token-view-${rootAddress}-${name}-${symbol}`;
        },
        idKey: 'rootAddress',
        collectionName: liveLocalized.CatalogTip3Tokens,
        renderItem: ({ item }: { item: any }, index?: number) => <Tip3TokenView index={index} item={item} />,
    };
};

export default observer(Tip3TokenView);
