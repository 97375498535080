import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Popover } from '@mui/material';
import classNames from 'classnames';

import controllerService from '@services/ControllerService';
import { useTap } from '#helpers/hooks/useTap';
import BackgroundView from '#components/BackgroundView';
import MobilePopover from '#components/Popover/MobilePopover';

import { padding } from '#uikit/designCore/styles/padding';
import { common } from '#uikit/designCore/styles/comon';
import { flex } from '#uikit/designCore/styles/flex';
import { border } from '#uikit/designCore/styles/border';

import { CustomPopoverProps, PopoverRefType } from './types';
import './Popover.scss';

const POPOVER_MENU = 'popover-menu';
const POPOVER_TRIGGER = 'popover-button';

export enum Placement {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
}

enum anchorVerticalPosition {
    Top = 'top',
    Bottom = 'bottom',
    Center = 'center',
}

enum anchorHorizontalPosition {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

const anchorPlacementCoords: {
    [key in Placement]: { vertical: anchorVerticalPosition; horizontal: anchorHorizontalPosition };
} = {
    [Placement.Top]: { vertical: anchorVerticalPosition.Top, horizontal: anchorHorizontalPosition.Center },
    [Placement.Bottom]: { vertical: anchorVerticalPosition.Bottom, horizontal: anchorHorizontalPosition.Center },
    [Placement.Left]: { vertical: anchorVerticalPosition.Center, horizontal: anchorHorizontalPosition.Left },
    [Placement.Right]: { vertical: anchorVerticalPosition.Center, horizontal: anchorHorizontalPosition.Right },
};

const CustomPopover = React.forwardRef<PopoverRefType, CustomPopoverProps>(
    (
        {
            placement = Placement.Bottom,
            children,
            testID,
            style,
            containerStyle,
            componentStyle,
            component,
            needCloseOnClick,
            componentClassNames,
            componentContainerStyles = { transform: 'translateY(10px)' },
            narrowComponentClassNames = classNames(padding.topDefault, padding.bottomTiny),
            onShow = () => {},
            onHide = () => {},
        },
        ref
    ) => {
        const [open, setOpen] = useState<boolean>(false);

        const anchorRef = useRef<HTMLDivElement>(null);
        const componentRef = useRef<HTMLDivElement>(null);
        const narrowComponentRef = useRef<HTMLDivElement>(null);

        const [_, isTap] = useTap(anchorRef);

        const closeRef = useRef<{ close: (e: MouseEvent) => void }>({
            close: (e) => {
                const hasClickedPermittedElement = anchorRef.current?.contains(e.target as HTMLElement);
                const hasClickedIgnoringElement = [componentRef, narrowComponentRef].find((ignoringElement) =>
                    ignoringElement?.current?.contains(e.target as HTMLElement)
                );

                if (hasClickedIgnoringElement || hasClickedPermittedElement) return;

                document.removeEventListener('click', closeRef.current?.close);
                setOpen(false);
                onHide();
            },
        });

        const isNarrow = controllerService.isNarrow;

        useImperativeHandle(
            ref,
            () => ({
                close: () => {
                    setOpen(false);
                    document.removeEventListener('click', closeRef.current?.close);
                },
            }),
            [open]
        );

        useEffect(() => {
            if (open) onShow();
            else onHide();
        }, [open]);

        const onClick = () => {
            const clickHandler = (e: MouseEvent) => {
                const hasClickedPermittedElement = anchorRef.current?.contains(e.target as HTMLElement);
                const hasClickedIgnoringElement = !![componentRef, narrowComponentRef].find((ignoringElement) =>
                    ignoringElement?.current?.contains(e.target as HTMLElement)
                );

                if (hasClickedIgnoringElement || hasClickedPermittedElement) return;

                document.removeEventListener('click', clickHandler);
                setOpen(false);
                onHide();
            };

            if (open) {
                // closing now
                document.removeEventListener('click', clickHandler);
            } else {
                // opening now
                document.addEventListener('click', clickHandler);
            }

            setOpen((prev) => !prev);
        };

        const triggerComponent = (
            <div
                ref={anchorRef}
                className={classNames('Popover__buttonContainer', { pressed: isTap })}
                onClick={onClick}
                id={POPOVER_TRIGGER}
                data-testid={testID}
            >
                {children}
            </div>
        );

        if (isNarrow) {
            return (
                <>
                    {triggerComponent}
                    <MobilePopover open={open} onClose={() => setOpen(false)}>
                        <BackgroundView
                            ref={narrowComponentRef}
                            classNames={classNames(
                                common.displayFlex,
                                flex.column,
                                border.radiusMedium,
                                padding.horizontal,
                                narrowComponentClassNames
                            )}
                        >
                            {component}
                        </BackgroundView>
                    </MobilePopover>
                </>
            );
        }

        return (
            <div className="Popover">
                {triggerComponent}
                <Popover
                    open={open}
                    anchorEl={anchorRef?.current}
                    anchorOrigin={anchorPlacementCoords[placement]}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{
                        root: 'Popover__root',
                        paper: classNames('Popover Mui__paper'),
                    }}
                    PaperProps={{ style: componentContainerStyles }}
                >
                    <BackgroundView
                        ref={componentRef}
                        classNames={classNames('Popover__container', componentClassNames)}
                        id={POPOVER_MENU}
                    >
                        {component}
                    </BackgroundView>
                </Popover>
            </div>
        );
    }
);

export default observer(CustomPopover);
