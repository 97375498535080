import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { EVERTransfer } from '#TONClient';
import type { TransferDetailsType } from '#TONClient/EVERTransfer/types';
import { TONLog } from '#TONUtility';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';

import { getDetails } from './helpers';

const log = new TONLog('TransferDetailsScreen');

const Transfer = () => {
    const ref = useRef(null);
    const [transfer, setTransfer] = useState<TransferDetailsType | null>(null);

    const [params] = useParams();
    const { messageID } = params;

    const details = useMemo(() => getDetails(transfer), [transfer]);

    const loadTransfer = useCallback(async () => {
        if (!messageID) {
            log.debug('No message id passed');
            return;
        }

        controllerService.showSpinnerOverlay();

        const transfer = await EVERTransfer.getTransferByMessageID(messageID);
        if (transfer) {
            setTransfer(transfer);
        } else {
            controllerService.showPageNotFound(liveLocalized.TransferByThisMessageIDWasntFound);
        }

        controllerService.hideSpinnerOverlay();
    }, [messageID]);

    useEffect(() => {
        return onWillFocus(params, ref, loadTransfer);
    }, [params]);

    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.TransferDetails}
            details={details}
            needMoreDetailsButton={false}
        />
    );
};

export { Transfer };
