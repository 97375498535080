// @flow
import TONClient from '../TONClient';
import { transactionShortFields } from '#TONClient/constants';

const { formatDEC } = TONClient;

export const shortFields = `id value${formatDEC} src dst created_at`;

export const messageTransactionsField = `
    dst_transaction {
        ${transactionShortFields}
    }
    src_transaction {
        ${transactionShortFields}
    }
`;

export const additionalFields = `
    msg_type
    msg_type_name
    status
    status_name
    block_id
    body
    created_lt${formatDEC}
    ihr_fee${formatDEC}
    fwd_fee${formatDEC}
    bounce
    bounced
    body_hash
    code_hash
    data_hash
    library_hash
    dst_transaction {
        now
        balance_delta(format:DEC)
    }
`;

export const binaryFields = 'code data library proof boc';

export const dstTransactionFields = `
    id src created_at body
    dst_transaction {
        id
        balance_delta(format:DEC)
}`;

export const msgEnvelopeQuery = `{ msg_id next_addr cur_addr fwd_fee_remaining${formatDEC} }`;

export const inMsgDescrQuery = `{
    msg_type
    msg_type_name
    msg_id
    ihr_fee${formatDEC}
    proof_created
    in_msg ${msgEnvelopeQuery}
    fwd_fee${formatDEC}
    out_msg ${msgEnvelopeQuery}
    transit_fee${formatDEC}
    transaction_id
    proof_delivered
}`;

export const outMsgQuery = `{
    msg_type
    msg_type_name
    msg_id
    transaction_id
    out_msg ${msgEnvelopeQuery}
    reimport ${inMsgDescrQuery}
    imported ${inMsgDescrQuery}
    import_block_lt${formatDEC}
}`;
