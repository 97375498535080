// @flow
import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { loadShards } from '#controllers/Lists/helpers';
import { TONFilterTags, TONAccount } from '#TONClient';
import type { GetAccountsArgs, TONAccountT } from '#TONClient/TONAccount/types';
import type { FilterValues } from '#TONClient/TONFilter';
import animationData from '#assets/animations/data1.json';
import { AccountView } from '#components';
import EnvManager from '#helpers/EnvManager';
import useAccountsStatistic from '#helpers/hooks/useAccountsStatistic';

import ListScreen from '../ListScreen';

const AccountsComponent = () => {
    const ref = useRef(null);

    const [accountsStatistic, setAccountsStatistic] = useAccountsStatistic();

    // Details
    const details = [
        {
            title: accountsStatistic.accountsCount,
            details: liveLocalized.Accounts,
        },
        {
            title: accountsStatistic.totalSupply,
            isBalance: true,
            details: EnvManager.isMainnet()
                ? liveLocalized.FreeTONCrystals
                : EnvManager.isTestnet()
                ? liveLocalized.Grams
                : liveLocalized.TotalSupply,
        },
        {
            title: accountsStatistic.circulatingSupply,
            isBalance: true,
            details: liveLocalized.CirculatingSupply,
        },
        {
            title: accountsStatistic.giversBalance,
            isBalance: true,
            details: liveLocalized.CoinsGivers,
        },
    ];

    // Actions
    const itemsLoader = useCallback((args: GetAccountsArgs, filterValues: FilterValues): Promise<TONAccountT[]> => {
        setAccountsStatistic(EnvManager.isNetworkOnDappServer);

        return TONAccount.getAccounts({
            ...args,
            filterValues,
        });
    }, []);

    const onStartReload = async (filterValues?: FilterValues) => {
        loadShards(filterValues || {}).then((result) => {
            ref?.current && ref.current.updateFilterValues(result.filterValues || {});
        });
    };

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Accounts}
            listTitle={liveLocalized.TopUsed}
            shouldHideListTitleOnASC
            details={details}
            itemsLoader={itemsLoader}
            onStartReload={onStartReload}
            animationData={animationData}
            filterProps={TONFilterTags.getAccountProps()}
            collectionConfigs={AccountView.getCollectionConfigs(accountsStatistic.totalSupply)}
            realtimeUpdated={false}
        />
    );
};

const Accounts = observer(AccountsComponent);

export { Accounts };
