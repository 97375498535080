// @flow
import { sha256 } from 'js-sha256';

import FBProfiles from '@Firebase/FBProfiles';
import { TONAccount, TONDePool, TONMessage, TONClient, TONFilter } from '#TONClient';
import electorStakeAbi from '#TONClient/TONValidator/electorStakeAbi';
import type { GetStakesArgs, TONMessageT, TONStakeT } from '#TONClient/TONMessage/types';
import { liveLocalized } from '@services/LocalizationService';
import FBContracts from '@Firebase/FBContracts';
import type { ContractsByCodeHash } from '@Firebase/FBContracts';
import type { AccountCodeHash } from '#TONClient/TONAccount/types';
import Utils from '#helpers/Utils';
import FBValidators from '@Firebase/FBValidators';
import EnvManager from '#helpers/EnvManager';
import {
    addProfileAndValidatorId,
    formatMasterConfigValidators,
    getElectorMessagesArgs,
} from '#TONClient/TONValidator/helpers';

import { CACHED, ERROR, NOT_FOUND, SUCCESS } from '../statuses';
import type { TONMaster, TONMasterConfig, TONValidatorSet, TONZeroState } from '../EVERBlock/types';
import { masterQuery, zeroStateForValidatorsQuery, zeroStateQuery } from '../EVERBlock/fields';
import type { ElectorAccountContract, SolidityElectorOutput, TONValidatorT, ValidatorType } from './types';
import {
    getFuncElectorElectionParticipants,
    getFuncElectorTotalStakesAndBonuses,
    getSolidityElectorOutput,
} from './contracts';
import { FBQueryWrapper } from '../helpers';

export type GetValidatorArgs = {
    expression: string,
    keyBlockNum?: number,
    fullSearch?: boolean,
    fullData?: boolean,
};

export default class TONValidator {
    static types = {
        single: 'Single',
        dePool: 'DePool',
    };

    static roundDuration = 65536;
    static masterConfig = null;

    static async getElectorAccountWithContract(): Promise<ElectorAccountContract> {
        const [account, contract]: any = await Promise.all([
            TONAccount.getAccountForRunTVM(TONClient.addresses.elector),
            FBQueryWrapper(FBContracts.getElectorContractForCurrentNetwork, null)(),
        ]);

        return {
            account,
            ...contract,
        };
    }

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('TONValidator', str, params);
    }

    static getValidatorType(code_hash: string, dePoolProxyContractsByCodeHash: ContractsByCodeHash): ValidatorType {
        return dePoolProxyContractsByCodeHash[code_hash] ? this.types.dePool : this.types.single;
    }

    static getValidatorSetFromMasterConfig(publicKey: string, config: ?TONMasterConfig): ?TONValidatorSet {
        if (!config) return null;

        let validatorConfig: ?TONValidatorSet;

        [config?.p32, config?.p34, config?.p36].forEach((validatorSet: ?TONValidatorSet) => {
            if (!validatorSet) return;

            const list = validatorSet?.list || [];
            if (list.find(({ public_key }) => publicKey === public_key)) {
                validatorConfig = validatorSet;
            }
        });

        return validatorConfig;
    }

    static async getFullValidatorByElectorMsg(electorMsgId: string): Promise<?TONValidatorT> {
        const log = this.initLog('full validator', { electorMsgId });
        const [profiles, electorMsg, dePoolProxyContractsByCodeHash, dePoolContractsByCodeHash] = await Promise.all([
            // $FlowExpectedError
            // this.getMasterConfig(true), // keyBlockNum
            FBQueryWrapper(FBProfiles.getProfiles, null)(),
            TONMessage.getMessage(electorMsgId, { needAdditionalFields: true }),
            FBQueryWrapper(FBContracts.getDePoolProxyContractsByCodeHash, null)(),
            FBQueryWrapper(FBContracts.getDePoolContractsByCodeHash, null)(),
        ]);

        if (!electorMsg) {
            log.debug(NOT_FOUND, 'no elector message with given id');
            return null;
        }

        const validatorAccount =
            (await TONAccount.getAccountWithCreator(electorMsg.src, { needAdditionalFields: true })) || '';
        const validatorType = this.getValidatorType(validatorAccount.code_hash, dePoolProxyContractsByCodeHash || {});

        let validatorWallet;
        let dePoolInfo;
        if (validatorType === this.types.dePool) {
            const dePoolAccount = await TONAccount.getAccountForRunTVM(validatorAccount.creator);
            log.debug('Account for runTVM', dePoolAccount);
            dePoolInfo = await TONDePool.getDePoolInfo(dePoolAccount, dePoolContractsByCodeHash);
            validatorWallet = dePoolInfo?.validatorWallet;
        } else {
            validatorWallet = electorMsg.src;
        }

        const validatorFromParsing = await TONClient.decodeMessageBody({
            abi: electorStakeAbi,
            body: electorMsg.body,
            internal: true,
        })
            .then(({ value }) => {
                const public_key = Utils.formatHex(value?.validatorKey);
                const parsedFields = {
                    public_key,
                    type: validatorType,
                    adnlAddr: Utils.formatHex(value?.adnlAddr),
                    stakeAt: value?.stakeAt,
                    nodeId: TONValidator.getNodeId(public_key),
                    // depool fields
                    validatorWallet,
                    maxFactor: Utils.formatHex(value?.maxFactor),
                    proxies: dePoolInfo?.proxies,
                    dePoolAddress: dePoolInfo?.id,
                };

                return addProfileAndValidatorId(parsedFields, profiles || {});
            })
            .catch((e) => {
                log.warning(e);
                return {};
            });

        const validatorFromConfig = await this.getValidator({
            expression: validatorFromParsing?.public_key,
            fullData: true,
        });

        const result = {
            ...validatorFromConfig,
            ...validatorFromParsing,
            stakeSent: electorMsg.dst_transaction.balance_delta,
            timeSent: electorMsg.dst_transaction.now,
        };

        console.log(SUCCESS, result);
        return result;
    }

    static async getFullValidatorByPublicKey(publicKey: string, keyBlockNumParam?: number): Promise<?TONValidatorT> {
        const log = this.initLog('Get validator by publicKey', publicKey);
        try {
            // $FlowExpectedError
            const config = await this.getMasterConfig(true, keyBlockNumParam);

            if (!config) return null;

            const currentValidatorSet = this.getValidatorSetFromMasterConfig(publicKey, config);

            // eslint-disable-next-line max-len
            const msgDocs: TONMessageT[] = await TONClient.loadAllInRange(
                getElectorMessagesArgs(config, currentValidatorSet?.utime_since)
            );

            const msgs = await Promise.all(
                msgDocs.map((doc) =>
                    TONClient.decodeMessageBody({
                        abi: electorStakeAbi,
                        body: doc.body,
                        internal: true,
                    })
                        .then(({ value }) => ({
                            publicKey: Utils.formatHex(value?.validatorKey),
                            electorMsgId: doc.id,
                        }))
                        .catch((e) => {
                            log.warning(e);
                            return null;
                        })
                )
            );
            const filteredMsgs = msgs.filter((msg) => !!msg);

            const msgsByPublicKey = Utils.groupById(filteredMsgs, 'publicKey');

            const msgId = msgsByPublicKey[publicKey]?.electorMsgId;

            if (msgId) {
                log.debug(msgId);
                return this.getFullValidatorByElectorMsg(msgId);
            }

            const validatorFromConfig = await this.getValidator({
                expression: publicKey,
                fullData: true,
            });

            if (validatorFromConfig) {
                return validatorFromConfig;
            }

            log.debug(NOT_FOUND);
            return null;
        } catch (e) {
            log.warning(e);
            return null;
        }
    }

    static async getFullValidatorByIdForCurrent(validatorId: string): Promise<?TONValidatorT> {
        const log = this.initLog('Find current validator by validatorId', validatorId);
        const config: ?TONMasterConfig = await this.getMasterConfigWithFullValidators();
        const shortValidator = (config?.p34?.list || []).find((val) => val.validatorId === validatorId);

        if (!shortValidator?.electorMsgId) {
            log.debug(NOT_FOUND);
        }
        const result = await this.getFullValidatorByElectorMsg(shortValidator?.electorMsgId || '');

        if (result) {
            log.debug(SUCCESS, result);
        } else {
            log.debug(NOT_FOUND);
        }
        return result;
    }

    static async getMasterConfigWithFullValidators(keyBlockNum?: number): Promise<?TONMasterConfig> {
        try {
            if (!EnvManager.isMainnet()) {
                // && !EnvManager.isRustnet()
                return this.loadMasterConfigWithFullValidators(keyBlockNum);
            }

            const [configFromFb, config, profiles] = await Promise.all([
                FBQueryWrapper(FBValidators.getMasterConfigWithFullValidators, null)(),
                this.getCleanMasterConfig(keyBlockNum),
                FBQueryWrapper(FBProfiles.getProfiles, null)(),
            ]);

            if (!configFromFb) return null;

            const formatter = (validatorSet: TONValidatorSet) => ({
                ...validatorSet,
                list: validatorSet.list.map((item) => ({
                    ...addProfileAndValidatorId<TONValidatorT>(item, profiles || {}),
                    maxFactor: Utils.formatHex(item?.maxFactor),
                })),
            });

            return {
                ...config,
                p32: formatter(configFromFb.p32),
                p34: formatter(configFromFb.p34),
                p36: formatter(configFromFb.p36),
            };
        } catch (e) {
            return null;
        }
    }

    static async loadMasterConfigWithFullValidators(keyBlockNum?: number): Promise<?TONMasterConfig> {
        const log = this.initLog('validators', { keyBlockNum });

        try {
            const [config, profiles, dePoolProxyContractsByCodeHash] = await Promise.all([
                // $FlowExpectedError
                this.getMasterConfig(true, keyBlockNum),
                FBQueryWrapper(FBProfiles.getProfiles, null)(),
                FBQueryWrapper(FBContracts.getDePoolProxyContractsByCodeHash, null)(),
            ]);
            if (!config) return null;

            const electorMsgDocs: any = await Promise.all(
                ['p32', 'p34', 'p36'].map((path) =>
                    TONClient.loadAllInRange(getElectorMessagesArgs(config, config[path]?.utime_since))
                )
            );

            log.debug('Elector messages for p32/p34/p36', electorMsgDocs);

            const electorMsgsList: TONMessageT[] = [
                ...electorMsgDocs[0],
                ...electorMsgDocs[1],
                ...electorMsgDocs[2],
            ].filter((doc) => doc.dst_transaction.balance_delta > 0);

            const srcList: string[] = Utils.removeDuplicates(electorMsgsList.map((doc) => doc.src));

            const acDocs: AccountCodeHash[] = await TONClient.loadAllInList(srcList, {
                result: 'id code_hash',
                collection: TONClient.collections.accounts,
                getFilter: (subList) => ({ id: { in: subList } }),
            }).then((acDocsWithCodeHash) => {
                const acDocsWithCodeHashById = Utils.groupById(acDocsWithCodeHash, 'id');
                return srcList.map((id) => acDocsWithCodeHashById[id]);
            });
            log.debug('Accounts for elector messages', acDocs);

            const validatorTypesById: { [string]: ValidatorType } = acDocs.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.id]: this.getValidatorType(curr.code_hash, dePoolProxyContractsByCodeHash),
                }),
                {}
            );

            const dePoolDetailsWithProxyId = await TONDePool.getDePoolDetailsWithProxyId(acDocs);
            const dePoolDetailsByProxyId = Utils.groupById(dePoolDetailsWithProxyId, 'proxyId');

            const validators = await Promise.all(
                electorMsgsList.map((doc) =>
                    TONClient.decodeMessageBody({
                        abi: electorStakeAbi,
                        body: doc.body,
                        internal: true,
                    })
                        .then(({ value }) => {
                            const public_key = Utils.formatHex(value?.validatorKey);
                            const type = doc.src ? validatorTypesById[doc.src] : '';
                            const dePoolDetails = doc.src ? dePoolDetailsByProxyId[doc.src] : {};
                            const validatorWallet =
                                type === this.types.single ? doc.src : dePoolDetails?.validatorWallet;

                            const result = {
                                public_key,
                                type,
                                adnlAddr: Utils.formatHex(value?.adnlAddr),
                                stakeAt: value?.stakeAt,
                                nodeId: TONValidator.getNodeId(public_key),
                                validatorWallet,
                                // depool fields
                                dePoolAddress: dePoolDetails?.id,
                                proxies: dePoolDetails?.proxies || null,
                                maxFactor: Utils.formatHex(value?.maxFactor),
                                electorMsgId: doc.id,
                            };

                            return addProfileAndValidatorId(result, profiles || {});
                        })
                        .catch((e) => {
                            log.warning(ERROR, e);
                            return {};
                        })
                )
            );
            log.debug(`Parsing elector messages, add validatorWallet ${SUCCESS}`, validators);

            const validatorDetailsByPublicKey = Utils.groupById(validators, 'public_key');

            const newConfig = {
                ...config,
                ...['p32', 'p34', 'p36'].reduce(
                    (prev, curr: string) => ({
                        ...prev,
                        [curr]: {
                            ...config[curr],
                            list: config[curr]
                                ? (config[curr].list || []).map((item) => {
                                      return {
                                          ...item,
                                          ...validatorDetailsByPublicKey[item.public_key],
                                      };
                                  })
                                : [],
                        },
                    }),
                    {}
                ),
            };

            log.debug(SUCCESS, newConfig);
            return newConfig;
        } catch (e) {
            log.error(e);
            return null;
        }
    }

    static async getCleanMasterConfig(keyBlockNumParam?: number) {
        try {
            const log = this.initLog('Clean master config', { keyBlockNumParam });

            const filter = {
                ...(keyBlockNumParam && { seq_no: { eq: TONClient.number(keyBlockNumParam) } }),
                workchain_id: { eq: -1 },
                key_block: { eq: true },
            };
            const order = [{ path: '"seq_no"', direction: 'DESC' }];

            const blDocs: { master: TONMaster }[] = await TONClient.queryBlocks(
                Utils.JSONStringifyWithoutParanthesesAroundKeys(filter),
                masterQuery(false),
                Utils.JSONStringifyWithoutParanthesesAroundKeys(order),
                1
            );

            if (!blDocs?.length) {
                log.debug(NOT_FOUND);
                return null;
            }

            const config = blDocs && blDocs[0]?.master?.config;
            log.debug(SUCCESS, config);
            return config;
        } catch (e) {
            return null;
        }
    }

    static async getMasterConfig(
        validatorsMode: boolean = false,
        keyBlockNumParam?: number
    ): Promise<?TONMasterConfig> {
        const log = this.initLog('Master config', { validatorsMode, keyBlockNumParam });

        if (!keyBlockNumParam && !validatorsMode && this.masterConfig) {
            // we're caching masterConfig for last key block and can use only short result
            // without stakes, bonuses and next elections participants
            log.debug(CACHED, this.masterConfig);
            return this.masterConfig;
        }

        const electorAccountContract = await this.getElectorAccountWithContract();
        const isFunc = FBContracts.isFuncElector(electorAccountContract);

        const solidityElectorOutput = isFunc ? {} : await getSolidityElectorOutput(electorAccountContract);

        const p36WithElectionParticipantsWithDefaultRound = await this.getP36WithElectParticipantsDefRound(
            solidityElectorOutput,
            electorAccountContract
        );

        let config = await this.getCleanMasterConfig(keyBlockNumParam);

        if (!config) {
            // || true
            const blDocs = await TONClient.queryCollectionZeroStates({}, zeroStateForValidatorsQuery);
            config = blDocs && blDocs[0]?.master?.config;
        }

        // this is if net is stopped, but prepare for start with election participants
        if (!config) {
            log.debug(NOT_FOUND);
            return validatorsMode
                ? {
                      p36: p36WithElectionParticipantsWithDefaultRound,
                  }
                : null;
        }

        const stakesAndBonuses = validatorsMode
            ? isFunc
                ? await getFuncElectorTotalStakesAndBonuses(electorAccountContract)
                : solidityElectorOutput?.past_elections || {}
            : {};

        const result = formatMasterConfigValidators(
            stakesAndBonuses,
            p36WithElectionParticipantsWithDefaultRound,
            validatorsMode,
            config
        );
        log.debug(SUCCESS, result);

        if (!keyBlockNumParam && !validatorsMode) {
            // we're caching masterConfig for last key block and non-validators mode only
            this.masterConfig = result;
        }

        return result;
    }

    static async getZeroState() {
        const log = this.initLog('ZeroState');
        let blDocs: TONZeroState = {};

        try {
            blDocs = await TONClient.queryCollectionZeroStates({}, zeroStateQuery(true));
        } catch (e) {
            blDocs = await TONClient.queryCollectionZeroStates({}, zeroStateQuery(false));
        } finally {
            log.debug(SUCCESS, blDocs);
        }

        return blDocs[0];
    }

    static async getP36WithElectParticipantsDefRound(
        output: SolidityElectorOutput,
        electorAccountContract: ElectorAccountContract
    ): Promise<TONValidatorSet> {
        const isFunc = FBContracts.isFuncElector(electorAccountContract);
        const electionParticipantsFoFuncElector = isFunc
            ? await getFuncElectorElectionParticipants(electorAccountContract)
            : {};
        const p36Utime_since = TONClient.number(
            isFunc ? electionParticipantsFoFuncElector.utime_since : output?.cur_elect?.elect_at || 0
        );

        return {
            list: isFunc ? electionParticipantsFoFuncElector.participants : output?.cur_elect?.members,
            utime_since: p36Utime_since,
            utime_until: p36Utime_since ? p36Utime_since + this.roundDuration : 0,
        };
    }

    static async getStakes(args: GetStakesArgs) {
        const filter = {
            // TODO Refactor this to apply src / dst filters
            ...(await TONMessage.filter(args, 'src')),
            src: { in: args.proxies },
            dst: { eq: TONClient.addresses.elector },
            body: { gt: null },
        };
        const log = this.initLog('election stakes', filter);
        const orderBy = TONFilter.orderBy(args, 'created_at');

        const msgDocs: TONMessageT[] = await TONClient.queryCollectionMessages(
            filter,
            TONMessage.dstTransactionFields,
            orderBy,
            args.limit
        );
        log.debug('msgs from validator to Elector contract', msgDocs);

        const result: TONStakeT[] = await Promise.all(
            msgDocs
                .filter((doc) => TONClient.number(doc.dst_transaction?.balance_delta) !== 0)
                .map((doc) => {
                    const delta = TONClient.number(doc.dst_transaction?.balance_delta);
                    const item = {
                        id: doc.dst_transaction?.id || doc.id,
                        created_at: doc.created_at,
                        src: doc.src,
                        type: delta > 0 ? liveLocalized.Stake : liveLocalized.Recover,
                        amount: delta,
                    };

                    return delta > 0
                        ? TONClient.decodeMessageBody({
                              abi: electorStakeAbi,
                              body: doc.body,
                              internal: true,
                          })
                              .then(({ value }) => ({
                                  ...item,
                                  stakeAt: delta > 0 ? TONClient.number(value?.stakeAt) : null,
                              }))
                              .catch((e) => {
                                  log.warning(ERROR, e);
                                  return item;
                              })
                        : new Promise((r) => r(item));
                })
        );
        TONFilter.sortBy(result, args, 'created_at');

        return result;
    }

    static async getValidator(args: GetValidatorArgs): Promise<?TONValidatorT> {
        const log = this.initLog('validator from master config', args);
        const { expression, keyBlockNum, fullSearch = false } = args;

        const config: ?TONMasterConfig = await this.getMasterConfig(true, keyBlockNum);

        let validator;
        [config?.p32, config?.p34, config?.p36].forEach((validatorSet: ?TONValidatorSet) => {
            if (validator || !validatorSet) return;

            const list = validatorSet?.list || [];
            validator = list.find(({ public_key, adnl_addr }) => {
                const hexExpression = TONClient.base64ToHex(expression);
                return (
                    [hexExpression, Utils.remove0x(expression).toLowerCase()].includes(public_key) ||
                    (fullSearch && [hexExpression, expression.toLowerCase()].includes(adnl_addr))
                );
            });
            if (validator) {
                validator = {
                    ...validator,
                    nodeId: this.getNodeId(validator.public_key),
                    utime_since: validatorSet?.utime_since,
                    utime_until: validatorSet?.utime_until,
                };
            }
        });

        if (!validator) {
            log.debug(NOT_FOUND);
            return null;
        }

        log.debug(SUCCESS, validator);
        return validator;
    }

    static async findValidators(args: GetValidatorArgs): Promise<?(TONValidatorT[])> {
        const log = this.initLog('validator from master config', args);
        const { expression, keyBlockNum, fullSearch = false } = args;

        const config: ?TONMasterConfig = await this.getCleanMasterConfig(keyBlockNum);

        const result = [];
        [config?.p32, config?.p34, config?.p36].forEach((validatorSet: ?TONValidatorSet) => {
            let validators = [];
            if (!validatorSet) return;

            const list = validatorSet?.list || [];
            validators = list.filter(({ public_key, adnl_addr }) => {
                const hexExp = TONClient.base64ToHex(expression);
                const expStr = expression.toLowerCase();

                return (
                    public_key?.startsWith(hexExp) ||
                    public_key?.startsWith(Utils.remove0x(expStr)) ||
                    (fullSearch && adnl_addr?.startsWith(hexExp)) ||
                    adnl_addr?.startsWith(expStr)
                );
            });
            if (validators?.length > 0) {
                validators.forEach((validator) => {
                    result.push({
                        ...validator,
                        nodeId: this.getNodeId(validator.public_key) || '',
                        utime_since: validatorSet?.utime_since,
                        utime_until: validatorSet?.utime_until,
                    });
                });
            }
        });

        if (!result?.length) {
            log.debug(NOT_FOUND);
            return null;
        }

        log.debug(SUCCESS, result);
        return result;
    }

    static nodeIdsByKey = {};

    static getNodeId(publicKey: ?string) {
        if (!publicKey) return null;

        if (this.nodeIdsByKey[publicKey]) return this.nodeIdsByKey[publicKey];

        const formattedPublicKey = Utils.formatHex(publicKey);
        try {
            const byteArr = Buffer.from(formattedPublicKey, 'hex');
            const magic = [0xc6, 0xb4, 0x13, 0x48]; // magic 0x4813b4c6 from original node's code
            const hash = sha256.update(magic).update(byteArr);

            return (this.nodeIdsByKey[publicKey] = hash.hex());
        } catch (e) {
            console.log(e);
            return null;
        }
    }
}
