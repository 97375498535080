import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import type { EVERTransactionT } from '#TONClient/EVERTransaction/types';
import { paths } from '#navigation/paths';
import { LinkWithChild, UILabel, BalanceView, UIDetailsButton } from '#components';
import { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import { TONClient, EVERTransaction } from '#TONClient';
import Utils from '#helpers/Utils';
import MomentHelper from '#helpers/MomentHelper';
import type { TONCollectionConfigs } from '#components/EVERList';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

export type Props = {
    item: Maybe<EVERTransactionT>;
};

export const transactionViewTestIDs = {
    transactionView: 'transactionView',
};

const TransactionView = ({ item = null }: Props) => {
    if (!item) return null;

    const { balance_delta, tr_type, tr_type_name, id, now, account_addr } = item;
    const timeDate = MomentHelper.getTimeDate(now ?? null);
    const details = `${Utils.truncAddress(account_addr)} · ${timeDate}`;
    const captionComponent =
        tr_type === EVERTransaction.types.ordinary ? (
            <BalanceView fixFractional balance={balance_delta} />
        ) : (
            <UILabel role={TypographyVariants.ParagraphNote} color={ColorVariants.TextTertiary}>
                {(tr_type_name && liveLocalized.TONTransaction.types[tr_type_name]) || ''}
            </UILabel>
        );

    return (
        <LinkWithChild navigationPath={paths.transactions.details} params={{ id: item.id }}>
            <UIDetailsButton
                testID={transactionViewTestIDs.transactionView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                truncTitle
                title={id}
                captionComponent={captionComponent}
                details={details}
            />
        </LinkWithChild>
    );
};

TransactionView.externalConfigs = {
    sortingKey: 'now',
    items: null,
};

TransactionView.getCollectionConfigs = (accountId?: Maybe<string>): TONCollectionConfigs => {
    const accountIdProp = accountId ? { accountId } : null;

    return {
        keyExtractor: ({ id }: { id: string }): string => {
            return `transaction-view-${id}`;
        },
        idKey: 'id',
        newItemName: 'NewTransaction',
        collectionName: liveLocalized.Transactions,
        collectionKey: TONClient.collections.transactions,
        renderItem: ({ item }: { item: any }) => <TransactionView item={item} {...accountIdProp} />,
    };
};

export default TransactionView;
