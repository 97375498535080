// @flow
import { TONLog } from '#TONUtility';
import { ERROR, SUCCESS } from '#TONClient/statuses';
import Utils from '#helpers/Utils';
import type { AccountsStatistic, TONAccountT } from '#TONClient/TONAccount/types';
import type { SortDirection } from '#TONClient/TONFilter';
import type { ParamsOfQueryOperation } from '@eversdk/core';
import {
    abiContract,
    FieldAggregation,
    ResponseHandler,
    ResultOfRunTvm,
    ResultOfSubscribeCollection,
} from '@eversdk/core';
import {
    AbiContract,
    bocCacheTypePinned,
    ParamsOfEncodeMessage,
    ParamsOfRunGet,
    ParamsOfRunTvm,
} from '@eversdk/core/dist/modules';

import type { BlocksStatistic, MasterSeqNoRange, TONBlockT } from '#TONClient/EVERBlock/types';
import { EVERBlockFromNewAPI } from '#TONClient/EVERBlock/types';
import { EVERTransactionT, TransactionFromNewAPI } from '#TONClient/EVERTransaction/types';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import { blocksStatisticQuery } from '#TONClient/TONFields';
import { getNetworkCluster } from '#helpers/index';

export type Tons = string;
export type TonsNumber = number;
export type OtherCurrency = string | number;
export type Hexadecimal = number;

export type ParamsOfAggregate = {
    filter?: any,
    fields?: FieldAggregation[],
};

export type ConvertAddressArgs = {
    address: string,
    bounce: boolean,
    url: boolean,
};

const clientLog = new TONLog('TONClient');

export type AggregationFn = 'COUNT' | 'SUM';

export type CollectionName = 'blocks' | 'accounts' | 'transactions' | 'messages' | 'block_signatures';

export type AggregationParams = {
    filter: any,
    collection: CollectionName,
    log: TONLog,
    fields?: { field: string, fn: AggregationFn }[],
};

export type LoadItemsParams = {
    log?: TONLog,
    itemsLoader?: (lastItem: any) => Promise<any[]> | any[],
    result?: string,
    order?: any,
    collection?: string,
    getFilter?: (lastItem: any) => any,
    multiThread?: boolean,
    maximizeThreads?: boolean,
};

export type RunTVMWrappedArgs = {
    account: TONAccountT,
    abi: AbiContract, // type from SDK
    functionName: string,
    input?: any,
    needToLog?: boolean,
    bocRef?: string | null,
};

export type DecodeMessageBodyArgs = {
    abi: AbiContract,
    body?: string,
    internal: boolean,
    needToLog?: boolean,
};

type runGetParams = {
    account: TONAccountT,
    abi: AbiContract,
    methods: string[],
    input?: { [key: string]: any },
    needToLog?: boolean,
};

export default class TONClient {
    static itemsLoadingMax: number = 50;
    static operationBatchLengthMax: number = 200;
    static itemsLoadingLimit: number = 25;
    static itemsLoadingDoubleLimit: number = this.itemsLoadingLimit * 2;
    static itemsLoadingSmallLimit: number = 5;
    static itemsLoadingInfinity: number = 10000;
    static loadAllInRangeMultiThreadKeys: number[] = Array(16)
        .fill()
        .map((e, i) => i);
    static timeDelayForEndTimeQuery = 20000;

    static collections: { [key: string]: CollectionName } = {
        accounts: 'accounts',
        blocks: 'blocks',
        messages: 'messages',
        transactions: 'transactions',
    };

    static sortDirection: { [key: SortDirection]: SortDirection } = {
        ASC: 'ASC',
        DESC: 'DESC',
    };

    static operationTypes = {
        queryCollection: 'QueryCollection',
        aggregateCollection: 'AggregateCollection',
    };

    static addresses = {
        elector: '-1:3333333333333333333333333333333333333333333333333333333333333333',
        creator: '-1:0000000000000000000000000000000000000000000000000000000000000000',
    };

    static network: any;

    static formatDEC = '(format: DEC)';
    static timeout0 = '(timeout:0)';

    static convertedNanoToTons = {};

    static runTVMFields = 'id boc balance code_hash';

    static nanoToTons(value: Tons | number): number {
        return this.number(value) / 10 ** 9;
    }

    static tonsToNano(value: Tons | number): number {
        return this.number(value) * 10 ** 9;
    }

    static hexNanoToTons(value: Tons): number {
        return this.nanoToTons(this.hexToDec(value));
    }

    static hex0xNanoToTons(value: Tons): number {
        return this.nanoToTons(this.hex0xToDec(value));
    }

    static number(num: ?string | number): number {
        return Number(num || 0);
    }

    static hex0xToDec(value: string): number {
        return this.hexToDec(Utils.remove0x(value));
    }

    static optionalHex0xToDecConverter(hex: boolean): (string) => number {
        return hex ? (value) => TONClient.hex0xToDec(value) : (value) => TONClient.number(value);
    }

    static hexToDec(value: ?string | number = '0'): number {
        return parseInt(value, 16);
    }

    static initLog(className: string, str: string | number = '', params?: any) {
        return new TONLog(`${className}${str ? ` ${str}` : ''}`, params);
    }

    // query
    static async query(query: string) {
        const response = await this.network.net.query({
            query,
        });
        return response?.result || [];
    }

    static JSFetchQuery = async <ResponseType = any, VariablesType = any>(
        query: string,
        variables?: VariablesType
    ): Promise<ResponseType | null> => {
        try {
            const body = JSON.stringify({ query, ...(variables ? variables : {}) });
            const cluster = getNetworkCluster();
            const prefix = cluster.startsWith('http') ? '' : 'https://';

            return fetch(`${prefix}${getNetworkCluster()}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Basic OjE2NGNkYWQyNjY1MjRmNGU4YjgwMTVmODEyOWY0MDIz',
                },
                body,
                redirect: 'follow',
            })
                .then((response) => response.json())
                .catch(() => null);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    static async queryMasterSeqNoRange(startTime: ?number, endTime: ?number): Promise<MasterSeqNoRange> {
        const isStartTime = startTime || startTime === 0;
        const isEndTime = endTime || endTime === 0;

        const response = await this.JSFetchQuery(`
                query {
                    blockchain{
                        master_seq_no_range(${isStartTime ? `time_start: ${startTime},` : ''} ${
            isEndTime
                ? `time_end: ${Math.min(endTime, parseInt((Date.now() - TONClient.timeDelayForEndTimeQuery) / 1000))},`
                : ''
        }){
                            ${isStartTime ? 'start' : ''}
                            ${isEndTime ? 'end' : ''}
                        }
                    }
                }
        `);
        return response?.data?.blockchain?.master_seq_no_range;
    }

    static async queryBlock(bHash: string, fields: string) {
        const response = await this.JSFetchQuery(
            `
                query{
                  blockchain{
                    block(hash:"${bHash}"){
                        ${fields}
                    }
                  }
                }
            `
        );

        return response?.data?.blockchain?.block;
    }

    static async queryBlockBySeqNo(workchain: number, seq_no: number, thread: string, fields: string) {
        const response = await this.JSFetchQuery(
            `
                query{
                  blockchain{
                    block_by_seq_no(workchain:${workchain}, seq_no:${seq_no}, thread:"${thread}"){
                      ${fields}
                    }
                  }
                }
            `
        );
        return response?.data?.blockchain?.block_by_seq_no;
    }

    static async queryBlocksFromBlockchainApi(
        query: string,
        isKeyBlocksQuery: boolean,
        sortDirection?: SortDirection
    ): Promise<{
        blocks: TONBlockT[],
        endCursor: string,
    }> {
        const response = await this.JSFetchQuery(query);

        const result = (response?.data?.blockchain || {})[isKeyBlocksQuery ? 'key_blocks' : 'blocks'];
        return {
            blocks: result.edges.map<EVERBlockFromNewAPI>((blockEdge) => blockEdge.node),
            endCursor: sortDirection === 'DESC' ? result?.edges[0]?.cursor : result?.pageInfo.endCursor,
        };
    }

    static async queryBlocks(filter: string, fields: string, orderBy: string, limit: number) {
        const response = await this.JSFetchQuery(`
                query{
                    blocks${
                        filter || orderBy || limit
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                    ${orderBy ? `orderBy: ${orderBy},` : ''}
                    ${limit ? `limit: ${limit},` : ''}
                )`
                            : ''
                    }
                    {
                       ${fields}
                    }
                }
        `);

        return response?.data?.blocks || {};
    }

    static async queryBlocksSignatures(filter: string, fields: string) {
        const response = await this.JSFetchQuery(`
                query{
                    blocks_signatures${
                        filter
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                )`
                            : ''
                    }
                    {
                       ${fields}
                    }
                }
        `);

        return response?.data.blocks_signatures || {};
    }

    static async queryAggregateBlockSignatures(filter: string) {
        const response = await this.JSFetchQuery(`
                query{
                    aggregateBlockSignatures${
                        filter
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                )`
                            : ''
                    }
                }
        `);

        return response?.data.aggregateBlockSignatures || {};
    }

    static async queryBlockShards(fields: string, limit: number): Promise<any> {
        const response = await this.JSFetchQuery(`
                query {
                    blockchain{
                        blocks( last:${limit}){
                            edges {
                                node {
                                    ${fields}
                                }
                            }
                        }
                    }
                }
        `);

        return response?.data?.blockchain?.blocks?.edges[0].node;
    }

    static async queryTransactionsFromBlockchainAPI(
        query: string,
        sortDirection: SortDirection = 'DESC'
    ): Promise<{
        transactions: EVERTransactionT[],
        endCursor: number,
    }> {
        const response = await this.JSFetchQuery(query);

        const result = response?.data?.blockchain?.transactions || {};
        return {
            transactions: result?.edges.map<TransactionFromNewAPI>((transactionEdge) => transactionEdge.node) || [],
            endCursor: sortDirection === 'DESC' ? result?.edges[0]?.cursor : result?.pageInfo?.endCursor,
        };
    }

    static async queryAccountTransactions(
        query: string,
        sortDirection: SortDirection = 'DESC'
    ): Promise<{
        transactions: EVERTransactionT[],
        endCursor: number,
    }> {
        const response = await this.JSFetchQuery(query);

        const result = response?.data?.blockchain?.account?.transactions || {};
        return {
            transactions: result?.edges.map<TransactionFromNewAPI>((transactionEdge) => transactionEdge.node) || [],
            endCursor: sortDirection === 'DESC' ? result?.edges[0]?.cursor : result?.pageInfo?.endCursor,
        };
    }

    static async queryAccountMessages(
        query: string,
        sortDirection: SortDirection = 'DESC'
    ): Promise<{
        messages: TONMessageT[],
        endCursor: number,
    }> {
        const response = await this.JSFetchQuery(query);

        const result = response?.data?.blockchain?.account?.messages || {};
        return {
            messages: result?.edges.map<TONMessageT>((messageEdge) => messageEdge.node) || [],
            endCursor: sortDirection === 'DESC' ? result?.edges[0]?.cursor : result?.pageInfo?.endCursor,
        };
    }

    static async queryAccount(accountId: string, fields: string): Promise<TONAccountT | {}> {
        const response = await this.JSFetchQuery(`
                query {
                    blockchain{
                        account(address:"${accountId}"){
                            info{
                                ${fields}
                            }
                        }
                    }
                }
            `);

        return response?.data?.blockchain?.account?.info || {};
    }

    static async queryTransactions(
        filter: string,
        fields: string,
        orderBy: string,
        limit: number
    ): Promise<EVERTransactionT | null> {
        const response = await this.JSFetchQuery(`
                query{
                    transactions${
                        filter || orderBy || limit
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                    ${orderBy ? `orderBy: ${orderBy},` : ''}
                    ${limit ? `limit: ${limit},` : ''}
                )`
                            : ''
                    }
                    {
                       ${fields}
                    }
                }
        `);

        return response?.data?.transactions || {};
    }

    static async queryTransaction(hash: string, fields: string): Promise<EVERTransactionT | null> {
        const response = await this.JSFetchQuery(`
                query{
                    blockchain{
                        transaction(hash:"${hash}"){
                            ${fields}
                        }
                    }
                }
        `);

        return response?.data?.blockchain?.transaction || {};
    }

    static async queryMessages(
        filter: string,
        fields: string,
        orderBy: string,
        limit: number
    ): Promise<TONMessageT | null> {
        const response = await this.JSFetchQuery(`
                query{
                    messages${
                        filter || orderBy || limit
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                    ${orderBy ? `orderBy: ${orderBy},` : ''}
                    ${limit ? `limit: ${limit},` : ''}
                )`
                            : ''
                    }
                    {
                       ${fields}
                    }
                }
        `);

        return response?.data?.messages || {};
    }

    static async queryMessage(hash: string, fields: string): Promise<TONMessageT | null> {
        const response = await this.JSFetchQuery(`
                query{
                    blockchain{
                        message(hash:"${hash}"){
                            ${fields}
                        }
                    }
                }
        `);

        return response?.data?.blockchain?.message || {};
    }

    static queryAccounts = async (filter: string, fields: string, orderBy: string, limit: number) => {
        const response = await this.JSFetchQuery(`
                query{
                    accounts${
                        filter || orderBy || limit
                            ? `(
                    ${filter ? `filter: ${filter},` : ''}
                    ${orderBy ? `orderBy: ${orderBy},` : ''}
                    ${limit ? `limit: ${limit},` : ''}
                )`
                            : ''
                    }
                    {
                       ${fields}
                    }
                }
        `);

        return response?.data.accounts || {};
    };

    static queryAccountsStatistic = async (): Promise<AccountsStatistic | null> => {
        try {
            const response = await this.JSFetchQuery(`
                query{
                    statistics{
                        accounts{
                            totalCount
                            totalSupply
                            circulatingSupply
                            amountOnGivers
                        }
                    }
                }
        `);

            const result = response?.data?.statistics?.accounts;
            return {
                accountsCount: this.number(result?.totalCount || 0),
                totalSupply: this.number(result?.totalSupply || 0),
                circulatingSupply: this.number(result?.circulatingSupply || 0),
                giversBalance: this.number(result?.amountOnGivers || 0),
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    static queryBlocksStatistic = async (): Promise<BlocksStatistic | null> => {
        try {
            const response = await this.JSFetchQuery(blocksStatisticQuery);

            const result = response?.data?.statistics?.blocks;
            return {
                totalCount: this.number(result?.totalCount || 0),
                ratePerSecond: this.number(result?.ratePerSecond || 0),
                countByCurrentValidators: this.number(result?.countByCurrentValidators || 0),
            };
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    static async queryCollection(
        collection: string,
        filter: { [string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number
    ) {
        const response = await this.network.net.query_collection({
            collection,
            filter,
            result,
            order: order || undefined,
            limit: limit || undefined,
        });

        return response?.result || [];
    }

    static async queryCollectionBlocks(
        filter: { [string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number
    ) {
        return this.queryCollection('blocks', filter, result, order, limit);
    }

    static async queryCollectionZeroStates(
        filter: { [string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number
    ) {
        return this.queryCollection('zerostates', filter, result, order, limit);
    }

    static async queryCollectionAccounts(
        filter: { [string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number
    ) {
        return this.queryCollection('accounts', filter, result, order, limit);
    }

    static async queryCollectionMessages(
        filter: { [string]: any },
        result: string,
        order?: { path: string, direction: SortDirection }[],
        limit?: number
    ) {
        return this.queryCollection('messages', filter, result, order, limit);
    }

    // aggregate
    static async aggregateCollection(collection: string, filter: { [string]: any }, fields?: FieldAggregation[]) {
        const response = await this.network.net.aggregate_collection({
            collection,
            filter,
            fields,
        });

        return response?.values || 0;
    }

    static async aggregateBlocks(filter: { [string]: any }, fields?: FieldAggregation[]) {
        const response = await this.JSFetchQuery(`
            query {
                aggregateBlocks
                    ${
                        filter || fields
                            ? `(${filter ? `filter: ${filter},` : ''}${fields ? `filter: ${fields},` : ''})`
                            : ''
                    }
            }
        `);

        return response?.data?.aggregateBlocks?.[0] || null;
    }

    static async aggregateTransactions(filter?: string, fields?: string) {
        const response = await this.JSFetchQuery(`
            query {
                aggregateTransactions
                    ${
                        filter || fields
                            ? `(${filter ? `filter: ${filter},` : ''}${fields ? `filter: ${fields},` : ''})`
                            : ''
                    }
            }
        `);

        return response?.data?.aggregateTransactions?.[0] || null;
    }

    static aggregateCollectionMessages(filter: { [string]: any }, fields?: FieldAggregation[]) {
        return this.aggregateCollection('messages', filter, fields);
    }

    static async aggregateMessages(filter?: string, fields?: string) {
        const response = await this.JSFetchQuery(`
            query {
                aggregateMessages
                    ${
                        filter || fields
                            ? `(${filter ? `filter: ${filter},` : ''}${fields ? `filter: ${fields},` : ''})`
                            : ''
                    }
            }
        `);

        return response?.data?.aggregateMessages?.[0] || null;
    }

    static async aggregateAccounts(filter?: string, fields?: string) {
        const response = await this.JSFetchQuery(`
            query {
                aggregateAccounts
                    ${
                        filter || fields
                            ? `(${filter ? `filter: ${filter},` : ''}${fields ? `filter: ${fields},` : ''})`
                            : ''
                    }
            }
        `);

        return response?.data?.aggregateAccounts?.[0] || null;
    }

    // subscribe
    static subscribeCollection(
        collection: string,
        filter: { [string]: any },
        result: string,
        responseHandler: ResponseHandler
    ) {
        return this.network.net.subscribe_collection(
            {
                collection,
                filter,
                result,
            },
            (response) => responseHandler(response?.result)
        );
    }

    static subscribeCollectionBlocks(filter: { [string]: any }, result: string, responseHandler: ResponseHandler) {
        return this.subscribeCollection('blocks', filter, result, responseHandler);
    }

    static subscribeCollectionTransactions(
        filter: { [string]: any },
        result: string,
        responseHandler: ResponseHandler
    ) {
        return this.subscribeCollection('transactions', filter, result, responseHandler);
    }

    static subscribeCollectionMessages(filter: { [string]: any }, result: string, responseHandler: ResponseHandler) {
        return this.subscribeCollection('messages', filter, result, responseHandler);
    }

    static unsubscribe(args: ResultOfSubscribeCollection) {
        return this.network.net.unsubscribe(args);
    }

    static encodeMessage(args: ParamsOfEncodeMessage) {
        return this.network.abi.encode_message(args);
    }

    static runTVM(args: ParamsOfRunTvm) {
        return this.network.tvm.run_tvm(args);
    }

    static async runTVMWrapped({
        account,
        abi,
        functionName,
        input = {},
        needToLog = false,
        bocRef,
    }: RunTVMWrappedArgs): Promise<any> {
        const encodedMessage = await TONClient.encodeMessage({
            abi: abiContract(abi),
            address: account?.id,
            call_set: {
                function_name: functionName,
                input,
            },
            signer: { type: 'None' },
        });
        const result: ResultOfRunTvm = await TONClient.runTVM({
            message: encodedMessage.message,
            // ...bocRef || account?.boc ? { account: bocRef || account?.boc } : {},
            account: bocRef || account?.boc || '',
            abi: abiContract(abi),
        });

        needToLog && console.log('runTVMWrapped', { account, abi, functionName }, SUCCESS, result.decoded.output);
        return result.decoded.output;
    }

    static runGet(args: ParamsOfRunGet) {
        return this.network.tvm.run_get(args);
    }

    static async decodeMessageBody({ abi, body, internal, needToLog = false }: DecodeMessageBodyArgs) {
        const result = await this.network.abi.decode_message_body({
            abi: abiContract(abi),
            body,
            is_internal: internal,
        });

        needToLog && console.log('decodeMessageBody', result);
        return result;
    }

    static hexToBase64(str: string): string {
        if (!str) {
            return '';
        }

        return Buffer.from(str, 'hex').toString('base64');
    }

    static base64ToHex(str: string): string {
        if (!str) {
            return '';
        }

        return Buffer.from(str, 'base64').toString('hex');
    }

    static convertAddress(params: any) {
        return this.network.utils.convert_address(params);
    }

    static async convertAddressToHex(address: ?string) {
        if (!address) {
            return null;
        }

        try {
            const result = await TONClient.convertAddress({
                address,
                output_format: {
                    type: 'Hex',
                },
            });
            return result.address;
        } catch (e) {
            clientLog.debug('Error, converting address to hex:', e);
            return null;
        }
    }

    static async convertAddressToBase64(args: ConvertAddressArgs) {
        const { address, bounce, url } = args;
        try {
            const result = await TONClient.convertAddress({
                address,
                output_format: {
                    type: 'Base64',
                    test: false,
                    bounce,
                    url,
                },
            });
            return result.address;
        } catch (e) {
            clientLog.debug('Error, converting address to base64:', e);
            return null;
        }
    }

    static async aggregateItems({ filter, collection, log, fields = [{ field: '', fn: 'COUNT' }] }: AggregationParams) {
        try {
            const docs = await this.aggregateCollection(collection, filter, fields);

            const count = docs && Number(docs[0]);
            log && log.debug(SUCCESS, count);
            return count;
        } catch (err) {
            log && log.error(err);
            throw err;
        }
    }

    static async loadAllInRange(
        args: LoadItemsParams,
        allPrevItemsAr: any[] = [],
        itemsById: { [string]: boolean } = {},
        lastPrevItemsAr: any[] = []
    ) {
        const {
            collection,
            getFilter = () => ({}),
            order,
            result,
            multiThread = false,
            maximizeThreads = false,
            // for single thread
            itemsLoader = () => [],
            log,
        } = args;

        let operations = [];
        let newItemsAr: any[] = [];

        // Loading newItems
        try {
            if (multiThread) {
                const workflowIds = ['-1', '0', '1'];
                const numArrays: number[] = Array(16)
                    .fill()
                    .map((e, i) => i);
                const loadAllInRangeMultiThreadKeys: string[] = [];

                if (maximizeThreads) {
                    workflowIds.forEach((workflowId) => {
                        numArrays.forEach((i) => {
                            loadAllInRangeMultiThreadKeys.push(`${workflowId}:${Number(i).toString(16)}`);
                        });
                    });
                } else {
                    numArrays.forEach((i) => {
                        loadAllInRangeMultiThreadKeys.push(Number(i).toString(16));
                    });
                }

                // setting filters and pagination for each operation
                const filters = await Promise.all(
                    loadAllInRangeMultiThreadKeys.map((prefix, i) => {
                        const lastItem = allPrevItemsAr[i]
                            ? allPrevItemsAr[i][allPrevItemsAr[i]?.length - 1]
                            : undefined;
                        return getFilter(lastItem);
                    })
                );

                // setting operations width filters for multiThread mode
                loadAllInRangeMultiThreadKeys.forEach((prefix, i) => {
                    // const prefix = i >= 16 ? Number(i).toString(16) : `${Number(i).toString(16)}`;
                    const needLoad =
                        lastPrevItemsAr[i]?.length % TONClient.itemsLoadingMax > 0 || lastPrevItemsAr[i]?.length === 0;
                    operations = [
                        ...operations,
                        needLoad
                            ? null
                            : {
                                  order,
                                  result,
                                  collection,
                                  filter: {
                                      ...filters[i],
                                      id: {
                                          ge: `${prefix}`,
                                          le: `${prefix}z`,
                                          ...filters[i]?.id,
                                      },
                                  },
                              },
                    ];
                });

                newItemsAr = await this.batchQuery(operations);

                log && log.debug(newItemsAr, SUCCESS, Utils.parseArray(newItemsAr));
            } else {
                const lastItem = allPrevItemsAr[allPrevItemsAr.length - 1];
                newItemsAr = await itemsLoader(lastItem);

                log && log.debug(lastItem, SUCCESS, newItemsAr);
            }
        } catch (e) {
            console.log(e);
            log && log.error(ERROR, e);
        }

        // count of loaded items
        const newItemsLength = multiThread
            ? newItemsAr.filter((items) => items.length > 0).length // count of non-empty responses
            : newItemsAr.length;

        if (newItemsLength === 0) {
            return multiThread ? Utils.parseArray<any>(allPrevItemsAr) : allPrevItemsAr;
        }

        let addedCount = 0;
        newItemsAr.forEach((element, index) => {
            if (multiThread) {
                // for each operation result we check the elements for originality
                element.forEach((item) => {
                    Utils.callIfNotDuplicate(item.id, itemsById, () => {
                        if (!allPrevItemsAr[index]) allPrevItemsAr[index] = [];
                        allPrevItemsAr[index].push(item);
                        addedCount += 1;
                    });
                });
            } else {
                Utils.callIfNotDuplicate(element.id, itemsById, () => {
                    allPrevItemsAr.push(element);
                    addedCount += 1;
                });
            }
        });

        if (addedCount === 0) {
            return multiThread ? Utils.parseArray<any>(allPrevItemsAr) : allPrevItemsAr;
        }

        // checking for the need for the next request
        const needNewRequest = multiThread
            ? newItemsAr.filter((items) => items.length === TONClient.itemsLoadingMax).length > 0
            : newItemsAr.length === TONClient.itemsLoadingMax;

        if (needNewRequest) {
            return TONClient.loadAllInRange(args, allPrevItemsAr, itemsById, newItemsAr);
        }

        return multiThread ? Utils.parseArray<any>(allPrevItemsAr) : allPrevItemsAr;
    }

    static async loadAllInList(idsList: string[], queryArgs: any) {
        const subLists = Utils.divideBySubLists(idsList);
        const { result, collection, getFilter } = queryArgs;

        const resultQuery = await this.batchQuery(
            subLists.map((subList) => ({
                result,
                collection,
                filter: getFilter(subList),
            }))
        );

        return resultQuery.reduce((prev, curr) => [...prev, ...curr], []);
    }

    static async batchQuery(operations: ParamsOfQueryOperation[], type: string = this.operationTypes.queryCollection) {
        const operationFragment = Utils.divideBySubLists(operations, this.operationBatchLengthMax);

        const batchQueryResults = await Promise.all(
            operationFragment.map((fragment) => {
                const requestsAreEmpty = [];
                const newFragment = [];
                if (type === this.operationTypes.queryCollection) {
                    fragment.forEach((operation) => {
                        requestsAreEmpty.push(operation === null);
                        if (operation !== null) {
                            newFragment.push(operation);
                        }
                    });
                }
                if (type === this.operationTypes.queryCollection && newFragment.length === 0) {
                    return { results: [] };
                }

                return this.network.net
                    .batch_query({
                        operations: (type === this.operationTypes.queryCollection ? newFragment : fragment).map(
                            (operation) => ({
                                type,
                                ...operation,
                            })
                        ),
                    })
                    .then(({ results }) => {
                        if (type === this.operationTypes.queryCollection) {
                            let i = -1;
                            return {
                                results: requestsAreEmpty.map((isEmpty) => {
                                    if (isEmpty) return [];
                                    i += 1;
                                    return results[i];
                                }),
                            };
                        }
                        return { results };
                    });
            })
        );

        const result: any = batchQueryResults.reduce((prev, curr) => [...prev, ...curr.results], []);

        if (type === this.operationTypes.aggregateCollection) {
            return result.map((count) => this.number(count[0]));
        }

        return result;
    }

    static async batchAggregateCollection(operations: ParamsOfQueryOperation[]) {
        return await this.batchQuery(operations, this.operationTypes.aggregateCollection);
    }

    static async batchAggregateMessages(paramsAr: ParamsOfAggregate[]) {
        return this.batchAggregateCollection(
            paramsAr.map((params) => ({
                collection: this.collections.messages,
                ...params,
            }))
        );
    }

    static async batchAggregateBlocks(paramsAr: ParamsOfAggregate[]) {
        return this.batchAggregateCollection(
            paramsAr.map((params) => ({
                collection: this.collections.blocks,
                ...params,
            }))
        );
    }

    static async batchAggregateTransactions(paramsAr: ParamsOfAggregate[]) {
        return this.batchAggregateCollection(
            paramsAr.map((params) => ({
                collection: this.collections.transactions,
                ...params,
            }))
        );
    }

    static async batchAggregateAccounts(paramsAr: ParamsOfAggregate[]) {
        return this.batchAggregateCollection(
            paramsAr.map((params) => ({
                collection: this.collections.accounts,
                ...params,
            }))
        );
    }

    static arrayFromCONS(cons: any[]): any[] {
        const result = [];
        let item = cons;
        while (item) {
            result.push(item[0]);
            item = item[1];
        }
        return result;
    }

    static isTxSignedByNthCustodian(confMask: number, custodianIndex: number): boolean {
        return Boolean((confMask >> custodianIndex) & 1);
    }

    static async runGetMethods<T = any>({
        account,
        abi,
        methods,
        input,
        needToLog = false,
    }: runGetParams): Promise<T[]> {
        const bocRef =
            methods.length > 1
                ? await this.network.boc
                      .cache_set({
                          boc: account.boc,
                          cache_type: bocCacheTypePinned(account.id),
                      })
                      .then((response) => response.boc_ref)
                : null;

        return Promise.all(
            methods.map((method) =>
                this.runTVMWrapped({
                    account,
                    abi,
                    functionName: method,
                    input,
                    needToLog,

                    bocRef,
                })
                    .catch((e) => console.warn(e))
                    .finally(
                        () =>
                            bocRef &&
                            this.network.boc.cache_unpin({
                                pin: account.id,
                            })
                    )
            )
        );
    }

    static async getAcDocsByCodeHashes(
        codeHashes: string[],
        additionalFields: string = '',
        additionalFilter: any = {}
    ) {
        const acDocs: TONAccountT[] = await this.loadAllInRange({
            multiThread: true,
            maximizeThreads: true,
            collection: this.collections.accounts,
            getFilter: (lastItem: TONAccountT) => ({
                ...(!!lastItem && { id: { lt: lastItem.id } }),
                ...additionalFilter,
                code_hash: { in: codeHashes },
            }),
            order: [{ path: 'id', direction: 'DESC' }],
            result: `${this.runTVMFields} ${additionalFields}`,
        });

        return acDocs;
    }
}
