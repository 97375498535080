import React, { CSSProperties, useCallback } from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import { UIShareManager, UILabel, UITextButton } from '#components';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin, padding } from '#uikit/designCore/styles';

export type CopyableViewProps = {
    classNames?: string;
    value: string;
    copyValue?: string;
    caption?: string;
    displayCaption?: boolean;
    titleRole?: TypographyVariants;
    textStyle?: CSSProperties;
};

const testIDs = {
    copyableValue: (arg) => `copyableValue_${arg || ''}`,
};

const CopyableView = ({
    value,
    copyValue = '',
    caption = '',
    displayCaption = true,
    classNames = '',
    titleRole,
    textStyle,
}: CopyableViewProps) => {
    const onPressId = useCallback(() => {
        UIShareManager.copyToClipboard(copyValue || value, liveLocalized.CopiedToClipboard);
    }, [copyValue, value]);

    return (
        <div className={classnames(classNames, { [padding.verticalNormal]: displayCaption })}>
            <UILabel role={TypographyVariants.ParagraphNote} color={ColorVariants.TextSecondary}>
                {displayCaption && caption}
            </UILabel>
            <UITextButton
                multiLine
                textStyle={textStyle}
                classNames={displayCaption && margin.topTiny}
                titleRole={titleRole || TypographyVariants.PromoSmall}
                titleColor={ColorVariants.TextPrimary}
                title={value}
                onPress={onPressId}
                testID={testIDs.copyableValue(caption)}
            />
        </div>
    );
};

export default CopyableView;
