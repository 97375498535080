import { useState } from 'react';

import { TONAccount, TONClient } from '#TONClient';
import type { AccountsStatistic } from '#TONClient/TONAccount/types';
import { loadTotalBalances } from '#controllers/Lists/helpers';

const useAccountsStatistic = (): [
    AccountsStatistic,
    (isNetworkWithoutStatisticApi: boolean, state?: Partial<AccountsStatistic>) => Promise<void>
] => {
    const [statistic, setStatistic] = useState<AccountsStatistic>({
        accountsCount: 0,
        totalSupply: 0,
        circulatingSupply: 0,
        giversBalance: 0,
    });

    const setNewStatistic = async (
        isNetworkWithoutStatisticApi: boolean = false,
        state?: Partial<AccountsStatistic>
    ) => {
        if (state) {
            setStatistic((prev) => ({ ...prev, ...state }));
            return;
        }

        if (isNetworkWithoutStatisticApi) {
            const [totalSupply, giversBalance] = await loadTotalBalances({}).then((result) => [
                result?.aggregatedBalance || 0,
                result?.aggregatedGiversBalance || 0,
            ]);
            //@ts-ignore doesn`t see method
            const accountsCount = await TONAccount.aggregateAccounts().then((count) => count);

            setStatistic((prev) => ({
                accountsCount,
                totalSupply,
                circulatingSupply: Math.max(totalSupply - giversBalance, 0),
                giversBalance,
            }));

            return;
        }

        return TONClient.queryAccountsStatistic().then((statistic) => {
            if (statistic) setStatistic(statistic);
        });
    };

    return [statistic, setNewStatistic];
};

export default useAccountsStatistic;
