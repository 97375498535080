import { action, makeAutoObservable, observable } from 'mobx';

import { AccountWallet, TokenWallet } from '#TONClient/EVERTip3/types';

import { AccountKWTWalletsByAddressType, AccountWalletsByAddressType } from './types';

class TIP3TokenServiceClass {
    @observable KWTWallets: TokenWallet[] | null = null;
    @observable KWTWalletsByAddress: AccountKWTWalletsByAddressType = {};

    @observable walletsByOwnerAddress: AccountWalletsByAddressType = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setKWTHolders = (holders: TokenWallet[]) => {
        this.KWTWallets = holders;
    };

    @action
    setWalletsForOneOwnerAddress = (ownerAddress: string, wallets: AccountWallet[]) => {
        this.walletsByOwnerAddress[ownerAddress] = wallets;
    };

    @action
    setKWTWalletsByAddress = (address: string, wallets: AccountWallet) => {
        console.error(wallets);
        this.KWTWalletsByAddress[address] = wallets;
    };
}

const TIP3TokenService = new TIP3TokenServiceClass();

export { TIP3TokenService };
