// @flow
import { LocalizationService, prepareLocales } from '#uikit/@tonlabs/localization';
import type { LocalizedInstance } from '#uikit/@tonlabs/localization';

import availableLanguages from '#configs/languages';

import type { TONLocalization } from './types';

const languages = prepareLocales<TONLocalization>(availableLanguages, {});

// $FlowExpectedError
export const liveLocalized: LocalizedInstance<TONLocalization> = new LocalizationService<TONLocalization>({
    languages,
});

// $FlowExpectedError
liveLocalized.localizedStringTemplateForValue = function (value: number, base: string) {
    let localizedString = '';
    if (value === 1) {
        localizedString = this[`${base}01`];
    } else {
        let remainder = value % 100;
        if (remainder < 11 || remainder > 14) {
            remainder %= 10;
            if (remainder === 1) {
                const key = `${base}11`;
                localizedString = this[key];
            } else if (remainder >= 2 && remainder <= 4) {
                const key = `${base}24`;
                localizedString = this[key];
            }
        }
        if (!localizedString) {
            const key = `${base}50`;
            localizedString = this[key];
        }
    }
    return this.formatString(localizedString, value);
};
