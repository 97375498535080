const electorStakeAbi = {
    'ABI version': 1,
    functions: [
        {
            name: 'elector_process_new_stake',
            id: '0x4E73744B',
            inputs: [
                {
                    name: 'queryId',
                    type: 'uint64',
                },
                {
                    name: 'validatorKey',
                    type: 'uint256',
                },
                {
                    name: 'stakeAt',
                    type: 'uint32',
                },
                {
                    name: 'maxFactor',
                    type: 'uint32',
                },
                {
                    name: 'adnlAddr',
                    type: 'uint256',
                },
                {
                    name: 'signature',
                    type: 'bytes',
                },
            ],
            outputs: [],
        },
    ],
};

export default electorStakeAbi;
