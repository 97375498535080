import React from 'react';

import { BalanceView } from '#components';
import type { Tons, TonsNumber } from '#TONClient/TONClient';

type Props = {
    balance?: TonsNumber | Tons;
    notConvert?: boolean;
};

const ShortBalanceView: React.FC<Props> = ({ balance, notConvert }) => (
    <BalanceView balance={balance} notConvert={notConvert} fixFractional fractionalDigits={0} />
);

export default ShortBalanceView;
