import React from 'react';
import { observer } from 'mobx-react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import { themesService } from '@services/ThemesService';
import controllerService from '@services/ControllerService';
import { UIShareManager, UITextButton, LinkWithChild, ActionImage } from '#components';
import { URLSearchParamsType } from '#navigation/types';
import Utils from '#helpers/Utils';
import { common, flex, height, margin } from '#uikit/designCore/styles';
import UIColorPalette from '#uikit/designCore/color/pallete';

type Props = {
    navigationPath: string;
    params?: URLSearchParamsType;
    title?: string | number;
    truncTitle?: boolean;
    classNames?: string;
};

const LinkWithCopy: React.FC<Props> = ({ title, classNames, navigationPath, params, truncTitle = true }) => {
    const link = (
        <LinkWithChild navigationPath={navigationPath} params={params}>
            <UITextButton
                {...(title && {
                    title: truncTitle ? Utils.truncText(`${title}`, controllerService.isNarrow) : title,
                })}
            />
        </LinkWithChild>
    );

    return (
        <div className={classnames(common.displayFlex, flex.alignItemsCenter, height.littleCell, classNames)}>
            {link}
            {!!title && (
                <ActionImage
                    Img={ContentCopyIcon}
                    fill={
                        themesService.isDarkTheme ? UIColorPalette.text.darkPrimary : UIColorPalette.text.lightPrimary
                    }
                    fillOnHover={
                        themesService.isDarkTheme ? UIColorPalette.text.darkAccent : UIColorPalette.text.lightAccent
                    }
                    onClick={() =>
                        UIShareManager.copyToClipboard(title ? `${title}` : '', liveLocalized.CopiedToClipboard)
                    }
                    className={margin.leftDefault}
                    fontSize="small"
                />
            )}
        </div>
    );
};

export default observer(LinkWithCopy);
