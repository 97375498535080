// @flow
import React from 'react';

import type { FilterPropObj } from '#TONClient/TONFilterTags';
import type { FilterValues } from '#TONClient/TONFilter';
import { TONAccount, EVERBlock, TONClient, TONMessage, EVERTransaction } from '#TONClient';
import { result as EVERTransactionResult } from '#TONClient/EVERTransaction/helpers';
import TONFilter from '#TONClient/TONFilter';
import type { PlaygroundArgs } from '#components/TagFilter';
import EnvManager from '#helpers/EnvManager';
import Utils from '#helpers/Utils';
import { newResult as EVERBlockNewResult } from '#TONClient/EVERBlock/helpers';
import { result as EVERMessageResult } from '#TONClient/TONMessage/helpers';
import { liveLocalized } from '@services/LocalizationService';

export const getDefaultFilterValue = (filterProps: ?FilterPropObj = {}, key: string) => ({
    value: filterProps ? filterProps[key]?.defaultValue || null : null,
    list: filterProps ? filterProps[key]?.list || [] : [],
    strict: filterProps ? filterProps[key]?.strict || false : false,
});

export const getDefaultFilterValues = (filterProps: ?FilterPropObj) => {
    if (!filterProps) return {};
    const result = {};
    Object.keys(filterProps || {}).forEach((key) => {
        result[key] = getDefaultFilterValue(filterProps, key);
    });

    return result;
};

export const getFilterValuesFromURLParams = (urlParams: { [string]: string }, filterValues?: { [string]: any }) => {
    const result = {};
    Object.keys(urlParams || {}).forEach((key) => {
        let value = urlParams[key];

        if (!!value && !isNaN(value) && typeof value !== 'boolean') {
            value = TONClient.number(value);
        }
        if (!!value && key === 'shard') {
            const shardValue = value.split(':');
            value = { shard: shardValue[1], workchain_id: TONClient.number(shardValue[0]) };
        }
        if (!!value && value === 'true') {
            value = true;
        }

        if (filterValues && filterValues[key]) {
            result[key] = {
                ...filterValues[key],
                value: urlParams[key] !== '' ? value : null,
            };
        } else {
            result[key] = {
                value: urlParams[key] !== '' ? value : null,
                list: [],
            };
        }
    });

    return result;
};

const getFormattedStringForUrlParamForPlayground = (key, value) => {
    if (
        ['path', 'shard', 'balance', 'src', 'dst', 'value', 'account_addr', 'balance_delta', 'block_id', 'id'].includes(
            key
        )
    ) {
        return `"${value}"`;
    }

    return value;
};

export const getParamForPlaygroundFromQueryFilter = (filter: { [key: string]: any }): string => {
    if (!filter) return '';

    return Object.keys(filter).reduce((prev, curr) => {
        const filterVal = filter[curr];
        if (!filterVal) return prev;
        const filterValStr = Object.keys(filterVal).reduce((prev2, curr2) => {
            if (!filterVal[curr2] && filterVal[curr2] !== 0) return prev2;
            return `${prev2}${curr2}:${getFormattedStringForUrlParamForPlayground(curr, filterVal[curr2])} `;
        }, '');

        return `${prev}${curr}:{${filterValStr}}`;
    }, '');
};

export const getParamForPlaygroundFromQueryOrder = (orderBy: any): string => {
    return orderBy.reduce((prev, curr) => {
        const orderValue = Object.keys(curr).reduce(
            (prev2, curr2) => `${prev2}${curr2}:${getFormattedStringForUrlParamForPlayground(curr2, curr[curr2])} `,
            ''
        );

        return `${prev}{${orderValue}}`;
    }, '');
};

export const getFormattedStringFromQueryFilter = (
    filter: { [key: string]: any },
    tabsCount: number,
    tabChar: string = ' '
): string => {
    let tabs = '\n';
    for (let i = 0; i < tabsCount; i += 1) {
        tabs += tabChar;
    }

    return Object.keys(filter).reduce((prev, curr, index) => {
        const filterVal = filter[curr];
        if (!filterVal) return prev;

        const tabsInside = tabs + tabChar;
        const filterValStr = Object.keys(filterVal).reduce((prev2, curr2) => {
            if (!filterVal[curr2] && filterVal[curr2] !== 0) return prev2;
            const val = getFormattedStringForUrlParamForPlayground(curr, filterVal[curr2]);
            const valFormatted = `${val}`.length >= 25 ? Utils.truncText(val) : val;

            return `${prev2}${tabsInside}${curr2}: ${valFormatted}`;
        }, '');

        if (index === 0) {
            return `${curr}: {${filterValStr}${tabs}}`;
        }

        return `${prev}${tabs}${curr}: {${filterValStr}${tabs}}`;
    }, '');
};

export const getFormattedStringFromQueryOrder = (orderBy: any, tabsCount: number, tabChar: string = ' '): string => {
    let tabs = '\n';
    for (let i = 0; i < tabsCount; i += 1) {
        tabs += tabChar;
    }

    const tabsInside = tabs + tabChar;
    return orderBy.reduce((prev, curr) => {
        const orderValue = Object.keys(curr).reduce(
            (prev2, curr2) =>
                `${prev2}${tabsInside}${curr2}: ${getFormattedStringForUrlParamForPlayground(curr2, curr[curr2])}`,
            ''
        );

        return `${prev}{${orderValue}${tabs}}`;
    }, '');
};

const getOrderByFromUrlParam = (urlParam: string = '') => {
    const queryOrderStr = decodeURIComponent(urlParam) || '';

    if (!queryOrderStr || queryOrderStr.length === 0) {
        return undefined;
    }

    const result = [];
    queryOrderStr.split('{').forEach((item) => {
        const orderBy = item;
        const path = orderBy.split('"')[1];
        const direction = orderBy.indexOf('DESC') !== -1 ? 'DESC' : 'ASC';

        if (!path || !direction) return;
        result.push({
            path,
            direction,
        });
    });

    return result;
};

const getQueryFilterFromUrlParam = (urlParam: string = '') => {
    const queryFilterStr = decodeURIComponent(urlParam) || '';
    const result = {};

    if (!queryFilterStr || queryFilterStr.length === 0) {
        return undefined;
    }

    queryFilterStr.split('}').forEach((item) => {
        const key = (item.split(':')[0] || '').replace(' ', '').replace(',', '');
        if (!key) return;

        const isStringValue = [
            'balance',
            'balance_other',
            'balance_delta',
            'balance_delta_other',
            'value',
            'value_other',
            'shard',
        ].includes(key);

        const filterValueStr = item.split('{')[1] || '';

        const arFields =
            filterValueStr.indexOf('notIn') === -1 && filterValueStr.indexOf('in') === -1
                ? filterValueStr.split(',')
                : [filterValueStr];
        result[key] = arFields.reduce((prev, curr) => {
            const key2 = curr.split(':')[0];
            if (!key2) return prev;

            let value: any = curr.split(`${key2}:`)[1];
            value = (value !== undefined ? value : '').replace(' ', '').replace('"', '').replace('"', '');

            if (!!value && !isNaN(value) && !isStringValue && typeof value !== 'boolean') {
                value = TONClient.number(value);
            }

            if (!!value && value === 'true') {
                value = true;
            }
            if (!!value && value === 'false') {
                value = false;
            }

            if (typeof value === 'string' && value.indexOf('[') !== -1) {
                value = value
                    .replace('[', '')
                    .replace(']', '')
                    .split(',')
                    .map((val) => val.replace(' ', '').replace('"', '').replace('"', ''));
            }

            return {
                ...prev,
                [key2]: value,
            };
        }, {});
    });

    return result;
};

export type ParamsForPlayGround = {
    collection: string,
    filter: string,
    orderBy: string,
    result: string,
    limit?: number,
};

export const getUrlParamsForPlayground = async (args: PlaygroundArgs): Promise<ParamsForPlayGround> => {
    const { limit } = args;
    let { collection } = args;
    let filter = {};
    let orderBy = [];
    let result = 'id';

    if (collection === 'blocks') {
        filter = EVERBlock.filter(args);
        orderBy = EVERBlock.orderBy(args);
        result = EVERBlockNewResult(args);
    } else if (collection === 'accounts') {
        filter = TONAccount.filter(args);
        orderBy = TONFilter.orderBy(args, 'balance');
        result = TONAccount.result(args);
    } else if (collection === 'messages') {
        filter = await TONMessage.filter(args, args.filterValues?.srcDst?.value === 'Dst' ? 'dst' : 'src');
        orderBy = TONFilter.orderBy(args, 'created_at');
        result = EVERMessageResult(args);
    } else if (collection === 'transactions') {
        filter = await EVERTransaction.filter(args);
        orderBy =
            args.filterValues?.accountId?.value || args.filterValues?.accountId
                ? TONFilter.orderBy(args, 'lt')
                : TONFilter.orderBy(args, ['now', 'account_addr', 'lt']);
        result = EVERTransactionResult();
    } else {
        collection = '';
    }

    if (!Object.keys(filter || {}).length) {
        // eslint-disable-next-line
        filter = args?.queryParams;
    }

    const filterStr = getParamForPlaygroundFromQueryFilter(filter);
    const orderByStr = getParamForPlaygroundFromQueryOrder(orderBy);

    return {
        collection,
        network: EnvManager.getNetwork(),
        filter: filterStr,
        orderBy: orderByStr,
        result,
        limit,
    };
};

export const getUrlParams = (): { [key: string]: any } => {
    const params = {};
    const parts = location.search.substring(1).split('&');

    (parts || []).forEach((part) => {
        const nv = part.split('=');
        if (!nv[0]) return;

        params[decodeURIComponent(nv[0])] = decodeURIComponent(nv[1]);
    });

    return params;
};

export const getQueryParams = (params: { [string]: string }) => {
    const orderBy = getOrderByFromUrlParam(params.orderBy);
    const filter = getQueryFilterFromUrlParam(params.filter);

    let defaultResult = 'id';
    const collection = decodeURIComponent(params.collection);

    if (collection === 'blocks') {
        defaultResult = EVERBlockNewResult({ needAdditionalFields: true });
    } else if (collection === 'accounts') {
        defaultResult = TONAccount.result({});
    } else if (collection === 'messages') {
        defaultResult = EVERMessageResult({ needAdditionalFields: true });
    } else if (collection === 'transactions') {
        defaultResult = EVERTransactionResult({ needAdditionalFields: true });
    }

    return {
        result: params.result ? decodeURIComponent(params.result) : defaultResult,
        collection,
        orderBy,
        filter,
        limit: params.limit || TONClient.itemsLoadingLimit,
    };
};

export const isFilterActive = (filterValues?: FilterValues): boolean => {
    if (!filterValues) return false;
    return !!Object.keys(filterValues || {}).find((key) => filterValues[key]?.value !== null);
};

export const getAverageBlockTimeStr = (time: number, fractionalDigits: number = 2): string =>
    time
        ? `${liveLocalized.amountToLocale(parseFloat(time.toFixed(fractionalDigits)))}
        ${liveLocalized.seconds.substring(0, 3)}
        `
        : '';
