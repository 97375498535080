// @flow
import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { TONAccount, TONContract, TONFilterTags } from '#TONClient';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import type { TONContractT } from '#TONClient/TONContract/types';
import type { FilterValues } from '#TONClient/TONFilter';
import type { GetAccountsArgs, TONAccountT } from '#TONClient/TONAccount/types';
import { AccountView } from '#components';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import controllerService from '@services/ControllerService';
import TONClient from '#TONClient/TONClient';
import TONFilter from '#TONClient/TONFilter';
import { useParams } from '#navigation/hooks/useParams';

const ContractComponent = () => {
    const ref = useRef(null);
    const [contract, setContract] = useState<?TONContractT>(null);

    const [params] = useParams();
    const { codeHash } = params;

    // Getters
    const { totalBalances, contractsCount, name } = contract || {};

    const details = [
        DetailsRow.crystals(liveLocalized.TotalBalances, totalBalances),
        {
            caption: liveLocalized.DeployedContracts,
            value: liveLocalized.amountToLocale(contractsCount?.total || 0),
        },
        {
            caption: liveLocalized.ActiveContractsInPeriod,
            value: liveLocalized.amountToLocale(contractsCount?.active || 0),
        },
        {
            caption: liveLocalized.NewContractsInPeriod,
            value: liveLocalized.amountToLocale(contractsCount?.recent || 0),
        },
    ];

    // Actions
    const onLoadContract = async (filterValues: FilterValues = {}, codeHashParam = codeHash) => {
        controllerService.showSpinnerOverlay();
        try {
            const [newContract] = await TONContract.getContracts({
                filterValues: {
                    ...filterValues,
                    code_hash: {
                        value: codeHashParam,
                    },
                },
            });
            if (!newContract) {
                controllerService.showPageNotFound(liveLocalized.ContractWithThisHashWasntFound);
                return;
            }
            setContract(newContract);
        } catch (e) {
            controllerService.showServiceUnavailable();
        }
        controllerService.hideSpinnerOverlay();
    };

    const itemsLoader = useCallback(
        (argsParam: GetAccountsArgs, filterValues: FilterValues): Promise<TONAccountT[]> => {
            const args: GetAccountsArgs = {
                ...argsParam,
                filterValues: {
                    ...filterValues,
                    maxTime: undefined,
                    minTime: undefined,
                    code_hash: codeHash,
                },
            };

            return TONClient.queryCollectionAccounts(
                TONAccount.filter(args),
                TONAccount.result(args),
                TONFilter.orderBy(args, 'last_paid'),
                args?.limit
            );
        },
        [codeHash]
    );

    return (
        <DetailsScreen
            ref={ref}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            needTagFilterContainerAboveTitle
            title={name ? `${name} ${liveLocalized.Details.toLowerCase()}` : liveLocalized.SmartContractDetails}
            listTitle={liveLocalized.Accounts}
            filterProps={TONFilterTags.getContractProps(false)}
            needToCallOnChangeFilterCustomOnFocus
            hasDefaultFilterValuesDefined
            collectionConfigs={AccountView.getCollectionConfigs()} // this.state.totalBalance
            copyableViewData={{
                value: codeHash,
                caption: liveLocalized.TONAccount.code_hash,
            }}
            details={details}
            needListInDetails
            itemsLoader={itemsLoader}
            onChangeFilterCustom={(filterValues) => {
                onLoadContract(filterValues);
            }}
        />
    );
};

const Contract = observer(ContractComponent);

export { Contract };
