import { otherCurrencyQuery } from '../TONFields';
import TONClient from '../TONClient';

const { formatDEC } = TONClient;

export const shortFields = `id balance${formatDEC} last_paid`;

export const additionalFields = `
    acc_type
    due_payment${formatDEC}
    last_trans_lt${formatDEC}
    balance_other ${otherCurrencyQuery}
    tick
    tock
    code_hash
    data_hash
    library_hash
`;

export const binaryFields = 'code data library proof boc';
