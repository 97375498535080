// @flow
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import controllerService from '@services/ControllerService';
import { TabView } from '#components';
import type { TabViewPage } from '#components/TabView/UITabView';
import { margin } from '#uikit/designCore/styles/margin';

type Props = {
    pages: TabViewPage[],
    initialIndex?: number,
    onChangeTab?: (index: number) => void,
};

const DetailsTabView = ({ pages, initialIndex, onChangeTab }: Props) => (
    <TabView
        onChangeTab={onChangeTab}
        containerClassNames={classNames(margin.topGreat, controllerService.reactContentClassNames)}
        pages={pages}
        initialIndex={initialIndex}
    />
);

export default observer(DetailsTabView);
