import { TONClient } from '#TONClient';
import { NOT_FOUND, SUCCESS } from '#TONClient/statuses';
import { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import type { FilterValues } from '#TONClient/TONFilter';

import { TransferDetailsType, AccountTransfer } from './types';
import { loadAccountTransfers, loadTransferByMessageID } from './loaders';

class EVERTransfer {
    private static initLog(str: string | number, params?: any) {
        return TONClient.initLog('EVERTransfer', str, params);
    }

    public static getTransferByMessageID = async (messageID: string): Promise<TransferDetailsType | null> => {
        const log = EVERTransfer.initLog('Transfer by message ID', { messageID });
        const errorLog = (e: unknown) => log.error(e);

        const transfer = await loadTransferByMessageID(messageID, errorLog);

        if (transfer) {
            log.debug(SUCCESS, transfer);
            return transfer;
        } else {
            log.debug(NOT_FOUND);
            return null;
        }
    };

    static getAccountTransfers = async (
        accountID: string,
        args: GetTransactionsArgs,
        filterValues: FilterValues
    ): Promise<AccountTransfer[] | null> => {
        const log = EVERTransfer.initLog('Account transfers', { accountID, args, filterValues });
        const errorLog = (e: unknown) => log.error(e);

        const transfers = await loadAccountTransfers(accountID, args, filterValues, errorLog);

        if (transfers) {
            log.debug(SUCCESS, transfers);
            return transfers;
        } else {
            log.debug(NOT_FOUND);
            return null;
        }
    };
}

export { EVERTransfer };
