import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';
import localization from '#configs/languages';
import { StaticDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';

import { liveLocalized } from '@services/LocalizationService';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { UITextButton } from '#components';

import './DateTimePicker.scss';

const monthsLocalization = (language: string) => [
    localization[language].DateTimePicker.monthNames['0'],
    localization[language].DateTimePicker.monthNames['1'],
    localization[language].DateTimePicker.monthNames['2'],
    localization[language].DateTimePicker.monthNames['3'],
    localization[language].DateTimePicker.monthNames['4'],
    localization[language].DateTimePicker.monthNames['5'],
    localization[language].DateTimePicker.monthNames['6'],
    localization[language].DateTimePicker.monthNames['7'],
    localization[language].DateTimePicker.monthNames['8'],
    localization[language].DateTimePicker.monthNames['9'],
    localization[language].DateTimePicker.monthNames['10'],
    localization[language].DateTimePicker.monthNames['11'],
];
const getCustomLocalization = (language: string) => ({
    months: monthsLocalization(language),
    monthsShort: monthsLocalization(language),
});

moment.updateLocale('en', {
    week: { dow: 1 },
    ...getCustomLocalization('en'),
});
moment.updateLocale('fr', getCustomLocalization('fr'));
moment.updateLocale('it', getCustomLocalization('it'));
moment.updateLocale('ko', getCustomLocalization('ko'));
moment.updateLocale('ru', getCustomLocalization('ru'));
moment.updateLocale('zh-cn', getCustomLocalization('zh_CN'));

type Props = {
    submitValue: (timestamp: number | null) => void;
    defaultDateTime?: number;
    minDateTime?: number;
    onClose?: () => void;
};

const DateTimePicker: React.FC<Props> = ({ defaultDateTime, minDateTime, submitValue, onClose = () => {} }) => {
    const [value, setValue] = useState<number>(defaultDateTime || 0);

    return (
        <div className={classnames('DateTimePicker', 'MUI')} onClick={(e) => e.stopPropagation()}>
            <header className="DateTimePicker__header">
                <UITextButton
                    title={liveLocalized.Cancel}
                    titleColor={ColorVariants.TextAccent}
                    classNames="header__button"
                    onPress={() => onClose()}
                />
                <UITextButton
                    title={liveLocalized.Clear}
                    titleColor={ColorVariants.TextAccent}
                    classNames="header__button"
                    onPress={() => submitValue(null)}
                />
                <UITextButton
                    title={liveLocalized.Submit}
                    titleColor={ColorVariants.TextAccent}
                    classNames="header__button"
                    onPress={() => submitValue(value / 1000)}
                />
            </header>
            <div className="DateTimePicker__divid er" />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDateTimePicker
                    className="DateTimePicker"
                    label=" "
                    value={value}
                    onChange={(newValue) => {
                        if (newValue === null) return;
                        setValue(newValue.valueOf());
                    }}
                    minDateTime={moment(minDateTime)}
                    ampm={false}
                    closeOnSelect={false}
                    renderInput={(params) => <TextField {...params} />}
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                />
            </LocalizationProvider>
        </div>
    );
};
export default observer(DateTimePicker);
