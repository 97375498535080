import React, { ReactNode } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import classnames from 'classnames';

import { UILabel, UITextButton, BackgroundView } from '#components';
import { UIPureComponent } from '#components/UIActionComponent/UIPureComponent';
import { TONString } from '#TONUtility';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { common, margin, border, width, padding, flex, height } from '#uikit/designCore/styles';

import './UIDetailsSortingTable.scss';

type Value = Maybe<string | number | boolean>;

export type DetailsRow = {
    caption: Maybe<string>;
    value?: Value;
    limit?: number;
    type?: string;
    captionType?: string;
    screen?: string;
    component?: ReactNode;
    comment?: string;
    onPress?: () => void;
    key?: string;
    showAlways?: boolean;
};

export type DetailsList = DetailsRow[];

export type FormatNestedListArgs = {
    list: DetailsList;
    key: string;
    needOffset?: boolean;
};

type Props = {
    narrow?: boolean;
    detailsList: DetailsList;
    containerClassNames?: string;
    onPress?: (details: DetailsRow) => void;
    leftCellClassNames?: string;
    rightCellClassNames?: string;
    rowContainerClassNames?: string;
    rowSeparator?: boolean;
};

export default class UIDetailsTable extends UIPureComponent<Props, {}> {
    static cellType = {
        default: 'default',
        success: 'success',
        action: 'action',
        accent: 'accent',
        error: 'error',
        number: 'number',
        numberPercent: 'numberPercent',
        disabled: 'disabled',
        bool: 'bool',
    };

    static captionType = {
        default: 'default',
        header: 'header',
        bold: 'bold',
        topOffset: 'top-offset',
        boldTopOffset: 'bold-top-offset',
    };

    static defaultProps: Props = {
        detailsList: [],
        rowSeparator: true,
    };

    static testIDs = {
        detailsTextButton: 'detailsTextButton',
    };

    static formatNestedList(args: FormatNestedListArgs | DetailsList, keyParam?: string) {
        let list;
        let key;
        // let needOffset;
        if (args instanceof Array) {
            list = args;
            key = keyParam;
            // needOffset = true;
        } else {
            ({ list, key } = args);
            // needOffset = args.needOffset !== undefined ? args.needOffset : true;
        }

        const generatedKey = key || list[0].caption || '';
        return list.map((item, index) => ({
            ...item,
            key: `${item.key ? `${item.key}-` : ''}${generatedKey}`,
            caption: item.caption,
            captionType:
                item.captionType || (!index ? UIDetailsTable.captionType.header : UIDetailsTable.captionType.default),
        }));
    }

    // Events
    onActionPressed(details: DetailsRow) {
        if (this.props.onPress) {
            this.props.onPress(details);
        }
    }

    // Getters
    getTextStyle(type: Maybe<string>, value: Value) {
        if (type === UIDetailsTable.cellType.success) {
            return {
                color: ColorVariants.TextPositive,
                role: TypographyVariants.ParagraphText,
            };
        } else if (type === UIDetailsTable.cellType.error) {
            return {
                color: ColorVariants.TextNegative,
                role: TypographyVariants.ParagraphText,
            };
        } else if (type === UIDetailsTable.cellType.accent) {
            return {
                color: ColorVariants.TextPrimary,
                role: TypographyVariants.Action,
            };
        } else if (type === UIDetailsTable.cellType.disabled) {
            return {
                color: ColorVariants.TextTertiary,
                role: TypographyVariants.ParagraphText,
            };
        } else if (type === UIDetailsTable.cellType.number) {
            return {
                color: ColorVariants.TextPrimary,
                role: TypographyVariants.MonoText,
            };
        } else if ((type === UIDetailsTable.cellType.bool && !value) || value === false) {
            return {
                color: ColorVariants.TextTertiary,
                role: TypographyVariants.ParagraphText,
            };
        }
        return {
            color: ColorVariants.TextSecondary,
            role: TypographyVariants.ParagraphText,
        };
    }

    // Render
    renderTextCell(value: number | string, details: string) {
        return (
            <UILabel>
                <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.ParagraphNote}>
                    {value}
                </UILabel>
                <UILabel color={ColorVariants.TextSecondary} role={TypographyVariants.ParagraphNote}>
                    {details}
                </UILabel>
            </UILabel>
        );
    }

    renderCell(details: DetailsRow) {
        const { type, value, limit, component, onPress, caption } = details;
        const { color, role } = this.getTextStyle(type, value);

        if (type === UIDetailsTable.cellType.numberPercent && limit && limit !== 0 && typeof value === 'number') {
            const primary = TONString.getNumberString(value);
            const percent = (value / limit) * 100;
            const formattedPercent = TONString.getNumberString(percent);
            const secondary = ` (${formattedPercent} %)`;
            return this.renderTextCell(primary, secondary);
        } else if (type === UIDetailsTable.cellType.action || onPress) {
            return (
                <UITextButton
                    multiLine
                    title={value}
                    onPress={onPress || (() => this.onActionPressed(details))}
                    testID={`table_cell_clickable_${caption || 'default'}_value`}
                />
            );
        } else if (component) {
            return component;
        }

        return (
            <UILabel color={color} role={role} classNames={flex.flex1}>
                {type === UIDetailsTable.cellType.bool || value === true || value === false
                    ? JSON.stringify(value)
                    : value}
            </UILabel>
        );
    }

    renderRows() {
        const { detailsList, leftCellClassNames, rowSeparator, rowContainerClassNames } = this.props;

        return detailsList
            .filter((item) => !!item)
            .map<ReactNode>((item, index) => {
                const { caption, value, captionType, key, showAlways, component, comment } = item;

                const { header, bold, topOffset, boldTopOffset } = UIDetailsTable.captionType;

                if ((value == null || value === '') && !showAlways && !component && captionType !== header) {
                    return null;
                }

                let paddingClassNames;
                if (captionType && [header, topOffset, boldTopOffset].includes(captionType)) {
                    paddingClassNames = classnames(
                        padding.topHuge,
                        comment ? padding.bottomTiny : padding.bottomDefault
                    );
                } else {
                    paddingClassNames = comment ? classnames(padding.topDefault, padding.bottomTiny) : padding.vertical;
                }

                return (
                    <div
                        key={`details-table-row-${caption || ''}-${JSON.stringify(value) || ''}-${key || ''}-${
                            captionType || ''
                        }`}
                    >
                        {index > 0 && rowSeparator && (
                            <BackgroundView
                                color={ColorVariants.LinePrimary}
                                classNames={classnames(border.top, width.full, height.borderHeight)}
                            />
                        )}
                        <div className={classnames(common.displayFlex, rowContainerClassNames, paddingClassNames)}>
                            <div className={classnames(leftCellClassNames, margin.rightDefault, flex.flex1)}>
                                <UILabel
                                    color={ColorVariants.TextPrimary}
                                    role={
                                        captionType && [header, bold, boldTopOffset].includes(captionType)
                                            ? TypographyVariants.HeadlineHead
                                            : TypographyVariants.ParagraphText
                                    }
                                >
                                    {caption}
                                </UILabel>
                            </div>
                            {!(captionType && [header].includes(captionType)) && (
                                <div
                                    data-testid={`table_cell_${caption || 'default'}_value`}
                                    className={classnames(
                                        'UIDetailsSortingTable__cellContainer',
                                        common.displayFlex,
                                        flex.column,
                                        flex.flex2
                                    )}
                                >
                                    {this.renderCell(item)}
                                </div>
                            )}
                        </div>
                        {comment ? (
                            <UILabel
                                color={ColorVariants.TextSecondary}
                                role={TypographyVariants.ParagraphNote}
                                classNames={padding.bottomDefault}
                            >
                                {comment}
                            </UILabel>
                        ) : null}
                    </div>
                );
            });
    }

    render = () => <div className={this.props.containerClassNames}>{this.renderRows()}</div>;
}
