import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import dataService from '@services/DataServices';
import MomentHelper from '#helpers/MomentHelper';
import { Accordion, TabView, TagFilter, UIDetailsTable, ValidatorView } from '#components';
import animationData from '#assets/animations/data2.json';
import type { TONValidatorSet } from '#TONClient/EVERBlock/types';
import type { FilterValues } from '#TONClient/TONFilter';
import { TONFilter, TONFilterTags, TONValidator } from '#TONClient';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import { getDefaultFilterValue, getDefaultFilterValues, getFilterValuesFromURLParams } from '#controllers/helpers';
import ValidatorsList from '#controllers/TabViewLists/ValidatorsList';
import { getValidatorsElectionsTime } from '#controllers/Lists/helpers';
import { margin } from '#uikit/designCore/styles/margin';

import MasterDetails from '../../Details/Block/helpers/MasterDetails';
import ListScreen, { smallAccentTitleRoleObj } from '../ListScreen';

type Validators = [TONValidatorSet | {}, TONValidatorSet | {}, TONValidatorSet | {}];

const emptyMasterConfigWithValidators = {
    p32: { list: [] },
    p34: { list: [] },
    p36: { list: [] },
};

const isTONValidatorSet = (validatorSet: TONValidatorSet | {}): validatorSet is TONValidatorSet => {
    return !!(validatorSet as TONValidatorSet).list;
};

const getValidatorSetWithPlace = (valSet: TONValidatorSet | {}) => {
    if (isTONValidatorSet(valSet)) {
        TONFilter.sortBy(
            valSet.list || [],
            { direction: 'DESC' },
            ValidatorView.externalConfigs.sortingKey,
            ValidatorView.externalConfigs.additionalSortingKey
        );

        return {
            ...valSet,
            list: valSet.list?.map((item, index) => ({ ...item, place: index + 1 })),
        };
    } else return {};
};

const ValidatorsComponent = () => {
    const ref = useRef(null);

    const [tabsItems, setTabsItems] = useState<Validators>([{}, {}, {}]);
    const filterProps = TONFilterTags.getValidatorProps();
    const defaultFilterValues = getDefaultFilterValues(filterProps);
    const [filterValues, setFilterValues] = useState<FilterValues>(defaultFilterValues);

    const [params, setParams] = useParams();
    const { sortDirection } = params;

    // Details
    const {
        electionsStart,
        electionsEnd,
        nextElectionsStart,
        nextElectionsEnd,
        electionsTitle,
    } = getValidatorsElectionsTime(dataService.cleanMasterConfigWithValidators || {});
    const isElectionsInProgress = Date.now() / 1000 >= electionsStart && Date.now() / 1000 <= electionsEnd;
    const { p34 } = dataService.cleanMasterConfigWithValidators || {};
    const { list: p34List } = p34 || {};

    const validatorDetails = [
        {
            ...smallAccentTitleRoleObj,
            title: p34List?.length,
            details: liveLocalized.NumberOfCurrentValidators,
        },
    ];

    const electionDetails = [
        {
            ...smallAccentTitleRoleObj,
            title: electionsTitle,
            details: liveLocalized.ElectionsStatus,
        },
        {
            ...smallAccentTitleRoleObj,
            title: MomentHelper.getTimeDate(electionsStart),
            details: liveLocalized.ElectionsStart,
        },
        {
            ...smallAccentTitleRoleObj,
            title: MomentHelper.getTimeDate(electionsEnd),
            details: liveLocalized.ElectionsEnd,
        },
    ];

    const nextElectionDetails = [
        {
            ...smallAccentTitleRoleObj,
            title: MomentHelper.getTimeDate(nextElectionsStart),
            details: liveLocalized.NextElectionsStart,
        },
        {
            ...smallAccentTitleRoleObj,
            title: MomentHelper.getTimeDate(nextElectionsEnd),
            details: liveLocalized.NextElectionsEnd,
        },
    ];

    const details = controllerService.isNarrow
        ? [validatorDetails[0], electionDetails[0], electionDetails[1], electionDetails[2], ...nextElectionDetails]
        : [validatorDetails[0], electionDetails[0], electionDetails[1], electionDetails[2], ...nextElectionDetails];

    // Pages
    const getPage = (title: string, params: TONValidatorSet | {}, nothingWasFoundMessage?: string) => ({
        title: `${title} ${isTONValidatorSet(params) ? params.list.length || '' : ''}`,
        component: (
            <ValidatorsList
                {...(isTONValidatorSet(params) ? { validatorSet: params } : {})}
                nothingWasFoundMessage={nothingWasFoundMessage}
                onChangeSortDirection={(direction) => setParams({ sortDirection: direction })}
                sortDirectionByDefault={sortDirection}
                {...(isTONValidatorSet(params) ? { totalWeight: params.total_weight } : {})}
            />
        ),
    });

    const pages = [
        getPage(liveLocalized.Prev, tabsItems[0]),
        getPage(liveLocalized.Current, tabsItems[1]),
        getPage(
            liveLocalized.NextShort,
            tabsItems[2],
            isElectionsInProgress ? liveLocalized.NothingWasFound : liveLocalized.WaitingForTheElections
        ),
    ];

    // Components
    const customContentComponent = (
        <Accordion
            classNames={classnames(controllerService.reactContentClassNames, margin.topDefault)}
            title={liveLocalized.ValidatorConfigs}
        >
            <UIDetailsTable detailsList={MasterDetails.validatorConfigs(dataService.masterConfigWithValidators)} />
        </Accordion>
    );

    const filterTabItems = (filterValuesParam: FilterValues) => {
        const config = dataService.masterConfigWithValidators || {};
        if (!config) return;

        const { filterParams } = ValidatorView.externalConfigs;
        const filterValidatorSet = (validatorSet: TONValidatorSet | {}, filterValuesParam: FilterValues) =>
            isTONValidatorSet(validatorSet)
                ? {
                      ...validatorSet,
                      list: TONFilter.filterBy(validatorSet.list, filterValuesParam, filterParams),
                  }
                : {};

        const newTabsItems: Validators = [
            filterValidatorSet(config.p32 || {}, filterValuesParam),
            filterValidatorSet(config.p34 || {}, filterValuesParam),
            filterValidatorSet(config.p36 || {}, filterValuesParam),
        ];

        setTabsItems(newTabsItems);
    };

    const onChangeFilter = (key: string, value: any): void => {
        setFilterValues((prevFilterValues) => {
            const newFilterValues = { ...prevFilterValues };
            newFilterValues[key] = {
                ...(newFilterValues[key] || getDefaultFilterValue({}, key)),
                value,
            };

            filterTabItems(newFilterValues);
            setParams(TONFilter.getValuesFromFilterValues(newFilterValues, true));

            return newFilterValues;
        });
    };

    const resetAllFilers = () => {
        setFilterValues({});
        setParams({}, false);
    };

    const tagFilter = (
        <div className={classnames(controllerService.reactContentClassNames, margin.topDefault)}>
            <TagFilter
                filterProps={filterProps}
                filterValues={filterValues}
                onChange={onChangeFilter}
                resetAllFilers={resetAllFilers}
            />
        </div>
    );

    const customListComponent = (
        <TabView
            containerClassNames={classnames(margin.topDefault, controllerService.reactContentClassNames)}
            pages={pages}
            initialIndex={1}
        />
    );

    // Actions
    const loadItems = async () => {
        if (!dataService.masterConfigWithValidators) {
            controllerService.showSpinnerOverlay();
        }
        const cleanConfig = await TONValidator.getCleanMasterConfig();
        const formattedConfig = await TONValidator.getMasterConfigWithFullValidators();
        if (!cleanConfig && !formattedConfig) {
            dataService.setMasterConfigWithValidators(emptyMasterConfigWithValidators);
            controllerService.hideSpinnerOverlay();
            return;
        }

        const resultConfig = formattedConfig || cleanConfig;
        if (resultConfig) {
            const p32 = getValidatorSetWithPlace(resultConfig.p32 || emptyMasterConfigWithValidators.p32);
            const p34 = getValidatorSetWithPlace(resultConfig.p34 || emptyMasterConfigWithValidators.p34);
            const p36 = getValidatorSetWithPlace(resultConfig.p36 || emptyMasterConfigWithValidators.p36);

            const formattedConfigWithSortedValidatorSetLists = {
                ...resultConfig,
                ...(isTONValidatorSet(p32) ? { p32 } : {}),
                ...(isTONValidatorSet(p34) ? { p34 } : {}),
                ...(isTONValidatorSet(p36) ? { p36 } : {}),
            };

            dataService.setMasterConfigWithValidators(formattedConfigWithSortedValidatorSetLists);
        } else {
            dataService.setMasterConfigWithValidators(emptyMasterConfigWithValidators);
        }

        dataService.setCleanMasterConfigWithValidators(cleanConfig || {});
        controllerService.hideSpinnerOverlay();
    };

    const onFocus = async (filterValuesParam: { [key: string]: string }) => {
        const newFilterValues = getFilterValuesFromURLParams(filterValuesParam);
        setFilterValues({ ...filterValues, ...newFilterValues });
        await loadItems();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onFocus);
    }, [params]);

    useEffect(() => {
        filterTabItems(filterValues);
    }, [dataService.masterConfigWithValidators]);

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.Validators}
            details={details}
            animationData={animationData}
            realtimeUpdated={false}
            customListComponent={customListComponent}
            beforeTitleComponent={tagFilter}
            needTagFilterContainerAboveTitle={false}
            customContentComponent={customContentComponent}
        />
    );
};

const Validators = observer(ValidatorsComponent);

export { Validators };
