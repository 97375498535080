import React from 'react';

import { liveLocalized } from '@services/LocalizationService';
import { TransferDetailsType } from '#TONClient/EVERTransfer/types';
import { BalanceView } from '#components';

import DetailsRowHelper from '../DetailsRowHelper';

const getDetails = (transfer: TransferDetailsType | null) => {
    const { tokenSymbol, tokenDecimals, transferType, fromHolderAddress, toHolderAddress, value, timestamp } =
        transfer || {};

    return [
        DetailsRowHelper.tokenSymbol(liveLocalized.Symbol, tokenSymbol || '-'),
        {
            caption: liveLocalized.TokenDecimals,
            value: tokenDecimals,
        },
        DetailsRowHelper.tokenSymbol(
            liveLocalized.TransferType,
            transferType ? liveLocalized.transferType[transferType] : '-'
        ),
        DetailsRowHelper.accountLinkWithCopy(liveLocalized.From, fromHolderAddress || '-'),
        DetailsRowHelper.accountLinkWithCopy(liveLocalized.To, toHolderAddress || '-'),
        {
            caption: liveLocalized.Value,
            component: (
                <BalanceView
                    hideIcon
                    hex={false}
                    balance={value}
                    notConvert
                    fixFractional
                    fractionalDigits={tokenDecimals || 6}
                />
            ),
        },
        DetailsRowHelper.getTimeAndDate(timestamp),
    ];
};

export { getDetails };
