// @flow
import type { StakeAndBonuses, TONValidatorT, ValidatorRootFields, ValidatorType } from '#TONClient/TONValidator/types';
import type { TONMasterConfig, TONValidatorSet } from '#TONClient/EVERBlock/types';
import type { Profile } from '@Firebase/FBProfiles';
import { getRandomName } from '#helpers/RandomNames';
import LinkManager from '#helpers/LinkManager';
import { TONClient, TONMessage, TONValidator } from '#TONClient';
import type { TONMessageT } from '#TONClient/TONMessage/types';

export const getElectorMessagesArgs = (config: TONMasterConfig, utime_since: ?number) => {
    if (!utime_since) return {};

    return {
        order: [{ path: 'created_at', direction: 'DESC' }],
        result: TONMessage.dstTransactionFields,
        collection: TONClient.collections.messages,
        getFilter: async (lastItem: TONMessageT) => ({
            ...(await TONMessage.filter({
                lastItem,
                filterValues: {
                    minTime: utime_since - (config.p15?.elections_start_before || 0),
                    maxTime: utime_since - (config.p15?.elections_end_before || 0),
                },
                direction: 'DESC',
            })),
            dst: { eq: TONClient.addresses.elector },
            value: { gt: '1000000000' },
            body: { gt: null },
        }),
        multiThread: true,
        log: TONValidator.initLog('ElectorMessages'),
    };
};

export const convertWeight = (weight: number | string) => parseInt(`${weight}`.substr(2), 16) / 2 ** 60;

const formatter = (val: TONValidatorT, { stake, bonuses }: StakeAndBonuses = {}): TONValidatorT => {
    const weight = val.weight ? convertWeight(val.weight) : null;
    return {
        ...val,
        weight,
        bonuses: bonuses && weight ? bonuses * weight + 0.0000000001 : null,
        stake: stake && weight ? stake * weight + 0.0000000001 : null,
    };
};

export const formatMasterConfigValidators = (
    stakesAndBonuses: { [number]: StakeAndBonuses },
    p36WithElectionParticipantsDefaultRound: TONValidatorSet,
    validatorsMode: boolean,
    config: TONMasterConfig
): TONMasterConfig => {
    const formatValidators = (param: TONValidatorSet) => {
        const { list = [] } = param || {};
        return list
            .map<TONValidatorT>((item) => formatter(item, stakesAndBonuses[param?.utime_since]))
            .sort((a: TONValidatorT, b: TONValidatorT) => Number(b.weight) - Number(a.weight));
    };
    const formatParam = (param: TONValidatorSet) => {
        const stakeAndBonus = stakesAndBonuses[param?.utime_since] || {};
        return {
            ...param,
            list: formatValidators(param),
            total_stake: stakeAndBonus.stake,
            bonuses: stakeAndBonus.bonuses,
        };
    };

    const { p32, p34, p36 } = config;

    const p36WithElectionParticipants = {
        ...p36WithElectionParticipantsDefaultRound,
        utime_until: p36WithElectionParticipantsDefaultRound.utime_since + config.p15?.validators_elected_for,
    };

    return {
        ...config,
        p32: p32 ? formatParam(p32) : undefined,
        p34: p34 ? formatParam(p34) : undefined,
        p36: p36 || !validatorsMode ? (p36 ? formatParam(p36) : p36) : p36WithElectionParticipants,
    };
};

const getSeedFromValidatorWallet = (validatorWallet: string) => {
    return validatorWallet.split(':')[1] || validatorWallet;
};

export const addProfileAndValidatorId = <T>(
    doc: { ...T, validatorWallet: ?string, dePoolAddress?: string, type: ?ValidatorType },
    profiles: { [string]: Profile }
): T & ValidatorRootFields & Profile => {
    const validatorId = (doc.type === TONValidator.types.single ? doc.validatorWallet : doc.dePoolAddress) || '';
    const validatorWallet = doc.validatorWallet || '';
    const seedStr = getSeedFromValidatorWallet(validatorWallet);

    return {
        ...doc,
        validatorId,
        validatorWallet,
        name: profiles[seedStr] ? profiles[seedStr].name : getRandomName(seedStr, false),
        avatar: profiles[seedStr] ? profiles[seedStr].avatar : LinkManager.getAvatar(seedStr, false),
    };
};
