import { TONLog } from '#TONUtility';
import type { TONMasterConfig } from '#TONClient/EVERBlock/types';
import EnvManager from '#helpers/EnvManager';

import FBTemplate from './FBTemplate';
import { netKeys } from 'constants/constants';

const log = new TONLog('FBValidators');

export default class FBValidators extends FBTemplate {
    static collectionName = 'validators';
    static subCollections = {
        validators: 'validators',
    };

    static lastMasterConfigWithValidators: TONMasterConfig | null = null;

    static getNetworkDoc() {
        try {
            return FBValidators.getCollection().doc(
                {
                    [netKeys.mainnet]: netKeys.mainnet,
                    [netKeys.rustnet]: netKeys.rustnet,
                    [netKeys.net]: 'dev',
                }[EnvManager.getNetwork()]
            );
        } catch (e) {
            return null;
        }
    }

    static getMasterConfigDoc(docName: string) {
        try {
            return FBValidators.getNetworkDoc().collection(FBValidators.subCollections.validators).doc(docName);
        } catch (e) {
            return null;
        }
    }

    static async loadMasterConfigWithFullValidators() {
        try {
            const [p32DocRef, p34DocRef, p36DocRef] = await Promise.all([
                FBValidators.getMasterConfigDoc('p32').get(),
                FBValidators.getMasterConfigDoc('p34').get(),
                FBValidators.getMasterConfigDoc('p36').get(),
            ]);

            const result = {
                p32: p32DocRef.data(),
                p34: p34DocRef.data(),
                p36: p36DocRef.data(),
            };

            log.debug('Validator sets were loaded from cache', result);
            return result;
        } catch (e) {
            return null;
        }
    }

    static async getMasterConfigWithFullValidators(): Promise<any> {
        if (FBValidators.lastMasterConfigWithValidators === null) {
            FBValidators.lastMasterConfigWithValidators = await FBValidators.loadMasterConfigWithFullValidators();
        }

        return FBValidators.lastMasterConfigWithValidators;
    }
}
