import React, { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { TONFilterTags } from '#TONClient';
import type { FilterProp } from '#TONClient/TONFilterTags';
import { projectStartDate } from '#components/TagFilter/constants';
import { Popover } from '#components';
import UIButton, { ButtonStyle } from '#components/UIButton/UIButton';
import PopoverMenu from '#components/Popover/PopoverMenu/PopoverMenu';
import { PopoverRefType } from '#components/Popover/types';
import DateTimePicker from '#components/TagFilter/UIDateTImePicker';
import { margin, padding } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { UIStyle } from '#uikit/designCore';

import { AmountInput } from '../AmountInput';
import DetailsInput from '../DetailsInput';

import './Tag.scss';
import { Dialog } from '@mui/material';
import { Switcher } from '#components/TagFilter/Switcher';

type Props = {
    propKey: string;
    filterValue: any;
    showDefault: boolean;
    editable: boolean;
    filterPropItem: FilterProp | null;
    onChangeValue: (any) => void;
};

const { propTypes } = TONFilterTags;

const tagTestIDs = {
    filterButton: (title: string) => `filterButton_${title}`,
};

const Tag = ({
    filterValue = null,
    propKey = '',
    showDefault = true,
    editable = true,
    filterPropItem = null,
    onChangeValue = () => {},
}: Props) => {
    const propsValue = filterValue?.value == null ? null : filterValue?.value;
    const isActive = propsValue != null && propsValue !== false;

    const inputRef = useRef(null);
    const popoverRef = useRef<PopoverRefType>(null);

    const { type } = filterPropItem || {};

    const onSubmitValue = useCallback(
        (newValue: any) => {
            popoverRef?.current?.close();
            // TODO hide
            if (!newValue && newValue !== 0) {
                onChangeValue(null);
            } else {
                onChangeValue(newValue);
            }
        },
        [popoverRef?.current, onChangeValue]
    );

    const onSubmitDate = useCallback(
        (newValue: any) => {
            if (isNaN(newValue)) {
                return;
            }
            onSubmitValue(newValue);
            onChangeValue(newValue);
            popoverRef?.current?.close();
        },
        [popoverRef?.current, onChangeValue, onSubmitValue]
    );

    const onShowPopover = useCallback(() => {
        setTimeout(() => {
            if (inputRef?.current?.focus) {
                inputRef.current.focus();
            }
        }, 0);
    }, [inputRef]);

    // Getters
    const tagTitle: any = (() => {
        const { type, replaceTitle, renderer = (str: string) => ({ title: str }) } = filterPropItem || {};
        const { title } = liveLocalized.tags[propKey] || {};

        if (type === propTypes.switcher) {
            return replaceTitle
                ? propsValue != null
                    ? renderer(propsValue).title
                    : title
                : `${title}${propsValue != null ? `: ${renderer(propsValue).title}` : ''}`;
        }
        if (type === propTypes.boolean) return title;
        if ([propTypes.double, propTypes.integer, propTypes.signedDouble].includes(type)) {
            return `${title}${!Utils.isNil(propsValue) ? `: ${liveLocalized.amountToLocale(propsValue)}` : ''}`;
        }
        if (type === propTypes.string) return renderer(propsValue || '').title;
        if (type === propTypes.time) {
            const valStr = propsValue ? MomentHelper.getTimeDate(propsValue, MomentHelper.formats.TAG) : '';
            if (propKey === 'minTime') {
                return `${valStr} ${title}\u00A0`;
            }
            return ` ${title}\u00A0${valStr}`;
        }
        return '';
    })();

    // Render
    const renderButton = (onPress?: () => void) => {
        const inactive = !editable || !isActive;

        return (
            <UIButton
                disabled={!editable}
                title={tagTitle}
                testID={tagTestIDs.filterButton(tagTitle)}
                classNames={classNames(margin.rightDefault, margin.bottomSmall, padding.horizontalNormal)}
                titleColor={inactive ? ColorVariants.TextSecondary : ColorVariants.StaticTextPrimaryLight}
                buttonStyle={ButtonStyle.border}
                buttonColor={inactive ? ColorVariants.BackgroundPrimary : ColorVariants.BackgroundAccent}
                buttonSize={UIButton.buttonSize.small}
                buttonShape={UIButton.buttonShape.radius}
                {...(onPress ? { onPress } : {})}
            />
        );
    };

    const switcher = (() => {
        const { renderer = (str: string) => ({ title: str }) } = filterPropItem || {};

        // $FlowExpectedError
        const menuItems = (filterValue?.list || []).map((item) => ({
            onPress: () => onChangeValue(item),
            style: { height: `${UIStyle.height.mediumCell()}px` },
            ...renderer(item),
        }));

        isActive &&
            menuItems.unshift({
                title: liveLocalized.Clear,
                titleColor: ColorVariants.TextNegative,
                onPress: () => onChangeValue(null),
            });

        return <Switcher menuItems={menuItems} renderTrigger={renderButton} />;
    })();

    const popoverComponent = (() => {
        const { type } = filterPropItem || {};
        const { title, fullTitle } = liveLocalized.tags[propKey] || {};
        const placeholder = fullTitle || title;
        // const locale = TONString.getLocaleInfo().dates;
        const inputProps = {
            ref: inputRef,
            needArrow: true,
            value: propsValue,
            label: placeholder,
            placeholder,
            comment: liveLocalized.Clear,
            onPressComment: () => onSubmitValue(null),
            onSubmitEditing: (value) => onSubmitValue(value),
        };

        if ([propTypes.double, propTypes.signedDouble].includes(type)) {
            return <AmountInput {...inputProps} needSign={type === propTypes.signedDouble} />;
        }

        if (type === propTypes.integer) {
            return <DetailsInput {...inputProps} integer />;
        }

        if (type === propTypes.string) {
            return (
                <DetailsInput
                    {...inputProps}
                    isString
                    // onChangeValue={onChangeValueState}
                />
            );
        }

        const now = new Date();
        const defaultDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            ...(propKey === 'maxTime' ? [23, 59, 59] : [0, 0, 0])
        );

        return (
            <>
                <DateTimePicker
                    onClose={() => {
                        popoverRef?.current?.close();
                    }}
                    submitValue={(timestamp: number | null) => {
                        if (timestamp === null) {
                            onSubmitValue(null);
                        } else {
                            onSubmitDate(timestamp);
                        }
                    }}
                    defaultDateTime={filterValue?.value ? new Date(filterValue?.value * 1000) : defaultDate}
                    minDateTime={projectStartDate}
                />
            </>
        );
    })();

    const popover = (
        <Popover
            ref={popoverRef}
            componentClassNames={classNames('Tag Popover__component', {
                [classNames(padding.bottomSmall, padding.default)]: type !== propTypes.time,
            })}
            componentContainerStyles={{ transform: 'translateY(10px)' }}
            narrowComponentClassNames={classNames(padding.topDefault, padding.bottomTiny)}
            component={popoverComponent}
            onShow={onShowPopover}
        >
            {renderButton()}
        </Popover>
    );

    if ((!isActive && !showDefault) || !filterPropItem) {
        return null;
    }

    if (!editable || type === propTypes.boolean) {
        return renderButton(() => onChangeValue(!propsValue));
    }

    if (type === propTypes.switcher) {
        return switcher;
    }

    return popover;
};

export default observer(Tag);
