// @flow
import moment, { Moment } from 'moment';
import { localizationService } from '@services/LocalizationService';

// import EnvManager from '#helpers/EnvManager';
//
// const mainnetStart = 1588856455;

export default class MomentHelper {
    static secondsInDay = 86400;
    static secondsInYear = 31536000;
    static formats = {
        TAG: this.getLocale() === 'en' ? 'MM/DD/YYYY hh:mm a' : 'DD/MM/YYYY HH:mm:ss',
    };

    static isUSLocale() {
        return (window.navigator.userLanguage || window.navigator.language) === 'en-US';
    }

    static isToday(arg: ?number) {
        const m = moment.unix(arg);
        return m.date() === moment().date() && m.month() === moment().month() && m.year() === moment().year();
    }

    static getLocale() {
        return localizationService.language === 'en'
            ? this.isUSLocale()
                ? 'en'
                : 'en-gb'
            : localizationService.language;
    }

    static localize(m: Moment) {
        return m.locale(this.getLocale());
    }

    static getDefaultTimestamp() {
        // const date = new Date();
        // date.setMonth(date.getMonth() - 2);
        // const timestamp = this.getTimestamp(date);

        // mainnetStart > (timestamp || 0)
        // return EnvManager.isTestnet() ? timestamp : null;
        return null;
    }

    static now() {
        return Math.round(new Date().getTime() / 1000);
    }

    static dayBefore() {
        return this.now() - this.secondsInDay;
    }

    static monthBefore(monthsCount: number = 1, fromToday: boolean = true) {
        const date = new Date();
        const currentMonth = date.getMonth();
        date.setMonth(currentMonth - monthsCount);

        if (!fromToday) {
            date.setDate(1);
            date.setHours(0, 0, 0, 0);
        }

        return this.getTimestamp(date);
    }

    static getTimestamp(date: ?string | Date) {
        if (!date) return null;
        return Math.round(new Date(date).getTime() / 1000);
    }

    static getTimeDateFromString(str: string) {
        return this.getTimeDate(this.getTimestamp(str));
    }

    static getDate(arg: ?number) {
        if (!arg) return '';

        const m = moment.unix(arg);
        return this.localize(m).format('ll');
    }

    static getTimeDate(arg: ?number, format?: string) {
        if (!arg) return '';

        const m = moment.unix(arg);
        const formatDate = this.isToday(arg) ? '' : ' · ll';
        return this.localize(m).format(format || `LTS${formatDate}`);
    }

    static getTimeFromNow(arg: number) {
        if (!arg) {
            return '';
        }

        return moment.unix(arg).fromNow();
    }

    static getFullTimeDate(arg: ?number) {
        if (!arg) {
            return '';
        }

        const m = moment.unix(arg);
        return `${this.localize(m).format('LTS')} · ${this.localize(m).format('LL')}`;
    }
}
