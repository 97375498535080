// @flow
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { EVERBlock } from '#TONClient';
import { liveLocalized } from '@services/LocalizationService';

import type { GetBlocksArgs, TONBlockT } from '#TONClient/EVERBlock/types';
import { BlockView } from '#components';

import TabViewList, { getImperativeHandleParamsForTabViewList } from './TabViewList';

type Props = {
    publicKey?: string,
};

const SignedBlocksList = forwardRef<Props, any>(({ publicKey = '' }: Props, Ref) => {
    const ref = useRef(null);

    useImperativeHandle(Ref, () => getImperativeHandleParamsForTabViewList(ref));

    const itemsLoader = useCallback(
        (args: GetBlocksArgs): Promise<TONBlockT[]> => {
            return EVERBlock.getSignedBlocks(args, publicKey);
        },
        [publicKey]
    );

    // Render
    if (!publicKey) return null;
    return (
        <TabViewList
            ref={ref}
            nothingWasFoundMessage={liveLocalized.NothingWasFound}
            realtimeUpdated={false}
            collectionConfigs={BlockView.getCollectionConfigs()}
            itemsLoader={itemsLoader}
        />
    );
});

export default SignedBlocksList;
