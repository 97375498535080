import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Popper } from '@mui/material';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import searchService from '@services/SearchService';
import { LinkWithChild, UILabel, UISeparator } from '#components';
import { useOnClickOutside } from '#helpers/hooks/useOnClickOutside';
import { useCustomNavigate } from '#navigation/hooks/useCustomNavigate';
import { common, flex, height } from '#uikit/designCore/styles';
import { getClassNameWithTheme } from '#uikit/designCore/helpers';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import { SearchResultGroup, SearchResultOption } from '../types';
import { useSearch } from '../hooks/useSearch';
import { SearchRow } from '../SearchRow';
import { InputID } from '../constants';

import './SearchDialog.scss';

const topBarDefaultHeight = 57;
const topBarNarrowHeight = 105;

type SearchDialogProps = {
    isOpen: boolean;
    anchorEl: HTMLElement | null;
};

type SectionLabelProps = {
    title: string;
};

const setSelectedField = (searchResults: SearchResultGroup[], selectedItemIndex) => {
    let currentItemIndex = 0;

    return searchResults.map((group) => ({
        ...group,
        items: group.items.map((item) => {
            const newItem = {
                ...item,
                ...(currentItemIndex === selectedItemIndex ? { selected: true } : {}),
            };

            currentItemIndex += 1;

            return newItem;
        }),
    }));
};

const SectionLabel: React.FC<SectionLabelProps> = ({ title }) => (
    <>
        <UILabel
            classNames={classnames(height.mediumCell, common.displayFlex, flex.alignItemsCenter)}
            color={ColorVariants.TextTertiary}
            role={TypographyVariants.HeadlineLabel}
        >
            {title}
        </UILabel>
        <UISeparator color={ColorVariants.BackgroundSecondary} />
    </>
);

const Option: React.FC<SearchResultOption> = ({ navigationPath, urlParams, title, selected }) => (
    <li>
        <LinkWithChild
            navigationPath={navigationPath}
            params={urlParams}
            onFollow={() => searchService.setSearchExpression('')}
        >
            <SearchRow value={title} accentValue={searchService.searchExpression} isSelected={Boolean(selected)} />
        </LinkWithChild>
    </li>
);

const PopperSection: React.FC<SearchResultGroup> = ({ title, items }) => (
    <section>
        <SectionLabel title={title} />
        <ul>
            {items.map((option) => (
                <Option {...option} key={option.title} />
            ))}
        </ul>
    </section>
);

const SearchDialogComponent: React.FC<SearchDialogProps> = ({ isOpen, anchorEl }) => {
    const dialogRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const navigate = useCustomNavigate();

    useOnClickOutside(contentRef, () => searchService.setIsSearchModalAvailableToBeOpen(false), [`#${InputID}`]);

    const [selectedElementIndex, setSelectedElementIndex] = useState<number>(0);

    const searchResults = useSearch(searchService.searchExpression);
    const items: SearchResultOption[] = searchResults.reduce((acc, group) => [...acc, ...group.items], []);
    const formattedResults = setSelectedField(searchResults, selectedElementIndex);

    const { isNarrow, screenHeight } = controllerService;
    const { searchExpression, isSearchModalAvailableToBeOpen } = searchService;

    const noItemsContent = searchService.isSearchInProgress ? null : (
        <SectionLabel title={liveLocalized.NothingWasFound} />
    );

    useEffect(() => {
        if (items.length && isOpen) {
            const listener = (e: KeyboardEvent) => {
                const scroll = () => {
                    if (selectedElementIndex % 5 === 0) {
                        dialogRef.current?.scroll({ top: (selectedElementIndex / 5) * 200, behavior: 'smooth' });
                    }
                };

                if (e.code === 'ArrowDown') {
                    const newSelectedIndex = selectedElementIndex + 1;

                    if (newSelectedIndex <= items.length) {
                        setSelectedElementIndex(newSelectedIndex);
                        scroll();
                    }

                    return;
                }

                if (e.code === 'ArrowUp') {
                    const newSelectedIndex = selectedElementIndex - 1;

                    if (newSelectedIndex >= 0) {
                        setSelectedElementIndex(newSelectedIndex);
                        scroll();
                    }

                    return;
                }

                const targetElement = items[selectedElementIndex];
                if ((e.code === 'Enter' || e.code === 'NumpadEnter') && isOpen && targetElement) {
                    navigate(targetElement.navigationPath, targetElement.urlParams || {});

                    searchService.setSearchExpression('');

                    return;
                }

                if (e.code === 'Escape') {
                    searchService.setIsSearchModalAvailableToBeOpen(false);
                }
            };

            document.addEventListener('keydown', listener);

            return () => document.removeEventListener('keydown', listener);
        }
    }, [isOpen, items.length, selectedElementIndex, dialogRef.current]);

    useEffect(() => {
        if (selectedElementIndex) {
            setSelectedElementIndex(0);
        }
    }, [searchExpression]);

    return (
        <Popper
            ref={dialogRef}
            open={Boolean(searchExpression) && isSearchModalAvailableToBeOpen}
            anchorEl={anchorEl}
            className={classnames('SearchDialog', getClassNameWithTheme(ColorVariants.BackgroundPrimary))}
            style={{
                width: '100%',
                height: isNarrow ? screenHeight - topBarNarrowHeight : screenHeight - topBarDefaultHeight,
            }}
        >
            <div
                ref={contentRef}
                className={classnames(controllerService.reactContentClassNames, 'SearchDialog__content')}
            >
                {Boolean(items.length)
                    ? formattedResults.map((section) =>
                          section.items.length ? <PopperSection {...section} key={section.title} /> : null
                      )
                    : noItemsContent}
            </div>
        </Popper>
    );
};

const SearchDialog = observer(SearchDialogComponent);

export { SearchDialog };
