import React, { FunctionComponent as F } from 'react';
import classnames from 'classnames';

import EnvManager from '#helpers/EnvManager';
import iconEver from '#assets/ico-ever/ever.svg';
import { common } from '#uikit/designCore/styles/comon';
import { netKeys } from 'constants/constants';

import './EverIcon.scss';

const { mainnet, testnet, net, net2, cinet, privatenet, osnet, rustnet, game0net, customnet } = netKeys;

export enum EverSize {
    Small,
    Big,
}

interface IProps {
    size?: EverSize;
    classNames?: string;
    testID?: string;
}

const RubyCrystal = ({ size }: { size?: EverSize }) => {
    const sizeClassname = size === EverSize.Big ? common.icon : 'smallSymbol';
    const sizeNumber = size === EverSize.Big ? 24 : 18;
    const scale = sizeNumber / 24;
    return <div className={classnames(sizeClassname, 'rubyCrystal')} style={{ borderRadius: `${scale * 2}px` }} />;
};

const EverLogo = ({ size }: { size?: EverSize }) => {
    const sizeClassname = size === EverSize.Big ? common.icon : 'smallSymbol';
    return <img className={sizeClassname} alt="arrow" src={iconEver} />;
};

const TokenComponent =
    {
        [mainnet]: EverLogo,
        [testnet]: RubyCrystal,
        [net]: RubyCrystal,
        [game0net]: RubyCrystal,
        [privatenet]: RubyCrystal,
        [rustnet]: RubyCrystal,
        [net2]: RubyCrystal,
        [cinet]: RubyCrystal,
        [osnet]: RubyCrystal,
        [customnet]: RubyCrystal,
    }[EnvManager.getNetwork()] || RubyCrystal;

const EverIcon: F<IProps> = ({ classNames, testID, size }) => {
    const sizeClassname = size === EverSize.Big ? common.icon : 'smallSymbol';
    return (
        <div className={classnames(classNames, sizeClassname)} data-testid={testID}>
            <TokenComponent size={size} />
        </div>
    );
};

export default EverIcon;
