import React from 'react';
import classnames from 'classnames';

import UILabel from '#components/UILabel';
import { width, flex, margin, common } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

type Props = {
    level?: number;
    bullet?: string;
};

const getBulletWidthClassName = (len: number) => (len >= 6 && width.major) || (len >= 4 && width.great) || width.huge;

const shift = <div className={classnames(margin.rightDefault, margin.leftGreat)} />;

const Bullet: React.FC<Props> = ({ level = 1, bullet = '—', children }) => (
    <div className={classnames(flex.rowWrap, common.displayFlex, margin.topDefault)}>
        {level > 1 && shift}
        <UILabel
            classNames={getBulletWidthClassName(bullet.length)}
            color={ColorVariants.TextTertiary}
            role={TypographyVariants.MonoNote}
        >
            {bullet}
        </UILabel>
        <UILabel classNames={flex.flex1} color={ColorVariants.TextSecondary} role={TypographyVariants.MonoNote}>
            {children}
        </UILabel>
    </div>
);

export { Bullet };
