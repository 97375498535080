import { Maybe } from 'graphql/jsutils/Maybe';

import TONClient from '#TONClient/TONClient';
import { TONAccountT } from '#TONClient/TONAccount/types';
import type { SortDirection } from '#TONClient/TONFilter';
import EnvManager from '#helpers/EnvManager';

const getMasterSeqNoRangeFromNewApi = async (minTime: Maybe<number>, maxTime: Maybe<number>): Promise<string> => {
    const isMinTime = !!(minTime || minTime === 0);
    const isMaxTime = !!(maxTime || maxTime === 0);
    let startTime: number | null = null;
    let endTime: number | null = null;

    if (isMinTime || isMaxTime) {
        //@ts-ignore doesn`t see Maybe type
        const { start, end } = await TONClient.queryMasterSeqNoRange(minTime, maxTime);

        if (start || start === 0) startTime = start;
        if (end || end === 0) endTime = end;
    }

    return isMinTime || isMaxTime
        ? `master_seq_no_range:{${isMinTime ? `start: ${startTime},` : ''} ${isMaxTime ? `end: ${endTime}` : ''}},`
        : '';
};

const FBQueryWrapper = <Query extends Function, DefaultValue>(
    query: Query,
    defaultValue: DefaultValue
): Query | (() => Promise<DefaultValue>) => (EnvManager.isNetworkWithoutAccessToFB ? async () => defaultValue : query);

const loadDetailsBySteps = async <T>(
    loader: (item: TONAccountT) => Promise<T>,
    subLists: TONAccountT[][],
    log,
    currentSublistIndex: number = 0,
    result: T[] = []
): Promise<T[]> => {
    if (currentSublistIndex >= subLists.length) {
        return result;
    }

    const res = await Promise.all(subLists[currentSublistIndex].map((doc) => loader(doc)));
    log.debug(`Sublist ${currentSublistIndex} loaded`, res);

    return loadDetailsBySteps(loader, subLists, log, currentSublistIndex + 1, [...res, ...result]);
};

const getPaginationFiltersString = (direction: SortDirection, limit: number, endCursor?: string): string => {
    const cursorPosition = endCursor ? `${direction === 'DESC' ? 'after' : 'before'}: "${endCursor}",` : '';
    const itemsRange = `${direction === 'DESC' ? 'first' : 'last'}: ${limit},`;

    return `${cursorPosition}${itemsRange}`;
};

const getEndCursor = (direction: SortDirection, lastItemCursor: string, pageEndCursor: string) =>
    direction === 'ASC' ? lastItemCursor : pageEndCursor;

const getOtherFilters = (filters: { value: any; label: string; wrapInBraces?: boolean }[]): string =>
    filters.reduce((acc, { value, label, wrapInBraces = true }) => {
        const preparedValue = wrapInBraces ? `"${value}"` : value;
        return `${acc}${value || value === false ? `${label}: ${preparedValue},` : ''}`;
    }, '');

export {
    getMasterSeqNoRangeFromNewApi,
    FBQueryWrapper,
    loadDetailsBySteps,
    getPaginationFiltersString,
    getEndCursor,
    getOtherFilters,
};
