import React, { useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import UIDetailsTable, { DetailsList } from '#components/UIDetailsTable';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONInMsgDescr, TONOutMsgDescr } from '#TONClient/TONMessage/types';
import { TONClient } from '#TONClient';

type UnifiedMsgDescr = TONInMsgDescr | (TONOutMsgDescr & any);

type Props = {
    item: Maybe<UnifiedMsgDescr>;
    detailsListGetter: ((item: TONOutMsgDescr) => DetailsList) | ((item: TONInMsgDescr) => DetailsList);
};

export const messageDescrViewTestIDs = {
    messageDescrView: 'messageDescrView',
};

const MessageDescrView = ({ item = null, detailsListGetter }: Props) => {
    const [visibleDetails, setVisibleDetails] = useState(false);

    if (!item) return null;

    const { msg_id, in_msg, out_msg, msg_type_name } = item;
    const id = msg_id || out_msg?.msg_id || in_msg?.msg_id;
    // const onPress = id ? { onPress: () => StackNavigator.pushMessageDetails(navigation, { id }) } : {};

    return (
        <>
            <UIDetailsButton
                testID={messageDescrViewTestIDs.messageDescrView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                truncTitle
                title={id || '-'}
                caption={msg_type_name}
                onPress={() => setVisibleDetails(!visibleDetails)}
            />
            {visibleDetails && <UIDetailsTable detailsList={detailsListGetter(item)} />}
        </>
    );
};

MessageDescrView.configs = {
    keyExtractor: ({ in_msg, out_msg, msg_id }: UnifiedMsgDescr): string => {
        return `message-descr-view-${msg_id || out_msg?.msg_id || in_msg?.msg_id || ''}`;
    },
    // TODO
    // need to support complex addresses in_msg?.msg_id || out_msg?.msg_id
    idKey: 'id', // not valid, can be used in EVERList only with direct managing props.items
    newItemName: 'NewMessage',
    collectionName: liveLocalized.Messages,
    collectionKey: TONClient.collections.messages,
    sortingKey: '',
    renderItem: () => null,
};

export default MessageDescrView;
