import React from 'react';
import { observer } from 'mobx-react';

import controllerService from '@services/ControllerService';
import type { TabViewPage } from '#components/TabView/UITabView';
import UIConstant from '#uikit/designCore/constants/index';
import { margin } from '#uikit/designCore/styles/margin';

import UITabView from './UITabView';

type Props = {
    pages: TabViewPage[];
    titleClassNames?: string;
    initialIndex?: number;
    onChangeTab?: (index: number) => void;
    containerClassNames?: string;
};

const doubleOffset = 2 * UIConstant.contentOffset();

const TabView: React.FC<Props> = (props) => {
    const { isNarrow, screenWidth } = controllerService;
    const width = Math.min(screenWidth, UIConstant.elasticWidthHuge());
    const contentWidth = isNarrow ? width : width / 2 + doubleOffset;

    return (
        <UITabView
            {...props}
            pageClassNames={margin.horizontalNegativeOffset}
            indicatorWidth={contentWidth - doubleOffset}
            width={contentWidth}
        />
    );
};

export default observer(TabView);
