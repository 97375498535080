// @flow
import React, { useEffect, useRef, useState } from 'react';
import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';

import { EVERNft } from '#TONClient';
import { NFTRootContracts } from '#TONClient/EVERNft/constants';
import type { NFTItem, NFTItemAdditionalFields } from '#TONClient/EVERNft/types';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import { ExternalLink } from '#components';
import Utils from '#helpers/Utils';
import { height } from '#uikit/designCore/styles/height';

const NFT = () => {
    const ref = useRef(null);

    const [params] = useParams();

    const [token, setToken] = useState<NFTItem>({});
    const [additionalFields, setAdditionalFields] = useState<NFTItemAdditionalFields | null>(null);

    const loadExtraData = async (tokenParam, attemptNum = 1): Promise<NFTItemAdditionalFields> => {
        if (tokenParam.extraData) {
            return tokenParam.extraData;
        }
        if (!tokenParam.url) return null;

        try {
            const response = await fetch(`https://ipfs.itgold.io/ipfs/${tokenParam.url}`);

            const extraData = {};
            if (response?.ok) {
                try {
                    const extraDataJson = await response.json();

                    extraData.description = extraDataJson?.description;
                    extraData.author = extraDataJson?.author;
                    extraData.collection = extraDataJson?.collection;
                    extraData.image = `https://ipfs.itgold.io/ipfs/${extraDataJson?.image}`;
                } catch (e) {
                    extraData.image = `https://ipfs.itgold.io/ipfs/${tokenParam.url}`;
                }
            }

            return extraData;
        } catch (e) {
            console.log('Failed to get ipfs data with error:', e);
            if (attemptNum === 1) {
                console.log('Retry again for url', tokenParam.url);
                return loadExtraData(tokenParam, attemptNum + 1);
            }
        }

        return null;
    };

    const onLoadToken = async ({ id: idParam }) => {
        controllerService.showSpinnerOverlay();
        const result = await EVERNft.getNFT(idParam);
        loadExtraData(result)
            .then((fields) => {
                setAdditionalFields(fields);
            })
            .catch((e) => {
                console.log('Load additionFields failed', e);
            });

        setToken(result);
        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadToken);
    }, [params]);

    const title = `${token?.name || liveLocalized.NFT}`;
    const { marketplace: marketName = '', url: marketUrl = '' } =
        NFTRootContracts.find((item) => item.addr === token?.addrRoot) || {};

    return (
        <>
            <DetailsScreen
                ref={ref}
                title={title}
                copyableViewData={null}
                details={[
                    DetailsRow.accountLinkWithCopy(liveLocalized.Address, token?.addrData),
                    DetailsRow.accountLinkWithCopy(liveLocalized.Owner, token?.addrOwner),
                    {
                        caption: liveLocalized.Market,
                        component: token?.addrData && (
                            <ExternalLink
                                buttonHeightClassName={height.littleCell}
                                openInNewTab
                                href={`${marketUrl}${token?.addrData}`}
                                title={marketName}
                                needArrowIcon
                            />
                        ),
                    },
                    {
                        caption: liveLocalized.Market,
                        value: token?.description,
                    },
                    {
                        caption: liveLocalized.Creator,
                        value: additionalFields?.author,
                    },
                    {
                        caption: liveLocalized.Collection,
                        value: additionalFields?.collection,
                    },
                    {
                        caption: liveLocalized.Description,
                        value: additionalFields?.description,
                    },
                    {
                        caption: liveLocalized.LinkToMedia,
                        component: additionalFields?.image && (
                            <ExternalLink
                                buttonHeightClassName={height.littleCell}
                                openInNewTab
                                href={additionalFields?.image}
                                title={Utils.truncText(additionalFields?.image)}
                                needArrowIcon
                            />
                        ),
                    },
                ]}
                realtimeUpdated={false}
                needMoreDetailsButton={false}
            />
        </>
    );
};

export { NFT };
