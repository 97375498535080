// @flow
import type { Language } from '#uikit/@tonlabs/localization';

import languages from './languages';

type LanguageConfig = {
    name: Language,
    file: File,
    path: string,
};

export type LanguagesConfig = {
    main: LanguageConfig,
    translations: LanguageConfig[],
};

export default class ExtraConfig {
    static giverTDNAddress(): string {
        return '0:5b168970a9c63dd5c42a6afbcf706ef652476bb8960a22e1d8a2ad148e60c0ea';
    }

    static giverAddress(): string {
        // TODO: change for private net!
        return '0:841288ed3b55d9cdafa806807f02a0ae0c169aa5edfe88a789a6482429756a94';
    }

    static isTestnetEnabled() {
        return true;
    }

    static get availableLanguages(): Language[] {
        const { AVAILABLE_LANGUAGES } = process.env;
        return AVAILABLE_LANGUAGES ? JSON.parse(AVAILABLE_LANGUAGES) : ['en', 'ru', 'ko', 'it', 'fr', 'zh_CN'];
    }

    static get enabledLanguages(): Language[] {
        const { ENABLED_LANGUAGES } = process.env;
        return ENABLED_LANGUAGES ? JSON.parse(ENABLED_LANGUAGES) : ['en', 'ru', 'ko', 'it', 'fr', 'zh_CN'];
    }

    static get mainLanguage(): Language {
        return 'en';
    }

    static get languagesConfig(): LanguagesConfig {
        const path = 'src/configs/languages/';
        const translations = ExtraConfig.enabledLanguages.filter((language) => language !== ExtraConfig.mainLanguage);

        return {
            main: {
                name: ExtraConfig.mainLanguage,
                path: path.concat(ExtraConfig.mainLanguage, '.json'),
                file: languages[ExtraConfig.mainLanguage],
            },
            translations: translations.map((language) => ({
                name: language,
                path: path.concat(language, '.json'),
                file: languages[language],
            })),
        };
    }
}
