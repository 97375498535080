// @flow
import React from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import MomentHelper from '#helpers/MomentHelper';
import type { TONValidatorSet } from '#TONClient/EVERBlock/types';
import type { SortDirection } from '#TONClient/TONFilter';
import { TONClient } from '#TONClient';
import { ValidatorView } from '#components';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import TabViewList from './TabViewList';

type Props = {
    convertWeight?: boolean,
    totalWeight?: number,
    validatorSet?: TONValidatorSet,
    keyBlockNum?: number,
    nothingWasFoundMessage?: string,
    sortDirectionByDefault?: SortDirection,
    onChangeSortDirection?: (direction: SortDirection) => void,
};

const ValidatorsList = ({
    convertWeight,
    totalWeight,
    validatorSet,
    keyBlockNum,
    nothingWasFoundMessage,
    sortDirectionByDefault,
    onChangeSortDirection = (direction: SortDirection) => {},
}: Props) => {
    // Getters
    const { utime_until, utime_since } = validatorSet || {};
    const accentDetailsList = [
        {
            titleRole: TypographyVariants.PromoMedium,
            title: utime_since && TONClient.number(utime_since) ? MomentHelper.getTimeDate(utime_since) : '',
            details: liveLocalized.ValidatorsSince,
        },
        {
            titleRole: TypographyVariants.PromoMedium,
            title: utime_since && TONClient.number(utime_since) ? MomentHelper.getTimeDate(utime_until) : '',
            details: liveLocalized.ValidatorsUntil,
        },
    ];
    // Actions

    return (
        <TabViewList
            realtimeUpdated={false}
            nothingWasFoundMessage={nothingWasFoundMessage} // liveLocalized.WaitingForTheElections
            collectionConfigs={ValidatorView.getCollectionConfigs(convertWeight, keyBlockNum, totalWeight)}
            propsItems={validatorSet?.list || []}
            accentDetailsList={accentDetailsList}
            onChangeSortDirection={onChangeSortDirection}
            externalControlConfigs={{
                ...ValidatorView.externalConfigs,
                items: validatorSet?.list || [],
                sortDirectionByDefault: sortDirectionByDefault || ValidatorView.externalConfigs.sortDirectionByDefault,
                isLoading: !validatorSet?.list,
            }}
        />
    );
};

export default observer(ValidatorsList);
