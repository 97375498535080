import React from 'react';
import classnames from 'classnames';

import './styles.scss';

type Props = {
    vertical?: boolean;
    error?: boolean;
    color?: string;
    classNames?: string;
};

const UISeparator: React.FC<Props> = ({ vertical, error, color, classNames }) => {
    const colorClassName = color || (error ? 'Separator__error' : 'Separator__light');
    const sizeClassName = vertical ? 'Separator__vertical' : 'Separator__horizontal';

    return <div className={classnames(sizeClassName, colorClassName, classNames)} />;
};

export default UISeparator;
