import React from 'react';
import classNames from 'classnames';
import { SortDirection } from '@eversdk/core';

import { liveLocalized } from '@services/LocalizationService';
import UIDetailsButton, { UIDetailsButtonCopyTargets, UIDetailsButtonTestIds } from '#components/UIDetailsButton';
import { BalanceView, LinkWithChild, UILabel } from '#components';
import type { TONCollectionConfigs } from '#components/EVERList';
import { TokenWallet } from '#TONClient/EVERTip3/types';
import { KWTRootAddress } from '#TONClient/EVERTip3/constants';
import { paths } from '#navigation/paths';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex } from '#uikit/designCore/styles/flex';
import { common } from '#uikit/designCore/styles/comon';

type Props = {
    item: TokenWallet & { index: number };
    totalSupply: number;
};

const TokenHoldersViewTestIDs = {
    TokenHoldersView: 'TokenHolders-view',
};

const TokenHoldersView = ({ item, totalSupply }: Props) => {
    if (!item) return null;

    const { balance, index, rootAddress, ownerAddress, walletAddress } = item;

    const percentStr = `${((balance / totalSupply) * 100).toFixed(2)}%`;

    const title = rootAddress === KWTRootAddress ? walletAddress : ownerAddress;

    const captionComponent = (
        <div className={classNames(common.displayFlex, flex.column, flex.alignEnd)}>
            <BalanceView
                hideIcon
                hex={false}
                balance={balance}
                notConvert
                fixFractional
                fractionalDigits={balance < 1 && item ? item.decimals : 0}
            />
            <UILabel
                testID={UIDetailsButtonTestIds.secondDetails}
                color={ColorVariants.TextSecondary}
                role={TypographyVariants.ParagraphFootnote}
            >
                {percentStr}
            </UILabel>
        </div>
    );

    return (
        <LinkWithChild
            navigationPath={paths.accounts.details}
            params={{ id: rootAddress === KWTRootAddress ? item.walletAddress : item.ownerAddress }}
        >
            <UIDetailsButton
                testID={TokenHoldersViewTestIDs.TokenHoldersView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                copyValue={title}
                truncTitle
                title={title}
                index={index || index === 0 ? index + 1 : null}
                captionComponent={captionComponent}
            />
        </LinkWithChild>
    );
};

TokenHoldersView.externalConfigs = {
    sortingKey: 'balance',
    sortDirectionByDefault: SortDirection.DESC,
    // filterParams: {
    //     expression: {
    //         ownerAddress: 'ownerAddress',
    //     },
    //     minMax: {
    //         balance: {
    //             min: 'minBalance',
    //             max: 'maxBalance',
    //         },
    //     },
    // },
};

TokenHoldersView.KWTExternalConfigs = {
    sortingKey: 'balance',
    sortDirectionByDefault: SortDirection.DESC,
    filterParams: {
        expression: {
            ownerAddress: 'ownerAddress',
        },
        minMax: {
            balance: {
                min: 'minBalance',
                max: 'maxBalance',
            },
        },
    },
};

TokenHoldersView.getCollectionConfigs = (totalSupply): TONCollectionConfigs => {
    return {
        keyExtractor: ({ walletAddress }: TokenWallet): string => {
            return walletAddress;
        },
        idKey: 'ownerAddress',
        collectionName: liveLocalized.TokenHolders,
        renderItem: ({ item }: { item: any }) => <TokenHoldersView totalSupply={totalSupply} item={item} />,
    };
};

export default TokenHoldersView;
