import React, { useState } from 'react';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import { TONLog } from '#TONUtility';
import UITextButton from '#components/UITextButton';
import type { GetTONItemsArgs, TONCollectionConfigs, EVERItem } from '#components/EVERList/index';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { TONClient } from '#TONClient';
import { ERROR, SUCCESS } from '#TONClient/statuses';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import { defaultItemsLoader } from '#components/EVERList/index';

type Props = {
    collectionConfigs: TONCollectionConfigs;
    items: Maybe<EVERItem[]>;
    testID: string;
    itemsLoader?: (args: GetTONItemsArgs, filterValues?: FilterValues) => Promise<EVERItem[]>;
    sortDirection: SortDirection;
    filterValues: FilterValues;
};

const { itemsLoadingMax } = TONClient;

const log = new TONLog('Export CSV');

const totalLimit = 5000;
let itemsLoadingLimit = itemsLoadingMax;

const ExportCSVButton = ({
    items = null,
    testID = '',
    itemsLoader = defaultItemsLoader,
    collectionConfigs,
    sortDirection,
    filterValues,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [numberLoaded, setNumberLoaded] = useState<number>(0);

    const loadItems = async (
        prevItems: EVERItem[] = [],
        itemsById: { [id: string]: boolean } = {}
    ): Promise<EVERItem[]> => {
        const lastItem = prevItems[prevItems.length - 1];
        let newItems;
        try {
            newItems = await itemsLoader(
                {
                    lastItem,
                    needAdditionalFields: true,
                    limit: itemsLoadingLimit,
                    direction: sortDirection,
                },
                filterValues
            );
        } catch (e) {
            log.error(ERROR, e);
        }

        if (!newItems) {
            itemsLoadingLimit -= 10;
            log.debug(`reduce items loading limit to ${itemsLoadingLimit}`);
            return loadItems(prevItems, itemsById);
        }

        if (!newItems.length) return prevItems;

        // add new items
        let addedCount = 0;
        newItems.forEach((item) => {
            Utils.callIfNotDuplicate(item[collectionConfigs.idKey], itemsById, () => {
                prevItems = [...prevItems, item];
                addedCount += 1;
            });
        });

        if (prevItems.length >= totalLimit || addedCount === 0) {
            return prevItems.slice(0, totalLimit);
        }

        setNumberLoaded((prev) => prev + addedCount);

        if (newItems.length >= itemsLoadingLimit) {
            return loadItems(prevItems, itemsById);
        }

        return prevItems;
    };

    // Events
    const onExportCSV = async () => {
        !items && setLoading(true);
        const tonItems = items || (await loadItems());
        setLoading(false);
        setNumberLoaded(0);

        log.debug(SUCCESS, `${tonItems.length} items`);
        const flatItems = tonItems.map((item) => Utils.flatify(item));

        const date = MomentHelper.getDate(moment().unix());
        const title = `${collectionConfigs?.collectionName || ''}-${date}`;

        const csvExporter = new ExportToCsv({
            filename: title,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            // title: `${liveLocalized.Messages}`, // ${JSON.stringify(this.state.filterValues)
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            title,
        });

        csvExporter.generateCsv(flatItems);
    };

    if (loading) return <UITextButton title={`${numberLoaded} / ${totalLimit}`} disabled />;

    return (
        <UITextButton
            title={liveLocalized.formatString(liveLocalized.ExportTo, '.csv')}
            onPress={onExportCSV}
            testID={testID}
        />
    );
};

export default ExportCSVButton;
