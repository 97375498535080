import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import { UIPureComponent } from './UIPureComponent';
import { common } from '#uikit/designCore/styles/comon';
import { flex } from '#uikit/designCore/styles/flex';

export type UIActionComponentState = {
    tapped: boolean;
    hover: boolean;
};

export type UIActionComponentProps = {
    testID?: string;
    disabled?: boolean;
    showIndicator?: boolean;
    onPress?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

export class UIActionComponent<Props, State> extends UIPureComponent<
    any & UIActionComponentProps,
    any & UIActionComponentState
> {
    constructor(props: any & UIActionComponentProps) {
        super(props);

        this.state = {
            tapped: false,
            hover: false,
        };
    }

    // Events
    onPressIn = () => {
        this.setTapped();
        this.onTappedIn();
    };

    onPressOut = () => {
        this.setTapped(false);
        this.onTappedOut();
    };

    onPress = () => {
        this.onPressed();
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    onMouseEnter = () => {
        this.setHover();
        this.onEnter();
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter();
        }
    };

    onMouseLeave = () => {
        this.setHover(false);
        this.onLeave();
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave();
        }
    };

    // Virtual
    onEnter = (): void => {};

    onLeave = (): void => {};

    onPressed = (): void => {};

    onTappedIn = (): void => {};

    onTappedOut = (): void => {};

    // Setters
    setTapped(tapped: boolean = true) {
        this.setStateSafely({ tapped });
    }

    setHover(hover: boolean = true) {
        this.setStateSafely({ hover });
    }

    // Getters
    isTapped() {
        return this.state.tapped;
    }

    isHover() {
        return this.state.hover;
    }

    isDisabled() {
        return this.props.disabled;
    }

    shouldShowIndicator() {
        return this.props.showIndicator;
    }

    // Render
    // Virtual
    renderContent(): ReactElement<any> {
        if (!this.props.children) {
            throw new Error('Not implemented method `renderContent` of UIActionComponent');
        } else {
            return this.props.children as ReactElement;
        }
    }

    render(): ReactNode {
        const { onPress, testID } = this.props;
        const element = this.renderContent();

        const isDisabled = this.isDisabled() || this.shouldShowIndicator() || !onPress;

        return (
            <div
                className={classNames(common.displayFlex, flex.column)}
                onClick={isDisabled ? undefined : this.onPress}
                onMouseDown={isDisabled ? undefined : this.onPressIn}
                onMouseUp={isDisabled ? undefined : this.onPressOut}
                data-testid={testID}
            >
                {React.cloneElement(element, {
                    onMouseEnter: this.onMouseEnter,
                    onMouseLeave: this.onMouseLeave,
                    style: {
                        ...element.props.style,
                        cursor: 'pointer',
                    },
                })}
            </div>
        );
    }

    static defaultProps;
}

UIActionComponent.defaultProps = {
    testID: '',
    disabled: false,
    showIndicator: false,
    onPress: null,
};
