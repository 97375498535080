// @flow
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { TONAsync, TONLog } from '#TONUtility';
import { LongBalanceView, BalanceView, UIDetailsTable } from '#components';
import { TONValidator, EVERBlock, TONClient } from '#TONClient';
import type { TONValidatorT } from '#TONClient/TONValidator/types';
import SignedBlocksList from '#controllers/TabViewLists/SignedBlocksList';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import StakesList from '#controllers/TabViewLists/StakesList';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import DetailsTabView from '#controllers/Details/components/DetailsTabView';
import { toFixedOrEmpty } from '#controllers/Details/helpers';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import MomentHelper from '#helpers/MomentHelper';

const log = new TONLog('ValidatopropsrDetailsScreen');

const ValidatorComponent = () => {
    const ref = useRef(null);
    const stakesListRef = useRef(null);
    const signedBlocksListRef = useRef(null);

    const [validator, setValidator] = useState<?TONValidatorT>(null);
    const [signedBlocksCount, setSignedBlocksCount] = useState<number>(0);
    const [totalBlocksCount, setTotalBlocksCount] = useState<number>(0);

    const [params] = useParams();
    const { publicKey: publicKeyFromUrlParams } = params;

    const publicKey = publicKeyFromUrlParams || validator?.public_key;
    const nodeId = TONValidator.getNodeId(publicKey);

    // details
    const {
        weight,
        dePoolAddress,
        validatorWallet,
        stakeAt,
        type,
        adnl_addr,
        public_key,
        proxies,
        stakeSent,
        timeSent,
        validatorId,
        bonuses,
        stake,
    } = validator || {};

    const { hex, base64, TONValidator: validatorLocale, NodeID } = liveLocalized;
    const percentWeight = weight
        ? `${liveLocalized.amountToLocale((TONClient.number(weight) * 100).toFixed(2))} %`
        : '';
    const BalanceComponent = controllerService.isNarrow ? BalanceView : LongBalanceView;

    const details = !validator
        ? []
        : [
              {
                  caption: liveLocalized.Type,
                  value: validator?.type,
              },
              !!validatorWallet && DetailsRow.accountLinkWithCopy(liveLocalized.ValidatorWallet, validatorWallet),
              // {
              //     caption: liveLocalized.CurrentSlashingScore,
              //     component: (
              //         <LinkWithChild
              //             navigationPath={PathMap.slashingDetails}
              //             params={{ validatorId }}
              //         >
              //             <UITextButton
              //                 style={UIStyle.height.littleCell()}
              //                 title={currentSlashingScore ? currentSlashingScore.toFixed(3) : '-'}
              //             />
              //         </LinkWithChild>
              //     ),
              // },
              ...(type === TONValidator.types.dePool
                  ? [
                        DetailsRow.dePoolLinkWithCopy(liveLocalized.DePoolDetails, dePoolAddress),
                        DetailsRow.accountLinkWithCopy(
                            liveLocalized.formatString(liveLocalized.ProxyAddress, 1),
                            proxies && proxies[0]
                        ),
                        DetailsRow.accountLinkWithCopy(
                            liveLocalized.formatString(liveLocalized.ProxyAddress, 2),
                            proxies && proxies[1]
                        ),
                    ]
                  : []),
              {
                  caption: liveLocalized.ElectionsID,
                  value: stakeAt,
              },
              {
                  caption: liveLocalized.StakeSent,
                  component: <BalanceComponent balance={stakeSent} fixFractional />,
              },
              {
                  caption: liveLocalized.TimeSent,
                  value: MomentHelper.getTimeDate(timeSent),
              },
              stake && {
                  caption: liveLocalized.StakeAccepted,
                  component: <BalanceComponent balance={stake} notConvert fixFractional />,
              },
              bonuses && {
                  caption: liveLocalized.Reward,
                  component: <LongBalanceView balance={bonuses} notConvert />,
              },
              !!validator?.type && {
                  caption: liveLocalized.Validation,
                  captionType: UIDetailsTable.captionType.header,
              },
              DetailsRow.copyableRow(`${validatorLocale.public_key} ${hex}`, public_key),
              DetailsRow.copyableRow(`${validatorLocale.public_key} ${base64}`, TONClient.hexToBase64(public_key)),
              DetailsRow.copyableRow(`${validatorLocale.adnl_addr} ${hex}`, adnl_addr),
              DetailsRow.copyableRow(`${validatorLocale.adnl_addr} ${base64}`, TONClient.hexToBase64(adnl_addr)),
              DetailsRow.copyableRow(`${NodeID} ${hex}`, nodeId),
              // {
              //     caption: liveLocalized.Stake,
              //     component: <LongBalanceView balance={stake} notConvert />,
              // },
              {
                  caption: liveLocalized.TONValidator.weight,
                  value: percentWeight,
              },
              {
                  caption: liveLocalized.TONBlock.master.TONValidatorSet.utime_since,
                  value: MomentHelper.getTimeDate(validator.utime_since),
              },
              {
                  caption: liveLocalized.TONBlock.master.TONValidatorSet.utime_until,
                  value: MomentHelper.getTimeDate(validator.utime_until),
              },
              {
                  caption: liveLocalized.SignedBlocks,
                  value: signedBlocksCount,
              },
              {
                  caption: liveLocalized.TotalNumberOfMasterchainBlocks,
                  value: totalBlocksCount,
              },
              {
                  caption: liveLocalized.Uptime,
                  value: toFixedOrEmpty(signedBlocksCount / totalBlocksCount),
              },
          ];

    // pages
    // refs are for direct managing in MyWalletScreen
    const pages = [
        {
            title: liveLocalized.SignedBlocks,
            component: <SignedBlocksList ref={signedBlocksListRef} publicKey={publicKey} />,
        },
        {
            title: liveLocalized.History,
            component: (
                <StakesList
                    ref={stakesListRef}
                    {...(validator ? { proxies: validator.proxies || [validator.validatorWallet] } : {})}
                />
            ),
        },
    ];

    // Actions
    const onLoadValidator = async (params) => {
        controllerService.showSpinnerOverlay();
        let newValidator;
        try {
            newValidator = params.validatorId
                ? await TONValidator.getFullValidatorByIdForCurrent(params.validatorId)
                : params.electorMsgId
                ? await TONValidator.getFullValidatorByElectorMsg(params.electorMsgId)
                : await TONValidator.getFullValidatorByPublicKey(params.publicKey, params.keyBlockNum);

            const { utime_since, utime_until } = newValidator || {};
            const [{ total }, newTotalBlocksCount] = await Promise.all([
                EVERBlock.aggregateBlockSignatures(params.nodeId || newValidator?.nodeId, utime_since, utime_until),
                EVERBlock.aggregateMasterchainBlocks({
                    minTime: utime_since,
                    maxTime: utime_until,
                }),
            ]);

            if ((params.publicKey || params.keyBlockNum) && !newValidator) {
                controllerService.showPageNotFound(liveLocalized.ValidatorPublicKeyIsIncorrect);
                return;
            }

            setValidator(newValidator);
            setSignedBlocksCount(total);
            setTotalBlocksCount(newTotalBlocksCount);

            await TONAsync.timeout(0); // need some delay to render lists
            // stakesListRef?.current && stakesListRef.current.loadItems();
            // signedBlocksListRef?.current && signedBlocksListRef.current.loadItems();
        } catch (e) {
            log.error(e);
            controllerService.showServiceUnavailable();
        }
        if (!newValidator) {
            controllerService.showPageNotFound(liveLocalized.PublicKeyIsNotValid);
            return;
        }
        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadValidator);
    }, [params]);

    // Render
    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.formatString(liveLocalized.Validator, validator?.name || '')}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            profile={validator}
            details={details}
            copyableViewData={null}
            detailsComponent={<DetailsTabView pages={pages} />}
        />
    );
};

const Validator = observer(ValidatorComponent);

export { Validator };
