// @flow
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { errorCodes } from '#controllers/ErrorScreen/constants';
import { UIStyle } from '#uikit/designCore';
import UIConstant from '#uikit/designCore/constants';
import { container } from '#uikit/designCore/styles/container';

class ControllerService {
    @observable isMobile: boolean = false;
    @observable screenWidth: number = window.innerWidth;
    @observable screenHeight: number = window.innerHeight;
    @observable topBarRightWidth: number = 0;
    @observable spinnerVisible: boolean = false;
    @observable spinnerTitleContent: string = '';
    @observable spinnerTextContent: string = '';
    @observable errorCaption: string = '';
    @observable errorCode: string | any = null;
    @observable isAlertOpen: boolean = false;
    @observable alertText: string = '';
    @observable isMenuScreenOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setIsMobile = () => {
        this.isMobile = Boolean(document.documentElement.ontouchstart);
    };

    @action
    setScreenWidth = (width: number) => {
        if (this.screenWidth !== width) {
            this.screenWidth = width;
        }
    };

    @action
    setScreenHeight = (height: number) => {
        if (this.screenHeight !== height) {
            this.screenHeight = height;
        }
    };

    @action
    setTopBarRightWidth = (width: number) => {
        if (this.topBarRightWidth !== width) {
            this.topBarRightWidth = width;
        }
    };

    setSpinnerVisible = (visible: boolean) => {
        if (this.spinnerVisible !== visible) {
            this.spinnerVisible = visible;
        }
    };

    @action
    showSpinnerOverlay = () => {
        this.setSpinnerVisible(true);
    };

    @action
    hideSpinnerOverlay = () => {
        this.setSpinnerVisible(false);
        this.setSpinnerTitleContent('');
        this.setSpinnerTextContent('');
    };

    @action
    setSpinnerTitleContent = (title: string) => {
        if (this.spinnerTitleContent !== title) {
            this.spinnerTitleContent = title;
        }
    };

    @action
    setSpinnerTextContent = (text: string) => {
        if (this.spinnerTextContent !== text) {
            this.spinnerTextContent = text;
        }
    };

    @action
    setErrorCaption = (caption: string) => {
        if (this.errorCaption !== caption) {
            this.errorCaption = caption;
        }
    };

    @action
    setErrorCode = (code: ?string) => {
        if (this.errorCode !== code) {
            this.errorCode = code;
        }
    };

    @action
    showServiceUnavailable = () => {
        this.setErrorCode(errorCodes.serviceUnavailable);
        this.hideSpinnerOverlay();
    };

    @action
    showPageNotFound = (caption?: string) => {
        this.setErrorCode(errorCodes.pageNotfound);
        this.setErrorCaption(caption || '');
        this.hideSpinnerOverlay();
    };

    @action
    setIsAlertOpen = (isOpen: boolean) => {
        if (isOpen && this.isAlertOpen) {
            this.isAlertOpen = false;
            setTimeout(() => (this.isAlertOpen = true), 200);
        } else {
            this.isAlertOpen = isOpen;
        }
    };

    @action
    setAlertText = (text: string) => {
        this.alertText = text;
    };

    @action
    setIsMenuScreenOpen = (isOpen: boolean) => {
        this.isMenuScreenOpen = isOpen;
    };

    // Getters
    @computed
    get isNarrow() {
        return this.screenWidth <= UIConstant.elasticWidthBroad();
    }

    @computed
    get contentStyle() {
        return this.isNarrow ? UIStyle.width.fullPaddingContainer() : UIStyle.width.halfContainer();
    }

    @computed
    get reactContentClassNames() {
        return this.isNarrow ? container.fullWidthPadding : container.halfWidth;
    }
}

const controllerService = new ControllerService();

export default controllerService;
