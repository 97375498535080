// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { themesService } from '@services/ThemesService';
import { TONString } from '@packages/TONUtility';
import { TONClient } from '#TONClient';
import type { TonsNumber, Tons } from '#TONClient/TONClient';
import { blockingLinkElementID } from '#components/Link/LinkWithChild/LinkWithChild';
import { EverIcon, UIShareManager, UILabel } from '#components';
import { maxFractionalDigits } from '#components/BalanceView/constants';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { common } from '#uikit/designCore/styles/comon';
import { color } from '#uikit/designCore/styles/color';

import { CurrencyPosition, CurrencySymbolSize } from './constants';
import { quantityAndCurrency } from './helpers';

const getPreparedBalance = (balance: string | number, fixFractional: boolean, fractionalDigits: number): string => {
    let preparedBalance: string;

    if (fixFractional && balance && balance != '0') {
        if (+balance < 10 ** -maxFractionalDigits && +balance > 0) {
            const exponentialBalance = TONClient.number(balance).toExponential(maxFractionalDigits);

            const preparedIntegerPart = liveLocalized.amountToLocale(exponentialBalance.split('e')[0], {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            });
            const preparedExponentialPart =
                'E' +
                liveLocalized.amountToLocale(
                    exponentialBalance.slice(exponentialBalance.indexOf('e') + 1).replace(/\s/g, '')
                );

            return preparedIntegerPart + preparedExponentialPart;
        }

        preparedBalance = TONClient.number(balance).toFixed(Math.min(fractionalDigits, maxFractionalDigits));
    } else {
        preparedBalance = String(balance);
    }

    return liveLocalized.amountToLocale(preparedBalance, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionalDigits,
    });
};

type Props = {
    hideIcon?: boolean;
    hex?: boolean;
    notConvert?: boolean;
    balance?: TonsNumber | Tons;
    // animated?: boolean,
    // tokenSymbol?: string,
    // useMaxBalanceLength?: boolean,
    // smartTruncator?: boolean,
    // fractionalDigits: ?number,
    fixFractional?: boolean;
    fractionalDigits?: number;
};

const BalanceView = ({
    hideIcon = false,
    hex = false,
    notConvert = false,
    // animated = true,
    balance = 0,
    // tokenSymbol = '',
    // useMaxBalanceLength,
    // smartTruncator,
    // fractionalDigits,
    fixFractional = false,
    fractionalDigits = 3,
}: Props) => {
    // for future using
    // static convertGramsToNanoGrams = grams => {
    //     const x = new BigNumber(grams);
    //     const y = new BigNumber('1e9');
    //     return x.times(y).toNumber();
    // }
    const theme = themesService.isDarkTheme ? color.DarkTheme : color.LightTheme;

    const [convertedBalance, setConvertedBalance] = useState<number>(0);

    useEffect(() => {
        if (notConvert) return;

        if (balance == null) {
            setConvertedBalance(0);
        } else {
            setConvertedBalance(hex ? TONClient.hexNanoToTons(String(balance)) : TONClient.nanoToTons(balance));
        }
    }, [balance, notConvert]);

    const resultBalance = notConvert ? balance : convertedBalance;
    const preparedBalance = useMemo(() => getPreparedBalance(resultBalance, fixFractional, fractionalDigits), [
        resultBalance,
        fixFractional,
        fractionalDigits,
    ]);

    if (balance == null) return null;

    return (
        <div
            // this is gor UIDetailsButton which is a Link, BalanceView can't be a Link
            id={blockingLinkElementID}
            className={common.cursorPointer}
            onClick={() => UIShareManager.copyToClipboard(`${resultBalance}`, liveLocalized.CopiedToClipboard)}
            role="button"
            tabIndex={0}
        >
            <UILabel testID="transaction_change" role={TypographyVariants.ParagraphText}>
                {quantityAndCurrency(preparedBalance, hideIcon ? <div /> : <EverIcon />, {
                    key: 'balance',
                    position: CurrencyPosition.After,
                    separator: TONString.getLocaleInfo().numbers.decimal,
                    integerClassNames: `${theme} ${color.TextPrimary}`,
                    fractionalClassNames: `${theme} ${color.TextTertiary}`,
                    currencySymbolSize: CurrencySymbolSize.S,
                })}
            </UILabel>
        </div>
    );
};

export default observer(BalanceView);
