// @flow
import LinkManager from '#helpers/LinkManager';
import TONAccount from '#TONClient/TONAccount';
import FBContracts from '@Firebase/FBContracts';
import Utils from '#helpers/Utils';

import TONClient from '../TONClient';
import { SUCCESS } from '../statuses';
import type { GetContractsArgs, TONContractT } from './types';
import TONFilter from '../TONFilter';
import { FBQueryWrapper } from '../helpers';

export default class TONContract {
    static formatter = (contractDoc?: TONContractT): TONContractT => ({
        ...contractDoc,
        avatar: contractDoc.avatar || LinkManager.getAvatar(contractDoc?.code_hash || ''),
    });

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('TONContract', str, params);
    }

    // static async aggregateSmartContracts(filterValues: AccountFilterValues) {
    //     const filter = await this.filter({ filterValues });
    //     const log = this.initLog('smart contracts count', { filterValues, filter });
    //
    //     try {
    //         const docs = await TONClient.accounts().aggregate({ filter });
    //
    //         const count = docs && (Number(docs[0]) ? Number(docs[0]) - 1 : 0);
    //         log.debug(SUCCESS, count);
    //         return count;
    //     } catch (err) {
    //         log.error(err);
    //         throw err;
    //     }
    // }

    static async getContracts(args: GetContractsArgs): Promise<TONContractT[]> {
        const { code_hash: codeHashExpression, name: nameFilterValue } = TONFilter.getValuesFromFilterValues(
            args.filterValues
        );

        const contractsByCodeHash = await FBQueryWrapper(FBContracts.getContractsByCodeHash, null)();
        let contracts = Utils.objectValues(contractsByCodeHash || {});
        if (args?.filterValues?.code_hash?.value) {
            contracts = contracts.filter(({ code_hash }) => code_hash.startsWith(codeHashExpression));
        }

        // if contract is not in FB, but exists in graphQL
        if (!contracts.length) {
            contracts = [{ code_hash: codeHashExpression }];
        }

        const log = this.initLog('list', { args });
        const numerify = (doc) => doc != null && TONClient.number(doc);
        const accountFilter = TONAccount.filter(args);

        const aggregationQueries = await TONClient.batchAggregateCollection(
            contracts.reduce((prev, { code_hash }) => {
                const preparedFilter = { ...accountFilter, code_hash: { eq: code_hash } };
                delete preparedFilter.last_paid;

                return [
                    ...prev,
                    // total sum of balances, no need for last_paid filter
                    {
                        collection: TONClient.collections.accounts,
                        filter: preparedFilter,
                        fields: [{ field: 'balance', fn: 'SUM' }],
                    },
                    // total number of contracts, no need for last_paid filter
                    {
                        collection: TONClient.collections.accounts,
                        filter: preparedFilter,
                    },
                    // number of active contracts by screen filter, it use last_paid and others
                    {
                        collection: TONClient.collections.accounts,
                        filter: {
                            ...accountFilter,
                            code_hash: { eq: code_hash },
                        },
                    },
                    // number of recent (new) contracts by screen filter
                    {
                        collection: TONClient.collections.messages,
                        filter: {
                            code_hash: { eq: code_hash },
                            // ...TONFilter.workchainStringFilter(args?.filterValues, key),
                            ...TONFilter.timeFilter('created_at', args?.filterValues),
                        },
                    },
                ];
            }, [])
        ).then((docs) => docs.map(numerify));

        const aggregationResults = Utils.divideBySubLists(aggregationQueries, 4);

        log.debug('aggregation results', aggregationResults);

        let result = contracts
            .map((contract, index) => {
                const aggregation = aggregationResults[index];
                return this.formatter({
                    ...contract,
                    totalBalances: aggregation[0],
                    contractsCount: {
                        total: aggregation[1],
                        active: aggregation[2],
                        recent: aggregation[3],
                    },
                    id: '', // for EVERItem interface
                });
            })
            .filter(({ contractsCount }) => !!contractsCount.total);

        TONFilter.sortBy(result, args, 'totalBalances');

        log.debug(SUCCESS, result);

        if (args?.filterValues?.name?.value) {
            result = result.filter(({ name }) => (name || '').toLowerCase().includes(nameFilterValue.toLowerCase()));
        }

        return result;
    }
}
