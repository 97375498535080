import React from 'react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import { ExternalLink } from '#components';
import { ExternalLinkTypes } from '#components/Link/ExternalLink/contants';
//@ts-ignore doesn`t see import
import IconSurf from '#assets/surf.png';
import { margin, common, color } from '#uikit/designCore/styles';

import './SurfLink.scss';

type Props = {
    text: string;
    href: string;
};

const SurfLink: React.FC<Props> = ({ text, href }) => {
    return (
        <ExternalLink
            openInNewTab
            classNames={classnames('SurfLink', margin.topGreat, margin.bottomGreat)}
            title={
                <div className={classnames(common.displayFlex, color.Shadow)}>
                    {text}
                    <img src={IconSurf} className={classnames(common.icon, margin.horizontalSmallOffset)} alt="Ton" />
                    {liveLocalized.Surf}
                </div>
            }
            href={href}
            type={ExternalLinkTypes.button}
        />
    );
};

export { SurfLink };
