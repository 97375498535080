export const typography = {
    TitleHuge: 'TitleHuge',
    TitleLarge: 'TitleLarge',
    TitleMedium: 'TitleMedium',
    TitleSmall: 'TitleSmall',
    PromoHuge: 'PromoHuge',
    PromoLarge: 'PromoLarge',
    PromoMedium: 'PromoMedium',
    PromoSmall: 'PromoSmall',
    HeadlineHead: 'HeadlineHead',
    HeadlineSubhead: 'HeadlineSubhead',
    HeadlineFootnote: 'HeadlineFootnote',
    HeadlineLabel: 'HeadlineLabel',
    Action: 'Action',
    ActionCallout: 'ActionCallout',
    ActionFootnote: 'ActionFootnote',
    ActionLabel: 'ActionLabel',
    ParagraphText: 'ParagraphText',
    ParagraphNote: 'ParagraphNote',
    ParagraphFootnote: 'ParagraphFootnote',
    ParagraphLabel: 'ParagraphLabel',
    MonoText: 'MonoText',
    MonoNote: 'MonoNote',
    LightHuge: 'LightHuge',
    LightLarge: 'LightLarge',
    LightMedium: 'LightMedium',
    LightSmall: 'LightSmall',
};
