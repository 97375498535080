export const border = {
    common: 'borderCommon',
    top: 'borderTop',
    bottom: 'borderBottom',
    left: 'borderLeft',
    bottomAction: 'borderBottomAction',
    radiusDefault: 'borderRadiusDefault',
    radiusMedium: 'borderRadiusMedium',
    solid: 'borderSolid',
    colorPrimary: 'BorderColorPrimary',
    colorSecondary: 'BorderColorSecondary',
    colorTertiary: 'BorderColorTertiary',
    colorNeutral: 'BorderColorNeutral',
    colorAccent: 'BorderColorAccent',
    colorNegative: 'BorderColorNegative',
    colorPositive: 'BorderColorPositive',
    colorWarning: 'BorderColorWarning',
    colorPrimaryInverted: 'BorderColorPrimaryInverted',
    colorTertiaryInverted: 'BorderColorTertiaryInverted',
    colorOverlay: 'BorderColorOverlay',
    colorOverlayInverted: 'BorderColorOverlayInverted',
    topColorPrimary: 'BorderTopColorPrimary',
    topColorSecondary: 'BorderTopColorSecondary',
    topColorTertiary: 'BorderTopColorTertiary',
    topColorNeutral: 'BorderTopColorNeutral',
    topColorAccent: 'BorderTopColorAccent',
    topColorNegative: 'BorderTopColorNegative',
    topColorPositive: 'BorderTopColorPositive',
    topColorWarning: 'BorderTopColorWarning',
    topColorPrimaryInverted: 'BorderTopColorPrimaryInverted',
    topColorTertiaryInverted: 'BorderTopColorTertiaryInverted',
    topColorOverlay: 'BorderTopColorOverlay',
    topColorOverlayInverted: 'BorderTopColorOverlayInverted',
    topLeftRadiusDefault: 'borderTopLeftRadiusDefault',
    topRightRadiusDefault: 'borderTopRightRadiusDefault',
    rightColorPrimary: 'BorderRightColorPrimary',
    rightColorSecondary: 'BorderRightColorSecondary',
    rightColorTertiary: 'BorderRightColorTertiary',
    rightColorNeutral: 'BorderRightColorNeutral',
    rightColorAccent: 'BorderRightColorAccent',
    rightColorNegative: 'BorderRightColorNegative',
    rightColorPositive: 'BorderRightColorPositive',
    rightColorWarning: 'BorderRightColorWarning',
    rightColorPrimaryInverted: 'BorderRightColorPrimaryInverted',
    rightColorTertiaryInverted: 'BorderRightColorTertiaryInverted',
    rightColorOverlay: 'BorderRightColorOverlay',
    rightColorOverlayInverted: 'BorderRightColorOverlayInverted',
    bottomColorPrimary: 'BorderBottomColorPrimary',
    bottomColorSecondary: 'BorderBottomColorSecondary',
    bottomColorTertiary: 'BorderBottomColorTertiary',
    bottomColorNeutral: 'BorderBottomColorNeutral',
    bottomColorAccent: 'BorderBottomColorAccent',
    bottomColorNegative: 'BorderBottomColorNegative',
    bottomColorPositive: 'BorderBottomColorPositive',
    bottomColorWarning: 'BorderBottomColorWarning',
    bottomColorPrimaryInverted: 'BorderBottomColorPrimaryInverted',
    bottomColorTertiaryInverted: 'BorderBottomColorTertiaryInverted',
    bottomColorOverlay: 'BorderBottomColorOverlay',
    bottomColorOverlayInverted: 'BorderBottomColorOverlayInverted',
    leftColorPrimary: 'BorderLeftColorPrimary',
    leftColorSecondary: 'BorderLeftColorSecondary',
    leftColorTertiary: 'BorderLeftColorTertiary',
    leftColorNeutral: 'BorderLeftColorNeutral',
    leftColorAccent: 'BorderLeftColorAccent',
    leftColorNegative: 'BorderLeftColorNegative',
    leftColorPositive: 'BorderLeftColorPositive',
    leftColorWarning: 'BorderLeftColorWarning',
    leftColorPrimaryInverted: 'BorderLeftColorPrimaryInverted',
    leftColorTertiaryInverted: 'BorderLeftColorTertiaryInverted',
    leftColorOverlay: 'BorderLeftColorOverlay',
    leftColorOverlayInverted: 'BorderLeftColorOverlayInverted',

    BackgroundPrimary: 'BorderColorBackgroundPrimary',
    BackgroundSecondary: 'BorderColorBackgroundSecondary',
    BackgroundTertiary: 'BorderColorBackgroundTertiary',
    BackgroundNeutral: 'BorderColorBackgroundNeutral',
    BackgroundAccent: 'BorderColorBackgroundAccent',
    BackgroundNegative: 'BorderColorBackgroundNegative',
    BackgroundPositive: 'BorderColorBackgroundPositive',
    BackgroundWarning: 'BorderColorBackgroundWarning',
    BackgroundPrimaryInverted: 'BorderColorBackgroundPrimaryInverted',
    BackgroundTertiaryInverted: 'BorderColorBackgroundTertiaryInverted',
    BackgroundOverlay: 'BorderColorBackgroundOverlay',
    BackgroundOverlayInverted: 'BorderColorBackgroundOverlayInverted',
};
