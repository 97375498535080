import React, { CSSProperties, ReactElement } from 'react';
import classnames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import {
    UIActionComponent,
    UIActionComponentProps,
    UIActionComponentState,
} from '#components/UIActionComponent/ReactActionClassComponent';
import { UILabel } from '#components';
import UIColor from '#uikit/designCore/color';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex, common, height, margin } from '#uikit/designCore/styles';

type Props = UIActionComponentProps & {
    align: CSSProperties;
    containerStyle?: CSSProperties;
    details: string;
    detailsClassNames?: string;
    disableIconColor?: boolean;
    icon: Maybe<string>;
    backIconPath: string;
    textStyle?: CSSProperties;
    textHoverStyle?: CSSProperties;
    textTappedStyle?: CSSProperties;
    theme: string;
    title: string;
    titleColor?: string;
    titleRole?: string;
    multiLine: boolean;
    buttonHeightClassName?: string;
    classNames?: string;
};

type State = UIActionComponentState;

export default class UITextButton extends UIActionComponent<Props, State> {
    static alignClassNames = {
        left: flex.justifyStart,
        center: flex.justifyCenter,
        between: flex.justifySpaceBetween,
    };

    static defaultProps: Props = {
        ...UIActionComponent.defaultProps,
        align: UITextButton.alignClassNames.left,
        details: '',
        icon: null,
        backIconPath: null,
        theme: UIColor.Theme.Light,
        title: '',
        multiLine: false,
        defaultHeight: height.button,
    };

    static pushStyle(styleArray: CSSProperties[], newStyle: CSSProperties | CSSProperties[]) {
        if (newStyle instanceof Array) {
            styleArray.push(...newStyle);
        } else {
            styleArray.push(newStyle);
        }
    }

    // Virtual
    getStateCustomColorStyle() {
        if (this.isTapped()) {
            return this.props.textTappedStyle;
        }
        if (this.isHover()) {
            return this.props.textHoverStyle;
        }
        return null;
    }

    // Render
    renderIcon(icon: string, isBack: boolean) {
        if (!icon) {
            return null;
        }

        return <img src={icon} alt="icon" className={isBack ? margin.leftSmall : margin.rightSmall} />;
    }

    renderTitle() {
        const { title, titleColor, titleRole, textStyle, details, theme, disabled } = this.props;
        if (!title) {
            return null;
        }
        const tapped = this.isTapped();
        const hover = this.isHover();
        const stateColorStyle =
            disabled || tapped || hover ? UIColor.stateTextPrimaryStyle(theme, disabled, tapped, hover) : null;
        const stateCustomColorStyle = this.getStateCustomColorStyle();

        return (
            <UILabel
                color={disabled ? ColorVariants.TextTertiary : titleColor || ColorVariants.TextAccent}
                role={titleRole || TypographyVariants.Action}
                classNames={details ? flex.grow1 : flex.grow0}
                style={{ ...stateCustomColorStyle, ...stateColorStyle, ...textStyle }}
            >
                {title}
            </UILabel>
        );
    }

    renderDetails() {
        const { details, detailsClassNames } = this.props;
        if (!details || !details.length) {
            return null;
        }
        return (
            <UILabel
                color={ColorVariants.TextSecondary}
                role={TypographyVariants.ParagraphNote}
                classNames={detailsClassNames}
            >
                {details}
            </UILabel>
        );
    }

    renderContent(): ReactElement {
        const { alignClassNames, icon, backIconPath, classNames, multiLine, buttonHeightClassName } = this.props;

        return (
            <div
                className={classnames(
                    common.displayFlex,
                    common.backgroundTransparent,
                    alignClassNames,
                    {
                        [flex.column]: multiLine,
                        centerLeftContainer: !multiLine,
                        buttonHeight: !multiLine && !buttonHeightClassName,
                    },
                    classNames
                )}
            >
                {this.renderIcon(icon, false)}
                {this.renderTitle()}
                {this.renderIcon(backIconPath, true)}
                {this.renderDetails()}
            </div>
        );
    }
}
