// @flow
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import UIDetailsTable from '#components/UIDetailsTable';
import { TONMessage } from '#TONClient';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import DetailsRowHelper from '#controllers/Details/DetailsRowHelper';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import controllerService from '@services/ControllerService';
import { TONLog } from '#TONUtility';

const log = new TONLog('MessageDetailsScreen');

const MessageComponent = () => {
    const ref = useRef(null);
    const [message, setMessage] = useState<?TONMessageT>(null);
    const [decodedMsgBody, setDecodedMsgBody] = useState<{
        value: any,
        name: string,
        header: any,
    }>({
        value: {},
        name: '',
        header: {},
    });

    const [params] = useParams();
    const { id } = params;

    // Getters
    const {
        value,
        created_at,
        src,
        dst,
        msg_type_name,
        parent_tr,
        child_tr,
        created_lt,
        body,
        ihr_fee,
        fwd_fee,
        bounce,
        bounced,
        code,
        data,
        library,
        proof,
        boc,
        code_hash,
        data_hash,
        library_hash,
        body_hash,
        decodedBody,
    } = message || {};
    const { header, value: decodedValue, name } = decodedMsgBody;

    const details = [
        {
            caption: liveLocalized.Type,
            value: liveLocalized.TONMessage.types[msg_type_name],
        },
        ...[created_at ? DetailsRowHelper.getTimeAndDate(created_at) : {}],
        DetailsRowHelper.accountLinkWithCopy(liveLocalized.From, src),
        DetailsRowHelper.accountLinkWithCopy(liveLocalized.To, dst),
        DetailsRowHelper.crystals(liveLocalized.Value, value),
        DetailsRowHelper.transactionLinkWithCopy(liveLocalized.Parent, parent_tr?.id),
        DetailsRowHelper.transactionLinkWithCopy(liveLocalized.Child, child_tr?.id),
        {
            caption: liveLocalized.Name,
            value: name,
        },
        DetailsRowHelper.copyableRow(liveLocalized.Value, decodedValue?.owners?.length && decodedValue?.owners[0]),
        DetailsRowHelper.copyableRow(liveLocalized.formatString(liveLocalized.PublicKey, ''), header?.pubkey),
    ];

    const moreDetails = !message
        ? []
        : [
              {
                  caption: liveLocalized.LogicalTime,
                  value: created_lt,
              },
              DetailsRowHelper.crystals(liveLocalized.IhrFee, ihr_fee),
              DetailsRowHelper.crystals(liveLocalized.FwdFee, fwd_fee),
              {
                  caption: liveLocalized.Bounce,
                  value: bounce,
                  type: UIDetailsTable.cellType.bool,
              },
              {
                  caption: liveLocalized.Bounced,
                  value: bounced,
                  type: UIDetailsTable.cellType.bool,
              },
              DetailsRowHelper.binaryRow(liveLocalized.Body, body, 'body'),
              !!(src && dst) && {
                  caption: `${liveLocalized.Body} UTF-8`,
                  value: decodedBody,
              },
              DetailsRowHelper.copyableRow(liveLocalized.TONMessage.body_hash, body_hash),
              ...DetailsRowHelper.codeDataLibraryRows(
                  code,
                  data,
                  library,
                  code_hash,
                  data_hash,
                  library_hash,
                  'message',
                  id
              ),
              ...DetailsRowHelper.proofBocRows(proof, boc, 'message', id),
          ];

    // Actions
    const onLoadMessage = async ({ id: idParam }) => {
        if (!idParam) {
            log.debug('No message id passed');
            return;
        }

        controllerService.showSpinnerOverlay();

        try {
            const msg = await TONMessage.getMessage(idParam, {
                needAdditionalFields: true,
                needBinaryFields: true,
            });
            if (!msg) {
                controllerService.showPageNotFound(liveLocalized.MessageWithThisIdWasntFound);
                return;
            }

            const newDecodedMsgBody = await TONMessage.decodeExtInMsgBody(msg);
            setMessage(msg);
            setDecodedMsgBody(newDecodedMsgBody);
        } catch (e) {
            controllerService.showServiceUnavailable();
        }
        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, onLoadMessage);
    }, [params]);

    // Render
    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.MessageDetails}
            details={details}
            needMoreDetailsButton
            moreDetails={moreDetails}
        />
    );
};

const Message = observer(MessageComponent);

export { Message };
