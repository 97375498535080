import { useNavigate, useLocation } from 'react-router-dom';

import { URLSearchParamsType } from '#navigation/types';
import { useParams } from '#navigation/hooks/useParams';

const useCustomNavigate = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [currentParams] = useParams();

    return (navigationPath: string, params?: URLSearchParamsType) => {
        const navigationPathWithQueryParams = params
            ? `${navigationPath}?${Object.keys(params)
                  .map((key) => `${key}=${params[key] || ''}`)
                  .join('&')}`
            : navigationPath;

        navigate(navigationPathWithQueryParams, { state: { prevRoute: { pathname, queryParams: currentParams } } });
    };
};

export { useCustomNavigate };
