import React, { useRef } from 'react';

import './UITextView.scss';

type Props = {
    onFocus?: () => void;
    onBlur?: () => void;
    onChangeText: (text: string) => void;
    onKeyDown?: (e) => void;
    placeholder?: string;
    classNames?: string;
    value?: string;
    testID?: string;
};

const UITextView: React.FC<Props> = React.forwardRef(
    (
        { onFocus = () => {}, onBlur = () => {}, onChangeText, onKeyDown, placeholder, classNames, value, testID },
        Ref
    ) => {
        const fallbackRef = useRef<HTMLInputElement>();
        const inputRef = Ref || fallbackRef;

        return (
            <input
                key={testID || ''}
                className={classNames + ' UITextInput'}
                placeholder={placeholder}
                ref={inputRef}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(event) => onChangeText(event.target.value)}
                onKeyDown={onKeyDown}
                value={value}
            />
        );
    }
);

export { UITextView };
