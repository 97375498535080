import React from 'react';
import classnames from 'classnames';

import { UITextButton } from '#components';
import UIToggle from '#components/EVERList/Toggle/UIToggle';
import { margin } from '#uikit/designCore/styles/margin';
import { common } from '#uikit/designCore/styles/comon';
import { flex } from '#uikit/designCore/styles/flex';
import { font } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';

type Props = {
    title: string;
    active: boolean;
    onClick: (turnOn?: boolean) => void;
};

export const toggleTestIDs = {
    autoUpdateToggle: 'autoUpdateToggle',
};

const Toggle: React.FC<Props> = ({ title = '', active = false, onClick = () => {} }) => (
    <div
        className={classnames(common.displayFlex, common.cursorPointer, flex.alignItemsCenter)}
        onClick={() => onClick()}
    >
        <UITextButton
            classNames={classnames(margin.rightSmall, margin.leftHuge)}
            title={title}
            titleColor={ColorVariants.TextAccent}
            titleRole={font.smallMedium}
        />
        <UIToggle active={active} onClick={onClick} testID={toggleTestIDs.autoUpdateToggle} />
    </div>
);

export default Toggle;
