import React from 'react';
import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import { UITextButton, UILabel } from '#components';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin } from '#uikit/designCore/styles/margin';
import { width } from '#uikit/designCore/styles/width';
import { height } from '#uikit/designCore/styles/height';
import { container } from '#uikit/designCore/styles/container';
import { flex } from '#uikit/designCore/styles/flex';
import { common } from '#uikit/designCore/styles/comon';

type Props = {
    title: string;
    details: Maybe<string>;
    onPress: () => void;
};

const updateViewTestIDs = {
    newItemsQty: 'newItemsQty',
    justNow: 'justNow',
    showButton: 'showButton',
};

const UpdateView = ({ title = '', details = '', onPress = () => {} }: Props) => {
    return (
        <div className={classNames(common.displayFlex, width.full, height.majorCell, container.centerLeft)}>
            <div className={classNames(common.displayFlex, flex.flex1, margin.rightDefault)}>
                <UILabel testID={updateViewTestIDs.newItemsQty} role={TypographyVariants.Action}>
                    {title}
                </UILabel>
                <UILabel
                    testID={updateViewTestIDs.justNow}
                    classNames="marginTopTiny"
                    role={TypographyVariants.ParagraphFootnote}
                    color={ColorVariants.TextSecondary}
                >
                    {details}
                </UILabel>
            </div>
            <UITextButton title={liveLocalized.Show} onPress={onPress} testID={updateViewTestIDs.showButton} />
        </div>
    );
};

export default UpdateView;
