import { liveLocalized } from '@services/LocalizationService';
import FBDePools from '@Firebase/FBDePools';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { EVERTransaction, TONMessage, EVERBlock, TONAccount, TONValidator, TONClient } from '#TONClient';
import type { TONAccountT } from '#TONClient/TONAccount/types';
import type { EVERTransactionT } from '#TONClient/EVERTransaction/types';
import type { TONBlockT } from '#TONClient/EVERBlock/types';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import type { TONValidatorT } from '#TONClient/TONValidator/types';
import type { TONDePoolT } from '#TONClient/TONDePool/types';
import { paths } from '#navigation/paths';
import { SearchTokenResult } from '#TONClient/EVERTip3/types';
import { findTokens } from '#TONClient/EVERTip3/helpers';
import { validatorDetailsGetParams } from '#navigation/helpers';

export default class TONSearch {
    static getInitialResults(expression: string) {
        return [
            {
                title: liveLocalized.Accounts,
                data: [],
                navigationPath: paths.accounts.details,
                getParams: ({ id }: TONAccountT) => ({ id }),
                labelRenderer: ({ id }: TONAccountT) => id,
            },
            {
                title: liveLocalized.DePools,
                data: [],
                navigationPath: paths.dePools.details,
                getParams: ({ id }: TONDePoolT) => ({ id }),
                labelRenderer: () => expression,
            },
            {
                title: liveLocalized.Transactions,
                data: [],
                navigationPath: paths.transactions.details,
                getParams: ({ id }: EVERTransactionT) => ({ id }),
                labelRenderer: ({ id }: EVERTransactionT) => id,
            },
            {
                title: liveLocalized.Blocks,
                data: [],
                navigationPath: paths.blocks.details,
                getParams: ({ id }: TONBlockT) => ({ id }),
                labelRenderer: ({ id }: TONBlockT) => id,
            },
            {
                title: liveLocalized.Blocks,
                data: [],
                navigationPath: paths.blocks.details,
                getParams: ({ id }: TONBlockT) => ({ id }),
                labelRenderer: (block: TONBlockT = {}) =>
                    `${block?.seq_no} / ${Utils.getWorkchainShard(block)} / ${MomentHelper.getTimeDate(
                        block?.gen_utime
                    )}`,
            },
            {
                title: liveLocalized.Messages,
                data: [],
                navigationPath: paths.messages.details,
                getParams: ({ id }: TONMessageT) => ({ id }),
                labelRenderer: ({ id }: TONMessageT) => id,
            },
            {
                title: liveLocalized.Validators,
                data: [],
                navigationPath: paths.validators.details,
                getParams: ({ public_key }: TONValidatorT) =>
                    validatorDetailsGetParams({
                        publicKey: public_key,
                    }),
                labelRenderer: ({ public_key, adnl_addr }: TONValidatorT) => {
                    const hexExp = TONClient.base64ToHex(expression);
                    const expStr = expression.toLowerCase();

                    const isPublicKey = public_key.indexOf(hexExp) + public_key.indexOf(Utils.remove0x(expStr)) > -2;

                    const isAdnlAddr = adnl_addr.indexOf(hexExp) + adnl_addr.indexOf(expStr) > -2;

                    return (isPublicKey && public_key) || (isAdnlAddr && adnl_addr) || expStr;
                },
            },
            {
                title: liveLocalized.Tokens,
                data: [],
                navigationPath: paths.TIP32.details,
                getParams: ({ rootAddress }: SearchTokenResult) => ({ id: rootAddress }),
                labelRenderer: ({ symbol, rootAddress }: SearchTokenResult) => `${symbol} | ${rootAddress}`,
            },
        ];
    }

    static getRequests(expression: string) {
        const valExpression = expression.trim();
        const isValidExpression = /^[0-9a-fA-F]{64}$/.test(valExpression);

        // lowerCase is needed to be custom here
        return [
            TONAccount.getAccountIdsByExpression(valExpression, undefined),
            FBDePools.findDePool(valExpression),
            isValidExpression ? EVERTransaction.checkTransactionId(valExpression.toLowerCase()) : null,
            isValidExpression ? EVERBlock.getBlock(valExpression.toLowerCase()) : null,
            /^\d+$/.test(valExpression) ? EVERBlock.getBlockIdsByNumberFromCollection(Number(valExpression)) : null,
            isValidExpression ? TONMessage.getMessage(valExpression.toLowerCase()) : null,
            TONValidator.findValidators({
                expression: valExpression,
                fullSearch: true,
            }),
            findTokens(expression),
        ];
    }
}
