import { Maybe } from 'graphql/jsutils/Maybe';

import type { UIColorData, UIColorThemeNameType } from '../color/types';
import { UITheme } from '../theme';

type colorStyleStorageFields = 'color' | 'backgroundColor' | 'borderBottomColor' | 'borderTopColor' | 'tintColor';

const colorStylesStorage: { [key in colorStyleStorageFields]: { [key: string]: { color: string } } } = {
    color: {},
    backgroundColor: {},
    borderBottomColor: {},
    borderTopColor: {},
    tintColor: {},
};

export default class UIStyleColor {
    static styles: { [key in colorStyleStorageFields]: colorStyleStorageFields } = {
        color: 'color',
        backgroundColor: 'backgroundColor',
        borderBottomColor: 'borderBottomColor',
        borderTopColor: 'borderTopColor',
        tintColor: 'tintColor',
    };

    // Deprecated
    static Styles = {
        Color: 'color',
        BackgroundColor: 'backgroundColor',
        BorderBottomColor: 'borderBottomColor',
        TintColor: 'tintColor',
    };

    static getStyle(color: UIColorData, colorStyle: colorStyleStorageFields): { color: string } {
        const colorStyles = colorStylesStorage[colorStyle];
        let colorValue = colorStyles[color];
        if (!colorValue) {
            colorStyles[color] = { color };
        }

        return colorStyles[color];
    }

    static getColorStyle(color: UIColorData) {
        return this.getStyle(color, this.styles.color);
    }

    static getBackgroundColorStyle(color: UIColorData) {
        return this.getStyle(color, this.styles.backgroundColor);
    }

    static getBorderBottomColorStyle(color: UIColorData) {
        return this.getStyle(color, this.styles.borderBottomColor);
    }

    static getBorderTopColorStyle(color: UIColorData) {
        return this.getStyle(color, this.styles.borderTopColor);
    }

    static getTintColorStyle(color: UIColorData) {
        return this.getStyle(color, this.styles.tintColor);
    }

    static textPrimary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getColorStyle(UITheme.textPrimary(theme));
    }

    static backgroundPrimary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getBackgroundColorStyle(UITheme.backgroundPrimary(theme));
    }

    static stateTextPrimary(theme: Maybe<UIColorThemeNameType>, disabled: boolean, tapped: boolean, hover: boolean) {
        const color = UITheme.stateTextPrimary(theme, disabled, tapped, hover);
        return this.getColorStyle(color);
    }

    static actionTextPrimary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getColorStyle(UITheme.actionTextPrimary(theme));
    }

    static textSecondary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getColorStyle(UITheme.textSecondary(theme));
    }

    static textTertiary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getColorStyle(UITheme.textTertiary(theme));
    }

    static textQuaternary(theme?: Maybe<UIColorThemeNameType>) {
        return this.getColorStyle(UITheme.textQuaternary(theme));
    }

    static borderBottomLight(theme: Maybe<UIColorThemeNameType>) {
        const borderColor = UITheme.borderBottomLightColor(theme);
        return this.getBorderBottomColorStyle(borderColor);
    }

    static borderBottom(theme: Maybe<UIColorThemeNameType>, focused: boolean, hover: boolean) {
        const borderColor = UITheme.borderBottomColor(theme, focused, hover);
        return this.getBorderBottomColorStyle(borderColor);
    }
}
