import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import { UITextButton, Popover } from '#components';
import type { MenuItemProps } from '#components/TagFilter/types';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex } from '#uikit/designCore/styles';

import type { CustomPopoverProps, PopoverRefType } from '../types';

type PopoverMenuProps = {
    menuItems: MenuItemProps[];
} & Omit<CustomPopoverProps, 'component'>;

const PopoverMenu: React.FC<PopoverMenuProps> = ({ menuItems, ...other }) => {
    const popoverRef = useRef<PopoverRefType>(null);
    const menuComponent = (
        <>
            {menuItems.map(
                ({
                    title,
                    titleColor,
                    titleStyle,
                    titleTypography = TypographyVariants.ActionCallout,
                    onPress,
                    chosen,
                    classNames,
                }) => (
                    <UITextButton
                        key={title}
                        title={title}
                        titleColor={
                            titleStyle || titleColor || (chosen ? ColorVariants.TextAccent : ColorVariants.TextPrimary)
                        }
                        titleRole={titleTypography}
                        onPress={() => {
                            if (onPress) onPress();
                            popoverRef?.current?.close();
                        }}
                        classNames={classnames(classNames, flex.justifyContentCenter)}
                    />
                )
            )}
            {controllerService.isNarrow && (
                <UITextButton
                    title={liveLocalized.Cancel}
                    titleColor={ColorVariants.TextAccent}
                    onPress={() => popoverRef?.current?.close()}
                    classNames={flex.justifyContentCenter}
                />
            )}
        </>
    );

    return <Popover ref={popoverRef} component={menuComponent} {...other} />;
};

export default observer(PopoverMenu);
