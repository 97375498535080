import { TonClient as TONCoreClient } from '@eversdk/core';
import { libWeb, libWebSetup } from '@eversdk/lib-web';
import ReactGA from 'react-ga4';

import { localizationService } from '@services/LocalizationService';
import EnvManager from '#helpers/EnvManager';
import { getNetworkCluster } from '#helpers/index';
import { TONClient } from '#TONClient';
import { TONCache, TONEnvironment, TONLogSettings } from '#TONUtility';
import iconEver from '#assets/ico-ever/x-blue.svg';
import SecretConfig from '#configs/SecretConfig';
import { netKeys } from 'constants/constants';

const GOOGLE_ANALYTICS_TRACKING_ID = EnvManager.isMainnet()
    ? 'G-BE720VNBN4'
    : EnvManager.isDevnet()
    ? 'G-BX3LFLP1FE'
    : '';

export default class Configs {
    static getLegalNotesConfig() {
        return {
            iconBackScreen: iconEver,
            backScreen: 'landing',
            siteURL: 'ever.live',
            companyName: '',
            productName: 'EVER Live',
            supportEmail: 'support@tonlabs.io',
        };
    }

    static getMarketPlaceConfig() {
        return {
            name: 'grandbazar.io',
            url: 'https://grandbazar.io/item/',
        };
    }

    static async setupTONClientSDK() {
        libWebSetup({
            binaryURL: '/assets/eversdk.wasm',
        });

        TONCoreClient.useBinaryLibrary(libWeb);
        let endpoints = getNetworkCluster();
        if (!Array.isArray(endpoints)) endpoints = [endpoints];

        const { fldnet, rfldnet, bsnnet, bsn2net, net, gychainnet, smftnet, tonnet } = netKeys;
        const netConfig = {
            network: {
                endpoints,
                queries_protocol: [fldnet, rfldnet, bsnnet, bsn2net, net, gychainnet, smftnet, tonnet].includes(
                    EnvManager.getNetwork()
                )
                    ? 'HTTP'
                    : 'WS',
                access_key: SecretConfig.everCloudCredits.secret,
            },
        };
        console.log('Network config', {
            network: {
                endpoints: netConfig.network.endpoints,
                queries_protocol: netConfig.network.queries_protocol,
            },
        });
        TONClient.network = new TONCoreClient(netConfig);
    }

    static async setupLogs() {
        // for future
        // TONLogSettings.shared.writer = {
        //     debug(...args: any[]) {
        //         console.log(...args);
        //     },
        //     info(...args: any[]) {
        //         console.log(...args);
        //     },
        //     warning(...args: any[]) {
        //         console.warn(...args);
        //     },
        //     error(...args: any[]) {
        //         console.error(...args);
        //     },
        // };

        if (TONEnvironment.isDevelopment() || EnvManager.isTestHost()) {
            // [un]comment lines below for better logs management when developing
            // TONLogSettings.shared.disableDebug();
            // console.disable = true;
            const includes = (s, arrayOfStrings) => {
                return arrayOfStrings.reduce((res, curr) => res || s.includes(curr), false);
            };

            const filterSpam = (originalLog) => {
                return (...args) => {
                    const s = args.join().toLowerCase();
                    let spamer = '';
                    if (
                        includes(s, [
                            '[mtp',
                            '[initnetworker',
                            '[requestwith]',
                            '[performrequest]',
                            '[chooseserver',
                            '[initconnection',
                        ])
                    ) {
                        spamer = 'mtproto';
                    } else if (s.includes('[] nw_')) {
                        spamer = 'nw';
                    } else if (s.includes('[wrapapical')) {
                        spamer = 'api';
                    } else if (
                        includes(s, [
                            'using the "classname" prop on',
                            'failed prop type',
                            'does not recognize the',
                            'warning: componentwillreceiveprops',
                            'warning: componentwillupdate',
                            'warning: componentwillmount',
                            'animated: `usenativedriver`',
                        ])
                    ) {
                        spamer = 'react';
                    }
                    if (spamer) {
                        // originalLog('SPAM from: ', spamer);
                    } else {
                        originalLog(...args);
                    }
                };
            };
            console.info = filterSpam(console.info);
            console.trace = filterSpam(console.trace);
            console.debug = filterSpam(console.debug);
            console.log = filterSpam(console.log);
            console.warn = filterSpam(console.warn);
            console.error = filterSpam(console.error);
        } else {
            TONLogSettings.shared.disableAll();
            console.log = () => {};
            console.info = () => {};
            console.warn = () => {};
            console.debug = () => {};
        }
    }

    static async setupLocalization() {
        await localizationService.load();
    }

    static setupGoogleAnalytics() {
        if (GOOGLE_ANALYTICS_TRACKING_ID) {
            ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
        }
    }

    static async setup() {
        await TONCache.setAsyncStorage(localStorage);
        await Promise.all([this.setupLocalization(), this.setupTONClientSDK(), this.setupLogs()]);
        this.setupGoogleAnalytics();
    }

    static isETHEnabled() {
        return false;
    }
}
