// @flow
import { TONLog } from '#TONUtility';
import MomentHelper from '#helpers/MomentHelper';
import IParticipantAbi from '#TONClient/TONDePool/IParticipantAbi';
import { liveLocalized } from '@services/LocalizationService';
import FBContracts from '@Firebase/FBContracts';
import { TONAccount } from '#TONClient';
import UserTokenAbi from '#TONClient/EVERTip3/abi/UserToken.abi.json';
import { getAccountMessagesQuery, result } from '#TONClient/TONMessage/helpers';
import { FBQueryWrapper, getMasterSeqNoRangeFromNewApi } from '#TONClient/helpers';
import EnvManager from '#helpers/EnvManager';

import TONClient from '../TONClient';
import TONFilter from '../TONFilter';
import { ERROR, NOT_FOUND, SUCCESS } from '../statuses';
import type { GetMessagesArgs, JoinedMessageFields, MessageFilterValues, TONMessageT } from './types';
import { dstTransactionFields, shortFields } from './fields';
import Utils from '#helpers/Utils';

const customLog = new TONLog('TONMessage');
let newMessagesById = {};
let subscriptions = [];
const firstBytesCount = 20;

export default class TONMessage {
    static types = {
        internal: 0,
        extIn: 1,
        extOut: 2,
    };

    static statuses = {
        unknown: 0,
        queued: 1,
        processing: 2,
        preliminary: 3,
        proposed: 4,
        finalized: 5,
        refused: 6,
        transiting: 7,
    };

    static inMsgTypes = {
        external: 0,
        ihr: 1,
        immediately: 2,
        final: 3,
        transit: 4,
        discardedFinal: 5,
        discardedTransit: 6,
    };

    static formatter = (msgDoc: TONMessageT, expandedMsgDoc?: JoinedMessageFields): TONMessageT => ({
        ...msgDoc,
        id: msgDoc.id?.replace('message/', '') || '',
        // joined fields
        parent_tr: expandedMsgDoc?.parent_tr
            ? {
                  ...expandedMsgDoc.parent_tr,
                  id: expandedMsgDoc.parent_tr.id.replace('transaction/', ''),
              }
            : null,
        child_tr: expandedMsgDoc?.child_tr
            ? {
                  ...expandedMsgDoc.child_tr,
                  id: expandedMsgDoc.child_tr.id.replace('transaction/', ''),
              }
            : null,
    });

    static newFormatter = (message: TONMessageT, expandedMsgDoc?: JoinedMessageFields): TONMessageT => ({
        ...message,
        id: message.id?.replace('message/', '') || '',
        parent_tr: expandedMsgDoc?.parent_tr,
        child_tr: expandedMsgDoc?.child_tr,
    });

    static shortFields = shortFields;
    static dstTransactionFields = dstTransactionFields;

    static fields = {
        Src: 'Src',
        Dst: 'Dst',
        Int: 'Int',
        Ext: 'Ext',
        ExtIn: 'ExtIn',
        ExtOut: 'ExtOut',
    };

    static messageTypes = {
        Int: 'IntIn, IntOut',
        ExtOut: 'ExtOut',
        ExtIn: 'ExtIn',
    };

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('TONMessage', str, params);
    }

    static extIntFilter(filterValues: MessageFilterValues) {
        const { extInt = '' } = TONFilter.getValuesFromFilterValues(filterValues);
        if (!extInt) return {};

        const msgType = {
            [this.fields.Int]: 0,
            [this.fields.ExtIn]: 1,
            [this.fields.ExtOut]: 2,
        }[extInt];

        if (msgType !== undefined) {
            return { msg_type: { eq: msgType } };
        }

        return {};
    }

    static valueFilter(filterValues: MessageFilterValues, isJSFetchQuery: boolean = false) {
        const { minValue, maxValue } = filterValues || {};
        return TONFilter.minMaxNumberFilter(
            {
                key: 'value',
                minParam: minValue,
                maxParam: maxValue,
                denormalize: true,
            },
            isJSFetchQuery
        );
    }

    static async filter(args: GetMessagesArgs, key?: string, isJSFetchQuery: boolean = false) {
        const { filterValues } = args;

        return {
            ...(await TONFilter.accountWorkchainFilter(filterValues, key, isJSFetchQuery)),
            ...(this.extIntFilter(filterValues): any),
            ...this.valueFilter(filterValues, isJSFetchQuery),
            ...TONFilter.timePaginationFilter({
                args,
                key: 'created_at',
            }),
        };
    }

    static async filterString(args: GetMessagesArgs, key?: string) {
        const { filterValues } = args;

        return {
            ...(await TONFilter.accountWorkchainFilter(filterValues, key)),
            ...(this.extIntFilter(filterValues): any),
            ...this.valueFilter(filterValues),
            ...TONFilter.timePaginationFilter({
                args,
                key: 'created_at',
            }),
        };
    }

    static async newFilter(args: GetMessagesArgs): Promise<string> {
        const { filterValues, direction, limit, lastItem } = args;
        const messageType = filterValues?.extInt?.value;
        const minValue = filterValues?.minValue?.value;

        const type = messageType ? `msg_type:[${this.messageTypes[messageType]}],` : '';
        const masterSeqNoRange = await getMasterSeqNoRangeFromNewApi(
            filterValues?.minTime?.value,
            filterValues?.maxTime?.value
        );
        const min = minValue ? `min_value:"${TONFilter.convertTonsToHexNano(minValue)}",` : '';
        const cursorPosition = lastItem?.endCursor
            ? `${direction === 'DESC' ? 'before' : 'after'}: "${lastItem.endCursor}",`
            : '';

        const itemsRange = limit ? `${direction === 'DESC' ? 'last' : 'first'}: ${limit},` : '';

        return `${type}${masterSeqNoRange}${min}${cursorPosition}${itemsRange}`;
    }

    static mergeMessageLists(lists: TONMessageT[][], args: GetMessagesArgs): TONMessageT[] {
        const msgsById = {};
        return lists
            .reduce((res, curr) => res.concat(curr), [])
            .filter((item) => {
                const wasBefore = msgsById[item.id];
                msgsById[item.id] = true;
                return !wasBefore;
            })
            .sort(
                args.direction === 'DESC'
                    ? (a, b) => b.created_at - a.created_at
                    : (a, b) => a.created_at - b.created_at
            )
            .slice(0, args.limit || 0)
            .map<TONMessageT>((msg) => this.formatter(msg));
    }

    static async requestMessages(args: GetMessagesArgs, key: string): Promise<TONMessageT[]> {
        const { srcDst = '' } = TONFilter.getValuesFromFilterValues(args?.filterValues);
        if (srcDst && key !== srcDst.toLowerCase()) return [];

        const filter = await this.filter(args, key, true);
        const orderBy = TONFilter.orderBy(args, 'created_at', true);

        const msgDocs: TONMessageT[] = await TONClient.queryMessages(
            Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : '',
            result(args),
            Utils.JSONStringifyWithoutParanthesesAroundKeys(orderBy[0]),
            args.limit
        );

        customLog.debug(`${msgDocs.length} messages were successfully requested`, msgDocs, { args, filter });
        return msgDocs;
    }

    static async getMessages(args: GetMessagesArgs, needFormatting: boolean = true): Promise<any[]> {
        const log = this.initLog('list', { args }); // filter

        try {
            const [dstDocs, srcDocs]: TONMessageT[][] = await Promise.all([
                this.requestMessages(args, 'dst'),
                this.requestMessages(args, 'src'),
            ]);

            const concatArray: TONMessageT[] = [...dstDocs, ...srcDocs];
            if (concatArray.length) {
                const resultDocs = needFormatting ? this.mergeMessageLists([dstDocs, srcDocs], args) : concatArray;

                log.debug(SUCCESS, resultDocs);
                return resultDocs;
            }

            log.debug(NOT_FOUND);
            return [];
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async getAccountMessages(args: GetMessagesArgs): Promise<TONMessageT[] | null> {
        const sortingKey = 'created_at';
        const log = this.initLog('list messages', { args });

        try {
            const query = await getAccountMessagesQuery(args);
            const { messages, endCursor } = await TONClient.queryAccountMessages(query, args.direction);

            if (!messages?.length) {
                log.debug(NOT_FOUND);
                return [];
            }

            TONFilter.sortBy(messages, args, sortingKey);
            const formattedMessages = messages.map((message, index) => ({
                ...this.formatter(message),
                ...(index === messages.length - 1 ? { endCursor: endCursor } : {}),
            }));

            log.debug(SUCCESS, formattedMessages);
            return formattedMessages;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async aggregateTransfers(for24h: boolean = false) {
        const filter = for24h
            ? {
                  msg_type: { eq: this.types.internal },
                  created_at: { gt: MomentHelper.dayBefore() },
              }
            : {
                  value: { gt: null },
              };
        const log = this.initLog('transfers count', { filter });

        return TONClient.aggregateItems({ filter, collection: 'messages', log });
    }

    static async aggregateMessages(filterValues: MessageFilterValues) {
        const filter = await this.filter({ filterValues }, '', true);
        const log = this.initLog('messages count', { filterValues, filter });

        try {
            const messagesCount = await TONClient.aggregateMessages(
                Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : ''
            );

            log.debug(messagesCount);
            return messagesCount;
        } catch (e) {
            log.error(e);
            return null;
        }
    }

    static async getMessage(mId: string, args?: GetMessagesArgs): Promise<*> {
        const log = this.initLog(mId);
        try {
            const message = await TONClient.queryMessage(mId, result({ ...args, needTransactionFields: true }));

            if (message?.id) {
                const decodedBody = await this.decodeMessageBody(message);

                const formattedMsg = this.formatter(
                    {
                        ...message,
                        decodedBody,
                    },
                    {
                        parent_tr: message.src_transaction,
                        child_tr: message.dst_transaction,
                    }
                );

                log.debug(SUCCESS, formattedMsg);
                return formattedMsg;
            }
            log.debug(NOT_FOUND);
            return null;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async decodeMessageBody(msg: TONMessageT): Promise<string> {
        const log = this.initLog('decodeMessageBody', msg.body);
        const simpleDecodeBody = Buffer.from(msg.body || '', 'base64')
            .slice(firstBytesCount)
            .toString('utf8')
            .trim();

        if (!msg.body) return simpleDecodeBody;

        try {
            const result = await TONClient.decodeMessageBody({
                abi: IParticipantAbi,
                body: msg.body,
                internal: true,
            }).then((decoded) => {
                if (!decoded?.value || decoded?.value?.errcode) {
                    return simpleDecodeBody;
                }

                const lockStake = TONClient.nanoToTons(decoded.value.lockStake);
                const reward = TONClient.nanoToTons(decoded.value.reward);
                const ordinaryStake = TONClient.nanoToTons(decoded.value.ordinaryStake);
                const vestingStake = TONClient.nanoToTons(decoded.value.vestingStake);
                const reason = TONClient.hex0xToDec(decoded.value.reason);
                const roundId = TONClient.hex0xToDec(decoded.value.roundId);

                const layoutParsingBody =
                    'ordinaryStake: {0}\nlockStake: {1}\nvestingStake: {2}\nreward: {3}\n' +
                    'roundId: {4}\nreason: {5}\nreinvest: {6}';

                const decodeBodyValues = [
                    ordinaryStake,
                    lockStake,
                    vestingStake,
                    reward,
                    roundId,
                    reason,
                    decoded.value.reinvest,
                ];

                return liveLocalized.formatString(layoutParsingBody, ...decodeBodyValues);
            });

            log.debug(result);
            return result;
        } catch (err) {
            log.warning(err);
        }

        return simpleDecodeBody;
    }

    static unsubscribe() {
        if (subscriptions.length) {
            subscriptions.forEach((item) => {
                customLog.debug('Stop listen for messages update...', item);
                TONClient.unsubscribe(item);
            });
            subscriptions = [];
        }
    }

    static newMessagesById = {};

    static async subscribeForMessages(callback: (?TONMessageT) => void, args: GetMessagesArgs, key: string) {
        const filter = await this.filter(args, key);
        customLog.debug('Start listening for messages update...', args, key, filter);

        const newSubscription = await TONClient.subscribeCollectionMessages(filter, shortFields, (msg) => {
            customLog.debug('Message was updated', msg);
            if (newMessagesById[msg.id]) {
                return;
            }

            newMessagesById[msg.id] = 1;
            callback(this.formatter(msg));
        });

        subscriptions.push(newSubscription);
        return newSubscription;
    }

    static subscribeForUpdate(
        callback: (?TONMessageT) => void,
        args: GetMessagesArgs,
        multipleSubscribe: boolean = false
    ) {
        !multipleSubscribe && this.unsubscribe();
        newMessagesById = {};
        const sub1 = this.subscribeForMessages(callback, args, 'src');
        const sub2 = this.subscribeForMessages(callback, args, 'dst');
        return [sub1, sub2];
    }

    static async aggregateRewardsForTheMonth() {
        return TONClient.aggregateCollectionMessages(
            {
                src: { eq: TONClient.addresses.creator },
                dst: { eq: TONClient.addresses.elector },
                created_at: { gt: MomentHelper.monthBefore() },
            },
            [{ field: 'value', fn: 'SUM' }]
        );
    }

    static async decodeExtInMsgBody(msg: TONMessageT): Promise<*> {
        if (msg.msg_type === 1) {
            const log = this.initLog('decodeExtInMsgBody');
            const [contractsByCodeHash, account] = await Promise.all([
                FBQueryWrapper(FBContracts.getContractsByCodeHash, null)(),
                TONAccount.getAccountForRunTVM(msg.dst),
            ]);
            const contract = contractsByCodeHash && contractsByCodeHash[account.code_hash];
            if (contract && FBContracts.isWalletContract(contract) && contract.abi) {
                const result = await TONClient.decodeMessageBody({
                    abi: contract.abi,
                    body: msg.body || '',
                    internal: false,
                }).catch((e) => log.warning(ERROR, e));

                log.debug(SUCCESS, result);
                return result;
            }
        }

        return {};
    }

    // Running row
    static timestampByNetKey = {
        net: 1635586800,
        main: 1635600600,
    };

    static rootByNetKey = {
        net: '0:41a545c8d0ab66dac0cf5bcc1fac76c1022e0d83c57d68d0327851d10e9a61a9',
        main: '0:68618890c447cc6be870142c062f5e3f64d42c7925531615cf77e3598edd35e2',
    };

    static runningRowFilter = {
        src: {
            eq: this.rootByNetKey[EnvManager.getNetwork()],
        },
        value: { le: '1000000000' },
        body_hash: { notIn: [null] },
    };
    static runningRowFields = 'id created_at body body_hash';

    static getPrice(created_at: number) {
        const start = this.timestampByNetKey[EnvManager.getNetwork()];
        const minute = (created_at - start) / 60; // start <= now < start+3600 sec
        const price = 0.1 + minute * 0.01;
        return Math.round(price * 100) / 100;
    }

    static decodeRunningRowMessage(msg: TONMessageT) {
        return TONClient.decodeMessageBody({
            abi: UserTokenAbi,
            body: msg.body,
            internal: true,
        })
            .then((res) => ({ ...msg, ...res }))
            .catch(() => {});
    }

    static runningRowFormatter = (item: any) => {
        return {
            ...item,
            stake: TONClient.nanoToTons(item.value.stake),
            price: this.getPrice(item.created_at),
        };
    };

    static async getMessagesForRunningRow() {
        const log = this.initLog('Parse messages for running row');

        const msgs = await TONClient.loadAllInRange({
            multiThread: true,
            collection: TONClient.collections.messages,
            getFilter: (lastItem: any) => ({
                ...TONFilter.timePaginationFilter({
                    args: { lastItem, direction: 'ASC' },
                    key: 'created_at',
                }),
                ...this.runningRowFilter,
            }),
            order: [{ path: 'created_at', direction: 'ASC' }],
            result: this.runningRowFields,
            log: this.initLog('Filter messages for running row'),
        });

        const result: any[] = await Promise.all(msgs.map((msg) => this.decodeRunningRowMessage(msg))).then((items) => {
            return items
                .filter((item) => item?.name === 'IsAcceptStake')
                .map(this.runningRowFormatter)
                .sort((a, b) => a.created_at - b.created_at);
        });

        log.debug(SUCCESS, result);
        return result;
    }

    static async subscribeForRunningRow(callback: (?TONMessageT) => void) {
        newMessagesById = {};
        const log = this.initLog('Subscribe for running row messages');
        log.debug(callback);

        const newSubscription = await TONClient.subscribeCollectionMessages(
            this.runningRowFilter,
            this.runningRowFields,
            (msg) => {
                log.debug('Message was updated', msg);

                if (!msg || newMessagesById[msg.id]) {
                    return;
                }

                newMessagesById[msg.id] = 1;
                this.decodeRunningRowMessage(msg).then((decodedMsg) => {
                    if (decodedMsg?.name === 'IsAcceptStake') {
                        const formattedMsg = this.runningRowFormatter(decodedMsg);
                        log.debug('Insert decoded and formatted msg', formattedMsg);
                        callback(formattedMsg);
                    }
                });
            }
        );

        subscriptions.push(newSubscription);
        return newSubscription;
    }
}
