// @flow
import { CSSProperties } from 'react';

import { liveLocalized } from '@services/LocalizationService';
import { TransferTypeFilterType } from '#TONClient/EVERTransfer/types';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { ColorVariants } from '#uikit/designCore/constants/color';

import TONMessage from './TONMessage';
import type { TONShardHash } from './EVERBlock/types';

export type FilterProp = {
    type: string,
    list?: (string | number)[],
    defaultValue?: ?(string | number),
    replaceTitle?: boolean,
    renderer?: (any) => {
        title: string,
        titleStyle?: CSSProperties,
    },
    strict?: boolean,
};

export type FilterPropObj = {
    [string]: FilterProp,
};

export type WorkchainFilterProp = {|
    workchain_id?: FilterProp,
|};

export type TimeFilterProp = {|
    minTime: FilterProp,
    maxTime: FilterProp,
|};

export type MessageFilterProps = {|
    ...WorkchainFilterProp,
    accountId?: FilterProp,
    extInt?: FilterProp,
    srcDst?: FilterProp,
    ...TimeFilterProp,
    minValue: FilterProp,
    maxValue: FilterProp,
|};

export default class TONFilterTags {
    static propTypes = {
        boolean: 'boolean',
        double: 'double',
        signedDouble: 'signedDouble',
        integer: 'integer',
        string: 'string',
        time: 'time',
        switcher: 'switcher',
    };

    static workchainProp = (): WorkchainFilterProp => ({
        workchain_id: {
            type: this.propTypes.switcher,
            renderer: (num) => ({ title: `${num}` }),
        },
    });

    static timeProp = () => ({
        minTime: {
            type: this.propTypes.time,
            defaultValue: MomentHelper.getDefaultTimestamp(),
        },
        maxTime: {
            type: this.propTypes.time,
        },
    });

    static getShortMessageProps(): MessageFilterProps {
        return {
            ...this.timeProp(),
            minValue: {
                type: this.propTypes.double,
            },
            maxValue: {
                type: this.propTypes.double,
            },
        };
    }

    static srcDstProp = {
        srcDst: {
            type: this.propTypes.switcher,
            list: [TONMessage.fields.Src, TONMessage.fields.Dst],
            replaceTitle: true,
            renderer: (str: string) => ({ title: liveLocalized[str] }),
        },
    };

    static getStakesProps() {
        return {
            // ...this.srcDstProp,
            minTime: {
                type: this.propTypes.time,
                defaultValue: MomentHelper.monthBefore(),
            },
            maxTime: {
                type: this.propTypes.time,
            },
            // minValue: {
            //     type: this.propTypes.signedDouble,
            // },
            // maxValue: {
            //     type: this.propTypes.signedDouble,
            // },
        };
    }

    static getMessageProps(needAccountProp: boolean = false, needSrcDst: boolean = false): MessageFilterProps {
        const accountProp = {
            accountId: {
                type: this.propTypes.string,
            },
        };

        // $FlowExpectedError
        return {
            ...this.workchainProp(),
            ...((needAccountProp ? accountProp : {}): { accountId?: FilterProp }),
            extInt: {
                type: this.propTypes.switcher,
                list: [TONMessage.fields.Int, TONMessage.fields.ExtIn, TONMessage.fields.ExtOut],
                replaceTitle: true,
                renderer: (str: string) => ({ title: liveLocalized[str] }),
            },
            ...(needSrcDst && this.srcDstProp),
            ...this.timeProp(),
            minValue: {
                type: this.propTypes.double,
            },
            maxValue: {
                type: this.propTypes.double,
            },
        };
    }

    static getAccountMessageProps(): { extInt: FilterProp, minValue: FilterProp } {
        return {
            extInt: {
                type: this.propTypes.switcher,
                list: [TONMessage.fields.Int, TONMessage.fields.Ext],
                replaceTitle: true,
                renderer: (str: string) => ({ title: liveLocalized[str] }),
            },
            ...this.srcDstProp,
            ...this.timeProp(),
            minValue: {
                type: this.propTypes.double,
            },
        };
    }

    static getTransactionProps(needWorkchainProp: boolean = true, needTimeProp: boolean = true): FilterPropObj {
        const workchainProp: any = needWorkchainProp ? this.workchainProp() : {};
        const timeProp: any = needTimeProp ? this.timeProp() : {};

        return {
            ...workchainProp,
            ...timeProp,
            minBalanceDelta: {
                type: this.propTypes.signedDouble,
            },
            maxBalanceDelta: {
                type: this.propTypes.signedDouble,
            },
        };
    }

    static getAccountTransactionProps(needWorkchainProp: boolean = true, needTimeProp: boolean = true): FilterPropObj {
        const workchainProp: any = needWorkchainProp ? this.workchainProp() : {};
        const timeProp: any = needTimeProp ? this.timeProp() : {};

        return {
            ...workchainProp,
            ...timeProp,
            aborted: {
                type: this.propTypes.boolean,
                renderer: () => liveLocalized.Statuses.Aborted,
            },
            minBalanceDelta: {
                type: this.propTypes.signedDouble,
            },
            maxBalanceDelta: {
                type: this.propTypes.signedDouble,
            },
        };
    }

    static getAccountTransferProps(): FilterPropObj {
        return {
            transferType: {
                type: this.propTypes.switcher,
                list: [
                    TransferTypeFilterType.MINT,
                    TransferTypeFilterType.BURN,
                    TransferTypeFilterType.SEND,
                    TransferTypeFilterType.RECEIVE,
                    TransferTypeFilterType.REVERT_BURN,
                    TransferTypeFilterType.REVERT_MINT,
                    TransferTypeFilterType.REVERT_SEND,
                    TransferTypeFilterType.REVERT_RECEIVE,
                ],
                replaceTitle: true,
                renderer: (str: string) => ({ title: liveLocalized.transferType[str] }),
            },
        };
    }

    static getBlockProps(): FilterPropObj {
        return {
            ...this.workchainProp(),
            key_block: {
                type: this.propTypes.boolean,
            },
            shard: {
                type: this.propTypes.switcher,
                renderer: (block: TONShardHash) => ({
                    title: `${block.workchain_id}:${Utils.getShard(block)}`,
                    titleStyle:
                        block?.descr?.gen_utime < MomentHelper.now() - 300
                            ? ColorVariants.TextNegative
                            : ColorVariants.TextPrimary,
                }),
            },
            ...this.timeProp(),
            minTransactions: {
                type: this.propTypes.integer,
            },
            maxTransactions: {
                type: this.propTypes.integer,
            },
        };
    }

    static getAccountProps(): FilterPropObj {
        return {
            ...this.workchainProp(),
            ...this.timeProp(),
            minBalance: {
                type: this.propTypes.double,
            },
            maxBalance: {
                type: this.propTypes.double,
            },
        };
    }

    static getDefaultDePoolProps(): FilterPropObj {
        return {
            minDePoolFee: {
                type: this.propTypes.double,
            },
            maxDePoolFee: {
                type: this.propTypes.double,
            },
            minStake: {
                type: this.propTypes.double,
                defaultValue: 1,
            },
            maxStake: {
                type: this.propTypes.double,
            },
        };
    }

    static getNameProp(): FilterPropObj {
        return {
            name: {
                type: this.propTypes.string,
                renderer: (str: string) => ({ title: str ? `...${str}...` : liveLocalized.tags.name.title }),
            },
        };
    }

    static getDePoolProps(): FilterPropObj {
        return {
            ...this.getNameProp(),
            // $FlowExpectedError
            ...TONFilterTags.getDefaultDePoolProps(),
            minParticipants: {
                type: this.propTypes.integer,
            },
            maxParticipants: {
                type: this.propTypes.integer,
            },
            minAssurance: {
                type: this.propTypes.double,
            },
            maxAssurance: {
                type: this.propTypes.double,
            },
        };
    }

    static getValidatorProps(): FilterPropObj {
        return {
            ...this.getNameProp(),
            minStake: {
                type: this.propTypes.double,
            },
            maxStake: {
                type: this.propTypes.double,
            },
        };
    }

    static getContractProps(needCodeHashAndName: boolean = true): FilterPropObj {
        return {
            ...(needCodeHashAndName && {
                ...this.getNameProp(),
                code_hash: {
                    type: this.propTypes.string,
                    renderer: (str: string) => {
                        const { length } = str;

                        if (str && length >= 16) {
                            return { title: `${str.substring(0, 3)}...${str.substring(length - 3, length)}` };
                        } else if (str && length < 16) {
                            return { title: `${str}...` };
                        }

                        return { title: liveLocalized.tags.code_hash.title };
                    },
                },
            }),
            ...this.timeProp(),
            minBalance: {
                type: this.propTypes.double,
            },
            maxBalance: {
                type: this.propTypes.double,
            },
            minTime: {
                type: this.propTypes.time,
                defaultValue: MomentHelper.monthBefore(),
            },
        };
    }

    static getNFTProps(): FilterPropObj {
        return {
            ...this.getNameProp(),
            addrOwner: {
                type: this.propTypes.string,
                renderer: (str: string) => ({
                    title: str ? Utils.truncText(str) : liveLocalized.tags.addrOwner.title,
                }),
            },
            addrData: {
                type: this.propTypes.string,
                renderer: (str: string) => ({
                    title: str ? Utils.truncText(str) : liveLocalized.tags.address.title,
                }),
            },
        };
    }

    static getTIP3TokensProps(): FilterPropObj {
        return {
            name: {
                type: this.propTypes.string,
                renderer: (str: string) => ({ title: str ? `...${str}...` : liveLocalized.tags.name.title }),
            },
            symbol: {
                type: this.propTypes.string,
                renderer: (str: string) => ({ title: str ? `...${str}...` : liveLocalized.tags.symbol.title }),
            },
            // rootAddress: {
            //     type: this.propTypes.string,
            //     renderer: (str: string) => ({
            //         title: str ? Utils.truncText(str) : liveLocalized.tags.address.title,
            //     }),
            // },
        };
    }

    static getTokenHoldersProps(): FilterPropObj {
        return {
            ownerAddress: {
                type: this.propTypes.string,
                renderer: (str: string) => ({
                    title: str ? Utils.truncText(str) : liveLocalized.tags.addrOwner.title,
                }),
            },
        };
    }

    static getTokenDetailsProps(): FilterPropObj {
        return {
            ...this.getTokenHoldersProps(),
            minBalance: {
                type: this.propTypes.double,
            },
            maxBalance: {
                type: this.propTypes.double,
            },
        };
    }

    // for external control lists
    static getDePoolFilterMinMaxParams = (needAdditionalFilters?: boolean) => {
        return {
            ...(needAdditionalFilters
                ? {
                      participantsCount: {
                          min: 'minParticipants',
                          max: 'maxParticipants',
                      },
                  }
                : {}),
            validatorRewardFraction: {
                min: 'minDePoolFee',
                max: 'maxDePoolFee',
            },
            validatorAssurance: {
                min: 'minAssurance',
                max: 'maxAssurance',
                normalize: true,
            },
            stakes: {
                min: 'minStake',
                max: 'maxStake',
                normalize: true,
            },
        };
    };
}
