// @flow
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import DetailsScreen from '#controllers/Details/DetailsScreen';
import MasterDetails from '#controllers/Details/Block/helpers/MasterDetails';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import { TONClient, TONValidator } from '#TONClient';
import type { TONZeroState } from '#TONClient/EVERBlock/types';
import { TONLog } from '#TONUtility';
import { Accordion, ValidatorView, UIDetailsTable } from '#components';
import { onWillFocus } from '#navigation/helpers';
import { margin } from '#uikit/designCore/styles/margin';
import { TypographyVariants } from '#uikit/designCore/constants/font';

const log = new TONLog('ZeroStateScreen');

const ZeroStateComponent = () => {
    const ref = useRef(null);

    const [zeroState, setZeroState] = useState<?TONZeroState>(null);

    // details
    const { id, workchain_id, global_id, master, total_balance, total_balance_other } = zeroState || {};
    const { TONZeroState: zeroStateLocale } = liveLocalized;

    const details = [
        DetailsRow.copyableRow(liveLocalized.ID, id),
        {
            caption: liveLocalized.TONBlock.global_id,
            value: global_id,
        },
        {
            caption: liveLocalized.Workchain,
            value: workchain_id,
        },
        DetailsRow.crystals(zeroStateLocale.total_balance, total_balance, true),
        ...DetailsRow.otherCurrency(total_balance_other, zeroStateLocale.total_balance_other),

        DetailsRow.crystals(zeroStateLocale.global_balance, master?.global_balance, true),
        ...DetailsRow.otherCurrency(master?.global_balance_other, zeroStateLocale.global_balance_other),

        {
            caption: zeroStateLocale.validator_list_hash_short,
            value: master?.validator_list_hash_short,
        },
    ];

    // validators listItems
    const { p32, p34, p36 } = zeroState?.master?.config || {};
    const listItems = [
        ...(p32?.list?.length ? p32.list : []),
        ...(p34?.list?.length ? p34.list : []),
        ...(p36?.list?.length ? p36.list : []),
    ];

    const totalWeight = TONClient.hexToDec(p34?.total_weight);

    // Actions
    const onLoadZeroState = async () => {
        controllerService.showSpinnerOverlay();
        try {
            const newZeroState = await TONValidator.getZeroState();

            setZeroState(newZeroState);
        } catch (e) {
            log.error(e);
            controllerService.showPageNotFound();
        }
        controllerService.hideSpinnerOverlay();
    };

    // accordions
    const { TONBlock: blockLocale } = liveLocalized;
    const sections = [
        {
            title: blockLocale.master.config.title,
            rows: MasterDetails.config(master || null),
        },
    ];

    const accordions = sections.map<React$Node>(({ title, rows }, index) => {
        if (!rows.length) return null;

        return (
            <Accordion
                key={`details-accordion-section-${title}`}
                classNames={classnames(controllerService.reactContentClassNames, margin.topDefault)}
                title={title}
            >
                <UIDetailsTable containerClassNames={index === 4 ? margin.topDefault : ''} detailsList={rows} />
            </Accordion>
        );
    });

    useEffect(() => {
        return onWillFocus({}, ref, onLoadZeroState);
    }, []);

    // Render
    return (
        <DetailsScreen
            ref={ref}
            title={liveLocalized.ZeroState}
            titleRole={TypographyVariants.TitleHuge}
            listTitle={liveLocalized.Validators}
            subtitleRole={TypographyVariants.TitleMedium}
            details={details}
            realtimeUpdated={false}
            needMoreDetailsButton={false}
            copyableViewData={null}
            collectionConfigs={ValidatorView.getCollectionConfigs(undefined, undefined, totalWeight, false)}
            externalControlConfigs={{
                ...ValidatorView.externalConfigs,
                items: listItems,
            }}
            detailsComponent={accordions}
            needListInDetails
        />
    );
};

const ZeroState = observer(ZeroStateComponent);

export { ZeroState };
