import TONClient from '../TONClient';

const { formatDEC } = TONClient;

export const shortFields = `
    id
    now
    tr_type
    tr_type_name
    account_addr
    balance_delta${formatDEC}
`;

export const accountCreatorFields = 'account_addr orig_status end_status in_message { src }';

export const additionalFields = `
    total_fees${formatDEC}
    lt${formatDEC}
    aborted
    status
    orig_status
    orig_status_name
    end_status
    end_status_name
    block_id
    in_msg
    in_message {
        id
        msg_type
        msg_type_name
        value${formatDEC}
        src
        dst
    }
    out_msgs
    out_messages {
        id
        dst
        value${formatDEC}
    }
    compute {
        gas_limit${formatDEC}
        gas_used${formatDEC}
        gas_fees${formatDEC}
        gas_credit
        compute_type
        compute_type_name
        skipped_reason
        success
        msg_state_used
        account_activated
        mode
        exit_code
        exit_arg
        vm_steps
        vm_init_state_hash
        vm_final_state_hash
    }
    prev_trans_hash
    prev_trans_lt${formatDEC}
    outmsg_cnt
    old_hash
    new_hash
    credit_first
    storage {
        storage_fees_collected${formatDEC}
        storage_fees_due${formatDEC}
        status_change
        status_change_name
    }
    credit {
        due_fees_collected${formatDEC}
        credit${formatDEC}
    }
    action {
        success
        valid
        no_funds
        status_change
        status_change_name
        total_fwd_fees${formatDEC}
        total_action_fees${formatDEC}
        result_code
        result_arg
        tot_actions
        spec_actions
        skipped_actions
        msgs_created
        action_list_hash
        total_msg_size_cells
        total_msg_size_bits
    }
    bounce {
        bounce_type
        bounce_type_name
        msg_size_cells
        msg_size_bits
        req_fwd_fees${formatDEC}
        msg_fees${formatDEC}
        fwd_fees${formatDEC}
    }
    destroyed
    tt
    split_info {
        cur_shard_pfx_len
        acc_split_depth
        this_addr
        sibling_addr
    }
    prepare_transaction
    installed
`;

export const binaryFields = 'proof boc';
