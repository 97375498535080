import React, { PureComponent, ReactNode } from 'react';

class UIPureComponent<Props, State> extends PureComponent<Props, State> {
    mounted = false;

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateSafely(state: Object, callback?: () => void) {
        if (!this.mounted) {
            return;
        }
        this.setState(state, callback);
    }

    render(): ReactNode {
        return null;
    }
}

export { UIPureComponent };
