// @flow
import { liveLocalized } from '@services/LocalizationService';
import UIDetailsTable from '#components/UIDetailsTable';

import type { DePoolParticipant, DePoolRound } from '#TONClient/TONDePool/types';
import MomentHelper from '#helpers/MomentHelper';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import { TONClient } from '#TONClient';

export const getParticipantTableRows = ({
    participant,
    key,
    needOffset = false,
}: {
    participant: DePoolParticipant,
    key: string,
    needOffset?: boolean,
}) => {
    if (!participant) return [];

    const {
        address,
        total,
        reinvest,
        reward,
        withdrawValue,
        stakes,
        vestings,
        vestingsData = {},
        locks,
        locksData = {},
        vestingDonor,
        lockDonor,
    } = participant;

    const participantDescrKey = `${key}-participant-descr`;

    const vestingExpire =
        vestingsData.lastWithdrawalTime +
        (vestingsData.remainingAmount / vestingsData.withdrawalValue) * vestingsData.withdrawalPeriod;
    const lockExpire = locksData.lastWithdrawalTime + locksData.withdrawalPeriod;

    return UIDetailsTable.formatNestedList({
        list: [
            { caption: liveLocalized.ParticipantDetails },
            DetailsRow.accountLinkWithCopy(liveLocalized.Account, address),
            DetailsRow.crystals(liveLocalized.TotalStake, total),
            DetailsRow.crystals(liveLocalized.Ordinary, stakes),
            {
                caption: liveLocalized.Reinvest,
                value: reinvest,
            },
            DetailsRow.crystals(liveLocalized.Reward, reward),
            {
                caption: liveLocalized.WithdrawValue,
                value: TONClient.nanoToTons(withdrawValue),
            },
            // {
            //     caption: liveLocalized.Stakes,
            //     captionType: UIDetailsTable.captionType.header,
            // },
            ...(vestings
                ? UIDetailsTable.formatNestedList({
                      list: [
                          { caption: liveLocalized.Vestings },
                          DetailsRow.crystals(liveLocalized.Value, vestings),
                          { caption: liveLocalized.Expire, value: MomentHelper.getFullTimeDate(vestingExpire) },
                          DetailsRow.accountLinkWithCopy(liveLocalized.Donor, vestingDonor),
                      ],
                      key: 'vestings',
                      needOffset,
                  })
                : []),
            ...(locks
                ? UIDetailsTable.formatNestedList({
                      list: [
                          { caption: liveLocalized.Locks },
                          DetailsRow.crystals(liveLocalized.Value, locks),
                          { caption: liveLocalized.Expire, value: MomentHelper.getFullTimeDate(lockExpire) },
                          DetailsRow.accountLinkWithCopy(liveLocalized.Donor, lockDonor),
                      ],
                      key: 'locks',
                      needOffset,
                  })
                : []),
        ],
        key: participantDescrKey,
        needOffset,
    });
};

export const getRoundTableRows = ({
    round,
    key,
    needOffset = false,
}: {
    round: DePoolRound,
    key: string,
    needOffset?: boolean,
}) => {
    if (!round) return [];

    const {
        id,
        stepName,
        stake,
        rewards,
        participantQty,
        validatorStake,
        unused,
        completionReason,
        completionReasonNum,
        supposedElectedAt,
    } = round;

    const roundDescrKey = `${key}-round-descr`;

    return UIDetailsTable.formatNestedList({
        list: [
            { caption: liveLocalized.RoundDetails },
            {
                caption: liveLocalized.RoundID,
                value: id,
            },
            {
                caption: liveLocalized.Step,
                value: liveLocalized.dePoolRoundSteps[stepName],
            },
            DetailsRow.crystals(liveLocalized.Stake, stake),
            {
                caption: liveLocalized.ElectionsID,
                value: MomentHelper.getFullTimeDate(supposedElectedAt) || '-',
            },
            {
                caption: liveLocalized.ParticipantQty,
                value: participantQty,
            },
            DetailsRow.crystals(liveLocalized.ValidatorStake, validatorStake),
            DetailsRow.crystals(liveLocalized.UnusedStake, unused),
            DetailsRow.crystals(liveLocalized.Rewards, rewards),
            DetailsRow.copyableRow(
                liveLocalized.CompletionReason,
                `${liveLocalized.dePoolCompletionReasons[completionReason]} (${completionReasonNum})`,
                false
            ),
            // {
            //     caption: liveLocalized.CompletionReason,
            //     value: `${liveLocalized.dePoolCompletionReasons[completionReason]} (${completionReasonNum})`,
            // },
        ],
        key: roundDescrKey,
        needOffset,
    });
};
