import { TIP3TokenService } from '@services/DataServices/TIP3Tokens';

import { AccountWallet } from './types';

const setWalletsByOwnerAddressInStorage = (ownerAddress: string, wallets: AccountWallet[]) => {
    const newWallets = [...(TIP3TokenService.walletsByOwnerAddress[ownerAddress] || []), ...wallets];

    TIP3TokenService.setWalletsForOneOwnerAddress(ownerAddress, newWallets);
};

export { setWalletsByOwnerAddressInStorage };
