import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import type { TONContractT } from '#TONClient/TONContract/types';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import { LinkWithChild, ShortBalanceView, UILabel } from '#components';
import type { TONCollectionConfigs } from '#components/EVERList';
import { paths } from '#navigation/paths';
import Utils from '#helpers/Utils';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin } from '#uikit/designCore/styles/margin';
import { container } from '#uikit/designCore/styles/container';
import { common } from '#uikit/designCore/styles/comon';

type Props = {
    item: TONContractT;
};

export const contractViewTestIDs = {
    smartContractView: 'smart-contract-view',
};

const ContractView = ({ item }: Props) => {
    if (!item) return null;

    const narrow = controllerService.isNarrow;
    const { code_hash, name, avatar, totalBalances, contractsCount } = item;

    const captionComponent = (
        <div className={classNames(common.displayFlex, container.centerLeft)}>
            {!narrow && (
                <UILabel
                    classNames={margin.rightTiny}
                    role={TypographyVariants.ParagraphFootnote}
                    color={ColorVariants.TextSecondary}
                >
                    {liveLocalized.TotalBalances}:{' '}
                </UILabel>
            )}
            <ShortBalanceView balance={totalBalances} />
        </div>
    );

    const { Contracts, ActiveAdjectivePlural, NewAdjectivePlural, amountToLocale } = liveLocalized;
    const detailsStr = (label: string, value: number) => {
        return `${narrow ? '' : `${label}: `}${amountToLocale(value || 0)}`;
    };
    const { total, active, recent } = contractsCount || {};
    const totalStr = detailsStr(Contracts, total);
    const activeStr = detailsStr(ActiveAdjectivePlural, active);
    const newStr = detailsStr(NewAdjectivePlural, recent);

    const secondDetails = !!contractsCount && `${totalStr} | ${activeStr} | ${newStr}`;

    return (
        <LinkWithChild navigationPath={paths.contracts.details} params={{ codeHash: item.code_hash }}>
            <UIDetailsButton
                testID={contractViewTestIDs.smartContractView}
                copyTarget={UIDetailsButtonCopyTargets.details}
                image={avatar}
                title={narrow ? Utils.truncTextToNarrow(name || '') : name}
                captionComponent={captionComponent}
                truncDetails
                details={code_hash}
                secondDetails={secondDetails}
            />
        </LinkWithChild>
    );
};

ContractView.externalConfigs = {
    items: null,
    sortingKey: 'totalBalances',
};

ContractView.getCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ code_hash }: TONContractT): string => {
            return `smart-contract-${code_hash}`;
        },
        idKey: 'code_hash',
        collectionName: liveLocalized.SmartContracts,
        renderItem: ({ item }: { item: any }) => <ContractView item={item} />,
    };
};

export default observer(ContractView);
