import React from 'react';
import classnames from 'classnames';

import { container, common, flex } from '#uikit/designCore/styles';

import './SpinnerOverlay.scss';

type Props = {
    visible: boolean;
};

const SpinnerOverlay: React.FC<Props> = ({ visible }) => {
    if (!visible) {
        return null;
    }

    return (
        <div
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
            className={classnames(container.absoluteFill, common.zIndex1)}
        >
            <div
                className={classnames(container.absoluteFill, common.displayFlex, flex.justifyCenter, flex.alignCenter)}
            >
                <div className="UISpinnerOverlay__circle1" />
                <div className="UISpinnerOverlay__circle2" />
            </div>
        </div>
    );
};

export { SpinnerOverlay };
