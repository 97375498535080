import { SortDirection } from '@eversdk/core';

import { TIP3TokenService } from '@services/DataServices/TIP3Tokens';
import { TONClient, TONFilter } from '#TONClient';
import { ERROR, NOT_FOUND, SUCCESS } from '#TONClient/statuses';
import { FilterValues } from '#TONClient/TONFilter';
import { getEndCursor } from '#TONClient/helpers';
import EnvManager from '#helpers/EnvManager';
import Utils from '#helpers/Utils';

import { setWalletsByOwnerAddressInStorage } from './storageSetters';
import type { TokenWallet, StatisticOfTokenType, Tip3Token, TokenStatisticsType, AccountWallet } from './types';
import {
    flexClientCodeHashes,
    getTokensField,
    isNetworkWithCache,
    KWTRootAddress,
    TIP_32_wallet_code_hash,
} from './constants';
import { queryTokens } from './queries';
import {
    getWalletsForAccount,
    getKWTData,
    getToken,
    getKWTWallets,
    getTokenStatistics,
    getStatisticOfToken,
    getPreparedToken,
    getTokenFilters,
    getWalletAccountToken,
    getAccountFlexClientTokens,
} from './helpers';
import FBTip3Tokens from '../../Firebase/FBTip3Tokens';
import { GetTokenArgs, TokensQueryType } from './types';

class EVERTip3 {
    private static isFBTokenWalletsListUpdating = false;
    private static accountAddressesForWhichDownloadKWTWalletsStarted: string[] = [];
    private static accountAddressesForWhichDownloadWalletsStarted: string[] = [];

    private static initLog(str: string | number, params?: any) {
        return TONClient.initLog('EVERToken', str, params);
    }

    public static getTIP32Tokens = async (args: GetTokenArgs, filterValues?: FilterValues) => {
        const log = EVERTip3.initLog('TIP32Tokens', { args, filterValues });

        try {
            const filters = getTokenFilters(args, filterValues);

            const response = await queryTokens<TokensQueryType>(filters, getTokensField);
            const tokens = response?.data?.ft.tokens;

            if (tokens) {
                const {
                    edges,
                    pageInfo: { endCursor: pageEndCursor },
                } = tokens;

                const formattedTokens = edges.map(({ node }, index) => ({
                    ...getPreparedToken(node),
                    ...(index === edges.length - 1
                        ? { endCursor: getEndCursor(args.direction || 'DESC', edges[0].cursor, pageEndCursor) }
                        : {}),
                }));
                log.debug(SUCCESS, formattedTokens);

                return formattedTokens;
            } else {
                log.debug(NOT_FOUND);
                return null;
            }
        } catch (e) {
            log.error(ERROR, e);
            return null;
        }
    };

    public static getTIP3Token = async (tokenAddress: string): Promise<Tip3Token | null> => {
        const log = EVERTip3.initLog('Tip3TokenById', { tokenAddress });

        try {
            const token = await getToken(tokenAddress);

            if (token) {
                log.debug(SUCCESS, token);
                return token;
            } else {
                log.debug(NOT_FOUND);
                return null;
            }
        } catch (e) {
            log.error(ERROR, e);
            return null;
        }
    };

    public static getKWT = async (): Promise<Tip3Token | null> => {
        const log = EVERTip3.initLog('KWT');

        try {
            const token = await getKWTData(log);
            if (token) {
                log.debug(SUCCESS, token);
                return token;
            } else {
                log.debug(NOT_FOUND);
                return null;
            }
        } catch (e) {
            log.error(ERROR, e);
            return null;
        }
    };

    public static fetchKWTWallets = async (): Promise<void> => {
        if (TIP3TokenService.KWTWallets) return;

        if (!isNetworkWithCache) {
            const holders = await EVERTip3.loadKWTWallets();
            TONFilter.sortBy(holders || [], { direction: SortDirection.DESC }, 'balance');

            TIP3TokenService.setKWTHolders(holders || []);

            return;
        }

        const holdersFromFB = await FBTip3Tokens.getTokenWalletsList(KWTRootAddress);
        TONFilter.sortBy(holdersFromFB || [], { direction: SortDirection.DESC }, 'balance');

        TIP3TokenService.setKWTHolders(holdersFromFB || []);

        EVERTip3.updateWalletsListInFBIfNeed();
    };

    private static loadKWTWallets = async (): Promise<TokenWallet[] | null> => {
        const log = EVERTip3.initLog('Load KWT holders');

        try {
            const token = await EVERTip3.getKWT();
            if (!token) {
                log.debug(NOT_FOUND, 'token not found');
                return null;
            }

            const wallets = await getKWTWallets(token, log);
            if (!wallets) {
                log.debug(NOT_FOUND);
                return null;
            }

            log.debug(SUCCESS, wallets);
            return wallets;
        } catch (e) {
            log.error(ERROR, e);
            return null;
        }
    };

    public static async fetchAccountWallets(accountId: string, codeHash: string) {
        EVERTip3.fetchKWTWallets();

        if (!isNetworkWithCache) {
            const wallets = await getWalletsForAccount(accountId);
            const accountToken = await (codeHash === TIP_32_wallet_code_hash
                ? EVERTip3.getWalletAccountToken
                : async () => null)(accountId);
            const flexClientTokens = await (flexClientCodeHashes.includes(codeHash)
                ? EVERTip3.getAccountFlexClientTokens
                : async () => null)(accountId);

            setWalletsByOwnerAddressInStorage(accountId, [
                ...(wallets || []),
                ...(accountToken ? [accountToken] : []),
                ...(flexClientTokens || []),
            ]);

            await EVERTip3.loadAndSetKWTWalletsByWalletAddressIfNeed(accountId);

            return;
        }

        if (!EVERTip3.accountAddressesForWhichDownloadWalletsStarted.includes(accountId)) {
            EVERTip3.accountAddressesForWhichDownloadWalletsStarted.push(accountId);
            EVERTip3.accountAddressesForWhichDownloadKWTWalletsStarted.push(accountId);

            const [wallets, KWTWallet] = await Promise.all([
                getWalletsForAccount(accountId),
                FBTip3Tokens.getKWTWalletForAccount(accountId),
            ]);
            const flexClientTokens = await (flexClientCodeHashes.includes(codeHash)
                ? EVERTip3.getAccountFlexClientTokens
                : async () => null)(accountId);
            const accountToken = await (codeHash === TIP_32_wallet_code_hash
                ? EVERTip3.getWalletAccountToken
                : async () => null)(accountId);

            setWalletsByOwnerAddressInStorage(accountId, [
                ...(wallets || []),
                ...(accountToken ? [accountToken] : []),
                ...(flexClientTokens || []),
            ]);
            if (KWTWallet) TIP3TokenService.setKWTWalletsByAddress(accountId, KWTWallet);
        }
    }

    public static getWalletAccountToken = async (accountID: string): Promise<AccountWallet | null> => {
        const log = EVERTip3.initLog('WalletAccountToken');

        const walletAccountToken = await getWalletAccountToken(accountID);

        if (walletAccountToken) {
            log.debug(SUCCESS, walletAccountToken);
            return walletAccountToken;
        } else {
            log.debug(NOT_FOUND);
            return null;
        }
    };

    private static getAccountFlexClientTokens = async (accountID: string): Promise<AccountWallet[] | null> => {
        const log = EVERTip3.initLog('Get account flex client tokens');

        const flexClientTokens = await getAccountFlexClientTokens(accountID);

        if (flexClientTokens) {
            log.debug(SUCCESS, flexClientTokens);
            return flexClientTokens;
        } else {
            log.debug(NOT_FOUND);
            return null;
        }
    };

    public static getTokenStatistics = async (): Promise<Partial<TokenStatisticsType> | null> => {
        const log = EVERTip3.initLog('TokenStatistics');

        try {
            if (EnvManager.isNetworkOnDappServer) {
                const numberOfWallets = await EVERTip3.getTokenWalletsCount();
                const statistics = { ...(numberOfWallets ? { numberOfWallets } : {}) };
                log.debug(SUCCESS, statistics);

                return { ...(numberOfWallets ? { numberOfWallets } : {}) };
            } else {
                const statistics = await getTokenStatistics();
                log.debug(SUCCESS, statistics);

                return statistics;
            }
        } catch (e) {
            log.debug(ERROR, e);
            return null;
        }
    };

    public static async getTokenWalletsCount(): Promise<number | null> {
        const log = EVERTip3.initLog('TokenWalletsCount');

        try {
            const walletsCount = await TONClient.aggregateAccounts(
                Utils.JSONStringifyWithoutParanthesesAroundKeys({
                    code_hash: {
                        in: [`"${TIP_32_wallet_code_hash}"`],
                    },
                })
            );

            if (!walletsCount) {
                log.debug(NOT_FOUND);
                return null;
            }

            log.debug(SUCCESS, walletsCount);
            return walletsCount;
        } catch (e) {
            log.error(ERROR, e);
            return null;
        }
    }

    public static getStatisticOfToken = async (tokenId: number): Promise<StatisticOfTokenType | null> => {
        const log = EVERTip3.initLog('TokensStatistic');

        try {
            if (EnvManager.isNetworkOnDappServer) {
                log.debug(NOT_FOUND);
                return null;
            } else {
                const statistics = await getStatisticOfToken(tokenId);

                if (statistics) {
                    log.debug(SUCCESS, statistics);
                    return statistics;
                } else {
                    log.debug(NOT_FOUND);
                    return null;
                }
            }
        } catch (e) {
            log.debug(ERROR, e);
            return null;
        }
    };

    private static updateWalletsListInFBIfNeed = async () => {
        const isWalletsListDeprecated = await FBTip3Tokens.checkNeedUpdateTokenWalletsListCache();
        const needUpdate = isWalletsListDeprecated && !EVERTip3.isFBTokenWalletsListUpdating;

        if (needUpdate) await EVERTip3.updateTokenWalletsList();
    };

    private static async updateTokenWalletsList(): Promise<void> {
        EVERTip3.isFBTokenWalletsListUpdating = true;

        const wallets = await EVERTip3.loadKWTWallets();
        if (wallets) await FBTip3Tokens.updateTokenWalletsListCache(wallets);

        EVERTip3.isFBTokenWalletsListUpdating = false;
    }

    private static loadAndSetKWTWalletsByWalletAddressIfNeed = async (accountAddress: string) => {
        if (!EVERTip3.accountAddressesForWhichDownloadKWTWalletsStarted.includes(accountAddress)) {
            EVERTip3.accountAddressesForWhichDownloadKWTWalletsStarted.push(accountAddress);

            const wallets = await EVERTip3.loadKWTWallets().then((wallets) =>
                (wallets || []).filter((wallet) => wallet.walletAddress === accountAddress)
            );

            TIP3TokenService.setKWTWalletsByAddress(accountAddress, wallets[0]);
        }
    };
}

export { EVERTip3 };
