import React from 'react';

import Utils from '#helpers/Utils';
import { UILabel, FlexContainer } from '#components';
import { UILabelProps } from '#components/UILabel/types';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

type Props = {
    detailsItemId?: string;
    to?: string;
    from?: string;
    fromAddressPlaceholder?: string;
    toAddressPlaceholder?: string;
};

const truncAddress = (addressPlaceholder: string, address?: string) => {
    if (!address) return <FootNote color={ColorVariants.TextTertiary}>{addressPlaceholder}</FootNote>;

    return Utils.truncAddress(address);
};

const FootNote = (props: UILabelProps) => (
    <UILabel {...props} role={TypographyVariants.ParagraphFootnote}>
        {props.children}
    </UILabel>
);

const ToFromDetails: React.FC<Props> = ({
    detailsItemId,
    to = '',
    from = '',
    fromAddressPlaceholder = '',
    toAddressPlaceholder = '',
}) => {
    const fromStr = from || fromAddressPlaceholder ? truncAddress(fromAddressPlaceholder, from) : '';
    const toStr = to || toAddressPlaceholder ? truncAddress(toAddressPlaceholder, to) : '';

    if (detailsItemId) {
        if (from === detailsItemId) {
            return toStr ? (
                <FlexContainer>
                    <FootNote color={ColorVariants.TextNegative}>&nbsp;→&nbsp;</FootNote> to&nbsp;{toStr}
                </FlexContainer>
            ) : null;
        } else {
            return fromStr ? (
                <FlexContainer>
                    <FootNote color={ColorVariants.TextPositive}>&nbsp;←&nbsp;</FootNote> from&nbsp;{fromStr}
                </FlexContainer>
            ) : null;
        }
    } else {
        return fromStr && toStr ? (
            <FlexContainer>
                {fromStr} <FootNote color={ColorVariants.TextPrimary}>&nbsp;→&nbsp;</FootNote> {toStr}
            </FlexContainer>
        ) : null;
    }
};

export { ToFromDetails };
