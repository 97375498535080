import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import { LinkWithChild, UILabel, BalanceView } from '#components';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import { TONClient } from '#TONClient';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import MomentHelper from '#helpers/MomentHelper';
import Utils from '#helpers/Utils';
import { paths } from '#navigation/paths';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import FlexContainer from '#components/common/FlexContainer';

type Props = {
    item: TONMessageT;
    accountId?: string;
};

export const messageViewTestIDs = {
    messageView: 'messageView',
};

const FootNote = (props: any) => (
    <UILabel {...props} role={TypographyVariants.ParagraphFootnote}>
        {props.children}
    </UILabel>
);

const truncAddress = (address?: string) => {
    if (!address) return <FootNote color={ColorVariants.TextTertiary}>ext</FootNote>;

    return Utils.truncAddress(address);
};

const MessageView = ({ item, accountId = '' }: Props) => {
    if (!item) return null;

    const { id, value, created_at, src, dst } = item;
    const timeDate = MomentHelper.getTimeDate(created_at);
    const fromStr = truncAddress(src);
    const toStr = truncAddress(dst);

    const secondDetails = accountId ? (
        src === accountId ? (
            <FlexContainer>
                <FootNote color={ColorVariants.TextNegative}>&nbsp;→&nbsp;</FootNote> to&nbsp;{toStr}
            </FlexContainer>
        ) : (
            <FlexContainer>
                <FootNote color={ColorVariants.TextPositive}>&nbsp;←&nbsp;</FootNote> from&nbsp;{fromStr}
            </FlexContainer>
        )
    ) : (
        <FlexContainer>
            {' '}
            {fromStr} <FootNote color={ColorVariants.TextPrimary}>&nbsp;→&nbsp;</FootNote>
            {toStr}
        </FlexContainer>
    );

    return (
        <LinkWithChild navigationPath={paths.messages.details} params={{ id: item.id }}>
            <UIDetailsButton
                testID={messageViewTestIDs.messageView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                truncTitle
                title={id}
                captionComponent={<BalanceView balance={value || '0'} fixFractional />}
                details={timeDate}
                secondDetails={secondDetails}
            />
        </LinkWithChild>
    );
};

MessageView.externalConfigs = {
    items: null,
    sortingKey: 'created_at',
};

MessageView.getCollectionConfigs = (accountId?: Maybe<string>): TONCollectionConfigs => {
    const accountIdProp = accountId ? { accountId } : null;

    return {
        keyExtractor: ({ id }: { id: string }): string => {
            return `message-view-${id}`;
        },
        idKey: 'id',
        newItemName: 'NewMessage',
        collectionName: liveLocalized.Messages,
        //@ts-ignore doesn`t calculate right type
        collectionKey: TONClient.collections.messages,
        renderItem: ({ item }: { item: any }) => <MessageView item={item} {...accountIdProp} />,
    };
};

export default MessageView;
