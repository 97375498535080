import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import type { TONLocalization } from '@services/LocalizationService/types';
import controllerService from '@services/ControllerService';
//@ts-ignore doesn`t see import
import IconArrowLeft from '#assets/ico-arrow-left.png';
import { LinkWithChild, UITextButton } from '#components';
import { paths } from '#navigation/paths';
import { LocationStateType } from '#navigation/types';
import { height, common } from '#uikit/designCore/styles';
import type { LocalizedInstance } from '#uikit/@tonlabs/localization';

import './BottomNavigationBar.scss';

export const bottomNavigationBarTestIDs = {
    backToButton: (prevPage: string) => `backTo_${prevPage}`,
    backHomeButton: 'backHomeButton',
};

const getBackButtonTitlesByPathName = (localized: LocalizedInstance<TONLocalization>) => ({
    [paths.main]: localized.Main,
    [paths.blocks.main]: localized.Blocks,
    [paths.blocks.details]: localized.BlockDetails,
    [paths.messages.main]: localized.Messages,
    [paths.messages.details]: localized.MessageDetails,
    [paths.transactions.main]: localized.Transactions,
    [paths.transactions.details]: localized.TransactionDetails,
    [paths.zeroState]: localized.ZeroState,
    [paths.accounts.main]: localized.Accounts,
    [paths.accounts.details]: localized.AccountDetails,
    [paths.contracts.main]: localized.SmartContracts,
    [paths.contracts.details]: localized.SmartContractDetails,
    [paths.TIP32.main]: localized.Tip3Tokens,
    [paths.TIP32.details]: localized.Tip3TokenDetails,
    [paths.KWT]: localized.KWT,
    [paths.NFT.main]: localized.NFT,
    [paths.NFT.collection]: localized.NFTCollectionName,
    [paths.NFT.token]: localized.NFTDetails,
    [paths.validators.main]: localized.Validators,
    [paths.validators.details]: localized.ValidatorDetails,
    [paths.dePools.main]: localized.DePools,
    [paths.dePools.details]: localized.DePoolDetails,
    [paths.legalNotes]: localized.LegalNotes,
});

const BottomNavigationBar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { prevRoute } = (location.state as LocationStateType) || {};

    const narrow = controllerService.isNarrow;

    const backButton = prevRoute ? (
        <LinkWithChild
            navigationPath={prevRoute.pathname}
            params={prevRoute.queryParams}
            preventNavigation
            onFollow={() => navigate(-1)}
        >
            <UITextButton
                icon={IconArrowLeft}
                title={!narrow && (getBackButtonTitlesByPathName(liveLocalized)[prevRoute.pathname] || '')}
            />
        </LinkWithChild>
    ) : null;

    const homeButton =
        location.pathname === paths.main ? null : (
            <LinkWithChild navigationPath={paths.main} classNames={common.positionAbsoluteCenter}>
                <UITextButton title={liveLocalized.BackToHome} testID={bottomNavigationBarTestIDs.backHomeButton} />
            </LinkWithChild>
        );

    return (
        <div className={classnames('BottomNavigationBar', height.bigCell, controllerService.reactContentClassNames)}>
            {homeButton}
            {backButton}
        </div>
    );
};

export default observer(BottomNavigationBar);
