import React, { forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { liveLocalized } from '@services/LocalizationService';
import { TONClient, TONFilter } from '#TONClient';
import { TONLog } from '#TONUtility';

import type { TONBlockT } from '#TONClient/EVERBlock/types';
import type { EVERTransactionT } from '#TONClient/EVERTransaction/types';
import type { TONMessageT } from '#TONClient/TONMessage/types';
import type { TONAccountT } from '#TONClient/TONAccount/types';
import type { TONValidatorT } from '#TONClient/TONValidator/types';
import type { FilterParams, FilterValues, SortDirection } from '#TONClient/TONFilter';
import type { TONContractT } from '#TONClient/TONContract/types';
import type { TONDePoolT } from '#TONClient/TONDePool/types';
import type { CollectionName } from '#TONClient/TONClient';
import { UIDetailsButton, UILabel } from '#components';

import { TypographyVariants } from '#uikit/designCore/constants/font';
import { margin } from '#uikit/designCore/styles/margin';
import { width } from '#uikit/designCore/styles/width';
import Utils from '#helpers/Utils';
import { container } from '#uikit/designCore/styles/container';
import { common } from '#uikit/designCore/styles/comon';
import { flex } from '#uikit/designCore/styles/flex';

import Footer from './Footer';
import Header from './Header';
import TONAsync from '../../TONUtility/TONAsync';
import { EVERListRef } from './types';

import './EVERList.scss';

export type EVERItem =
    | TONBlockT
    | EVERTransactionT
    | TONMessageT
    | TONAccountT
    | TONValidatorT
    | TONContractT
    | TONDePoolT;
export type GetTONItemsArgs = {
    lastItem?: Maybe<EVERItem>;
    limit?: number;
    direction?: SortDirection;
    accountId?: string;
    blockId?: string;
    filterValues?: FilterValues;
    needAdditionalFields?: boolean;
};

export type TONCollectionConfigs = {
    keyExtractor: (EVERItem) => string;
    idKey: string;
    newItemName?: string;
    collectionName?: string;
    collectionKey?: CollectionName;
    renderItem: ({ item: EVERItem }, index?: number) => ReactNode;
};

export type TONExternalConfigs = {
    items?: Maybe<EVERItem[]>;
    customItemsForExport?: Maybe<EVERItem[]>;
    onChangeFilter?: (items: Maybe<EVERItem[]>) => void;
    filterValues?: FilterValues;
    filterParams?: FilterParams;
    sortingKey: string;
    additionalSortingKey?: string;
    sortDirectionByDefault?: SortDirection;
    load25more?: boolean;
    isLoading?: boolean;
};

type Props = {
    // spinnerVisible: boolean,
    title?: string;
    titleRole?: TypographyVariants;
    updateViewDetails?: Maybe<string>;
    classNames?: string;
    contentClassNames?: string;
    itemsLoader?: (args: GetTONItemsArgs, filterValues?: FilterValues) => Promise<EVERItem[]>;
    collectionConfigs: TONCollectionConfigs;
    externalControlConfigs: TONExternalConfigs;
    filterValues?: FilterValues;
    footer?: Maybe<ReactElement>;
    realtimeUpdated: boolean;
    nothingWasFoundMessage?: string;
    maxVisibleItemsCount?: number;
    onChangeItems?: (items: EVERItem[]) => void;
    onChangeNewItems?: (items: EVERItem[]) => void;
    onSubscribeForUpdate?: (filterValues?: FilterValues) => void;
    onUnsubscribeForUpdate?: () => void;
    onErrorOccurs?: () => void;
    onStartReload?: (filterValues?: FilterValues, args?: GetTONItemsArgs) => void;
    onChangeSortDirection?: (item: SortDirection) => void;
};

const log = new TONLog('ListScreen');

const { itemsLoadingLimit, itemsLoadingDoubleLimit, itemsLoadingMax, itemsLoadingInfinity } = TONClient;

const EVERListTestIDs = {
    loadMoreButton: 'loadMoreButton',
    listItem: 'listItem',
    exportToCSVButton: 'exportToCSVButton',
    listHeader: 'listHeader',
    listWrapper: 'listWrapper',
    EVERList: 'EVERList',
};

// const LIST_ITEM_HEIGHT = 80;

const ItemContainer = ({ children, contentClassNames }: { children: ReactNode; contentClassNames?: string }) => {
    return (
        <div className={classnames(container.fullWidthCenter, common.displayFlex, flex.column)}>
            <div
                className={classnames(common.positionRelative, contentClassNames)}
                data-testid={EVERListTestIDs.listItem}
            >
                {children}
            </div>
        </div>
    );
};

export const defaultItemsLoader = async () => [];

const EVERList = forwardRef<EVERListRef, Props>(
    (
        {
            // spinnerVisible: false,
            title = '',
            titleRole = TypographyVariants.TitleMedium,
            updateViewDetails = liveLocalized.JustNow,
            collectionConfigs = {
                idKey: 'id',
                newItemName: '',
                collectionName: '',
                keyExtractor: ({ id }) => id,
                renderItem: ({ item }) => null,
            },
            externalControlConfigs = {
                items: null,
                additionalSortingKey: '',
                onChangeFilter: (items: Maybe<EVERItem[]>) => {},
                sortingKey: '',
                load25more: false,
            },
            filterValues,
            itemsLoader = defaultItemsLoader,
            footer = null,
            realtimeUpdated = true,
            nothingWasFoundMessage = liveLocalized.NothingWasFound,
            maxVisibleItemsCount = 0,
            classNames,
            contentClassNames,
            onChangeItems = (items: EVERItem[]) => {},
            onChangeNewItems = (items: EVERItem[]) => {},
            onSubscribeForUpdate = () => {},
            onUnsubscribeForUpdate = () => {},
            onErrorOccurs = () => {},
            onStartReload = (filterValues?: FilterValues, args?: GetTONItemsArgs) => {},
            onChangeSortDirection = (item: SortDirection) => {},
        },
        ref
    ) => {
        const [isMounted, setIsMounted] = useState(false);
        const [realtimeAddedItems, setRealtimeAddedItems] = useState<EVERItem[]>([]);
        const [realtimeAddedItemsCount, setRealtimeAddedItemsCount] = useState<number>(0);
        const [selfLoadedItems, setSelfLoadedItems] = useState<EVERItem[]>([]);
        const [visibleItemsCount, setVisibleItemsCount] = useState<number>(itemsLoadingLimit);
        const [isNothingWasFound, setNothingWasFound] = useState<boolean>(false);
        const [isLoadingMoreItems, setLoadingMoreItems] = useState<boolean>(false);
        const [allItemsLoaded, setAllItemsLoaded] = useState<boolean>(false);
        const [isRealtimeUpdated, setRealTimeUpdated] = useState<boolean>(false);
        const [itemsById, setItemsById] = useState({});
        const [sortedExternalItems, setSortedExternalItems] = useState<EVERItem[]>([]);
        const externalItems = useRef<EVERItem[] | null>(null);

        const {
            items,
            customItemsForExport,
            filterParams,
            sortingKey,
            additionalSortingKey,
            sortDirectionByDefault,
            onChangeFilter,
            load25more,
            isLoading,
        } = externalControlConfigs;

        const [sortDirection, setSortDirection] = useState<SortDirection>(sortDirectionByDefault || 'DESC');

        // Setters
        const changeSelfLoadedItems = (newCurrItems: EVERItem[]) => {
            setSelfLoadedItems(newCurrItems);

            if (realtimeAddedItemsCount >= itemsLoadingDoubleLimit) {
                onChangeItems(realtimeAddedItems);
            } else {
                const itemsResult = [...realtimeAddedItems, ...newCurrItems];
                onChangeItems(itemsResult);
            }
        };

        const setNewCurrItems = (newCurrItems: EVERItem[]) => {
            if (realtimeAddedItemsCount >= itemsLoadingDoubleLimit) {
                onChangeItems(newCurrItems);
                onChangeNewItems(newCurrItems);
            } else {
                const itemsResult = [...newCurrItems, ...selfLoadedItems];
                onChangeItems(itemsResult);
                onChangeNewItems(newCurrItems);
            }
        };

        // Actions
        const addSelfLoadedItems = (
            newItemsParam: EVERItem[],
            prevItemsParam: EVERItem[],
            itemsByIdParam: { [id: string]: boolean }
        ) => {
            let prevItems = [...prevItemsParam];
            newItemsParam.forEach((item) => {
                Utils.callIfNotDuplicate(item[collectionConfigs.idKey], itemsByIdParam, (newItemsByIdParam) => {
                    setItemsById(newItemsByIdParam);
                    prevItems = [...prevItems, item];
                });
            });
            changeSelfLoadedItems(prevItems);
        };

        const showMoreItems = async (
            visibleItemsCountParam: number = visibleItemsCount,
            selfLoadedItemsParam: EVERItem[] = selfLoadedItems,
            sortDirectionParam: SortDirection = sortDirection,
            itemsByIdParam: { [id: string]: boolean } = itemsById,
            externalItemsParam: Maybe<EVERItem[]> = externalItems.current,
            filterValuesParam: FilterValues = filterValues
        ) => {
            if (externalItemsParam?.length) {
                if (visibleItemsCountParam >= sortedExternalItems.length) {
                    setAllItemsLoaded(true);
                }
                return;
            }

            if (visibleItemsCountParam + itemsLoadingLimit <= selfLoadedItemsParam.length) {
                return;
            }

            // we load more items to have a stock for immediate showing them after the next request
            setLoadingMoreItems(true);
            try {
                const limit = Math.min(
                    visibleItemsCountParam + itemsLoadingLimit - selfLoadedItemsParam.length,
                    itemsLoadingMax
                );

                const loadedItems = await itemsLoader(
                    {
                        lastItem: selfLoadedItemsParam[selfLoadedItemsParam.length - 1],
                        limit,
                        ...(!externalItemsParam && { direction: sortDirectionParam }),
                    },
                    filterValuesParam
                );

                if (!loadedItems?.length && !selfLoadedItemsParam.length) {
                    setNothingWasFound(true);
                } else {
                    setNothingWasFound(false);
                    addSelfLoadedItems(loadedItems, selfLoadedItemsParam, itemsByIdParam);
                    if (loadedItems.length < limit) {
                        setAllItemsLoaded(true);
                    }
                }
            } catch (e) {
                log.error('Error while loading list items', e);
                onErrorOccurs();
            }
            setLoadingMoreItems(false);
        };

        const resetAll = () => {
            setItemsById({});
            setSelfLoadedItems([]);
            setRealtimeAddedItems([]);
            setVisibleItemsCount(itemsLoadingLimit);
            setLoadingMoreItems(false);
            setAllItemsLoaded(false);
            setNothingWasFound(false);
            return new Promise<void>((resolve) => {
                resolve();
            });
            // TODO insert clean cursor and clean hasNextPage
        };

        const reload = async (
            newDirection?: SortDirection,
            realtimeUpdatedParam?: boolean,
            externalItemsParam: Maybe<EVERItem[]> = externalItems.current,
            filterValuesParam: FilterValues = filterValues
        ) => {
            onStartReload(filterValuesParam, {
                ...(!externalItemsParam && { direction: newDirection }),
                limit: itemsLoadingLimit,
            });
            onUnsubscribeForUpdate();
            await resetAll();
            await showMoreItems(itemsLoadingLimit, [], newDirection, {}, externalItemsParam, filterValuesParam);
            if (realtimeUpdatedParam) {
                await onSubscribeForUpdate(filterValuesParam);
            }
        };

        const filterAndSortExternalItems = (dir: SortDirection) => {
            if (!items) return;

            const itemsCopy = [...items];

            const filteredItems =
                filterValues && filterParams ? TONFilter.filterBy(itemsCopy, filterValues, filterParams) : itemsCopy;

            TONFilter.sortBy(filteredItems, { direction: dir }, sortingKey, additionalSortingKey);

            onChangeFilter && onChangeFilter(filteredItems);
            setSortedExternalItems(filteredItems);
        };

        const changeSortDirection = (newDirection: SortDirection) => {
            setSortDirection(newDirection);
            onChangeSortDirection(newDirection);

            if (items?.length) filterAndSortExternalItems(newDirection);
            else reload(newDirection);
        };

        const unshiftItem = (item: EVERItem, sortingKeyParam?: string) => {
            setNothingWasFound(false);
            let selfLoadedItemsParam;

            setSelfLoadedItems((prevState) => {
                selfLoadedItemsParam = prevState;
                return prevState;
            });

            if (!selfLoadedItemsParam?.length) {
                return;
            }

            const callback = (newItemsByIdParam) => {
                setItemsById(newItemsByIdParam);
                setRealTimeUpdated((prevStateRealTimeUpdated) => {
                    if (prevStateRealTimeUpdated) {
                        setRealtimeAddedItemsCount((prev) => {
                            return prev + 1;
                        });
                    }

                    return prevStateRealTimeUpdated;
                });

                setRealtimeAddedItems((prev) => {
                    const currItems = [...prev];
                    if (sortingKeyParam && item[sortingKeyParam] && currItems.length) {
                        for (let i = 0; i < currItems.length; i += 1) {
                            if (item[sortingKeyParam] >= currItems[i][sortingKeyParam]) {
                                currItems.splice(i, 0, item);
                                break;
                            }
                        }
                    } else {
                        if (sortingKeyParam && !item[sortingKeyParam]) {
                            log.warning(
                                'item[sortingKeyParam] is not defined, you need to update key for unshiftItem()'
                            );
                        }

                        currItems.unshift(item);
                    }

                    if (currItems.length > itemsLoadingDoubleLimit) {
                        currItems.pop();
                    }
                    setNewCurrItems(currItems);

                    return currItems;
                });
            };

            Utils.callIfNotDuplicate(item[collectionConfigs.idKey], itemsById, callback);
        };

        // Events
        const onPressLoadMore = async () => {
            if (controllerService.isMobile) {
                // to prevent from accidental tap on one of new elements
                await TONAsync.timeout(50);
            }

            const increaseLimit = items && !load25more ? itemsLoadingInfinity : itemsLoadingLimit;
            setVisibleItemsCount(visibleItemsCount + increaseLimit);
            if (!allItemsLoaded) {
                showMoreItems(visibleItemsCount + increaseLimit);
            }
        };

        const onPressUpdate = (): void => {
            let currItems = [...selfLoadedItems];

            if (realtimeAddedItems.length < itemsLoadingDoubleLimit) {
                currItems = [...realtimeAddedItems, ...currItems].slice(0, itemsLoadingDoubleLimit);
            } else {
                currItems = [...realtimeAddedItems];
            }

            setItemsById({});
            currItems.forEach((item) =>
                Utils.callIfNotDuplicate(item[collectionConfigs.idKey], itemsById, (newItemsByIdParam) =>
                    setItemsById(newItemsByIdParam)
                )
            );

            setSelfLoadedItems(currItems);
            setRealtimeAddedItems([]);
            setRealtimeAddedItemsCount(0);
            setVisibleItemsCount(itemsLoadingLimit);
        };

        const changeRealtimeUpdated = (realtimeUpdatedParam: boolean) => {
            setRealTimeUpdated(realtimeUpdatedParam);
            let sortDirectionParam = sortDirection;

            if (realtimeUpdatedParam) {
                if (sortDirectionParam === 'ASC') {
                    changeSortDirection('DESC');
                    sortDirectionParam = 'DESC';
                }
            } else {
                onPressUpdate();
                onUnsubscribeForUpdate();
            }
            reload(sortDirectionParam, realtimeUpdatedParam);
        };

        const onToggleRealtimeUpdate = (turnOn: boolean = false) => {
            const newRealtimeUpdated = turnOn || !isRealtimeUpdated;
            changeRealtimeUpdated(newRealtimeUpdated);
        };

        const onChangeSortDirectionInner = () => {
            const newDirection = sortDirection === 'DESC' ? 'ASC' : 'DESC';
            if (newDirection === 'ASC' && isRealtimeUpdated) {
                changeRealtimeUpdated(false);
            }
            changeSortDirection(newDirection);
        };

        useImperativeHandle(
            ref,
            () => ({
                reload: (filterValuesParam?: FilterValues) =>
                    reload(undefined, undefined, externalItems.current, filterValuesParam),
                // showMoreItems,
                unshiftItem,
                finishRealtimeUpdate: () => {
                    changeRealtimeUpdated(false);
                },
            }),
            [reload, externalItems.current, unshiftItem, changeRealtimeUpdated]
        );

        useEffect(() => {
            onUnsubscribeForUpdate();
            return () => {
                onUnsubscribeForUpdate();
                resetAll();
            };
        }, []);

        useEffect(() => {
            if (items?.length) {
                externalItems.current = items;
                filterAndSortExternalItems(sortDirection);
                reload(undefined, undefined, items).then(() => {
                    setAllItemsLoaded(visibleItemsCount >= sortedExternalItems.length);
                });
            } else if (items?.length === 0) {
                setNothingWasFound(true);
            }
        }, [items, filterValues]);

        useEffect(() => {
            if (isMounted) reload(undefined, undefined, externalItems.current, undefined);
            else setIsMounted(true);
        }, [itemsLoader]);

        // Render
        const listItems = (items ? sortedExternalItems : selfLoadedItems) || [];

        const containerClassnames =
            !isNothingWasFound && (!listItems.length || listItems.length >= itemsLoadingLimit) && 'container';

        const headerComponent = (
            <ItemContainer contentClassNames={contentClassNames}>
                <Header
                    items={items}
                    customItemsForExport={customItemsForExport}
                    stateItems={listItems}
                    collectionConfigs={collectionConfigs}
                    updateViewDetails={updateViewDetails}
                    titleRole={titleRole}
                    title={title}
                    realtimeUpdated={realtimeUpdated}
                    itemsLoader={itemsLoader}
                    filterValues={filterValues}
                    headerTestID={EVERListTestIDs.listHeader}
                    exportCSVTestID={EVERListTestIDs.exportToCSVButton}
                    newItemsCount={realtimeAddedItemsCount}
                    isRealtimeUpdatedNow={isRealtimeUpdated}
                    sortDirection={sortDirection}
                    onChangeSortDirection={onChangeSortDirectionInner}
                    onToggle={onToggleRealtimeUpdate}
                    onPressUpdate={onPressUpdate}
                />
            </ItemContainer>
        );

        const renderFlatListItem = (item: EVERItem) => {
            const { renderItem, keyExtractor } = collectionConfigs;
            return (
                <ItemContainer key={keyExtractor(item)} contentClassNames={contentClassNames}>
                    {renderItem({ item })}
                </ItemContainer>
            );
        };

        // const getItemLayout = (data, index) => ({ length: LIST_ITEM_HEIGHT, offset: LIST_ITEM_HEIGHT * index, index });

        const loadingSpinner = <UIDetailsButton containerClassnames={margin.topDefault} transparent progress />;

        const nothingWasFound = (
            <ItemContainer contentClassNames={contentClassNames}>
                <UILabel classNames={classnames(margin.topDefault, width.full)} role={TypographyVariants.ParagraphText}>
                    {nothingWasFoundMessage}
                </UILabel>
            </ItemContainer>
        );

        const emptyContent =
            isLoadingMoreItems || (!isNothingWasFound && items?.length === 0) || isLoading
                ? loadingSpinner
                : nothingWasFound;

        const footerComponent = (!allItemsLoaded || visibleItemsCount < listItems.length) && (
            <Footer
                testID={EVERListTestIDs.loadMoreButton}
                showAll={!!items && !load25more}
                loadingMoreItems={isLoadingMoreItems}
                hideButton={visibleItemsCount >= listItems?.length || !listItems.length}
                onPress={onPressLoadMore}
                {...(footer ? { footerComponent: footer } : {})}
            />
        );

        const count = maxVisibleItemsCount ? Math.min(maxVisibleItemsCount, visibleItemsCount) : visibleItemsCount;
        const visibleItems = listItems.slice(0, count);

        const isLoadingOrIsNothingWasFound =
            (!isNothingWasFound && items?.length === 0) || isNothingWasFound || listItems?.length === 0;

        const contentComponent = isLoadingOrIsNothingWasFound ? ( // external items were loaded but have zero length
            emptyContent
        ) : (
            <div className={width.full} data-testid={EVERListTestIDs.EVERList}>
                {visibleItems.map(renderFlatListItem)}
                {footerComponent}
            </div>
        );

        return (
            <div
                className={classnames('EVERList', width.full, containerClassnames, classNames)}
                data-testid={EVERListTestIDs.listWrapper}
            >
                {headerComponent}
                {contentComponent}
            </div>
        );
    }
);

export default EVERList;
