import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

import { BackgroundView } from '#components';
import { padding } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';

type Props = {
    link: string;
};

const QRCode: React.FC<Props> = ({ link }) => (
    <BackgroundView classNames={padding.tiny} color={ColorVariants.StaticBackgroundWhite}>
        <QRCodeSVG value={link} size={88} />
    </BackgroundView>
);

export default QRCode;
