import { NetworkQueryResponseType } from '#TONClient/types';
import { SortDirection } from '#TONClient/TONFilter';

enum AccountWalletVariants {
    KWT = 'KWT',
    FlexClient = 'FlexClient',
}

type Standard = 'TIP-3' | 'KWT' | 'TIP-3.1';

type Tip3Token = {
    name: string;
    symbol: string;
    rootAddress: string;
    rootOwnerAddress: string;
    totalSupply: number;
    decimals: number;
    standard: Standard;
};

type TokenWallet = {
    name: string;
    symbol: string;
    decimals: number;
    rootAddress: string;
    walletAddress: string;
    ownerAddress: string;
    balance: number;
};

type TokenHolder = Pick<
    TokenWallet,
    'rootAddress' | 'ownerAddress' | 'balance' | 'decimals' | 'name' | 'symbol' | 'walletAddress'
>;

type TokenDetailsWallet = {
    balance: number;
    walletAddress: string;
    percentage: number;
    decimals: number;
};

type TokenStatisticsType = {
    numberOfTokens: number;
    numberOfWallets: number;
};

type StatisticOfTokenType = {
    numberOfHolders: number;
};

type SearchTokenResult = Pick<Tip3Token, 'rootAddress' | 'symbol'>;

type AccountWallet = Pick<TokenWallet, 'symbol' | 'balance' | 'rootAddress' | 'decimals'> & {
    variant?: AccountWalletVariants;
};

type KWTWalletDetailsFromRunGetMethods = Pick<TokenWallet, 'walletAddress' | 'rootAddress' | 'balance'>;

type GetHolderArgs = {
    lastItem?: TokenWallet & { endCursor?: string };
    limit?: number;
    direction?: SortDirection;
};

type GetTokenArgs = {
    lastItem?: Tip3Token & { endCursor?: string };
    limit?: number;
    direction?: SortDirection;
};

type TokensQueryType = NetworkQueryResponseType<{
    ft: {
        tokens: {
            pageInfo: {
                endCursor: string;
            };
            edges: {
                cursor: string;
                node: TokenQueryType;
            }[];
        };
    };
}>;

type WalletsForAccountQueryType = NetworkQueryResponseType<{
    blockchain: {
        account: {
            info: {
                tokenHolder: {
                    wallets: {
                        pageInfo: {
                            hasNextPage: boolean;
                            endCursor: string;
                        };
                        nodes: {
                            balance: string;
                            token: {
                                address: string;
                                decimals: number;
                                symbol: string;
                            };
                        }[];
                    };
                };
            };
        };
    };
}>;

type WalletAccountTokenQueryType = NetworkQueryResponseType<{
    ft: {
        wallet: {
            balance: string;
            token: {
                address: string;
                decimals: number;
                symbol: string;
            };
        };
    };
}>;

type AccountFlexClientTokensQueryType = NetworkQueryResponseType<{
    flex: {
        wallets: {
            address: string;
            userId: string;
            totalBalance: string;
            token: {
                decimals: number;
                ticker: string;
            };
        }[];
    };
}>;

type HoldersQueryType = NetworkQueryResponseType<{
    ft: {
        token: {
            address: string;
            decimals: number;
            name: string;
            symbol: string;
            wallets: {
                pageInfo: {
                    endCursor: string;
                };
                edges: {
                    cursor: string;
                    node: {
                        address: string;
                        balance: string;
                        holder: {
                            address: string;
                        };
                    };
                }[];
            };
        };
    };
}>;

type TokenQueryType = {
    address: string;
    decimals: number;
    name: string;
    symbol: string;
    rootOwner: string;
    standard: Standard | 'TIP_32';
    totalSupply: string;
};

type TokenDetailsQueryType = NetworkQueryResponseType<{
    ft: { token: TokenQueryType };
}>;

type TokenDetailsWalletQueryType = NetworkQueryResponseType<{
    ft: {
        token: {
            decimals: number;
            wallets: {
                pageInfo: {
                    hasNextPage: boolean;
                    endCursor: string;
                };
                edges: {
                    cursor: string;
                    node: {
                        address: string;
                        balance: string;
                        percentage: number;
                    };
                }[];
            };
        };
    };
}>;

type SearchTokensType = NetworkQueryResponseType<{
    ft: {
        tokens: {
            pageInfo: {
                hasNextPage: boolean;
                endCursor: string;
            };
            nodes: {
                address: string;
                symbol: string;
            }[];
        };
    };
}>;

type TokenStatisticsQueryType = NetworkQueryResponseType<{
    ft: {
        statistics: [
            {
                totalTokenCount: number;
                totalWalletCount: number;
            }
        ];
    };
}>;

type StatisticOfTokenQueryType = NetworkQueryResponseType<{
    ft: {
        token: {
            statistics: {
                totalWalletCount: number;
            };
        };
    };
}>;

type TIP32FilterValues = {
    symbol: string;
};
export { AccountWalletVariants };
export type {
    Standard,
    Tip3Token,
    TokenWallet,
    TokenHolder,
    TokenDetailsWallet,
    SearchTokenResult,
    AccountWallet,
    KWTWalletDetailsFromRunGetMethods,
    GetHolderArgs,
    GetTokenArgs,
    TokensQueryType,
    WalletsForAccountQueryType,
    WalletAccountTokenQueryType,
    AccountFlexClientTokensQueryType,
    HoldersQueryType,
    TokenQueryType,
    TokenDetailsQueryType,
    TokenDetailsWalletQueryType,
    SearchTokensType,
    TIP32FilterValues,
    TokenStatisticsQueryType,
    StatisticOfTokenQueryType,
    TokenStatisticsType,
    StatisticOfTokenType,
};
