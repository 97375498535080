// @flow
import React from 'react';
import { liveLocalized } from '@services/LocalizationService';
import { GasView, LinkWithCopy, UIDetailsTable } from '#components';
import Utils from '#helpers/Utils';
import type { TONBlockT } from '#TONClient/EVERBlock/types';
import type { EVERTransactionT } from '#TONClient/EVERTransaction/types';
import DetailsRow from '#controllers/Details/DetailsRowHelper';
import { getComputeExitCodeDescription } from '#controllers/Details/helpers';
import { paths } from '#navigation/paths';

export default class Details {
    static trTypes = {
        0: 'Ordinary',
        1: 'Storage',
        2: 'Tick',
        3: 'Tock',
        4: 'SplitPrepare',
        5: 'SplitInstall',
        6: 'MergePrepare',
        7: 'MergeInstall',
    };

    static getFees(tr: EVERTransactionT) {
        if (!tr) {
            return [];
        }

        const { ext_in_fwd_fees, out_fwd_fees, storage, compute, action, bounce } = tr;

        return UIDetailsTable.formatNestedList({
            list: [
                { caption: liveLocalized.Fees },
                DetailsRow.crystals(liveLocalized.StorageFee, storage?.storage_fees_collected),
                DetailsRow.crystals(liveLocalized.GasFee, compute?.gas_fees),
                DetailsRow.crystals(liveLocalized.ActionFee, action?.total_action_fees),
                DetailsRow.crystals(liveLocalized.BounceFee, bounce?.msg_fees),
                DetailsRow.crystals(liveLocalized.OtherFeesExtInFwdIhrInFee, ext_in_fwd_fees || 0),
                DetailsRow.crystals(liveLocalized.OutFwdFee, out_fwd_fees || 0),
            ],
            key: 'fees',
            needOffset: false,
        });
    }

    static getBlockFields(block: ?TONBlockT, block_id: ?string) {
        if (!block) {
            return [block_id && DetailsRow.blockLinkWithCopy(liveLocalized.BlockID, block_id)];
        }

        const fullBlockNumber = block ? `${block?.seq_no} / ${block?.workchain_id}:${block?.shard}` : '';

        return [
            {
                caption: liveLocalized.Block,
                component: (
                    <LinkWithCopy
                        truncTitle={false}
                        title={fullBlockNumber}
                        navigationPath={paths.blocks.details}
                        params={{ id: block?.id }}
                    />
                ),
            },
            DetailsRow.blockLinkWithCopy(liveLocalized.BlockID, block?.id),
        ];
    }

    static getFields(tr: EVERTransactionT) {
        if (!tr) return [];

        const { TONTransaction } = liveLocalized;

        return UIDetailsTable.formatNestedList({
            list: [
                { caption: liveLocalized.Details },
                ...this.getBlockFields(tr.block, tr.block_id),
                {
                    caption: liveLocalized.LogicalTime,
                    value: tr?.lt,
                },
                DetailsRow.transactionLinkWithCopy(TONTransaction.prev_trans_hash, tr.prev_trans_hash || ''),
                {
                    caption: TONTransaction.prev_trans_lt,
                    value: tr.prev_trans_lt,
                },
                {
                    caption: TONTransaction.outmsg_cnt,
                    value: tr.outmsg_cnt,
                },
                {
                    caption: TONTransaction.orig_status,
                    value: liveLocalized.TONAccount.states[tr.orig_status_name],
                },
                {
                    caption: TONTransaction.end_status,
                    value: liveLocalized.TONAccount.states[tr.end_status_name],
                },
                // total_fees_other: OtherCurrencyNumber,
                {
                    caption: TONTransaction.old_hash,
                    value: tr.old_hash,
                },
                {
                    caption: TONTransaction.new_hash,
                    value: tr.new_hash,
                },
                {
                    caption: TONTransaction.credit_first,
                    value: tr.credit_first,
                    type: UIDetailsTable.cellType.bool,
                },
            ],
            key: 'additional-details',
            needOffset: true,
        });
    }

    static getStorage(tr: EVERTransactionT) {
        if (!tr?.storage) return [];

        const { storage: storageLocalized } = liveLocalized.TONTransaction;

        const { storage } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: storageLocalized.title },
                DetailsRow.crystals(storageLocalized.storage_fees_collected, storage.storage_fees_collected),
                DetailsRow.crystals(storageLocalized.storage_fees_due, storage.storage_fees_due),
                {
                    caption: storageLocalized.status_change,
                    value: liveLocalized.TONAccount.statusChanges[storage.status_change_name],
                    key: 'storage',
                },
            ],
            'storage'
        );
    }

    static getCredit(tr: EVERTransactionT) {
        if (!tr?.credit) return [];

        const { credit: creditLocalized } = liveLocalized.TONTransaction;

        const { credit } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: creditLocalized.title },
                DetailsRow.crystals(creditLocalized.due_fees_collected, credit.due_fees_collected),
                DetailsRow.crystals(creditLocalized.credit, credit.credit),
            ],
            'credit'
        );
    }

    static getCompute(tr: EVERTransactionT) {
        if (!tr?.compute) return [];

        const { compute: computeLocalized, computeTypes } = liveLocalized.TONTransaction;

        const { compute } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: computeLocalized.title },
                {
                    caption: liveLocalized.GasLimit,
                    component: <GasView balance={compute.gas_limit} />,
                },
                {
                    caption: liveLocalized.GasUsed,
                    component: (
                        <GasView
                            balance={compute.gas_used}
                            tokenSymbol={Utils.getPercentString(compute.gas_used, compute.gas_limit)}
                        />
                    ),
                },
                // {
                //     caption: liveLocalized.GasPrice,
                //     component: <GasView balance={compute.gas_price} />,
                // },
                DetailsRow.crystals(computeLocalized.gas_fees, compute?.gas_fees),
                {
                    caption: computeLocalized.gas_credit,
                    component: <GasView balance={compute.gas_credit} />,
                },
                {
                    caption: computeLocalized.compute_type,
                    value: computeTypes[compute.compute_type_name],
                },
                {
                    caption: computeLocalized.skipped_reason,
                    value: compute.skipped_reason,
                },
                {
                    caption: computeLocalized.success,
                    value: compute.success,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: computeLocalized.msg_state_used,
                    value: compute.msg_state_used,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: computeLocalized.account_activated,
                    value: compute.account_activated,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: computeLocalized.mode,
                    value: compute.mode,
                },
                {
                    caption: computeLocalized.exit_code,
                    value: `${compute.exit_code} - ${getComputeExitCodeDescription(compute.exit_code)}`,
                },
                {
                    caption: computeLocalized.exit_arg,
                    value: compute.exit_arg,
                },
                {
                    caption: computeLocalized.vm_steps,
                    value: compute.vm_steps,
                },
                {
                    caption: computeLocalized.vm_init_state_hash,
                    value: compute.vm_init_state_hash,
                },
                {
                    caption: computeLocalized.vm_final_state_hash,
                    value: compute.vm_final_state_hash,
                },
            ],
            'compute'
        );
    }

    static getAction(tr: EVERTransactionT) {
        if (!tr?.action) return [];

        const { action: actionLocalized } = liveLocalized.TONTransaction;

        const { action } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: actionLocalized.title },
                {
                    caption: actionLocalized.success,
                    value: action.success,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: actionLocalized.valid,
                    value: action.valid,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: actionLocalized.no_funds,
                    value: action.no_funds,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: actionLocalized.status_change,
                    value: liveLocalized.TONAccount.statusChanges[action.status_change_name],
                },
                DetailsRow.crystals(actionLocalized.total_fwd_fees, action.total_fwd_fees),
                DetailsRow.crystals(actionLocalized.total_action_fees, action.total_action_fees),
                {
                    caption: actionLocalized.result_code,
                    value: action.result_code,
                },
                {
                    caption: actionLocalized.result_arg,
                    value: action.result_arg,
                },
                {
                    caption: actionLocalized.tot_actions,
                    value: action.tot_actions,
                },
                {
                    caption: actionLocalized.spec_actions,
                    value: action.spec_actions,
                },
                {
                    caption: actionLocalized.skipped_actions,
                    value: action.skipped_actions,
                },
                {
                    caption: actionLocalized.msgs_created,
                    value: action.msgs_created,
                },
                {
                    caption: actionLocalized.action_list_hash,
                    value: action.action_list_hash,
                },
                {
                    caption: actionLocalized.total_msg_size_cells,
                    value: action.total_msg_size_cells,
                },
                {
                    caption: actionLocalized.total_msg_size_bits,
                    value: action.total_msg_size_bits,
                },
            ],
            'action'
        );
    }

    static getBounce(tr: EVERTransactionT) {
        if (!tr?.bounce) return [];

        const { bounce: bounceLocalized } = liveLocalized.TONTransaction;

        const { bounce } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: bounceLocalized.title },
                {
                    caption: bounceLocalized.bounce_type,
                    value: liveLocalized.TONTransaction.bounceTypes[bounce.bounce_type_name],
                },
                {
                    caption: bounceLocalized.msg_size_cells,
                    value: bounce.msg_size_cells,
                },
                {
                    caption: bounceLocalized.msg_size_bits,
                    value: bounce.msg_size_bits,
                },
                DetailsRow.crystals(bounceLocalized.req_fwd_fees, bounce.req_fwd_fees),
                DetailsRow.crystals(bounceLocalized.msg_fees, bounce.msg_fees),
                DetailsRow.crystals(bounceLocalized.fwd_fees, bounce.fwd_fees),
            ],
            'bounce'
        );
    }

    static getSplitInfo(tr: EVERTransactionT) {
        if (!tr?.split_info) return [];

        const { split_info: splitInfoLocalized } = liveLocalized.TONTransaction;

        const { split_info } = tr;
        return UIDetailsTable.formatNestedList(
            [
                { caption: splitInfoLocalized.title },
                {
                    caption: splitInfoLocalized.cur_shard_pfx_len,
                    value: split_info.cur_shard_pfx_len,
                },
                {
                    caption: splitInfoLocalized.acc_split_depth,
                    value: split_info.acc_split_depth,
                },
                {
                    caption: splitInfoLocalized.this_addr,
                    value: split_info.this_addr,
                },
                {
                    caption: splitInfoLocalized.sibling_addr,
                    value: split_info.sibling_addr,
                },
            ],
            'split_info'
        );
    }

    static getEndingFields(tr: EVERTransactionT) {
        if (!tr) return [];

        const { TONTransaction } = liveLocalized;

        return UIDetailsTable.formatNestedList({
            list: [
                { captionType: UIDetailsTable.captionType.header, caption: liveLocalized.FinalState },
                {
                    caption: TONTransaction.destroyed,
                    value: tr.destroyed,
                    type: UIDetailsTable.cellType.bool,
                },
                {
                    caption: TONTransaction.tt,
                    value: tr.tt,
                },
                {
                    caption: TONTransaction.prepare_transaction,
                    value: tr.prepare_transaction,
                },
                {
                    caption: TONTransaction.installed,
                    value: tr.installed,
                    type: UIDetailsTable.cellType.bool,
                },
            ],
            key: 'Ending fields',
        });
    }
}
