import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { themesService } from '@services/ThemesService';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { ThemesNames } from '#uikit/themes';

type Props = {
    color?: ColorVariants;
    classNames?: string;
    id?: string;
    children?: ReactNode;
} & React.InsHTMLAttributes<HTMLDivElement>;

const BackgroundView = React.forwardRef<HTMLDivElement, Props>(
    ({ color = ColorVariants.BackgroundPrimary, classNames, children, ...other }, ref) => {
        const preparedClassNames = classnames(
            classNames,
            `${themesService.isDarkTheme ? ThemesNames.DarkTheme : ThemesNames.LightTheme} ${color}`
        );

        return (
            <div ref={ref} className={preparedClassNames} {...other}>
                {children}
            </div>
        );
    }
);

export default observer(BackgroundView);
