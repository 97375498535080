const transferDetailsFields = `
    token {
        symbol
        decimals
    }
    fromHolder {
        address
    }
    toHolder {
        address
    }
    transferType
    timestamp
    value
`;

export { transferDetailsFields };
