import React from 'react';

import { LinkWithChild } from '#components';
import Config from '#configs/index';
import UITextButton from '#components/UITextButton';
import { height } from '#uikit/designCore/styles/height';
import { common } from '#uikit/designCore/styles/comon';
import { TypographyVariants } from '#uikit/designCore/constants/font';

const { siteURL } = Config.getLegalNotesConfig();

type Props = {
    children?: string | React.ReactNode;
};

const SiteLink: React.FC<Props> = ({ children = siteURL }) => {
    const href = typeof children === 'string' && children?.startsWith('http') ? children : `https://${children}`;

    return (
        <LinkWithChild href={href} classNames={common.displayInlineBlock}>
            <UITextButton
                title={children}
                buttonHeightClassName={height.littleCell}
                titleRole={TypographyVariants.ActionCallout}
            />
        </LinkWithChild>
    );
};

export { SiteLink };
