import UIColor from '../color';
// import UIFont from '../UIFont';
import UIConstant from '../constants';
import UIDevice from '../device';
import common, { styles } from './common';
import margin from './margin';
import flex from './flex';
import width from './width';
import height from './height';
import UIStyleColor from './color';
// import UIStyleBorder, { borderStyles } from './UIStyleBorder';
// import UIStylePadding, { paddingStyles } from './UIStylePadding';

// import UIStyleText from '../UITextStyle/UIStyleText';

const UIStyle = {
    ...styles,
    // ...widthStyles,
    // ...heightStyles,
    // ...marginStyles,
    // ...paddingStyles,
    // ...borderStyles,
    height,
    // navigator
    navigatorHeaderTitle: {
        // ...UIFont.bodyMedium(),
        textAlign: 'center',
        // color: UIColor.textPrimary(),
        alignSelf: 'center',
    },
    navigatorButton: {
        marginHorizontal: UIConstant.normalContentOffset(),
        height: 32,
        width: 32,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },

    // split view controller
    splitViewController: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        maxWidth: UIConstant.elasticWidthMax(),
        alignSelf: 'center',
        borderRadius: UIConstant.smallBorderRadius(),
        padding: UIConstant.contentOffset(),
    },
    masterViewController: {
        minWidth: UIConstant.masterScreenWidth(),
        maxWidth: UIConstant.masterScreenWidth(),
        overflow: 'hidden',
        borderRadius: UIConstant.smallBorderRadius(),
    },
    detailViewController: {
        flex: 1,
        marginLeft: UIConstant.contentOffset(),
        borderRadius: UIConstant.smallBorderRadius(),
        overflow: 'hidden',
    },
    common,
    margin,
    flex,
    width,
};

// UIStyle.border = UIStyleBorder;
UIStyle.color = UIStyleColor;
// UIStyle.common = Common;
// UIStyle.container = UIStyleContainer;
// UIStyle.margin = UIStyleMargin;
// UIStyle.padding = UIStylePadding;
// UIStyle.text = UIStyleText;
// UIStyle.width = UIStyleWidth;
// UIStyle.flex = UIStyleFlex;

export default UIStyle;
