import { action, computed, observable, when } from 'mobx';

import TONLog from './TONLog';

export class TONCommonStore {
    log = new TONLog(this.constructor.name);

    @observable initialized: boolean = false;

    @observable loading: boolean = false;

    @observable errors: string[] = [];

    // Loading
    @action
    load = async () => {
        this.startLoading();
        try {
            await this.onLoad();
            this.initializeEnd();
        } catch (error) {
            this.log.error('Loading error:', error);
        } finally {
            this.stopLoading();
        }
    };

    @action
    lazyLoad = async () => {
        if (this.loading || this.initialized) {
            return;
        }
        await this.load();
    };

    // eslint-disable-next-line
    @action
    onLoad = async () => {
        throw new Error('TONCommonStore: onLoad method must be overridden');
    };

    // Unloading
    @action
    unload = async () => {
        try {
            if (!this.initialized) {
                if (!this.loading) {
                    // No need to unload
                    return;
                }
                // Wait until loaded and initialized to unload
                await when(() => !this.loading && this.initialized, {
                    name: 'wait for store initialization',
                });
            }

            await this.onUnload();
            this.initializeReset();
        } catch (error) {
            this.log.error('Unloading error:', error);
        }
    };

    // eslint-disable-next-line
    @action
    onUnload = async () => {
        throw new Error('TONCommonStore: onUnload method must be overridden');
    };

    // Initialized flag
    @action
    initializeEnd = () => {
        this.initialized = true;
    };

    @action
    initializeReset = () => {
        this.initialized = false;
    };

    // Loading flag
    @action
    startLoading = () => {
        this.loading = true;
    };

    @action
    stopLoading = () => {
        this.loading = false;
    };

    // Error handling
    @action
    createError = (error: string) => {
        this.log.error(error);
        this.errors.push(error);
    };

    @action
    clearErrors = () => {
        this.errors = [];
    };

    @computed get isLoading() {
        return this.loading;
    }

    waitUntilInitialized = async (forceLaunch: boolean = false) => {
        if (this.initialized) {
            // No need to await
            return undefined;
        }

        if (forceLaunch) {
            await this.lazyLoad();
        }

        return when(() => this.initialized);
    };
}
