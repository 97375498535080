import controllerService from '@services/ControllerService';

export default class UIShareManager {
    static async copyToClipboard(message: string, success: string) {
        if (!message) {
            return;
        }

        await navigator.clipboard.writeText(message);
        controllerService.setAlertText(success);
        controllerService.setIsAlertOpen(true);
    }
}
