export const flex = {
    flex1: 'flex1',
    flex2: 'flex2',
    flex3: 'flex3',
    grow0: 'flexGrow0',
    grow1: 'flexGrow1',
    row: 'flexRow',
    column: 'flexColumn',
    rowWrap: 'flexRowWrap',
    alignCenter: 'alignCenter',
    alignItemsCenter: 'alignItemsCenter',
    alignSelfCenter: 'alignSelfCenter',
    alignSelfEnd: 'alignSelfEnd',
    alignEnd: 'alignEnd',
    alignStart: 'alignStart',
    alignBaseline: 'alignBaseline',
    alignStretch: 'alignStretch',
    justifyStart: 'justifyStart',
    justifyCenter: 'justifyCenter',
    justifyContentCenter: 'justifyContentCenter',
    alignJustifyCenter: 'alignJustifyCenter',
    justifySpaceBetween: 'justifySpaceBetween',
    justifyEnd: 'justifyEnd',
    flexAlignCenter: 'flexAlignCenter',
    flexJustifyCenter: 'flexJustifyCenter',
    flexJustifyEnd: 'flexJustifyEnd',
};
