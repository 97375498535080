export const height = {
    none: 'noneHeight',
    tinyCell: 'tinyCellHeight',
    littleCell: 'littleCellHeight',
    smallCell: 'smallCellHeight',
    mediumCell: 'mediumCellHeight',
    defaultCell: 'defaultCellHeight',
    bigCell: 'bigCellHeight',
    greatCell: 'greatCellHeight',
    majorCell: 'majorCellHeight',
    minGreatCell: 'minGreatCellHeight',
    button: 'buttonHeight',
    full: 'fullHeight',
    auto: 'autoHeight',
    borderHeight: 'borderHeight',
};
