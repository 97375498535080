import { useEffect, useState } from 'react';

import searchService from '@services/SearchService';
import { TONClient } from '#TONClient';
import TONSearch from '#TONClient/EVERSearch';
import { SearchResultGroup } from '#components/search/types';

const getSearchResults = async (searchExpression) => {
    return Promise.all(TONSearch.getRequests(searchExpression)).then((result) =>
        TONSearch.getInitialResults(searchExpression).map((group, index) => {
            if (!result[index]) return group;

            let newDataForGroup = [];

            const arrayedItem = Array.isArray(result[index]) ? result[index] : [result[index]];
            arrayedItem.forEach((elem) => {
                newDataForGroup = [...newDataForGroup, { item: elem }];
            });

            return { ...group, data: newDataForGroup.slice(0, TONClient.itemsLoadingMax) };
        })
    );
};

const getFormattedSearchResults = (results): SearchResultGroup[] =>
    results.map(({ data, getParams, labelRenderer, navigationPath, title }) => {
        const formattedGroupItems = data.map(({ item }) => ({
            item: item,
            title: labelRenderer(item),
            urlParams: getParams(item),
            navigationPath: navigationPath,
        }));

        return {
            title,
            items: formattedGroupItems,
        };
    });

const useSearch = (searchExpression: string) => {
    const [searchResults, setSearchResults] = useState(getFormattedSearchResults(TONSearch.getInitialResults('')));

    const onChangeSearchExpression = async (newSearchExpression: string) => {
        if (!newSearchExpression) {
            setSearchResults([]);
            return;
        }

        searchService.setIsSearchInProgress(true);

        const newSearchResults = await getSearchResults(newSearchExpression);
        const formattedSearchResult = getFormattedSearchResults(newSearchResults);

        // ! answers may not come in the order of the call
        if (newSearchExpression === searchService.searchExpression) setSearchResults(formattedSearchResult);

        searchService.setIsSearchInProgress(false);
    };

    useEffect(() => {
        onChangeSearchExpression(searchExpression);
    }, [searchExpression]);

    return searchResults;
};

export { useSearch };
