import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import dataService from '@services/DataServices';
import controllerService from '@services/ControllerService';
import { DePoolView, QRCode } from '#components';
import { TONDePool, TONFilter, TONFilterTags } from '#TONClient';
import type { TONDePoolT } from '#TONClient/TONDePool/types';
import { onWillFocus } from '#navigation/helpers';
import { useParams } from '#navigation/hooks/useParams';
import Utils from '#helpers/Utils';
import { common, container } from '#uikit/designCore/styles';

import ListScreen from '../ListScreen';

import './DePools.scss';

const formatDePoolsForScvExport = (item: any) => ({
    ...item,
    participants: (item.participants || []).map((participant: any) => {
        const newItem = { ...participant };
        delete newItem.lockDonor;
        delete newItem.vestingDonor;
        delete newItem.withdrawValue;

        return newItem;
    }),
});

const DePoolsComponent = () => {
    const ref = useRef(null);

    const [params] = useParams();

    const [filteredItems, setFilteredItems] = useState<TONDePoolT[]>([]);
    const [customItemsForExport, setCustomItemsForExport] = useState<TONDePoolT[]>([]);

    const externalControlConfigs = {
        ...DePoolView.externalConfigs,
        onChangeFilter: (newItems: TONDePoolT[]) => {
            setFilteredItems(newItems);
        },
        items: dataService.dePools,
        customItemsForExport,
        isLoading: !dataService.dePools,
    };

    // Details
    const details = [
        {
            title: (filteredItems || dataService.dePools || []).length,
            details: liveLocalized.DePools,
        },
        {
            isBalance: true,
            title: Utils.summarizeByKey(filteredItems || dataService.dePools || [], 'stakes'),
            details: liveLocalized.Stakes,
        },
    ];

    const dePoolsLink = 'https://uri.ton.surf/debot/0:66cb703cd63dd0b9eafbce702a9f838211ba1ea5ccce101dc81b98114d824b8a';
    const rightTitleComponent = controllerService.isNarrow ? null : (
        <div
            className={classnames(
                'DePoolQRCodeContainer',
                controllerService.reactContentClassNames,
                common.displayFlex,
                container.centerRight
            )}
        >
            <QRCode link={dePoolsLink} />
        </div>
    );

    // Actions
    const loadDePools = async () => {
        if (!dataService.dePools?.length) {
            controllerService.showSpinnerOverlay();
        }
        const dePools = await TONDePool.getDePools();
        TONFilter.sortBy(dePools || [], { direction: 'DESC' }, DePoolView.externalConfigs.sortingKey);

        dataService.setDePools(dePools.map((item, index) => ({ ...item, place: index + 1 })));
        setCustomItemsForExport(dePools.map(formatDePoolsForScvExport));
        controllerService.hideSpinnerOverlay();
    };

    useEffect(() => {
        return onWillFocus(params, ref, loadDePools);
    }, [params]);

    return (
        <ListScreen
            ref={ref}
            title={liveLocalized.CatalogDePools}
            details={details}
            filterProps={TONFilterTags.getDePoolProps()}
            collectionConfigs={DePoolView.getCollectionConfigs()}
            externalControlConfigs={externalControlConfigs}
            realtimeUpdated={false}
            // descriptionComponent={descriptionComponent}
            rightTitleComponent={rightTitleComponent}
        />
    );
};

const DePools = observer(DePoolsComponent);

export { DePools };
