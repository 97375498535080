import React, { ReactNode } from 'react';
import classnames from 'classnames';

import { UILabel } from '#components';
import {
    UIActionComponent,
    UIActionComponentProps,
    UIActionComponentState,
} from '#components/UIActionComponent/ReactActionClassComponent';
import UIColor from '#uikit/designCore/color';
import { border, color, common, container, margin } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import './UIButton.scss';

enum IconPosition {
    left = 'left',
    right = 'right',
}

export enum ButtonStyle {
    full = 'full',
    border = 'border',
}

export type ButtonProps = UIActionComponentProps & {
    buttonColor?: ColorVariants;
    buttonSize?: string;
    buttonShape?: string;
    buttonStyle?: ButtonStyle;
    iconL?: any;
    iconR?: any;
    iconLClassNames?: string;
    iconRClassNames?: string;
    classNames?: string;
    textAlign?: string;
    title?: string;
    titleColor?: ColorVariants;
    theme?: string;
    testID?: string;
};

type State = UIActionComponentState;

type ButtonWrapperProps = {
    buttonStyle?: 'full' | 'border';
    buttonColor?: ColorVariants;
    classNames?: string;
    children: ReactNode;
};

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ buttonStyle, buttonColor, classNames, ...otherProps }) => (
    <div
        {...otherProps}
        className={classnames('UIButton', classNames, common.overflowHidden, {
            border: buttonStyle === 'border',
            [border[buttonColor || '']]: buttonStyle === 'border',
            [border.colorAccent]: buttonStyle === 'border' && !buttonColor,
            [color.BackgroundAccent]: buttonStyle === 'full' && !buttonColor,
        })}
    />
);

export default class UIButton extends UIActionComponent<ButtonProps, State> {
    static buttonSize = {
        default: 'default',
        large: 'l',
        medium: 'm',
        small: 's',
        tiny: 't',
    };

    static buttonShape = {
        default: 'default',
        radius: 'radius',
        mediumRadius: 'medium-radius',
        rounded: 'rounded',
        full: 'full',
    };

    static buttonStyle = {
        full: 'full',
        border: 'border',
        link: 'link',
    };

    static textAlign = {
        center: 'center',
        left: 'left',
        right: 'right',
    };

    // Getters
    getHeightClassNames = () =>
        ({
            [UIButton.buttonSize.tiny]: 'tinyHeight',
            [UIButton.buttonSize.small]: 'smallHeight',
            [UIButton.buttonSize.medium]: 'mediumHeight',
            [UIButton.buttonSize.large]: 'largeHeight',
            [UIButton.buttonSize.default]: 'defaultHeight',
        }[this.props.buttonSize] || '');

    getRadiusClassNames = () =>
        ({
            [UIButton.buttonShape.radius]: 'smallRadius',
            [UIButton.buttonShape.mediumRadius]: 'mediumRadius',
            [UIButton.buttonShape.rounded]: 'roundedRadius',
            [UIButton.buttonShape.full]: '',
            [UIButton.buttonShape.default]: 'defaultRadius',
        }[this.props.buttonShape] || '');

    getTitleRole = (): TypographyVariants =>
        ({
            [UIButton.buttonSize.large]: TypographyVariants.Action,
            [UIButton.buttonSize.medium]: TypographyVariants.ActionCallout,
            [UIButton.buttonSize.small]: TypographyVariants.ActionFootnote,
            [UIButton.buttonSize.tiny]: TypographyVariants.ActionFootnote,
        }[this.props.buttonSize] || TypographyVariants.Action);

    getTitleColor = (): ColorVariants => {
        const { buttonColor, disabled, titleColor } = this.props;
        if (titleColor) {
            return titleColor;
        }
        if (disabled) {
            return ColorVariants.BackgroundTertiary;
        }

        return buttonColor || ColorVariants.BackgroundAccent;
    };

    renderIcon(Icon: any, position: IconPosition) {
        const marginClassNames = this.props.title ? (position === 'left' ? margin.rightSmall : margin.leftSmall) : '';
        const classNamesByPosition = position === 'left' ? this.props.iconLClassNames : this.props.iconRClassNames;
        const resultClassNames = classnames(marginClassNames, classNamesByPosition);

        return <Icon className={resultClassNames} key={`${Math.random()}`} />;
    }

    renderIconL() {
        if (!this.props.iconL && !this.props.hasIcon) return null;
        return this.renderIcon(this.props.iconL, IconPosition.left);
    }

    renderIconR() {
        if (!this.props.iconR && !this.props.hasIconR) return null;
        return this.renderIcon(this.props.iconR, IconPosition.right);
    }

    renderTitle() {
        if (this.shouldShowIndicator()) {
            return null;
        }

        const hovered = this.isHover() || this.isTapped();

        return (
            <UILabel
                key="buttonTitle"
                color={this.getTitleColor()}
                role={this.getTitleRole()}
                classNames={classnames(hovered ? this.props.textHoverClassNames : this.props.textClassNames)}
            >
                {this.props.title}
            </UILabel>
        );
    }

    renderLeftLayout(hasIconLeftOnly: boolean, hasIconRightOnly: boolean, hasIcons: boolean) {
        const content = hasIcons
            ? [
                  <div className={container.centerLeft}>
                      {this.renderIconL()}
                      {this.renderTitle()}
                  </div>,
                  this.renderIconR(),
              ]
            : [this.renderIconL(), this.renderTitle(), this.renderIconR()];

        return (
            <div
                className={classnames(
                    common.displayFlex,
                    hasIcons || hasIconLeftOnly ? container.rowCenterSpace : container.centerLeft
                )}
            >
                {content}
            </div>
        );
    }

    renderRightLayout(hasIconLeftOnly: boolean, hasIconRightOnly: boolean, hasIcons: boolean) {
        const content = hasIcons
            ? [
                  this.renderIconR(),
                  <div className={container.centerLeft}>
                      {this.renderTitle()}
                      {this.renderIconR()}
                  </div>,
              ]
            : [this.renderIconL(), this.renderTitle(), this.renderIconR()];

        return (
            <div
                className={classnames(
                    common.displayFlex,
                    hasIcons || hasIconLeftOnly ? container.rowCenterSpace : container.centerRight
                )}
            >
                {content}
            </div>
        );
    }

    renderCenterLayout(hasIconLeftOnly: boolean, hasIconRightOnly: boolean, hasIcons: boolean) {
        const content = [this.renderIconL(), this.renderTitle(), this.renderIconR()];
        return (
            <div
                className={classnames(
                    common.displayFlex,
                    hasIcons || hasIconLeftOnly ? container.rowCenterSpace : container.center
                )}
            >
                {content}
            </div>
        );
    }

    renderContent() {
        const { bottomExtend, classNames, buttonStyle, buttonColor, textAlign, iconL, iconR, title } = this.props;

        const hasIconLeftOnly = iconL && !iconR;
        const hasIconRightOnly = iconR && !iconL;
        const hasIcons = iconL && iconR;

        let content: ReactNode | null = null;
        if (title && hasIconLeftOnly) {
            const alignContainerClassNames =
                textAlign === UIButton.textAlign.left
                    ? container.centerLeft
                    : textAlign === UIButton.textAlign.right
                    ? container.centerRight
                    : container.center;

            content = (
                <div className={classnames(alignContainerClassNames, common.displayFlex)}>{this.renderIconL()}</div>
            );
        } else if (textAlign === UIButton.textAlign.left) {
            content = this.renderLeftLayout(hasIconLeftOnly, hasIconRightOnly, hasIcons);
        } else if (textAlign === UIButton.textAlign.center) {
            content = this.renderCenterLayout(hasIconLeftOnly, hasIconRightOnly, hasIcons);
        } else if (textAlign === UIButton.textAlign.right) {
            content = this.renderRightLayout(hasIconLeftOnly, hasIconRightOnly, hasIcons);
        }

        return (
            <ButtonWrapper
                buttonStyle={buttonStyle}
                buttonColor={buttonColor}
                classNames={classnames(
                    classNames,
                    buttonColor,
                    this.getRadiusClassNames(),
                    this.getHeightClassNames(),
                    {
                        doubleHeight: bottomExtend,
                    }
                )}
            >
                {content}
            </ButtonWrapper>
        );
    }

    static defaultProps: ButtonProps;
}

UIButton.defaultProps = {
    ...UIActionComponent.defaultProps,
    buttonColor: null,
    buttonStyle: UIButton.buttonStyle.full,
    iconL: null,
    iconR: null,
    theme: UIColor.Theme.Light,
    title: '',
    titleColor: null,
    iconStyle: null,
    iconRStyle: null,
    textAlign: UIButton.textAlign.center,
    testID: 'uiButton',
};
