import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import UITextButton from '#components/UITextButton';
import { ExternalLink, LinkWithChild, UIBottomBar } from '#components';
import LinkManager from '#helpers/LinkManager';
import { paths } from '#navigation/paths';
import ErrorScreen from '#controllers/ErrorScreen';
//@ts-ignore doesn`t see import
import IconTonlabs from '#assets/brands/tonlines/tonlabs.png';
import { common, flex, width } from '#uikit/designCore/styles';

import BottomNavigationBar from './BottomNavigationBar';
import UIScreen from './UIScreen';
import type { UIScreenRefType } from './UIScreen/types';
import { ScreenRefType } from './types';

import './Screen.scss';

type Props = {
    customContentComponent?: ReactNode;
    contentComponent?: ReactNode;
};

const Screen = forwardRef<ScreenRefType, Props>(({ customContentComponent = null, contentComponent = null }, Ref) => {
    const ref = useRef<UIScreenRefType>(null);

    const onFocus = () => {
        controllerService.setErrorCaption('');
        controllerService.setErrorCode(null);
    };

    // Components
    const bottomBarMenuItems = [
        {
            title: liveLocalized.LegalNotes,
            componentClass: (textButtonProps) => (
                <LinkWithChild navigationPath={paths.legalNotes}>
                    <UITextButton {...textButtonProps} />
                </LinkWithChild>
            ),
            href: '',
        },
        {
            title: liveLocalized.ApiDocumentation,
            componentClass: ExternalLink,
            href: LinkManager.urls.apiDocs,
        },
    ];

    const bottomBarComponent = (
        <UIBottomBar
            isNarrow={controllerService.isNarrow}
            menuItems={bottomBarMenuItems}
            copyRight={liveLocalized.CopyRight}
            copyRightIconPath={IconTonlabs}
        />
    );
    const bottomNavBarComponent = <BottomNavigationBar />;

    const mainContentComponent = controllerService.errorCode ? (
        <ErrorScreen />
    ) : contentComponent ? (
        <>
            <div className={classnames('Content', common.displayFlex, flex.column, flex.flex1, width.full)}>
                {contentComponent}
            </div>
            {bottomNavBarComponent}
            {bottomBarComponent}
        </>
    ) : (
        <>
            <div className={classnames('Content', common.displayFlex, flex.column, flex.flex1, width.full)}>
                {customContentComponent}
            </div>
            {bottomNavBarComponent}
            {bottomBarComponent}
        </>
    );

    useImperativeHandle(
        Ref,
        () => ({
            componentWillFocus: () => {
                ref?.current && ref.current.componentWillFocus();
                onFocus();
            },
            componentWillBlur: () => {
                ref?.current && ref.current.componentWillBlur();
            },
        }),
        [ref.current]
    );

    useEffect(() => {
        ref?.current && ref.current.componentWillFocus();
        onFocus();
    }, []);

    return <UIScreen ref={ref} contentComponent={mainContentComponent} />;
});

export default observer(Screen);
