// @flow
import { liveLocalized } from '@services/LocalizationService';
import type { TONMaster, TONShardFee, TONShardHash } from '#TONClient/EVERBlock/types';
import UIDetailsTable from '#components/UIDetailsTable';
import DetailsRow from '#controllers/Details/DetailsRowHelper';

export default class ShardDetails {
    static shardHash(hash: TONShardHash, index: number) {
        if (!hash) return [];

        const { shard_hashes: locale } = liveLocalized.TONBlock.master;
        return UIDetailsTable.formatNestedList(
            [
                { caption: `${locale.title} ${index}` },
                {
                    caption: `${liveLocalized.Workchain}:${liveLocalized.Shard}`,
                    value: `${hash.workchain_id}:${hash.shard}`,
                },
                { caption: locale.descr.seq_no, value: hash.descr.seq_no },
                { caption: locale.descr.reg_mc_seqno, value: hash.descr.reg_mc_seqno },
                {
                    caption: `${locale.descr.start_lt} - ${locale.descr.end_lt}`,
                    value: `${hash.descr.start_lt} - ${hash.descr.end_lt}`,
                },
                DetailsRow.blockLinkWithCopy(locale.descr.root_hash, hash.descr.root_hash),
                { caption: locale.descr.file_hash, value: hash.descr.file_hash },
                { caption: locale.descr.before_split, value: hash.descr.before_split },
                { caption: locale.descr.before_merge, value: hash.descr.before_merge },
                { caption: locale.descr.want_split, value: hash.descr.want_split },
                { caption: locale.descr.want_merge, value: hash.descr.want_merge },
                { caption: locale.descr.nx_cc_updated, value: hash.descr.nx_cc_updated },
                { caption: locale.descr.flags, value: hash.descr.flags },
                { caption: locale.descr.next_catchain_seqno, value: hash.descr.next_catchain_seqno },
                { caption: locale.descr.next_validator_shard, value: hash.descr.next_validator_shard },
                { caption: locale.descr.min_ref_mc_seqno, value: hash.descr.min_ref_mc_seqno },
                { caption: locale.descr.gen_utime, value: hash.descr.gen_utime },
                { caption: locale.descr.split_type, value: hash.descr.split_type },
                { caption: locale.descr.split_type_name, value: hash.descr.split_type_name },
                { caption: locale.descr.split, value: hash.descr.split },
                { caption: locale.descr.fees_collected, value: hash.descr.fees_collected },
                { caption: locale.descr.funds_created, value: hash.descr.funds_created },
            ],
            `hash${index}`
        );
    }

    static shardHashesList(shard_hashes: TONShardHash[]) {
        if (!shard_hashes) return [];

        let result = [];
        shard_hashes.forEach((hash, index) => {
            result = [...result, ...this.shardHash(hash, index)];
        });
        return result;
    }

    static shardFee(shard_fee: TONShardFee, index: number) {
        if (!shard_fee) return [];

        const { shard_fees: locale } = liveLocalized.TONBlock.master;
        return UIDetailsTable.formatNestedList(
            [
                { caption: `${locale.title} ${index}` },
                {
                    caption: `${liveLocalized.Workchain}:${liveLocalized.Shard}`,
                    value: `${shard_fee.workchain_id}:${shard_fee.shard}`,
                },
                { caption: locale.fees, value: shard_fee.fees },
                { caption: locale.create, value: shard_fee.create },
            ],
            `fee${index}`
        );
    }

    static shardFeesList(shard_fees: TONShardFee[]) {
        if (!shard_fees) return [];

        let result = [];
        shard_fees.forEach((fee, index) => {
            result = [...result, ...this.shardFee(fee, index)];
        });
        return result;
    }

    static shards(master: ?TONMaster) {
        if (!master || !master.config) return [];

        const { min_shard_gen_utime, max_shard_gen_utime } = liveLocalized.TONBlock.master;
        const result = [];
        master?.min_shard_gen_utime &&
            result.push({
                caption: min_shard_gen_utime,
                value: master?.min_shard_gen_utime,
            });
        master?.max_shard_gen_utime &&
            result.push({
                caption: max_shard_gen_utime,
                value: master?.max_shard_gen_utime,
            });
        return [...result, ...this.shardHashesList(master?.shard_hashes), ...this.shardFeesList(master?.shard_fees)];
    }
}
