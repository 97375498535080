import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import classnames from 'classnames';

import { themesService } from '@services/ThemesService';
import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { LinkWithChild, UITextButton, Popover, UIButton, ActionImage } from '#components';
import { navigationMenuItems } from '#navigation/navigationMenuItems';
import type { NavigationMenuItem } from '#navigation/navigationMenuItems';
import { common, margin, padding } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { UIColorPalette } from '#uikit/designCore';

import { ThemeToggle } from '../ThemeToggle';

type SiteMenuItemProps = {
    items: NavigationMenuItem[];
    headTitle: string;
};

export const siteMenuTestIDs = {
    siteMenu: 'siteMenu',
    menuButton: (title: string) => `menuButton_${title}`,
};

const SiteMenuItem: React.FC<SiteMenuItemProps> = observer(({ items, headTitle }) => {
    const location = useLocation();
    const [shown, setShown] = useState<boolean>(false);

    const subMenu = items.map(({ title, navigationPath }) => {
        const isCurrent = location.pathname === navigationPath;

        const button = (
            <UITextButton
                key={`top~bar~right~sub~menu~button~${title}`}
                title={liveLocalized[title]}
                titleColor={isCurrent ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
                testID={siteMenuTestIDs.menuButton(title)}
            />
        );

        return isCurrent ? (
            button
        ) : (
            <LinkWithChild
                key={`top~bar~right~menu~link~${title}`}
                navigationPath={navigationPath}
                onFollow={() => setShown(false)}
            >
                {button}
            </LinkWithChild>
        );
    });

    return (
        <Popover
            component={subMenu}
            componentClassNames={padding.default}
            narrowComponentClassNames={padding.default}
            onShow={() => setShown(true)}
            onHide={() => setShown(false)}
        >
            <UIButton
                classNames={padding.horizontalSmall}
                title={liveLocalized[headTitle]}
                iconR={KeyboardArrowDownIcon}
                iconRClassNames={classnames('arrowDrop', { show: shown })}
                buttonColor={shown ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
                testID={siteMenuTestIDs.menuButton(headTitle)}
            />
        </Popover>
    );
});

const SiteMenu: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);

    const { topBarRightWidth } = controllerService;

    useEffect(() => {
        if (containerRef.current) {
            //@ts-ignore doesn`t see ResizeObserver
            const containerResizeObserver = new ResizeObserver((entries) => {
                if (entries[0]) {
                    const containerWidth = entries[0].contentRect.width;
                    if (containerWidth && containerWidth !== topBarRightWidth) {
                        controllerService.setTopBarRightWidth(containerWidth);
                    }
                }
            });

            containerResizeObserver.observe(containerRef.current);

            return () => {
                containerResizeObserver.disconnect();
            };
        }
    }, [containerRef.current]);

    return controllerService.screenWidth > 700 ? (
        <div
            ref={containerRef}
            className={classnames(padding.rightDefault, common.displayFlex)}
            data-testid={siteMenuTestIDs.siteMenu}
        >
            {navigationMenuItems.map(({ title, items }) => (
                <SiteMenuItem key={`top~bar~right~menu~button~${title}`} headTitle={title} items={items} />
            ))}
            <ThemeToggle />
        </div>
    ) : (
        <>
            <ThemeToggle />
            <ActionImage
                Img={DragHandleOutlinedIcon}
                fill={themesService.isDarkTheme ? UIColorPalette.text.darkPrimary : UIColorPalette.text.lightPrimary}
                onClick={() => controllerService.setIsMenuScreenOpen(true)}
                className={margin.horizontalOffset}
                fontSize="medium"
            />
        </>
    );
};

export default observer(SiteMenu);
