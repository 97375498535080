import { CollectionReference, QuerySnapshot } from '@Firebase/firestore-types';
import { TONLog } from '#TONUtility';
import { TONAccount, TONClient, TONDePool } from '#TONClient';
import type { DePoolInfoExternal, DePoolRoundObjExternal, TONDePoolT } from '#TONClient/TONDePool/types';
import Utils from '#helpers/Utils';
import EnvManager from '#helpers/EnvManager';

import FBTemplate from './FBTemplate';

export type FBDePoolParticipant = {
    address?: string;
    reinvest: boolean;
    reward: string;
    total: string;
    vestings: { [key: string]: string };
    locks: { [key: string]: string };
    stakes: { [key: string]: string };
    withdrawValue: string;
};

type FBDePool = {
    getDePoolInfo: DePoolInfoExternal;
    getDePoolBalance: { [key: string]: string };
    getParticipantInfo: { [key: string]: FBDePoolParticipant };
    getParticipants: string[];
    getRounds: {
        rounds: {
            [key: string]: DePoolRoundObjExternal;
        };
    };
    updatedAt: number;
    id: string;
};

const log = new TONLog('FBDePools');

export default class FBDePools extends FBTemplate {
    static collectionName = 'contracts';
    static dePoolsDocName = 'depool';

    static dePools: TONDePoolT[] | null = null;

    static async getDePoolsDocs(): Promise<CollectionReference<FBDePool> | null> {
        try {
            const dePoolsDoc = await FBDePools.getCollection().doc(FBDePools.dePoolsDocName);
            return dePoolsDoc.collection(EnvManager.getNetwork());
        } catch (e) {
            return null;
        }
    }

    static async getDePools(): Promise<TONDePoolT[] | null> {
        if (FBDePools.dePools === null) {
            FBDePools.dePools = await FBDePools.loadDePools();
        }

        return FBDePools.dePools;
    }

    static async findDePool(address: string): Promise<TONDePoolT | null> {
        try {
            if (!/^[0-9A-F:-]*$/i.test(address)) {
                log.debug('Invalid address');
                return null;
            }

            if (FBDePools.dePools !== null) {
                const dePool = FBDePools.dePools.filter(({ id }: TONDePoolT) => id === address)[0];
                if (!dePool) return null;

                return dePool;
            }

            const snapshotDoc = await FBDePools.getDePoolsDocs().then((collection) => collection?.doc(address).get());
            if (snapshotDoc?.exists) {
                const collSnapshotData = await snapshotDoc.data();

                return {
                    ...collSnapshotData,
                    id: address,
                };
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    static async loadDePools(): Promise<TONDePoolT[] | null> {
        try {
            const collSnapshot = await FBDePools.getDePoolsDocs().then((docs) => docs?.get());
            if (!collSnapshot) return null;

            const collSnapshotData = await Promise.all(collSnapshot.docs.map((doc) => doc.data()));
            const fbDePoolDocs = collSnapshotData.map((doc, index) => ({
                ...doc,
                id: collSnapshot.docs[index].id,
            }));
            const codeHashesById = await TONAccount.getCodeHashesById(fbDePoolDocs.map(({ id }) => id));

            const blackList = await FBDePools.getCollection()
                .doc(this.dePoolsDocName)
                .collection('main-blacklist')
                .get()
                .then((docs: QuerySnapshot<{}>) => docs.docs.map((doc) => doc.id));

            const result = fbDePoolDocs
                .filter((doc) => !blackList.includes(doc.id))
                .map((doc) => {
                    const { getDePoolInfo, getRounds, getParticipants, getParticipantInfo } = doc;
                    const formattedParticipants = getParticipantInfo
                        ? (getParticipants || [])
                              .map((item) => ({
                                  ...(getParticipantInfo[item] || {}),
                                  address: item,
                              }))
                              .map((participant: FBDePoolParticipant) =>
                                  TONDePool.participantFormatter(participant, true)
                              )
                        : getParticipants || [];

                    const formattedRounds = (getRounds?.rounds
                        ? Utils.objectValues(getRounds.rounds)
                        : []
                    ).map((round: DePoolRoundObjExternal) => TONDePool.roundFormatter(round, true));

                    const balance = Utils.objectValues(doc.getDePoolBalance || {})[0];

                    return {
                        ...getDePoolInfo,
                        code_hash: codeHashesById[doc.id]?.code_hash,
                        id: doc.id,
                        updatedAt: doc.updatedAt,
                        balance,
                        validatorAssurance: TONClient.hex0xToDec(getDePoolInfo?.validatorAssurance || ''),
                        validatorRewardFraction: TONClient.hex0xToDec(getDePoolInfo?.validatorRewardFraction || ''),
                        stakes: formattedRounds.reduce((prev, curr) => prev + (curr.step === 9 ? 0 : curr.stake), 0),
                        // don't need these fields for now
                        // rounds: formattedRounds,
                        participants: formattedParticipants,
                        participantsCount: formattedParticipants.length,
                    };
                });
            log.debug('DePools were loaded from FB', result);

            return result;
        } catch (e) {
            return null;
        }
    }
}
