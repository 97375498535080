import { NetworkQueryResponseType } from '#TONClient/types';

enum TransferTypeType {
    MINT = 'MINT',
    BURN = 'BURN',
    TRANSFER = 'TRANSFER',
    REVERT_SEND = 'REVERT_SEND',
    REVERT_BURN = 'REVERT_BURN',
    REVERT_MINT = 'REVERT_MINT',
    RECEIVE = 'RECEIVE',
    REVERT_RECEIVE = 'REVERT_RECEIVE',
}

enum TransferTypeFilterType {
    MINT = 'MINT',
    BURN = 'BURN',
    SEND = 'SEND',
    RECEIVE = 'RECEIVE',
    REVERT_BURN = 'REVERT_BURN',
    REVERT_MINT = 'REVERT_MINT',
    REVERT_SEND = 'REVERT_SEND',
    REVERT_RECEIVE = 'REVERT_RECEIVE',
}

type AccountTransferFilterValues = {
    transferType: TransferTypeFilterType;
};

type TransferDetailsType = {
    tokenSymbol: string;
    tokenDecimals: number;
    transferType: string;
    fromHolderAddress: string;
    toHolderAddress: string;
    value: number;
    timestamp: number;
};

type AccountTransfer = {
    type: TransferTypeType;
    tokenSymbol: string;
    decimals: number;
    to?: string;
    from?: string;
    messageId: string;
    timestamp: number;
    value: number;
};

type TransfersByMessageIDResponseType = NetworkQueryResponseType<{
    ft: {
        transfersByMessageIds: {
            token: {
                symbol: string;
                decimals: number;
            };
            transferType: TransferTypeType;
            fromHolder: {
                address: string;
            };
            toHolder: {
                address: string;
            };
            value: string;
            timestamp: number;
        }[];
    };
}>;

type AccountTransferQueryType = NetworkQueryResponseType<{
    blockchain: {
        account: {
            info: {
                tokenHolder: {
                    transfers: {
                        pageInfo: {
                            endCursor: string;
                        };
                        edges: {
                            cursor;
                            node: {
                                transferType: TransferTypeType;
                                token: {
                                    symbol: string;
                                    decimals: number;
                                };
                                toHolder?: { address: string };
                                fromHolder?: { address: string };
                                messageId: string;
                                timestamp: number;
                                value: string;
                            };
                        }[];
                    };
                };
            };
        };
    };
}>;

export { TransferTypeType, TransferTypeFilterType };
export type {
    AccountTransfer,
    AccountTransferFilterValues,
    TransfersByMessageIDResponseType,
    AccountTransferQueryType,
    TransferDetailsType,
};
