import type { SortDirection } from '#TONClient/TONFilter';
import { Maybe } from 'graphql/jsutils/Maybe';
import { GetBlocksArgs } from '#TONClient/EVERBlock/types';
import { getMasterSeqNoRangeFromNewApi } from '#TONClient/helpers';
import { additionalFields, binaryFields, shortFields } from '#TONClient/EVERBlock/fields';

const orderByGenUTime: { path: string; direction: SortDirection }[] = [{ path: 'gen_utime', direction: 'DESC' }];
export const orderByNumber: { path: string; direction: SortDirection }[] = [{ path: 'seq_no', direction: 'DESC' }];
export const orderByTimeAndNumber = [...orderByGenUTime, ...orderByNumber];

export const newResult = (args: Maybe<GetBlocksArgs>, needP40: boolean = false) =>
    `
          ${shortFields}
          ${args?.needAdditionalFields ? additionalFields(needP40) : ''}
          ${args?.needBinaryFields ? binaryFields : ''}
    `;

export const newFilter = async (args: Maybe<GetBlocksArgs>): Promise<string | null> => {
    try {
        if (!args) {
            return '';
        }

        const { filterValues, direction, limit, lastItem } = args;
        const workchain = filterValues?.workchain_id?.value;
        const shard = filterValues?.shard?.value?.shard;
        const minTransactionsCount = filterValues?.minTransactions?.value;
        const maxTransactionsCount = filterValues?.maxTransactions?.value;
        const isKeyBlocks = filterValues?.key_block?.value;

        const allBlockFilters = `
            ${workchain || workchain === 0 || shard ? `workchain: ${workchain || 0},` : ''}
            ${shard ? `thread: "${shard}",` : ''}
            ${minTransactionsCount || minTransactionsCount === 0 ? `min_tr_count: ${minTransactionsCount},` : ''}
            ${maxTransactionsCount || maxTransactionsCount === 0 ? `max_tr_count: ${maxTransactionsCount},` : ''}
            `;
        const masterSeqNoRange = await getMasterSeqNoRangeFromNewApi(
            filterValues?.minTime?.value,
            filterValues?.maxTime?.value
        );
        const cursorPosition = lastItem?.endCursor
            ? `${direction === 'DESC' ? 'before' : 'after'}: "${lastItem.endCursor}",`
            : '';
        const itemsRange = `${direction === 'DESC' ? 'last' : 'first'}: ${limit},`;

        return `${masterSeqNoRange}${isKeyBlocks ? '' : allBlockFilters}${cursorPosition}${itemsRange}`;
    } catch (e) {
        return null;
    }
};

export const getBlocksQuery = async (args: GetBlocksArgs): Promise<string> => {
    const isKeyBlocksQuery = !!args?.filterValues?.key_block?.value;
    const filter = await newFilter(args);
    const result = newResult(args);

    if (!filter) return '';

    return `
        query {
            blockchain {
                ${isKeyBlocksQuery ? 'key_blocks' : 'blocks'}(${filter}) {
                    edges {
                        node {
                            ${result}
                        }
                        cursor
                    }
                    pageInfo{
                        endCursor
                    }
                }
            }
        }
    `;
};
