import React from 'react';
import classnames from 'classnames';

import type { Profile } from '@Firebase/FBProfiles';
import { margin } from '#uikit/designCore/styles';

import './Avatar.scss';

type Props = {
    profile: Profile;
};

const Avatar: React.FC<Props> = ({ profile }) => {
    if (!profile?.avatar) return null;

    return <img src={profile.avatar} alt="avatar" className={classnames('Avatar', margin.rightSmall)} />;
};

export default Avatar;
