// @flow
import { action, makeAutoObservable, observable } from 'mobx';

class SearchService {
    @observable isSearchModalAvailableToBeOpen = false;
    @observable searchExpression: string = '';
    @observable isSearchInProgress: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setIsSearchModalAvailableToBeOpen = (isAvailable: boolean) => {
        this.isSearchModalAvailableToBeOpen = isAvailable;
    };

    @action
    setIsSearchInProgress = (isInProgress: boolean) => {
        this.isSearchInProgress = isInProgress;
    };

    @action
    setSearchExpression = (newSearchExpression: string = '') => {
        this.searchExpression = newSearchExpression;
    };
}

const searchService = new SearchService();

export default searchService;
