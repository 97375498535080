import { QueryDocumentSnapshot } from '@Firebase/firestore-types';
import { TONLog } from '#TONUtility';
import EnvManager from '#helpers/EnvManager';

import FBTemplate from './FBTemplate';

const customLog = new TONLog('FBAccounts');

export default class FBAccounts extends FBTemplate {
    static collectionName = 'named-accounts';

    static namesById: { [key: string]: string } | null = null;
    static idsByTypes: { [key: string]: string[] } | null = null;

    static typeNames = {
        giver: 'giver',
        burner: 'burner',
    };

    static async getAccountNamesById(): Promise<{ [key: string]: string }> {
        if (!EnvManager.isMainnet()) {
            return {};
        }

        if (FBAccounts.namesById === null) {
            const namesAndTypes = await FBAccounts.loadAccountNamesAndTypes();
            FBAccounts.namesById = namesAndTypes?.namesById || {};
            FBAccounts.idsByTypes = namesAndTypes?.idsByTypes || {};
        }

        return FBAccounts.namesById;
    }

    static async getAccountIdsByType(): Promise<{ [key: string]: string[] }> {
        if (FBAccounts.idsByTypes === null) {
            const namesAndTypes = await FBAccounts.loadAccountNamesAndTypes();
            FBAccounts.namesById = namesAndTypes?.namesById || {};
            FBAccounts.idsByTypes = namesAndTypes?.idsByTypes || {};
        }

        return FBAccounts.idsByTypes;
    }

    static async loadAccountNamesAndTypes(): Promise<{
        namesById: { [key: string]: string };
        idsByTypes: { [key: string]: string[] };
    } | null> {
        try {
            const collectionRef = FBAccounts.getCollection();
            const collSnapshot = await collectionRef.get();

            const accountsData: { id: string; name: string; type?: string }[] = await Promise.all(
                collSnapshot.docs.map((doc: QueryDocumentSnapshot<{ id: string; name: string; type?: string }>) => {
                    const data = doc.data();
                    return {
                        name: data?.name,
                        type: data.type,
                        id: doc.id,
                    };
                })
            );

            const namesById = accountsData.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.id]: curr.name,
                }),
                {}
            );

            let types: string[] = [];
            accountsData.forEach((account) => {
                if (account.type && !types.includes(account.type)) {
                    types = [...types, account.type];
                }
            });

            const idsByTypes: { [key: string]: string[] } | {} = {};
            types.forEach((type) => {
                idsByTypes[type] = accountsData.filter((account) => account.type === type).map((account) => account.id);
            });

            const result = {
                namesById,
                idsByTypes,
            };
            customLog.debug('Load custom names and types', result);
            return result;
        } catch (e) {
            return null;
        }
    }
}
