import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import controllerService from '@services/ControllerService';
import { BackgroundView, SpinnerOverlay } from 'components';
import { UIScreenRefType } from '#controllers/Screen/UIScreen/types';
import { common, flex } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';

import './UIScreen.scss';

type ScreenProps = {
    contentComponent?: ReactNode;
};

const UIScreen = forwardRef<UIScreenRefType, ScreenProps>(({ contentComponent = null }, Ref) => {
    const [isFocused, setFocus] = useState<boolean>(false);

    // Actions
    const componentWillFocus = useCallback(() => {
        setFocus(true);
    }, []);

    const componentWillBlur = useCallback(() => {
        setFocus(false);
    }, []);

    useImperativeHandle(Ref, () => ({
        componentWillFocus,
        componentWillBlur,
    }));

    return (
        <BackgroundView
            classNames={classnames('UIScreen', common.displayFlex, flex.column, flex.flex1)}
            color={ColorVariants.BackgroundPrimary}
            {...(isFocused ? { 'data-testid': 'activeScreen' } : {})}
        >
            <div
                className={classnames(
                    'UIScreen__content',
                    common.displayFlex,
                    flex.column,
                    flex.flex1,
                    flex.alignCenter
                )}
            >
                {contentComponent}
            </div>
            <SpinnerOverlay visible={controllerService.spinnerVisible && isFocused} />
        </BackgroundView>
    );
});

export default observer(UIScreen);
