import React from 'react';
import classNames from 'classnames';
import { SortDirection } from '@eversdk/core';

import { liveLocalized } from '@services/LocalizationService';
import UIDetailsButton, { UIDetailsButtonCopyTargets, UIDetailsButtonTestIds } from '#components/UIDetailsButton';
import { BalanceView, LinkWithChild, TabView, UILabel } from '#components';
import type { TONCollectionConfigs } from '#components/EVERList';
import { TokenDetailsWallet, TokenWallet } from '#TONClient/EVERTip3/types';
import { paths } from '#navigation/paths';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex } from '#uikit/designCore/styles/flex';
import { common } from '#uikit/designCore/styles/comon';

type Props = {
    item: TokenDetailsWallet & { index: number };
};

const TokenWalletsViewTestIDs = {
    TokenWalletsView: 'TokenWallets-view',
};

const TokenWalletsView: React.FC<Props> = ({ item }) => {
    if (!item) return null;

    const { balance, index, walletAddress, percentage } = item;

    const percentStr = `${percentage.toFixed(2)}%`;

    const title = walletAddress;

    const captionComponent = (
        <div className={classNames(common.displayFlex, flex.column, flex.alignEnd)}>
            <BalanceView
                hideIcon
                hex={false}
                balance={balance}
                notConvert
                fixFractional
                fractionalDigits={balance < 1 && item ? item.decimals : 0}
            />
            <UILabel
                testID={UIDetailsButtonTestIds.secondDetails}
                color={ColorVariants.TextSecondary}
                role={TypographyVariants.ParagraphFootnote}
            >
                {percentStr}
            </UILabel>
        </div>
    );

    return (
        <LinkWithChild navigationPath={paths.accounts.details} params={{ id: item.walletAddress }}>
            <UIDetailsButton
                testID={TokenWalletsViewTestIDs.TokenWalletsView}
                copyTarget={UIDetailsButtonCopyTargets.title}
                copyValue={title}
                truncTitle
                title={title}
                index={index || index === 0 ? index + 1 : null}
                captionComponent={captionComponent}
            />
        </LinkWithChild>
    );
};

export const walletsExternalConfigs = {
    sortingKey: 'balance',
    sortDirectionByDefault: SortDirection.DESC,
};

export const walletsKWTExternalConfigs = {
    sortingKey: 'balance',
    sortDirectionByDefault: SortDirection.DESC,
    filterParams: {
        expression: {
            walletAddress: 'walletAddress',
        },
        minMax: {
            balance: {
                min: 'minBalance',
                max: 'maxBalance',
            },
        },
    },
};

export const getWalletsCollectionConfigs = (): TONCollectionConfigs => {
    return {
        keyExtractor: ({ walletAddress }: TokenWallet): string => {
            return walletAddress;
        },
        idKey: 'walletAddress',
        collectionName: liveLocalized.TokenWallets,
        renderItem: ({ item }: { item: any }) => <TokenWalletsView item={item} />,
    };
};

export default TokenWalletsView;
