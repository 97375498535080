import React from 'react';
import { observer } from 'mobx-react';

import controllerService from '@services/ControllerService';
import type { AccountTransfer } from '#TONClient/EVERTransfer/types';
import MomentHelper from '#helpers/MomentHelper';
import { LinkWithChild, BalanceView } from '#components';
import UIDetailsButton, { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import { ToFromDetails } from '#components/EVERList/ListViews/components/ToFromDetails';
import { paths } from '#navigation/paths';

const transferViewTestIDs = {
    transferView: 'transferView',
};

const AccountTransferViewComponent: React.FC<AccountTransfer & { accountId?: string }> = ({
    type,
    tokenSymbol,
    to,
    from,
    messageId,
    timestamp,
    value,
    decimals,
    accountId,
}) => (
    <LinkWithChild navigationPath={paths.transfers.details} params={{ messageID: messageId }}>
        <UIDetailsButton
            testID={transferViewTestIDs.transferView}
            copyTarget={UIDetailsButtonCopyTargets.title}
            title={`${type} ${tokenSymbol}`}
            truncTitle={controllerService.isNarrow}
            details={MomentHelper.getTimeDate(timestamp)}
            captionComponent={<BalanceView balance={value} notConvert hideIcon fractionalDigits={decimals} />}
            secondDetails={<ToFromDetails detailsItemId={accountId} to={to} from={from} />}
        />
    </LinkWithChild>
);

const AccountTransferView = observer(AccountTransferViewComponent);

const AccountTransferViewGetCollectionConfigs = (accountId?: string): TONCollectionConfigs => ({
    keyExtractor: ({ messageId }: AccountTransfer) => `transaction-view-${messageId}`,
    idKey: 'messageId',
    renderItem: ({ item }: any) => <AccountTransferView {...item} accountId={accountId} />,
});

export { AccountTransferViewGetCollectionConfigs };
