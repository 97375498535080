import { Maybe } from 'graphql/jsutils/Maybe';

import { TONLog } from '#TONUtility';
import MomentHelper from '#helpers/MomentHelper';

import TONClient from '../TONClient';
import type { FormattedEVERBlock } from '../EVERBlock/types';
import EVERBlock from '../EVERBlock';
import TONFilter from '../TONFilter';
import { ERROR, NOT_FOUND, SUCCESS } from '../statuses';
import type { GetTransactionsArgs, EVERTransactionT, TransactionFilterValues } from './types';
import { shortFields, accountCreatorFields } from './fields';
import {
    getAccountTransactionsQuery,
    getTransactionsWithPaginationQuery,
    newFilter,
    result,
} from '#TONClient/EVERTransaction/helpers';
import Utils from '#helpers/Utils';

let newTransactionsById = {};

let subscription;

const customLog = new TONLog('EVERTransaction');

export default class EVERTransaction {
    static statuses = {
        unknown: 0,
        preliminary: 1,
        proposed: 2,
        finalized: 3,
        refused: 4,
    };

    static types = {
        ordinary: 0,
        storage: 1,
        tick: 2,
        tock: 3,
        splitPrepare: 4,
        splitInstall: 5,
        mergePrepare: 6,
        mergeInstall: 7,
    };

    static bounceTypes = {
        negFunds: 0,
        noFunds: 1,
        ok: 2,
    };

    static computeTypes = {
        skipped: 0,
        vm: 1,
    };

    static shortFields = shortFields;
    static accountCreatorFields = accountCreatorFields;

    static calcGasPrice(trDoc: EVERTransactionT): number {
        const gasUsed = trDoc?.compute?.gas_used;
        if (!gasUsed) {
            return 0;
        }
        return TONClient.number(trDoc?.compute?.gas_fees || null) / Number(gasUsed);
    }

    static formatter = (trDoc: EVERTransactionT, blockDoc?: Maybe<FormattedEVERBlock>): EVERTransactionT => {
        const { compute, action, storage, bounce } = trDoc;

        const { number } = TONClient;
        const value_received: Maybe<number> =
            number(trDoc?.in_message?.value || null) + number(trDoc?.in_message?.ihr_fee || null);
        const value_sent: Maybe<number> = trDoc?.out_messages
            ? (trDoc?.out_messages || []).reduce((prev, curr) => prev + number(curr.value || null), 0)
            : null;
        const fees: number =
            number(trDoc?.total_fees || null) + number(action?.total_fwd_fees) - number(action?.total_action_fees);

        const ext_in_fwd_fees =
            number(trDoc?.total_fees || null) -
            number(storage?.storage_fees_collected) -
            number(compute?.gas_fees || null) -
            number(action?.total_action_fees) -
            number(bounce?.msg_fees);

        const out_fwd_fees = number(action?.total_fwd_fees) - number(action?.total_action_fees);

        return {
            ...trDoc,
            compute: {
                ...compute,
                gas_price: EVERTransaction.calcGasPrice(trDoc),
            },
            block: blockDoc ?? undefined,
            value_received,
            value_sent,
            fees,
            ext_in_fwd_fees,
            out_fwd_fees,
            ...(trDoc.in_message
                ? {
                      in_message: {
                          ...trDoc.in_message,
                          id: trDoc.in_message.id.replace('message/', ''),
                      },
                  }
                : {}),
            ...(trDoc.out_messages
                ? {
                      out_messages: trDoc.out_messages.map((message) => ({
                          ...message,
                          id: message.id.replace('message/', ''),
                      })),
                  }
                : {}),
        };
    };

    static initLog(str: string | number, params?: any) {
        return TONClient.initLog('EVERTransaction', str, params);
    }

    static blockFilter(blockId: Maybe<string>, isJSFetchQuery: boolean = false) {
        return blockId ? { block_id: { eq: isJSFetchQuery ? `"${blockId}"` : blockId } } : {};
    }

    static async filter(args: GetTransactionsArgs, isJSFetchQuery: boolean = false) {
        const { lastItem, filterValues, blockId } = args;
        return {
            ...TONFilter.timePaginationFilter({ args, key: 'now' }),
            // ...(lastItem ? { id: { le: lastItem?.id } } : {}),
            ...(lastItem?.account_addr && { account_addr: { le: lastItem?.account_addr } }),
            ...(lastItem?.lt && { lt: { le: `${lastItem?.lt || ''}` } }),
            ...(await TONFilter.accountFilter(filterValues, 'account_addr')),
            ...TONFilter.workchainShardFilter(filterValues),
            ...this.blockFilter(blockId ?? null, isJSFetchQuery),
            ...(filterValues?.aborted?.value && { aborted: { eq: true } }),
            ...TONFilter.minMaxNumberFilter(
                {
                    key: 'balance_delta',
                    denormalize: true,
                    minParam: filterValues?.minBalanceDelta ?? null,
                    maxParam: filterValues?.maxBalanceDelta ?? null,
                },
                isJSFetchQuery
            ),
        };
    }

    static newFormatter(transaction: EVERTransactionT) {
        return {
            ...transaction,
            id: transaction.id.replace('transaction/', ''),
        };
    }

    static async getTransactions(args: GetTransactionsArgs) {
        const { limit } = args;
        const filter = await this.filter(args, true);
        const orderBy = TONFilter.orderBy(args, 'now', true);

        const log = this.initLog('list', { args, filter, orderBy });

        try {
            const trDocs = await TONClient.queryTransactions(
                Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : '',
                result(args),
                Utils.JSONStringifyWithoutParanthesesAroundKeys(orderBy[0]),
                limit
            );

            log.debug(SUCCESS, trDocs);
            return trDocs;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async getTransactionsWithPagination(args: GetTransactionsArgs) {
        const { filterValues } = args;
        const accountId = filterValues?.accountId?.value || filterValues?.accountId;
        const sortingKey = 'now';
        const filter = await newFilter(args);

        const log = this.initLog('list', { args, filter, sortingKey });
        try {
            let resultTransactions: EVERTransactionT[] | null = null;
            let resultEndCursor: number | null = null;
            if (accountId) {
                const query = await getAccountTransactionsQuery(args);
                const { transactions, endCursor } = await TONClient.queryAccountTransactions(query, args.direction);

                resultTransactions = transactions;
                resultEndCursor = endCursor;
            } else {
                const query = await getTransactionsWithPaginationQuery(args);
                const { transactions, endCursor } = await TONClient.queryTransactionsFromBlockchainAPI(
                    query,
                    args.direction
                );

                resultTransactions = transactions;
                resultEndCursor = endCursor;
            }

            if (!resultTransactions?.length) return null;

            TONFilter.sortBy(resultTransactions, args, sortingKey);
            const formattedTransactions = resultTransactions.map((transaction, index) => ({
                ...this.newFormatter(transaction),
                //@ts-ignore doesn`t see not null check above
                ...(index === resultTransactions.length - 1 ? { endCursor: resultEndCursor } : {}),
            }));

            log.debug(SUCCESS, formattedTransactions);
            return formattedTransactions;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async checkTransactionId(tId: string) {
        const log = this.initLog(tId);
        try {
            const transaction = await TONClient.queryTransaction(tId, shortFields);
            if (!transaction?.id) {
                log.debug(NOT_FOUND);
                return null;
            }

            const formattedDoc = this.newFormatter(transaction);

            log.debug(SUCCESS, formattedDoc);
            return formattedDoc;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async getTransaction(tId: string, args?: GetTransactionsArgs): Promise<EVERTransactionT | null> {
        const log = this.initLog(tId, args);
        try {
            const transaction = await TONClient.queryTransaction(tId, result(args ?? null));
            if (!transaction?.block_id) {
                log.debug(NOT_FOUND);
                return null;
            }

            const blockDoc = await EVERBlock.getBlock(transaction.block_id);
            const formattedTransaction = this.formatter(transaction, blockDoc ?? undefined);

            log.debug(SUCCESS, formattedTransaction);
            return formattedTransaction;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async aggregateOrdinaryTransactionsMonthly() {
        const log = this.initLog('Last15months');

        try {
            const monthsCount = 15;
            const months: number[] = Array(monthsCount).map((e, i) => monthsCount - i);
            const operations = months.map((month) => ({
                filter: {
                    tr_type: { in: [this.types.ordinary] },
                    now: {
                        ge: MomentHelper.monthBefore(month, false),
                        lt: MomentHelper.monthBefore(month - 1, false),
                    },
                },
                collection: TONClient.collections.transactions,
            }));

            const transactionsCount = await TONClient.batchQuery(
                //@ts-ignore
                operations,
                TONClient.operationTypes.aggregateCollection
            );

            const result = transactionsCount.map((count, index) => ({
                timeStamp: MomentHelper.monthBefore(monthsCount - index, false),
                value: count,
            }));

            log.debug(SUCCESS, result);
            return result;
        } catch (err) {
            log.error(ERROR, err);
            throw err;
        }
    }

    static async aggregateTransactions(filterValues: TransactionFilterValues) {
        const filter = await this.filter({ filterValues }, true);
        const log = this.initLog('transactions count', { filterValues, filter });

        try {
            const count = await TONClient.aggregateTransactions(
                Object.keys(filter).length ? Utils.JSONStringifyWithoutParanthesesAroundKeys(filter) : ''
            );

            log.debug(count);
            return count;
        } catch (e) {
            log.error(e);
            return null;
        }
    }

    static async aggregateAllTransactions() {
        const log = this.initLog('total transactions count');
        try {
            const docs = await TONClient.network.queries.getTransactionsCount();
            log.debug(SUCCESS, docs);
            return docs;
        } catch (err) {
            log.error(err);
            throw err;
        }
    }

    static async aggregateOrdinaryTransactions(for24h: boolean = false) {
        const filter = {
            tr_type: { in: [this.types.ordinary] },
            ...(for24h && { now: { gt: MomentHelper.dayBefore() } }),
        };
        const log = this.initLog('operations count', { filter });

        return TONClient.aggregateItems({ filter, collection: 'transactions', log });
    }

    static unsubscribe() {
        if (subscription) {
            customLog.debug('Stop listen for transactions update...');
            TONClient.unsubscribe(subscription);
        }
    }

    static async subscribeForUpdate(
        callback: (transaction: Maybe<EVERTransactionT>) => void,
        args: GetTransactionsArgs
    ) {
        this.unsubscribe();
        const filter = await this.filter(args);
        customLog.debug('Start listening for transactions update...', args, filter);
        newTransactionsById = {};

        subscription = await TONClient.subscribeCollectionTransactions(filter, shortFields, async (tr) => {
            customLog.debug('Transaction was updated', tr);

            if (!tr || newTransactionsById[tr.id]) {
                return;
            }

            newTransactionsById[tr.id] = 1;
            const formattedDoc = this.formatter(tr);
            callback(formattedDoc);
        });
    }
}
