// @flow
import React from 'react';
import { liveLocalized } from '@services/LocalizationService';
import type { TONBlockT, TONBlockRef, TONAccountBlock, TONValueFlow } from '#TONClient/EVERBlock/types';
import type { TONMsgEnvelope, TONOutMsgDescr, TONInMsgDescr } from '#TONClient/TONMessage/types';
import UIDetailsTable from '#components/UIDetailsTable';
import { LinkWithCopy, LongBalanceView } from '#components';
import DetailsRowHelper from '#controllers/Details/DetailsRowHelper';
import { paths } from '#navigation/paths';

const { bool } = UIDetailsTable.cellType;

type MsgDescrOptions = {
    title: string,
    key: string,
    listMode?: boolean,
};

export default class Details {
    static refSection(title: string, ref: ?TONBlockRef) {
        const { TONBlock: blockLocalized } = liveLocalized;

        if (!blockLocalized.blockRef || !ref) return [];

        return [
            // { caption: title },
            // { caption: blockRef.end_lt, value: TONClient.hexToDec(ref.end_lt) },
            // { caption: blockRef.seq_no, value: ref.seq_no },
            // this.linkWithCopyRow(
            //     `${title} ${blockLocalized.blockRef.root_hash.toLowerCase()}`,
            //     ref?.root_hash,
            //     () => StackNavigator.pushBlockDetails({ id: ref?.root_hash }),
            // ),
            DetailsRowHelper.blockLinkWithCopy(
                `${title} ${blockLocalized.blockRef.root_hash.toLowerCase()}`,
                ref?.root_hash
            ),
            // { caption: blockRef.file_hash, value: ref.file_hash },
        ];
    }

    static refs(b: ?TONBlockT) {
        if (!b) return [];

        const { TONBlock: blockLocalized } = liveLocalized;

        return [
            ...this.refSection(blockLocalized.master_ref, b.master_ref),
            ...this.refSection(blockLocalized.prev_ref, b.prev_ref),
            ...this.refSection(blockLocalized.prev_alt_ref, b.prev_alt_ref),
            ...this.refSection(blockLocalized.prev_vert_ref, b.prev_vert_ref),
            ...this.refSection(blockLocalized.prev_vert_alt_ref, b.prev_vert_alt_ref),
        ];
    }

    static someFields(b: ?TONBlockT) {
        if (!b) return [];

        const { TONBlock: blockLocalized } = liveLocalized;

        return [
            // { key: 'getFields', captionType: header },
            { caption: blockLocalized.version, value: b.version },
            { caption: blockLocalized.gen_validator_list_hash_short, value: b.gen_validator_list_hash_short },
            { caption: blockLocalized.after_merge, value: b.after_merge, type: UIDetailsTable.cellType.bool },
            { caption: blockLocalized.after_split, value: b.after_split, type: bool },
            { caption: blockLocalized.before_split, value: b.before_split, type: bool },
            { caption: blockLocalized.want_merge, value: b.want_merge, type: bool },
            { caption: blockLocalized.want_split, value: b.want_split, type: UIDetailsTable.cellType.bool },
            { caption: blockLocalized.vert_seq_no, value: b.vert_seq_no },
            { caption: blockLocalized.min_ref_mc_seqno, value: b.min_ref_mc_seqno },
            { caption: blockLocalized.gen_software_capabilities, value: b.gen_software_capabilities },
            { caption: blockLocalized.rand_seed, value: b.rand_seed },
        ];
    }

    static valueFlow(value_flow: ?TONValueFlow) {
        if (!value_flow) return [];
        const { value_flow: valueFlowLocalized } = liveLocalized.TONBlock;

        return UIDetailsTable.formatNestedList([
            { caption: valueFlowLocalized.title },
            DetailsRowHelper.crystals(valueFlowLocalized.to_next_blk, value_flow?.to_next_blk),
            DetailsRowHelper.crystals(valueFlowLocalized.exported, value_flow?.exported),
            DetailsRowHelper.crystals(valueFlowLocalized.fees_collected, value_flow?.fees_collected),
            DetailsRowHelper.crystals(valueFlowLocalized.created, value_flow?.created),
            DetailsRowHelper.crystals(valueFlowLocalized.imported, value_flow?.imported),
            DetailsRowHelper.crystals(valueFlowLocalized.from_prev_blk, value_flow?.from_prev_blk),
            {
                caption: valueFlowLocalized.minted,
                component: <LongBalanceView balance={value_flow?.minted} hideIcon />,
            },
            DetailsRowHelper.crystals(valueFlowLocalized.fees_imported, value_flow?.fees_imported),
        ]);
    }

    static msgEnvelope(msgEnv: TONMsgEnvelope, title: string, key: string) {
        if (!msgEnv) return [];
        const { msgEnvelope: msgEnvelopLocalized } = liveLocalized.TONBlock;
        const keyProp = { key: `${key}-msg-envelope` };

        return [
            {
                caption: `${title} / ${msgEnvelopLocalized.msg_id}`,
                component: (
                    <LinkWithCopy
                        title={msgEnv.msg_id}
                        navigationPath={paths.messages.details}
                        params={{ id: msgEnv.msg_id }}
                    />
                ),
                ...keyProp,
            },
            {
                caption: `${title} / ${msgEnvelopLocalized.next_addr}`,
                value: msgEnv.next_addr,
                ...keyProp,
            },
            {
                caption: `${title} / ${msgEnvelopLocalized.cur_addr}`,
                value: msgEnv.cur_addr,
                ...keyProp,
            },
            {
                caption: `${title} / ${msgEnvelopLocalized.fwd_fee_remaining}`,
                component: <LongBalanceView balance={msgEnv?.fwd_fee_remaining} />,
                ...keyProp,
            },
        ];
    }

    static inMsgDescr({
        inMsgDescr,
        title,
        key,
        needOffset = false,
        listMode = false,
    }: MsgDescrOptions & {
        inMsgDescr: ?TONInMsgDescr,
        needOffset?: boolean,
    }) {
        if (!inMsgDescr) return [];

        const { inMsgDescr: inMsgDescrLoc } = liveLocalized.TONBlock;
        const inMsgDescrKey = `${key || title}-in-msg-descr`;

        return UIDetailsTable.formatNestedList({
            list: [
                { caption: title },
                ...(listMode
                    ? []
                    : [{ caption: inMsgDescrLoc.msg_type, value: liveLocalized.InMsgTypes[inMsgDescr.msg_type_name] }]),
                DetailsRowHelper.messageLinkWithCopy(inMsgDescrLoc.msg_id, inMsgDescr.msg_id),
                DetailsRowHelper.crystals(inMsgDescrLoc.ihr_fee, inMsgDescr.ihr_fee),
                {
                    caption: inMsgDescrLoc.proof_created,
                    value: inMsgDescr.proof_created,
                },
                ...this.msgEnvelope(inMsgDescr.in_msg, inMsgDescrLoc.in_msg, `${inMsgDescrKey}-in-msg`),
                DetailsRowHelper.crystals(inMsgDescrLoc.fwd_fee, inMsgDescr.fwd_fee),
                ...this.msgEnvelope(inMsgDescr.out_msg, inMsgDescrLoc.out_msg, `${inMsgDescrKey}-out-msg`),
                DetailsRowHelper.crystals(inMsgDescrLoc.transit_fee, inMsgDescr.transit_fee),
                DetailsRowHelper.transactionLinkWithCopy(inMsgDescrLoc.transaction_id, inMsgDescr.transaction_id),
                {
                    caption: inMsgDescrLoc.proof_delivered,
                    value: inMsgDescr.proof_delivered,
                },
            ],
            key: inMsgDescrKey,
            needOffset,
        });
    }

    static outMsgDescr({
        outMsgDescr,
        title,
        key,
        listMode = false,
    }: MsgDescrOptions & {
        outMsgDescr: TONOutMsgDescr,
    }) {
        if (!outMsgDescr) return [];

        const { out_msg_descr: outMsgDescrLoc } = liveLocalized.TONBlock;
        const outMsgDescrKey = `${key}-out-msg-descr`;

        return UIDetailsTable.formatNestedList(
            [
                { caption: title },
                ...(listMode ? [] : [{ caption: outMsgDescrLoc.msg_type, value: outMsgDescr.msg_type_name }]),
                DetailsRowHelper.messageLinkWithCopy(outMsgDescrLoc.msg_id, outMsgDescr.msg_id),
                DetailsRowHelper.messageLinkWithCopy(outMsgDescrLoc.transaction_id, outMsgDescr.transaction_id),
                ...this.msgEnvelope(outMsgDescr.out_msg, outMsgDescrLoc.out_msg, `${outMsgDescrKey}-out-msg`),
                ...this.inMsgDescr({
                    inMsgDescr: outMsgDescr.reimport,
                    title: outMsgDescrLoc.reimport,
                    key: `${outMsgDescrKey}-reimport`,
                }),
                ...this.inMsgDescr({
                    inMsgDescr: outMsgDescr.imported,
                    title: outMsgDescrLoc.imported,
                    key: `${outMsgDescrKey}-imported`,
                }),
                {
                    caption: outMsgDescrLoc.import_block_lt,
                    value: outMsgDescr.import_block_lt,
                },
            ],
            outMsgDescrKey
        );
    }

    static accountBlock(account_block: TONAccountBlock, index: number) {
        if (!account_block) return [];

        const { account_blocks: accountBlocksLocalized } = liveLocalized.TONBlock;

        return UIDetailsTable.formatNestedList([
            { caption: `${accountBlocksLocalized.title} ${index}` },
            {
                caption: accountBlocksLocalized.account_addr,
                value: account_block.account_addr,
            },
            {
                caption: accountBlocksLocalized.old_hash,
                value: account_block.old_hash,
            },
            {
                caption: accountBlocksLocalized.new_hash,
                value: account_block.new_hash,
            },
            {
                caption: accountBlocksLocalized.tr_count,
                value: account_block.tr_count,
            },
        ]);
    }

    static accountBlocksList(account_blocks: ?(TONAccountBlock[])) {
        if (!account_blocks) {
            return [];
        }

        let result = [];
        account_blocks.forEach((acc_block, index) => {
            result = [...result, ...this.accountBlock(acc_block, index)];
        });
        return result;
    }
}
