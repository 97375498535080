const FBCredentials = {
    prod: {
        apiKey: 'AIzaSyA85IyNkeZ7l0ZMtuHhhFCjqXKpoM8cjiU',
        authDomain: 'gram-scan.firebaseapp.com',
        databaseURL: 'https://gram-scan.firebaseio.com',
        projectId: 'gram-scan',
        storageBucket: 'gram-scan.appspot.com',
        messagingSenderId: '673460185317',
        appId: '1:673460185317:web:fe1108e0fca67d64',
    },
};

export default FBCredentials;
