// @flow
import { TONValidator } from '#TONClient';
import type { Tons } from '#TONClient/TONClient';
import type { Profile } from '@Firebase/FBProfiles';
import type { TONAccountT } from '#TONClient/TONAccount/types';
import type { Contract } from '@Firebase/FBContracts';

export type ValidatorType = TONValidator.types.single | TONValidator.types.dePool;

export type ValidatorsElectionTime = {
    electionsStart: number,
    electionsEnd: number,
    nextElectionsStart: number,
    nextElectionsEnd: number,
    electionsTitle: string,
};

export type ValidatorRootFields = {
    validatorId: string,
    validatorWallet: ?string,
    proxies?: string[],
    dePoolAddress?: string, // for DePools only
    type: ValidatorType,
};

export type TONValidatorT = {
    public_key: string,
    adnl_addr: string,
    weight: ?(string | number),
    // joined validator fields
    id: string, // not used, just for type EVERItem
    stake: ?number,
    bonuses: ?number,
    nodeId?: string,
    stakeAt?: number,
    signatures?: number,
    utime_since?: number,
    utime_until?: number,
    electorMsgId?: string,
    total_bonuses?: number,
    total_stake?: number,
    // joined depool fields
    maxFactor: number,

    electionId: string,
    stakeSent: Tons,
    timeSent: number,
    place?: number,
} & Profile &
    ValidatorRootFields;

export type StakeAndBonuses = {
    stake?: number,
    bonuses?: number,
};

export type SolidityElectorOutput = {
    past_elections: {
        [number]: StakeAndBonuses,
    },
    cur_elect: {
        members: TONValidatorT[],
        elect_at: number,
    },
};

export type ElectionParticipantsForFuncElector = {
    utime_since: number,
    participants: TONValidatorT[],
};

export type ElectorAccountContract = Contract & {
    account: TONAccountT,
};
