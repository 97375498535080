import React from 'react';
import { NavigateFunction } from 'react-router-dom';

const onWillFocus = (params, ref: React.RefObject<any>, callback: (params: any) => void | Promise<void> = () => {}) => {
    callback(params);
    const hasParams = Object.keys(params || {}).length !== 0;

    const unsubscribeOnFocus = () => {
        ref.current?.componentWillFocus();
    };

    const unsubscribeOnBlur = () => {
        ref.current?.componentWillBlur();
    };

    return () => {
        if (hasParams) {
            unsubscribeOnFocus();
            unsubscribeOnBlur();
        }
    };
};

const validatorDetailsGetParams = (params) => ({
    publicKey: '',
    electorMsgId: '',
    keyBlockNum: '',
    validatorId: '',
    ...params,
});

export { onWillFocus, validatorDetailsGetParams };
