export const messageTypes = {
    IntIn: 'IntIn',
    IntOut: 'IntOut',
    ExtIn: 'ExtIn',
    ExtOut: 'ExtOut',
};

export const messageTypeByFilters = {
    Int: [messageTypes.IntIn, messageTypes.IntOut],
    Ext: [messageTypes.ExtIn, messageTypes.ExtOut],
    Src: [messageTypes.IntOut, messageTypes.ExtOut],
    Dst: [messageTypes.IntIn, messageTypes.ExtIn],
};
