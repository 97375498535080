import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { paths } from '#navigation/paths';
import { validatorDetailsGetParams } from '#navigation/helpers';
import type { TONValidatorT } from '#TONClient/TONValidator/types';
import { convertWeight } from '#TONClient/TONValidator/helpers';
import { LinkWithChild, ShortBalanceView, UILabel, UIDetailsButton } from '#components';
import { UIDetailsButtonCopyTargets } from '#components/UIDetailsButton';
import type { TONCollectionConfigs } from '#components/EVERList';
import FlexContainer from '#components/common/FlexContainer';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { flex } from '#uikit/designCore/styles/flex';
import { common } from '#uikit/designCore/styles/comon';
import Utils from '#helpers/Utils';

type Props = {
    item: Maybe<TONValidatorT>;
    needConvertWeight?: boolean;
    keyBlockNum?: number;
    totalWeight?: number;
    needLink?: boolean;
};

export const validatorViewTestIDs = {
    validatorView: 'validatorView',
};

const ValidatorView = ({ item, needConvertWeight = false, keyBlockNum, totalWeight, needLink = true }: Props) => {
    if (!item) return null;

    const narrow = controllerService.isNarrow;

    const { type, weight, stake, bonuses, name, avatar, public_key, adnl_addr, validatorId, place } = item;

    const convertedWeight =
        Number(needConvertWeight && weight ? convertWeight(weight) : weight) /
        (Number(weight) > 1 ? Number(totalWeight) : 1);

    const percentFormatted = liveLocalized.amountToLocale((convertedWeight * 100).toFixed(2));
    const percentWeight =
        convertedWeight && stake ? `${percentFormatted}% | \u00A0` : convertedWeight ? `${percentFormatted} %` : '';

    const stakeComponent = stake && <ShortBalanceView balance={stake} notConvert />;

    const details = validatorId
        ? Utils.truncTextToNarrow(validatorId)
        : adnl_addr
        ? `adnl: ${Utils.truncTextToNarrow(adnl_addr)}`
        : '';

    const copyValue = validatorId || adnl_addr || '';

    const secondDetails = bonuses && (
        <FlexContainer alignItems="alignBaseline">
            {!narrow && <div>{liveLocalized.Rewards}:&nbsp;</div>}
            <ShortBalanceView balance={bonuses} notConvert />
        </FlexContainer>
    );

    const buttonComponent = (
        <UIDetailsButton
            testID={validatorViewTestIDs.validatorView}
            copyTarget={UIDetailsButtonCopyTargets.details}
            index={place}
            truncTitle={narrow}
            title={name || Utils.truncText(public_key)}
            image={avatar}
            details={type ? `${type} | ${details}` : details}
            copyValue={copyValue}
            captionComponent={
                <UILabel
                    role={TypographyVariants.ParagraphFootnote}
                    color={ColorVariants.TextSecondary}
                    classNames={classNames(common.displayFlex, flex.alignBaseline)}
                >
                    {!narrow && percentWeight}
                    {stakeComponent}
                </UILabel>
            }
            secondDetails={secondDetails}
        />
    );

    return needLink ? (
        <LinkWithChild
            navigationPath={paths.validators.details}
            params={validatorDetailsGetParams(
                item.electorMsgId ? { electorMsgId: item.electorMsgId } : { publicKey: item.public_key, keyBlockNum }
            )}
        >
            {buttonComponent}
        </LinkWithChild>
    ) : (
        buttonComponent
    );
};

ValidatorView.externalConfigs = {
    items: null,
    sortingKey: 'stake',
    additionalSortingKey: 'weight',
    filterParams: {
        minMax: {
            stake: {
                min: 'minStake',
                max: 'maxStake',
            },
        },
        expression: {
            name: 'name',
        },
    },
};

ValidatorView.getCollectionConfigs = (
    convertWeight?: boolean,
    keyBlockBum?: number,
    totalWeight?: number,
    needLink: boolean = true
): TONCollectionConfigs => {
    return {
        keyExtractor: ({ public_key }: TONValidatorT): string => {
            return `validator-view-${public_key}`;
        },
        idKey: 'public_key',
        collectionName: liveLocalized.Validators,
        renderItem: ({ item }: { item: any }) => (
            <ValidatorView
                needConvertWeight={convertWeight}
                totalWeight={totalWeight}
                keyBlockNum={keyBlockBum}
                item={item}
                needLink={needLink}
            />
        ),
    };
};

export default observer(ValidatorView);
