// @flow
import { action, makeObservable, observable } from 'mobx';

import { TONCache, TONCommonStore } from '@packages/TONUtility';
import Utils from '#helpers/Utils';
import { UITheme } from '#uikit/designCore/theme';
import { themesStoreKeys } from './constants';

export class ThemesService extends TONCommonStore {
    @observable isDarkTheme: boolean = false;

    constructor() {
        super();
        makeObservable(this);
    }

    onLoad = async () => {
        await this.loadStoreValues();
    };

    @action
    loadStoreValues = async () => {
        const storage = TONCache.getAsyncStorage();
        await Promise.all(
            Utils.objectValues(themesStoreKeys).map(async (storeKey: string) => {
                if (storage) {
                    const storeValue = await storage.getItem(storeKey);
                    if (!!storeValue && storeKey === themesStoreKeys.themeKey) {
                        this.isDarkTheme = storeValue === 'dark';
                    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                        this.isDarkTheme = true;
                    }
                } else {
                    throw new Error(`No storage or key present for ${storeKey}`);
                }
            })
        );
    };

    @action
    clearStoreValues = async () => {
        const storage = TONCache.getAsyncStorage();
        await Promise.all(
            Utils.objectValues(themesStoreKeys).map(async (storeKey: string) => {
                if (storage) {
                    storage.removeItem(storeKey);
                } else {
                    throw new Error(`No storage or key present for ${storeKey}`);
                }
            })
        );
    };

    @action
    saveTheme = async (theme: string) => {
        const storage = TONCache.getAsyncStorage();
        if (storage) {
            await storage.setItem(themesStoreKeys.themeKey, theme);
        } else {
            throw new Error('No storage or key is initialized');
        }
    };

    @action
    toggleTheme = async () => {
        this.isDarkTheme = !this.isDarkTheme;
        let theme;
        if (this.isDarkTheme) {
            theme = 'dark';
        } else {
            theme = 'light';
        }

        UITheme.switchCurrentTheme(theme);
        await this.saveTheme(theme);
    };
}
