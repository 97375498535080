import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import classnames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import searchService from '@services/SearchService';
import controllerService from '@services/ControllerService';
import Spinner from '#components/Spinner/Spinner';
//@ts-ignore doesn`t see import
import iconSearch from '#assets/ico-search/ico-search.png';
import { common, container, flex, height, margin, padding } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';

import { InputID } from '../constants';

import './SearchField.scss';

const Input: React.FC = observer(() => {
    const ref = useRef<HTMLInputElement>(null);
    const { isNarrow } = controllerService;

    return (
        <TextField
            inputRef={ref}
            value={searchService.searchExpression}
            onChange={(e) => {
                if (e.target.value) searchService.setIsSearchModalAvailableToBeOpen(true);
                searchService.setSearchExpression(e.target.value);
            }}
            onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    ref.current?.blur();
                }
            }}
            onFocus={() => searchService.setIsSearchModalAvailableToBeOpen(true)}
            onClick={() => searchService.setIsSearchModalAvailableToBeOpen(true)}
            classes={{ root: classnames('MUI') }}
            inputProps={{
                'data-testid': 'search-field-text-input',
                className: ColorVariants.TextPrimary,
                autoComplete: 'off',
            }}
            fullWidth
            className={classnames(flex.flex1, padding.rightDefault)}
            placeholder={isNarrow ? `${liveLocalized.SearchById}...` : liveLocalized.EnterIdTransactionAccountOrBlock}
        />
    );
});

const SearchFieldComponent: React.FC = () => {
    const { isNarrow } = controllerService;

    return (
        <div
            id={InputID}
            className={classnames(
                'SearchField',
                controllerService.reactContentClassNames,
                height.bigCell,
                container.centerLeft,
                common.displayFlex,
                {
                    narrow: isNarrow,
                }
            )}
        >
            <img src={iconSearch} className={classnames(common.icon, margin.rightSmall)} alt="search" />
            <Input />
            {searchService.isSearchInProgress && (
                <div className="SearchField__spinnerWrapper">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

const SearchField = observer(SearchFieldComponent);

export { SearchField };
