import { CollectionReference, DocumentSnapshot, QuerySnapshot } from '@Firebase/firestore-types';

import EnvManager from '#helpers/EnvManager';
import { TONLog } from '#TONUtility';
import { Tip3Token, AccountWallet, TokenWallet } from '#TONClient/EVERTip3/types';

import FBTemplate from '../FBTemplate';

const customLog = new TONLog('FBTip3Tokens');

export default class FBTip3Tokens extends FBTemplate {
    public static collectionName = 'tip3-tokens';

    public static network = EnvManager.getNetwork();
    private static tokenWalletsListCollectionName = 'tokenWallets';
    private static updatedTokenWalletsListField = 'updatedWallets';
    private static maxTimeWithoutUpdateForWallets = 9_000_00;

    private static async setNewListUpdatedTime(field: string, listName: string): Promise<void> {
        try {
            const collectionRef = FBTip3Tokens.getCollection();
            const newTime = Date.now();

            collectionRef
                .doc(FBTip3Tokens.network)
                .update({ [field]: newTime })
                .then(() => customLog.debug(`Set new ${listName} list time updated ----->`, newTime));
        } catch (e) {
            return;
        }
    }

    private static async checkNeedUpdateListCache(field: string, listName: string): Promise<boolean | null> {
        try {
            const collectionRef = FBTip3Tokens.getCollection();
            const updatedData = await collectionRef
                .doc(FBTip3Tokens.network)
                .get()
                .then((doc: DocumentSnapshot<{ [field: string]: string }>) => doc.data());
            if (!updatedData) return true;

            const lastUpdateTime = updatedData[field];
            customLog.debug(`Load ${listName} lastUpdateTime ----->`, lastUpdateTime);

            return !lastUpdateTime || Date.now() - lastUpdateTime > FBTip3Tokens.maxTimeWithoutUpdateForWallets;
        } catch (e) {
            return null;
        }
    }

    // tip-3 tokenWallets
    private static async unLoadTokenWalletsList(tokenWalletsList: TokenWallet[]): Promise<void> {
        try {
            const tokenWalletsListCollection: CollectionReference = FBTip3Tokens.getCollection()
                .doc(FBTip3Tokens.network)
                .collection(FBTip3Tokens.tokenWalletsListCollectionName);

            await tokenWalletsListCollection
                .get()
                .then((docs) => docs.docs.map((doc) => tokenWalletsListCollection.doc(doc.id).delete()));

            await Promise.all(
                tokenWalletsList.map((tokenWallet) =>
                    tokenWalletsListCollection.doc(tokenWallet.walletAddress).set(tokenWallet)
                )
            ).then(() => customLog.debug('TokenWallets list unLoaded to FB ----->', tokenWalletsList));
        } catch (e) {
            return;
        }
    }

    public static async checkNeedUpdateTokenWalletsListCache(): Promise<boolean | null> {
        return FBTip3Tokens.checkNeedUpdateListCache(
            FBTip3Tokens.updatedTokenWalletsListField,
            FBTip3Tokens.tokenWalletsListCollectionName
        );
    }

    public static async getTokenWalletsList(rootAddress: string): Promise<TokenWallet[] | null> {
        try {
            const tokenWalletsList = await FBTip3Tokens.getCollection()
                .doc(FBTip3Tokens.network)
                .collection(FBTip3Tokens.tokenWalletsListCollectionName)
                .where('rootAddress', '==', rootAddress)
                .get()
                .then((docs: QuerySnapshot<Tip3Token>) => docs.docs.map((doc) => doc.data()));

            customLog.debug('Load tokenWallets list ----->', tokenWalletsList);

            return tokenWalletsList;
        } catch (e) {
            return null;
        }
    }

    public static async getKWTWalletForAccount(accountId: string): Promise<AccountWallet | null> {
        try {
            const wallet = await FBTip3Tokens.getCollection()
                .doc(FBTip3Tokens.network)
                .collection(FBTip3Tokens.tokenWalletsListCollectionName)
                .doc(accountId)
                .get()
                .then((doc) => doc.data());

            if (wallet) {
                customLog.debug('Loaded KWT Wallet for account ----->', wallet);

                return {
                    decimals: wallet.decimals,
                    balance: wallet.balance,
                    rootAddress: wallet.rootAddress,
                    symbol: wallet.symbol,
                };
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    public static async updateTokenWalletsListCache(tokensList: TokenWallet[]): Promise<TokenWallet[]> {
        return FBTip3Tokens.unLoadTokenWalletsList(tokensList).then(() => {
            FBTip3Tokens.setNewListUpdatedTime(
                FBTip3Tokens.updatedTokenWalletsListField,
                FBTip3Tokens.tokenWalletsListCollectionName
            );
            return tokensList;
        });
    }
}
