// @flow
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import { TONFilterTags, TONMessage } from '#TONClient';
import type { GetTransactionsArgs } from '#TONClient/EVERTransaction/types';
import type { GetMessagesArgs, TONMessageT } from '#TONClient/TONMessage/types';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import { getAccountMessagesQuery } from '#TONClient/TONMessage/helpers';
import { TONLog } from '#TONUtility';
import { MessageView } from '#components';

import TabViewList, { getImperativeHandleParamsForTabViewList } from './TabViewList';
import type { CommonTabViewListProps } from './TabViewList';

const log = new TONLog('TransactionsList');

type Props = CommonTabViewListProps & {
    onChangeFilter: () => any,
    sortDirectionByDefault?: SortDirection,
    onChangeSortDirection?: (direction: SortDirection) => void,
};

const MessagesList = forwardRef<Props, any>(
    (
        {
            accountId = '',
            realtimeUpdated,
            onUpdateItems = () => {},
            onChangeFilter = () => {},
            onChangeSortDirection = () => {},
            sortDirectionByDefault,
        }: Props,
        Ref
    ) => {
        const ref = useRef(null);

        // Events
        const handleLoadMessage = (msg: ?TONMessageT): void => {
            if (!msg) {
                return;
            }

            ref?.current && ref.current.unshiftItem(msg);
            onUpdateItems();
        };

        const onStartReload = (filterValues?: FilterValues) => {
            //setTimeout to get correct ref
            setTimeout(() => ref.current?.updateFilterValues(filterValues || {}), 50);
        };

        const itemsLoader = useCallback(
            (args: GetMessagesArgs, filterValues: FilterValues): Promise<TONMessageT[]> => {
                if (!accountId) {
                    log.debug('No accountId passed');
                    return [];
                }

                return TONMessage.getAccountMessages({
                    ...args,
                    filterValues: {
                        ...filterValues,
                        accountId,
                    },
                });
            },
            [accountId]
        );

        useImperativeHandle(Ref, () => ({ ...getImperativeHandleParamsForTabViewList(ref), accountId }));

        return (
            <TabViewList
                ref={ref}
                nothingWasFoundMessage={liveLocalized.NoMessagesFound}
                allowFilterSubscription
                subscriptionAlwaysEnabled
                realtimeUpdated={realtimeUpdated}
                filterProps={TONFilterTags.getAccountMessageProps()}
                collectionConfigs={MessageView.getCollectionConfigs(accountId)}
                itemsLoader={itemsLoader}
                onChangeSortDirection={onChangeSortDirection}
                onSubscribeForUpdate={(filterValues: FilterValues): void => {
                    TONMessage.subscribeForUpdate(handleLoadMessage, {
                        filterValues: {
                            ...filterValues,
                            accountId,
                        },
                    });
                }}
                onUnsubscribeForUpdate={(): void => {
                    TONMessage.unsubscribe();
                }}
                onStartReload={onStartReload}
                onChangeFilterCustom={onChangeFilter}
                externalControlConfigs={{ sortDirectionByDefault }}
                isHiddenPlaygroundLink
                queryGetter={
                    accountId
                        ? (args: GetTransactionsArgs) =>
                              getAccountMessagesQuery({
                                  ...args,
                                  filterValues: {
                                      ...args.filterValues,
                                      accountId,
                                  },
                              })
                        : async () => {}
                }
            />
        );
    }
);

export default observer(MessagesList);
