import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import { themesService } from '@services/ThemesService';
import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import type { NavigationDeepMenuList, NavigationMenuItemsGroup } from '#navigation/navigationMenuItems';
import { BackgroundView, LinkWithChild, UILabel, UITextButton } from '#components';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { common, flex, height, margin, padding } from '#uikit/designCore/styles';

import './MenuScreen.scss';

type Props = {
    menuItems: NavigationDeepMenuList;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MenuItemsGroup: React.FC<NavigationMenuItemsGroup> = ({ title, items }) => {
    const location = useLocation();

    return (
        <div className={margin.bottomGreat}>
            <UILabel classNames={height.bigCell} role={TypographyVariants.LightHuge} color={ColorVariants.TextTertiary}>
                {liveLocalized[title]}
            </UILabel>
            {items.map(({ title, navigationPath }) => {
                const isCurrent = location.pathname === navigationPath;

                const button = (
                    <UITextButton
                        key={`navigation--sub-menu-button-${title}`}
                        buttonHeightClassName={height.bigCell}
                        titleRole={TypographyVariants.LightLarge}
                        titleColor={isCurrent ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
                        title={liveLocalized[title]}
                        onPress={() => controllerService.setIsMenuScreenOpen(false)}
                    />
                );
                return isCurrent ? (
                    button
                ) : (
                    <LinkWithChild
                        key={`navigation-menu-link-${title}`}
                        navigationPath={navigationPath}
                        onFollow={() => controllerService.setIsMenuScreenOpen(false)}
                    >
                        {button}
                    </LinkWithChild>
                );
            })}
        </div>
    );
};

const MenuScreen: React.FC<Props> = ({ menuItems }) => (
    <Dialog
        fullScreen
        open={controllerService.isMenuScreenOpen}
        TransitionComponent={Transition}
        className="MenuScreen"
    >
        <BackgroundView
            color={ColorVariants.BackgroundPrimary}
            classNames={classnames(common.displayFlex, flex.column)}
        >
            <IconButton
                onClick={() => controllerService.setIsMenuScreenOpen(false)}
                aria-label="close"
                className={classnames('MenuScreen__closeButton', 'MUI')}
            >
                <CloseIcon className={classnames('closeButton__icon', themesService.isDarkTheme ? 'dark' : 'light')} />
            </IconButton>
            <div className={classnames(flex.alignSelfCenter, padding.topHuge)}>
                {menuItems.map((menuItemsGroup) => (
                    <MenuItemsGroup {...menuItemsGroup} key={menuItemsGroup.title} />
                ))}
            </div>
        </BackgroundView>
    </Dialog>
);

export default observer(MenuScreen);
