import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { liveLocalized } from '@services/LocalizationService';
import type { GetTONItemsArgs, TONCollectionConfigs, TONExternalConfigs, EVERItem } from '#components/EVERList';
import type { FilterValues, SortDirection } from '#TONClient/TONFilter';
import type { AccentDetails } from '#components/AccentDetailsTable/AccentDetailsView';
import type { FilterPropObj } from '#TONClient/TONFilterTags';
import { AccentDetailsTable, TagFilter, EVERList } from '#components';
import { EVERListRef } from '#components/EVERList/types';
import { defaultCollectionConfigs } from '#controllers/Lists/helpers';
import { getFilterValuesFromURLParams, getDefaultFilterValue, getDefaultFilterValues } from '#controllers/helpers';
import { margin } from '#uikit/designCore/styles/margin';
import { container } from '#uikit/designCore/styles/container';
import { padding, width } from '#uikit/designCore/styles';
import { useParams } from '#navigation/hooks/useParams';
import { defaultItemsLoader } from '#components/EVERList';

type TabViewListRef = Pick<EVERListRef, 'reload' | 'unshiftItem'> & {
    setItems: (newItems: EVERItem[]) => void;
    updateFilterValues: (newFilterValues: FilterValues) => void;
    loadItems: () => void;
};

export type CommonTabViewListProps = {
    accountId: string;
    realtimeUpdated?: boolean;
    onUpdateItems: () => void;
};

type TabViewProps = {
    nothingWasFoundMessage?: string;
    allowFilterSubscription?: boolean;
    subscriptionAlwaysEnabled?: boolean;
    realtimeUpdated?: boolean;
    filterProps?: Maybe<FilterPropObj>;
    collectionConfigs?: TONCollectionConfigs;
    itemsLoader: (GetTONItemsArgs, FilterValues) => Promise<EVERItem[]>;
    propsItems?: Maybe<EVERItem[]>;
    externalControlConfigs: Maybe<TONExternalConfigs>;
    accentDetailsList?: AccentDetails[];
    onSubscribeForUpdate?: (FilterValues) => void;
    onUnsubscribeForUpdate?: () => void;
    onChangeFilterCustom?: (FilterValues) => void;
    onChangeItemsList?: (items: EVERItem[]) => void;
    onStartReload?: (FilterValues) => void;
    onChangeSortDirection?: (direction: SortDirection) => void;
    accountId?: string;
    blockId?: string;
    isHiddenPlaygroundLink?: boolean;
    queryGetter?: (args: GetTONItemsArgs) => Promise<string>;
};

export const getImperativeHandleParamsForTabViewList = (ref: any) => ({
    unshiftItem: (item: EVERItem) => ref?.current && ref.current.unshiftItem(item),
    setItems: (newItems: EVERItem[]) => ref?.current && ref.current.setItems(newItems),
    updateFilterValues: (newFilterValues: FilterValues) => {
        ref?.current && ref.current.updateFilterValues(newFilterValues);
    },
    loadItems: () => ref?.current && ref.current.loadItems(),
});

const TabViewList = forwardRef<TabViewListRef, TabViewProps>(
    (
        {
            accentDetailsList,
            filterProps,
            propsItems,
            collectionConfigs = defaultCollectionConfigs,
            externalControlConfigs,
            nothingWasFoundMessage = liveLocalized.NothingWasFound,
            realtimeUpdated = true,
            subscriptionAlwaysEnabled = false,
            allowFilterSubscription = false,
            itemsLoader = defaultItemsLoader,
            onChangeFilterCustom = (filterValues: FilterValues) => {},
            onUnsubscribeForUpdate = () => {},
            onSubscribeForUpdate = (filterValues?: FilterValues) => {},
            onStartReload = (filterValues?: FilterValues) => {},
            onChangeItemsList = (items: EVERItem[]) => {},
            onChangeSortDirection = (direction: SortDirection) => {},
            accountId,
            blockId,
            isHiddenPlaygroundLink,
            queryGetter = async () => '',
        }: TabViewProps,
        ref
    ) => {
        const listRef = useRef<EVERListRef>(null);
        const defaultFilterValues = getDefaultFilterValues(filterProps || null);

        const [items, setItems] = useState<EVERItem[]>([]);
        const [filterValues, setFilterValues] = useState<FilterValues>(defaultFilterValues);
        const [playgroundArgs, setPlaygroundArgs] = useState<GetTONItemsArgs>({});
        const [query, setQuery] = useState<string>('');
        const [params, setParams] = useParams();

        const externalControlConfigsResult = propsItems?.length
            ? {
                  items,
                  sortingKey: '',
                  ...externalControlConfigs,
                  sortDirectionByDefault: externalControlConfigs?.sortDirectionByDefault || 'DESC',
              }
            : {
                  items: null,
                  sortingKey: '',
                  ...externalControlConfigs,
                  sortDirectionByDefault: externalControlConfigs?.sortDirectionByDefault || 'DESC',
              };

        // Actions
        const setInnerItems = (newItems: EVERItem[]) => {
            setItems(newItems);
            onChangeItemsList(newItems);
        };

        const unshiftItem = (item: Maybe<EVERItem>) => {
            if (listRef?.current) {
                listRef.current.unshiftItem(item);
            }
        };

        const loadItems = () => {
            if (listRef?.current) {
                const filterValuesFromURLParams = getFilterValuesFromURLParams(params);
                const newFilterValues = { ...filterValues, ...filterValuesFromURLParams };
                setFilterValues(newFilterValues);
                listRef.current.reload(newFilterValues);
            }
        };

        // Events
        const onChangeFilter = (key: string, value: any): void => {
            setFilterValues((prevFilterValues) => {
                const newFilterValues = { ...prevFilterValues };
                newFilterValues[key] = {
                    ...(newFilterValues[key] || getDefaultFilterValue(filterProps, key)),
                    value,
                };

                onChangeFilterCustom(newFilterValues);
                if (listRef?.current) {
                    listRef.current.reload(newFilterValues);
                }

                return newFilterValues;
            });
        };

        const resetAllFilers = () => {
            setParams({ id: params.id }, false);
            setFilterValues({});
            listRef.current?.reload({});
        };

        useImperativeHandle(ref, () => ({
            unshiftItem,
            setItems: setInnerItems,
            updateFilterValues: (newFilterValues: FilterValues) => {
                setFilterValues((prevFilterValues: any) => {
                    const result = { ...prevFilterValues };
                    Object.keys(newFilterValues).forEach((key) => {
                        const prevFilterValue = prevFilterValues[key];
                        const newFilterValue = newFilterValues[key];
                        const defaultFilterValue = getDefaultFilterValue(filterProps, key);
                        result[key] = {
                            value:
                                prevFilterValue?.value !== null
                                    ? prevFilterValue?.value
                                    : newFilterValue?.value !== null
                                    ? newFilterValue?.value
                                    : defaultFilterValue?.value,
                            list:
                                prevFilterValue?.list !== null && prevFilterValue?.list?.length
                                    ? prevFilterValue?.list
                                    : newFilterValue?.list !== null && newFilterValue?.list?.length
                                    ? newFilterValue?.list
                                    : defaultFilterValue?.list,
                        };
                    });

                    return result;
                });
            },
            loadItems,
            reload: listRef.current ? listRef.current.reload : async () => {},
        }));

        useEffect(() => {
            loadItems();
            return () => {
                onUnsubscribeForUpdate();
            };
        }, []);

        useEffect(() => {
            onChangeItemsList(items);
        }, [items]);

        useEffect(() => {
            propsItems && setInnerItems(propsItems);
        }, [propsItems]);

        // Components
        const accentDetailsTable = (
            <AccentDetailsTable
                containerClassNames={classNames(margin.horizontalOffset, margin.topDefault)}
                detailsList={accentDetailsList || []}
            />
        );

        const tagFilter = (
            <div className={classNames(padding.horizontal, margin.topDefault)}>
                <TagFilter
                    filterProps={filterProps || null}
                    filterValues={filterValues}
                    playgroundArgs={{
                        ...playgroundArgs,
                        collection: collectionConfigs.collectionKey,
                        disable: isHiddenPlaygroundLink,
                        accountId,
                        blockId,
                        query,
                    }}
                    subscriptionAlwaysEnabled={subscriptionAlwaysEnabled}
                    allowSubscription={allowFilterSubscription}
                    onChange={onChangeFilter}
                    resetAllFilers={resetAllFilers}
                />
            </div>
        );

        const list = (
            <EVERList
                ref={listRef}
                classNames={margin.topTiny}
                contentClassNames={container.fullWidthPadding}
                realtimeUpdated={realtimeUpdated}
                nothingWasFoundMessage={nothingWasFoundMessage}
                collectionConfigs={collectionConfigs}
                externalControlConfigs={externalControlConfigsResult}
                itemsLoader={itemsLoader}
                filterValues={filterValues}
                onChangeItems={(newListItems: EVERItem[]) => setInnerItems(newListItems)}
                onChangeNewItems={(newListItems: EVERItem[]) => setInnerItems(newListItems)}
                onSubscribeForUpdate={onSubscribeForUpdate}
                onUnsubscribeForUpdate={onUnsubscribeForUpdate}
                onChangeSortDirection={onChangeSortDirection}
                onStartReload={(filterValuesParam, args) => {
                    onStartReload(filterValuesParam);
                    if (args) setPlaygroundArgs(args);
                    queryGetter({ ...args, filterValues: filterValuesParam }).then(setQuery);
                }}
            />
        );

        return (
            <div className={width.full}>
                {accentDetailsTable}
                {tagFilter}
                {list}
            </div>
        );
    }
);

export default observer(TabViewList);
