import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import classnames from 'classnames';

import { TONString } from '#TONUtility';
import { UIDetailsInput } from '#components/UIDetailsInput';
import { common, flex } from '#uikit/designCore/styles';
import UIColor from '#uikit/designCore/color';

import SignButton from '../SignButton/SignButton';

type Props = {
    comment?: string;
    onPressComment: () => void;
    onSubmitEditing: (value: any) => void;
    label?: string;
    value: number | null;
    placeholder?: string;
    needSign?: boolean;
};

export const EVERAmountInputTestIDs = {
    amountInput: (placeholder: string) => `amountInput_${placeholder}`,
};

export const MAX_SUBMITTING_VALUE = 10000000000;

const AmountInput = forwardRef<any, Props>(
    ({ comment, onPressComment, onSubmitEditing, label, placeholder, value: initialValue, needSign }, ref) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        const [value, setValue] = useState<number | null>(
            initialValue || initialValue === 0 ? Math.abs(initialValue) : null
        );
        const [negative, setNegative] = useState<boolean>(Number(initialValue) < 0);

        const focusInput = () => {
            if (inputRef?.current) {
                inputRef.current.focus();
            }
        };

        const handleInputChange = (value: string) => {
            if (value) {
                const numObject = TONString.getNumberParts(value);

                if (numObject) {
                    setValue(Math.abs(Math.min(numObject.value, MAX_SUBMITTING_VALUE)));
                }
            } else {
                setValue(null);
            }
        };

        const handleSubmitValue = () => {
            if (value || value === 0) {
                onSubmitEditing((negative ? -1 : 1) * value);
            }
        };

        const handleSwitchSign = () => {
            setNegative((prev) => !prev);
            focusInput();
        };

        useImperativeHandle(
            ref,
            () => ({
                focus: () => focusInput(),
            }),
            []
        );

        return (
            <div className={classnames(common.displayFlex)}>
                {needSign && <SignButton active={negative} onSwitch={handleSwitchSign} />}
                <UIDetailsInput
                    ref={inputRef}
                    testID={EVERAmountInputTestIDs.amountInput(placeholder || '')}
                    {...{
                        integer: true,
                        placeholder: label || placeholder,
                        comment,
                        onPressComment,
                    }}
                    onSubmitEditing={handleSubmitValue}
                    value={(typeof value === 'number' && TONString.getNumberParts(value.toString())?.valueString) || ''}
                    onChangeText={handleInputChange}
                />
            </div>
        );
    }
);

export { AmountInput };
