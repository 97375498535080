import React from 'react';
import classnames from 'classnames';

import { BackgroundView } from '#components';

import { common, flex, font, height } from '#uikit/designCore/styles';

import './SearchRow.scss';

type SearchRowProps = {
    value: string;
    accentValue: string;
    isSelected: boolean;
};

const SearchRow: React.FC<SearchRowProps> = ({ value, accentValue, isSelected }) => {
    const startAccentValueIndex = value.toLowerCase().indexOf(accentValue.toLowerCase());
    const endAccentValueIndex = startAccentValueIndex + accentValue.length;

    return (
        <BackgroundView
            className={classnames('SearchRow', common.displayFlex, flex.alignItemsCenter, height.mediumCell, {
                accent: isSelected,
            })}
            data-testID="searchResultItem"
        >
            <p className={classnames('SearchRow__text', font.smallRegular)}>
                {startAccentValueIndex !== -1 ? (
                    <>
                        {value.substr(0, startAccentValueIndex)}
                        <span color={font.bold}>{value.substr(startAccentValueIndex, accentValue.length)}</span>
                        {value.substr(endAccentValueIndex)}
                    </>
                ) : (
                    value
                )}
            </p>
        </BackgroundView>
    );
};

export { SearchRow };
