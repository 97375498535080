export type Hex = string;

export const convertByteToString = (byte: string): string => {
    return hexToString(byte || '').replace(/[^\w\s]/gi, '');
};

export const hexToString = (hex: Hex): string => {
    return hexToBuffer(hex).toString();
};

export function hexToBuffer(hex: Hex): Buffer {
    return Buffer.from(hex, 'hex');
}
