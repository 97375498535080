import React from 'react';

import UILabel from '#components/UILabel';
import { margin } from '#uikit/designCore/styles/margin';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

const Description: React.FC = ({ children }) => (
    <UILabel classNames={margin.topDefault} color={ColorVariants.TextSecondary} role={TypographyVariants.MonoNote}>
        {children}
    </UILabel>
);

export { Description };
