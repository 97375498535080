import React, { useImperativeHandle, useRef, useState } from 'react';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import classnames from 'classnames';

import { ActionImage, UILabel, UITextButton, UITextView } from '#components';
import type { ActionProps } from '#components/UIActionComponent/ReactActionComponent';
import { container, margin, height, common, flex, padding, color, width } from '#uikit/designCore/styles';
import { UIColorPalette } from '#uikit/designCore';
import { TypographyVariants } from '#uikit/themes/typography';
import { ColorVariants } from '#uikit/designCore/constants/color';

import { TextViewWrapper } from './TextViewWrapper';

type Props = ActionProps & {
    comment?: string | null;
    onChangeText: (text: string) => void;
    onPressComment: () => void;
    onSubmitEditing?: () => void;
    placeholder?: string;
    secondaryPlaceholder?: string;
    value: string;
    testID?: string;
};

type UIDetailsInputRef = {
    focus: () => void;
};

const UIDetailsInput = React.forwardRef<UIDetailsInputRef, Props>(
    (
        {
            placeholder,
            testID,
            onPressComment,
            secondaryPlaceholder,
            onSubmitEditing,
            onChangeText,
            comment,
            value = '',
        },
        Ref
    ) => {
        const textInputRef = useRef<HTMLInputElement>(null);

        const [focused, setFocused] = useState(false);
        const [hover, setHover] = useState(false);

        const focus = () => {
            if (!textInputRef.current) return;
            textInputRef.current.focus();
        };

        useImperativeHandle(Ref, () => ({ focus }), []);

        // Events
        const handleChangeText = (text: string) => {
            if (onChangeText) {
                onChangeText(text);
            }
        };

        // Getters
        const isFocused = () => focused || textInputRef.current?.value;

        // Render
        const renderFloatingTitle = () => {
            const inputValue = `${value}`;
            const emptyValue = !inputValue || !inputValue.length;
            const text = emptyValue && !isFocused() ? ' ' : placeholder;

            return (
                <UILabel color={ColorVariants.TextTertiary} role={TypographyVariants.ParagraphLabel}>
                    {text}
                </UILabel>
            );
        };

        const renderTextInput = () => {
            const testIDProp = testID ? { testID } : null;
            const valueProp = value != null ? { value: `${value}` } : null;

            return (
                <UITextView
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChangeText={handleChangeText}
                    onKeyDown={(e) => {
                        if ((e.code === 'Enter' || e.code === 'NumpadEnter') && onSubmitEditing) {
                            onSubmitEditing();
                        }
                    }}
                    placeholder={isFocused() ? secondaryPlaceholder : placeholder}
                    ref={textInputRef}
                    classNames={classnames(
                        common.displayFlex,
                        color.TextPrimary,
                        color.BackgroundPrimary,
                        width.full,
                        TypographyVariants.ParagraphText
                    )}
                    {...valueProp}
                    {...testIDProp}
                />
            );
        };

        const renderArrow = () => {
            return (
                <ActionImage Img={EastOutlinedIcon} fill={UIColorPalette.text.darkAccent} onClick={onSubmitEditing} />
            );
        };

        const renderTextFragment = () => (
            <>
                <div className={container.screen}>{renderTextInput()}</div>
                {renderArrow()}
            </>
        );

        const renderTextView = () => {
            return (
                <TextViewWrapper
                    focused={isFocused()}
                    hover={hover}
                    className={classnames(
                        padding.topTiny,
                        padding.bottomSmall,
                        common.displayFlex,
                        flex.row,
                        flex.alignCenter
                    )}
                >
                    {renderTextFragment()}
                </TextViewWrapper>
            );
        };

        const renderComment = () => {
            const commentText = !comment ? '' : `${comment || ' '}`;

            if (!commentText) {
                return null;
            }

            return (
                <>
                    {onPressComment ? (
                        <UITextButton
                            classNames={classnames(margin.topSmall, margin.bottomSmall)}
                            buttonHeightClassName={height.littleCell}
                            title={commentText}
                            titleColor={ColorVariants.TextAccent}
                            titleRole={TypographyVariants.ParagraphNote}
                            onPress={onPressComment}
                        />
                    ) : (
                        <UILabel
                            color={ColorVariants.TextTertiary}
                            role={TypographyVariants.ParagraphNote}
                            classNames={classnames(margin.topSmall, margin.bottomSmall)}
                        >
                            {commentText}
                        </UILabel>
                    )}
                </>
            );
        };

        return (
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={classnames(flex.flex1)}
            >
                {renderFloatingTitle()}
                {renderTextView()}
                {renderComment()}
            </div>
        );
    }
);

export { UIDetailsInput };
